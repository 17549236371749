<template lang="pug">
  div.form-group.my-2
    div
      div.my-2.small(v-if="!event.files || event.files.length === 0")
        i No files linked to event yet...
      div(v-else,style="max-height: 60vh; overflow-y: auto;")
        div.mb-2(v-if="thisInstanceFiles.length > 0")
          h6.m-0 This instance's files:
          ol.px-3
            li.mb-2.bg-light(v-for="(file, ind) in thisInstanceFiles")
              attachment-item(:attachment="file",:index="ind",@delete="deleteFile")
        div.mb-2(v-if="otherInstanceFiles.length > 0")
          div(v-for="(files, instance) in groupedOtherInstanceFiles")
            h6.m-0 {{ getFormattedDate(instance, 'MMMM Do, YYYY', true) }} instance's files:
            ol.px-3
              li.mb-2.bg-light(v-for="(file, ind) in files")
                attachment-item(:attachment="file",:index="ind",@delete="deleteFile")
      file-uploader(
        size="sm",
        defaultMessage="Click or drop files here to upload.",
        @added="uploadedFile")
</template>

<script>
  import Utilities from '@risk3sixty/utilities'
  import ApiFiles from '../../factories/ApiFiles'
  import TimeHelpers from '../../factories/TimeHelpers'

  const moment = Utilities.Dates.moment
  const arrayGroupBy = Utilities.Arrays.arrayGroupBy

  export default {
    props: {
      event: { type: Object },
    },

    computed: {
      thisInstanceFiles() {
        return this.event.files.filter((f) => {
          const fileDate = moment(f.recurring_event_start_date).format(
            'YYYY-MM-DD'
          )
          const instDate = moment(this.event.start).format('YYYY-MM-DD')
          return fileDate === instDate
        })
      },

      otherInstanceFiles() {
        return this.event.files.filter((f) => {
          const fileDate = moment(f.recurring_event_start_date).format(
            'YYYY-MM-DD'
          )
          const instDate = moment(this.event.start).format('YYYY-MM-DD')
          return fileDate !== instDate
        })
      },

      groupedOtherInstanceFiles() {
        return arrayGroupBy(
          this.otherInstanceFiles,
          (f) => f.recurring_event_start_date
        )
      },
    },

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,

      async deleteFile(fileId) {
        try {
          await this.$store.dispatch('updateCalendarEventFile', {
            eventId: this.event.id,
            date: this.event.start,
            fileUploadId: fileId,
            deleteFile: true,
          })
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async uploadedFile([, file]) {
        try {
          const { filename } = file
          const { files } = await ApiFiles.saveFile({ name: filename })

          await Promise.all(
            files.map(async (file) => {
              await this.$store.dispatch('updateCalendarEventFile', {
                eventId: this.event.id,
                date: this.event.start,
                fileUploadId: file.id,
              })
            })
          )
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },
  }
</script>
