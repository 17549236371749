<template lang="pug">
  div
    div(v-if="!isEditing" @click="canEdit ? isEditing = !isEditing : null" :class="canEdit ? 'editable' : ''")
      div(v-if="!mutableVal")
        i {{canEdit ? 'No value...' : ''}}
      div(v-else) {{ value }}
    div(v-else)
      input.form-control(
        :type="type"
        v-model="mutableVal"
        @blur="blurred")
</template>

<script>
  export default {
    props: {
      type: { type: String, default: 'number' },
      value: { type: String, default: null },
      canEdit: { type: Boolean, default: true },
    },

    data() {
      return {
        isEditing: false,
        isBlurred: false,
        tmpVal: this.value,
      }
    },

    computed: {
      mutableVal: {
        get() {
          return this.value
        },

        set(n) {
          if (n === this.value) return
          if (!this.isBlurred) return (this.tmpVal = n)

          this.$emit('input', this.tmpVal)
          this.isEditing = false
          this.isBlurred = false
        },
      },
    },

    methods: {
      blurred() {
        this.isBlurred = true
        this.mutableVal = this.tmpVal
      },
    },
  }
</script>
