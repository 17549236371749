<template lang="pug">
  draggable(
    v-model="mutableWidgets",
    v-bind="{disabled: !isEditing, group: 'mutableWidgets'}",
    @change="updateWidgets")
    transition-group.row
      div.mb-4(
        v-for="(widget, ind) in mutableWidgets"
        :key="`${widget.component}${ind}`"
        :class="getColumnSpanClass(widget)"
      )
        div.d-flex.align-items-center.draggable-header.text-center(v-if="isEditing")
          i.grab.fal.fa-bars
          select.form-control.form-control-sm.mx-4(v-model="widget.span",@change="updateWidgets")
            option(
              :value="span"
              v-for="span in [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ]") Span {{ getSpanFractionGcd(span) }} width of screen
          div.ml-auto.d-flex.align-items-center
            a.mr-1(v-if="hasEditIcon",@click="$emit('edit', ind)")
              i.fal.fa-edit
            a.text-danger(@click="removeWidget(ind)")
              i.fal.fa-times
        div.card.no-shadow.card-small(v-if="isCard")
          div.card-body
            component.widget(
              :is="widget.component"
              :chart-data="widget.chartData"
              :options="widget.options")
        component.widget(
          v-else
          :is="widget.component"
          :chart-data="widget.chartData"
          :options="widget.options")
</template>

<script>
  import DashboardWidgets from '../dashboard/widgets'
  import NumberHelpers from '../../factories/NumberHelpers'

  export default {
    props: {
      // `widgets` array, should be used with v-model in parent component
      // [{component: 'ComponentName', span: 1-12 }, ...]
      value: { type: Array, required: true },
      hasEditIcon: { type: Boolean, default: false },
      isEditing: { type: Boolean, default: false },
      isCard: { type: Boolean, default: false },
    },

    computed: {
      mutableWidgets: {
        get() {
          return this.value.slice(0)
        },

        set(newWidgets) {
          this.$emit('input', newWidgets)
        },
      },
    },

    methods: {
      getColumnSpanClass(widget) {
        if (typeof widget === 'string') return 'col-lg-4'
        return `col-lg-${widget.span || 4}`
      },

      getSpanFractionGcd(span) {
        const gcd = NumberHelpers.gcd(span || 4, 12)
        const fraction = `${(span || 4) / gcd}/${12 / gcd}`
        return fraction === '1/1' ? 'entire' : fraction
      },

      removeWidget(widgetIndex) {
        this.$emit('remove', widgetIndex)
      },

      updateWidgets() {
        this.$emit('update', this.mutableWidgets)
      },
    },

    components: {
      ...DashboardWidgets.reduce(
        (o, comp) => ({ ...o, [comp.val]: comp.component }),
        {}
      ),
    },
  }
</script>

<style scoped lang="scss">
  .card.widget {
    &:not(.short) {
      min-height: 300px !important;
    }
  }

  .draggable-header {
    background: #ffffff;
    border-radius: 5px 5px 0px 0px;
    color: inherit;
    margin: 0px 8px;
    padding: 6px;
  }
</style>
