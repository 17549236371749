<template lang="pug" functional>
  div.d-flex.align-items-center(
    :class="`${data.staticClass || ''} ${props.count && props.count > 0 ? props.textClass : ''}`")
      i.badge-wrapper(:class="props.iconBaseType + ' ' + props.iconClass",:id="props.uid")
        //- span.badge.badge-pill(:class="[ badgePositionClass, iconBadgeClass ]") {{ props.count }}
      sup
        strong(v-visible="props.count && props.count > 0") {{ props.count || '0' }}
      b-tooltip(v-if="(props.tooltipText || props.tooltipHtml) && props.uid",:target="props.uid")
        div(v-if="props.tooltipHtml",v-html="props.tooltipHtml")
        div(v-else) {{ props.tooltipText }}
</template>

<script>
  export default {
    functional: true,
    props: {
      count: { type: [Number, String] },
      iconBaseType: { type: String, default: 'fal' },
      iconClass: { type: String, default: 'fa-check' },
      tooltipText: { type: String, default: null },
      tooltipHtml: { type: String, default: null },
      uid: { type: String, default: null },
      position: { type: String, default: 'top-right' },
      textClass: { type: String, default: 'text-success' },
    },

    // computed: {
    //   iconBadgeClass() {
    //     if (parseInt(this.props.count) > 0) return 'badge-success'
    //     return 'badge-info'
    //   },
    //   badgePositionClass() {
    //     return `${this.props.position}-badge`
    //   },
    // },
  }
</script>
