<template lang="pug">
  div.d-flex.align-items-center
    div.progress.my-0.mr-2(
      v-if="showBar"
      :style="removeBoxShadow && { boxShadow: 'none'}")
        template(v-if="completedQuestions > 0")
          div.progress-bar.status(
            :id="`section-progress-${sectionId}-${_uid}-complete`"
            class="bg-transparent-dark-success"
            role="progressbar"
            :style="{ width: `${getStatusWidthPercentage('complete')}%` }")
          b-tooltip(:target="`section-progress-${sectionId}-${_uid}-complete`")
            | Complete: {{ completedQuestions }}
        template(v-if="completedQuestions != totalQuestions")
          div.progress-bar.status(
            :id="`section-progress-${sectionId}-${_uid}-incomplete`"
            class="bg-transparent-dark-info"
            role="progressbar"
            :style="{ width: `${getStatusWidthPercentage('incomplete')}%` }")
          b-tooltip(:target="`section-progress-${sectionId}-${_uid}-incomplete`")
            | Incomplete: {{ totalQuestions - completedQuestions }}
    strong(v-if="showCompletionText")
      | {{ completedQuestions }} of
      | {{ totalQuestions }} complete
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    props: {
      sectionId: { type: [Number, String], required: true },
      showCompletionText: { type: Boolean, default: false },
      removeBoxShadow: { type: Boolean, default: false },
      showBar: { type: Boolean, default: true }
    },

    computed: {
      ...mapState({
        section(state) {
          try {
            return state.questionnaire.record.sections.find(
              (s) => s.id == this.sectionId
            )
          } catch (err) {
            return null
          }
        },
      }),

      completedQuestions() {
        const { completedQuestions } = this.$store.state.questionnaire.getCountCompletedAndTotalQuestions(this.section.questions)
        return completedQuestions
      },
      totalQuestions() {
        const { totalQuestions } = this.$store.state.questionnaire.getCountCompletedAndTotalQuestions(this.section.questions)
        return totalQuestions
      },
    },

    methods: {
      getStatusWidthPercentage(which) {
        const completeWidthPerc = Math.floor(
          (this.completedQuestions / this.totalQuestions) * 100
        )
        switch (which) {
          case 'complete':
            return completeWidthPerc
          default:
            return 100 - completeWidthPerc
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .progress {
    min-width: 6vw;
  }
</style>
