export default {
  pentesting: {
    vulnerabilityScans: [],

    subHeaderTabs() {
      return [
        {
          text: 'All Pentests',
          activeClassArgs: ['ProjectListViewContainer'],
          link: `/pentesting`,
        },
        {
          text: 'Planning',
          activeClassArgs: ['PlanningContainer'],
          link: `/pentesting/planning/${this.id}`,
        },
        {
          text: 'Scoping',
          activeClassArgs: ['PentestingScopingContainer'],
          link: `/pentesting/scoping/${this.id}`,
        },
        {
          // Hide tab
          showCondition: false,
          text: 'Reporting',
          activeClassArgs: ['StatusReportingContainer'],
          link: `/pentesting/status/${this.id}`,
        },
        {
          text: 'Findings',
          activeClassArgs: ['FormalFindingsContainer'],
          link: `/pentesting/findings/${this.id}`,
        },
        {
          // Hide tab
          showCondition: false,
          text: 'Secure Messaging',
          activeClassArgs: ['SecureMessagesContainer'],
          link: `/pentesting/messaging/${this.id}`,
        },
        {
          text: 'File Sharing',
          activeClassArgs: ['ProjectContainer', this.tab === 'evidence'],
          link: `/pentesting/evidence/${this.id}`,
        },
        {
          text: 'Scan Results',
          activeClassArgs: ['PentestingVulnerabilitiesContainer'],
          link: `/pentesting/vulnerability/${this.id}`,
        },
        {
          // Hide tab
          showCondition: false,
          text: 'Internal',
          activeClassArgs: ['PentestingInternalContainer'],
          link: `/pentesting/internal/${this.id}/creds`,
        },
        {
          // Hide tab
          showCondition: false,
          text: 'Reporting',
          activeClassArgs: ['ReportingContainer'],
          link: `/pentesting/reporting/${this.id}`,
        },
      ]
    },
  },
}
