<template lang="pug">
  div
    div.modal.fade(:id="`send-${parentUid}`",tabindex="-1",role="dialog",aria-hidden="true")
      div.modal-dialog(role="document")
        div.modal-content
            div.modal-header
              h5.modal-title Deploy Questionnaire
              button.close(type="button",data-dismiss="modal",aria-label="Close")
                span(aria-hidden="true") &times;
            div.modal-body
              send-questionnaire-options(
                :vendor-id="computedVendorId",
                :review-id="computedReviewId",
                :questionnaire-id="computedQuestionnaireId",
                @showCreateVendor="showCreateVendor",
                @showCreateReview="showCreateReview",
                @showCreateContact="showCreateContact",
                @teamVendorUpdated="updateAndSetTeamVendor",
                @send="$emit('send')"
                @close="closeModal")

    create-vendor-modal(
      :id="`create-vendor-${parentUid}`",
      @created="updateAndSetTeamVendor",
      @cancel="showSendModal")
    
    vendor-review-modal(
      :id="`create-review-${parentUid}`",
      :team-vendor-id="computedVendorId",
      :start-now-option="false",
      :redirect-on-save="false",
      @updated="setTeamVendorReview",
      @cancel="showSendModal"
    )
    
    create-contact-modal(
      :id="`create-contact-${parentUid}`",
      @created="addAndSetTeamVendorContact",
      @cancel="showSendModal")
</template>

<script>
  import $ from 'jquery'
  import ApiVendorManagement from '../../factories/ApiVendorManagement'
  import CreateVendorModal from './CreateVendorModal'
  import CreateContactModal from './CreateContactModal'
  import VendorReviewModal from './VendorReviewModal'
  import SendQuestionnaireOptions from './SendQuestionnaireOptions'

  export default {
    components: {
      CreateVendorModal,
      CreateContactModal,
      VendorReviewModal,
      SendQuestionnaireOptions,
    },

    data() {
      return {
        passedVendorId: null,
        passedReviewId: null,
        passedQuestionnaireId: null,
      }
    },

    props: {
      parentUid: { type: [Number, String], required: true },
      vendorId: { type: [Number, String], default: null },
      reviewId: { type: [Number, String], default: null },
      questionnaireId: { type: [Number, String], default: null },
    },

    computed: {
      computedVendorId() {
        return this.passedVendorId || this.vendorId
      },

      computedReviewId() {
        return this.passedReviewId || this.reviewId
      },

      computedQuestionnaireId() {
        return this.passedQuestionnaireId || this.questionnaireId
      },
    },

    methods: {
      closeModal() {
        $(`#create-vendor-${this.parentUid}`).modal('hide')
        $(`#create-review-${this.parentUid}`).modal('hide')
        $(`#create-contact-${this.parentUid}`).modal('hide')
        $(`#send-${this.parentUid}`).modal('hide')
      },

      showCreateVendor() {
        $(`#send-${this.parentUid}`).modal('hide')
        $(`#create-contact-${this.parentUid}`).modal('hide')
        $(`#create-review-${this.parentUid}`).modal('hide')
        $(`#create-vendor-${this.parentUid}`).modal('show')
      },

      showCreateReview() {
        $(`#send-${this.parentUid}`).modal('hide')
        $(`#create-contact-${this.parentUid}`).modal('hide')
        $(`#create-vendor-${this.parentUid}`).modal('hide')
        $(`#create-review-${this.parentUid}`).modal('show')
      },

      showCreateContact(questionnaireId) {
        this.passedQuestionnaireId = questionnaireId
        $(`#send-${this.parentUid}`).modal('hide')
        $(`#create-vendor-${this.parentUid}`).modal('hide')
        $(`#create-review-${this.parentUid}`).modal('hide')
        $(`#create-contact-${this.parentUid}`).modal('show')
      },

      showSendModal() {
        $(`#send-${this.parentUid}`).modal('show')
      },

      async setTeamVendorReview(id) {
        this.showSendModal()
        await this.$store.dispatch('getReviews', true)
        this.passedReviewId = id
      },

      async updateAndSetTeamVendor(id) {
        this.showSendModal()
        await Promise.all([
          this.$store.dispatch('getTeamVendors', true),
          this.$store.dispatch('getQuestionnaires', true),
        ])
        this.passedVendorId = id
      },

      async addUserToVendor(userId) {
        try {
          await ApiVendorManagement.addUserToVendor(
            this.computedVendorId,
            userId
          )
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async addUserToQuestionnaire(userId) {
        try {
          await Promise.all([
            ApiVendorManagement.createUpdateQuestionnaire({
              id: this.computedQuestionnaireId,
              team_vendor_id: this.computedVendorId,
            }),
            ApiVendorManagement.addUserToQuestionnaire(
              this.computedQuestionnaireId,
              userId
            ),
          ])
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async addAndSetTeamVendorContact(user) {
        this.showSendModal()

        await this.addUserToVendor(user.id)
        // await this.addUserToQuestionnaire(user.id)

        await Promise.all([
          this.$store.dispatch('getTeamVendors', true),
          this.$store.dispatch('getQuestionnaires', true),
        ])
      },
    },

    mounted() {
      // This is here due to this issue
      // https://stackoverflow.com/questions/10636667/bootstrap-modal-appearing-under-background
      $(`#send-${this.parentUid}`).appendTo('body')
      $(`#create-vendor-${this.parentUid}`).appendTo('body')
      $(`#create-review-${this.parentUid}`).appendTo('body')
      $(`#create-contact-${this.parentUid}`).appendTo('body')

      $(`#send-${this.parentUid}`).on('shown.bs.modal', () => {
        // https://stackoverflow.com/questions/28077066/bootstrap-modal-issue-scrolling-gets-disabled
        $('body').addClass('modal-open')
      })
    },

    beforeDestroy() {
      // See comments in mounted() as to why this needs to be here.
      $(`#send-${this.parentUid}`).remove()
      $(`#create-vendor-${this.parentUid}`).remove()
      $(`#create-review-${this.parentUid}`).remove()
      $(`#create-contact-${this.parentUid}`).remove()
    },
  }
</script>
