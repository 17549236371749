<template lang="pug">
  span
    span(v-if="isLoadingLocal")
      loader-inline
    span(v-else)
      i.clickable.fal(:id="`independence-icon-${_uid}`", :class="getIndependenceIconClass")
      b-tooltip(:target="`independence-icon-${_uid}`")
        | You {{ (independence) ? '' : "don't" }} assert your
        | independence with {{ team.name }}.
      org-independence-popover(
        :independence="independence",
        :parentUid="_uid",
        :team="team",
        @update="getIndependence()")
</template>

<script>
  import ApiAssessments from '../../factories/ApiAssessments'

  export default {
    props: {
      team: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },

    data() {
      return {
        isLoadingLocal: true,
        independence: false,
      }
    },

    computed: {
      getIndependenceIconClass() {
        return this.independence
          ? 'text-success fa-check-circle'
          : 'text-danger fa-times-circle'
      },
    },

    methods: {
      async getIndependence() {
        const response = await ApiAssessments.getIndependence(this.team.id)
        this.independence = response.independent
      },
    },

    async created() {
      try {
        await this.getIndependence()
      } catch (err) {
        window.toastr.error(err.message)
      } finally {
        this.isLoadingLocal = false
      }
    },
  }
</script>
