<template lang="pug">
  table.table.mb-0
    thead.bg-light
      tr
        th.border-0(scope='col') Purpose
        th.border-0(scope='col') Tool Name
        th.border-0(scope='col') Notes
        th.border-0.text-center(v-if="isAdmin",scope='col') Edit/Delete
    tbody
      tr(v-if="tools.length === 0 || !tools[0].id")
        td(colspan="100%")
          i No components added yet...
      system-components-row(
        v-for="(tool, index) in tools"
        v-else
        :class="isAdmin ? 'clickable' : ''"
        :key="tool.item_id"
        :project-id="projectId"
        :category="category"
        :tool="tool"
        :index="index"
        :is-editing="isEditing === index"
        @editing="(editing) => isEditing = editing"
        @click="onClick(index)"
        @updated="refreshComponents")
    create-system-component-tbody(
      v-if="isCreatingNew"
      :project-id="projectId"
      :category="category"
      @created="toggleRow")
</template>

<script>
  import { mapState } from 'vuex'
  import SystemComponentsRow from './SystemComponentsRow'

  export default {
    props: {
      projectId: { type: [Number, String], default: null },
      category: { type: String, default: null },
      tools: { type: Array, default: () => [] },
      isCreatingNew: { type: Boolean, default: false },
    },

    data() {
      return {
        isEditing: false,
      }
    },

    computed: {
      ...mapState({
        isAdmin: (_, getters) => getters.isAdmin,
      }),
    },

    methods: {
      onClick(index) {
        if (!this.isAdmin) return
        this.isEditing = index
      },

      async toggleRow(comp) {
        this.$emit('toggleRow', comp)
      },

      async refreshComponents() {
        this.$emit('update')
      },
    },

    components: {
      SystemComponentsRow,
    },
  }
</script>

<style scoped lang="scss">
  .table tbody + tbody {
    border-top: 1px solid #dee2e6;
  }
</style>
