<template lang="pug">
  div.nowrap(:class="requestDueDateClass")
    update-item-param(
      extra-classes="color-inherit",
      type="date",
      :item="{ ...request, id: `${request.request_id}|${request.category_id}` }",
      backend-key="due_date",
      frontend-key="request_due_date",
      frontend-show-key="request_due_date",
      :event-only="true",
      date-format="MMM D, YYYY",
      @updated="changeRequestDueDate"
      :showDatePickerClear="true")
</template>

<script>
  import { mapState } from 'vuex'
  import UpdateItemParam from '../vulnerability/UpdateItemParam'
  import ApiAssessments from '../../factories/ApiAssessments'
  import TimeHelpers from '../../factories/TimeHelpers'

  export default {
    props: {
      projectId: { type: [Number, String], default: null },
      request: { type: Object },
    },

    computed: {
      ...mapState({
        assessmentProjectId(state) {
          return this.projectId || state.assessments.currentProject.id
        },
      }),

      requestDueDateClass() {
        const status = this.request.request_status || this.request.status
        const dueDate = this.request.request_due_date || this.request.due_date
        if (status === 'complete') return 'text-success'
        else if (status === 'fulfilled') return 'text-info'
        return TimeHelpers.dueDateColorClass(dueDate)
      },
    },

    methods: {
      async changeRequestDueDate(requestAndCategoryId, newDueDate) {
        try {
          const [requestId, categoryId] = requestAndCategoryId.split('|')

          await ApiAssessments.createProjectRequest(this.assessmentProjectId, {
            id: requestId,
            assessment_category_id: categoryId,
            due_date: newDueDate,
          })
          this.$emit('updated', requestId)
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    components: {
      UpdateItemParam,
    },
  }
</script>
