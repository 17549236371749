<template lang="pug">
  div.form-group
    div.d-flex.align-items-center.justify-content-between
      label.h6.my-0 {{childEntity}}:
      div.custom-control.custom-toggle-sm.custom-toggle
        input.custom-control-input(
          type="checkbox",
          :id="`mapped-items-toggle-${_uid}`",
          name="mapped-items-toggle",
          v-model="onlyShowMappedItems")
        label.custom-control-label(:for="`mapped-items-toggle-${_uid}`") Only Show Mapped {{childEntity}}
      search-input.pb-3(v-model="searchText" placeholder="Filter...")
    table.table
      thead
        tr
          th.col
          th.col(v-for="field in childFieldsToDisplay") {{field.fieldDisplay}}
      tbody(v-if="paginatedItems.data.length > 0")
        tr(v-for="item in paginatedItems.data" :key="item.id")
          th(scope="row")
            div.custom-control.custom-checkbox
              input.custom-control-input(
                :id="`item-${item.id}-${_uid}`",
                type="checkbox",
                :checked="isSelected(item.id)"
                @change="selectItem(item.id)")
              label.custom-control-label(:for="`item-${item.id}-${_uid}`")
          td(v-for="field in childFieldsToDisplay" v-html="item[field.fieldName]")
        tr
          td(colspan="100%")
            div.d-flex.justify-content-center
              pagination(
                :info="paginatedItems",
                @changePage="p => itemsPage = p",
                @changePerPage="p => { itemsPage = 1; itemsPerPage = p }")
      tbody(v-else)
        tr.text-center
          td.text-muted(colspan="100%") No {{childEntity}} Available to Map
</template>

<script>
  import Utilities from '@risk3sixty/utilities'

  export default {
    props: {
      value: { type: Set, default: () => new Set() },
      childEntity: { type: String, required: true },
      childFieldsToDisplay: {
        type: Array,
        default: () => [{ fieldName: 'id', fieldDisplay: 'ID' }],
      },
      availableItems: { type: Array, default: () => [] }, //Array of objects. Must at least have an 'id' field
    },

    watch: {
      value: {
        handler: function (newVal) {
          this.selectedItems = new Set(newVal)
        },
        immediate: true,
      },

      onlyShowMappedItems() {
        this.itemsPage = 1
      },
    },

    data() {
      return {
        selectedItems: new Set(),
        onlyShowMappedItems: false,
        searchText: '',
        itemsPerPage: 5,
        itemsPage: 1,
      }
    },

    computed: {
      paginatedItems() {
        let itemsToDisplay
        if (this.onlyShowMappedItems) {
          itemsToDisplay = this.availableItems.filter(
            (item) =>
              this.selectedItems.has(item.id) && this.itemIncludedInSearch(item)
          )
        } else {
          itemsToDisplay = this.availableItems.filter((item) =>
            this.itemIncludedInSearch(item)
          )
        }
        const paginationData = Utilities.Arrays.paginateArray(
          itemsToDisplay,
          this.itemsPerPage,
          this.itemsPage
        )
        return {
          data: paginationData.data,
          currentPage: paginationData.currentPage,
          totalCount: paginationData.dataLength,
          numberPages: paginationData.numberOfPages,
          perPage: this.itemsPerPage,
        }
      },
    },

    methods: {
      selectItem(itemId) {
        this.selectedItems.has(itemId)
          ? this.selectedItems.delete(itemId)
          : this.selectedItems.add(itemId)
        this.$emit('input', this.selectedItems)
      },

      itemIncludedInSearch(item) {
        if (!this.searchText) return true
        return this.childFieldsToDisplay.some((childField) => {
          return item[childField.fieldName]
            .toLowerCase()
            .includes(this.searchText.toLowerCase())
        })
      },

      isSelected(id) {
        return this.selectedItems.has(id)
      },
    },

    mounted() {
      this.onlyShowMappedItems = false
    },
  }
</script>
<style lang="scss" scoped>
  .col {
    width: unset;
  }
</style>
