<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-hidden="true")
    div.modal-dialog(role="document")
      div.modal-content
        form(@submit="saveOrg")
          div.modal-header
            h5.modal-title Select Organization
            button.close(type="button",data-dismiss="modal",aria-label="Close")
              span(aria-hidden="true") &times;
          div.modal-body
            div.form-body.mb-4
              label.mb-0 Search for organization
              div.text-large.d-flex.align-items-center(v-if="selectedTeam")
                strong {{ selectedTeam.name }}
                a.ml-2(@click="selectedTeam = null")
                  i.fal.fa-times.text-danger
              typeahead-input-new(
                src="/api/1.0/teams/getall?db=true"
                keys-from-response="teams"
                :show-prop-function="t => t.name"
                @input="selectOrg")
            div.form-body.mb-4
              div.text-center(v-if="text",v-html="text")
          div.modal-footer
            button.btn.btn-secondary(type="button",data-dismiss="modal") Cancel
            button.btn.btn-primary(type="submit") Select Org
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'

  export default {
    props: {
      text: { type: String, default: null },
    },

    data() {
      return {
        selectedTeam: null,
      }
    },

    computed: mapState({
      currentTeam: (state) => state.session.current_team,
    }),

    methods: {
      selectOrg(team) {
        this.selectedTeam = team
      },

      saveOrg(evt) {
        evt.preventDefault()
        this.$emit('select', this.selectedTeam)
        $(`#${this.$el.id}`).modal('hide')
      },
    },

    created() {
      this.selectedTeam = { ...this.currentTeam }
    },
  }
</script>
