<template lang="pug">
  div.d-flex.align-items-center.assessments-request-status(:class="(showDownArrow) ? 'clickable' : ''")
    i.fal(
      :class="getAllClasses"
      @mouseover="changeHovering(true)"
      @mouseout="changeHovering()")
    div.ml-1.small(:class="sliderElementClass",v-if="text") {{ text }}
    div.ml-1.small(v-if="text && showDownArrow")
      i.fal.fa-sort-down(:class="sliderElementClass")
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    props: {
      status: { type: String },
      isActive: { type: Boolean, default: false },
      text: { type: String, default: null },
      variant: { type: String, default: null },
      recordType: { type: String, default: 'request' },
      showDownArrow: { type: Boolean, default: true },
      projectId: { type: [Number, String], default: null },
    },

    data() {
      return {
        isHovering: false,
      }
    },

    computed: {
      ...mapState({
        sliderElementClass(state) {
          const { rawStatuses } = state.assessmentStatusesAndColors(
            this.projectId,
            this.recordType === 'control' && 'testing'
          )
          const variant = (
            rawStatuses.find((s) => s.value === this.status) || {}
          ).variant

          return `text-${variant || 'primary'}`
        },
      }),

      getAllClasses() {
        return [this.isActiveClass, this.sliderElementClass]
      },

      isActiveClass() {
        if (this.isHovering || this.isActive) {
          if (['fulfilled', 'ready_for_review'].includes(this.status))
            return 'fa-exclamation-circle'
          if (this.status === 'complete') return 'fa-check-circle'
          if (['not_started', 'returned'].includes(this.status))
            return 'fa-times-circle'

          return 'fa-chevron-circle-right'
        }

        return 'fa-circle'
      },
    },

    methods: {
      changeHovering(isOver = false) {
        return (this.isHovering = !!isOver)
      },
    },
  }
</script>
