<template lang="pug">
  b-dropdown(
    variant="link"
    toggle-class="p-0 text-decoration-none"
    boundary="viewport"
    no-caret)
      template(v-slot:button-content)
        div.d-flex.align-items-center(
          :id="`status-changer-dropdown-${_uid}`"
          data-toggle="dropdown")
            assessment-status-circle.fa-1-3x(
              :id="`linked-evidence-request-${_uid}`"
              :project-id="currentProject && currentProject.id"
              :record-type="recordType"
              :status="record.status"
              :text="text"
              :is-active="true")
            b-tooltip(:target="`linked-evidence-request-${_uid}`")
              | {{ (availableStatuses.find(s => s.value == record.status) || {}).text }}
      b-dropdown-item(
        v-for="status in availableStatuses"
        :key="status.value"
        @click="updateStatus(status.value)")
          div.d-flex.align-items-center(:class="`text-${status.variant}`")
            div.mr-1
              assessment-status-circle(
                :project-id="currentProject && currentProject.id",
                :record-type="recordType",
                :status="status.value",
                :variant="status.variant",
                :is-active="true")
            strong {{ titleCase(status.value, true) }}
</template>

<script>
  import { mapState } from 'vuex'
  import ApiAssessments from '../../factories/ApiAssessments'
  import StringHelpers from '../../factories/StringHelpers'

  export default {
    props: {
      text: { type: String, default: null },
      passedRecord: { type: Object, default: null },
      emitOnly: { type: Boolean, default: false },
      recordType: { type: String, default: 'request' },
    },

    computed: {
      ...mapState({
        currentProject: (state) => state.assessments.currentProject,
        record(state) {
          return this.passedRecord || state.assessments[this.globalRecordKey]
        },

        shouldShowInternalFunctionality(state, getters) {
          return (
            getters.isInternalUser ||
            (state.assessments.currentProject || {}).is_internal
          )
        },

        availableStatuses(state) {
          let allStatuses =
            this.recordType === 'control'
              ? state.assessments.allControlStatuses
              : state.assessments.allRequestStatuses

          if (
            this.currentProject &&
            state.assessments.projectStatuses[this.currentProject.id]
          ) {
            const projStatuses =
              state.assessments.projectStatuses[this.currentProject.id]
            allStatuses =
              this.recordType === 'control'
                ? projStatuses.control
                : projStatuses.request

            if (
              !this.shouldShowInternalFunctionality &&
              this.recordType === 'evidence'
            ) {
              allStatuses = projStatuses.userRequest
            }
          }

          return this.shouldShowInternalFunctionality
            ? allStatuses
            : allStatuses.filter((s) => ['all', 'external'].includes(s.mod1))
        },
      }),

      updateRecordMethod() {
        return this.recordType === 'control'
          ? 'createProjectControl'
          : 'createProjectRequest'
      },

      globalRecordKey() {
        return this.recordType === 'control'
          ? 'currentControl'
          : 'currentRequest'
      },
    },

    methods: {
      titleCase: StringHelpers.titleCase,

      async updateStatus(newStatus) {
        try {
          const finish = () => this.$emit('updated', 'status', newStatus)
          if (this.emitOnly) return finish()

          // update the current record's status so it propagates throughout
          // without perceived latency to the end user and refresh in background
          const commiter =
            this.recordType === 'control'
              ? 'SET_CURRENT_PROJECT_CONTROL_ONLY'
              : 'SET_CURRENT_PROJECT_REQUEST_ONLY'
          this.$store.commit(commiter, { ...this.record, status: newStatus })

          await ApiAssessments[this.updateRecordMethod](
            this.currentProject.id,
            {
              id: this.record.id,
              assessment_category_id: this.record.assessment_category_id,
              status: newStatus,
            }
          )

          finish()
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },
  }
</script>
