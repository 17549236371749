<template lang="pug">
  div
    div(v-if="isLoadingLocal")
      loader
    div(v-else)
      div.d-flex.align-items-center.justify-content-end.mb-2
        button.btn.btn-primary(
          data-toggle="modal"
          data-target="#create-key-contact-modal") Create Group
      div.card.no-shadow.border.card-small.mb-4(v-for="(roles, groupName) in contacts")
        div.card-header.border-bottom
          div.d-flex.align-items-center
            h6.m-0
              span {{ groupName }}
              i.add-item-to-group.text-gray.ml-1.fal.fa-plus.clickable(
                @click="$set(newRoleInCategory, groupName, !newRoleInCategory[groupName])")
            div.ml-auto
              button.btn.btn-success.btn-vsm(
                data-toggle="modal",
                data-target="#assessments-contact-invite-team-member-modal")
                | Add User
        div.card-body.p-0.table-responsive
          key-contacts-table.mb-0.small.small-padding(
            :project-id="projectId"
            :roles="roles"
            :is-creating-new="newRoleInCategory[groupName]"
            :group-name="groupName"
            @toggleRow="toggleNewRow"
            @update="getContacts")

    add-team-member-modal#assessments-contact-invite-team-member-modal(
      @added="addNewTeamMember")
    create-key-contact-modal#create-key-contact-modal(
      :project-id="projectId",
      :categories="contactCategories"
      @created="getContacts")
</template>

<script>
  import ApiProgram from '../../factories/ApiProgram'
  import ApiTeams from '../../factories/ApiTeams'

  export default {
    props: {
      projectId: { type: [Number, String], default: null },
    },

    data() {
      return {
        isLoadingLocal: true,
        contacts: null,

        selectedUserId: null,
        newRoleInCategory: {},
      }
    },

    computed: {
      contactCategories() {
        return Object.keys(this.contacts || [])
      },
    },

    methods: {
      async toggleNewRow(newContact) {
        this.newRoleInCategory[newContact.group_name] = false
        await this.getContacts()
      },

      async addNewTeamMember() {
        const [teamExternalUsers, teamAndInternalUsers] = await Promise.all([
          ApiTeams.getTeamUsers(),
          ApiTeams.getTeamUsers(true),
        ])

        this.$store.commit('SET_TEAM_USERS', teamExternalUsers.users)
        this.$store.commit(
          'SET_TEAM_AND_INTERNAL_USERS',
          teamAndInternalUsers.users
        )
      },

      async getContacts() {
        try {
          if (this.projectId) {
            await this.$store.dispatch(
              'getAssessmentKeyContactRolesByProject',
              this.projectId
            )
            this.contacts = this.$store.state.assessments.keyContactRoles[
              this.projectId
            ]
          } else {
            const { contacts } = await ApiProgram.getAllContacts()
            this.contacts = contacts
          }
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.isLoadingLocal = false
        }
      },
    },

    async created() {
      await this.getContacts()
    },
  }
</script>
