export default {
  SET_REPORT_TYPES(state, typesObj) {
    state.reporting.types = typesObj
  },

  SET_CURRENT_REPORT_TYPE(state, type) {
    state.reporting.currentType = type
  },

  SET_REPORTS(state, reports) {
    state.reporting.reports = reports
  },

  SET_REPORT_SORTING(state, { key, direction }) {
    state.reporting.sorting.reports = {}
    state.reporting.sorting.reports[key] = direction
  },

  SET_REPORTS_PAGE(state, page) {
    state.reporting.pagination.reports.page = page
  },

  SET_REPORTS_PER_PAGE(state, perPage) {
    state.reporting.pagination.reports.perPage = perPage
  },
}
