<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-labelledby="add-users-modal-label",aria-hidden="true")
    div.modal-dialog.modal-lg(role="document")
      div.modal-content
        div.modal-header
          h5#add-users-modal-label.modal-title Bulk Upload Users
          button.close(type="button",data-dismiss="modal",aria-label="Close")
            span(aria-hidden="true") &times;
        div.modal-body
          div.text-center
            file-uploader.d-none(
              :clickable="`#upload-bulk-users-${_uid}`", 
              acceptedFileTypes="text/csv, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xlsx",
              @added="uploadedCsv"
              @processing="uploadingCsv")
            div.d-flex.justify-content-center(v-if="!bulkUploadCsvFilename")
              button.btn.btn-secondary.mr-2(@click="downloadTemplate(templateData)" type="button") Download CSV Template
              button.btn.btn-primary.ml-2(:id="`upload-bulk-users-${_uid}`")
                | Upload CSV #[loader-inline.ml-2(v-if="bulkUploadProcessing")]
            div.alert.alert-primary(v-if="!!bulkUploadCsvFilename")
              b CSV uploaded successfully. Click "Create Users" below to begin adding users into fullCircle.
        div.modal-footer
          button.btn.btn-secondary(type="button",data-dismiss="modal") Cancel
          button.btn.btn-primary(
            v-if="!bulkUploadProcessing && bulkUploadCsvFilename",type="button",@click="saveItems()") Create Users
</template>

<script>
  import $ from 'jquery'
  import ApiUsers from '../../factories/ApiUsers'
  import StringHelpers from '../../factories/StringHelpers'
  import { downloadTemplate } from '../../factories/BulkUploadHelper'

  export default {
    data() {
      return defaultData()
    },

    methods: {
      titleCase: StringHelpers.titleCase,
      downloadTemplate,

      uploadingCsv() {
        this.bulkUploadProcessing = true
      },

      uploadedCsv([, response]) {
        this.bulkUploadCsvFilename = response.filename
        this.bulkUploadProcessing = false
      },

      async saveItems() {
        try {
          await ApiUsers.bulkUpload(this.bulkUploadCsvFilename)
          window.toastr.success(
            `Successfully began adding your users. Please be patient as this could take a little time to complete.`
          )

          $(`#${this.$el.id}`).modal('toggle')
          Object.assign(this.$data, defaultData())
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },
  }

  function defaultData() {
    return {
      bulkUploadCsvFilename: null,
      bulkUploadProcessing: false,
      templateData: {
        filename: 'bulk-upload-users-template.csv',
        columns: ['email', 'name', 'role', 'send_invite_email'],
        rows: [
          ['john.doe@yourcompany.com', 'John Doe', 'admin', 'true'],
          ['jane.doe@yourcompany.com', 'Jane Doe', 'manager', 'true'],
          ['mike.smith@yourcompany.com', 'Mike Smith', 'analyst', 'false'],
        ],
      },
    }
  }
</script>
