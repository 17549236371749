import { render, staticRenderFns } from "./Board.vue?vue&type=template&id=35509c0f&scoped=true"
import script from "./Board.vue?vue&type=script&lang=js"
export * from "./Board.vue?vue&type=script&lang=js"
import style0 from "./common.scss?vue&type=style&index=0&id=35509c0f&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35509c0f",
  null
  
)

export default component.exports