<template lang="pug">
div.modal.fade(role="dialog",aria-hidden="true")
  div.modal-dialog(role="document")
    div.modal-content
      form 
        div.modal-header
          h5.modal-title Email Report
          button.close(type="button",data-dismiss="modal",aria-label="Close")
            span(aria-hidden="true") &times;
        div.modal-body
          email-report-form(
            :emailSubject="mutableEmailSubject",
            :emailMessage="mutableEmailMessage",
            :emailUserAddresses="emailUserAddresses",
            :userCanEdit="userCanEditReports"
            @update="updateEmailReport"
          )
        div.modal-footer
          button.btn.btn-secondary(@click="closeModal" type="button",data-dismiss="modal") Cancel
          button#send-email.btn.btn-primary(type="button", @click.stop) Send
            confirmation-popover(
              target="send-email"
              header="Confirm Send Emails?"
              :text="`Are you sure you want to send this report to ${emailUserAddresses.length} email addresses?`"
              @confirm="emailReport")
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import EmailReportForm from './EmailReportForm.vue'

  export default {
    props: {
      emailSubject: { type: String, required: true },
      emailMessage: { type: String, required: true },
      userCanEditReports: { type: Boolean, default: true },
    },

    data() {
      return {
        emailUserAddresses: [],
        mutableEmailSubject: this.emailSubject,
        mutableEmailMessage: this.emailMessage,
      }
    },

    computed: {
      ...mapState({
        teamAndInternalUsers: (state) => state.team.teamAndInternalUsers,
      }),
    },

    methods: {
      updateEmailReport(key, value) {
        switch (key) {
          case 'emailSubject':
            this.mutableEmailSubject = value
            break
          case 'emailMessage':
            this.mutableEmailMessage = value
            break
          case 'emailUserAddresses':
            this.emailUserAddresses = value
            break
        }
      },

      closeModal() {
        $(`#${this.$el.id}`).modal('hide')
      },

      resetModal() {
        this.emailUserAddresses = []
        this.mutableEmailSubject = this.emailSubject
        this.mutableEmailMessage = this.emailMessage
      },

      async emailReport() {
        if (this.emailUserAddresses.length === 0) {
          return window.toastr.error('Please add at least one recipient.')
        }

        this.$emit('emailReport', {
          emailUserAddresses: this.emailUserAddresses,
          emailSubject: this.mutableEmailSubject,
          emailMessage: this.mutableEmailMessage,
        })
        this.closeModal()
      },
    },

    mounted() {
      $(`#${this.$el.id}`).appendTo('body')
      $(`#${this.$el.id}`).on('show.bs.modal', async () => {
        this.resetModal()
      })
    },

    beforeDestroy() {
      $(`#${this.$el.id}`).remove()
    },

    components: {
      EmailReportForm,
    },
  }
</script>
