<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-hidden="true")
    div.modal-dialog(role="document")
      div.modal-content
        form(@submit="createSelfAssessment")
          div.modal-header
            h5.modal-title {{ assessmentId ? 'Update' : 'Create' }} Self Assessment
            button.close(type="button",data-dismiss="modal",aria-label="Close")
              span(aria-hidden="true") &times;

          div.modal-body
            template(v-if="!assessmentId")
              div
                h6 Create from Template
              div.form-group.mb-2
                label Template #[span.text-danger *]
                select.form-control(
                  v-model="templateRecord"
                  @change="updateForm")
                    option(:value="null") Select template...
                    option(
                      v-for="questionnaire in questionnaireTemplates.data"
                      :value="questionnaire") {{ questionnaire.name }}
              div.text-center
                h6 OR
              hr
              div.mt-2
                h6 Create New Assessment
              template(v-if="assessmentId")
                div.form-group.mb-2.d-flex.justify-content-center
                  button.btn.btn-primary(@click="goToBuilder")
                    | Edit Sections &amp; Questions
                hr
            div.form-group.mb-2
              label Name
              input.form-control(v-model="record.name")
            div.form-group.mb-2
              label Description
              textarea.form-control(v-model="record.description")
            div.form-group.mb-2
              label Framework
              select.form-control(
                v-model="record.engagement_type")
                  option(:value="null") Select framework...
                  option(
                    v-for="framework in frameworks"
                    :value="framework.key") {{ framework.text }}
            div.form-group.mb-2
              label Due Date
              datepicker-shards(
                :show-clear="true",
                :input-id="`questionnaire-due-${_uid}`",
                v-model="record.due_date")

            hr
            div.form-group.mb-2(v-if="isManager")
              div.custom-control.custom-toggle.custom-toggle-sm
                input.custom-control-input(
                  :id="`is-template-${_uid}`"
                  type="checkbox"
                  v-model="record.is_template")
                label.custom-control-label(:for="`is-template-${_uid}`")
                  | Is this a template self assessment?

          div.modal-footer
            button.btn.btn-secondary(type="button",data-dismiss="modal") Cancel
            //- button.btn.btn-primary(type="submit" @click="editNow = true") Create &amp; Edit Now
            button.btn.btn-primary(type="submit") {{ assessmentId ? 'Update' : 'Create' }} 

</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import ApiVendorManagement from '../../factories/ApiVendorManagement'
  import { questionnaireTypes } from '../../constants/questionnaireTypes'

  export default {
    props: {
      assessmentId: { type: [Number, String], default: null },
    },

    data() {
      return {
        createdQuestionnaireId: null,
        templateRecord: null,
        record: {
          id: null,
          name: null,
          type: questionnaireTypes.SELF_ASSESSMENT,
          description: null,
          engagement_type: null,
          due_date: null,
          is_template: false,
        },
        editNow: false,
      }
    },

    computed: {
      ...mapState({
        isManager: (_, getters) => getters.isManager,
        frameworks: (_, getters) => getters.getSelfAssessmentFrameworks,

        questionnaireTemplates: (state) =>
          state.selfassessments.allSelfAssessmentTemplates || [],
      }),
    },

    methods: {
      goToBuilder() {
        $(`#${this.$el.id}`).modal('hide')
        this.$router.push(`/selfassessments/qbuilder/${this.assessmentId}`)
      },

      updateForm() {
        if (this.templateRecord === null) {
          this.resetSelfAssRecord()
        } else {
          // If templateRecord exists, set record off of template
          this.record = {
            ...this.templateRecord,
            id: null,
            type: questionnaireTypes.SELF_ASSESSMENT,
            template_id: this.templateRecord.id,
            is_template: false,
            is_published: false,
          }
        }
      },

      resetSelfAssRecord() {
        return (this.record = {
          id: null,
          name: null,
          description: null,
          engagement_type: null,
          type: questionnaireTypes.SELF_ASSESSMENT,
          due_date: null,
          is_template: false,
        })
      },

      async getAssessment(id = this.assessmentId) {
        try {
          const { questionnaire } = await ApiVendorManagement.getQuestionnaire(
            id
          )
          this.record = { ...this.record, ...questionnaire }
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async createSelfAssessment(evt) {
        try {
          evt.preventDefault()

          if (!this.record.name) {
            return window.toastr.error(
              `Please add a name of the questionnaire before saving.`
            )
          }

          const questionnaireId = await this.$store.dispatch(
            'createUpdateQuestionnaire',
            this.record
          )

          window.toastr.success(`Successfully updated questionnaire!`)

          this.$emit('created', questionnaireId, this.record.is_template)
          this.createdQuestionnaireId = questionnaireId
          this.templateRecord = null
          $(`#${this.$el.id}`).modal('hide')
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    mounted() {
      $(`#${this.$el.id}`).on('shown.bs.modal', async () => {
        if (this.assessmentId) return await this.getAssessment()
        this.resetSelfAssRecord()
      })

      $(`#${this.$el.id}`).on('hidden.bs.modal', () => {
        this.createdQuestionnaireId = null
      })
    },
  }
</script>
