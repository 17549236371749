<template lang="pug">
  div
    div.d-flex.align-items-start
      h4.clickable.m-0.strong(
        :id="`questionnaire-section-editor-${_uid}`") {{section.text}}

    b-popover(
      boundary="viewport",
      :target="`questionnaire-section-editor-${_uid}`",
      :ref="`questionnaire-section-editor-${_uid}`")
      template(slot="title")
        div.d-flex.align-items-center
          h6.mb-0.mr-3 Edit Section
          div.ml-auto
            i.fal.fa-times.clickable(@click="confirm('cancel')")

      div.form-group
        label Section Name
        input.form-control(type="text",v-model="mutableSection.text")
      div.form-group.m-0.d-flex.justify-content-center
        button.btn.btn-white.btn-vsm(@click="confirm('cancel')") Cancel
        button.ml-1.btn.btn-success.btn-vsm(@click="saveSection(mutableSection)") Save

</template>

<script>
  import ApiVendorManagement from '../../factories/ApiVendorManagement'

  export default {
    props: {
      section: { type: Object, required: true },
      saveSection: { type: Function, required: true }
    },

    watch: {
      section: {
        handler(newSection) {
          this.mutableSection = { ...newSection }
        },
        deep: true,
      },
    },

    data() {
      return {
        mutableSection: { ...this.section },
      }
    },

    methods: {
      confirm(action, data = null) {
        this.$emit(action, data || this.mutableSection)
        this.$refs[`questionnaire-section-editor-${this._uid}`].$emit('close')
      },


      async createQuestion() {
        try {
          const { id } = await ApiVendorManagement.updateQuestionnaireQuestion({
            group_id: this.section.id,
            text: '<p>New question!</p>',
            type: 'free_form',
          })
          this.confirm('addQuestion', id)
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },
  }
</script>
