<template lang="pug">
  div.btn-group.btn-group-sm
    button.btn.btn-toggle.btn-white(v-if="showRemove",type="button",@click="$emit('remove')")
      div.d-flex.align-items-center
        i.fal.fa-minus
    button.btn.btn-toggle.btn-white(type="button",@click="$emit('add')")
      div.d-flex.align-items-center
        i.fal.fa-plus
</template>

<script>
  export default {
    props: {
      showRemove: { type: Boolean, default: true },
    },
  }
</script>
