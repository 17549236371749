<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-hidden="true")
    div.modal-dialog(role="document")
      div.modal-content
        form(@submit="createComponent")
          div.modal-header
            h5.modal-title Create System Component
            button.close(type="button",data-dismiss="modal",aria-label="Close")
              span(aria-hidden="true") &times;
          div.modal-body
            div.card.card-small.border.no-shadow.mb-4
              div.card-body
                div.form-group.mb-2.d-flex.justify-content-center
                  div.custom-control.custom-checkbox
                    input#existing-or-new-category.custom-control-input(type="checkbox",v-model="createNewCategory")
                    label.nowrap.custom-control-label(for="existing-or-new-category") Create New Category?
                div.form-group(v-if="createNewCategory")
                  label New Category Name
                  input.form-control(v-model="component.category")
                div.form-group(v-else)
                  label Select Category
                  select.form-control(v-model="component.category")
                    option(:value="null") Select existing category...
                    option(v-for="category in categories") {{ category }}

            div.form-group.mb-3
              label Component Purpose (i.e. Access Control, Change Control, etc.)
              input.form-control(v-model="component.item_name")

            div.form-group.mb-3
              label Tool (i.e. Okta, JIRA, Zendesk, etc.)
              input.form-control(v-model="component.tool_name")
            div.form-group.mb-3
              label Tool Description
              textarea.form-control(v-model="component.description")
          div.modal-footer
            button.btn.btn-secondary(type="button",data-dismiss="modal") Cancel
            button.btn.btn-primary(type="submit") Create
</template>

<script>
  import $ from 'jquery'
  import ApiAssessments from '../../factories/ApiAssessments'

  export default {
    props: {
      projectId: { type: [ Number, String ] },
      categories: { type: Array }
    },

    data() {
      return {
        createNewCategory: false,
        component: getDefaultComponentData()
      }
    },

    methods: {
      async createComponent(evt) {
        try {
          evt.preventDefault()

          if (!(this.component.category && this.component.item_name && this.component.tool_name))
            return window.toastr.error(`Please enter at least a category, component purpose, and a tool name to proceed.`)

          await ApiAssessments.updateSystemComponent({ ...this.component, assessment_project_id: this.projectId })
          window.toastr.success(`Successfully created your component!`)
          
          this.$emit('created')
          $( `#${this.$el.id}` ).modal('toggle')

        } catch(err) {
          window.toastr.error(err.message)
        }
      }
    },

    async mounted() {
      $( `#${this.$el.id}` ).on('shown.bs.modal', async () => {
        this.createNewCategory = false
        this.component = getDefaultComponentData()
      })
    }
  }

  function getDefaultComponentData() {
    return {
      item_type: 'custom',
      item_name: null,
      category: null,
      tool_name: null,
      description: null
    }
  }
</script>
