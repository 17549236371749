<template lang="pug">
  input.form-control(
    v-model="compVal"
    :class="size == 'sm' && 'form-control-sm'")
</template>

<script>
  export default {
    props: {
      value: { type: String, default: null },
      size: { type: String, default: null },
    },

    computed: {
      compVal: {
        get() {
          return this.value
        },

        set(t) {
          this.$emit('input', t)
        },
      },
    },
  }
</script>
