<template lang="pug">
  div
    div.mb-4
      label How would you like to process Nessus scans?
      div.custom-control.custom-radio
        input.custom-control-input(
          id="nessus-is-file"
          name="nessus-is-file"
          type="radio"
          :value="true"
          v-model="isFileUpload"
          @change="processExtraInfo")
        label.custom-control-label(for="nessus-is-file") Manually upload .nessus import file(s) here
      div.custom-control.custom-radio
        input.custom-control-input(
          id="nessus-is-remote-node"
          name="nessus-is-remote-node"
          type="radio"
          :value="false"
          v-model="isFileUpload"
          @change="getRemoteAccessNodes")
        label.custom-control-label(for="nessus-is-remote-node") Automatically use remote access node to import scan data

    div(v-if="isFileUpload")
      extra-file-uploader(
        @fileAdded="fileAdded"
        @fileProcessing="$emit('fileProcessing')")
    div(v-else)
      loader(v-if="isLoadingLocal")
      div(v-else-if="nodes.length === 0")
        div.alert.alert-danger
          | You don't have any remote access nodes configured and set up yet.
          | You must configure a remote access node with access to your Nessus server
          | before using this option, contact your administrator if you have additional
          | questions.
      div(v-else)
        div.form-group
          label Select remote access node w/ access to Nessus
          select.form-control(v-model="selected.remoteNodeId" :class="remoteNodeSelectClass" @change="processExtraInfo")
            option(
              v-for="node in nodes"
              :value="node.id")
                | {{ node.external_name || node.hostname || node.dns || node.ip_address }}
                | ({{ node.ip_address }}),
                | {{ node.isAlive ? 'Connected!' : 'disconnected' }}
        div.form-group
          label External Vendor Scan ID
          input.form-control(type="number" v-model="selected.vendorScanId" @change="processExtraInfo")
        scheduler-form(
          :schedule="selected.schedule" 
          @sendItem="editScheduleItem")
</template>

<script>
  import { mapState } from 'vuex'
  import ExtraFileUploader from './ExtraFileUploader'

  export default {
    data() {
      return {
        isLoadingLocal: true,
        isFileUpload: true,

        selected: {
          remoteNodeId: null,
          vendorScanId: null,
          schedule: {
            frequencyType: 'one-time',
          },
        },
      }
    },

    computed: {
      ...mapState({
        nodes: (state) => state.vulnerability.remoteNodes,
      }),

      canSubmitScan() {
        if (this.isFileUpload) return true

        // the following logic only applies if remote access node config
        if (!this.selected.remoteNodeId) return false
        if (!this.selected.vendorScanId) return false

        const schedule = this.selected.schedule
        if (schedule.frequencyType === 'one-time') return true

        if (schedule.frequency && schedule.dayOfWeek && schedule.startDate)
          return true

        return false
      },

      remoteNodeSelectClass() {
        if (!this.selected.remoteNodeId) return
        return this.nodes.find((n) => n.id == this.selected.remoteNodeId)
          .isAlive
          ? 'is-valid'
          : 'is-invalid'
      },
    },

    methods: {
      editScheduleItem(item, val) {
        this.selected.schedule = {
          ...this.selected.schedule,
          [item]: val,
        }
        this.processExtraInfo()
      },

      processExtraInfo() {
        this.$emit('processExtraInfo', this.selected, this.canSubmitScan)
      },

      fileAdded() {
        this.$emit('fileAdded', ...arguments)
      },

      async getRemoteAccessNodes() {
        try {
          await this.$store.dispatch('getRemoteAccessNodes')
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.isLoadingLocal = false
        }
      },
    },

    components: {
      ExtraFileUploader,
    },
  }
</script>
