// import Utilities from '@risk3sixty/utilities'

export default {
  projects: {
    error: null,
    statuses: [],
    allTagsInProject: [],
    criticalities: [
      { val: 'extreme', text: 'Extreme', variant: 'danger' },
      { val: 'high', text: 'High', variant: 'warning' },
      { val: 'medium', text: 'Medium', variant: 'info' },
      { val: 'low', text: 'Low', variant: 'minimal' },
      { val: 'informational', text: 'Informational', variant: 'primary' },
      { val: null, text: 'Not Set', variant: 'gray' },
    ],
    projects: [],
    currentProject: {},
    currentTask: {
      record: {},
      comments: [],
      files: [],
      collaborators: [],
      owners: [],
      tags: [],
      changelog: [],
      subtasks: [],
      riskItems: [],
      controls: [],
      formalFindings: [],
      penTestFindings: [],
      myControls: [],
      evidence: [],
      uploadUsers: [],
    },
    taskSorting: {},
    taskSearch: null,
    taskFilters: {
      group: [],
      criticality: [],
      status: [],
      tag: [],
      taskTypes: [],
      user: [],
      // assessment: [],
      dueDateAfter: null,
      dueDateBefore: null,
    },
    groups: [],
    tasks: {
      currentPage: 1,
      data: [],
      numberPages: 1,
      perPage: 1e5,
      totalCount: 0,
    },
    allTasks: [],
    frontendPaginatedData: {
      currentPage: 1,
      perPage: 5,
    },
    availablePeriods: [
      { value: 'D', text: 'Day(s)' },
      { value: 'W', text: 'Week(s)' },
      { value: 'M', text: 'Month(s)' },
      { value: 'Q', text: 'Quarter(s)' },
      { value: 'Y', text: 'Year(s)' },
    ],
    getRowNumber(index) {
      return (
        this.frontendPaginatedData.perPage *
          (this.frontendPaginatedData.currentPage - 1) +
        (index + 1)
      )
    },
    getCriticalityVariant(criticality) {
      return this.criticalities.find((c) => c.val == criticality).variant
    },
    projectsCriticalityBubbleInfo(tasks) {
      return this.criticalities.map((c) => {
        return {
          key: c.val || 'not_set',
          value: tasks.filter((task) => task.criticality === c.val).length,
          subvalue: `${tasks
            .filter((task) => task.criticality === c.val)
            .reduce((t, i) => t + i.hours_to_complete, 0)} hours`,
          variant: c.variant,
        }
      })
    },
  },
}
