<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-labelledby="create-extension-label",aria-hidden="true")
    //- div.modal-dialog(role="document")
    div.modal-dialog.modal-lg(role="document")
      div.modal-content
        form(@submit="updateMapping")
          div.modal-header
            h5.modal-title Extension Mapping
            button.close(
              type="button"
              data-dismiss="modal"
              aria-label="Close")
                span(aria-hidden="true") &times;
          div.modal-body
            loader(v-if="isLoadingLocal")
            template(v-else)
              div.card.no-shadow.border
                div.card-header.header-tabs
                  ul.nav.nav-tabs.card-header-tabs
                    li.nav-item
                      a.nav-link(
                        :class="tabNavClass('config')"
                        @click="activeTab = 'config'") Configuration
                    li.nav-item
                      a.nav-link(
                        :class="tabNavClass('mappings')"
                        @click="activeTab = 'mappings'") Mappings
                div.card-body(v-if="activeTab === 'config'")
                  div.form-group
                    div Extension
                    div.strong {{ currentMapping.name }}
                  div.form-group(v-if="currentMapping.description")
                    div Description
                    div.strong {{ currentMapping.description }}

                  div.form-group
                    div.custom-control.custom-toggle.custom-toggle-sm
                      input#is-mapping-enabled.custom-control-input(
                        type="checkbox"
                        v-model="currentMapping.is_enabled")
                      label.custom-control-label(for="is-mapping-enabled")
                        | Turned on? #[i#mapping-enabled-tooltip.fal.fa-info-circle]
                        b-tooltip(target="mapping-enabled-tooltip")
                          | If you turn off the extension, any trigger that would automate
                          | its execution would no longer trigger it. The extensions can still be executed manually.
                          | You can turn extensions on and off whenever you'd like to ensure
                          | the appropriate extensions are executing as needed.

                  extension-mapping-configuration.border.no-shadow.mt-4(
                    v-if="currentMapping.configs && currentMapping.configs.length > 0"
                    :configs="currentMapping.configs"
                    v-model="currentMapping.env_config")
                div.card-body(v-if="activeTab === 'mappings'")
                  record-mapping-list-editor(v-model="currentMapping.record_maps")
          div.modal-footer
            button.btn.btn-secondary(
              type="button"
              data-dismiss="modal") Cancel
            button.btn.btn-primary(type="submit") Save
</template>

<script>
  import $ from 'jquery'
  import ExtensionMappingConfiguration from './ExtensionMappingConfiguration'
  import RecordMappingListEditor from './RecordMappingListEditor'
  import ApiExtensions from '../../../factories/ApiExtensions'
  import {
    deleteSecretsFromConfig,
    getEnvConfigFromMapping,
  } from '../../../factories/ConnectedAppHelpers'
  export default {
    props: {
      mappingId: { type: [Number, String], default: null },
    },

    data() {
      return {
        activeTab: 'config',
        currentMapping: { configs: [] },
        isLoadingLocal: false,
      }
    },

    methods: {
      tabNavClass(type = 'config') {
        let isActive = type === this.activeTab
        return isActive ? 'active' : ''
      },

      async getMappingAndExtension() {
        try {
          this.isLoadingLocal = true
          const { mapping } = await ApiExtensions.getMapping(this.mappingId)
          this.currentMapping = getEnvConfigFromMapping(mapping)
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.isLoadingLocal = false
        }
      },

      async updateMapping(evt) {
        try {
          evt.preventDefault()
          const requiredConfigsAnswered = (
            this.currentMapping.configs || []
          ).reduce((check, conf) => {
            if (!check) return false
            if (!conf.is_required) return true
            if (!this.currentMapping.env_config[conf.variable]) return false
            return true
          }, true)

          if (!requiredConfigsAnswered) {
            return window.toastr.error(
              `Please make sure all required config variables have info in them.`
            )
          }

          // don't update secret values if they weren't changed from the default secret ('*****')
          this.currentMapping = deleteSecretsFromConfig(this.currentMapping)

          const { id } = await ApiExtensions.mapExtension(this.currentMapping)
          this.$emit('update', { ...this.currentMapping, id })
          $(`#${this.$el.id}`).modal('toggle')
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    async mounted() {
      $(`#${this.$el.id}`).on(
        'show.bs.modal',
        () => (this.isLoadingLocal = true)
      )
      $(`#${this.$el.id}`).on('shown.bs.modal', async () => {
        if (this.mappingId) await this.getMappingAndExtension()
      })
    },

    components: {
      ExtensionMappingConfiguration,
      RecordMappingListEditor,
    },
  }
</script>
