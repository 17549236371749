<template lang="pug">
  div.row(v-if="!isLoadingLocal")
    div.col-12(v-if="!potentialFrameworks || potentialFrameworks.length == 0")
      i.small No potential framework progress found..
    div.col-2(v-else, v-for="framework in potentialFrameworks")
      doughnut.doughnut(
        :chart-data="frameworkChartData(framework)",
        :options="{ plugins: {tooltip: { enabled: true }, legend: { display: false }}, cutout: '80%' }")
      p.mt-1.mb-0.text-center
        b {{ getEngagementType(framework.engagement_type) }} (~{{ ((framework.completed / framework.total) * 100).toFixed(0) }}%)
</template>

<script>
  import { mapState } from 'vuex'
  import ApiAssessments from '../../factories/ApiAssessments'
  import Doughnut from './chartjs/Doughnut'

  export default {
    data() {
      return {
        isLoadingLocal: true,
        projects: [],
      }
    },

    computed: {
      ...mapState({
        engagementTypes: (state) => state.assessments.engagementTypes,
        frameworkKpis: (state) => state.assessments.frameworkKpis,
        variantColors: (state) => state.dashboard.variantColors(),
      }),

      potentialFrameworks() {
        return this.frameworkKpis.filter((kpi) => {
          return (
            kpi.engagement_type &&
            !this.projects.find((project) => {
              return (
                project.project_type != 'template' &&
                project.status == 'active' &&
                project.engagement_type == kpi.engagement_type
              )
            })
          )
        })
      },
    },

    methods: {
      getEngagementType(type) {
        return type
          ? (this.engagementTypes.find((t) => t.key === type) || {}).text ||
              'Unknown'
          : 'N/A'
      },

      frameworkChartData(framework) {
        return {
          labels: ['Complete', 'Incomplete'],
          datasets: [
            {
              label: 'Status',
              backgroundColor: [
                this.variantColors['success'],
                this.variantColors['default'],
              ],
              borderColor: '#fff',
              data: [
                framework.completed,
                framework.total - framework.completed,
              ],
            },
          ],
        }
      },

      async getProjects() {
        try {
          const { projects } = await ApiAssessments.getProjects()
          this.projects = projects
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    async created() {
      try {
        await Promise.all([
          this.getProjects(),
          this.$store.dispatch('getEngagementTypes', true),
          this.$store.dispatch('getFrameworkKpis'),
        ])
      } finally {
        this.isLoadingLocal = false
      }
    },

    components: {
      Doughnut,
    },
  }
</script>
