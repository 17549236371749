<template lang="pug">
  - // .no-shadow
  div.card.card-small
    div.card-header.border-bottom
      div.small.d-flex.align-items-center
        strong Most Vulnerable Hosts (top {{ numberHosts }})
        div.ml-auto
          select.form-control.form-control-sm(v-model="numberHosts",@change="getSummary()")
            option 5
            option 10
            option 15
            option 20
            option 25
            option 50
    div.card-body.p-3
      div(v-if="isLoadingLocal")
        loader
      div(v-else)
        div.text-center.p-2(v-if="hosts.length === 0")
          i No hosts yet to report on.
        div(v-if="hosts.length > 0",v-for="host in hosts")
          div
            a(v-if="hostLink",href="javascript:void(0)",@click="executeHostLink(host)") {{ getHostText(host) }}
            strong(v-else) {{ getHostText(host) }}
          div.progress
            div.progress-bar.status(v-if="getTotalByHostAndSeverity(host, severity) !== 0",v-for="severity in severities",:id="'host-summary-progress-' + host + '-' + severity",role="progressbar",:class="severityBgClassMap(severity)",:style="{ width: getSeverityWidthPercentage(host, severity) + '%' }")
          b-tooltip(v-for="(severity, ind) in severities",:key="ind",:target="'host-summary-progress-' + host + '-' + severity") {{ titleCase(severity, true) }}: {{ getTotalByHostAndSeverity(host, severity) }}
        div.row.mt-4
          div.col-sm-6.mb-1(v-for="severity in severities")
            div.row.no-gutters
              div.col-3
                div.mr-2.legend-bubble.status(:class="severityBgClassMap(severity)")
              div.col-9
                div.small.overflow-ellipses.d-flex.align-items-center
                  small {{ titleCase(severity, true) }}
</template>

<script>
  import ApiVulnerability from '../../../../factories/ApiVulnerability'
  import StringHelpers from '../../../../factories/StringHelpers'

  export default {
    props: {
      num: { type: Number, default: null },
      hostLink: {
        type: Function,
        default: (host, info) =>
          window.vueRouter.push(
            `/vulnerability/${info.vulnerability_scan_id}?host=${info.ip_address}`
          ),
      },
    },

    data() {
      return {
        isLoadingLocal: true,
        numberHosts: 10,
        summaryData: null,
      }
    },

    computed: {
      hosts() {
        return Object.keys(this.summaryData || {}).sort((h1, h2) => {
          return (
            parseInt((this.summaryData[h2][0] || {}).total_weight) -
            parseInt((this.summaryData[h1][0] || {}).total_weight)
          )
        })
      },

      severities() {
        let severities = this.$store.state.vulnerability.severities.slice(0)
        severities.splice(severities.indexOf('informational'), 1)
        return severities
      },
    },

    methods: {
      titleCase: StringHelpers.titleCase,
      truncateString: StringHelpers.truncateString,

      getHostText(host) {
        return `${this.truncateString(host, 20)}: (${this.getTotalByHost(
          host
        )})` // ${(this.summaryData[host][0] || {}).total_weight}
      },

      async executeHostLink(host) {
        await this.hostLink(host, this.summaryData[host][0])
      },

      getSeverityWidthPercentage(host, severity) {
        if (!this.summaryData) return 0

        const hostSevData =
          this.summaryData[host].find((rec) => rec.severity === severity) || {}
        return Math.ceil(
          (parseInt(hostSevData.count || 0) / this.getTotalByHost(host)) * 100
        )
      },

      getTotalByHost(host) {
        return (this.summaryData || {})[host].reduce(
          (total, info) => total + parseInt(info.count || 0),
          0
        )
      },

      getTotalByHostAndSeverity(host, severity) {
        if (!this.summaryData) return 0

        let hostData = (this.summaryData || {})[host]
        let infoIndex = hostData.findIndex(
          (r) => r.severity === 'informational'
        )
        if (infoIndex > -1) hostData.splice(infoIndex, 1)
        return (
          (hostData.find((rec) => rec.severity === severity) || {}).count || 0
        )
      },

      severityBgClassMap(severity) {
        return `bg-transparent-dark-${severity}`
      },

      async getSummary() {
        try {
          const response = await ApiVulnerability.getDashboardHostSummary(
            this.numberHosts
          )
          this.summaryData = response.summary
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    async mounted() {
      this.numberHosts = this.num || this.numberHosts
      await this.getSummary()
      this.isLoadingLocal = false
    },
  }
</script>
