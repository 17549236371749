<template lang="pug">
  div.stats-small.stats-small--1.card.card-small
    div.card-body.p-0.d-flex
      div.d-flex.flex-column.m-auto
        div.stats-small__data.text-center
          span.stats-small__label.text-uppercase {{ title }}
          h6.stats-small__value.count.my-3(:class="addClass") {{ valueWithThousandsSeparator(value) }}
        div.stats-small__data
          span.stats-small__percentage(v-if="trendPercentage",:class="increaseOrDecreaseClass") {{ abs(trendPercentage) }}%
      canvas.blog-overview-stats-small-1(height='120',v-if="graphData")
</template>

<script>
  export default {
    props: {
      title: { type: String },
      value: { type: [ Number, String ] },
      graphData: { type: Array, default: null },
      trendPercentage: { type: Number, default: null },
      addClass: { type: [ Array, String ], default: null }
    },

    computed: {
      increaseOrDecreaseClass() {
        return (this.trendPercentage > 0)
          ? 'stats-small__percentage--increase'
          : 'stats-small__percentage--decrease'
      }
    },

    methods: {
      abs(num) {
        return Math.abs(num)
      },

      valueWithThousandsSeparator(num) {
        return num.toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
    }
  }
</script>


<style scoped lang="scss">
  .text-large {
    font-size: 3.5rem !important;
  }

  .border {
    border: 1px solid;
  }
</style>
