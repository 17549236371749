<template lang="pug">
  div.d-flex.align-items-center.justify-content-center.circle-group(:style="cssVars")
    user-avatar(
      v-for="(userId, ind) in userIds",
      :id="`user-avatar-group-${_uid}-${userId}`",
      :key="`user-avatar-group-${_uid}-${userId}-${ind}`"
      :user-id="userId",
      :max-height="maxHeight",
      :height="height",
      :should-show-close="shouldShowClose",
      @close="closeUser")
</template>

<script>
  export default {
    props: {
      userIds: { type: Array, required: true },
      maxHeight: { type: String, default: null },
      height: { type: String, default: null },
      shouldShowClose: { type: Boolean, default: false },
      groupMaxWidth: { type: Number, default: 150 },
    },

    computed: {
      cssVars() {
        const minMargin = -15 // The icons are barely overlapping at this point
        const maxMargin = -6 // Want to have some overlap in icons
        const maxWidth = this.groupMaxWidth
        const count = this.userIds.length
        const width =
          parseInt(this.height.split('px')[0]) ||
          parseInt(this.maxHeight.split('px')[0]) ||
          30
        // overlapping circles like CCCCCO so total width is one full width plus what's showing of others
        // totalWidth = width + (count - 1) * (width + margin)
        // margin = (maxWidth - width) / (count - 1) - width
        const eachWidth = (maxWidth - width) / (count - 1)
        let margin = eachWidth - width

        if (margin < minMargin) margin = minMargin
        if (margin > maxMargin) margin = maxMargin

        const calculatedWidth = width + (count - 1) * (width + margin)

        let varObject = {
          '--leftMargin': margin + 'px',
          'max-width': maxWidth + 'px',
          'margin-left': -1 * margin + 'px',
        }
        if (calculatedWidth > maxWidth) varObject['flex-wrap'] = 'wrap'
        return varObject
      },
    },

    methods: {
      closeUser(userId) {
        this.$emit('close', userId)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .circle-group > * {
    margin-left: var(--leftMargin);
  }
</style>
