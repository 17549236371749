<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-hidden="true")
    div.modal-dialog(role="document")
      div.modal-content
        div.modal-header
          h5.modal-title {{ event && event.title ? event.title : 'New Calendar Event' }}
          button.close(type="button",data-dismiss="modal",aria-label="Close",@click="closeModal")
            span(aria-hidden="true") &times;
        div.modal-body.d-flex.flex-column.justify-content-center.p-0(style="min-height: 200px")
          div.mx-4.mt-4(v-if="canFindExisting")
            v-select(
              :options="calendarEvents"
              placeholder="Select existing calendar event..."
              v-model="selectedEvent"
              :get-option-label="e => `(${getFormattedDate(e.start)}) ${e.title}`")
            div.text-center.mt-5(v-if="selectedEvent")
              button.btn.btn-primary(@click="clickSelected") Select Event
          template(v-if="!selectedEvent")
            template(v-if="canFindExisting")
              hr.w-100
              div.mx-4
                strong OR create new event:
            calendar-event-container(
              :event="event"
              @update="closeModal")
</template>

<script>
  import $ from 'jquery'
  import Utilities from '@risk3sixty/utilities'
  import { mapState } from 'vuex'
  import CalendarEventContainer from './CalendarEventContainer'
  import TimeHelpers from '../../factories/TimeHelpers'

  const moment = Utilities.Dates.moment

  export default {
    props: {
      canFindExisting: { type: Boolean, default: null },
      event: { type: Object, default: () => {} },
    },

    data() {
      return {
        selectedEvent: null,
      }
    },

    computed: mapState({
      isManager: (_, getters) => getters.isManager,
      calendarEvents: (state) => state.compliance.calendar.events,
    }),

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,

      eventUpdated(event) {
        this.$emit('updated', event)
        this.closeModal()
      },

      closeModal() {
        this.$emit('hide')
        $(`#${this.$el.id}`).modal('hide')
      },

      clickSelected() {
        this.$emit('select', this.selectedEvent)
        this.closeModal()
      },
    },

    async mounted() {
      if (this.canFindExisting) {
        this.$store.commit('SET_CALENDAR_DATES', {
          endDate: moment().utc().endOf('day').add(6, 'months').toDate(),
        })

        if (this.isManager)
          this.$store.commit('SET_CALENDAR_SHOW_ALL_EVENTS', true)

        await this.$store.dispatch('getCalendarEvents')
      }
    },

    components: {
      CalendarEventContainer,
    },
  }
</script>

<style lang="scss" scoped>
  .modal-dialog {
    min-width: 30vw;
    max-width: 40vw;
    min-height: 75%;
  }
</style>
