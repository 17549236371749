<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-hidden="true")
    div.modal-dialog(role="document")
      div.modal-content
        form(@submit="mapItem")
          div.modal-header
            h5.modal-title Link Formal Finding to {{ entityName }}
            button.close(type="button",data-dismiss="modal",aria-label="Close")
              span(aria-hidden="true") &times;
          div.modal-body
            div.form-group
              h6 Formal Findings:
              div(v-if="isLoadingFindings")
                loader.small
              div(v-else)
                select.form-control.form-control-sm(v-model="finding")
                  option(:value="null") Select finding...
                  option(v-for="f in allFindings",:value="f") {{ stripHtmlTags(f.summary || '') }}
            div(v-if="linkControls && finding && finding.controls.length > 0")
              p This will also link the following controls associated to the selected Formal Finding:
              li(v-for="control in finding.controls") <strong>{{control.control_number}}</strong> - {{control.name}}
          div.modal-footer
            button.btn.btn-secondary(type="button",data-dismiss="modal") Cancel
            button.btn.btn-primary(type="submit") Link
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import ApiAssessments from '../../factories/ApiAssessments'
  import Utilities from '@risk3sixty/utilities'

  export default {
    props: {
      entityName: { type: String, required: true },
      linkControls: { type: Boolean, default: true }, // Let the user know that associated controls will be linked as well
    },

    data() {
      return {
        allFindings: [],
        finding: null,
        isLoadingFindings: true,
      }
    },

    computed: mapState({}),

    methods: {
      stripHtmlTags: Utilities.Strings.stripHtmlTags,

      async getFindings() {
        try {
          const { findings } =
            await ApiAssessments.getFormalFindingGlobalTemplates()
          this.allFindings = findings
        } catch (err) {
          window.toastr.error(
            `Error fetching Template Formal Findings. Please try again.`
          )
        } finally {
          this.isLoadingFindings = false
        }
      },

      async mapItem(evt) {
        try {
          evt.preventDefault()

          if (!this.finding)
            return window.toastr.error(`Please select a finding to link.`)

          this.$emit('selected', {
            table: 'assessment_formal_findings',
            entityId: this.finding.id,
            summary: this.finding.summary,
          })
          $(`#${this.$el.id}`).modal('hide')
          this.selectedId = null
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    mounted() {
      $(`#${this.$el.id}`).on('shown.bs.modal', async () => {
        await this.getFindings()
      })
    },
  }
</script>
