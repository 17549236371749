<template lang="pug">
  div.d-flex.justify-content-center
    select-dropdown-filter(
      v-if="canEdit"
      v-model="mutableVal",
      :multi="false",
      :text="field.key",
      :options="enumOptions"
      boundary="scrollParent")
    div(v-else) {{ currentValText }}
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    props: {
      value: { type: String, default: null },
      field: { type: Object, required: true },
      row: { type: Object, required: true },
      canEdit: { type: Boolean, default: true },
    },

    computed: {
      ...mapState({
        currentEnum(state) {
          return state.team.enums.find(
            (e) => e.id == this.field.enum_options_id
          )
        },
      }),

      currentValText() {
        if (!this.currentEnum) return
        const val =
          this.currentEnum.values.find((v) => v.id == this.mutableVal) || {}
        return val.text || val.value
      },

      enumOptions() {
        if (!this.currentEnum) return []
        return this.currentEnum.values.map((v) => ({
          text: v.text || v.value,
          value: v.id.toString(),
          variant: v.variant,
        }))
      },

      mutableVal: {
        get() {
          return this.value
        },

        set(newVal) {
          this.$emit('input', newVal)
        },
      },
    },

    async created() {
      await this.$store.dispatch('getTeamEnums')
    },
  }
</script>
