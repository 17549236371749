<template lang="pug">
  quill-editor(
    ref="quillEditor",
    v-model="dynamicValue",
    :options="editorOptions",
    @ready="editorReady",
    @blur="onBlur")
</template>

<script>
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'

  import $ from 'jquery'
  import Quill from 'quill'
  import { mapState } from 'vuex'
  import { ImageDrop } from 'quill-image-drop-module'
  import { quillEditor } from 'vue-quill-editor'

  import 'quill-mention'
  import 'quill-mention/dist/quill.mention.css'

  Quill.debug('error')

  // https://quilljs.com/guides/how-to-customize-quill/
  const Size = Quill.import('attributors/style/size')
  Size.whitelist = ['10px', '24px', '32px', '48px']
  Quill.register(Size, true)
  Quill.register('modules/imageDrop', ImageDrop)

  // Using inline styles for Quill indent instead of classes
  // https://github.com/quilljs/quill/issues/1930#issuecomment-453593070
  class IndentAttributor extends Quill.import('parchment').Attributor.Style {
    multiplier = 2

    constructor(name, style, params) {
      super(name, style, params)
    }

    add(node, value) {
      return super.add(node, `${value * this.multiplier}rem`)
    }

    value(node) {
      return parseFloat(super.value(node)) / this.multiplier || undefined
    }
  }

  export default {
    props: {
      options: { type: Object, default: () => ({}) },
      value: { type: String, default: null },
      onlyExternalUsers: { type: Boolean, default: null },
      users: { type: Array, default: () => null },
    },

    computed: {
      ...mapState({
        allMentionUsers: function mentionUsers(state) {
          const stateUserKey = this.onlyExternalUsers
            ? 'users'
            : 'teamAndInternalUsers'
          return (this.users || state.team[stateUserKey]).map((u) => {
            return { id: u.email || u.username_email, value: u.name }
          })
        },
      }),

      // TODO: 20200826 - Use data prop and watcher because there's something
      // fucking bizarre happening with the getters changing the value
      // but the setter never fires to emit an update to the parent.
      // dynamicValue: {
      //   get() {
      //     console.log('VAL', this.value)
      //     return this.value
      //   },
      //   set(newVal) {
      //     this.$emit('input', newVal)
      //     console.log('VALUPDATE', this.value)
      //   },
      // },
    },

    watch: {
      value(val) {
        this.dynamicValue = val
      },

      dynamicValue(newVal) {
        this.$emit('input', newVal)
      },
    },

    data() {
      return {
        dynamicValue: this.value,
        quillInstance: null,

        editorOptions: {
          ...this.options,

          modules: {
            imageDrop: true,
            mention: {
              allowedChars: /^[A-Za-z\d\s@_]*$/,
              mentionDenotationChars: ['@'],
              spaceAfterInsert: true,
              source: (searchTerm, renderList) => {
                if (!(this.allMentionUsers && this.allMentionUsers.length > 0))
                  return

                if (searchTerm.length === 0)
                  return renderList(this.allMentionUsers, searchTerm)

                const matchingValues = this.allMentionUsers.filter((user) => {
                  return (
                    user.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    user.value.toLowerCase().includes(searchTerm.toLowerCase())
                  )
                })
                renderList(matchingValues, searchTerm)
              },

              onSelect: (item, insertItem) => {
                this.$emit('mentionReplaced', item)
                insertItem(item)
              },
            },

            toolbar: this.options.toolbar || [
              [{ size: ['10px', false, '24px', '32px', '48px'] }], // custom dropdown
              // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

              ['bold'],
              ['italic'],
              ['underline'],
              ['strike'], // toggled buttons
              // ['blockquote', 'code-block'],

              // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
              [{ list: 'ordered' }, { list: 'bullet' }],
              // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
              [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
              // [{ 'direction': 'rtl' }],                         // text direction

              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              // [{ 'font': [] }],
              [{ align: [] }],

              ['clean'], // remove formatting button
              ['link'],
            ],
          },
        },
      }
    },

    methods: {
      createCustomIndent() {
        const levels = [1, 2, 3, 4, 5]
        const multiplier = 2
        const indentStyle = new IndentAttributor('indent', 'margin-left', {
          scope: Quill.import('parchment').Scope.BLOCK,
          whitelist: levels.map((value) => `${value * multiplier}rem`),
        })

        Quill.register(indentStyle)
      },

      editorReady(quillInstance) {
        // https://github.com/quilljs/quill/issues/110
        // disable tabs and treat them the same way as
        // other input elements to tab through to the next control
        delete quillInstance.getModule('keyboard').bindings['9']
        this.quillInstance = quillInstance

        // https://mattdyor.wordpress.com/2017/01/28/skipping-over-quill-buttons-with-tabs/
        // disable focus support on toolbar buttons
        $('.ql-toolbar').find(':button,.ql-picker-label').attr('tabindex', '-1')
      },

      focus(scroll = false) {
        this.quillInstance.setSelection(this.quillInstance.getLength(), 0)

        if (scroll) {
          this.$refs.quillEditor.$el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      },

      onBlur() {
        this.$emit('blur')
      },

      enableSanitizedLinks() {
        // From https://stackoverflow.com/questions/41696056/quill-link-handler-not-working
        // In a routed Vue view, the link can get intercepted and routed within Vue Router
        // Adding '\\' to the beginning of the URL indicates an external link and escapes the routing
        var Link = Quill.import('formats/link')
        var builtInFunc = Link.sanitize
        Link.sanitize = function customSanitizeLinkInput(linkValueInput) {
          var val = linkValueInput

          const startsWithSlashesRegex = /^\/\//
          const startsWithHttpRegex = /^http/
          // Add "//" to beginning of link if it doesn't start with http to prevent Vue Router from intercepting
          if (
            !startsWithSlashesRegex.test(val) &&
            !startsWithHttpRegex.test(val)
          ) {
            val = `//${val}`
          }

          return builtInFunc.call(this, val) // retain the built-in logic
        }
      },
    },

    created() {
      this.createCustomIndent()
      this.enableSanitizedLinks()
    },

    components: {
      quillEditor,
    },
  }
</script>

<style lang="scss">
  // Customizing content of toolbar without
  // needing to update the HTML
  // https://github.com/KillerCodeMonkey/ngx-quill/issues/181#issuecomment-389898910
  .ql-tooltip {
    z-index: 10;
    left: unset !important; // To prevent 'edit' box for adding a link from overflowing and disappearing from the card
  }

  .ql-picker {
    &.ql-size {
      .ql-picker-item[data-value='10px']::before {
        content: '10px';
        font-size: 10px !important;
      }

      .ql-picker-item[data-value='24px']::before {
        content: '24px';
        font-size: 24px !important;
      }

      .ql-picker-item[data-value='32px']::before {
        content: '32px';
        font-size: 32px !important;
      }

      .ql-picker-item[data-value='48px']::before {
        content: '48px';
        font-size: 48px !important;
      }
    }
  }
</style>
