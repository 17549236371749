<template lang="pug">
  b-dropdown(
    variant="link"
    toggle-class="p-0 text-decoration-none"
    boundary="viewport"
    no-caret)
      template(v-slot:button-content)
        a.nav-link.drop-down(:class="dropdownClass") {{dropwdownText}} #[i.fas.fa-caret-down]
      b-dropdown-item.pr-0(v-for="(link, index) in links" :key="`${link.path}-${index}`")
        router-link(:to="link.path") {{link.text}}

</template>

<script>
  export default {
    props: {
      dropdownClass: {
        type: String,
        default: '',
      },
      dropwdownText: {
        type: String,
        required: true,
      },
      links: {
        type: Array,
        default: () => [], //ie. [{text: "Path name", path: "/path/value"}]
      },
    },
    data() {
      return {
        showDropdown: false,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .nav-link.drop-down {
    font-weight: bold;
  }
</style>
