<template lang="pug">
  user-avatar-editable-group(
    :owners="owners",
    @add="addOwner(project, ...arguments)",
    @delete="deleteOwner(project, ...arguments)")
</template>

<script>
  import ApiProjects from '../../factories/ApiProjects'

  export default {
    props: {
      owners: { type: Array, default: null },
      project: { type: Object, required: true },
    },

    methods: {
      async addOwner(project, newUserId) {
        try {
          const currentOwners = project.owners || []
          const newUserIds = [
            ...new Set([
              ...currentOwners.map((o) => o.user_id),
              parseInt(newUserId),
            ]),
          ]
          await ApiProjects.updateProjectOwners(project.id, newUserIds)
          this.$emit('updated')
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async deleteOwner(project, user) {
        try {
          await ApiProjects.removeProjectOwner(project.id, user)
          this.$emit('updated')
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },
  }
</script>
