<template lang="pug">
  div.card
    div.card-body
      h5(v-if="title") {{ title }}
      form(@submit="sendCompleteSchedule")
        div.form-group
          label(for="schedule-frequency-type") Frequency Type
          select#schedule-frequency-type.form-control(v-model="models.frequencyType",@change="sendItem('frequencyType')")
            option(v-for="freq in possibleFrequencyTypes",:value="freq") {{ titleCase(freq) }}
        template(v-if="models.frequencyType === 'recurring'")
          div.form-group
            label(for="schedule-frequency") Frequency
            select#schedule-frequency.form-control(v-model="models.frequency",@change="sendItem('frequency')")
              option(v-for="freq in possibleFrequencies",:value="freq") {{ titleCase(freq) }}
          div.form-group
            label(for="schedule-day-of-week") Day of Week
            select#schedule-day-of-week.form-control(v-model="models.dayOfWeek",@change="sendItem('dayOfWeek')")
              option(v-for="day in daysOfWeek",:value="day.val") {{ day.text }}
        datepicker-shards.mb-4(
          input-id="start-date"
          label="Start Date (keeping empty means start immediately)"
          placeholder="Click to select start date"
          v-model="models.startDate"
          @changedWithKey="sendItem('startDate')")
        datepicker-shards(
          v-if="models.frequencyType === 'recurring'"
          input-id="end-date"
          label="End Date (keeping empty means never end)"
          placeholder="Click to select end date"
          v-model="models.endDate"
          @changedWithKey="sendItem('endDate')")
        - //div.form-group.text-center
        - //  button.btn.btn-primary(type="submit")
        - //    span Submit
        div.alert.alert-danger.mt-2(v-if="!!error") {{ error }}
</template>

<script>
  import StringHelpers from '../../factories/StringHelpers'

  export default {
    props: {
      title: { type: String, default: 'Set Schedule' },
      schedule: { type: Object, default: null },
    },

    watch: {
      schedule(newSchedule) {
        this.models = { ...this.models, ...newSchedule }
      },
    },

    data() {
      return {
        error: null,

        models: {
          frequencyType: 'one-time',
          frequency: null,
          dayOfWeek: null,
          startDate: null,
          endDate: null,
        },

        possibleFrequencies: ['daily', 'weekly', 'monthly', 'quarterly'],

        possibleFrequencyTypes: ['one-time', 'recurring'],

        daysOfWeek: [
          { text: 'Sunday', val: 0 },
          { text: 'Monday', val: 1 },
          { text: 'Tuesday', val: 2 },
          { text: 'Wednesday', val: 3 },
          { text: 'Thursday', val: 4 },
          { text: 'Friday', val: 5 },
          { text: 'Saturday', val: 6 },
        ],
      }
    },

    methods: {
      titleCase: StringHelpers.titleCase,

      sendCompleteSchedule(evt) {
        evt.preventDefault()
        this.$emit('sendSchedule', this.models)
      },

      sendItem(item) {
        this.$emit('sendItem', item, this.models[item])
      },
    },
  }
</script>

<style scoped lang="scss">
  .card {
    border: 1px solid #f5f6f7;
    box-shadow: none;
    -webkit-box-shadow: none;

    .card-body {
      padding: 1rem;
    }
  }
</style>
