<template lang="pug">
  div.card.card-small
    div.card-header.border-bottom
      div.small
        router-link(to="/assessments")
          strong Active Assessment Request List
    ul.list-group.list-group-flush
      li.list-group-item(v-if="isLoadingLocal")
        loader
      li.clickable.list-group-item.hover-fade.py-2.px-3(
        v-else-if="requests.totalCount > 0",
        v-for="(request, ind) in requests.data",
        :class="requestStatusContextClass(request)",
        @click="goToRequest(request)")
          div.row
            div.col-lg-8
              div.overflow-ellipses.no-hover.small {{ request.project_name }}
              h6.m-0(:id="`assessment-request-list-name-${ind}`")
                div.w-100.overflow-ellipses.no-hover #[strong {{ request.project_ref_num }}]. {{ request.name }}
              b-tooltip(:target="`assessment-request-list-name-${ind}`")
                | {{ request.name }}

              //- div.small(v-if="request.description",:id="'assessment-request-list-desc-' + ind")
              //-   div.overflow-ellipses.no-hover
              //-     div(v-html="request.description")
              //- b-tooltip(
              //-   :target="'assessment-request-list-desc-' + ind",
              //-   v-html="request.description")

            div.col-lg-4.text-right.text-soft.small
              div
                assessment-request-due-date-updater(:request="request")
              div(:class="`text-${requestStatusContextClass(request)}`")
                | {{ titleCase(request.status, true) }}
      li.list-group-item(v-else)
        small
          i No active requests that require action assigned to you!
    div.card-footer.border-top
      pagination(
        size="sm",
        :info="requests",
        @changePage="changePage",
        @changePerPage="changePerPage")
</template>

<script>
  import ApiAssessments from '../../../../factories/ApiAssessments'
  import ApiTeams from '../../../../factories/ApiTeams'
  import StringHelpers from '../../../../factories/StringHelpers'
  import TimeHelpers from '../../../../factories/TimeHelpers'

  export default {
    data() {
      return {
        isLoadingLocal: true,
        requests: {
          currentPage: 1,
          data: [],
          numberPages: 1,
          perPage: 5,
          totalCount: 0,
        },
      }
    },

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,
      titleCase: StringHelpers.titleCase,

      async goToRequest(req) {
        await ApiTeams.switch(
          this,
          req.team_id,
          encodeURIComponent(
            `/assessments/evidence/${req.project_id}/${req.id}`
          )
        )
      },

      requestStatusContextClass({ project_id, status }) {
        const { rawStatuses } = this.$store.state.assessmentStatusesAndColors(
          project_id
        )
        return (
          (rawStatuses.find((s) => s.value === status) || {}).variant ||
          'primary'
        )
      },

      async changePage(newPage) {
        this.requests.currentPage = newPage
        await this.getAssignedRequests()
      },

      async changePerPage(newPerPage) {
        this.requests.perPage = newPerPage
        await this.getAssignedRequests()
      },

      async getAssignedRequests() {
        try {
          this.requests = await ApiAssessments.getRequestsAssignedToUser(
            this.requests.currentPage,
            this.requests.perPage
          )
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.isLoadingLocal = false
        }
      },
    },

    async created() {
      await Promise.all([
        this.$store.dispatch('getAssessmentStatuses'),
        this.getAssignedRequests(),
      ])
    },
  }
</script>
