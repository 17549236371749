<template lang="pug">
  li.nav-item.dropdown.notifications(v-show="users && users.length > 0")
    user-avatar-group(
      :user-ids="users.map(u => u.userId)",
      max-height="25",
      height="30px",
      :should-show-close="false")
    //- a.nav-link.nav-link-icon.text-center(role='button', data-toggle='dropdown', aria-haspopup='true', aria-expanded='false')
    //-   div.nav-link-icon__wrapper
    //-     i.fal.fa-users-class
    //-     span.badge.badge-pill.badge-danger(v-show="users.length > 0") {{ users.length }}
    //- div.dropdown-menu.dropdown-menu-small(aria-labelledby='dropdownMenuLink')
    //-   div.dropdown-item.notification__all(style="border-radius: 0px")
    //-     | The following users are on this page with you:
    //-   div.dropdown-item(v-if="users.length > 0",v-for="(user, index) in users")
    //-     div.notification__icon-wrapper
    //-       div.notification__icon
    //-         i.material-icons face
    //-     div.notification__content.d-flex.align-items-center.w-100
    //-       span.notification__category.mb-0
    //-         div {{ user.userName || user.userEmail }}
    //-         div.small(v-if="user.userName") {{ user.userEmail }}
    //-         div.small {{ getFormattedDate(user.date, 'MMMM D, YYYY h:mm a') }}
    //-       //- p.overflow-ellipses.no-hover(style="max-width: 320px",v-html="alert.description")
    //-     div.nowrap.small.notification__content.d-flex.align-items-center
    //-       span.notification__category.mb-0 {{ user.count }} windows
    //-   div.dropdown-item.d-flex.align-items-center(v-else)
    //-     div.notification__icon-wrapper
    //-       div.notification__icon
    //-         i.material-icons highlight_off
    //-     div.notification__content
    //-       span.notification__category
    //-         i No users on this page with you...
</template>

<script>
  import { mapState } from 'vuex'
  import Utilities from '@risk3sixty/utilities'
  // import R3sSocket from '../factories/R3sSocket'
  import TimeHelpers from '../factories/TimeHelpers'

  const arrayGroupBy = Utilities.Arrays.arrayGroupBy

  export default {
    computed: mapState({
      users: (state) => {
        let groupedUsers = arrayGroupBy(state.usersInPage, (u) => u.userEmail)
        delete groupedUsers[state.auth.user.username_email]

        const userValues = Object.values(groupedUsers)
        return userValues.map((ary) => {
          return {
            ...ary[0],
            count: ary.length,
          }
        })
      },
    }),

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,
    },

    created() {
      // TODO: intelligently add item instead of reloading full list
      // TODO: uncomment to add users dynamically instead of needing refresh
      // R3sSocket.on(`userTeamPageUpdate`, async () =>
      //   this.$store.dispatch('getUsersInPage')
      // )
      // R3sSocket.on(`userTeamPageUpdate`, info => {
      //   console.log("GOTHERE", info)
      // })
    },
  }
</script>
