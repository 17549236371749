<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-labelledby="invite-team-member-label",aria-hidden="true")
    div.modal-dialog(role="document")
      div.modal-content
        form(@submit="inviteNewUser")
          div.modal-header
            h5#invite-team-member-label.modal-title Invite New User
            button.close(type="button",data-dismiss="modal",aria-label="Close")
              span(aria-hidden="true") &times;
          div.modal-body
            div
              | Enter the information of your team member to add them to the
              | team. They will receive an e-mail with the invite information.
            hr
            div.form-row
              div.form-group.col-md-12
                label(:for="`invite-client-member-modal-email-${_uid}`") Email Address#[sup.text-danger *]
                input.form-control(
                  :id="`invite-client-member-modal-email-${_uid}`",
                  v-model="inviteUser.email",
                  type='text',
                  placeholder='Email Address')
            hr.mt-2
            div.form-row
              div.form-group.col-md-12
                label(:for="`invite-client-member-modal-first-name-${_uid}`") First Name
                input.form-control(
                  :id="`invite-client-member-modal-first-name-${_uid}`",
                  v-model="inviteUser.first_name",
                  type='text',
                  placeholder='First Name')
            div.form-row
              div.form-group.col-md-12
                label(:for="`invite-client-member-modal-last-name-${_uid}`") Last Name
                input.form-control(
                  :id="`invite-client-member-modal-last-name-${_uid}`",
                  v-model="inviteUser.last_name",
                  type='text',
                  placeholder='Last Name')
            div(v-if="$store.getters.isAdmin")
              hr.mt-2
              div.form-row
                div.form-group.col-12
                  label(:for="`edit-user-modal-role-${_uid}`") User's Role #[i.fal.fa-info-circle(:id="`user-role-tt-${_uid}`")]
                  user-role-tooltip(:target="`user-role-tt-${_uid}`")
                  select.form-control(v-model="inviteUser.role" :id="`edit-user-modal-role-${_uid}`")
                    option(v-for="role in $store.state.userRoles",:value="role.val") {{ role.text }}
              div.form-row(v-if="$store.getters.isInternalUser")
                div.form-group.col-12
                  label(:for="`edit-user-modal-user-type-${_uid}`") User Type
                  select.form-control(v-model="inviteUser.user_type" :id="`edit-user-modal-user-type-${_uid}`")
                    option(v-for="type in $store.state.userTypes",:value="type.val") {{ type.text }}
            hr.mt-2
            div.form-group.col-md-12
              label
                strong Allowed Modules
              div.custom-control.custom-checkbox-sm.custom-checkbox(v-for="(perm, ind) in currentUserAllowedModuleList")
                input.custom-control-input(
                  :id="`new-team-permission-${ind}-${_uid}`",
                  type="checkbox",
                  v-model="inviteUser.permissions[perm]")
                label.custom-control-label(:for="`new-team-permission-${ind}-${_uid}`") {{ $store.state.getModuleNameFromNavPermission(perm) }}
            hr.mt-2
            div.form-row.mb-0
              div.custom-control.custom-checkbox-sm.custom-checkbox
                input.custom-control-input(
                  type="checkbox",
                  :id="`invite-client-member-modal-send-email-${_uid}`",
                  name="invite-client-member-modal-send-email",
                  v-model="inviteUser.send_email")
                label.custom-control-label(
                  :for="`invite-client-member-modal-send-email-${_uid}`") Send invitation e-mail to user?
            div.mt-4(v-if="inviteUser.error || inviteUser.success")
              div.alert.alert-danger.margin-bottom-medium(v-if="!!inviteUser.error && !inviteUser.success") {{ inviteUser.error }}
              div.alert.alert-success.margin-bottom-medium(v-if="!!inviteUser.success") {{ inviteUser.success }}
          div.modal-footer
            button.btn.btn-secondary(type="button",data-dismiss="modal") Cancel
            button.btn.btn-primary(type="submit" :disabled="loading") Invite #[loader-inline(v-if="loading")]
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import ApiAuth from '../../factories/ApiAuth'
  import ApiTeams from '../../factories/ApiTeams'

  export default {
    data() {
      return {
        inviteUser: this.initUserData(),
        loading: false,
      }
    },

    computed: {
      ...mapState({
        currentUserAllowedModuleList: (_, getters) =>
          getters.currentUserAllowedModuleList,

        resetPermissions(state) {
          return state.modulePermissionList.reduce(
            (obj, perm) => ({ ...obj, [perm]: false }),
            {}
          )
        },
      }),

      isEmailValid() {
        return (
          this.inviteUser?.email && this.isValidEmail(this.inviteUser.email)
        )
      },
    },

    methods: {
      isValidEmail: ApiAuth.isValidEmail,

      initUserData() {
        return {
          email: null,
          first_name: null,
          last_name: null,
          role: 'analyst',
          user_type: 'external',
          permissions: { ...this.resetPermissions },
          send_email: true,

          error: null,
          success: null,
        }
      },

      async inviteNewUser(evt) {
        try {
          evt.preventDefault()
          this.loading = true
          this.inviteUser.success = null
          this.inviteUser.error = null

          if (this.isEmailValid) {
            const { id } = await ApiTeams.inviteTeamMember(this.inviteUser)
            this.inviteUser.id = id
            this.inviteUser.success = `Successfully added team member to your organization!`

            await this.successfullyInvitedUser()
          } else {
            this.inviteUser.error = `Please enter a valid e-mail address to invite the user to the organization.`
          }

          await this.$store.dispatch('getAllUsers')
        } catch (err) {
          this.inviteUser.error = err.message
        } finally {
          this.loading = false
        }
      },

      async successfullyInvitedUser() {
        this.$emit('added', this.inviteUser)
        $(`#${this.$el.id}`).modal('toggle')
      },
    },

    mounted() {
      $(`#${this.$el.id}`).on('shown.bs.modal', () => {
        this.inviteUser = this.initUserData()
      })
    },
  }
</script>
