<template lang="pug">
  div
    div(
      v-if="!isEditing"
      @click="canEdit ? isEditing = !isEditing : ''" :class="canEdit ? 'editable' : ''")
        div(v-if="selectedVendors.length === 0")
          i {{canEdit ? 'No vendors yet...' : ''}}
        ul.list-unstyled(v-else)
          li.mb-1.strong(v-for="vendor in selectedVendors")
            div.d-flex.align-items-center
              risk-register-data-circle.mr-2(
                type="risk",
                :value="vendor.risk_score || '-'",
                size="25")
              div.mr-2 {{ vendor.name }}
              div
                a(@click.stop="removeVendor(vendor.id)")
                  i.fal.fa-times.text-danger
    div(v-else)
      select-dropdown-filter(
        v-model="selectedVendorIds"
        :multi="true"
        text="Select Vendor"
        :options="vendorsForSelect")
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    props: {
      value: { type: String, default: null },
      canEdit: { type: Boolean, default: true },
    },

    data() {
      return {
        isEditing: false,
      }
    },

    computed: {
      ...mapState({
        vendors: (state) => state.vendormanagement.teamVendors.data,
      }),

      vendorsForSelect() {
        if (!this.vendors) return []
        return this.vendors.map((v) => ({
          text: v.name,
          value: v.id.toString(),
          // variant: v.variant,
        }))
      },

      selectedVendors() {
        return this.selectedVendorIds
          .map((vid) => {
            return this.vendors.find((ven) => ven.id == vid)
          })
          .filter((v) => !!v)
          .sort((v1, v2) =>
            v1.name.toLowerCase() < v2.name.toLowerCase() ? -1 : 1
          )
      },

      selectedVendorIds: {
        get() {
          return (this.value || '').split(',').filter((i) => !!i)
        },

        set(newVal) {
          this.updateVal(newVal.filter((i) => !!i).join(','))
        },
      },
    },

    methods: {
      removeVendor(vendorId) {
        this.updateVal(
          this.selectedVendorIds.filter((i) => i != vendorId).join(',')
        )
      },

      updateVal(newVal) {
        if (newVal === this.value) return
        this.$emit('input', newVal)
        this.isEditing = false
      },
    },

    created() {
      // get all vendors for us to search and display for
      this.$store.commit('CLEAR_VENDOR_MANAGEMENT_TEAM_VENDOR_FILTERS')
      this.$store.dispatch('setTeamVendorsChangePerPage', 1e3)
    },
  }
</script>
