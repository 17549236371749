<template lang="pug">
  div
    loader(v-if="isLoadingFiles")
    div.px-2(v-else)
      div.d-flex.align-items-center.justify-content-between.p-2
        h5.m-0
          i.fal.fa-file-alt
          span.ml-2 File Manager
        div.d-flex.w-75.justify-content-end
          select-dropdown-filter.mr-2(
            v-model="filters.user"
            :class="filters.user.length > 0 && 'filter-active'"
            :multi="true"
            icon="fa fa-user"
            text="Uploaded By",
            :options="users.map(u => ({ text: u.name, value: u.id }))"
            @change="setFilters()")
          select-dropdown-filter.mr-2(
            v-model="filters.module"
            :class="filters.module.length > 0 && 'filter-active'"
            :multi="false"
            icon="fa fa-layer-group"
            text="Module",
            :options="moduleFilterOptions"
            @change="setFilters()")
          select-dropdown-filter.mr-2(
            v-if="canAccessAssessments"
            v-model="filters.assessment"
            :class="filters.assessment.length > 0 && 'filter-active'"
            :multi="true"
            icon="fa fa-clipboard-check"
            text="Assessment",
            :options="availableAssessments.map(a => ({ text: a.name, value: a.id }))"
            @change="setFilters()")
          select-dropdown-filter.mr-2(
            v-if="canAccessControls"
            v-model="filters.framework"
            :class="filters.framework.length > 0 && 'filter-active'"
            :multi="true"
            icon="fa fa-list"
            text="Framework",
            :options="availableFrameworks.map(f => ({ text: f.name, value: f.id }))"
            @change="setFilters()")
          
          div.pt-2.w-25
            input-debounce.form-control.form-control-sm.mb-2(
              v-model="mutableSearch"
              placeholder="Search file names..."
              @input="setSearch()")
      div.text-center.m-4(v-if="files.totalCount === 0")
        i No files uploaded yet...
      table.w-100.mt-2(v-else)
        thead
          th(@click="setSorting('name')").col-3.px-0 
            div.d-flex.align-items-center.clickable
              div.mr-1 File
              i.fa(:class="getSorting('name')")
          th(@click="setSorting('uploaded')").col-1.px-0 
            div.d-flex.align-items-center.clickable
              div.mr-1 Upload Date
              i.fa(:class="getSorting('uploaded')")
          th(@click="setSorting('user')").col-1.pl-0.pr-2
            div.d-flex.align-items-center.justify-content-center.clickable 
              div.mr-1 Uploaded By
              i.fa(:class="getSorting('user')")
          th
            div.mr-1 Modules
          th.col-3.pr-0.pl-4
            div.d-flex.align-items-center 
              div.mr-1 Records
          th(v-if="canAccessControls") Associated Controls
        tbody.hover-fade.border-top
          tr(v-for="(file, index) in files.data" :class="index === 0 ? '' : 'border-top'")
            td
              div.d-flex.align-items-center
                i.fal.fa-eye.fa-lg.text-primary.mr-2.clickable(@click="previewFile(file)", data-toggle="modal",:data-target="`#preview-attachment-modal-${_uid}`")
                i.fal.fa-edit.clickable(@click="editingFile = file",data-toggle="modal",data-target="#file-update-modal")
                a.ml-2(@click="addFile(file)")
                  div(:id="`file-name-${_uid}-${file.id}`") {{ truncateString($decodeURIComponentRecursive(file.external_name || file.name), 30) }}
                  b-tooltip(:target="`file-name-${_uid}-${file.id}`") Click to add: #[b {{ $decodeURIComponentRecursive(file.external_name || file.name) }}]
            td {{ getFormattedDate(file.created_at, 'MM-DD-YYYY') }}
            td
              div.d-flex.justify-content-center
                user-avatar(
                  v-if="file.user_id"
                  :id="`user-avatar-${_uid}-${file.id}`"
                  height="30px",
                  max-height="25",
                  :should-show-close="false"
                  :user-id="file.user_id")
            td 
              div(v-html="getModuleNames(file)")
            td.py-2.pl-4
              div.strong(v-if="file.evidence?.length > 0 && file.evidence[0]") Controls Evidence:
              div.pb-2(v-for="evidence in file.evidence" v-if="evidence")
                div.pl-2 {{ evidence.evidence_number }}
                div.pl-4.small {{ evidence.evidence_name }}
              
              div.strong(v-if="file.assessments?.length > 0 && file.assessments[0]") Assessments & Requests:
              div.pb-2(v-for="assessment in file.assessments" v-if="assessment")
                div.pl-2 {{ assessment.assessment_name }}
                div.pl-4.small(v-for="requestName in assessment.request_names" v-html="requestName")
              
              div.strong(v-if="file.projects?.length > 0 && file.projects[0]") Projects & Tasks:
              div.pb-2(v-for="project in file.projects" v-if="project")
                div.pl-2 {{ project.project_name }}
                div.pl-4.small(v-for="taskName in project.task_names" v-html="taskName")

            td(v-if="canAccessControls")
              div.p-2(v-for="(control, index) in removeDuplicateControls(file.controls)")
                div.clickable.d-flex.align-items-center(@click="toggleList(file.id, control)")
                  i#minimize-list.fa(:class="isListMinimized(file.id, control) ? 'caret right' : 'caret down'")
                  div.pl-2(v-html="`<strong>${control.control_number}</strong> - ${control.control_name}`")
                div.pl-4(v-if="!!displayedLists[getMinimizeKey(file.id, control)]")
                  div(v-for="framework in control.frameworks")
                    div.strong(:style="`color:${framework.background_color };`") {{ framework.framework_name }}
                    ul
                      li(v-for="framework_control in framework.framework_controls")
                        div.d-flex.align-items-center
                          div.strong.mr-1 {{ framework_control.framework_control_number }}
                          div.mr-1 {{ framework_control.framework_control_name }}
      div.border-top.sticky-footer
        pagination(
          size="md"
          :info="files"
          @changePage="changePage"
          @changePerPage="changePerPage")

    preview-attachment-modal#preview-attachment-modal(
      :id="`preview-attachment-modal-${_uid}`",
      :file-id="previewingFile.id",
      :file-name="previewingFile.name",
      :title="previewingFile.external_name || previewingFile.name"
      :style="'z-index: 1074'")

    file-update-modal#file-update-modal(:file="editingFile",@updated="resetFiles",@newVersion="editingFileNewVersion")
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import TimeHelpers from '../../factories/TimeHelpers'
  import StringHelpers from '../../factories/StringHelpers'

  export default {
    props: {},

    data() {
      return {
        editingFile: {},
        isLoadingFiles: false,
        previewingFile: {},
        showClear: false,
        displayedLists: {},
        mutableSearch: this.fileSearch,
        moduleInfo: [
          {
            text: 'Controls',
            value: 'my_controls',
            column: 'evidence',
          },
          {
            text: 'Assessments',
            value: 'assessments',
            column: 'assessments',
          },
          {
            text: 'Project Management',
            value: 'projects',
            column: 'projects',
          },
        ],
      }
    },

    computed: {
      ...mapState({
        canAccessAssessments: (state) =>
          state.isModuleAuthorized('assessments'),
        canAccessControls: (state) => state.isModuleAuthorized('my_controls'),

        availableAssessments: (state) => state.files.availableAssessments,
        availableFrameworks: (state) => state.files.availableFrameworks,

        currentPage: (state) => state.files.currentPage,
        perPage: (state) => state.files.perPage,
        fileSearch: (state) => state.files.fileSearch,
        fileSorting: (state) => state.files.fileSorting,
        files: (state) => state.files.files,
        filters: (state) => state.files.filters,
        users: (state) => state.team.teamAndInternalUsers,
      }),

      authorizedModules() {
        return this.moduleInfo.filter((m) =>
          this.$store.state.isModuleAuthorized(m.value)
        )
      },

      moduleFilterOptions() {
        return this.authorizedModules.map((m) => ({
          text: m.text,
          value: m.value,
        }))
      },
    },

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,
      truncateString: StringHelpers.truncateString,

      removeDuplicateControls(controls) {
        if (!controls) return []
        return controls
          .flat(Infinity)
          .filter(
            (control, index, self) =>
              index ===
              self.findIndex(
                (t) =>
                  t.control_number === control.control_number &&
                  t.control_name === control.control_name
              )
          )
      },

      resetUpdateModal() {
        // This is here due to this issue
        // https://stackoverflow.com/questions/10636667/bootstrap-modal-appearing-under-background
        $(`#preview-attachment-modal-${this._uid}`).appendTo('body')
      },

      previewFile(file) {
        this.previewingFile = file
      },

      addFile(file) {
        this.$emit('addFile', file)
      },

      async editingFileNewVersion(newFile) {
        try {
          this.editingFile = newFile
          await this.resetFiles()
        } catch (err) {
          window.toastr.error(
            'There was an error while editing the file: ',
            err.message
          )
        }
      },

      async resetFiles() {
        this.showClear = false
        this.$store.dispatch('fileChangePage', 1)
      },

      async changePage(newPage) {
        this.$store.dispatch('fileChangePage', newPage)
      },

      async changePerPage(newPerPage) {
        this.$store.dispatch('fileChangePerPage', newPerPage)
      },

      async getFiles() {
        try {
          await this.$store.dispatch('getFiles', { reset: true })
        } catch (err) {
          window.toastr.error(
            'There was an issue retrieving your files: ',
            err.message
          )
        }
      },

      getSorting(key) {
        const sortOrder = this.fileSorting[key]
        if (!sortOrder) {
          return 'fa-sort'
        }
        if (sortOrder == 'desc') {
          return 'fa-sort-down'
        }
        if (sortOrder == 'asc') {
          return 'fa-sort-up'
        }
      },

      async setFilters() {
        try {
          // the module filter is not a multiselect, but it could be eventually.
          // the filter is expected to be an empty list rather than null
          if (this.filters.module === null) this.filters.module = []
          await this.$store.dispatch('fileSetFilters', this.filters)
        } catch (err) {
          window.toastr.error(
            'There was an issue filtering files: ',
            err.message
          )
        }
      },

      async setSearch() {
        try {
          await this.$store.dispatch('fileSetSearch', this.mutableSearch)
        } catch (err) {
          window.toastr.error(
            'There was an issue searching files: ',
            err.message
          )
        }
      },

      async setSorting(key = 'id') {
        try {
          let val = this.fileSorting[key]
          if (val == 'asc') {
            val = 'desc'
            this.fileSorting[key] = val
          } else if (val == 'desc') {
            delete this.fileSorting[key]
          } else if (!val) {
            val = 'asc'
            this.fileSorting[key] = val
          }

          const sorting = this.fileSorting[key] ? { key, val } : {}
          await this.$store.dispatch('fileSetSorting', sorting)
        } catch (err) {
          window.toastr.error(
            'There was an issue sorting the files: ',
            err.message
          )
        }
      },

      getModuleNames(file) {
        let moduleNames = ''

        this.authorizedModules.forEach((m) => {
          if (file[m.column]?.length > 0 && file[m.column][0]) {
            moduleNames += `<div>${m.text}</div>`
          }
        })
        return moduleNames
      },

      isListMinimized(file_id, control) {
        const minimizeKey = this.getMinimizeKey(file_id, control)
        return !this.displayedLists[minimizeKey]
      },

      toggleList(file_id, control) {
        const minimizeKey = this.getMinimizeKey(file_id, control)
        // this handles the case where the control number is not currently in the displayedLists
        if (this.displayedLists[minimizeKey]) {
          this.$set(this.displayedLists, minimizeKey, false)
        } else {
          this.$set(this.displayedLists, minimizeKey, true)
        }
      },

      getMinimizeKey(file_id, control) {
        return `${file_id}-${control.control_number}`
      },
    },

    created() {
      // This is here due to this issue
      // https://stackoverflow.com/questions/10636667/bootstrap-modal-appearing-under-background
      $(`#file-update-modal`).appendTo('body')
    },

    async mounted() {
      try {
        this.resetUpdateModal()
        this.isLoadingFiles = true
        await Promise.all([
          this.canAccessAssessments
            ? this.$store.dispatch('getAvailableAssessments')
            : null,
          this.canAccessControls
            ? this.$store.dispatch('getAvailableFrameworks')
            : null,
          this.getFiles(),
        ])
      } catch (err) {
        window.toastr.error(
          'There was an issue retrieving your files: ',
          err.message
        )
      } finally {
        this.isLoadingFiles = false
      }
    },

    beforeDestroy() {
      // See comments in this.resetUpdateModal as to why this needs
      // to be here.
      $(`#preview-attachment-modal-${this._uid}`).remove()
    },
  }
</script>

<style scoped lang="scss">
  .list-group-item {
    &:first-child {
      border-radius: 0 !important;
      border-top: 0px;
    }

    &:last-child {
      border-radius: 0 !important;
    }
  }
</style>
