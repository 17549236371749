<template lang="pug">
  aside.main-sidebar.px-0(@mouseenter="isLogoLarge = true",@mouseleave="rollOutOfNav()")
    div.main-navbar
      nav.navbar.align-items-stretch.navbar-light.flex-md-nowrap.p-0
        router-link.navbar-brand.w-100.mr-0(style="line-height: 25px",to="/")
          div.d-table.m-auto
            img#main-logo.d-inline-block.align-top(
              v-if="isLogoLarge || isLeftNavExpanded",
              style="max-width: 190px;",
              src="/public/images/fullcircle_logo_white.svg")
            img#main-logo.d-inline-block.align-top(v-else,style="max-width: 40px;",src="/public/images/fullcircle_icon.svg")
        a.toggle-sidebar.d-sm-inline.d-md-none.d-lg-none.d-flex.align-items-center.justify-content-center
          i.material-icons &#xE5C4;
    main-search(location="sidebar")
    div.nav-wrapper(v-if="userId")
      ul#main-side-nav.nav.nav--no-borders.flex-column
        risk3sixty-main-nav-item(
          material-icon="home",
          text="Home",
          active-path="/",
          nav-key="home")
        risk3sixty-main-nav-item(
          :is-dropdown="true"
          material-icon="fact_check"
          text="Program Management"
          active-path="never"
          :class="isNavActiveClass('^\/scope|\/compliance/list|\/risk/register|\/policies|\/selfassessments')")
            div.dropdown-menu.dropdown-menu-small
              router-link.dropdown-item(
                :class="isNavActiveClass('^\/scope')"
                to='/scope') Scope
              router-link.dropdown-item(
                :class="isNavActiveClass('^\/policies')"
                to='/policies') Policies
              router-link.dropdown-item(
                :class="isNavActiveClass('^\/projects')"
                to='/projects') Project Management
              router-link.dropdown-item(
                v-if="isItemAuthorized('risk_register')"
                :class="isNavActiveClass('^\/risk/register')"
                to='/risk/register') Risk Registers
              router-link.dropdown-item(
                v-if="isItemAuthorized('compliance_calendar')"
                :class="isNavActiveClass('^\/compliance/list')"
                to='/compliance/list') Calendar              
        risk3sixty-main-nav-item(
          :is-dropdown="true"
          material-icon="list_alt"
          text="Program Assessments"
          active-path="never"
          :class="isNavActiveClass('^\/assessments|\/selfassessments')")
          div.dropdown-menu.dropdown-menu-small
            router-link.dropdown-item(
              v-if="isItemAuthorized('self_assessments')"
              :class="isNavActiveClass('^\/selfassessments')"
              to='/selfassessments') Self Assessments
            upgrade-container.text-small(
              v-if="isModuleGated('assessments')"
              restrictionKey='assessments'
              :top="'-12'"
              :right="'-10'"
            )
              span.nav-link(v-if="isModuleGated('assessments')"
                style="cursor: pointer;"
                :class="isNavActiveClass('^\/assessments')"
                @click="showGatedModal(null, 'assessments')"
              ) Assessments
            router-link.dropdown-item(
              v-else-if="isItemAuthorized('assessments')"
              :class="isNavActiveClass('^\/assessments')"
              to='/assessments'
            ) Assessments
        //- risk3sixty-main-nav-item(
        //-   material-icon="list_alt",
        //-   text="Assessments",
        //-   active-path="/assessments",
        //-   nav-key="assessments")
        //- risk3sixty-main-nav-item(
        //-   fa-icon="fa-calendar-alt fas",
        //-   text="Compliance Calendar",
        //-   active-path="/compliance/calendar",
        //-   nav-key="compliance_calendar")
        //- risk3sixty-main-nav-item(
        //-   fa-icon="fa-cabinet-filing fas",
        //-   text="Risk Register",
        //-   active-path="/risk/register",
        //-   nav-key="risk_register")
        risk3sixty-main-nav-item(
          fa-icon="fa-solar-system fal",
          icon-styles="font-size: medium;",
          text="Controls",
          active-path="/controls",
          nav-key="my_controls")
        risk3sixty-main-nav-item(
          fa-icon="fa-address-book fas",
          text="Vendor Management",
          active-path="/vendor/management",
          nav-key="vendor_management")
        risk3sixty-main-nav-item(
          fa-icon="fa-briefcase fas",
          text="Inventories",
          active-path="/inventories",
          nav-key="inventories")
        risk3sixty-main-nav-item(
          :is-dropdown="false"
          material-icon="bug_report"
          text="Vulnerability Management"
          active-path="/vulnerability"
          nav-key="vulnerability")
          //- div.dropdown-menu.dropdown-menu-small
          //-   router-link.dropdown-item(
          //-     :class="isNavActiveClass('^\/vulnerability/list/assets$')",
          //-     to='/vulnerability/list/assets') Asset Management
          //-   router-link.dropdown-item(
          //-     :class="isNavActiveClass('^\/assets/groups')",
          //-     to='/assets/groups') Asset Groups
          //-   router-link.dropdown-item(
          //-     :class="isNavActiveClass('^\/vulnerability/list/scans$')",
          //-     to='/vulnerability/list/scans') Vulnerability Scans
          //-   router-link.dropdown-item(
          //-     :class="isNavActiveClass('^\/vulnerability/list/remote$')",
          //-     to='/vulnerability/list/remote') Remote Access 
        risk3sixty-main-nav-item(
          material-icon="smart_toy",
          text="Automations",
          active-path="/automations",
          nav-key="automations")
        //- risk3sixty-main-nav-item(
        //-   material-icon="security",
        //-   text="Incident Response",
        //-   active-path="/incident/response",
        //-   nav-key="incident_response")
        risk3sixty-main-nav-item(
          material-icon="insert_chart",
          text="Reporting",
          active-path="/reports",
          nav-key="reports")
        - //risk3sixty-main-nav-item(
        - //  material-icon="attach_money",
        - //  text="PCI ASV Scanning",
        - //  active-path="/pci",
        - //  nav-key="pci")
        risk3sixty-main-nav-item(
          fa-icon="fa-user-secret fas",
          text="Penetration Testing",
          active-path="/pentesting",
          nav-key="penetration_testing")
        risk3sixty-main-nav-item(
          material-icon="topic",
          text="Template Center",
          active-path="/templates",
          nav-key="template_center"
          :hideIfNotAuthorizedOverride="true")
        risk3sixty-main-nav-item(
          material-icon="settings",
          text="Settings",
          active-path="/settings",
          nav-key="settings")
        
        risk3sixty-main-nav-item.internal(
          v-if="isInternalUser"
          :is-dropdown="true",
          material-icon="lock",
          text="risk3sixty")
          div.dropdown-menu.dropdown-menu-small.mb-5
            router-link.dropdown-item(
              v-if="isR3s && isInternalLeadership",
              :class="isNavActiveClass('^\/admin/assessments/gaps$')",
              to='/admin/assessments/gaps') Assessment Gap Analysis
            router-link.dropdown-item(
              v-if="isR3s && isInternalLeadership",
              :class="isNavActiveClass('^\/admin/assessments/mastercontrols$')",
              to='/admin/assessments/mastercontrols') Assessment Master Controls
            router-link.dropdown-item(
              v-if="isR3s && isInternalLeadership",
              :class="isNavActiveClass('^\/admin/contribution$')",
              to='/admin/contribution') Employee Contribution Quotient
            router-link.dropdown-item(
              v-if="isR3s && isInternalLeadership",
              :class="isNavActiveClass('^\/admin/timetracking$')",
              to='/admin/timetracking') Employee Time Tracking
            router-link.dropdown-item(
              v-if="isR3s",
              :class="isNavActiveClass('^\/admin/scans/external$')",
              to='/admin/scans/external') External Vulnerability Scans
            router-link.dropdown-item(
              v-if="isR3s",
              :class="isNavActiveClass('^\/admin/integrations')",
              to='/admin/integrations') Internal Integrations
            router-link.dropdown-item(
              v-if="isR3s && isInternalManager",
              :class="isNavActiveClass('^\/admin/internal/user/permissions')",
              to='/admin/internal/user/permissions') Internal User Permissions
            router-link.dropdown-item(
              v-if="isR3s && isInternalLeadership",
              :class="isNavActiveClass('^\/admin/users$')",
              to='/admin/users') Logged-in Users
            router-link.dropdown-item(
              v-if="isR3s && isInternalManager",
              :class="isNavActiveClass('^\/admin/org/permissions')",
              to='/admin/org/permissions') Organizations
            //- if `admin` is in the route, tabs cannot function
            router-link.dropdown-item(
              v-if="isCurrentlyR3sOrg && (isInternalLeadership || isItemAuthorizedForUser('platform_intelligence'))",
              :class="isNavActiveClass('^\/platform/intel')"
              to='/platform/intel') Platform Intelligence
            router-link.dropdown-item(
              v-if="isR3s && isInternalManager",
              :class="isNavActiveClass('^\/styles')",
              to='/styles') Style Guide
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import Risk3sixtyMainNavItem from './Risk3sixtyMainNavItem'
  import MainSearch from './reusable/MainSearch'
  import SubscriptionHelpers from '../factories/SubscriptionHelpers'

  export default {
    data() {
      return {
        isLogoLarge: false,
      }
    },

    computed: {
      ...mapState({
        isInternalLeadership: (_, getters) => getters.isInternalLeadership,
        isInternalManager: (_, getters) => getters.isInternalManager,
        isInternalUser: (_, getters) => getters.isInternalUser,
        isLeftNavExpanded: (state) => state.isLeftNavExpanded,
        isR3s: (_, getters) => getters.isR3s,
        path: (state) => state.path,
        teamUserModulePermissions: (state) =>
          state.auth.teamUserModulePermissions,
        userId: (state) => state.auth.user && state.auth.user.id,
      }),

      unauthorizedTooltipText() {
        return `
          You are not authorized to access the following module. If you
          would like more information about what this module offers contact
          your account manager for more details. You can remove unauthorized
          links from showing in the navigation menu in your profile settings.`
      },

      isCurrentlyR3sOrg() {
        return this.$store.state.session.current_team.id == '1'
      },
    },

    methods: {
      showGatedModal: SubscriptionHelpers.showGatedModal,

      isModuleGated(navKey) {
        return !this.isInternalUser && this.$store.state.isModuleGated(navKey)
      },

      isNavActiveClass(regexString) {
        const regexAry = regexString.split(',').map((str) => new RegExp(str))
        const matches = regexAry.some((re) => re.test(this.path))
        return matches ? 'active' : ''
      },

      isItemAuthorized(navKey) {
        if (typeof navKey === 'boolean' && navKey) return true
        return this.$store.state.isModuleAuthorized(navKey) ? true : false
      },

      isItemAuthorizedForUser(navKey) {
        return !!(
          this.teamUserModulePermissions &&
          this.teamUserModulePermissions['is_' + navKey + '_customer']
        )
      },

      rollOutOfNav() {
        this.isLogoLarge = false
        $('.main-sidebar .dropdown, .main-sidebar .dropdown-menu').removeClass(
          'show'
        )
        $('.main-sidebar .dropdown-toggle').dropdown('dispose')
      },
    },

    components: {
      MainSearch,
      Risk3sixtyMainNavItem,
    },
  }
</script>
