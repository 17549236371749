<template lang="pug">
div
  div.form-group.mb-2
    label Name #[span.text-danger *]
    rich-text-editor(v-model="mutableVal.name",@input="update")
  div.form-group.mb-2
    label Description #[span.text-danger *]
    rich-text-editor(v-model="mutableVal.description",@input="update")
  div.form-group.mb-2
    label Criticality
    pill-status-updater(
      v-model="mutableVal.criticality",
      :is-hollow="true",
      :options="criticalityPillUpdaterOptions",
      @input="update")
  div.form-group.mb-2
    label Status
    pill-status-updater(
      v-model="mutableVal.status",
      :is-hollow="true",
      :options="statusPillUpdaterOptions",
      @input="update")
  div.form-group.mb-2
    label Hours to Complete
    input.form-control(type="number",v-model="mutableVal.hours_to_complete",@change="update")
  div.form-group.mb-2
    label Start Date
    datepicker-shards(
      v-model="mutableVal.start_date",
      @changedWithKey="update")
  div.form-group.mb-2
    div.d-flex
      label Due Date
      div.clickable.ml-1(
        id="task-edit-due-date-field"
        v-if="hasPeriodicity()"
        ) #[span.text-danger *]
      b-tooltip(target="task-edit-due-date-field")
        div Due date is required for recurrence.
    datepicker-shards(
      v-model="mutableVal.due_date",
      @changedWithKey="update")
  div.form-group.mb-2
    div.d-flex.justify-content-between
      periodicity-updater(
        :enabled="mutableVal.due_date !== null"
        :frequency="mutableVal.frequency"
        :frequency-unit="mutableVal.frequency_unit"
        :include-end-date="true"
        :frequency-end-date="mutableVal.frequency_end_date"
        :periodicity-options="periodicityOptions"
        :strong-title="false"
        title="Recurrence"
        @input="updatePeriodicity")
      div.clickable.pt-2(:id="`recurrence-requires-date-${uid}`")
        i.fal.fa-info-circle
        b-tooltip(:target="`recurrence-requires-date-${uid}`")
          div Recurrence requires a due date to be set.
  
  hr
  div.form-group
    label.m-0 Owners
    select.form-control(v-model="ownerUserId",@change="addOwner(ownerUserId)")
      option(v-for="user in users",:value="user.id") {{ user.name || user.username_email }}
    div.my-2
      div.small(v-if="mutableVal.owners?.length === 0")
        i No owners selected yet...
      div.d-flex.align-items-center(v-else)
        user-avatar.mr-1(
          v-for="(userId, ind) in mutableVal.owners",
          :key="userId",
          :id="`risk-register-owner-user-avatar-${userId}`",
          :user-id="userId",
          :shouldShowClose="canEditOwnersCollaborators"
          @close="removeOwner(ind)")
    template(v-if="!isSubTask")
      label.m-0 Collaborators
      select.form-control(v-model="collaboratorUserId",@change="addCollaborator(collaboratorUserId)")
        option(v-for="user in users",:value="user.id") {{ user.name || user.username_email }}
      div.my-2
        div.small(v-if="mutableVal.collaborators?.length === 0")
          i No collaborators selected yet...
        div.d-flex.align-items-center(v-else)
          user-avatar.mr-1(
            v-for="(userId, ind) in mutableVal.collaborators",
            :key="userId",
            :id="`risk-register-collaborator-user-avatar-${userId}`",
            :user-id="userId",
            :shouldShowClose="canEditOwnersCollaborators"
            @close="removeCollaborator(ind)")
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import StringHelpers from '../../factories/StringHelpers'
  import Utilities from '@risk3sixty/utilities'

  const moment = Utilities.Dates.moment

  export default {
    props: {
      value: { type: Object, required: true },
      uid: { type: [Number, String], default: null },
    },

    watch: {
      value: {
        handler(newVal) {
          this.mutableVal = { ...newVal }
          this.mutableVal.frequency_end_date = this.mutableVal
            .frequency_end_date
            ? moment(this.mutableVal.frequency_end_date).toISOString()
            : null
        },
        deep: true,
      },
    },

    data() {
      return {
        ownerUserId: null,
        collaboratorUserId: null,
        mutableVal: JSON.parse(JSON.stringify(this.value)),
      }
    },

    computed: {
      ...mapState({
        users: (state) => state.team.teamAndInternalUsers,
        periodicityOptions: (state) => state.projects.availablePeriods,
        criticalityPillUpdaterOptions(state) {
          return state.projects.criticalities.map((st) => {
            return {
              value: st.val,
              text: st.text,
              color: state.dashboard.variantColors()[st.variant],
            }
          })
        },
        statusPillUpdaterOptions(state) {
          return state.projects.statuses.map((st) => {
            return {
              value: st.value,
              text: st.text,
              color: state.dashboard.variantColors()[st.variant],
            }
          })
        },
        currentProject: (state) => state.projects.currentProject,
      }),

      ...mapGetters(['isManager']),

      isSubTask() {
        return !!this.mutableVal.parent_task_id
      },

      canEditOwnersCollaborators() {
        return !this.currentProject.is_locked || this.isManager
      },
    },

    methods: {
      titleCase: StringHelpers.titleCase,

      hasPeriodicity() {
        const hasFrequency =
          typeof this.mutableVal.frequency !== 'undefined' &&
          this.mutableVal.frequency !== null
        const hasFrequencyUnit =
          typeof this.mutableVal.frequency_unit !== 'undefined' &&
          this.mutableVal.frequency_unit !== null
        const hasFrequencyEndDate =
          typeof this.mutableVal.frequency_end_date !== 'undefined' &&
          this.mutableVal.frequency_end_date !== null
        return hasFrequency || hasFrequencyUnit || hasFrequencyEndDate
      },

      addOwner(userId) {
        this.mutableVal.owners = this.mutableVal.owners || []
        this.mutableVal.owners.push(userId)
        this.mutableVal.owners = [...new Set(this.mutableVal.owners)]
        this.ownerUserId = null
        this.update()
      },

      removeOwner(ind) {
        this.mutableVal.owners.splice(ind, 1)
        this.update()
      },

      addCollaborator(userId) {
        this.mutableVal.collaborators = this.mutableVal.collaborators || []
        this.mutableVal.collaborators.push(userId)
        this.mutableVal.collaborators = [
          ...new Set(this.mutableVal.collaborators),
        ]
        this.collaboratorUserId = null
        this.update()
      },

      removeCollaborator(ind) {
        this.mutableVal.collaborators.splice(ind, 1)
        this.update()
      },

      update() {
        this.$emit('input', this.mutableVal)
      },

      updatePeriodicity(periodicity) {
        const { frequency, frequency_unit, frequency_end_date } = periodicity
        this.mutableVal.frequency = frequency
        this.mutableVal.frequency_unit = frequency_unit
        this.mutableVal.frequency_end_date = frequency_end_date
        this.update()
      },
    },

    mounted() {
      this.$store.dispatch('getProjectStatuses')
    },
  }
</script>
