<template lang="pug">
  div.card.no-shadow.border.account-manager-card
    div.card-body.px-2.pt-2(:class="(this.small) ? 'pb-3' : 'pb-5'")
      div.text-primary.mb-3(v-if="!this.small")
        strong {{ title }}
      div.d-flex.align-items-start
        a.no-underline.d-flex.align-items-center.mr-4(:href="contactLink",target="_blank",rel="noopener noreferrer")
          div.small
            div.small
              div(v-if="teamAccountManager.name") {{ teamAccountManager.name }}
              div {{ teamAccountManager.username_email }}
              div(v-if="teamAccountManager.phone_number") {{ displayPhoneNumber(teamAccountManager.phone_number) }}
        router-link.no-underline.ml-auto.mr-3(:to="'/user/' + teamAccountManager.id")
          img.user-avatar.rounded-circle.border.max-60(
            v-if="!!teamAccountManager.avatar_file",
            :src="'/file/s3/' + teamAccountManager.avatar_file",
            alt='Account Manager Avatar')
          i.material-icons(v-else,style="font-size:2rem") account_circle
</template>

<script>
  import StringHelpers from '../../factories/StringHelpers'

  export default {
    props: {
      userId: { type: String, default: null },
      link: { type: String, default: null },
      title: { type: String, default: 'Contact Account Manager' },
      small: { type: Boolean, default: false },
    },

    computed: {
      teamAccountManager() {
        let userId = this.userId
        if (!userId)
          userId = this.$store.state.session.current_team
            .account_manager_user_id

        return (
          this.$store.state.team.teamAndInternalUsers.find(
            (u) => u.id === userId
          ) || {}
        )
      },

      contactLink() {
        if (this.link) return this.link

        return `mailto:${this.teamAccountManager.username_email}`
      },
    },

    methods: {
      displayPhoneNumber: StringHelpers.displayPhoneNumber,
    },
  }
</script>

<style scoped lang="scss">
  .account-manager-card {
    background-image: url('/public/images/bottom_right_wave.png');
    background-position: left bottom;
    background-repeat: no-repeat;
    // background-size: contain;
  }
</style>
