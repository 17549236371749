<template lang="pug">
  data-circle.nowrap.rr-data-circle(
    :class="`text-${getDatumVariant}`",
    :header="header",
    :value="value || 0",
    :card-class="cardClasses",
    :text-class="textClass",
    :size="size",
    :is-vertical="true",
    :title="tooltipText")
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    props: {
      value: { type: [Number, String], required: true },

      // drives tooltips and variant colors
      // risk, cost, effort, impact, likelihood
      type: { type: String, required: true },

      header: { type: String, default: null },
      size: { type: [Number, String], default: 40 },
      fill: { type: Boolean, default: true }, // Whether to fill the circle with the variant color
    },

    computed: {
      ...mapState({
        getDatumVariant(state) {
          return this.type === 'risk'
            ? state.riskregister.getRiskScoreVariant(this.value)
            : state.riskregister.getSingleDatumVariant(this.value)
        },

        tooltipText(state) {
          try {
            let scores = state.riskregister.riskScores
            switch (this.type) {
              case 'cost':
                scores = state.riskregister.costScores
                break
              case 'effort':
                scores = state.riskregister.effortScores
                break
              case 'impact':
                scores = state.riskregister.impactScores
                break
              case 'likelihood':
                scores = state.riskregister.likelihoodScores
                break
            }

            return scores.find((s) => {
              if (typeof s.value === 'function') return s.value(this.value)

              return s.value === this.value
            }).policy_text
          } catch (err) {
            return null
          }
        },
      }),

      cardClasses() {
        let classes = [`border-${this.getDatumVariant}`]
        if (this.fill)
          classes.push(`bg-${this.getDatumVariant}`)

        return classes
      },

      textClass() {
        return [
          'strong',
          this.fill ? 'text-white' : `text-${this.getDatumVariant}`,
        ]
      },
    },
  }
</script>
