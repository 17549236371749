<template lang="pug">
  - // .no-shadow
  div.card.card-small
    div.card-header.border-bottom
      div
        small
          strong Vulnerability Severity Summary
    div.card-body.p-3
      div(v-if="isLoadingLocal")
        loader
      div(v-else)
        div(v-for="severity in severitiesExceptInformational")
          router-link(:to="getLinkBySeverity(severity)")
            strong {{ titleCase(severity) }} ({{ getTotal(severity, true) }})
          div.progress
            template(
              v-if="getTotalByStatus(severity, status) !== 0"
              v-for="status in statuses")
                div.progress-bar.status(
                  :id="`status-summary-progress-${_uid}-${severity}-${status}`"
                  :class="statusBgClassMap(status)",role="progressbar"
                  :style="{ width: getStatusWidthPercentage(severity, status) + '%' }")
                b-tooltip(:target="`status-summary-progress-${_uid}-${severity}-${status}`")
                  | {{ titleCase(status, true) }}: {{ getTotalByStatus(severity, status) }}
        div.row.mt-4
          div.col-sm-6.mb-1(v-for="status in statuses")
            div.row.no-gutters
              div.col-3
                div.mr-2.legend-bubble.status(:class="statusBgClassMap(status)")
              div.col-9
                div.small.overflow-ellipses.d-flex.align-items-center
                  small {{ titleCase(status, true) }}
</template>

<script>
  import { mapState } from 'vuex'
  import ApiVulnerability from '../../../../factories/ApiVulnerability'
  import NumberHelpers from '../../../../factories/NumberHelpers'
  import StringHelpers from '../../../../factories/StringHelpers'

  export default {
    data() {
      return {
        isLoadingLocal: true,
        summaryData: null,
      }
    },

    computed: {
      ...mapState({
        statuses: (state) => state.vulnerability.statuses,
        severitiesExceptInformational(state) {
          return state.vulnerability.severities.filter(
            (s) => s !== 'informational'
          )
        },
      }),

      getSeverities() {
        // const storeSevAry = this.$store.state.vulnerability.severities
        return Object.keys(this.summaryData || {})
      },
    },

    methods: {
      commaInThousands: NumberHelpers.commaInThousands,
      titleCase: StringHelpers.titleCase,

      getLinkBySeverity(severity) {
        const severityData = this.summaryData[severity]
        const maxVulnId = (severityData || []).reduce((finalId, info) => {
          if (!finalId) return parseInt(info.vulnerability_scan_id)

          return Math.max(finalId, parseInt(info.vulnerability_scan_id))
        }, null)

        return `/vulnerability/${maxVulnId}?severity=${severity}`
      },

      getTotal(severity, separateCommas = false) {
        if (!this.summaryData) return 0

        const total = (this.summaryData[severity] || []).reduce(
          (t, rec) => t + parseInt(rec.count),
          0
        )
        return separateCommas ? this.commaInThousands(total) : total
      },

      getTotalByStatus(severity, status) {
        if (!this.summaryData) return 0

        return (
          (
            (this.summaryData[severity] || []).find(
              (rec) => rec.status === status
            ) || {}
          ).count || 0
        )
      },

      getStatusWidthPercentage(severity, status) {
        if (!this.summaryData) return 0

        const statusData =
          (this.summaryData[severity] || []).find(
            (rec) => rec.status === status
          ) || {}
        return Math.ceil(
          (parseInt(statusData.count) / this.getTotal(severity)) * 100
        )
      },

      statusBgClassMap(status) {
        return `bg-transparent-dark-${status}`
      },
    },

    async mounted() {
      try {
        const response = await ApiVulnerability.getDashboardItemSummary(
          'severity'
        )
        this.summaryData = response.summary
      } catch (err) {
        window.toastr.error(err.message)
      } finally {
        this.isLoadingLocal = false
      }
    },
  }
</script>
