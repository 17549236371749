<template lang="pug">
  div
    div.d-flex.align-items-center
      user-avatar-group(
        :user-ids="ownerUserIds",
        max-height="25",
        :height="height",
        :should-show-close="editable",
        :groupMaxWidth="groupMaxWidth"
        @close="deleteUser")
      div
        //- :class="ownerUserIds.length > 0 ? 'fa-plus-circle' : 'fa-user-plus'"
        i.fal.fa-user-plus.p-1(
          v-if="editable"
          @click.stop="toggleAddNewUser")
      div(v-if="toggleNewUser")
        v-select(
          append-to-body
          :calculate-position="withPopper"
          v-model="addedUserId"
          :options="availableUsers"
          :reduce="u => u.id"
          :get-option-label="u => u.name || u.username_email"
          @input="addUser"
          @click.native.stop.prevent)
</template>

<script>
  import { mapState } from 'vuex'
  import { createPopper } from '@popperjs/core'

  export default {
    props: {
      owners: { type: Array },
      height: { type: String, default: '30px' },
      groupMaxWidth: { type: Number, default: 100 },
      editable: { type: Boolean, default: true },
      dropdownUsers: { type: Array, required: false },
    },

    data() {
      return {
        toggleNewUser: false,
        addedUserId: null,
        placement: 'bottom',
      }
    },

    computed: {
      ...mapState({
        users: (state) => state.team.teamAndInternalUsers,
      }),

      ownerUserIds() {
        return (this.owners || []).map((o) => o?.user_id || o?.id || o)
      },

      availableUsers() {
        const userIds = this.ownerUserIds.map((id) => parseInt(id))
        return (this.dropdownUsers || this.users).filter(
          (user) => !userIds.some((uid) => uid == user.id)
        )
      },
    },

    methods: {
      toggleAddNewUser() {
        this.toggleNewUser = !this.toggleNewUser
      },

      addUser() {
        this.$emit('add', this.addedUserId)
        this.addedUserId = this.toggleNewUser = null
      },

      deleteUser(userId) {
        this.$emit('delete', userId)
      },

      // Popper allows us to be more explicit about dropdown positioning. We're using it here to make the dropdown be directly
      // below the down arrow and not go off the page in some instances (as it would do by default when the dropdown is on the right
      // side of the viewport).
      // https://vue-select.org/guide/positioning.html#popper-js-integration

      withPopper(dropdownList, component, { width }) {
        dropdownList.style.width = width

        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: this.placement,
          modifiers: [
            {
              name: 'toggleClass',
              phase: 'write',
              enabled: true,
              fn({ state }) {
                component.$el.classList.toggle(
                  'dropdown',
                  state.placement === 'bottom'
                )
              },
            },
          ],
        })
        return () => popper.destroy()
      },
    },
  }
</script>
