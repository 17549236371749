<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-hidden="true",data-backdrop="static",data-keyboard="false")
    div.modal-dialog(role="document")
      div.modal-content
        form(@submit="createItem")
          div.modal-header
            h5.modal-title {{ (computedItemId) ? 'Update' : 'New' }} Risk Register Item
            button.close(:id="`close-modal-x-${_uid}`", type="button",aria-label="Close")
              span(aria-hidden="true") &times;
              confirmation-popover(
                :target="`close-modal-x-${_uid}`",
                header="Confirm Cancel?",
                :text="`If there are any changes, progress will be lost`",
                @confirm="closeModal"
              )
          div.modal-body
            div.form-group(v-if="!registerId")
              label Choose Risk Register: (or 
                a(@click="showCreateModal()") Create New)
              select.form-control(v-model="selectedRegisterId", @change="getRegisterRecord()")
                option(:value="null") Select risk register...
                option(
                  v-for="register in riskRegisters",
                  v-html="register.name",
                  :value="register.id")
            div.form-group(v-if="computedRegisterId")
              template(v-if="canLinkExisting")
                label {{ labelText }}: (or 
                  a(@click="createNew = !createNew") {{ toggleText }})
                select.form-control(v-if="!createNew", v-model="selectedItemId", @change="getItemRecord()")
                  option(:value="null") Select risk register item...
                  option(
                    v-for="item in riskRegisterItems",
                    v-html="item.description",
                    :value="item.id")
              item-edit-form(
                v-if="!canLinkExisting || createNew"
                v-model="itemRecord"
                :register-id="computedRegisterId"
                :uid="computedItemId")
          div.modal-footer
            button.btn.btn-secondary(:id="`close-modal-btn-${_uid}`" type="button") Cancel
              confirmation-popover(
                :target="`close-modal-btn-${_uid}`",
                header="Confirm Cancel?",
                :text="`If there are any changes, progress will be lost`",
                @confirm="closeModal"
              )
            button.btn.btn-primary(type="submit",v-if="!this.computedItemId",@click="createAnother = true") Add and Create Another
            button.btn.btn-primary(type="submit", :disabled="isCreatingItem") 
              div(v-if="!isCreatingItem") {{ (this.computedItemId) ? 'Update' : 'Create' }}
              loader-inline.mx-3(v-else)
    
    create-register-modal#create-register-modal(@created="registerCreated")
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import CreateRegisterModal from '../riskregister/CreateRegisterModal'
  import ItemEditForm from '../riskregister/ItemEditForm'
  import ApiRiskRegister from '../../factories/ApiRiskRegister'
  import StringHelpers from '../../factories/StringHelpers'

  export default {
    props: {
      registerId: { type: [Number, String], default: null },
      itemId: { type: [Number, String], default: null },
      redirectOnCreate: { type: Boolean, default: true },
      canLinkExisting: { type: Boolean, default: false },
    },

    data() {
      return {
        createAnother: false,
        itemRecord: { status: 'not_started', owners: [] },
        selectedRegisterId: null,
        selectedItemId: null,
        createNew: true,
        isCreatingItem: false,
      }
    },

    computed: {
      ...mapState({
        riskRegisters: (state) => state.riskregister.riskRegisters,
        riskRegisterItems: (state) => state.riskregister.items.data,
      }),

      computedRegisterId() {
        return this.selectedRegisterId || this.registerId
      },

      computedItemId() {
        return this.selectedItemId || this.itemId
      },

      labelText() {
        return this.createNew ? 'Create New Item' : 'Choose Existing Item'
      },

      toggleText() {
        return this.createNew ? 'Choose Existing Item' : 'Create New Item'
      },
    },

    methods: {
      titleCase: StringHelpers.titleCase,

      showCreateModal() {
        $(`#${this.$el.id}`).modal('hide')
      },

      closeModal() {
        $(`#${this.$el.id}`).modal('hide')
      },

      resetItemRecord() {
        this.selectedItemId = null
        return (this.itemRecord = {
          status: 'not_started',
          owners: [],
        })
      },

      async registerCreated(id) {
        await this.$store.dispatch('getRegisters', { reset: true })
        this.selectedRegisterId = id
        this.getRegisterRecord(id)
      },

      async getRegisterRecord(id = this.computedRegisterId) {
        if (id) {
          await this.$store.dispatch('getRiskRegisterRecord', id)
          await this.$store.dispatch('getRiskRegisterItems', { reset: true })
        }
      },

      async getItemRecord(id = this.computedItemId) {
        if (!id) return
        try {
          const { item, owners } = await ApiRiskRegister.getItem(id)
          Object.keys(item).forEach((key) => {
            this.$set(this.itemRecord, key, item[key])
          })
          this.itemRecord.owners = owners.map((u) => u.user_id || u.id)
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async createItem(evt) {
        try {
          evt.preventDefault()
          this.isCreatingItem = true
          if (
            !(
              this.itemRecord.risk_register_category_id &&
              this.itemRecord.description
            )
          )
            return window.toastr.error(
              `Please enter at least a category and short description for the item.`
            )

          const { id } = await ApiRiskRegister.createOrUpdateItem({
            ...this.itemRecord,
            risk_register_id:
              this.computedRegisterId || this.itemRecord.risk_register_id,
          })
          await ApiRiskRegister.updateItemOwners(id, this.itemRecord.owners)

          window.toastr.success(`Successfully updated your item!`)
          this.$emit('created', { ...this.itemRecord, id })

          this.resetItemRecord()

          if (!this.createAnother) {
            $(`#${this.$el.id}`).modal('toggle')
            if (!this.itemRecord.id && this.redirectOnCreate)
              this.$router.push(
                `/risk/register/${
                  this.computedRegisterId || this.itemRecord.risk_register_id
                }/item/${id}`
              )
          }
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.createAnother = false
          this.isCreatingItem = false
        }
      },
    },

    mounted() {
      $(`#create-register-modal`).appendTo('body')
      $(`#create-register-modal`).on('hidden.bs.modal', async () => {
        $(`#${this.$el.id}`).modal('show')
      })

      $(`#${this.$el.id}`).on('shown.bs.modal', async () => {
        if (!this.registerId) {
          await this.$store.dispatch('initRiskRegister')
          await this.$store.dispatch('getRegisters', { reset: true })
        }

        if (!this.computedItemId) return this.resetItemRecord()

        await this.getItemRecord()
      })
    },

    beforeDestroy() {
      $(`#create-register-modal`).remove()
    },

    components: {
      CreateRegisterModal,
      ItemEditForm,
    },
  }
</script>
