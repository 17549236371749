<template lang="pug">
  - // .no-shadow
  div.card.card-small
    div.card-header.border-bottom
      small
        strong Vulnerability Item Owners
    div.card-body.p-3
      div(v-if="isLoadingLocal")
        loader
      div(v-else)
        div.p-2.text-center(v-if="owners.length === 0")
          i No owners yet to report on.
        div.mt-1(v-if="owners.length > 0",v-for="owner in owners")
          div
            router-link(:to="'/vulnerability/' + summaryData[owner][0].vulnerability_scan_id + '?assigned_user_id=' + summaryData[owner][0].assigned_user_id") {{ truncateString(owner, 20) }}: ({{ getTotalByOwner(owner) }})
          div.progress
            template(
              v-for="severity in severities"
              v-if="getSeverityWidthPercentage(owner, severity) !== 0")
                div.progress-bar.status(
                  :id="`owner-summary-progress-${owner.replace(/\\s/g, '_')}-${severity}`"
                  role="progressbar"
                  :class="severityBgClassMap(severity)"
                  :style="{ width: getSeverityWidthPercentage(owner, severity) + '%' }")
                b-tooltip(:target="`owner-summary-progress-${owner.replace(/\\s/g, '_')}-${severity}`")
                  | {{ titleCase(severity, true) }}: {{ getTotalByOwnerAndSeverity(owner, severity) }}
        div.row.mt-4
          div.col-sm-6.mb-1(v-for="severity in severities")
            div.row.no-gutters
              div.col-3
                div.mr-2.legend-bubble.status(:class="severityBgClassMap(severity)")
              div.col-9
                div.small.overflow-ellipses.d-flex.align-items-center
                  small {{ titleCase(severity, true) }}
</template>

<script>
  import ApiVulnerability from '../../../../factories/ApiVulnerability'
  import StringHelpers from '../../../../factories/StringHelpers'

  export default {
    data() {
      return {
        isLoadingLocal: true,
        summaryData: null,
      }
    },

    computed: {
      owners() {
        return Object.keys(this.summaryData || {})
      },

      severities() {
        return this.$store.state.vulnerability.severities
      },
    },

    methods: {
      titleCase: StringHelpers.titleCase,
      truncateString: StringHelpers.truncateString,

      getSeverityWidthPercentage(owner, severity) {
        if (!this.summaryData) return 0

        const ownerSevData =
          this.summaryData[owner].find((rec) => rec.severity === severity) || {}
        return Math.ceil(
          (parseInt(ownerSevData.count || 0) / this.getTotalByOwner(owner)) *
            100
        )
      },

      getTotalByOwner(owner) {
        return (this.summaryData || {})[owner].reduce(
          (total, info) => total + parseInt(info.count || 0),
          0
        )
      },

      getTotalByOwnerAndSeverity(owner, severity) {
        if (!this.summaryData) return 0

        const ownerData = (this.summaryData || {})[owner]
        return (
          (ownerData.find((rec) => rec.severity === severity) || {}).count || 0
        )
      },

      severityBgClassMap(severity) {
        return `bg-transparent-dark-${severity}`
      },
    },

    async mounted() {
      try {
        const response = await ApiVulnerability.getDashboardOwnerSummary()
        this.summaryData = response.summary
      } catch (err) {
        window.toastr.error(err.message)
      } finally {
        this.isLoadingLocal = false
      }
    },
  }
</script>
