<template lang="pug">
div.row
  div.col-md-6.offset-md-3
    h4.text-center.m-0 Confirm &amp; Submit
    div.mb-2.text-gray
      | Review your report configuration below and submit when
      | you're ready!
    div.card.card-small.no-shadow.border.mb-2
      div.card-body
        div.form-row
          div.col-12.form-group.m-0
            label Your Report Name
            h5.m-0.text-center {{ reportConfig.reportName }}
    div.card.card-small.no-shadow.border
      div.card-body
        div.form-row
          div.col-12.form-group.m-0
            label Are All Filters Filled Out?
            div.text-center
              i.fal.fa-3x(:class="(isValid) ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'")
    
              div.card.card-small.no-shadow.border
    div.card-body
      div.form-row
        button.btn.btn-white(@click="saveTeamConfigurations") Save Report Configuration
</template>

<script>
  import ApiReports from '../../../factories/ApiReports'

  export default {
    props: {
      reportConfig: { type: Object },
      isValid: { type: Boolean },
      reportTypeId: { type: [Number, String] },
    },

    methods: {
      async saveTeamConfigurations() {
        try {
          await ApiReports.saveTeamConfigurations({
            reportTypeId: this.reportTypeId,
            name: 'New Configuration',
            ...this.reportConfig,
          })
          window.toastr.success('Report configuration saved successfully!')
        } catch (err) {
          window.toastr.error(
            `Error saving report configuration. ${err.message}`
          )
        }
      },
    },
  }
</script>
