<template lang="pug">
  div
    div(v-if="canAnswerQuestionnaire")
      rich-text-editor.border-bottom(
        v-model="mutableVal"
        rows="5")
    div.card.border.no-shadow(v-else)
      div.card-body.p-2(v-html="mutableVal")
</template>

<script>
  import utilities from '@risk3sixty/utilities'
  import { mapState } from 'vuex'

  export default {
    props: {
      value: { type: Array, default: null },
      choices: { type: Array, default: null },
    },

    computed: {
      ...mapState({
        canAnswerQuestionnaire: (_, getters) => getters.canAnswerQuestionnaire,
      }),

      mutableVal: {
        get() {
          const defaultVal = this.canAnswerQuestionnaire
            ? ''
            : 'No answer yet...'
          return ((this.value || [])[0] || {}).text || defaultVal
        },

        set: utilities.debounce(function(text) {
          const opt = this.choices[0]
          const val = [{ choice_id: opt.id, text }]

          this.$emit('input', val)
          this.$emit('change', val)
        }, 500),
      },
    },
  }
</script>
