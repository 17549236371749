<template lang="pug">
  td(:class="cellVariantClass")
    div.text-center(v-if="isMinimized") ...
    component(
      v-else
      v-model="row.values[field.id]"
      :is="fieldTypeComponent"
      :canEdit="canEdit"
      :field="field"
      :row="row"
      :columns="columns"
      @input="valueChanged")
</template>

<script>
  import { mapState } from 'vuex'
  import AssessmentControlType from './td-types/AssessmentControl'
  import DateType from './td-types/Date'
  import EnumType from './td-types/Enum'
  import ExpressionType from './td-types/Expression'
  import NumberType from './td-types/Number'
  import RiskType from './td-types/Risk'
  import TagType from './td-types/Tag'
  import TextType from './td-types/Text'
  import UserType from './td-types/User'
  import VendorType from './td-types/Vendor'
  import ApiSpreadsheet from '../../../factories/ApiSpreadsheet'

  export default {
    props: {
      spreadsheetId: { type: [Number, String], required: true },
      field: { type: Object, required: true },
      row: { type: Object, required: true },
      columns: { type: Array, required: true },
      canEdit: { type: Boolean, default: true },
    },

    computed: {
      ...mapState({
        cellVariantClass(state) {
          if (this.isMinimized) return
          if (this.field.type !== 'enum') return

          const theEnum = state.team.enums.find(
            (e) => e.id == this.field.enum_options_id
          )
          if (!theEnum) return

          const theVal = theEnum.values.find(
            (v) => v.id == this.row.values[this.field.id]
          )
          if (theVal) return theVal.variant
        },

        isMinimized(state) {
          return (
            state.auth.personalizations[this.minimizeKey] &&
            state.auth.personalizations[this.minimizeKey].parameters[
              this.field.id
            ]
          )
        },
      }),

      minimizeKey() {
        return `spreadsheet_fields_minimized_${this.spreadsheetId}`
      },

      fieldTypeComponent() {
        switch (this.field.type) {
          case 'assessment_control':
            return 'AssessmentControlType'
          case 'date':
            return 'DateType'
          case 'enum':
            return 'EnumType'
          case 'expression':
            return 'ExpressionType'
          case 'number':
            return 'NumberType'
          case 'risk':
            return 'RiskType'
          case 'tag':
            return 'TagType'
          case 'user':
            return 'UserType'
          case 'vendor':
            return 'VendorType'
          default:
            return 'TextType'
        }
      },
    },

    methods: {
      updated() {
        this.$emit('updated', ...arguments)
      },

      async valueChanged(newVal) {
        if (!this.canEdit) return
        try {
          await ApiSpreadsheet.saveValue(this.row.row_id, this.field.id, newVal)
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    components: {
      AssessmentControlType,
      DateType,
      EnumType,
      ExpressionType,
      NumberType,
      RiskType,
      TagType,
      TextType,
      UserType,
      VendorType,
    },
  }
</script>
