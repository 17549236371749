<template lang="pug">
  b-tooltip(
    :target="target"
    placement="right"
    custom-class="user-role-tt text-left"
    boundary="window")
    h6 User Roles
    div.table-responsive.mb-0(style="font-size: 9px;")
      table.table.small-padding.table-striped.table-bordered
        thead
          tr
            th Role
            th Description
        tbody
          tr
            td Admin
            td Client Admin can perform any function in the application. Assign this role sparingly.
          tr
            td Manager
            td
              | Managers can do anything within a module they have been assigned by an admin.
              | Only assign a user as a manager if they will manage an entire module.
          tr
            td Analyst
            td Analyst have the most restricted access. Most users should be analysts.
</template>

<script>
  export default {
    props: {
      target: { type: String, required: true },
    },
  }
</script>

<style lang="scss">
  .user-role-tt {
    .tooltip-inner {
      max-width: 100% !important;
      min-width: 20vw;
    }
  }
</style>
