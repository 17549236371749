<template lang="pug">
  div.table-responsive
    table.table(:class="bordered && 'table-bordered'")
      thead.bg-light
        tr
          th Name
          th.text-center Send Reminder
          th.text-right
            i.ml-2.clickable.fal.fa-plus-circle.text-success(
              v-if="canCreateUser"
              @click="resetAndShowModal")
      tbody
        tr(v-if="!users || users.length === 0")
          td(colspan="100%")
            i No upload users yet...
        tr.border-bottom(v-else,v-for="(u, ind) in users")
          td
            div.d-flex
              div(:id="`email-address-on-hover-${u.id}`") {{ u.name || u.username_email }}
              b-tooltip(:target="`email-address-on-hover-${u.id}`") {{ u.username_email }}
          td
            div.d-flex.justify-content-center
              div
                i.text-danger.fa.fa-paper-plane.clickable(:id="`send-individual-email-${_uid}-${u.id}`")
                b-tooltip(:target="`send-individual-email-${_uid}-${u.id}`") Send reminder email
                confirmation-popover(
                  :target="`send-individual-email-${_uid}-${u.id}`"
                  header="Confirm Send Email"
                  :text="`Are you sure you want to send a reminder email to ${u.name}?`"
                  @confirm="$emit('sendEmail', u.id)")
          td.d-flex.justify-content-end.border-0.pt-3
            i.clickable.fal.fa-pencil.text-success.mx-2(
              v-if="canEditUser"
              :id="`user-list-edit-${_uid}-${u.id}`"
              @click="editUser(u)")
            b-tooltip(:target="`user-list-edit-${_uid}-${u.id}`",)
              | Edit Upload User
            i.clickable.fal.fa-times-circle.text-danger(
              :id="`user-list-remove-${_uid}-${u.id}`")
            b-tooltip(:target="`user-list-remove-${_uid}-${u.id}`")
              | Remove Upload User
            confirmation-popover(
              :target="`user-list-remove-${_uid}-${u.id}`",
              header="Confirm Remove Upload User?",
              text="Are you sure you want to remove this upload user?",
              @confirm="removeUser(u.id)")

    upload-user-modal(:id="`upload-user-modal-${this._uid}`" :user="mutableUser" :modalTitle="modalTitle" @updatedUser="updatedUser")
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'

  export default {
    props: {
      users: { type: Array, required: true },
      modalTitle: { type: String, default: 'Add Upload User' },
      bordered: { type: Boolean, default: false },
      canCreateUser: { type: Boolean, default: true },
      canEditUser: { type: Boolean, default: true },
    },

    data() {
      return {
        mutableUser: {
          username_email: null,
          first_name: null,
          last_name: null,
          job_title: null,
        },
      }
    },

    methods: {
      resetModal() {
        // This is here due to this issue
        // https://stackoverflow.com/questions/10636667/bootstrap-modal-appearing-under-background
        $(`#upload-user-modal-${this._uid}`).appendTo('body')
      },

      resetAndShowModal() {
        this.resetUser()
        this.resetModal()
        $(`#upload-user-modal-${this._uid}`).modal('show')
      },

      resetUser() {
        this.mutableUser = {
          username_email: null,
          first_name: null,
          last_name: null,
          job_title: null,
        }
      },

      editUser(user) {
        user.first_name = user.first_name || user.name.split(' ')[0]
        user.last_name = user.last_name || user.name.split(' ')[1]

        this.mutableUser = {
          ...user,
        }
        $(`#upload-user-modal-${this._uid}`).modal('show')
      },

      updatedUser(userId) {
        this.$emit('updatedUser', userId)
      },

      removeUser(userId) {
        this.$emit('removeUser', userId)
      },
    },

    mounted() {
      this.resetModal()
    },

    beforeDestroy() {
      // See comments in this.resetModal as to why this needs to be here.
      $(`#upload-user-modal-${this._uid}`).remove()
    },
  }
</script>
