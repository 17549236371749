<template lang="pug">
  div(:id="`flag-question-${_uid}`")
    a.vendorbase-entity-icon.d-block.d-flex.align-items-center(@click="flag")
        div.d-flex.align-items-center.justify-content-center.vendorbase-entity-button
          i.fa-flag(:class="flagClass")
    b-tooltip(:target="`flag-question-${_uid}`")
      | Flag this question as a reminder to
      | come back and review later.
</template>

<script>
  import { mapState } from 'vuex'
  import ApiVendorbase from '../../../factories/ApiVendorbase'

  export default {
    props: {
      questionId: { type: [Number, String], required: true },
      questionnaireId: { type: [Number, String], required: true },
      useOutlinedFlag: { type: Boolean, default: false },
    },

    data() {
      return {
        updatingFlag: false,
      }
    },

    computed: {
      ...mapState({
        isFlagged(state) {
          return state.questionnaire.flags
            .map((f) => f.id.toString())
            .includes(this.questionId.toString())
        },
        isSelfAssessment: (_, getters) => getters.isSelfAssessment,
      }),

      flagClass() {
        // Instantly change the flag color while loading. Will change back once done loading if there is an error (along with error toastr)
        const flaggedClass = 'fa danger'
        const unflaggedClass = this.useOutlinedFlag ? 'far' : 'fa'
        if (this.updatingFlag) {
          return this.isFlagged ? unflaggedClass : flaggedClass
        }
        return this.isFlagged ? flaggedClass : unflaggedClass
      },
    },

    methods: {
      async flag() {
        try {
          this.updatingFlag = true
          await ApiVendorbase.flagQuestion(
            this.questionId,
            this.isSelfAssessment
          )
          await this.$store.dispatch('getQuestionnaireFlags', {
            questionnaireId: this.questionnaireId,
            getGlobalFlags: this.isSelfAssessment,
          })
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.updatingFlag = false
        }
      },
    },
  }
</script>
