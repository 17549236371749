<template lang="pug">
  div(:id="`change-log-${_uid}`")
    a.vendorbase-entity-icon.d-block.d-flex.align-items-center(
      data-toggle="modal",
      :data-target="`#${modalId}`",
      :class="changeLog.length > 0 ? 'info': ''")
      div.d-flex.align-items-center.justify-content-center.vendorbase-entity-button
        i.fa.fa-history
      div.vendorbase-entity-count.d-flex.align-items-center.justify-content-center
        | {{ changeLog.length }}

    div.modal.fade(:id="modalId",tabindex="-1",role="dialog",aria-labelledby="change-log-modal",aria-hidden="true")
      div.modal-dialog.modal-lg(role="document")
        div.modal-content
          div.modal-header
            h5.modal-title {{ modalTitle || 'History' }}
            button.close(type="button",data-dismiss="modal",aria-label="Close")
              span(aria-hidden="true") &times;
          div.modal-body
            div(v-if="changeLog.length > 0")
              div(v-for="item in changeLog")
                change-log-item.mb-3(:data="item", :choices="choices")
            div(v-else)
              i No history...
          div.modal-footer
            button.btn.btn-secondary(type="button",data-dismiss="modal") Close
</template>

<script>
  import $ from 'jquery'

  export default {
    props: {
      changeLog: { type: Array, required: true },
      choices: { type: Array },
      modalTitle: { type: String, default: null },
      wrapperBorder: { type: Boolean, default: false },
    },

    data() {
      return {}
    },

    computed: {
      modalId() {
        return `change-log-modal-${this._uid}`
      },

      iconWrapperStyle() {
        return this.wrapperBorder
          ? {}
          : {
              borderWidth: '0px',
              borderRadius: 'inherit',
              width: 'auto',
              height: 'auto',
            }
      },
    },

    methods: {
      resetUpdateModal() {
        // This is here due to this issue
        // https://stackoverflow.com/questions/10636667/bootstrap-modal-appearing-under-background
        $(`#${this.modalId}`).appendTo('body')
      },
    },

    async mounted() {
      this.resetUpdateModal()
    },

    beforeDestroy() {
      $(`#${this.modalId}`).remove()
    },
  }
</script>
