<template lang="pug">
  ul.d-flex.flex-wrap.align-items-center.pl-0(style="width: 10rem; margin-bottom: 0; list-style-type: none;")
    li.w-100.mr-2
      img.img-fluid(
        v-if="logo"
        :class="imgClasses || ''"
        style="min-width: 30px;"
        :style="maxHeight && {'max-height': maxHeight}"
        :src="`/file/s3/${logo}`")
    li.w-100.h-20
      div.overflow-ellipses.no-hover
        | {{ name }}
</template>

<script>
  export default {
    props: {
      name: { type: String, required: true },
      logo: { type: String, default: null },
      imgClasses: { type: String, default: null },
      maxHeight: { type: String, default: '30px' },
    },
  }
</script>
