<template lang="pug">
  div.d-flex.align-items-center
    div.input-group
      div.custom-file
        input#upload-input.py-2(
          type="file"
          ref="file"
          accept=".json"
          @change="readGcpConfigFile($event)"
        )
        label.clickable.custom-file-label(for="upload-input", :class="uploadedFile.name ? '' : 'font-italic text-secondary'") {{ uploadedFile.name || 'Choose file' }}
</template>

<script>
  export default {
    props: {
      value: { type: String, default: null },
      size: { type: String, default: null },
    },

    data() {
      return {
        uploadedFile: '',
      }
    },

    computed: {
      compVal: {
        get() {
          return this.value
        },

        set(t) {
          this.$emit('input', t)
        },
      },
    },

    methods: {
      readGcpConfigFile(event) {
        var file = event.target.files[0]
        this.uploadedFile = file

        const reader = new FileReader()
        reader.onload = (res) => {
          this.$emit('input', res.target.result)
        }
        reader.onerror = (err) => window.toastr.error(err)
        reader.readAsText(file)
        event.target.value = null
      },
    },
  }
</script>
