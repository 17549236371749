<template lang="pug">
  div
    div.form-group.mb-2
      label Name #[span.text-danger *]
      textarea.form-control(
        v-model="mutableVal.name"
        @blur="update"
        :disabled="mutableVal.is_published && !isInternalAdmin")
    div.form-group.mb-2
      label Description
      textarea.form-control(
        v-model="mutableVal.description"
        @blur="update"
        :disabled="mutableVal.is_published && !isInternalAdmin")
    div.form-group.mb-2
      label Due Date
      datepicker-shards(
        :show-clear="true",
        :input-id="`questionnaire-due-${_uid}`",
        v-model="mutableVal.due_date")

    hr
    div.form-group
      label.m-0 Reviewers
      select.form-control(v-model="reviewUser",@change="addReviewer(reviewUser)")
        option(v-for="user in usersWithPermission",:value="user") {{ user.name || user.username_email }}
      div.my-2
        div.small(v-if="!mutableVal.reviewers || mutableVal.reviewers.length === 0")
          i No reviewers selected yet...
        div.d-flex.align-items-center(v-else)
          user-avatar.mr-1(
            v-for="(user, ind) in mutableVal.reviewers",
            :key="user.id",
            :id="`questionnaire-reviewer-user-avatar-${user.id}`",
            :user-id="user.id",
            @close="mutableVal.reviewers.splice(ind, 1)")

</template>

<script>
  import { mapState } from 'vuex'
  import StringHelpers from '../../factories/StringHelpers'

  export default {
    props: {
      value: { type: Object, required: true },
      uid: { type: [Number, String], default: null },
    },

    watch: {
      value: {
        handler(newVal) {
          this.mutableVal = newVal
        },
        deep: true,
      },
    },

    data() {
      return {
        reviewUser: null,
        mutableVal: this.value,
      }
    },

    computed: {
      ...mapState({
        isInternalAdmin: (_, getters) => getters.isInternalAdmin,
        isInternalUser: (_, getters) => getters.isInternalUser,

        usersWithPermission: (state) =>
          state.getUsersWithPerrmissionToModule(
            'is_vendor_management_customer'
          ),
      }),

      // reviewQuestionnaireUrl() {
      //   return `/api/1.0/vendormanagement/questionnaire/review/answers?questionnaireId=${this.mutableVal.id}&redirect=${encodeURIComponent(this.$route.path)}`
      // }
    },

    methods: {
      titleCase: StringHelpers.titleCase,

      addReviewer(newUser) {
        this.mutableVal.reviewers = this.mutableVal.reviewers || []
        this.mutableVal.reviewers.push(newUser)
        this.mutableVal.reviewers = [...new Set(this.mutableVal.reviewers)]
        this.reviewUser = null
        this.update()
      },

      update() {
        this.$emit('input', this.mutableVal)
      },
    },
  }
</script>
