<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog")
    div.modal-dialog.modal-max(role="document")
      div.modal-content
        div.modal-header
          h5.modal-title.overflow-ellipses.no-hover Preview {{ decodeURIComponent(title) }}
          button.close(type="button",data-dismiss="modal",aria-label="Close")
            span(aria-hidden="true") &times;
        div.modal-body
          preview-object(
            v-if="fileId && fileName && showPreview"
            :file-id="fileId"
            :file-name="fileName")
        div.modal-footer
          button.btn.btn-secondary(type="button",data-dismiss="modal") Close
</template>

<script>
  import $ from 'jquery'

  export default {
    props: {
      fileId: { type: [Number, String], required: false },
      fileName: { type: String, required: false },
      title: { type: String, required: false },
    },

    data() {
      return {
        showPreview: false,
      }
    },

    mounted() {
      $(`#${this.$el.id}`).on('shown.bs.modal', async () => {
        this.showPreview = true
      })

      $(`#${this.$el.id}`).on('hide.bs.modal', async () => {
        this.showPreview = false
      })
    },
  }
</script>
