<template lang="pug">
div
  template(v-if="getTotal === 0")
    i {{ placeholder }}
  template(v-else)
    div.progress.m-0(:style="`height: ${height}px; font-size: ${parseInt(height) * 0.75 }px`")
      template(v-for="(item, ind) in items.filter((item) => item.value != 0)")
        div.progress-bar(
          v-if="getTotalByKey(item.key) !== 0",
          :id="`progress-bar-${uid}-${item.key}`",
          :class="`${bgClassMapByKey(item.variant)} ${selectedKey === item.key ? 'selected-bottom' : ''}`",
          role="progressbar",
          :style="`width: ${getWidthPercentageByKey(item.key)}%; min-width: 10px; height:${height}px;`",
          @click="$emit('keySelected', item.key), setSelectedKey(item.key)")
            div.w-100.d-flex.align-items-center.strong(v-if="item.startText || item.middleText || item.endText")
              div(v-if="item.startText") {{ item.startText }}
              div.mr-auto.ml-auto(v-if="item.middleText") {{ item.middleText }}
              div.ml-auto(v-if="item.endText") {{ item.endText }}
        b-tooltip(
          :key="ind",
          :target="`progress-bar-${uid}-${item.key}`") {{ titleCase(item.key, true) }}: {{ getTotalByKey(item.key) }}
</template>

<script>
  import StringHelpers from '../../factories/StringHelpers'

  export default {
    props: {
      uid: { type: String, required: true },
      items: { type: Array, required: true },
      height: { type: [String, Number], default: 10 },
      placeholder: { type: String, default: `No items yet...` },
      selectedKey: { type: Boolean, default: false },
    },

    computed: {
      getTotal() {
        return this.items.reduce((t, rec) => t + parseInt(rec.value), 0)
      },
    },

    methods: {
      titleCase: StringHelpers.titleCase,

      getTotalByKey(key) {
        const data = this.items.find((info) => info.key === key) || {}
        return data.value || 0
      },

      getWidthPercentageByKey(key) {
        const data = this.items.find((info) => info.key === key) || {}
        return Math.ceil((parseInt(data.value || 0) / this.getTotal) * 100)
      },

      bgClassMapByKey(variant) {
        return `bg-${variant}`
      },
    },
  }
</script>
<style lang="scss" scoped>
  .selected-bottom {
    border-bottom: 3px solid #138ce9;
  }
</style>
