<template lang="pug">
  div
    div(v-if="isLoadingLocal")
      loader
    div(v-else)
      div.mb-1
        a.btn.btn-vsm.btn-primary(href="/auth/slack") Add Slack Channel
      div.table-responsive.mb-0
        table.table.table-bordered.small-padding
          thead.bg-light
            tr
              td Channel
              td Slack Team
              td Test
              td
                i.fal.fa-times.text-danger
          tbody
            tr(v-if="channels.length === 0")
              td(colspan="100%")
                i No channels added yet...
            tr(v-else,v-for="channel in channels")
              td {{ channel.slack_channel_name }}
              td {{ channel.slack_team_name }}
              td
                a(@click="testChannel(channel)")
                  button.btn.btn-vsm.btn-success Test
              td
                a(@click="removeSlackChannel(channel)")
                  i.fal.fa-times.text-danger
</template>

<script>
  import ApiIntegrations from '../../factories/ApiIntegrations'

  export default {
    data() {
      return {
        isLoadingLocal: true,
        channels: []
      }
    },

    methods: {
      async testChannel(channel) {
        try {
          await ApiIntegrations.testSlackChannel(channel.id)
          window.toastr.success(`Successfully sent a test message to channel ${channel.slack_channel_name}!`)
        } catch(err) {
          window.toastr.error(err.message)
        }
      },

      async removeSlackChannel(channel) {
        try {
          await ApiIntegrations.deleteSlackChannel(channel.id)
          await this.getChannels()
        } catch(err) {
          window.toastr.error(err.message)
        }
      },

      async getChannels() {
        try {
          const { channels } = await ApiIntegrations.getSlackChannels()
          this.channels = channels
        } catch(err) {
          window.toastr.error(err.message)
        } finally {
          this.isLoadingLocal = false
        }
      }
    },

    async created() {
      await this.getChannels()
    }
  }
</script>
