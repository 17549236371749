<template lang="pug">
  b-popover(
    :target="target"
    :placement="placement"
    boundary="viewport"
    :show.sync="isShowing")
      template(slot="title")
        div.d-flex.align-items-center
          h6.mb-0.mr-4 Find Request
          div.ml-auto
            i.fal.fa-times.clickable(@click="isShowing = false")
      assessment-request-finder(
        :value="value"
        @input="update"
        @reset="reset")
</template>

<script>
  export default {
    props: {
      target: { type: String, required: true },
      placement: { type: String, default: 'right' },
      value: { type: Object, default: null },
    },

    data() {
      return {
        isShowing: false,
      }
    },

    computed: {
      requestRecord() {
        return (this.value || {}).request
      },
    },

    methods: {
      update(val) {
        this.$emit('input', val)
      },

      reset() {
        this.$emit('input', null)
      },
    },
  }
</script>
