<template lang="pug">
  div
    loader-inline(v-if="isLoadingLocal")
    progress-bar.rr-severity-progress(
      v-else,
      :class="isLarge && 'large'",
      :is-large="isLarge",
      placeholder="No outstanding risks...",
      :uid="registerId",
      :items="progressBarData")
</template>

<script>
  import { mapState } from 'vuex'
  import ApiRiskRegister from '../../factories/ApiRiskRegister'
  import StringHelpers from '../../factories/StringHelpers'

  export default {
    props: {
      registerId: { type: [Number, String], required: true },
      items: { type: Array, default: null },
      metricData: { type: Array, default: null },
      isLarge: { type: Boolean, default: false },
    },

    data() {
      return {
        isLoadingLocal: false,
        registerItems: [],
      }
    },

    computed: mapState({
      progressBarData(state) {
        return (
          this.metricData ||
          state.riskregister
            .riskRegisterRiskScoreInfo(this.registerItems)
            .map((i) => {
              return {
                value: i.value,
                key: i.key,
                // startText: this.titleCase(i.key, true).replace(' Risk', ''),
                // endText: `${i.subvalue.split(' ')[0]} hr`,
                variant: i.variant,
              }
            })
        )
      },
    }),

    methods: {
      titleCase: StringHelpers.titleCase,
    },

    async created() {
      try {
        if (this.metricData) return
        if (this.items) return (this.registerItems = this.items)

        const { items } = await ApiRiskRegister.getAllItems(
          this.registerId,
          null,
          1,
          1e4
        )
        this.registerItems = items.data
      } catch (err) {
        window.toastr.error(err.message)
      } finally {
        this.isLoadingLocal = false
      }
    },
  }
</script>

<style lang="scss">
  .rr-severity-progress {
    &.large {
      .progress {
        height: 31px;
        border-radius: 0px;

        .progress-bar {
          height: 31px;
          padding-left: 4px;
          padding-right: 4px;
        }
      }
    }
  }
</style>
