<template lang="pug">
  div
    div(v-if="getTotal === 0")
      i {{ placeholder }}
    div.progress.m-0(v-else)
      template(
        v-for="status in allSeverities"
        v-if="getTotalByStatus(status) !== 0",)
          div.progress-bar.status(
            :id="`status-summary-progress-${scanId}-${status}`",
            :class="statusBgClassMap(status)",
            role="progressbar",
            :style="{ width: `${getStatusWidthPercentage(status)}%` }")
          b-tooltip(:target="`status-summary-progress-${scanId}-${status}`")
            | {{ titleCase(status, true) }}: {{ getTotalByStatus(status) }}
</template>

<script>
  import { mapState } from 'vuex'
  import StringHelpers from '../../factories/StringHelpers'

  export default {
    props: {
      scanId: { type: [Number, String] },
      counts: { type: Array, default: null },
      severities: { type: Array, default: null },
      placeholder: {
        type: String,
        default: `No open vulnerability items for scan.`,
      },
    },

    computed: {
      ...mapState({
        allSeverities(state) {
          return this.severities || state.vulnerability.severities
        },
      }),

      getTotal() {
        if (!this.counts) return 0

        return (this.counts || []).reduce(
          (t, rec) => t + parseInt(rec.count),
          0
        )
      },
    },

    methods: {
      titleCase: StringHelpers.titleCase,

      getTotalByStatus(status) {
        if (!this.counts) return 0

        const statusData =
          this.counts.find((info) => info.severity === status) || {}
        return statusData.count || 0
      },

      getStatusWidthPercentage(status) {
        if (!this.counts) return

        const statusData =
          this.counts.find((info) => info.severity === status) || {}
        return Math.ceil(
          (parseInt(statusData.count || 0) / this.getTotal) * 100
        )
      },

      statusBgClassMap(status) {
        if (!this.counts) return `bg-transparent-dark-${status}`

        const statusData = this.counts.find((info) => info.severity === status)
        if (statusData && statusData.variant)
          return `bg-transparent-vdark-${statusData.variant}`

        return `bg-transparent-dark-${status}`
      },
    },
  }
</script>
