<template lang="pug">
  div
    div(
      v-if="!isEditing"
      @click="canEdit ? isEditing = !isEditing : ''" :class="canEdit ? 'editable' : ''")
        div(v-if="userIds.length === 0")
          i {{canEdit ? 'No users...' : ''}}
        user-avatar-group(
          v-else
          :user-ids="userIds"
          max-height="25"
          height="30px"
          :should-show-close="canEdit"
          @close="removeUser")
    div(v-else)
      select-dropdown-filter(
        v-model="userIds"
        :multi="true"
        :text="field.key"
        :options="usersForSelect"
        :dropdown-right="true")
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    props: {
      value: { type: String, default: null },
      field: { type: Object, required: true },
      canEdit: { type: Boolean, default: true },
    },

    data() {
      return {
        isEditing: false,
      }
    },

    computed: {
      ...mapState({
        users: (state) => state.team.users || [],
      }),

      usersForSelect() {
        if (!this.users) return []
        return this.users.map((u) => ({
          text: u.name || u.username_email,
          value: u.id.toString(),
          // variant: v.variant,
        }))
      },

      userIds: {
        get() {
          return (this.value || '').split(',').filter((id) => !!id)
        },

        set(newVal) {
          this.updateVal(newVal.filter((i) => !!i).join(','))
        },
      },
    },

    methods: {
      removeUser(uid) {
        this.updateVal(this.userIds.filter((i) => i != uid).join(','))
      },

      updateVal(newVal) {
        if (newVal === this.value) return
        this.$emit('input', newVal)
        this.isEditing = false
      },
    },
  }
</script>
