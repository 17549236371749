<template lang="pug">
  div
    div.text-primary.m-0.d-flex.align-items-center
      a.ml-1(
        data-toggle="modal",
        :data-target="`#create-entity-calendar-event-${_uid}`",
        @click="handleResetEvent")
          strong.mr-1 {{ linkText || 'Calendar' }}
          i.fal.fa-calendar-plus
    template(v-if="getFirstCalendarEvId")
      div#entity-calendar-events
        router-link(:to="`/compliance/list/${getFirstCalendarEvId}`")
            | {{ existingEvents.length }} event(s)
            | #[i.fal.fa-info-circle]
      b-tooltip(target="entity-calendar-events")
        ol.m-0.pl-2
          li(v-for="ev in existingEvents") {{ getFormattedDate(ev.start_date, 'M/D/YYYY') }}: {{ ev.title }}

    calendar-event-modal(
      :id="`create-entity-calendar-event-${_uid}`"
      :event="newEventTemplate"
      :can-find-existing="!!mapExistingType"
      @select="mapEvent"
      @hide="handleResetEvent")
</template>

<script>
  import $ from 'jquery'
  import TimeHelpers from '../../factories/TimeHelpers'
  import CalendarEventModal from '../compliance/CalendarEventModal'

  export default {
    props: {
      newEventTemplate: { type: Object }, // Calendar event object to prepopulate data in the newly created calendar event
      existingEvents: { type: Array }, // Array of existing calendar event objects for the respective entity
      linkText: { type: String },
      mapExistingType: { type: String, default: null },
      mapExistingTypeId: { type: [Number, String], default: null },
    },

    computed: {
      getFirstCalendarEvId() {
        const ev =
          this.existingEvents &&
          this.existingEvents.length > 0 &&
          this.existingEvents[0]
        return ev ? ev.mapping_entity_id || ev.id : null
      },
    },

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,

      resetModal() {
        // This is here due to this issue
        // https://stackoverflow.com/questions/10636667/bootstrap-modal-appearing-under-background
        $(`#create-entity-calendar-event-${this._uid}`).appendTo('body')
      },

      handleResetEvent() {
        this.$emit('reset')
      },

      async mapEvent(ev) {
        await this.$store.dispatch('mapCalendarEvent', {
          eventId: ev.id,
          type: this.mapExistingType,
          id: this.mapExistingTypeId,
        })
        this.$emit('mapped', ev.id)
      },
    },

    mounted() {
      this.resetModal()
    },

    beforeDestroy() {
      // See comments in this.resetModal as to why this needs to be here.
      $(`#create-entity-calendar-event-${this._uid}`).remove()
    },

    components: {
      CalendarEventModal,
    },
  }
</script>
