<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-labelledby="file-update-modal-label",aria-hidden="true")
    div.modal-dialog.modal-lg(role="document")
      div.modal-content
        form(@submit="updateFile")
          div.modal-header
            h5#file-update-modal-label.modal-title Update File
            button.close(type="button",data-dismiss="modal",aria-label="Close")
              span(aria-hidden="true") &times;
          div.modal-body
            div.form-row
              div.form-group.col-md-12
                label.mb-0 File Name:
                div.overflow-ellipses
                  a(:href="'/file/s3/' + mutableFile.name",:download="mutableFile.name")
                    strong {{ $decodeURIComponentRecursive(mutableFile.name) }}
            div.form-row
              div.form-group.col-md-12
                label.mb-0 Version
                div {{ mutableFile.version }}
            div.form-row
              div.form-group.col-md-12
                label.mb-0(for='file-external-name') External Name (Display Name):
                input#file-external-name.form-control(v-model="mutableFile.external_name", type='text', placeholder='External Name...')
            div.form-row
              div.form-group.col-md-12
                label.mb-0(for='file-description') Description:
                textarea#file-description.form-control(v-model="mutableFile.description", placeholder='Description...')
            div.form-row
              div.form-group.col-md-6
                label.mb-0 Evidence Type (Optional):
                v-select(
                  v-model="mutableFile.evidence_type"
                  :options="evidenceTypes"
                  :reduce="t => t.value"
                  :get-option-label="t => t.name")
              div.form-group.col-md-6
                label.mb-0 Evidence Effective Date (Optional):
                datepicker-shards(
                  :show-clear="true",
                  input-id="file-uploads-evidence-date",
                  v-model="mutableFile.evidence_date")
            hr.mt-2
            h6 All File Versions
            div
              div.d-flex.mb-3
                div.mr-2
                  file-uploader.d-none(
                    :id="'file-uploader-' + dropzoneUuid",
                    :clickable="'#create-new-file-version-' + dropzoneUuid", 
                    :maxFileSize="10",
                    @added="addedNewFileVersion"
                  )
                  button.btn.btn-primary(:id="`create-new-file-version-${dropzoneUuid}`",type="button") Add New Version
                div.small.mt-2
                  i
                    | NOTE: This will deactivate the currently active
                    | version and activate the uploaded file.
              div.mb-2(v-if="allFileVersions.length > 0")
                div.table-responsive
                  table.table.table-bordered.small-padding.small
                    thead
                      tr
                        th.col-1 Version
                        th.col-10 File
                        th.col-1.text-center Current
                    tbody
                      tr(v-for="fileVersion, index in allFileVersions")
                        td {{ fileVersion.version }}
                        td( style="max-width: 500px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;")
                          a(:id="`file-name-${index}-${_uid}`" :href="'/file/s3/' + fileVersion.name",:download="fileVersion.name")
                            strong {{ fileVersion.name }}
                          b-tooltip(:target="`file-name-${index}-${_uid}`") {{ fileVersion.name }} 
                        td.px-0
                          div.d-flex.justify-content-center
                            div.custom-control.custom-toggle.custom-toggle-sm
                              input.custom-control-input(
                                type="checkbox"
                                @change="activateOldFileVersion(fileVersion)"
                                :id="`${fileVersion.id}-${index}`"
                                :name="`${fileVersion.name}-${index}`"
                                v-model="fileVersion.status == 'active'")
                              label.custom-control-label(:for="`${fileVersion.id}-${index}`")
              div.col-md-7.offset-md-1(v-else)
                div.alert.alert-info.rounded.text-center.small.mb-0
                  i
                    | No additional versions of this file yet. Once you add
                    | new versions of the file you can review all
                    | inactive versions here.
            div.alert.alert-danger.margin-bottom-medium(v-if="!!error") {{ error }}
          div.modal-footer
            button.btn.btn-secondary(type="button",data-dismiss="modal") Cancel
            button.btn.btn-primary(type="submit") Save File
</template>

<script>
  import $ from 'jquery'
  import uuidv1 from 'uuid/v1'
  import ApiFiles from '../../factories/ApiFiles'
  import { evidenceTypes } from '../../constants/fileUploads'

  export default {
    props: {
      file: { type: Object },
    },

    watch: {
      file: {
        async handler(newFile) {
          this.mutableFile = { ...newFile }
        },
        deep: true,
      },
    },

    data() {
      return {
        mutableFile: {},
        dropzoneUuid: uuidv1(),
        error: null,
        allFileVersions: [],
        evidenceTypes: evidenceTypes,
      }
    },

    methods: {
      async updateFile(evt) {
        try {
          evt.preventDefault()

          await ApiFiles.update(this.mutableFile)

          this.$emit('updated', this.mutableFile)
          $(`#${this.$el.id}`).modal('hide')
        } catch (err) {
          this.error = err.message
        }
      },

      async activateOldFileVersion(file) {
        try {
          await ApiFiles.activateVersion(file.id)
          this.$emit('newVersion', file)
        } catch (err) {
          this.error = err.message
        } finally {
          this.mutableFile = file
          this.getAllVersions()
          window.toastr.success('File version activated successfully')
        }
      },

      async addedNewFileVersion([, response]) {
        try {
          const newFilename = response.filename
          const res = await ApiFiles.addFileVersionAndActivate({
            extId: this.mutableFile.external_file_id,
            filename: newFilename,
          })

          const newFile = {
            ...this.mutableFile,
            id: res.id,
            name: newFilename,
            version: parseInt(this.mutableFile.version) + 1,
          }

          this.$emit('newVersion', newFile)
        } catch (err) {
          this.error = err.message
        } finally {
          // this.getAllVersions()
          window.toastr.success('File version added and activated successfully')
        }
      },

      async getAllVersions() {
        try {
          const res = await ApiFiles.getFileVersions(
            this.mutableFile.external_file_id
          )
          this.allFileVersions = res.files
        } catch (err) {
          this.console.error = err.message
        }
      },
    },

    created() {
      this.mutableFile = { ...this.file }
    },

    mounted() {
      $(`#${this.$el.id}`).on('show.bs.modal', async () => {
        await this.getAllVersions()
      })
    },
  }
</script>
