<template lang="pug">
  b-popover(:target="target",boundary="viewport",:show.sync="isShowing")
    template(slot="title")
      div.d-flex.align-items-center
        h6.mb-0.mr-4 Find Risks
        div.ml-auto
          i.fal.fa-times.clickable(@click="isShowing = false")
    div
      div.mb-2
        div
          small REGISTER
        div.d-flex.align-items-center(v-if="registerRecord")
          a(:href="`/risk/register/${registerRecord.id}`",target="_blank",rel="noopener noreferrer")
            strong {{ registerRecord.name }}
          i.ml-1.clickable.fal.fa-times.text-danger(@click="resetRecords")
        div(v-else)
          typeahead-input-new(
            placeholder="Search for register...",
            :options="riskRegisters",
            :showPropFunction="r => r.name",
            :optionSearchFilter="(s, r) => (r.name || '').toLowerCase().includes(s.toLowerCase())",
            @input="selectProject")
      div(v-if="registerRecord")
        div
          small RISK
        div(v-if="riskRecord")
          div.d-flex.align-items-center
            a(:href="`/risk/register/${registerRecord.id}/${riskRecord.id}`",target="_blank",rel="noopener noreferrer")
              strong {{ truncateString(stripHtmlTags(riskRecord.description), 40) }}
            i.ml-1.clickable.fal.fa-times.text-danger(@click="riskRecord = null")
        div(v-else)
          typeahead-input-new(
            placeholder="Search for risk...",
            :options="riskRegisterItems",
            :showPropFunction="r => truncateString(stripHtmlTags(r.description), 40)",
            :optionSearchFilter="(s, r) => stripHtmlTags(r.description || '').toLowerCase().includes(s.toLowerCase())",
            @input="selectRisk")
</template>

<script>
  import { mapState } from 'vuex'
  import StringHelpers from '../../factories/StringHelpers'

  export default {
    props: {
      target: { type: String, required: true },
    },

    data() {
      return {
        isShowing: false,

        registerRecord: null,
        riskRecord: null,
      }
    },

    computed: mapState({
      riskRegisters: (state) => state.riskregister.riskRegisters,
      riskRegisterItems: (state) => state.riskregister.items.data,
    }),

    methods: {
      stripHtmlTags: StringHelpers.stripHtmlTags,
      truncateString: StringHelpers.truncateString,

      async selectProject(register) {
        this.registerRecord = register
        await this.$store.dispatch('getRiskRegisterRecord', register.id)
        await this.$store.dispatch('getRiskRegisterItems', { reset: true })
      },

      selectRisk(risk) {
        this.riskRecord = risk
        this.$emit('select', risk.id)
      },

      resetRecords() {
        this.registerRecord = null
        this.riskRecord = null
      },
    },

    async created() {
      await this.$store.dispatch('getRegisters', { reset: true })
    },
  }
</script>
