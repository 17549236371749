<template lang="pug">
  div.card.card-small
    div.card-header.border-bottom
      div.d-flex.align-items-center
        small
          strong SLAs for Closing Vulnerabilities
        div.ml-auto(v-if="!onReportPage")
          router-link(to="/vulnerability/reports/sla")
            i.fal.fa-file-alt
    div(v-if="isLoadingLocal")
      loader
    div(v-else)
      div.card-body.p-0.table-responsive
        table.table.mb-0.text-center.small
          thead.text-center.bg-light
            tr
              th.border-0.border-right(style="border-width: 3px !important; border-color: #ffffff !important;") Time to Close Goal
              th.border-0 Actual Close Time
          tbody
            tr
              td.sla.p-0.border-0
                div.card.no-shadow.card-small
                  div.p-0.card-body.table-responsive
                    table.table.mb-0.nested
                      tbody
                        tr(v-for="severity in severitiesExceptInformational")
                          td.border-0.border-bottom
                            div.d-flex.align-items-center
                              div.mr-2.legend-bubble.status(:class="severityBgClassMap(severity)")
                              div {{ titleCase(severity) }}
                          td.border-0.border-bottom
                            severity-sla-toggle(:severity="severity",:days="severitySLAs[severity]")
              td.actual.p-0.border-0
                div.card.no-shadow.card-small
                  div.p-0.card-body.table-responsive
                    table.table.mb-0.nested
                      tbody
                        tr(v-for="severity in severitiesExceptInformational")
                          td.border-0.border-bottom
                            div.d-flex.align-items-center.justify-content-center(v-if="getDataBySeverity(severity).avg_seconds_to_close")
                              div.nowrap {{ getDaysToClose(getDataBySeverity(severity).avg_seconds_to_close) }} days
                              i.fal.ml-1(:class="getTimeToCompleteIconClass(severity)")
                            div(v-else) -
</template>

<script>
  import SeveritySlaToggle from '../../../vulnerability/SeveritySlaToggle'
  import ApiVulnerability from '../../../../factories/ApiVulnerability'
  import StringHelpers from '../../../../factories/StringHelpers'
  import TimeHelpers from '../../../../factories/TimeHelpers'
  import VulnerabilityHelpers from '../../../../factories/VulnerabilityHelpers'

  export default {
    data() {
      return {
        isLoadingLocal: true,
        summaryData: null,
        severitySLAs: {}
      }
    },

    computed: {
      onReportPage() {
        return this.$route.path === '/vulnerability/reports/sla'
      },

      severitiesExceptInformational() {
        return this.$store.state.vulnerability.severities.filter(s => s !== 'informational')
      }
    },

    methods: {
      itemRowContextClass: VulnerabilityHelpers.itemRowContextClass,
      titleCase: StringHelpers.titleCase,

      getDaysToClose(seconds) {
        return TimeHelpers.getDaysFromSeconds(seconds)
      },

      getDataBySeverity(severity) {
        if (!this.summaryData)
          return {}

        return (this.summaryData[severity] || [])[0] || {}
      },

      getTimeToCompleteIconClass(severity) {
        const slaDays = this.severitySLAs[severity]
        const daysToClose = parseFloat(this.getDaysToClose(this.getDataBySeverity(severity).avg_seconds_to_close))

        if (slaDays >= daysToClose)
          return [ 'text-success', 'fa-check-circle' ]

        return [ 'text-danger', 'fa-times-circle' ]
      },

      severityBgClassMap(severity) {
        return `bg-transparent-dark-${severity}`
      }
    },

    async mounted() {
      try {
        const [ dashboardInfo, slas ] = await Promise.all([
          ApiVulnerability.getDashboardTimeToCompleteSummary(),
          ApiVulnerability.getSeveritySlas()
        ])
        this.summaryData = dashboardInfo.summary
        this.severitySLAs = slas.slas

        this.$store.commit('SET_VULNERABILITY_SLAS', this.severitySLAs)

      } catch(err) {
        window.toastr.error(err.message)
      } finally {
        this.isLoadingLocal = false
      }
    },

    components: {
      SeveritySlaToggle
    }
  }
</script>

<style scoped lang="scss">
  .card-body:last-child {
    border-radius: 0 0 0.625rem 0.625rem;
  }

  table.nested {
    tr:last-child {
      td {
        border-bottom-width: 0 !important;
      }
    }
  }
</style>
