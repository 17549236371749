<template lang="pug">
  div
    div.form-group.mt-0.mb-0(v-if="isEditMode")
      select.form-control.form-control-vsm(v-model="newDays",@change="updateSla")
        option(v-for="d in possibleDays",:value="d") {{ d }} days
    div.clickable.d-flex.align-items-center.justify-content-center(v-else,@click="isEditMode = !isEditMode")
      span.nowrap {{ newDays }} days
      i.fal.fa-cog.ml-1(style="font-size: 0.5em;")
</template>

<script>
  import ApiVulnerability from '../../factories/ApiVulnerability'

  export default {
    props: {
      days: { type: [ Number, String ] },
      severity: { type: String }
    },

    watch: {
      days(newVal) {
        this.newDays = parseInt(newVal)
      }
    },

    data() {
      return {
        newDays: null,
        isEditMode: false,

        possibleDays: new Array(731).fill(0).map((_, i) => i)
      }
    },

    methods: {
      async updateSla() {
        try {
          await ApiVulnerability.updateSeveritySla(this.severity, this.newDays)
          this.$store.commit('SET_VULNERABILITY_SLAS', { [this.severity]: this.newDays })

          window.toastr.success(`Successfully updated the SLA days for ${this.severity} vulnerabilities to ${this.newDays} days.`)
          this.isEditMode = !this.isEditMode
        } catch(err) {
          window.toastr.error(err.message)
        }
      }
    },

    created() {
      this.newDays = parseInt(this.days) || 0
    }
  }
</script>
