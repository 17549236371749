<template lang="pug">
  div(v-if="isLoadingExtensions")
    loader
  div(v-else)
    div.mt-2.mb-4(v-if="mappingType")
      label.m-0 Add extension:
      v-select(
        v-model="newExtensionId"
        @input="addMapping"
        :options="selectableExtensions"
        :reduce="e => e.id"
        :get-option-label="e => `${e.name}${e.vendor_name ? ` (${e.vendor_name})` : ''}`")

    div.my-2.small(v-if="!mappings || mappings.length === 0")
      i No extensions mapped yet...
    div(v-else)
      ul.list-unstyled.mt-2
        li.mb-2.bg-light.border.p-2(v-for="(extMap, ind) in mappings")
          div.d-flex.align-items-start
            div {{ extMap.name }}
            div.ml-auto.d-flex.align-items-center
              div.ml-2(v-if="extMap.is_enabled")
                i.fal.fa-check-circle.text-success(
                  :id="`ext-mapping-enabled-${extMap.id}`")
                b-tooltip(:target="`ext-mapping-enabled-${extMap.id}`")
                  | Extension is enabled!

              a.ml-2.text-info(:id="`trigger-extension-${extMap.id}`")
                i.fal.fa-function
              b-tooltip(:target="`trigger-extension-${extMap.id}`")
                | Execute this extension!
              extension-confirmation-popover(
                :target="`trigger-extension-${extMap.id}`"
                :extension-id="extMap.id"
                :extension-name="extMap.name"
                :mapping-id="extMap.map_id"
                :configs="extMap.configs"
                @trigger="trigger")

              a.ml-2(
                data-toggle="modal"
                data-target="#execution-history-modal"
                @click="editingMappingId = extMap.id")
                  i.fal.fa-history

              a.ml-2(
                data-toggle="modal"
                data-target="#create-ext-mapping-modal"
                @click="editingMappingId = extMap.id")
                  i.fal.fa-edit

              div.ml-2
                a.text-danger(:id="`delete-mapping-${extMap.id}`")
                  i.fal.fa-times-circle
                confirmation-popover(
                  :target="`delete-mapping-${extMap.id}`",
                  header="Delete Automation?",
                  :text="`Are you sure you want to delete this automation mapping for ${extMap.name} to this record?`",
                  @confirm="deleteMapping(extMap.id, extMap.map_id)")
              //- div.custom-control.custom-toggle-sm.custom-toggle
              //-   input.custom-control-input(
              //-     type="checkbox",
              //-     :id="`ext-mapping-enabled-${extMap.id}`",
              //-     :name="`ext-mapping-enabled-${extMap.id}`",
              //-     v-model="extMap.is_enabled")
              //-   label.custom-control-label(:for="`ext-mapping-enabled-${extMap.id}`") enabled?
          div.small(v-if="extMap.description") {{ extMap.description }}

          div(v-if="extMap.configs && extMap.configs.length > 0")
            hr.my-2
            div.mb-1 Configuration:
            ul.list-unstyled.ml-2
              li(v-for="conf in extMap.configs")
                div.d-inline-block(:id="`mapping-conf_${_uid}-${conf.id}`")
                  | #[strong {{ conf.variable }}]:
                  | {{ showConfigurationValue(extMap, conf) }}
                b-tooltip(:target="`mapping-conf_${_uid}-${conf.id}`")
                  | Raw Value: {{ extMap.env_config[conf.variable] }}

    create-extension-mapping-modal#create-ext-mapping-modal(
      :mapping-id="editingMappingId"
      @update="updateAndRefresh")

    extension-execution-history-modal#execution-history-modal(
      :mapping-id="editingMappingId")
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import ExtensionConfirmationPopover from './ExtensionConfirmationPopover'
  import ApiExtensions from '../../../factories/ApiExtensions'

  export default {
    props: {
      mappingType: { type: String, default: null },
      mappingRecordId: { type: [Number, String], default: null },
      passedMappings: { type: Array, default: () => null },
      isInModal: { type: Boolean, default: false },
    },

    watch: {
      async mappingRecordId() {
        await this.getAllMappings()
      },
    },

    data() {
      return {
        mappings: [],
        newExtensionId: null,
        editingMappingId: null,
        isLoadingExtensions: false,
      }
    },

    computed: mapState({
      selectableExtensions: (state) => state.extensions.list,

      awsKeys: (state) =>
        state.awsKeys.map((k) => ({
          ...JSON.parse(k.decrypted_data),
          id: k.id,
        })),
    }),

    methods: {
      resetUpdateModal() {
        // This is here due to this issue
        // https://stackoverflow.com/questions/10636667/bootstrap-modal-appearing-under-background
        $(`#create-ext-mapping-modal,#execution-history-modal`).appendTo('body')
      },

      showConfigurationValue(mapping, config) {
        try {
          switch (config.value_type) {
            case 'secret':
              return '******'
            case 'aws_api_keys':
              // return mapping.env_config[config.variable] || 'N/A'
              return `AWS keys: ${
                this.awsKeys.find(
                  (k) => k.id == mapping.env_config[config.variable]
                ).name
              }`
            case 'gcp_auth_file':
              return '(Uploaded file)'
            default:
              return mapping.env_config[config.variable] || 'N/A'
          }
        } catch (err) {
          return 'error...'
        }
      },

      async updateAndRefresh() {
        this.$emit('update', this.editingMappingId)
        await this.getAllMappings()
      },

      async trigger(mapId, configOverrides = null) {
        try {
          await ApiExtensions.triggerExtensionMapping(mapId, configOverrides)
          window.toastr.success(
            `Successfully started your extension. A new artifact will be uploaded with the output shortly.`
          )
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async addMapping() {
        try {
          const { id } = await ApiExtensions.mapExtension({
            extension_id: this.newExtensionId,
            is_enabled: true,
            record_maps: [
              {
                mapping_type: this.mappingType,
                mapping_record_id: this.mappingRecordId,
              },
            ],
            // env_config: StringMap
          })
          this.$emit('update', id)

          this.newExtensionId = null
          this.editingMappingId = id
          $(`#create-ext-mapping-modal`).modal('show')
          await this.getAllMappings()
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async deleteMapping(mapId, mapRecordPrimaryKey = null) {
        try {
          if (mapRecordPrimaryKey) {
            const mappingToDelete = this.mappings.find(
              (m) => m.map_id == mapRecordPrimaryKey
            )
            await ApiExtensions.deleteMappingForRecord(
              mappingToDelete.map_id,
              mappingToDelete.mapping_type,
              mappingToDelete.mapping_record_id
            )
          } else {
            await ApiExtensions.mapExtension({
              id: mapId,
              is_deleted: true,
            })
          }
          this.$emit('update', mapId)
          await this.getAllMappings()
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async getAllMappings() {
        this.isLoadingExtensions = true
        try {
          if (this.passedMappings && !this.mappingType) {
            this.mappings = this.passedMappings.slice(0)
          } else {
            const { mappings } = await ApiExtensions.getAllMappingsForRecord(
              this.mappingType,
              this.mappingRecordId
            )
            this.mappings = mappings
          }

          if (
            this.mappings.find(
              (m) =>
                m.configs &&
                m.configs.find((c) => c.value_type === 'aws_api_keys')
            )
          ) {
            await this.$store.dispatch('getAwsKeys')
          }
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.isLoadingExtensions = false
        }
      },
    },

    async created() {
      await Promise.all([
        this.$store.dispatch('getAllExtensions'),
        this.getAllMappings(),
      ])

      this.resetUpdateModal()
    },

    mounted() {
      if (this.isInModal) {
        $(`#create-ext-mapping-modal,#execution-history-modal`).on(
          'hidden.bs.modal',
          () => {
            // https://stackoverflow.com/questions/28077066/bootstrap-modal-issue-scrolling-gets-disabled
            $('body').addClass('modal-open')
          }
        )
      }
    },

    beforeDestroy() {
      // See comments in this.resetUpdateModal as to why this needs to be here.
      $(`#create-ext-mapping-modal,#execution-history-modal`).remove()
    },

    components: {
      ExtensionConfirmationPopover,
    },
  }
</script>
