<template lang="pug">
  b-popover(
    :target="target"
    boundary="viewport"
    :show.sync="isShowing")
      template(slot="title")
        div.d-flex.align-items-center
          h5.mb-0.mr-4 Question Options
          div.ml-auto
            i.fal.fa-times.clickable(@click="isShowing = false")
      div
        div.mb-3(v-if="value.type !== 'free_form'")
          h6.mb-2 Criticality
          div
            v-select(
              v-model="point_value"
              :options="pointValues"
              :reduce="v => v.val"
              :get-option-label="v => v.text")
        div
          h6.m-0 Recommendation
          div
            rich-text-editor.border.rounded(
              :options="{ theme: 'bubble' }"
              v-model="recommendation")
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    props: {
      target: { type: String, required: true },
      value: { type: Object, required: true },
    },

    data() {
      return {
        isShowing: false,
      }
    },

    computed: {
      ...mapState({
        pointValues: (state) => state.questionnaire.questionPointValues,
      }),

      recommendation: {
        get() {
          return this.value.recommendation
        },

        set(recommendation) {
          this.$emit('input', {
            ...this.value,
            recommendation,
          })
        },
      },

      point_value: {
        get() {
          return this.value.point_value
        },

        set(point_value) {
          this.$emit('input', {
            ...this.value,
            point_value,
          })
        },
      },
    },
  }
</script>
