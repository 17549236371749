<template lang="pug">
  rich-text-editor(
    :options="{ theme: 'bubble' }"
    v-model="mutableValue"
    @input="updateDebounce"
  )
</template>

<script>
  import Utilities from '@risk3sixty/utilities'

  export default {
    props: {
      value: { type: String },
    },

    watch: {
      value: {
        handler(newVal) {
          this.mutableValue = newVal
        },
      },
    },

    data() {
      return {
        mutableValue: this.value,
        updateDebounce: Utilities.debounce(() => {
          this.$emit('input', this.mutableValue)
        }, 500),
      }
    },
  }
</script>
