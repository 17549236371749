<template lang="pug">
  div.custom-control.custom-checkbox.d-flex.align-items-center(v-if="isManager")
    input.custom-control-input(
      :id="`get-template-questionnaires-${_uid}`"
      type="checkbox",
      v-model="showQuestionnaireTemplates")
    label.custom-control-label.nowrap(
      :id="`show-template-questionnaires-${_uid}`"
      :for="`get-template-questionnaires-${_uid}`") Get templates #[i.fal.fa-info-circle]
    b-tooltip(:target="`show-template-questionnaires-${_uid}`")
      | As a manager you can pull down all questionnaire templates in your organization.
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    computed: {
      ...mapState({
        isManager: (_, getters) => getters.isManager,
      }),

      showQuestionnaireTemplates: {
        get() {
          return this.$store.state.vendormanagement.showQuestionnaireTemplates
        },
        set(val) {
          this.$store.commit(
            'SET_VENDOR_MANAGEMENT_SHOW_QUESTIONNAIRE_TEMPLATES',
            val
          )
        },
      },
    },
  }
</script>
