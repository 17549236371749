<template lang="pug">
  span.mathquill-field(:id="`math-expression-${_uid}`")
</template>

<script>
  export default {
    props: {
      value: { type: String, default: null },
    },

    watch: {
      value(newVal) {
        this.expField.latex(newVal)
      },
    },

    data() {
      return {
        expField: null,
      }
    },

    mounted() {
      // window.MathQuill comes from `/public/js/mathquill-basic.min.js`
      const MQ = window.MathQuill.getInterface(2)
      const el = document.getElementById(`math-expression-${this._uid}`)
      this.expField = MQ.MathField(el, {
        handlers: {
          edit: () => this.$emit('input', this.expField.latex()),
        },
      })

      if (this.value) this.expField.latex(this.value)
    },
  }
</script>

<style>
  .mathquill-field {
    border-color: #e1e5eb;
    border-radius: 0.25rem;
    font-size: 1.2125rem;
    /* font-family: inherit; */
    padding: 0.2375rem 0.35rem;
    width: 100%;
  }
</style>
