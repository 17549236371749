<template lang="pug">
  div.card.card-small
    div.card-header.border-bottom
      div.small.d-flex.align-items-center
        router-link(to="/templates/admin")
          strong Template Center Download Summary By User
        div.ml-auto
          select.form-control.form-control-sm(v-model="numberFiles",@change="getFiles()")
            option 5
            option 10
            option 15
            option 20
            option 25
    div.card-body.p-0
      div(v-if="isLoadingLocal")
        loader
      div(v-else)
        div.p-2
          div(v-if="users.length === 0")
            i No users who have downloaded files yet.
          select.mr-1.form-control.form-control-sm(v-else,v-model="selectedUserId",@change="getFiles()")
            option(v-for="user in users",:value="user.id") {{ user.name }} ({{ user.count }})
        div.table-responsive
          table.table.small-padding.small.mb-0
            thead.bg-light
              tr
                th #
                th File
                th.text-center Number Downloads
            tbody
              tr(v-if="summaryData.length === 0")
                td(colspan="100%")
                  i No files downloaded from the template center yet...
              tr(v-else,v-for="(file, index) in summaryData")
                td {{ index + 1 }}.
                td
                  a(:href="'/file/s3/' + file.name",:download="file.name")
                    div {{ truncateString(file.external_name || file.name, 60) }}
                    div.small
                      small {{ truncateString(file.name, 45) }}
                td.text-center {{ file.count }}
</template>

<script>
  import ApiTrustCenter from '../../../../factories/ApiTrustCenter'
  import StringHelpers from '../../../../factories/StringHelpers'

  export default {
    data() {
      return {
        isLoadingLocal: true,
        numberFiles: 5,
        selectedUserId: null,
        summaryData: [],
        users: [],
      }
    },

    methods: {
      truncateString: StringHelpers.truncateString,

      async getFiles() {
        try {
          const response = await ApiTrustCenter.getDashboardFileSummaryByUser(
            this.selectedUserId,
            this.numberFiles
          )
          this.summaryData = response.summary
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    async mounted() {
      const userRes = await ApiTrustCenter.getDashboardUniqueUsersWhoDownloaded()
      this.users = userRes.users
      if (this.users.length === 0) return (this.isLoadingLocal = false)

      this.selectedUserId = this.users[0].id

      await this.getFiles()
      this.isLoadingLocal = false
    },
  }
</script>
