<template lang="pug">
div.modal.fade(tabindex="-1",role="dialog",aria-labelledby="confirmation-modal",aria-hidden="true")
  div.modal-dialog(role="document")
    div.modal-content
      div.modal-header
        h5.modal-title {{ header }}
        button.close(ref="Close" type="button",data-dismiss="modal",aria-label="Close")
          span(aria-hidden="true") &times;
      div.modal-body
        div(v-html="text")
        slot
      div.modal-footer
        button.btn(@click="decline" :class="declineButtonClass") {{declineButtonText}}
        button.btn.btn-primary(@click="confirm") {{confirmationButtonText}}
</template>

<script>
  export default {
    props: {
      header: { type: String, default: 'Confirm' },
      text: { type: String },
      confirmationButtonText: { type: String, default: 'Confirm' },
      declineButtonText: { type: String, default: 'Cancel' },
      declineButtonClass: { type: String, default: 'btn-secondary' },
    },

    methods: {
      confirm() {
        this.$emit('confirm')
        this.$refs.Close.click()
      },

      decline() {
        this.$emit('decline')
        this.$refs.Close.click()
      },
    },
  }
</script>
