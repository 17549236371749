<template lang="pug">
  - // .no-shadow
  div.card.card-small
    div.card-header.border-bottom
      small
        strong Total Open Vulnerabilities
    div.card-body.p-3
      div(v-if="isLoadingLocal")
        loader
      div(v-else)
        div.d-flex.align-items-start
          div.mt-4
            div.text-large.mb-4 {{ commaInThousands(total) }} Open
            div.table-responsive
              table.table
                tbody
                  tr(v-for="sevInfo in summaryData")
                    td.border-0.p-0 {{ commaInThousands(sevInfo.count) }}
                    td.border-0.p-0
                      span.ml-1(:class="textClassBasedOnSeverity(sevInfo.severity)") {{ titleCase(sevInfo.severity) }}
          div.ml-auto
            div.p-2(v-if="summaryData.length === 0")
              i No items yet to report on.
            div(v-if="summaryData.length > 0")
              doughnut.ml-auto.mr-auto.doughnut(:chart-data="chartData",:options="chartOptions")
</template>

<script>
  import Utilities from '@risk3sixty/utilities'
  import Doughnut from '../../../reusable/chartjs/Doughnut'
  import ApiVulnerability from '../../../../factories/ApiVulnerability'
  import NumberHelpers from '../../../../factories/NumberHelpers'
  import StringHelpers from '../../../../factories/StringHelpers'

  export default {
    data() {
      return {
        isLoadingLocal: true,
        chartData: null,
        chartOptions: {
          plugins: {
            legend: {
              display: false,
              position: 'right',
            },
          },
          cutout: '80%',
        },
        summaryData: null,
      }
    },

    computed: {
      total() {
        return (this.summaryData || []).reduce(
          (total, v) => total + parseInt(v.count),
          0
        )
      },
    },

    methods: {
      commaInThousands: NumberHelpers.commaInThousands,
      titleCase: StringHelpers.titleCase,

      textClassBasedOnSeverity(severity) {
        switch (severity) {
          case 'critical':
            return 'text-danger'
          case 'high':
            return 'text-warning'
          case 'medium':
            return 'text-info'
          case 'low':
            return 'text-success'
        }
        return ''
      },
    },

    async mounted() {
      try {
        const sevColors = this.$store.state.dashboard.severityColors()
        const response = await ApiVulnerability.getDashboardSeveritySummary()
        this.summaryData = response.summary
        this.chartData = {
          labels: this.summaryData.map((sev) => this.titleCase(sev.severity)),
          datasets: [
            {
              label: 'Severities',
              backgroundColor: this.summaryData.map((s) => {
                const hex = sevColors[s.severity]
                const c = Utilities.Colors.hexToRgb(hex)
                return `rgba(${c.r}, ${c.g}, ${c.b}, 0.8)`
              }),
              borderColor: '#fff',
              data: this.summaryData.map((s) => parseInt(s.count)),
            },
          ],
        }
      } catch (err) {
        window.toastr.error(err.message)
      } finally {
        this.isLoadingLocal = false
      }
    },

    components: {
      Doughnut,
    },
  }
</script>

<style scoped lang="scss">
  .doughnut {
    max-width: 200px;
  }
</style>
