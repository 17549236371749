<template lang="pug">
  div.card.card-small
    div.card-header.border-bottom
      div.small.d-flex.align-items-center
        router-link(to="/assessments")
          strong Active Assessments
        div.ml-auto.d-flex.align-items-center
          div.custom-control.custom-toggle-sm.custom-toggle.mx-2.nowrap
            input.custom-control-input(
              type="checkbox"
              id="only-assessment-favorites"
              name="only-assessment-favorites"
              v-model="onlyFavorites"
              @change="changePage(1)")
            label.custom-control-label(for="only-assessment-favorites") Show only favorites?
          select.form-control.form-control-sm(v-model="assessmentInfo.perPage",@change="changePerPage()")
            option 5
            option 10
            option 15
            option 20
            option 25
            option 50
    div.card-body.p-0
      div(v-if="isLoadingLocal")
        loader
      div.table-responsive.mb-0(v-else)
        table.table.small-padding.small.mb-0
          thead.bg-light
            tr
              th #
              th.text-center Favorite
              th(v-if="isInternalUser") Org
              th Assessment
              th Requests
              th.text-center Created
              th.text-center Due Date
          tbody.hover-fade
            tr.no-hover(v-if="assessmentInfo.data.length === 0")
              td(colspan="100%")
                i No active assessments currently...
            tr.clickable(v-else,v-for="(assessment, index) in assessmentInfo.data",@click="goToProject(assessment)")
              td {{ getRowNumber(index) }}.
              td.text-center
                a(@click.stop="changeFavorite(assessment.id, !favoriteList[assessment.id])")
                  i.fa-heart.text-danger(:class="favoriteList[assessment.id] ? 'fa' : 'fal'")
              td.nowrap(v-if="isInternalUser") {{ assessment.team_name }}
              td.nowrap
                div {{ assessment.name }}
                div.text-danger.small(v-if="assessment.request_count_overdue > 0")
                  small {{ assessment.request_count_overdue }} overdue request{{ assessment.request_count_overdue == 1 ? '' : 's' }}
              td
                task-progress-bar(
                  :scan-id="assessment.id",
                  :counts="getKpisObject(assessment)",
                  :severities="getKpisObject(assessment).map(p => p.severity)",
                  placeholder="No evidence requests for assessment yet.")
              td.text-center.nowrap {{ getFormattedDate(assessment.created_at, 'MMM D, YYYY') }}
              td.text-center.nowrap {{ (assessment.due_date) ? getFormattedDate(assessment.due_date, 'MMM D, YYYY') : 'N/A' }}
    div.card-footer.border-top
      div.d-flex.justify-content-end
        pagination(
          :info="assessmentInfo",
          size="sm"
          @changePage="changePage")
</template>

<script>
  import { mapState } from 'vuex'
  import TaskProgressBar from '../../../vulnerability/OpenItemsProgressBar'
  import ApiAssessments from '../../../../factories/ApiAssessments'
  import ApiTeams from '../../../../factories/ApiTeams'
  import ApiUsers from '../../../../factories/ApiUsers'
  import TimeHelpers from '../../../../factories/TimeHelpers'

  export default {
    data() {
      return {
        isLoadingLocal: true,
        onlyFavorites: true,
        favoriteList: {}, // [projectId]: true/false
        assessmentInfo: {
          currentPage: 1,
          data: [],
          numberPages: 1,
          perPage: 20,
          totalCount: 0,
        },
      }
    },

    computed: mapState({
      isInternalUser: (_, getters) => getters.isInternalUser,
    }),

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,

      async goToProject(project) {
        await ApiTeams.switch(
          this,
          project.team_id,
          encodeURIComponent(`/assessments/evidence/${project.id}`)
        )
      },

      getRowNumber(index) {
        return (
          this.assessmentInfo.perPage * (this.assessmentInfo.currentPage - 1) +
          (index + 1)
        )
      },

      getKpisObject(project) {
        const { rawStatuses } = this.$store.state.assessmentStatusesAndColors(
          project.id
        )
        return rawStatuses.map((stat) => {
          return {
            severity: stat.value,
            variant: stat.variant,
            count: parseInt(project[`request_count_${stat.value}`] || 0),
          }
        })
      },

      async changeFavorite(projectId, isFav) {
        this.$set(this.favoriteList, projectId, isFav)

        const newParams = {
          assessments: Object.keys(this.favoriteList).reduce((ary, id) => {
            if (this.favoriteList[id]) return ary.concat([id])
            return ary
          }, []),
        }

        await ApiUsers.updatePersonalization(
          `assessment_dashboard_user_favorites`,
          newParams
        )
        this.$store.commit('SET_USER_PERSONALIZATIONS', {
          assessment_dashboard_user_favorites: {
            parameters: newParams,
          },
        })

        await this.changePage()
      },

      async changePerPage() {
        const newOption = { value: this.assessmentInfo.perPage }
        await Promise.all([
          this.changePage(1),
          ApiUsers.updatePersonalization(
            `dashboard_assessments_list_per_page`,
            newOption
          ),
        ])

        this.$store.commit('SET_USER_PERSONALIZATIONS', {
          dashboard_assessments_list_per_page: {
            parameters: newOption,
          },
        })
      },

      async changePage(newPage = this.assessmentInfo.currentPage) {
        this.assessmentInfo.currentPage = newPage
        await this.getAssessments()
      },

      async getAssessments() {
        try {
          const { info } = await ApiAssessments.projectForDashboard(
            this.assessmentInfo.currentPage,
            this.assessmentInfo.perPage,
            this.onlyFavorites
          )

          this.assessmentInfo = info
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.isLoadingLocal = false
        }
      },
    },

    async created() {
      const perPageParams = this.$store.state.getPersonalizationParams(
        'dashboard_assessments_list_per_page'
      )
      this.assessmentInfo.perPage =
        (perPageParams && perPageParams.value) || this.assessmentInfo.perPage

      const favoriteParams = this.$store.state.getPersonalizationParams(
        'assessment_dashboard_user_favorites'
      )
      this.favoriteList = favoriteParams
        ? favoriteParams.assessments.reduce(
            (obj, id) => ({ ...obj, [id]: true }),
            {}
          )
        : {}

      await Promise.all([
        this.$store.dispatch('getAssessmentStatuses'),
        this.getAssessments(),
      ])
    },

    components: {
      TaskProgressBar,
    },
  }
</script>
