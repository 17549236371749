export default {
  reporting: {
    types: {},
    currentType: {},
    reports: {
      data: [],
      totalCount: 0,
    },

    pagination: {
      reports: {
        page: 1,
        perPage: 25,
      },
    },

    sorting: {
      reports: {
        module: 'asc',
      },
    },
  },
}
