<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-hidden="true")
    div.modal-dialog(role="document")
      div.modal-content
        div.modal-header
          h5 {{ modalTitle }}
          button.close(type="button",data-dismiss="modal",aria-label="Close")
            span(aria-hidden="true") &times;
        div.modal-body
          h6 Existing Upload User
          div.form-group
            v-select(
              v-model="mutableUser"
              :options="uploadUsers"
              :get-option-label="u => u.name || u.username_email")
          hr
          h6 New Upload User
          div.form-group
            label Email Address #[span.text-danger *]
            input.form-control(type="text",v-model="mutableUser.username_email",:disabled="mutableUser.id")
          div.form-group
            label First Name
            input.form-control(type="text",v-model="mutableUser.first_name")
          div.form-group
            label Last Name
            input.form-control(type="text",v-model="mutableUser.last_name")
          div.form-group
            label Job Title
            input.form-control(type="text",v-model="mutableUser.job_title")
          div.form-group.mt-2.mb-0(v-if="createError")
            div.alert.alert-danger.rounded {{ createError }}
          div
            b.text-danger Adding an upload user will automatically send them an email with a link to upload files through the Upload Portal.
        div.modal-footer
          button.btn.btn-secondary(type="button",data-dismiss="modal") Cancel
          button.btn.btn-primary(type="button",@click="createUpdateUser") {{ mutableUser.id ? 'Save' : 'Add' }} 
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import ApiAuth from '../../factories/ApiAuth'
  import ApiUsers from '../../factories/ApiUsers'

  export default {
    props: {
      user: { type: Object, default: null },
      modalTitle: { type: String, default: 'Add Upload User' },
    },

    watch: {
      user: {
        immediate: true,
        handler(user) {
          if (user) {
            this.mutableUser = {
              ...user,
            }
          }
        },
      },
    },

    data() {
      return {
        createError: null,
        mutableUser: {
          username_email: null,
          first_name: null,
          last_name: null,
          job_title: null,
        },
      }
    },

    computed: {
      ...mapState({
        uploadUsers: (state) => state.team.uploadUsers || [],
      }),
    },

    methods: {
      resetUser() {
        this.mutableUser = {
          username_email: null,
          first_name: null,
          last_name: null,
          job_title: null,
        }
      },

      async createUpdateUser() {
        try {
          if (!ApiAuth.isValidEmail(this.mutableUser.username_email))
            return (this.createError = `Please enter a valid e-mail address to add the user.`)

          let userId
          if (this.mutableUser.id) {
            await ApiUsers.update({
              id: this.mutableUser.id,
              username_email: this.mutableUser.username_email,
              first_name: this.mutableUser.first_name,
              last_name: this.mutableUser.last_name,
              job_title: this.mutableUser.job_title,
            })
            userId = this.mutableUser.id
          } else {
            const res = await ApiUsers.create(
              this.mutableUser.username_email,
              this.mutableUser.first_name,
              this.mutableUser.last_name,
              this.mutableUser.job_title,
              'uploadportal',
              'portal'
            )
            userId = res.id
          }
          this.$emit('updatedUser', userId)
          this.createError = null
          this.resetUser()
          $(`#${this.$el.id}`).modal('hide')
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },
  }
</script>
