<template lang="pug">
  b-popover(
    boundary="viewport",
    :target="target",
    :ref="target")
    template(slot="title")
      div.d-flex.align-items-center
        h6.mb-0.mr-3 Mapped Jira Tickets
        div.ml-auto
          i.fal.fa-times.clickable(@click="confirm('cancel')")
    
    div.card.card-small.no-shadow.border
      div.card-body.p-0.table-resonsive
        table.table.table-striped.small-padding.m-0
          thead
            tr
              th.border-0 Project
              th.border-0 Key
              th.border-0 Ticket
              th.border-0 Jira Status
          tbody
            tr(v-if="tickets.length === 0")
              td(colspan="100%")
                i No tickets mapped yet...
            tr(v-for="ticket in tickets")
              td {{ ticket.info?.fields?.project?.name ?? 'Unknown' }}
              td
                strong {{ ticket.jira_key }}
              td
                a(
                  :href="`${ticket.externalUrl}/browse/${ticket.jira_key}`",
                  target="_blank",
                  rel="noopener noreferrer") {{ ticket.info?.fields?.description ?? 'Link' }}
              td
                div.d-flex.align-items-center
                  //- img.mr-1.img-fluid(
                  //-   v-if="ticket.info.fields.status.iconUrl",
                  //-   :src="ticket.info.fields.status.iconUrl")
                  div {{ ticket.info?.fields?.status?.name ?? 'Unkown' }}
</template>

<script>
  export default {
    props: {
      target: { type: String, required: true },
      tickets: { type: Array, required: true },
    },

    methods: {
      confirm(action) {
        this.$emit(action)
        this.$refs[this.target].$emit('close')
      },
    },
  }
</script>
