<template lang="pug">
  //- TODO: remove `data-backdrop="static"` when v-select dropdown issue is resolved
  //- https://github.com/sagalbot/vue-select/issues/1272
  div.modal.fade(tabindex="-1",role="dialog",aria-hidden="true",data-backdrop="static")
    div.modal-dialog(role="document")
      div.modal-content
        form(@submit="updateVendorReview")
          div.modal-header
            h5.modal-title Vendor Review
            button.close(type="button",data-dismiss="modal",aria-label="Close",@click="$emit('cancel')")
              span(aria-hidden="true") &times;
          div.modal-body
            div(v-if="!teamVendorId")
              h6 Vendor
              div.form-group
                label Choose vendor: {{ createNewVendorOption ? '(or' : '' }} 
                  a(v-if="createNewVendorOption",@click="showCreateVendor") Add New)
                v-select(
                  v-model="selectedTeamVendorId"
                  :options="teamVendors"
                  :reduce="v => v.id"
                  label="name")

            h6 Review Information
            div.form-group.mb-3
              label Title #[span.text-danger *]
              input.form-control(v-model="record.title")
            div.form-group.mb-3
              label Due Date
              datepicker-shards(
                placeholder="Due Date",
                input-id="due-date",
                v-model="record.due_date")
            div.form-group.mb-3
              label Status
              select.form-control(v-model="record.status")
                option(v-for="s in reviewStatuses",:value="s.value") {{ s.text }}
            div.form-group.mb-3(v-if="record.reviewed_at")
              label Reviewed On
              datepicker-shards(
                placeholder="Reviewed On",
                input-id="reviewed-on",
                v-model="record.reviewed_at")
            div.form-group.mb-3
              label Notes
              rich-text-editor(v-model="record.notes")
            div.form-group.mb-3
              label Assignees
              v-select(
                v-model="assignmentUser"
                :options="users"
                :get-option-label="u => u.name || u.username_email"
                @input="updateAssignee")
              div.m-2
                div.small(v-if="record.assignees && record.assignees.length === 0")
                  i No assignees selected yet...
                div.d-flex.align-items-center(v-else)
                  user-avatar.mr-1(
                    v-for="user in record.assignees",
                    :key="user.id",
                    :id="`request-create-assignee-user-avatar-${user.id}`",
                    :user-id="user.id",
                    @close="updateAssignee(user, 'remove')")
            
            //- div.form-group.mb-3(v-if="record.id")
            //-   label Files
            //-   entity-files-list(
            //-     v-if="record.id",
            //-     :entity-id="record.id",
            //-     entity-table="team_vendor_reviews")
            
            //- div(v-if="record.id")
            //-   h6 Review Questionnaires
            //-   div.form-group.mb-2
            //-     select.form-control.form-control-sm(v-model="selectedQuestionnaire",@change="updateQuestionnaire(selectedQuestionnaire)")
            //-       option(:value="null") Add a questionnaire..
            //-       option(v-for="quest in availableQuestionnaires",:value="quest") {{ quest.name }}
            //-     div.m-2
            //-       div.small(v-if="record.questionnaires && record.questionnaires.length === 0")
            //-         i No questionnaires selected yet...
            //-       div.py-2.border-bottom(v-else,v-for="(q, ind) in record.questionnaires")
            //-         div.d-flex.align-items-center
            //-           div.pr-1 {{ ind + 1 }}.
            //-           strong {{ q.name }}
            //-           div.ml-auto
            //-             a(@click="updateQuestionnaire(q, 'remove')")
            //-               i.fal.fa-times.text-danger
            //-         div.small.d-flex.justify-content-between
            //-           div(:class="`text-${questionnaireStatuses.find(s => s.value == q.status).variant}`") {{ questionnaireStatuses.find(s => s.value == q.status).text }}
            //-           div created {{ getFormattedDate(q.created_at) }}
              
          div.modal-footer
            button.btn.btn-secondary(type="button", data-dismiss="modal", @click="$emit('cancel')") Cancel
            button.btn.btn-primary(type="submit", @click="finishLater = true") Create &amp; Continue Later
            button.btn.btn-primary(v-if="startNowOption", type="submit") Start Now
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import ApiVendorManagement from '../../factories/ApiVendorManagement'
  import TimeHelpers from '../../factories/TimeHelpers'

  export default {
    props: {
      teamVendorId: { type: [Number, String] },
      reviewId: { type: [Number, String] },
      createNewVendorOption: { type: Boolean },
      startNowOption: { type: Boolean, default: true },
      redirectOnSave: { type: Boolean, default: true },
    },

    watch: {
      teamVendorId: {
        handler(newTeamVendorId) {
          this.selectedTeamVendorId = newTeamVendorId
        },
      },

      reviewId: {
        handler(newReviewId) {
          this.setReviewRecord(newReviewId)
        },
      },
    },

    data() {
      return {
        record: defaultRecord(),

        selectedTeamVendorId: this.teamVendorId,
        assignmentUser: null,
        selectedQuestionnaire: null,

        finishLater: false,
        redirectPath: null,
      }
    },

    computed: {
      ...mapState({
        users: (state) => state.team.teamAndInternalUsers,

        teamVendors: (state) => state.vendormanagement.teamVendors.data,
        allQuestionnaires: (state) =>
          state.vendormanagement.questionnaires.data,

        currentVendor: (state) => state.vendormanagement.currentVendor,

        reviewStatuses: (state) => state.vendormanagement.reviewStatuses,
        questionnaireStatuses: (state) =>
          state.vendormanagement.questionnaireStatuses,
      }),

      availableQuestionnaires() {
        return this.allQuestionnaires.filter((q) => {
          return (
            !q.team_vendor_review_id &&
            (!q.team_vendor_id || q.team_vendor_id == this.selectedTeamVendorId)
          )
        })
      },
    },

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,

      resetReviewRecord() {
        return (this.record = defaultRecord())
      },

      setReviewRecord(reviewId) {
        const currentReview = (this.currentVendor.reviews || []).find(
          (review) => {
            return review.id == reviewId
          }
        )

        if (currentReview)
          this.record = {
            ...currentReview,
            assignees: currentReview.assignees || [],
            questionnaires: currentReview.questionnaires || [],
          }
      },

      updateAssignee(user = this.assignmentUser, action = 'add') {
        if (action === 'remove') {
          const userIndex = this.record.assignees.findIndex(
            (u) => u.id === user.id
          )
          return this.record.assignees.splice(userIndex, 1)
        }

        this.record.assignees.push(user)
        this.record.assignees = [...new Set(this.record.assignees)]
        this.assignmentUser = null
      },

      async updateQuestionnaire(
        quest = this.selectedQuestionnaire,
        action = 'add'
      ) {
        if (!this.record.id)
          return window.toastr.error(`Vendor required before saving.`)

        quest.team_vendor_review_id = action === 'add' ? this.record.id : null
        quest.team_vendor_id =
          action === 'add' ? this.selectedTeamVendorId : quest.team_vendor_id

        await ApiVendorManagement.createUpdateQuestionnaire(quest)
        this.selectedQuestionnaire = null
        this.$emit('updated', this.record.id)
      },

      async updateVendorReview(evt) {
        try {
          evt.preventDefault()

          if (!this.selectedTeamVendorId)
            return window.toastr.error(`Vendor required before saving.`)

          if (!this.record.title)
            return window.toastr.error(`Title required before saving.`)

          const reviewId = await this.$store.dispatch('createUpdateReview', {
            ...this.record,
            team_vendor_id:
              this.record.team_vendor_id || this.selectedTeamVendorId,
          })

          window.toastr.success(`Successfully updated vendor review!`)

          this.$emit('updated', reviewId)

          let newPath = `/vendor/management/reviews/${reviewId}`
          if (this.finishLater) {
            newPath = `/vendor/management/vendors/${this.selectedTeamVendorId}/reviews`
          }
          if (this.$route.path !== newPath && this.redirectOnSave)
            this.redirectPath = newPath
          $(`#${this.$el.id}`).modal('hide')
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.$store.dispatch('getReviews', true)
        }
      },
    },

    async mounted() {
      $(`#${this.$el.id}`).on('shown.bs.modal', async () => {
        this.resetReviewRecord()
        if (this.reviewId) this.setReviewRecord(this.reviewId)
        await this.$store.dispatch('getReviewStatuses')
      })

      $(`#${this.$el.id}`).on('hidden.bs.modal', () => {
        if (this.redirectPath) this.$router.push(this.redirectPath)
      })
    },
  }

  function defaultRecord() {
    return {
      title: null,
      due_date: null,
      status: 'draft',
      notes: null,
      assignees: [],
      questionnaires: [],
    }
  }
</script>
