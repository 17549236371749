<template lang="pug">
  div
    div(
      v-if="!isEditing",
      v-html="mutableVal || '<div>N/A</div>'",
      @click="isEditing = $store.getters.isInternalUser && !isEditing")
    div(v-else)
      textarea.form-control(v-model="mutableVal")
      div.mt-2
        button.btn.btn-primary(@click="saveItem") Save
        button.ml-1.btn.btn-white(@click="cancelEdit") Cancel
</template>

<script>
  import ApiVulnerability from '../../factories/ApiVulnerability'

  export default {
    props: {
      vulnerabilityId: { type: [ Number, String ] },
      itemId: { type: [ Number, String ] },
      attrKey: { type: String },
      attrVal: { type: [ Boolean, Number, String ], default: null }
    },

    data() {
      return {
        isEditing: false,
        mutableVal: this.attrVal
      }
    },

    methods: {
      cancelEdit() {
        this.isEditing = false
        this.mutableVal = this.attrVal
      },

      async saveItem() {
        try {
          await ApiVulnerability.createScanActionItem(this.vulnerabilityId, this.itemId, { fields: [{ key: this.attrKey, value: this.mutableVal }]})

          this.isEditing = false
          this.$emit('updated')
        } catch(err) {
          window.toastr.error(err.message)
        }
      }
    }
  }
</script>
