<template lang="pug">
  div
    div.text-right.mb-1
      a(@click="isExpanded = !isExpanded") {{ (isExpanded) ? "&minus;" : "&plus;" }}
    div(v-if="logs.length === 0")
      i No logs generated yet...
    div.table-responsive(v-else,style="font-size: 0.6rem;")
      table.m-0.table.table-striped.table-bordered.small-padding
        thead
          tr
            th Who
            th Action
            th Details
            th When
        tbody
          tr(v-for="log in logs")
            td {{ log.user_name || 'N/A' }}
            td(:class="isExpandedClass",style="max-width: 80px !important;") {{ log.details.title }}
            td
              div(v-if="log.details.internal_only")
                i Internal comment...
              div(
                v-else-if="log.details.info",
                :class="isExpandedClass",
                style="max-width: 150px !important;",
                v-html="log.details.info")
              div(v-else) N/A
            td
              span {{ getFormattedDate(log.created_at, 'MMMM D, YYYY h:mm a') }}
              //- 8/30/22: Removed blockchain transaction (txn) functionality (causing performance issues)
              //- span.clickable.ml-1(v-if="log.blockchain_txns && log.blockchain_txns.length > 0",:id="`changelog-blockchain-${log.id}`")
              //-   img.max-15.img-fluid(:src="stellarLogoSrc")
              //-   blockchain-txn-popover(
              //-     v-for="txn in log.blockchain_txns",
              //-     :key="txn.id",
              //-     :target="`changelog-blockchain-${log.id}`",
              //-     :txn="txn")
    //- ul.border-top.list-unstyled(v-else)
    //-   li.p-1.small.border-bottom(v-for="log in logs")
    //-     div.d-flex.align-items-center
    //-       div
    //-         strong {{ log.details.title }}
    //-       div.ml-auto.small.text-gray.text-right
    //-         div {{ getFormattedDate(log.created_at, 'MMMM D, YYYY h:mm a') }}
    //-         div {{ log.user_name || 'N/A' }}
    //-     div.small.mt-1(v-if="log.details.info") {{ log.details.info }}
</template>

<script>
  import { mapState } from 'vuex'
  import TimeHelpers from '../../factories/TimeHelpers'

  export default {
    props: {
      logs: { type: Array },
    },

    data() {
      return {
        isExpanded: true,
      }
    },

    computed: {
      ...mapState({
        stellarLogoSrc: (_, getters) => getters.stellarLogoSrc,
      }),

      isExpandedClass() {
        return this.isExpanded ? '' : 'overflow-ellipses no-hover'
      },
    },

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,
    },
  }
</script>
