<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-hidden="true")
    div.modal-dialog(role="document")
      div.modal-content
        div.modal-header
          h5.modal-title {{ modalTitle }}
          button.close(type="button",data-dismiss="modal",aria-label="Close")
            span(aria-hidden="true") &times;
        div.modal-body
          div.form-group.m-0
            label Email Address #[span.text-danger *]
            input.form-control(type="text",v-model="newUserEmail")
          div.form-group.m-0
            label First Name
            input.form-control(type="text",v-model="newUserFn")
          div.form-group.m-0
            label Last Name
            input.form-control(type="text",v-model="newUserLn")
          div.form-group
            label Title
            input.form-control(type="text",v-model="newUserTitle")
          div.form-group.mt-2.mb-0(v-if="createError")
            div.alert.alert-danger.rounded {{ createError }}
        div.modal-footer
          button.btn.btn-secondary(type="button",data-dismiss="modal") Cancel
          button.btn.btn-primary(type="button",@click="createUser") Add
</template>

<script>
  import $ from 'jquery'
  import ApiAuth from '../../factories/ApiAuth'
  import ApiUsers from '../../factories/ApiUsers'

  export default {
    props: {
      modalTitle: { type: String, default: 'Add Contact' },
    },

    data() {
      return {
        createError: null,
        newUserEmail: null,
        newUserFn: null,
        newUserLn: null,
        newUserTitle: null,
      }
    },

    methods: {
      async createUser() {
        try {
          if (!ApiAuth.isValidEmail(this.newUserEmail))
            return (this.createError = `Please enter a valid e-mail address to add the user.`)

          const { id } = await ApiUsers.create(
            this.newUserEmail,
            this.newUserFn,
            this.newUserLn,
            this.newUserTitle
          )

          this.$emit('created', { id, username_email: this.newUserEmail })
          this.newUserEmail = this.createError = null
          $(`#${this.$el.id}`).modal('hide')
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    mounted() {
      $(`#${this.$el.id}`).on('shown.bs.modal', () => {
        this.newUserEmail = null

        // https://stackoverflow.com/questions/28077066/bootstrap-modal-issue-scrolling-gets-disabled
        $('body').addClass('modal-open')
      })
      $(`#${this.$el.id}`).on('hide.bs.modal', () => this.$emit('cancel'))
    },

    beforeDestroy() {},
  }
</script>
