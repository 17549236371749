<template lang="pug">
  div
    div.row.no-gutters
      div(:class="allowCreateClass()")
        typeahead-input-new(
          v-if="availableTags.length > 0",
          :options="availableTags",
          :showPropFunction="(t) => t.name",
          :optionSearchFilter="(s, t) => (t.name || '').toLowerCase().includes(s.toLowerCase())",
          :size="size",
          :placeholder="placeholder",
          :input-class="(isRounded) ? 'form-control-rounded' : ''"
          @input="addTagToEntity")
        div.small(v-else)
          i.small No tags created yet.
      div.align-items-center.justify-content-end(:class="allowCreateClass(true)")
        a(data-toggle="modal",:data-target="`#${createTagModalId}`")
          i.fal.fa-plus-circle.text-success

    create-edit-tag-modal(
      :id="createTagModalId"
      :tag="editingTag",
      :team-id="teamId",
      @saved="createdNewTag")
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import ApiTags from '../../factories/ApiTags'

  export default {
    props: {
      table: { type: String, default: null },
      tags: { type: Array, default: null },
      entityId: { type: [Number, String], default: null },
      onlyEmit: { type: Boolean, default: false },
      size: { type: String, default: 'sm' },
      placeholder: { type: String, default: 'Enter tag to add...' },
      allowCreateTag: { type: Boolean, default: true },
      addAfterCreate: { type: Boolean, default: false },
      isRounded: { type: Boolean, default: false },

      // If the user has access to another organization than
      // the one currently logged into, this can be passed
      // to the component to create a tag in another org.
      teamId: { type: [Number, String], default: null },
    },

    data() {
      return {
        editingTag: {},
      }
    },

    computed: {
      ...mapState({
        availableTags(state) {
          return this.tags || state.tags
        },
      }),

      createTagModalId() {
        return `create-edit-tag-modal-add-tag-to-entity-${this._uid}`
      },
    },

    methods: {
      resetCreateTagModal() {
        // This is here due to this issue
        // https://stackoverflow.com/questions/10636667/bootstrap-modal-appearing-under-background
        $(`#${this.createTagModalId}`).appendTo('body')
      },

      tagText(tag) {
        return tag.name
      },

      allowCreateClass(isCreateDomElement = false) {
        if (this.allowCreateTag)
          return isCreateDomElement ? 'd-flex col-1' : 'col-11'

        return isCreateDomElement ? 'd-none' : 'col-12'
      },

      async addTagToEntity(tag) {
        try {
          if (!this.onlyEmit && this.entityId)
            await ApiTags.updateEntity({
              tagId: tag.id,
              table: this.table,
              entityId: this.entityId,
              overrideTeamId: this.teamId,
              operation: 'add',
            })

          this.$emit('added', tag, this.entityId)
        } catch (err) {
          this.$emit('error', err)
        }
      },

      async createdNewTag(tagRecord) {
        this.$emit('created', tagRecord)
        if (this.addAfterCreate) await this.addTagToEntity(tagRecord)

        await this.getTags(true)
      },

      async getTags(reset = false) {
        if (reset) {
          const res = await ApiTags.getAllTags()
          this.$store.commit('SET_TAGS', res.tags)
        } else {
          await this.$store.dispatch('getTags')
        }

        this.editingTag = {}
      },
    },

    async created() {
      await this.getTags(true)
    },

    mounted() {
      this.resetCreateTagModal()
    },

    beforeDestroy() {
      // See comments in this.resetCreateTagModal as to why this needs
      // to be here.
      $(`#${this.createTagModalId}`).remove()
    },
  }
</script>
