<template lang="pug">
  div.dropdown(v-if="record && currentStatus")
    div#status-changer-dropdown.clickable.d-flex.align-items-center(data-toggle="dropdown")
      pill-inline.wide(
        :background="this.variantColors[currentStatus.variant]",
        :text="`${fullText} <i class='fal fa-arrow-down'></i>`",
        :show-close="false",
        :is-hollow="true")
    div.dropdown-menu(aria-labelledby="status-changer-dropdown")
      a.dropdown-item(
        v-for="status in availableStatuses"
        :class="`text-${status.variant}`",
        @click="updateStatus(status.value)")
        div.d-flex.align-items-center
          strong {{ titleCase(status.value, true) }}
</template>

<script>
  import { mapState } from 'vuex'
  import ApiVendorManagement from '../../factories/ApiVendorManagement'
  import StringHelpers from '../../factories/StringHelpers'

  export default {
    props: {
      text: { type: String, default: null },
      passedRecord: { type: Object, default: null },
      emitOnly: { type: Boolean, default: false },
    },

    computed: {
      ...mapState({
        availableStatuses: (state) =>
          state.vendormanagement.questionnaireStatuses,
        record(state) {
          return (
            this.passedRecord ||
            state.vendormanagement.currentQuestionnaireAndSections ||
            state.vendormanagement.currentQuestionnaire
          )
        },

        fullText() {
          const variant = this.record.review_status
            ? this.record.review_status === 'pass'
              ? 'success'
              : 'danger'
            : ''

          const text = this.record.review_status
            ? this.record.review_status === 'pass'
              ? 'Passed Review'
              : 'Failed Review'
            : ''
          return this.record.review_status
            ? `${this.text} <span class="strong text-${variant}">(${text})</span>`
            : this.text
        },

        variantColors: (state) => state.dashboard.variantColors(),
      }),

      currentStatus() {
        return this.availableStatuses.find(
          (s) => s.value === this.record.status
        )
      },
    },

    methods: {
      titleCase: StringHelpers.titleCase,

      async updateStatus(newStatus) {
        try {
          if (this.emitOnly) return this.$emit('updated', 'status', newStatus)

          await ApiVendorManagement.createUpdateQuestionnaire({
            ...this.record,
            id: this.record.id,
            status: newStatus,
          })
          this.$emit('updated', newStatus)
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },
  }
</script>
