<template lang="pug">
  div.dropdown.clickable
    div(v-if="!clickable")
      risk-register-data-circle(
        type="risk",
        :value="acceptableRisk || '-'",
        :header="header",
        :size="size")
    div(v-else :id="`rr-acceptable-risk-${_uid}`",role='button',data-toggle='dropdown',aria-haspopup='true',aria-expanded='false')
      risk-register-data-circle(
        type="risk",
        :value="acceptableRisk || '-'",
        :header="header",
        :size="size")
    div.dropdown-menu.dropdown-menu-small.scrollable-dropdown
      a.dropdown-item.p-2.border-bottom(v-for="(value, index) in 26",@click="update(index)")
        div.d-flex.justify-content-center
          risk-register-data-circle(
            type="risk",
            :value="index || '-'",
            size="25")

    b-tooltip(:target="`rr-acceptable-risk-${_uid}`")
      div(v-html="glossary.ACCEPTABLE_RISK")
</template>

<script>
  import { glossary } from '../../constants/glossary'

  export default {
    props: {
      acceptableRisk: { type: [Number, String], required: true },
      size: { type: [Number, String], default: 40 },
      header: { type: String, default: 'Acceptable Risk' },
      clickable: { type: Boolean, default: true },
    },

    data() {
      return {
        glossary: glossary.riskRegister.risks,
      }
    },

    methods: {
      update(newAcceptableRisk) {
        this.$emit('update', newAcceptableRisk)
      },
    },
  }
</script>
