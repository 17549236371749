<template lang="pug">
  li.nav-item.dropdown.notifications
    a.nav-link.nav-link-icon.text-center(role='button', data-toggle='dropdown', aria-haspopup='true', aria-expanded='false')
      div.nav-link-icon__wrapper
        i.fal.fa-bell
        span.badge.badge-pill.badge-danger(v-show="alerts.length > 0") {{ (alerts.length < 5) ? alerts.length : alerts.length.toString() + '+' }}
    div.dropdown-menu.dropdown-menu-small.overflow-auto(aria-labelledby='dropdownMenuLink', style="max-height: 80vh;")
      template(v-if="alerts.length > 0")
        router-link.dropdown-item.notification__all.text-center(style="border-radius: 0px",to='/alerts')  View all Alerts
        a.dropdown-item(v-for="(alert, index) in alerts",@click="acknowledgeAndFollowLink(alert, index)")
          div.notification__icon-wrapper
            div.notification__icon
              i.material-icons {{ alert.icon || 'notification_important' }}
          div.notification__content.w-100
            span.notification__category.mb-0
              div {{ alert.title }}
              div.small {{ getFormattedDate(alert.created_at, 'MMM DD, YYYY') }}
            p.overflow-ellipses.no-hover(style="max-width: 320px",v-html="alert.description")
      a.dropdown-item.d-flex.align-items-center(v-else)
        div.notification__icon-wrapper
          div.notification__icon
            i.material-icons highlight_off
        div.notification__content
          span.notification__category
            i You have no unacknowledged alerts.
      router-link.dropdown-item.notification__all.text-center(to='/alerts')  View all Alerts
</template>

<script>
  import Utilities from '@risk3sixty/utilities'
  import { mapState } from 'vuex'
  import ApiTeams from '../factories/ApiTeams'
  import ApiUsers from '../factories/ApiUsers'
  import R3sSocket from '../factories/R3sSocket'
  import TimeHelpers from '../factories/TimeHelpers'

  export default {
    computed: mapState({
      alerts: (state) => state.alerts,
    }),

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,

      async acknowledgeAndFollowLink(alert, index) {
        await ApiUsers.acknowledgeAlert(alert.id)
        this.$store.commit('ACKNOWLEDGE_ALERT', index)

        if (alert.link) {
          if (window.URL) {
            const linkUrl = new window.URL(
              `https://${window.location.pathname}${alert.link}`
            )
            const qs = Utilities.Strings.qsUnserialize(linkUrl.search.slice(1))
            if (!/^\/vendorbase/.test(qs.redirect)) {
              return await ApiTeams.switch(
                this,
                alert.team_id,
                encodeURIComponent(qs.redirect || '/')
              )
            }
          }

          window.location.href = alert.link
        }

        window.toastr.success(`Successfully acknowledged this alert!`)
      },
    },

    created() {
      R3sSocket.on(`newAlert`, (alert) => this.alerts.unshift(alert))
    },
  }
</script>
