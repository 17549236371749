<template lang="pug">
  div.modal.fade(:id="`update-item-param-modal-${uniqueId}`",tabindex="-1",role="dialog",aria-hidden="true")
    div.modal-dialog(role="document")
      div.modal-content
        div.modal-header
          h5.modal-title Add a Reason
          button.close(
            type="button"
            data-dismiss="modal"
            aria-label="Close")
              span(aria-hidden="true") &times;
        div.modal-body.text-left
          div.form-group.mb-0.mt-0
            label(:for="`item-reason-label-${uniqueId}`")
              | Add a reason/more details below for updating
              | the {{ field }} to {{ valueText }}:
            textarea.form-control(:id="`item-reason-value-${uniqueId}`",rows="10",v-model="updateReason")
        div.modal-footer
          button.btn.btn-primary(
            type="button"
            @click="changeSingleItem()") Save Item
</template>

<script>
  import $ from 'jquery'
  import StringHelpers from '../../factories/StringHelpers'

  export default {
    props: {
      field: { type: [String] },
      newValue: { type: [String, Number] },
      uniqueId: { type: [Number, String] },
    },

    data() {
      return {
        isSubmitted: false,
        updateReason: null,
      }
    },

    computed: {
      valueText() {
        return StringHelpers.titleCase(this.newValue, true)
      },
    },

    methods: {
      changeSingleItem() {
        this.isSubmitted = true
        this.$emit('submitReason', this.updateReason)
        this.updateReason = null
      },
    },

    mounted() {
      $(`#${this.$el.id}`).on('hide.bs.modal', () => {
        if (!this.isSubmitted) this.$emit('cancel')
        this.isSubmitted = false
      })
    },
  }
</script>
