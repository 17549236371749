<template lang="pug">
  div.page-error
    div.row
      div.col-xs-12.col-sm-8.offset-sm-2.col-lg-6.offset-lg-3
        div.alert.alert-danger.text-center.mt-4(v-html="htmlText")
</template>

<script>
  export default {
    props: {
      htmlText: { type: String },
    },
  }
</script>
