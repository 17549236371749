<template lang="pug">
select.form-control(:value="value",ref="select",@change="updateValue")
  option(v-for="option in options",:value="option.value") {{ option.key }}
</template>

<script>
  export default {
    props: ['value', 'options'],

    methods: {
      updateValue() {
        this.$emit('input', this.$refs.select.value)
        this.$emit('change', this.$refs.select.value)
      },
    },
  }
</script>
