<template lang="pug">
  b-popover(:target="target",placement="right",:show.sync="isShowing",custom-class="min-400")
    template(slot="title")
      div.d-flex.align-items-center
        h6.m-0 Question Choice Templates
        div.ml-auto
          i.fal.fa-times.clickable(@click="isShowing = false")
    div
      div.small.text-right
        a(@click="toggleCreateGroup = !toggleCreateGroup") create new template group
      form(v-if="toggleCreateGroup",@submit="saveGroup")
        div.form-group
          label New Template Name (Enter to create)
          input.form-control.form-control-sm(v-model="newGroupName")
        hr

      div.form-group
        label Template Group
        select.form-control(v-model="selectedGroupId")
          option(:value="null") Select template group...
          option(v-for="template in templates",:value="template.id") {{ template.name }}
      div(v-if="selectedGroup")
        hr
        div.d-flex.align-items-center.mb-2
          h6.m-0 Choices
          div.ml-2.btn-group.btn-group-sm
            button.small.btn.btn-toggle.btn-white(@click="mutableChoices.splice(mutableChoices.length - 1, 1)")
              i.fal.fa-minus
            button.small.btn.btn-toggle.btn-white(@click="mutableChoices.push({})")
              i.fal.fa-plus
        ul.list-unstyled
          li(v-if="mutableChoices.length === 0")
            i No choices added to group yet...
          draggable(v-else,v-model="mutableChoices")
            li.mb-4(v-for="(choice, ind) in mutableChoices")
              div.d-flex.align-items-start
                div.mr-2
                  i.clickable.fal.fa-grip-lines
                div.w-100
                  input.form-control.mb-1(v-model="choice.text")
                div.ml-1
                  a(@click="mutableChoices.splice(ind, 1)")
                    i.fal.fa-times-circle.text-danger

      div.form-group.d-flex.mt-3
        div.mr-2(v-if="selectedGroup")
          button.btn.btn-vsm.btn-primary(@click="saveChoices()") Save Selected Group
        div.ml-auto
          button.btn.btn-vsm.btn-success(@click="select()") Add Choices to Question
</template>

<script>
  import { mapState } from 'vuex'
  import ApiVendorManagement from '../../factories/ApiVendorManagement'

  export default {
    props: {
      target: { type: String, required: true },
    },

    data() {
      return {
        isShowing: false,
        newGroupName: null,
        selectedGroupId: null,
        toggleCreateGroup: null,
        mutableChoices: [],
      }
    },

    computed: {
      ...mapState({
        templates: (state) =>
          state.vendormanagement.questionnaireChoiceTemplates,
      }),

      selectedGroup() {
        return this.selectedGroupId
          ? this.templates.find((g) => g.id == this.selectedGroupId)
          : null
      },
    },

    watch: {
      selectedGroupId() {
        this.mutableChoices = JSON.parse(
          JSON.stringify(this.selectedGroup.choices)
        )
      },
    },

    methods: {
      select(group = this.selectedGroup) {
        this.$emit('select', group)
        this.isShowing = false
      },

      async refreshTemplates() {
        await this.$store.dispatch('getQuestionnaireChoiceTemplates', true)
      },

      async saveGroup(evt, group = null) {
        try {
          if (evt) evt.preventDefault()

          group = group || { name: this.newGroupName }
          if (!group.name)
            return window.toastr.error(
              `Please enter a name for the template to create/update it.`
            )

          const {
            id,
          } = await ApiVendorManagement.createUpdateChoiceTemplateGroup(group)

          this.newGroupName = this.toggleCreateGroup = null

          await this.refreshTemplates()
          this.selectedGroupId = id
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async saveChoices(
        choices = this.mutableChoices,
        groupId = this.selectedGroupId
      ) {
        try {
          await ApiVendorManagement.createUpdateChoiceTemplateGroupChoices({
            template_group_id: groupId,
            choices,
          })
          await this.refreshTemplates()
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },
  }
</script>
