<template lang="pug">
  div
    div.my-2(v-if="showUploader")
      loader(v-show="isFileUploading")
      file-uploader(
        v-show="!isFileUploading",
        size="sm",
        defaultMessage="Click or drop files here to upload.",
        @added="uploadFile"
        @processing="fileUploading")

    div.my-2.small(v-if="!files || files.length === 0")
      i No files linked to yet...
    div(v-else,style="max-height: 60vh; overflow-y: auto;")
      ol.px-3
        li.bg-light.mb-2(v-for="(file, ind) in files")
          attachment-item(
            :attachment="file"
            :index="ind"
            :allow-internal="true"
            @update="getFiles"
            @delete="deleteFile")
</template>

<script>
  import ApiFiles from '../../factories/ApiFiles'

  export default {
    props: {
      entityId: { type: [Number, String], required: true },
      entityTable: { type: [String], required: true },
      showUploader: { type: Boolean, default: true },
    },

    data() {
      return {
        isFileUploading: false,
        files: [],
      }
    },

    watch: {
      async entityId() {
        await this.getFiles()
      },
    },

    methods: {
      fileUploading() {
        this.isFileUploading = true
      },

      async uploadFile([, file]) {
        try {
          this.isFileUploading = false
          const { filename } = file

          // TODO: do files need to be private scoped here?
          const { files } = await ApiFiles.saveFile(
            { name: filename },
            'private'
          )
          await Promise.all(
            files.map(async (file) => {
              await this.addUpdateFile(file.id)
            })
          )

          this.$emit('updated', files)
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async addUpdateFile(id, del = false) {
        try {
          await ApiFiles.createOrUpdateAndLinkToEntity(
            {
              id,
              table: this.entityTable,
              entityId: this.entityId,
            },
            del
          )
          await this.getFiles()
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async deleteFile(id) {
        await this.addUpdateFile(id, true)
        this.$emit('updated', this.files)
      },

      async getFiles() {
        try {
          const { files } = await ApiFiles.getByEntity(
            this.entityTable,
            this.entityId
          )
          this.files = files
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    async created() {
      await this.getFiles()
    },
  }
</script>
