<template lang="pug">
  div.img-wrapper
    div.d-flex.justify-content-center.mb-2
      button.btn.btn-sm.btn-secondary.mr-1(:disabled='zoom == 100', @click='zoom -= 20')
        i.fa.fa-search-minus
      button.btn.btn-sm.btn-secondary(@click='zoom += 20')
        i.fa.fa-search-plus
    div.img-viewer-wrapper(v-dragscroll='true', :class="{'zoom-active' : zoom > 100}")
      img(:style="`width: ${zoom}%`", :src="src")
</template>

<script>
  import { dragscroll } from 'vue-dragscroll'

  export default {
    props: {
      src: { type: String },
    },

    data() {
      return {
        zoom: 100,
      }
    },

    directives: {
      dragscroll,
    },
  }
</script>

<style lang="scss">
  .img-viewer-wrapper {
    overflow: auto;
    max-height: 60vh;
    &.zoom-active {
      cursor: grab;
    }
  }
</style>
