<template lang="pug">
  div
    div.form-group.mb-2
      label Category #[span.text-danger *]
      select.form-control(v-model="mutableVal.risk_register_category_id", placeholder="Select category for risk..." @change="update")
        option(
          v-for="cat in categories",
          :value="cat.id") {{ cat.name }}
    div.form-group.mb-2
      label Risk Statement #[span.text-danger *]
      rich-text-editor(v-model="mutableVal.description",@input="update")
    div.form-group.mb-2
      label Consequences
      rich-text-editor(v-model="mutableVal.risk_description",@input="update")
    div.form-group.mb-2
      label Recommendation
      rich-text-editor(v-model="mutableVal.recommendation",@input="update")
    div.form-group.mb-2
      label Root Cause
      rich-text-editor(v-model="mutableVal.root_cause",@input="update")
    div.form-group.mb-2
      label Management Action Plan
      rich-text-editor(v-model="mutableVal.mgmt_action_plan",@input="update")
    div.form-group.mb-2
      label Risk Source
      rich-text-editor(v-model="mutableVal.source",@input="update")
    div.form-group.mb-2
      label Hours to Complete
      input.form-control(type="number",v-model="mutableVal.hours_to_complete",@change="update")
    div.form-group.mb-2
      label Risk Identified Date
      datepicker-shards(
        show-clear=true,
        v-model="mutableVal.identified_date",
        @input="update")
    div.form-group.mb-2
      label Start Date
      datepicker-shards(
        show-clear=true,
        v-model="mutableVal.start_date",
        @input="update")
    div.form-group.mb-2
      label Due Date
      datepicker-shards(
        show-clear=true,
        v-model="mutableVal.end_date",
        @input="update")
    div.form-group.mb-2
      label Closed Date
      datepicker-shards(
        show-clear=true,
        v-model="mutableVal.closed_date",
        @input="update")
    
    hr
    div.form-group(:class="showScore ? 'border-bottom' : ''")
      label.m-0 Owners
      select.form-control(v-model="ownerUserId",@change="addOwner(ownerUserId)")
        option(v-for="user in users",:value="user.id") {{ user.name || user.username_email }}
      div.my-2
        div.small(v-if="mutableVal.owners.length === 0")
          i No owners selected yet...
        div.d-flex.align-items-center(v-else)
          user-avatar.mr-1(
            v-for="(userId, ind) in mutableVal.owners",
            :key="userId",
            :id="`risk-register-owner-user-avatar-${userId}`",
            :user-id="userId",
            @close="removeOwner(ind)")
    

    div.row(v-if="showScore")
      div.col-12
        label.mb-2 Risk Scoring
        div.card.no-shadow.border.mb-3
          div.card-body
            div.mb-4
              label.m-0.small
                strong Impact
              div.d-flex.justify-content-center
                bubble-numeric-selector(
                  :value="mutableVal.impact",
                  :tooltips="impactTooltips",
                  @change="setMetric('impact', ...arguments)")
            div
              label.m-0.small
                strong Likelihood
              div.d-flex.justify-content-center
                bubble-numeric-selector(
                  :value="mutableVal.likelihood",
                  :tooltips="likelihoodTooltips",
                  @change="setMetric('likelihood', ...arguments)")

        div.card.no-shadow.border.mb-3
          div.card-body
            label.m-0.small
              strong Cost
            div.d-flex.justify-content-center
              bubble-numeric-selector(
                :value="mutableVal.cost_estimation",
                :tooltips="costTooltips",
                @change="setMetric('cost_estimation', ...arguments)")

        div.card.no-shadow.border.mb-3
          div.card-body
            label.m-0.small
              strong Effort
            div.d-flex.justify-content-center
              bubble-numeric-selector(
                :value="mutableVal.effort_estimation",
                :tooltips="effortTooltips",
                @change="setMetric('effort_estimation', ...arguments)")
</template>

<script>
  import { mapState } from 'vuex'
  import StringHelpers from '../../factories/StringHelpers'

  export default {
    props: {
      registerId: { type: [Number, String], required: true },
      showScore: { type: Boolean, default: false },
      value: { type: Object, required: true },
      uid: { type: [Number, String], default: null },
    },

    watch: {
      value: {
        handler(newVal) {
          this.mutableVal = JSON.parse(JSON.stringify(newVal))
        },
        deep: true,
      },
    },

    data() {
      return {
        ownerUserId: null,
        mutableVal: JSON.parse(JSON.stringify(this.value)),
      }
    },

    computed: mapState({
      users: (state) => state.team.teamAndInternalUsers,

      categories(state) {
        return state.riskregister.categories[this.registerId]
      },

      impactTooltips(state) {
        return state.riskregister.impactScores
          .map((s) => ({
            type: s.policy_type,
            value: s.value,
            tooltip: s.policy_text,
          }))
          .reverse()
      },

      likelihoodTooltips(state) {
        return state.riskregister.likelihoodScores
          .map((s) => ({
            type: s.policy_type,
            value: s.value,
            tooltip: s.policy_text,
          }))
          .reverse()
      },

      costTooltips(state) {
        return state.riskregister.costScores
          .map((s) => ({
            type: s.policy_type,
            value: s.value,
            tooltip: s.policy_text,
          }))
          .reverse()
      },

      effortTooltips(state) {
        return state.riskregister.effortScores
          .map((s) => ({
            type: s.policy_type,
            value: s.value,
            tooltip: s.policy_text,
          }))
          .reverse()
      },
    }),

    methods: {
      titleCase: StringHelpers.titleCase,

      addOwner(userId) {
        this.mutableVal.owners = this.mutableVal.owners || []
        this.mutableVal.owners.push(userId)
        this.mutableVal.owners = [...new Set(this.mutableVal.owners)]
        this.ownerUserId = null
        this.update()
      },

      removeOwner(ind) {
        this.mutableVal.owners.splice(ind, 1)
        this.update()
      },

      setMetric(metric, value) {
        this.mutableVal[metric] = value
        this.update()
      },

      update() {
        this.$emit('input', this.mutableVal)
      },
    },
  }
</script>
