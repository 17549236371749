<template lang="pug">
  div.modal(tabindex="-1",role="dialog",aria-labelledby="create-vulnerability-modal-label",aria-hidden="true")
    div.modal-dialog.modal-lg(role="document")
      div.modal-content
        div.modal-header
          h5#create-vulnerability-modal-label.modal-title {{ title }}
          button.close(type="button",data-dismiss="modal",aria-label="Close")
            span(aria-hidden="true") &times;
        div.modal-body
          div.new-or-rescan-selection(v-if="!newOrRescan.selected")
            div.d-flex.justify-content-center
              div.btn-group.btn-group-lg
                button.btn.btn-white(type="button",:class="(newOrRescan.selection === 'new') ? 'active' : ''",@click="selectNewOrRescan('new')") Add New Scan
                button.btn.btn-white(type="button",:class="(newOrRescan.selection === 'rescan') ? 'active' : ''",@click="selectNewOrRescan('rescan')") Perform a Rescan

            div.form-row.mt-4(v-if="newOrRescan.selection === 'rescan'")
              div.form-group.col-md-6.offset-md-3
                label Select Existing Scan
                select.form-control.form-control-lg(v-model="scanBeingRescanned",@change="selectScanBeingRescanned()")
                  option(:value="null") Select vulnerability scan...
                  option(v-for="scan in existingVulnerabilityScans",:value="scan") {{ scan.name }}

          div(v-else)
            div.new-scan-info
              div.only-new-scan(v-if="!scan")
                div.form-row
                  div.form-group.col-md-12
                    label(for='createVulnerabilityModalName') Name
                    input.form-control(type="text",v-model="newScan.name")
                div.form-row
                  div.form-group.col-md-12
                    label(for='create-vulnerability-modal-type') Scan Type
                    select#create-vulnerability-modal-type.form-control(v-model="newScan.scanType",@change="changeType")
                      option(value="aws_inspector") AWS Inspector
                      option(value="burp") Burp Suite Scan
                      option(value="manual") Manual
                      option(value="nessus") Nessus
                      option(value="nmap") Nmap
              div.form-row
                div.form-group.col-md-12
                  - //label(for='createVulnerabilityModalDate') Date Scanned
                  - //input#createVulnerabilityModalDate.form-control(v-model="userCopy.name",type='text',placeholder='Name')
                  datepicker-shards(
                    v-model="newScan.dateScanned",
                    label="Date Scan was Performed",
                    placeholder="Click Here to set date scanned")
              hr.mt-0

            - // ************************************************
            - // Any extra information or data required for the vendor or type
            - // of scan we're processing
            component(
              :is="extraVendorComponentMap[newScan.scanType]",
              :modal-id="modalId",
              :scan="scan",
              @processExtraInfo="processExtraInfo",
              @fileAdded="extraComponentFileAdded",
              @fileProcessing="extraComponentFileProcessing")
            - // ************************************************

            div.alert.alert-success.text-center.mt-2.mb-0(v-if="scanCreatedSuccessfully") Successfully saved your scan! We will send you an e-mail shortly when it's been processed.
            - //div.alert.alert-info.text-center.mt-2.mb-0(v-if="cannotSubmitScan") You'll be able to save your scan below once all fields are filled out.
        div.modal-footer
          div.mr-3(v-if="cannotSubmitScan") You'll be able to save your scan here once all fields are filled out.
          button.btn.btn-secondary(
            type="button"
            data-dismiss="modal") Cancel
          button.btn.btn-info(
            v-show="canMinimizeModalWhileUploading"
            type="button"
            data-dismiss="modal"
            @click="minimizeUntilFilesAreUploaded()") Minimize Until Files Are Uploaded
          button.btn.btn-primary(
            type="button"
            v-show="!cannotSubmitScan"
            @click="saveScan()") {{ (!!scan) ? 'Submit Rescan' : 'Create Scan' }}
</template>

<script>
  import $ from 'jquery'
  import ExtraAws from './extras/Aws'
  import ExtraFileUploader from './extras/ExtraFileUploader'
  import ExtraNessus from './extras/Nessus'
  import ApiVulnerability from '../../../factories/ApiVulnerability'
  import StringHelpers from '../../../factories/StringHelpers'
  import TimeHelpers from '../../../factories/TimeHelpers'

  export default {
    props: {
      isRescan: { type: Boolean, default: false },
      scan: { type: Object, default: null },
      pentestId: { type: [Number, String], default: null },
    },

    watch: {
      scan: {
        handler(scanInfo) {
          this.setupNewScan(scanInfo)
        },
        deep: true,
      },
    },

    data() {
      return getDefaultData()
    },

    computed: {
      title() {
        return this.scan
          ? `Import new scan files for: ${this.scan.name}`
          : 'Add New Vulnerability Scan'
      },
    },

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,
      titleCase: StringHelpers.titleCase,

      selectScanBeingRescanned() {
        this.$store.commit(
          'SET_VULNERABILITY_GLOBAL_SCAN_BEING_ASSIGNED',
          this.scanBeingRescanned
        )
        this.newOrRescan.selected = true
      },

      setupNewScan(scanInfo = this.scan) {
        const scanBackup = { ...scanInfo }
        this.newScan = {
          name: scanBackup.name,
          scanType: scanBackup.scan_type,
          files: [],
          dateScanned: new Date(),
        }
      },

      changeType() {
        // Reset extra info so it can get repopulated from any potential
        // extra components depending on if the vendor needs it more info.
        this.newScan.extraInfo = {}

        if (this.extraVendorComponentMap[this.newScan.scanType])
          return (this.cannotSubmitScan = true)

        this.cannotSubmitScan = false
      },

      processExtraInfo(info, isFinished = false) {
        this.newScan.extraInfo = { ...this.newScan.extraInfo, ...info }

        if (isFinished) this.cannotSubmitScan = false
        else this.cannotSubmitScan = true
      },

      extraComponentFileAdded(response) {
        const ind = this.filesProcessingAry.indexOf(true)
        this.filesProcessingAry[ind] = false
        this.newScan.files.push(response.filename)
        this.cannotSubmitScan = this.canMinimizeModalWhileUploading = !this.filesProcessingAry.every(
          (v) => !v
        )

        if (
          !this.cannotSubmitScan &&
          this.shouldShowAgainAfterFilesAreUploaded
        ) {
          this.shouldShowAgainAfterFilesAreUploaded = false
          $(`#${this.$el.id}`).modal('toggle')
        }
      },

      extraComponentFileProcessing() {
        this.filesProcessingAry.push(true)
        this.cannotSubmitScan = this.canMinimizeModalWhileUploading = true
      },

      minimizeUntilFilesAreUploaded() {
        this.shouldShowAgainAfterFilesAreUploaded = true
        $(`#${this.$el.id}`).modal('toggle')
        window.toastr.success(
          `Awesome, this window will pop back up as soon as all files are uploaded!`
        )
      },

      async selectNewOrRescan(type) {
        switch (type) {
          case 'rescan':
            this.newOrRescan.selection = 'rescan'
            break

          default:
            // 'new'
            return (this.newOrRescan = {
              selected: true,
              selection: 'new',
            })
        }

        try {
          const { scans } = await ApiVulnerability.getScans()
          this.existingVulnerabilityScans = scans
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async saveScan() {
        try {
          if (this.cannotSubmitScan) return

          const vulnInfo = await ApiVulnerability.save(
            this.newScan,
            (this.scan || {}).id,
            this.pentestId
          )
          this.successfulScanCreation()
          $(`#${this.$el.id}`).modal('hide')
          this.$router.push(`/vulnerability/${vulnInfo.id}`)
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      successfulScanCreation() {
        this.scanCreatedSuccessfully = true
        this.newScan = {
          name: `Manual Scan Ran On: ${this.getFormattedDate(
            undefined,
            'YYYY-MM-DD'
          )}`,
          scanType: 'manual',
          files: [],
          dateScanned: new Date(),
        }
      },
    },

    mounted() {
      this.modalId = this.$el.id
      if (this.isRescan)
        this.newOrRescan = { selected: true, selection: 'rescan' }

      if (this.scan) this.setupNewScan()

      $(`#${this.modalId}`).on('hidden.bs.modal', () => {
        // shouldShowAgainAfterFilesAreUploaded will be true if the modal
        // is simply hiding until all files are uploaded
        this.$emit('hide', this.shouldShowAgainAfterFilesAreUploaded)
        if (!this.shouldShowAgainAfterFilesAreUploaded && !this.isRescan)
          Object.assign(this.$data, getDefaultData())
      })
    },

    components: {
      ExtraAws,
      ExtraNessus,
      ExtraFileUploader,
    },
  }

  function getDefaultData() {
    return {
      newOrRescan: {
        selected: false,
        selection: null,
      },
      existingVulnerabilityScans: [],
      scanBeingRescanned: null,

      modalId: null,
      canMinimizeModalWhileUploading: false,
      cannotSubmitScan: true,
      scanCreatedSuccessfully: false,
      shouldShowAgainAfterFilesAreUploaded: false,
      filesProcessingAry: [],
      newScan: {
        name: `Manual Scan Ran On: ${TimeHelpers.getFormattedDate(
          undefined,
          'YYYY-MM-DD'
        )}`,
        scanType: 'manual',
        files: [],
        dateScanned: new Date(),
        extraInfo: {},
      },

      extraVendorComponentMap: {
        aws_inspector: 'ExtraAws',
        burp: 'ExtraFileUploader',
        nessus: 'ExtraNessus',
        nmap: 'ExtraFileUploader',
      },
    }
  }
</script>
