<template lang="pug">
  full-calendar(
    :options="options")
</template>

<script>
  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import timeGridPlugin from '@fullcalendar/timegrid'
  import listPlugin from '@fullcalendar/list'
  import interactionPlugin from '@fullcalendar/interaction'
  import rrulePlugin from '@fullcalendar/rrule'

  import Utilities from '@risk3sixty/utilities'
  const moment = Utilities.Dates.moment

  export default {
    components: {
      FullCalendar,
    },

    props: {
      events: {
        type: Array,
      },
      height: { type: String, default: null },
      initialView: {
        type: String,
        default: 'dayGridMonth',
      },
      numWeeks: {
        // For use with view 'shortenedDayGridMonth'
        type: Number,
        default: 4,
      },
      displayEventTime: {
        type: Boolean,
        default: false,
      },
      headerToolbar: {
        default: () => ({
          left: 'title',
          center: 'dayGridMonth,timeGridWeek,timeGridDay',
          right: 'prev today next',
        }),
      },
      timeZone: {
        type: String,
        default: 'UTC',
      },
      selectable: {
        type: Boolean,
        default: true,
      },
      weekends: {
        type: Boolean,
        default: true,
      },
      listDaySideFormat: {
        type: Boolean,
        default: true,
      },
    },

    watch: {
      events: {
        immediate: true,
        deep: true,
        handler(newValue) {
          // Set a unique id for each event so FullCalendar will render very occurrence
          // Set event_start and event_end to the specific event's start and end times
          this.options.events = newValue.map((ev, index) => ({
            ...ev,
            id: `compliance-${ev.id}-${index}-${moment(ev.start)
              .utc()
              .format(`YYYY-MM-DD`)}`,
            event_id: ev.id,
            event_start: ev.start,
            event_end: ev.end,
          }))
        },
      },
    },

    data() {
      return {
        options: {
          dateClick: this.handleDateClick,
          datesSet: this.handleRenderDates,
          dayCellDidMount: this.dayRender,
          displayEventTime: this.displayEventTime,
          eventClick: this.handleEventClick,
          events: this.events,
          headerToolbar: this.headerToolbar,
          initialView: this.initialView,
          listDaySideFormat: this.listDaySideFormat,
          height: this.height,
          views: {
            shortenedDayGridMonth: {
              type: 'dayGrid',
              duration: { weeks: this.numWeeks },
              dateAlignment: 'week',
              monthStartFormat: { day: 'numeric' },
              titleFormat: { month: 'long', year: 'numeric', commas: false },
              dayHeaderFormat: { weekday: 'narrow' },
            },
          },
          plugins: [
            dayGridPlugin,
            timeGridPlugin,
            listPlugin,
            interactionPlugin,
            rrulePlugin,
          ],
          select: this.handleDateSelect,
          selectable: this.selectable,
          timeZone: this.timeZone,
          weekends: this.weekends,
        },
      }
    },

    methods: {
      dayRender(args) {
        this.$emit('dayRender', args)
      },

      handleDateClick(args) {
        this.$emit('handle-date-click', args)
      },

      handleDateSelect(args) {
        this.$emit('handle-date-select', args)
      },

      handleEventClick(args) {
        this.$emit('handle-event-click', args)
      },

      handleRenderDates(args) {
        this.$emit('datesSet', args)
      },
    },
  }
</script>
