<template lang="pug">
  div.custom-control.custom-checkbox-sm.custom-checkbox
    input.custom-control-input(
      :id="`field-filter-${_uid}`"
      type="checkbox"
      v-model="theVal")
    label.custom-control-label(
      :for="`field-filter-${_uid}`"
      v-html="truncateString(text, 120)")
</template>

<script>
  import StringHelpers from '../../../factories/StringHelpers'

  export default {
    props: {
      text: { type: String, required: true },
      value: { type: Boolean, default: null },
    },

    computed: {
      theVal: {
        get() {
          return this.value
        },

        set(newVal) {
          this.$emit('input', newVal)
        },
      },
    },

    methods: {
      truncateString: StringHelpers.truncateString,
    },
  }
</script>
