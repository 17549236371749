<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-hidden="true")
    div.modal-dialog(role="document")
      div.modal-content
        form(@submit="onSubmit")
          div.modal-header
            h5.modal-title Add risk3sixty Content
            button.close(type="button",data-dismiss="modal",aria-label="Close")
              span(aria-hidden="true") &times;
          div.modal-body.pb-2
            div.text-center.mb-4
              h6.mb-1 Bulk Upload
              div.mb-2
                a(
                  :href="templateDownloadUrl",
                  target="_blank",
                  rel="noopener noreferrer") Click here for template
              div
                file-uploader.d-none(
                  clickable="#add-bulk-upload-file", 
                  acceptedFileTypes="text/csv, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xlsx",
                  :maxFileSize="1"
                  @added="successfullyUploadedFile"
                  @error="errorUploading")
                a#add-bulk-upload-file
                  p(v-if="bulkUploadFilename") {{ bulkUploadFilename }}
                  div(v-else)
                    button.btn.btn-sm.btn-white.d-table.mx-auto(type="button")
                      i.fal.fa-cloud-upload-alt.mr-1
                      span Upload File
            div(v-if="!bulkUploadFilename")
              hr
              div.form-group.mb-2
                label Type of Content {{ contentTypes.key }}
                v-select(
                  v-model="r3sContent.type",
                  :options="contentTypes",
                  :reduce="t => t.id",
                  :get-option-label="t => t.text")
              div.form-group.mb-2
                label Name
                input.form-control(v-model="r3sContent.title")
              div.form-group.mb-2
                label Description
                textarea.form-control(v-model="r3sContent.description")
              div.form-group.mb-2
                label Date (optional)
                datepicker-shards(
                  v-model="r3sContent.date"
                  placeholder="Select a relevant date for your content, if applicable.")            
              div.form-group.mb-2
                label Link (optional)
                input.form-control(v-model="r3sContent.link")
              div.form-group.mb-2
                label Image (optional)
                div.d-flex.align-items-center.justify-content-center
                  file-uploader.d-none(
                    clickable="#add-content-img", 
                    :maxFileSize="1"
                    @added="successfullyUploadedImage"
                    @error="errorUploading")
                  a#add-content-img
                    img.img-thumbnail.max-200(
                      v-if="!!r3sContent.img"
                      :src="`/file/s3/${r3sContent.img}`")
                    div(v-else)
                      button.btn.btn-sm.btn-white.d-table.mx-auto.mb-2(type="button")
                        i.fal.fa-cloud-upload-alt.mr-1
                        span Upload Image
          div.modal-footer
            button.btn.btn-secondary(type="button",data-dismiss="modal") Cancel
            div(v-if="!bulkUploadFilename")
              button.btn.btn-primary(type="submit") Create
            div(v-else)
              button.btn.btn-primary(type="submit") Bulk Upload
</template>

<script>
  import $ from 'jquery'
  import ApiGlobal from '../../factories/ApiGlobal'
  import TimeHelpers from '../../factories/TimeHelpers'

  export default {
    data() {
      return {
        r3sContent: defaultContent(),
        contentTypes: [],
        bulkUploadFilename: null,
        templateDownloadUrl:
          'https://s3.amazonaws.com/public.risk3sixty.com/assets/bulk_r3s_content_upload_template.csv',
      }
    },

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,

      resetContent() {
        this.r3sContent = defaultContent()
      },

      successfullyUploadedFile([, response]) {
        const filename = response.filename
        this.bulkUploadFilename = filename
      },

      successfullyUploadedImage([, response]) {
        const filename = response.filename
        this.r3sContent.img = filename
      },

      errorUploading([, err]) {
        window.toastr.error(err)
      },

      async onSubmit(evt) {
        evt.preventDefault()
        if (this.bulkUploadFilename) {
          this.bulkUploadR3sContent()
        } else {
          this.createR3sContent()
        }

        this.bulkUploadFilename = null
        this.resetContent()
        $(`#add-r3s-content-modal`).modal('hide')
      },

      async createR3sContent() {
        try {
          await ApiGlobal.createR3sContent(this.r3sContent)
          window.toastr.success('Successfully added content.')
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async bulkUploadR3sContent() {
        try {
          await ApiGlobal.bulkUploadR3sContent(this.bulkUploadFilename)
          window.toastr.success('Successfully added bulk content.')
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async getR3sContentTypes() {
        try {
          const { types } = await ApiGlobal.getR3sContentTypes()
          this.contentTypes = types
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.isLoadingLocal = false
        }
      },
    },

    async created() {
      await this.getR3sContentTypes()
    },
  }

  function defaultContent() {
    return {
      type: null,
      title: null,
      description: null,
      date: null,
      img: null,
      link: null,
    }
  }
</script>
