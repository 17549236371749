<template lang="pug">
  input.form-control(
    type="text"
    :placeholder="placeholder"
    autocomplete="off"
    v-model="mutableValue"
    @input="updateDebounce")
</template>

<script>
  import Utilities from '@risk3sixty/utilities'

  export default {
    props: {
      placeholder: { type: String, default: 'Search...' },
      value: { type: String },
    },

    watch: {
      value: {
        handler(newVal) {
          this.mutableValue = newVal
        },
      },
    },

    data() {
      return {
        mutableValue: this.value,
        updateDebounce: Utilities.debounce(() => {
          this.$emit('input', this.mutableValue)
        }, 500),
      }
    },
  }
</script>
