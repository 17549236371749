<template lang="pug">
  upgrade-container.text-small(
    :restrictionKey="navKey"
    :top="'-12'"
    :right="'-10'"
  )
    li.nav-item(v-if="shouldSeeNavItem" :class="liClasses")
      router-link.nav-link(
        v-if="!this.isModuleGated"
        :class="routerLinkClasses",
        :to="activePath && activePath !== 'never' ? itemAuthorizedLink((navKey === 'home') ? '/' : (activePath || '')) : ''",
        :data-toggle="(isDropdown && itemAuthorizedClass !== 'unauthorized') ? 'dropdown' : ''"
      )
        i.material-icons(v-if="materialIcon") {{ materialIcon }}
        i(v-if="faIcon",:class="faIcon", :style="iconStyles")
        span {{ text }}
      span.nav-link(v-else
        style="cursor: pointer;"
        :class="routerLinkClasses"
        @click="showGatedModal(null, navKey)"
      )
        i.material-icons(v-if="materialIcon") {{ materialIcon }}
        i(v-if="faIcon",:class="faIcon",:style="iconStyles")
        span {{ text }}
      slot
</template>

<script>
  import SubscriptionHelpers from '../factories/SubscriptionHelpers'

  export default {
    props: {
      text: { type: String },
      iconStyles: { type: String, default: '' },
      navKey: { type: String, default: null },
      activePath: { type: String, default: '' },
      faIcon: { type: String, default: null },
      materialIcon: { type: String, default: null },
      isDropdown: { type: Boolean, default: false },
      hideIfNotAuthorizedOverride: { type: Boolean, default: false },
    },

    computed: {
      activePathRegexpString() {
        return this.navKey === 'home'
          ? '^/$'
          : `^/${this.activePath.replace(/^\/(.*)/, '$1')}`
      },

      isModuleGated() {
        return !this.shouldSeeNavItem && this.isActionBlocked(this.navKey)
      },

      autoEnable() {
        return this.isDropdown
      },

      itemAuthorizedClass() {
        if (this.autoEnable) return true

        return this.$store.state.isModuleAuthorized(this.navKey)
          ? ''
          : 'unauthorized'
      },

      liClasses() {
        return [this.itemAuthorizedClass, this.isDropdown ? 'dropdown' : '']
      },

      routerLinkClasses() {
        return [
          this.isNavActiveClass(this.activePathRegexpString),
          this.isDropdown ? 'dropdown-toggle' : '',
        ]
      },

      shouldSeeNavItem() {
        try {
          if (this.isDropdown) return true

          const isAuthorized = this.$store.state.isModuleAuthorized(this.navKey)
          if (!isAuthorized) {
            return (
              // if this.hideIfNotAuthorizedOverride is true, then we'll always hide the nav item if not authorized
              !this.hideIfNotAuthorizedOverride &&
              this.$store.state.auth.user.show_unauthorized_modules
            )
          }
          return isAuthorized
        } catch (err) {
          return false
        }
      },
    },

    methods: {
      isActionBlocked: SubscriptionHelpers.isActionBlocked,
      showGatedModal: SubscriptionHelpers.showGatedModal,

      isNavActiveClass(regexString) {
        const regexAry = regexString.split(',').map((str) => new RegExp(str))
        const matches = regexAry.some((re) => re.test(this.$store.state.path))
        return matches ? 'active' : ''
      },

      itemAuthorizedLink(link) {
        return this.autoEnable
          ? link
          : this.$store.state.isModuleAuthorized(this.navKey)
          ? link
          : ''
      },
    },
  }
</script>

<style scoped lang="scss">
  li {
    &.unauthorized {
      order: 100;
    }

    &.internal {
      order: 1000;
    }
  }
</style>
