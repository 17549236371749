<template lang="pug">
  div.form-group
    label#label.m-0(v-if="showLabel") Tags
    add-tag-to-entity(
      v-if="canEdit"
      :only-emit="true"
      :size="null"
      :team-id="teamId"
      placeholder="Search for tags..."
      @added="addTag"
      @created="addTag")
    div.small(v-if="!mutableTags || mutableTags.length === 0")
      i No tags added yet...
    div.mt-2(v-else)
      pill-inline.mr-1.mb-1(
        v-for="(tag, ind) in mutableTags",
        :background="tag.color",
        :key="tag.id",
        :text="tag.name",
        @close="removeTag(ind)")
</template>

<script>
  export default {
    props: {
      tags: { type: Array },
      showLabel: { type: Boolean, default: true },

      // If the user has access to another organization than
      // the one currently logged into, this can be passed
      // to the component to create a tag in another org.
      teamId: { type: [Number, String], default: null },
      canEdit: { type: Boolean, default: true },
    },

    watch: {
      tags: {
        handler(newAry) {
          this.mutableTags = newAry
        },
        deep: true,
      },
    },

    data() {
      return {
        mutableTags: [...(this.tags || [])],
      }
    },

    methods: {
      addTag(tag) {
        this.$emit('addTag', tag)
      },

      removeTag(ind) {
        const [removedTag] = this.mutableTags.splice(ind, 1)
        this.$emit('removeTag', ind, removedTag)
      },
    },
  }
</script>
