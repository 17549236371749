<template lang="pug">
  line-chart(:options="options" :data="chartData")
  </template>

<script>
  import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Title,
    TimeScale,
    Tooltip,
    Legend,
  } from 'chart.js'
  import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm'
  import { Line as LineChart } from 'vue-chartjs'
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Title,
    TimeScale,
    Tooltip,
    Legend
  )
  export default {
    components: {
      LineChart,
    },
    props: {
      options: { type: Object, default: null },
      chartData: { type: Object, default: null },
    },
  }
</script>
