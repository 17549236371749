<template lang="pug">
  div
    div(v-if="!isEditMode") {{ value || defaultValue }}
    div.form-group.mb-0(v-else)
      input.form-control(v-if="controlType === 'text'",ref="text",:value="value",@input="valueChanged()")
      textarea.form-control(v-if="controlType === 'textarea'",ref="textarea",:value="value",@input="valueChanged()")
</template>

<script>
  export default {
    props: {
      defaultValue: { type: String, default: 'N/A' },
      isEditMode: { type: Boolean, default: false },
      controlType: { type: String, default: 'text' },
      value: { type: String, default: null }
    },

    methods: {
      valueChanged() {
        this.$emit('input', this.$refs[this.controlType].value)
      }
    }
  }
</script>
