<template lang="pug">
  div 
    a(@click="getScreenshot()")
      slot
</template>

<script>
  import html2canvas from 'html2canvas'
  import { jsPDF } from 'jspdf'

  export default {
    props: {
      //make sure that the screenshot ids in the array have # in front of it, or getScreenshot() will not work
      screenshotIdsArray: { type: Array, default: () => [] },
      fileType: { type: String, default: 'png' },
    },

    methods: {
      async getScreenshot() {
        const generateScreenshot = async (screenshot) => {
          const canvas = await html2canvas(screenshot, {
            width: screenshot.offsetWidth,
            height: screenshot.offsetHeight,
            scrollX: 0,
            scrollY: -window.scrollY,
          })
          const image = canvas.toDataURL('image/png')
          return { canvas, image }
        }

        const doc = new jsPDF()
        for (let i = 0; i < this.screenshotIdsArray.length; i++) {
          const screenshot = document.querySelector(this.screenshotIdsArray[i])
          const { canvas, image } = await generateScreenshot(screenshot)
          const imgWidth = doc.internal.pageSize.getWidth()
          const imgHeight = (canvas.height * imgWidth) / canvas.width
          //will download one or multiple pngs
          if (this.fileType === 'png') {
            const link = document.createElement('a')
            link.download = `fullCircle_download_${new Date().toLocaleDateString(
              'en-US'
            )}.png`
            link.href = image
            link.click()
          }
          //will add one or multiple pngs to a pdf
          if (this.fileType === 'pdf') {
            doc.addImage(image, 'PNG', 0, 0, imgWidth, imgHeight)
            if (i < this.screenshotIdsArray.length - 1) {
              doc.addPage()
            }
          }
        }
        //download pdf outside of for loop
        if (this.fileType === 'pdf') {
          doc.save(
            `fullCircle_download_${new Date().toLocaleDateString('en-US')}.pdf`
          )
        }
      },
    },
  }
</script>
