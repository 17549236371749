import { render, staticRenderFns } from "./CustomDetails.vue?vue&type=template&id=f9525aa6&scoped=true&lang=pug"
import script from "./CustomDetails.vue?vue&type=script&lang=js"
export * from "./CustomDetails.vue?vue&type=script&lang=js"
import style0 from "./CustomDetails.vue?vue&type=style&index=0&id=f9525aa6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9525aa6",
  null
  
)

export default component.exports