<template lang="pug">
  div.user-avatar-wrapper.d-flex.align-items-center.justify-content-center(
    @mouseover="isHovering = true"
    @mouseout="isHovering = false")
      img.user-avatar.rounded-circle(
        v-if="!!avatarFile",
        :src="`/file/s3/${avatarFile}`",
        :style="styles",
        alt='User Avatar')
      div.user-avatar.rounded-circle.d-flex.align-items-center.justify-content-center(
        v-else,
        :style="styles")
        div.font-weight-normal(style="font-size: medium;")  {{ userInitials }}

      a(@click="closeUser",v-show="shouldShowClose && isHovering")
        span.badge.badge-pill.badge-danger.user-avatar-badge.d-flex.align-items-center.justify-content-center
          i.fal.fa-times

      b-tooltip(v-if="elementId",:target="elementId")
        div
          strong {{ userName }}
        div {{ userEmail }}
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    props: {
      userId: { type: [Number, String], default: '' },
      maxHeight: { type: String, default: null },
      height: { type: String, default: null },
      shouldShowClose: { type: Boolean, default: true },
    },

    data() {
      return {
        isHovering: false,
        elementId: null,
      }
    },

    computed: {
      ...mapState({
        internalUsers: (state) => state.internalUsers || [],
        teamUsers: (state) => state.team.teamAndInternalUsers || [],
        uploadUsers: (state) => state.team.uploadUsers || [],
      }),

      avatarFile() {
        try {
          return this.user.avatar_file
        } catch (err) {
          return null
        }
      },

      styles() {
        const st = { border: '1px solid #ffffff' }

        if (this.height)
          return { ...st, height: this.height, width: this.height }

        return this.maxHeight
          ? { ...st, maxHeight: this.maxHeight, maxWidth: this.maxHeight }
          : { ...st }
      },

      userEmail() {
        return this.user ? this.user.username_email : ''
      },

      userInitials() {
        try {
          if (this.user) {
            if (this.user.first_name)
              return `${this.user.first_name[0].toUpperCase()}${
                (this.user.last_name || '')[0] || ''.toUpperCase()
              }`

            if (this.user.name) {
              const [firstName, lastName] = this.user.name.split(' ')
              return `${(firstName || '')[0].toUpperCase()}${(lastName ||
                '')[0].toUpperCase()}`
            }
          }
          return '?'
        } catch (err) {
          return '?'
        }
      },

      userName() {
        return this.user
          ? this.user.name || this.user.username_email
          : 'Unknown User'
      },

      user() {
        return [
          ...this.internalUsers,
          ...this.teamUsers,
          ...this.uploadUsers,
        ].find((u) => u.id == this.userId)
      },
    },

    methods: {
      closeUser(evt) {
        evt.stopPropagation()
        this.$emit('close', this.userId)
      },
    },

    mounted() {
      this.elementId = this.$el.id
    },
  }
</script>
