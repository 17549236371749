<template lang="pug">
  div
    div(v-if="canEdit")
      div(v-if="isEditing")
        rich-text-editor.border-bottom(
          v-if="richText" 
          ref="richTextInput" 
          v-model="mutableVal"
        )
        textarea.form-control.my-2(
          v-else-if="textArea"
          ref="textAreaInput"
          v-model="mutableVal"
        )
        datepicker-shards(
          v-else-if="datePicker"
          ref="datePickerInput"
          size="sm",
          placeholder="Not Set",
          :input-id="`click-to-edit-${_uid}`",
          v-model="mutableVal"
          :showClear="true")
        input.form-control.my-2(:placeholder="placeholder" ref="basicInput" :type="number ? 'number' : 'text'" v-else v-model="mutableVal" @keyup.enter="saveVal")
        div.d-flex.mt-2
          button.btn.btn-danger.btn-sm.mr-2.border(@click="isEditing = !isEditing") Cancel
          button.btn.btn-primary.btn-sm.border(@click="saveVal()") Save
      div.editable(
        v-else,
        @click="toggleEditing")
        slot(name="valueDisplay")
    slot(v-else,name="valueDisplay")
  
</template>

<script>
  import { nextTick } from 'vue'

  export default {
    props: {
      value: {
        default: '',
      },
      richText: {
        type: Boolean,
        default: false,
      },
      textArea: {
        type: Boolean,
        default: false,
      },
      datePicker: {
        type: Boolean,
        default: false,
      },
      number: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
        default: '',
      },
      canEdit: {
        type: Boolean,
        default: true,
      },
    },

    watch: {
      value: {
        immediate: true,
        handler(newVal) {
          this.mutableVal = newVal
        },
      },
    },
    data() {
      return {
        isEditing: false,
        mutableVal: '',
      }
    },

    methods: {
      async toggleEditing() {
        this.isEditing = !this.isEditing
        await nextTick() // Wait for inputs to render (v-if="isEditing")
        if (this.isEditing)
          if (this.richText) this.$refs.richTextInput.focus()
          else if (this.textArea) this.$refs.textAreaInput.focus()
          else if (this.datePicker) this.$refs.datePickerInput.focus()
          else this.$refs.basicInput.focus()
      },

      async saveVal() {
        this.$emit('save', this.mutableVal)
        this.$emit('input', this.mutableVal)
        this.isEditing = false
      },
    },
  }
</script>

<style></style>
