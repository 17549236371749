 <template lang="pug">
  div.d-flex.align-items-center
    div.d-flex.align-items-center(v-if="breadcrumbs.length > 0",v-for="(crumb, index) in breadcrumbs")
      router-link(:to="getCrumbInfo(crumb.link)") {{ getCrumbInfo(crumb.text) }}
      div.ml-2.mr-2(v-if="index + 1 < breadcrumbs.length") /
</template>

<script>
  import { getRoutes } from '@/router'

  export default {
    computed: {
      breadcrumbs() {
        const [ matchedRoute ] = this.$route.matched
        if (!matchedRoute)
          return []

        const routeConfig = getRoutes().find(r => r.path === matchedRoute.path)
        if (routeConfig)
          return routeConfig.breadcrumbs || []
        return []
      }
    },

    methods: {
      getCrumbInfo(pieceOfInfo) {
        if (typeof pieceOfInfo === 'function')
          return pieceOfInfo({ ...this.$route.params, pageTitle: this.$store.state.pageTitle })

        return pieceOfInfo
      }
    }
  }
</script>
