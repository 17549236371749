<template lang="pug">
  div
    div.mr-2(:class="strongTitle ? `strong` : ``") {{title}}
      template(v-if="tooltip")
        i.fal.fa-info-circle.ml-1(:id="`periodicity-tooltip-${_uid}`")
        b-tooltip(:target="`periodicity-tooltip-${_uid}`") {{ tooltip }}
    div.justify-content-between.d-flex.flex-wrap
      div.d-flex.align-items-center
        div(
          v-if="!isEditingFrequency"
          :class="enabled ? 'editable' : ''"
          @click="editFrequency") {{ periodicityAsText }}
        div.d-flex.flex-wrap.mb-2(v-else)
          div.d-flex
            //- Frequency
            div.pr-2
              input.form-control(v-model="mutableFrequency" style="width: 60px;" type="number")
            //- Periodicity
            select.form-control.pr-2(v-model="mutableFrequencyUnit" @change="setPeriodicity($event.target.value)")
              option(:value="null") Select periodicity...
              option(v-for="option in periodicityOptions" :key="option.value" :value="option.value") {{ option.text }}
          //- End Date
          div.d-flex.align-items-center.ml-2(v-if="includeEndDate")
            label.mr-2 End Date
            datepicker-shards(
              v-model="mutableFrequencyEndDate"
              :showClear="true")

      div.ml-2(v-if="isEditingFrequencyUnit || isEditingFrequency")
        div.d-flex
          button.btn.btn-danger.btn-sm.mr-1(@click="clearPeriodicity") Clear
          button.btn.btn-secondary.btn-sm.mr-1(@click="cancelEditingFrequency") Cancel
          button.btn.btn-primary.btn-sm(@click="checkAndEmit()" :disabled="!canSave") Save

</template>

<script>
  import moment from 'moment'

  export default {
    props: {
      frequency: {
        type: [String, Number],
        required: false,
      },
      frequencyUnit: {
        type: [String, Number],
        required: false,
      },
      includeEndDate: {
        type: Boolean,
        required: false,
        default: false,
      },
      frequencyEndDate: {
        type: [String, Date],
        required: false,
      },
      periodicityOptions: {
        type: Array,
        required: true,
      },
      strongTitle: {
        type: Boolean,
        required: false,
        default: true,
      },
      title: {
        type: String,
        required: false,
        default: 'Periodicity',
      },
      placeholder: {
        type: String,
        required: false,
        default: 'Select Periodicity...',
      },
      enabled: {
        type: Boolean,
        required: false,
        default: true,
      },
      tooltip: {
        type: String,
        required: false,
        default: '',
      },
    },

    watch: {
      frequency: {
        immediate: true,
        handler(newValue) {
          this.mutableFrequency = newValue
        },
      },
      frequencyUnit: {
        immediate: true,
        handler(newValue) {
          this.mutableFrequencyUnit = newValue
        },
      },
      frequencyEndDate: {
        immediate: true,
        handler(newValue) {
          this.mutableFrequencyEndDate = moment(newValue).toISOString()
        },
      },
    },

    data() {
      return {
        mutableFrequency: null,
        mutableFrequencyUnit: null,
        mutableFrequencyEndDate: null,
        isEditingFrequency: false,
        isEditingFrequencyUnit: false,
        isEditingFrequencyEndDate: false,
      }
    },

    computed: {
      periodicityAsText() {
        if (this.frequency && this.frequencyUnit) {
          const periodicityOption = this.periodicityOptions.find(
            (option) => option.value == this.frequencyUnit
          )

          if (!periodicityOption || !periodicityOption.text)
            return this.placeholder

          return `Every ${this.frequency} ${periodicityOption.text}${
            this.frequencyEndDate
              ? ` until ${
                  moment(this.frequencyEndDate).toISOString().split('T')[0]
                }`
              : ''
          }`
        }
        return this.placeholder
      },

      canSave() {
        // both or none (allow clearing)
        return (
          (this.mutableFrequency && this.mutableFrequencyUnit) ||
          (!this.mutableFrequency &&
            !this.mutableFrequencyUnit &&
            !this.mutableFrequencyEndDate)
        )
      },
    },

    methods: {
      clearPeriodicity() {
        this.mutableFrequency = null
        this.mutableFrequencyUnit = null
        this.mutableFrequencyEndDate = null
      },

      setPeriodicity(periodicity) {
        this.mutableFrequencyUnit = periodicity
        this.isEditingFrequencyUnit = true
      },

      editFrequency() {
        if (this.enabled) this.isEditingFrequency = !this.isEditingFrequency
      },

      cancelEditingFrequency() {
        if (this.isEditingFrequency) {
          this.isEditingFrequency = false
          this.mutableFrequency = this.frequency
        }
        if (this.isEditingFrequencyUnit) {
          this.isEditingFrequencyUnit = false
          this.mutableFrequencyUnit = this.frequencyUnit
        }
        if (this.isEditingFrequencyEndDate) {
          this.isEditingFrequencyEndDate = false
          this.mutableFrequencyEndDate = this.frequencyEndDate
        }
      },

      checkAndEmit() {
        if (this.mutableFrequency && isNaN(parseInt(this.mutableFrequency))) {
          window.toastr.error('Frequency must be a number')
          return
        }
        this.$emit('input', {
          frequency: this.mutableFrequency,
          frequency_unit: this.mutableFrequencyUnit,
          frequency_end_date: this.mutableFrequencyEndDate,
        })
        this.cancelEditingFrequency()
      },
    },
  }
</script>
