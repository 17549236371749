<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-labelledby="create-edit-tag-modal-label",aria-hidden="true")
    div.modal-dialog(role="document")
      div.modal-content
        form(@submit="updateTag")
          div.modal-header
            h5.modal-title #[span(:id="`tag-id-easteregg-${_uid}`") {{ (cachedTag.id) ? 'Edit' : 'Create' }}] Tag: {{ cachedTag.name || 'New Tag' }}
            b-popover(:target="`tag-id-easteregg-${_uid}`") ID: {{ cachedTag.id || 'No ID...' }}
            button.close(type="button",data-dismiss="modal",aria-label="Close")
              span(aria-hidden="true") &times;
          div.modal-body
            div.form-group.mb-2
              label Tag Name
              input.form-control(type="text",v-model="cachedTag.name")
            div.form-group.mb-2
              label Description
              textarea.form-control(v-model="cachedTag.description")
            div.form-group.mb-2
              label(for="edit-tag-color") Tag Color
              color-picker(v-model="cachedTag.color")
            div.form-group.mb-2
              label Preview
              div
                pill-inline(:background="cachedTag.color", isHollow=true , :text="cachedTag.name || 'New Tag'",:showClose="false")
          div.modal-footer
            button.btn.btn-secondary(
              type="button"
              data-dismiss="modal"
              @click="cancelled = true") Cancel
            button.btn.btn-primary(type="submit") Save
</template>

<script>
  import $ from 'jquery'
  import ApiTags from '../../factories/ApiTags'
  import { tabListener } from '../../factories/Utilities'

  export default {
    props: {
      tag: { type: Object, default: () => ({}) },

      // If the user has access to another organization than
      // the one currently logged into, this can be passed
      // to the component to create a tag in another org.
      teamId: { type: [Number, String], default: null },
    },

    data() {
      return {
        cachedTag: {},
      }
    },

    methods: {
      async updateTag(evt) {
        try {
          evt.preventDefault()

          let saveMethod = 'createNew'
          if (this.cachedTag.id) saveMethod = 'editTag'

          if (!this.cachedTag.name)
            return window.toastr.error(
              `Please at least add a name to create/update the tag.`
            )

          const { id } = await ApiTags[saveMethod](this.cachedTag, this.teamId)

          this.$emit('saved', { ...this.cachedTag, id })
          $(`#${this.$el.id}`).modal('toggle')
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    mounted() {
      $(`#${this.$el.id}`).on('show.bs.modal', () => {
        tabListener(this.$el.id)
        this.cachedTag = JSON.parse(JSON.stringify(this.tag || {}))
      })
    },
  }
</script>
