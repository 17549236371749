<template lang="pug">
  div(v-if="isFeatureEnabled")
    slot
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    props: {
      flag: { type: String, required: true }
    },

    computed: mapState({
      isFeatureEnabled(state) {
        const restrictedFeatures = state.featureFlags

        // there are no currently restricted features
        if (!restrictedFeatures)
          return true

        // the feature being checked is not currently restricted at all
        if (!restrictedFeatures[this.flag])
          return true

        // the currently logged in user is whitelisted for this restricted feature
        const teamEnabled = restrictedFeatures[this.flag][0].team
        const userEnabled = restrictedFeatures[this.flag][0].user
        if (teamEnabled || userEnabled)
          return true

        return false
      }
    })
  }
</script>
