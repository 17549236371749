<template lang="pug">
  component.data-header-circle.d-inline-block.m-0(
    :is="componentFromSize")
      div.card.card-small(:class="cardClasses")
        div.card-body.p-0.d-flex.justify-content-center.align-items-center
          i.fa(
            v-if="faIcon"
            :class="faIcon")
          span(v-else) {{ value }}
</template>

<script>
  import StringHelpers from '../../factories/StringHelpers'

  export default {
    props: {
      value: { type: [Number, String] },
      variant: { type: String },
      size: { type: String, default: 'lg' },
      faIcon: { type: String, default: null },
    },

    computed: {
      componentFromSize() {
        switch (this.size) {
          case 'lg':
            return 'h1'
          case 'md':
            return 'h3'
          case 'vsm':
            return 'h5'
          default:
            return 'h4'
        }
      },

      cardClasses() {
        let classes = []
        switch (this.size) {
          case 'sm':
            classes.push('border-sm')
            classes.push('padding-sm')
            break
          case 'md':
            classes.push('border-md')
            classes.push('padding-md')
            break
          case 'vsm':
            classes.push('border-vsm')
            classes.push('padding-vsm')
            break
          default:
            // sm
            classes.push('border-lg')
            classes.push('padding-lg')
            break
        }
        classes.push(this.variant ? `text-${this.variant}` : 'text-primary')
        return classes
      },
    },

    methods: {
      titleCase: StringHelpers.titleCase,
    },
  }
</script>

<style scoped>
  .border-lg {
    border: 8px solid;
  }

  .border-md {
    border: 5px solid;
  }

  .border-sm {
    border: 2px solid;
  }

  .border-vsm {
    border: 1px solid;
  }

  .padding-lg {
    padding: 1.5rem;
  }

  .padding-md {
    padding: 1rem;
  }

  .padding-sm {
    padding: 0.5rem;
  }

  .padding-vsm {
    padding: 0.25rem;
  }

  h1 {
    line-height: 1.5rem;
  }

  h3,
  h4 {
    line-height: 1rem;
  }

  h5 {
    line-height: 1.25rem;
  }
</style>
