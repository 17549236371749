<template lang="pug">
  div(id="blow-modal-container")
    div.blow-modal-background
      div.modal.mt-4
        img.img-fluid.w-50.h-50.mt-4(
          v-if="showEgg == 'montoya'"
          src="../../../public/images/themontoya.gif")
        img.img-fluid.w-50.h-50.mt-4(
          v-if="showEgg == 'rocky'"
          src="https://y.yarn.co/fe4bba8a-b5dd-4487-b9bc-103fc3279c28_text.gif")
        img.img-fluid.w-50.h-50.mt-4(
          v-if="showEgg == 'creed'"
          src="https://media.tenor.com/4Fq-bcMp0gUAAAAM/show-time.gif")
        img.img-fluid.w-50.h-50.mt-4(
          v-if="showEgg == 'drago'"
          src="https://media.giphy.com/media/koPSBhdvbCD4c/giphy.gif")
        img(v-if="showEgg == 'contra'" class="img-fluid" src="/public/images/contra.png")
        svg(
          v-if="showEgg == 'contra'"
          class="modal-svg"
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          preserveAspectRatio="none")
          rect(
            x="0"
            y="0"
            fill="none"
            width="226"
            height="162"
            rx="3"
            ry="3")
</template>

<script>
  import $ from 'jquery'
  import { addRockyCodeEventListener } from '../../factories/Rocky'
  import { addCreedCodeEventListener } from '../../factories/Creed'
  import { addDragoCodeEventListener } from '../../factories/Drago'
  import { addContraCodeEventListener } from '../../factories/Contra'
  import { addMontoyaCodeEventListener } from '../../factories/Montoya'

  export default {
    data() {
      return {
        modalClass: 'blow',
        removeKeypressBinding: this.handleRemoveOnKeypress.bind(this),
        showEgg: '',
      }
    },

    methods: {
      showMontoyaModal() {
        this.showEgg = 'montoya'
        this.showModal()
      },

      showRockyModal() {
        this.showEgg = 'rocky'
        this.showModal()
      },

      showContraModal() {
        this.showEgg = 'contra'
        this.showModal()
      },

      showCreedModal() {
        this.showEgg = 'creed'
        this.showModal()
      },

      showDragoModal() {
        this.showEgg = 'drago'
        this.showModal()
      },

      showModal() {
        $('#blow-modal-container').removeAttr('class').addClass(this.modalClass)
        $('body').addClass('blow-modal-active')

        $('#blow-modal-container').click(this.removeModal.bind(this))
        document.addEventListener('keydown', this.removeKeypressBinding)
      },

      handleRemoveOnKeypress(e) {
        const code = e.keyCode
        // 'esc' or 'enter'
        if ([13, 27].includes(code)) {
          document.removeEventListener('keydown', this.removeKeypressBinding)
          this.removeModal()
        }
      },

      removeModal() {
        this.showEgg = ''
        $('#blow-modal-container').addClass('out')
        $('body').removeClass('blow-modal-active')

        setTimeout(() => $('#blow-modal-container').removeAttr('class'), 400)
      },
    },

    created() {
      addMontoyaCodeEventListener(() => this.showMontoyaModal())
      addRockyCodeEventListener(() => this.showRockyModal())
      addContraCodeEventListener(() => this.showContraModal())
      addCreedCodeEventListener(() => this.showCreedModal())
      addDragoCodeEventListener(() => this.showDragoModal())
    },
  }
</script>
