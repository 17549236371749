<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-labelledby="create-edit-enum-modal-label",aria-hidden="true")
    //- div.modal-dialog.modal-lg(role="document")
    div.modal-dialog(role="document")
      div.modal-content
        form(@submit="updateEnum")
          div.modal-header
            h5.modal-title #[span(:id="`enum-id-easteregg-${_uid}`") {{ (currentEnum.id) ? 'Edit' : 'Create' }}] List: {{ currentEnum.name || 'New' }}
            b-popover(:target="`enum-id-easteregg-${_uid}`") ID: {{ currentEnum.id || 'No ID...' }}
            button.close(type="button",data-dismiss="modal",aria-label="Close")
              span(aria-hidden="true") &times;
          div.modal-body
            div.form-group(v-if="teamEnums && teamEnums.length > 0")
              label Choose an Existing List
              select.form-control(v-model="selectedEnumId",@change="getEnum")
                option(v-for="en in teamEnums",:value="en.id") {{ en.name }}
            div.form-group
              label List Name
              input.form-control(type="text",v-model="currentEnum.name")
            div.form-group
              label Description
              textarea.form-control(v-model="currentEnum.description")
            div.form-group
              div.card.card-small.no-shadow.border
                div.card-header
                  h6.m-0.d-flex.align-items-center
                    div Dropdown List Values
                    div.ml-auto
                      add-remove-btn-group(
                        @add="currentEnum.values.push(newVal())",
                        @remove="currentEnum.values.splice(currentEnum.values.length - 1, 1)")
                div.card-body.p-0.table-responsive
                  table.table.small-padding.small
                    thead
                      tr
                        th #
                        th List Item
                        th.nowrap
                          div Internal Value (Optional) #[i.fal.fa-info-circle(:id="`create-enum-val-${_uid}`")]
                          b-tooltip(:target="`create-enum-val-${_uid}`")
                            | For lists that will be evaluated in expressions,
                            | this can be used to populate numeric values that
                            | are internal (i.e. not show in the UI) but can be
                            | used in mathematical expressions for the item.
                        th Color
                    draggable(tag="tbody" v-model="currentEnum.values")
                      tr(v-if="!currentEnum.values || currentEnum.values.length === 0")
                        td(colspan="100%")
                          i No values added yet
                      tr(v-else,v-for="(val, ind) in currentEnum.values")
                        td {{ ind + 1 }}.
                        td 
                          input.form-control.form-control-sm(v-model="val.text")
                        td
                          input.form-control.form-control-sm(v-model="val.value")
                        td
                          pill-status-updater(
                            v-model="val.variant",
                            :options="variants",
                            :updatable="true")
                branded-card-footer.border-top
          div.modal-footer
            button.btn.btn-secondary(type="button",data-dismiss="modal") Cancel
            button.btn.btn-primary(type="submit") Save
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import ApiTeams from '../../factories/ApiTeams'

  export default {
    props: {
      enumId: { type: [Number, String], default: null },
    },

    watch: {
      async enumId(newId) {
        this.selectedEnumId = newId
        await this.getEnum()
      },
    },

    data() {
      return {
        selectedEnumId: this.enumId,
        currentEnum: this.newVal(),
      }
    },

    computed: mapState({
      teamEnums: (state) => state.team.enums,

      variants(state) {
        const colors = state.dashboard.variantColors()
        return [
          { value: 'danger', text: 'Danger', color: colors.danger },
          { value: 'info', text: 'Warning', color: colors.info },
          { value: 'gray', text: 'Neutral', color: colors.gray },
          { value: 'primary', text: 'OK', color: colors.primary },
          { value: 'success', text: 'Success', color: colors.success },
        ]
      },
    }),

    methods: {
      newVal() {
        return {
          value: null,
          text: null,
          ordering: null,
          variant: null,
          values: [],
        }
      },

      decodeString(str) {
        if (!str) return null
        return str
          .replace(/&gt;/g, '>')
          .replace(/&lt;/g, '<')
          .replace(/&amp;/g, '&')
      },

      async getEnum() {
        try {
          if (!this.selectedEnumId) return
          const res = await ApiTeams.getEnum(this.selectedEnumId)
          this.currentEnum = res.enum
          if (this.currentEnum) {
            this.currentEnum.values = this.currentEnum.values.map((e) => ({
              ...e,
              text: this.decodeString(e.text),
              value: this.decodeString(e.value),
            }))
          }
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async updateEnum(evt) {
        try {
          evt.preventDefault()
          if (!this.currentEnum.name)
            return window.toastr.error(
              `Please at least add a name to create/update the options.`
            )

          if (this.currentEnum.values.length === 0)
            return window.toastr.error(`Please add at least one value.`)

          const allValsHaveValue = this.currentEnum.values.every(
            (v) => !!(v.value || v.text)
          )
          if (!allValsHaveValue)
            return window.toastr.error(
              `All items need to have at least the 'List Item' column populated.`
            )

          const { id } = await ApiTeams.createUpdateEnum(this.currentEnum)
          this.$emit('saved', { ...this.currentEnum, id })
          $(`#${this.$el.id}`).modal('toggle')
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    async mounted() {
      $(`#${this.$el.id}`).on('shown.bs.modal', async () => {
        await Promise.all([
          this.$store.dispatch('getTeamEnums'),
          this.getEnum(),
        ])
      })
    },
  }
</script>
