<template lang="pug">
  b-popover(:target="target",:ref="target",placement="bottom",boundary="viewport", @shown="setCache")
    template(slot="title")
      div.d-flex.align-items-center
        h6.m-0 Filter By Column
        div.ml-auto
          i.ml-2.fal.fa-times.clickable(@click="$refs[target].$emit('close')")
    div.field-filter-scroller
      div(v-if="fieldValues.length === 0")
        i No values to filter by...
      div(v-else)
        template(v-if="fieldValuesActive.length > 0")
          div.mb-1.small.text-uppercase Active Filters:
          div(v-for="f in fieldValuesActive")
            field-filter-checkbox(
              :text="f._displayVal"
              v-model="fieldFilterCache[fieldType === 'expression' ? f._displayVal : f._value]"
              @input="changeFilter")
          hr

        div.mb-1(v-for="f in fieldValues" v-if="f._displayVal")
          field-filter-checkbox(
            :text="f._displayVal"
            v-model="fieldFilterCache[fieldType === 'expression' ? f._displayVal : f._value]"
            @input="changeFilter")
</template>

<script>
  import { mapState } from 'vuex'
  import Utilities from '@risk3sixty/utilities'
  import FieldFilterCheckbox from './FieldFilterCheckbox'
  import InventoryHelpers from '../../../factories/InventoryHelpers'

  const stripHtmlTags = Utilities.Strings.stripHtmlTags

  export default {
    props: {
      target: { type: String, required: true },
      field: { type: Object, required: true },
    },

    data() {
      return {
        isLoadingLocal: true,
        fieldFilterCache: {},
      }
    },

    computed: {
      ...mapState({
        rows: (state) => state.inventories.currentInventory.row_data,
        columns: (state) => state.inventories.currentInventory.fields,
        fieldFilters(state) {
          return (
            (state.inventories.filters[state.inventories.currentInventory.id] ||
              {})[this.fieldId] || []
          )
        },

        typesToRecords: (state) => {
          return {
            assessment_control: Object.values(
              state.inventories.assessmentControls
            ),
            enum: state.team.enums,
            risk: Object.values(state.inventories.risks),
            tag: state.tags,
            user: state.team.teamAndInternalUsers,
            vendor: state.vendormanagement.teamVendors.data,
          }
        },
      }),

      fieldId() {
        return this.field.id
      },

      fieldType() {
        return this.field.type
      },

      fieldMod1() {
        return this.field.mod1
      },

      enumOptsId() {
        return this.field.enum_options_id
      },

      idColTypes() {
        return ['assessment_control', 'enum', 'risk', 'tag', 'user', 'vendor']
      },

      fieldValuesActive() {
        return this.fieldValues.filter(
          (f) =>
            this.fieldFilterCache[
              this.fieldType === 'expression' ? f._displayVal : f._value
            ]
        )
      },

      fieldValues() {
        let valCache = {}
        return [
          ...new Set(
            (this.rows || [])
              .map((r) => {
                if (!r.values[this.fieldId])
                  return {
                    ...r,
                    _value: r.values[this.fieldId],
                    _displayVal: this.displayFilterValue(
                      r,
                      r.values[this.fieldId]
                    ),
                  }
                if (this.idColTypes.includes(this.fieldType)) {
                  return [...new Set(r.values[this.fieldId].split(','))].map(
                    (v) => ({
                      ...r,
                      _value: v,
                      _displayVal: this.displayFilterValue(r, v),
                    })
                  )
                }

                return {
                  ...r,
                  _value: r.values[this.fieldId],
                  _displayVal: this.displayFilterValue(
                    r,
                    r.values[this.fieldId]
                  ),
                }
              })
              .flat(Infinity)
              .filter((v) => {
                if (valCache[v._displayVal]) return false
                return (valCache[v._displayVal] = true)
              })
              .sort((v1, v2) => {
                if (isNaN(v1._displayVal) || isNaN(v2._displayVal)) {
                  return (v1._displayVal || '').toString().toLowerCase() <
                    (v2._displayVal || '').toString().toLowerCase()
                    ? -1
                    : 1
                }
                return v1._displayVal - v2._displayVal
              })
          ),
        ]
      },
    },

    methods: {
      changeFilter() {
        this.$store.commit('SET_INVENTORY_FILTERS', {
          fieldId: this.fieldId,
          values: Object.keys(this.fieldFilterCache).filter(
            (v) => this.fieldFilterCache[v]
          ),
        })
      },

      displayFilterValue(row, value = null) {
        const val = value || row._value

        if (this.fieldType === 'expression') {
          const getExprVal = (row) => {
            try {
              const expressionLatex = JSON.parse(this.fieldMod1).expressionLatex
              const computedVarObj = InventoryHelpers(
                this
              ).createExpressionEvalObj(
                JSON.parse(this.fieldMod1).fieldVariables,
                this.columns,
                row
              )
              const fn = window.evaluatex(expressionLatex)
              return fn(computedVarObj)
            } catch (err) {
              return null
            }
          }
          return getExprVal(row)
        }

        if (!val) return null

        if (this.fieldType === 'enum') {
          const enumRecord = this.typesToRecords[this.fieldType].find(
            (e) => e.id == this.enumOptsId
          )
          if (enumRecord) {
            const valRecord = enumRecord.values.find((v) => v.id == val)
            return valRecord.text || valRecord.value
          }
        } else if (this.idColTypes.includes(this.fieldType)) {
          return (
            this.typesToRecords[this.fieldType].find((r) => r.id == val) || {
              name: null,
            }
          ).name
        }
        return stripHtmlTags(val)
      },

      setCache() {
        this.fieldFilterCache = this.fieldValues.reduce((obj, v) => {
          return {
            ...obj,
            [v._value]: this.fieldFilters.includes(v._value),
          }
        }, {})
      },
    },

    components: {
      FieldFilterCheckbox,
    },
  }
</script>

<style lang="scss" scoped>
  .field-filter-scroller {
    max-height: 200px;
    overflow-y: scroll;
  }
  .popover {
    z-index: 1080;
  }
</style>
