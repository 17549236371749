<template lang="pug">
  div
    a(:id="`new-field-btn-${_uid}`")
      i.fal.fa-plus-circle.text-success

    field-edit-popover(
      :target="`#new-field-btn-${_uid}`"
      :spreadsheet-id="spreadsheetId"
      @saved="$emit('saved')")
</template>

<script>
  import FieldEditPopover from './FieldEditPopover'

  export default {
    props: {
      spreadsheetId: { type: [Number, String], required: true },
    },

    components: {
      FieldEditPopover,
    },
  }
</script>
