<template lang="pug">
  div.dashboard-widget
    div(v-if="isLoadingLocal")
      loader
    div.row(v-else)
      div.col-12
        small
          strong Days to Complete Vulnerabilities Summary
      div.col-12
        hr.mb-2.mt-2
      div.col-md-6.mb-2(v-for="severity in severitiesExceptInformational")
        div.card.status.border(:class="statusBgClassMap(severity)")
          div.card-body.p-1.text-center.text-white
            div.text-white.mb-0.mt-0
              strong {{ titleCase(severity) }}
            hr.mt-0.mb-0
            h1.text-white.mb-0.mt-0
              router-link.text-white(v-if="getDataBySeverity(severity).avg_seconds_to_close",:to="'/vulnerability/' + getDataBySeverity(severity).vulnerability_scan_id + '?severity=' + severity + '&status=complete'") {{ getDaysToClose(getDataBySeverity(severity).avg_seconds_to_close) }}
              div(v-else) -
            div.small
              div.small {{ (getDataBySeverity(severity).avg_seconds_to_close) ? 'days to complete' : '-' }}
              hr.mb-2.mt-2
              div.small {{ getDataBySeverity(severity).count_closed || 0 }} item(s) complete
</template>

<script>
  import ApiVulnerability from '../../../../factories/ApiVulnerability'
  import StringHelpers from '../../../../factories/StringHelpers'

  export default {
    data() {
      return {
        isLoadingLocal: true,
        summaryData: null
      }
    },

    computed: {
      severitiesExceptInformational() {
        return this.$store.state.vulnerability.severities.filter(s => s !== 'informational')
      }
    },

    methods: {
      titleCase: StringHelpers.titleCase,

      getDaysToClose(seconds) {
        return (seconds / 60 / 60 / 24).toFixed(1)
      },

      getDataBySeverity(severity) {
        return (this.summaryData[severity] || [])[0] || {}
      },

      statusBgClassMap(severity) {
        return `bg-transparent-dark-${severity}`
      }
    },

    async mounted() {
      try {
        const response = await ApiVulnerability.getDashboardTimeToCompleteSummary()
        this.summaryData = response.summary
      } catch(err) {
        window.toastr.error(err.message)
      } finally {
        this.isLoadingLocal = false
      }
    }
  }
</script>
