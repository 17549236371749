<template lang="pug">
    input.form-control(v-if="compVal === defaultSecret"  v-model="compVal" :class="size == 'sm' && 'form-control-sm'" @change="setFocusOnInput")
    secret-input(v-else v-model="compVal" ref="secretInput" defaultControlType='text')
</template>

<script>
  import defaultSecret from '../../../../constants/defaultSecret'

  export default {
    props: {
      value: { type: String, default: null },
      size: { type: String, default: null },
    },

    data() {
      return {
        defaultSecret,
      }
    },

    computed: {
      compVal: {
        get() {
          return this.value
        },

        set(t) {
          this.$emit('input', t)
        },
      },
    },

    methods: {
      /** When changing input components, focus on the new component's input */
      setFocusOnInput() {
        this.$refs.secretInput.$refs.input.focus()
      },
    },
  }
</script>
