import Utilities from '@risk3sixty/utilities'

const paginateArray = Utilities.Arrays.paginateArray

export default {
  tasksAry(state) {
    const ary = [...state.projects.tasks.data]
    let allTasks = []
    for (let group of ary) {
      allTasks = allTasks.concat(group.tasks)
    }
    return allTasks
  },

  projectsFrontendPaginatedTasks(state) {
    const info = state.projects.frontendPaginatedData
    const taskSearch = state.projects.taskSearch
    let tasks = [...state.projects.tasks.data]

    if (taskSearch) {
      tasks = tasks.slice().map((group) => {
        return {
          ...group,
          tasks: group.tasks.filter(
            (t) =>
              t.description &&
              t.description.toLowerCase().includes(taskSearch.toLowerCase())
          ),
        }
      })
    }

    const { data, numberOfPages, currentPage, dataLength } = paginateArray(
      tasks,
      info.perPage,
      info.currentPage
    )

    return {
      currentPage,
      data,
      numberPages: numberOfPages,
      perPage: info.perPage,
      totalCount: dataLength,
    }
  },

  projectsCriticalityBubbleInfo(state) {
    return state.projects.projectsCriticalityBubbleInfo(state.projects.allTasks)
  },
}
