<template lang="pug">
  div.d-flex.align-items-center(:class="wrapperClass")
    a.data-circle-link(
      v-for="(datum, ind) in data",@click="clickCircle(datum)",
      :id="`data-group-${_uid}-${ind}`",
      :class="dataClass"
      :style="clickable ? '' : 'cursor: auto'")
      data-circle(
        :class="datum.variant ? `text-${datum.variant}` : ''",
        :header="getDatumKey(datum)",
        :value="commaInThousands(datum.value)",
        :sub-value="datum.subvalue",
        :card-class="cardClass(datum.variant)",
        :text-class="bubbleTextClass(datum.value)"
        :is-vertical="isVertical"
        :size="size"
        :isSelfAssessment="isSelfAssessment"
        :isSelected="selectedKey === datum.rawKey")

      b-tooltip(:target="`data-group-${_uid}-${ind}`")
        | {{ datum.tooltip || datum.key }}
</template>

<script>
  import NumberHelpers from '../../factories/NumberHelpers'
  import StringHelpers from '../../factories/StringHelpers'

  export default {
    props: {
      data: { type: Array, required: true },
      isOverlap: { type: Boolean, default: false },
      isVertical: { type: Boolean, default: false },
      size: { type: String },
      isSelfAssessment: { type: Boolean, default: false },
      clickable: { type: Boolean, default: true },
      selectedKey: { type: String, default: null },
    },

    computed: {
      dataClass() {
        return this.isOverlap ? '' : 'mr-3'
      },

      wrapperClass() {
        return this.isOverlap ? 'circle-group' : ''
      },
    },

    methods: {
      commaInThousands: NumberHelpers.commaInThousands,
      titleCase: StringHelpers.titleCase,

      getDatumKey(datum) {
        return this.isOverlap ? '' : datum.key
      },

      bubbleTextClass(number) {
        let classes = ['text-white']
        if (number >= 1e3) classes.push('small')
        if (this.isSelfAssessment) classes.push('h5 m-0')
        // if (number < 1e3)
        //   classes.push('text-xlarge')
        // else
        //   classes.push('text-large')

        return classes
      },

      cardClass(variant) {
        if (!variant) return null
        return [`border-${variant}`, `bg-${variant}`]
      },

      clickCircle(datum) {
        this.$emit('click', datum)
      },
    },
  }
</script>

<style scoped lang="scss">
  a.data-circle-link:hover {
    text-decoration: none;
  }
</style>
