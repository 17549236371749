<template lang="pug">
  div
    i(v-if="awsKeys.length === 0")
      | No AWS keys available.
      a.mx-1(href="/settings/aws") Click here
      | to configure AWS keys in your organization.
    v-select(
      v-else-if="isAdmin"
      v-model="compVal"
      :options="awsKeys"
      :reduce="a => a.id"
      :get-option-label="u => u.name")
    div(v-else)
      i Must be admin to update...
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    props: {
      value: { type: String, default: null },
    },

    computed: {
      ...mapState({
        awsKeys: (state) =>
          state.awsKeys.map((k) => ({
            ...JSON.parse(k.decrypted_data),
            id: k.id,
          })),

        isAdmin: (_, getters) => getters.isAdmin,
      }),

      compVal: {
        get() {
          return this.value
        },

        set(t) {
          this.$emit('input', t)
        },
      },
    },

    async created() {
      if (this.isAdmin) await this.$store.dispatch('getAwsKeys')
    },
  }
</script>
