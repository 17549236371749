<template lang="pug">
  div
    select.form-control.form-control-sm(
      v-if="type == 'list' && editMode",
      v-model="item[frontendKey]",
      @change="changeSingleItem()")
      option(v-for="valObj in possibleValues",:value="valObj.id") {{ valObj.name }}
    datepicker-shards(
      v-else-if="type == 'date' && editMode",
      size="sm",
      placeholder="Select due date...",
      v-model="item[frontendKey]",
      :showClear="showDatePickerClear"
      @input="changeSingleItem()")
    a.text-nowrap(
      v-else,
      :class="extraClasses",
      @click="toggleEdit()") {{ showValue(truncateString(item[frontendShowKey] || item[frontendKey] || ((type === 'date' && 'No due date') || 'N/A'), truncate)) }}↓

    update-item-field-reason-modal(
      :unique-id="uniqueId",
      :field="frontendKey",
      :new-value="showValue(item[frontendKey], true)",
      @submitReason="changeSingleItem")
</template>

<script>
  import $ from 'jquery'
  import moment from 'moment'
  import uuidv1 from 'uuid/v1'
  import ApiVulnerability from '../../factories/ApiVulnerability'
  import StringHelpers from '../../factories/StringHelpers'
  import TimeHelpers from '../../factories/TimeHelpers'

  export default {
    props: {
      item: { type: Object },
      type: { type: String, default: 'list' },
      possibleValues: { type: Array },
      backendKey: { type: String, default: 'assignTo' },
      extraClasses: { type: String, default: null },
      frontendKey: { type: String, default: 'assigned_user_id' },
      frontendShowKey: { type: String, default: 'assigned_user_name' },
      isTitleCase: { type: Boolean, default: false },
      truncate: { type: Number, default: 20 },
      eventOnly: { type: Boolean, default: false },
      dateFormat: { type: String, default: 'YYYY-MM-DD' },
      showDatePickerClear: { type: Boolean, default: false },
    },

    data() {
      return {
        editMode: false,
        uniqueId: uuidv1(),
      }
    },

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,
      titleCase: StringHelpers.titleCase,

      toggleEdit() {
        return (this.editMode = !this.editMode)
      },

      truncateString(str, num) {
        return this.type === 'date'
          ? str
          : StringHelpers.truncateString(str, num)
      },

      showValue(val) {
        if (this.type === 'date') {
          if (val && !['N/A', 'No due date'].includes(val)) {
            if (val instanceof Date || moment(val).isValid())
              return this.getFormattedDate(val, this.dateFormat, true)
          }
        }

        return this.isTitleCase ? this.titleCase(val, true) : val
      },

      async changeSingleItem(updateReason) {
        try {
          const newValue = this.item[this.frontendKey]

          if (this.eventOnly) {
            this.toggleEdit()
            this.$emit('updated', this.item.id, newValue)
            return
          }

          if (
            this.backendKey === 'status' &&
            ['complete', 'not_applicable'].includes(
              this.item[this.frontendKey]
            ) &&
            !updateReason
          ) {
            return $(`#update-item-param-modal-${this.uniqueId}`).modal('show')
          }

          $(`#update-item-param-modal-${this.uniqueId}`).modal('hide')

          await this.updateItems(
            { [this.item.id]: true },
            {
              [this.backendKey]: this.item[this.frontendKey],
              reason: updateReason,
            }
          )
          if (this.frontendKey === 'assigned_user_id')
            this.item.assigned_user_name = this.$store.state.team.users.find(
              (u) => u.id == newValue
            ).name

          this.toggleEdit()
          this.$emit('updated', this.item.id, newValue)
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async updateItems(items, bulkInfo) {
        const selectedItemsIdAry = Object.keys(items)
          .map((id) => (items[id] ? id : null))
          .filter((i) => !!i)
        await ApiVulnerability.bulkUpdateItems(selectedItemsIdAry, bulkInfo)
      },
    },
  }
</script>
