<template lang="pug">
  div.pill-inline(
    :style="getStyle"
    :class="isHollow && 'hollow'"
    @click="$emit('click')"  
  )
    div.d-flex.align-items-center
      span.overflow-ellipses.no-hover.text-wrap(
        v-if="text"
        v-html="text")
      div(v-else)
        slot
      i.fal.fa-times-circle.clickable.ml-2(
        v-if="showClose"
        @click="clickClose")
</template>

<script>
  import Utilities from '@risk3sixty/utilities'

  const Colors = Utilities.Colors

  export default {
    props: {
      background: { type: String, default: '#1c567d' },
      text: { type: [Number, String], default: null },
      isHollow: { type: Boolean, default: false },
      showClose: { type: Boolean, default: true },
      fixedWidth: { type: Number, default: 0 },
    },

    computed: {
      getStyle() {
        let style = {}

        if (this.isHollow) {
          style = {
            color: this.background || '#1c567d',
            border: `2px solid ${this.background || '#1c567d'}`,
            fontWeight: '400',
          }
        } else if (this.background) {
          style = {
            backgroundColor: this.background,
          }

          if (Colors.getLumaNormalized(this.background) > 0.5)
            style = { ...style, color: '#1c567d' }
        }

        if (this.fixedWidth > 0)
          style = { ...style, width: `${this.fixedWidth}px` }

        return style
      },
    },

    methods: {
      clickClose() {
        this.$emit('close')
      },
    },
  }
</script>
<style lang="scss" scoped>
  .pill-inline {
    min-width: max-content;
  }
</style>
