<template lang="pug">
  div(@click.stop)
    b-dropdown(
      v-if="updatable"
      variant="link"
      toggle-class="p-0 text-decoration-none"
      boundary="viewport"
      ref="dropdown"
      no-caret
    )
      template(v-slot:button-content)
        pill-inline.wide(
          :class="(updatable ? 'clickable' : '') + additionalClasses",
          :background="bgColor",
          :text="fullText",
          :show-close="false",
          :is-hollow="isHollow",
          :data-toggle="'dropdown'")
      a.dropdown-item(
        v-for="option in options",
        :style="dropdownStyle(option)",
        @click="selectOption(option)")
        div.d-flex.align-items-center
          div {{ option.text || option.value }}
    pill-inline.wide(
      v-else
      :background="bgColor",
      :text="fullText",
      :show-close="false",
      :is-hollow="isHollow",
    )
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    props: {
      value: { type: [Number, String, Boolean] },
      options: { type: Array },
      updatable: { type: Boolean, default: true },
      isHollow: { type: Boolean, default: false },
      additionalClasses: { type: String, default: '' },
    },

    computed: {
      ...mapState({
        variantColors: (state) => state.dashboard.variantColors(),
      }),
      currentOption() {
        return this.options.find((o) => o.value === this.value) || {}
      },

      bgColor() {
        return (
          this.currentOption.color ||
          this.variantColors[this.currentOption.variant]
        )
      },

      fullText() {
        const t = this.text
        if (this.updatable) return `${t} ↓`
        return t
      },

      text() {
        return this.currentOption.text || this.currentOption.value || '-'
      },
    },

    methods: {
      dropdownStyle(option) {
        if (option.color) return { color: option.color }
        if (option.variant) return { color: this.variantColors[option.variant] }
        return null
      },

      selectOption(option) {
        this.$emit('input', option.value)
        this.$refs.dropdown.hide()
      },
    },
  }
</script>
