// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
// import $ from 'jquery'
import 'bootstrap'
import 'timesheet.js/dist/timesheet.min.js'
import toastr from 'toastr'
import * as FastClick from 'fastclick'
// import hljs from 'highlightjs' // see @/vue.config.js webpack alias
// import hljsJson from 'highlight.js/lib/languages/json'
import { Chart } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import InnerDonutChartPlugin from './plugins/InnerDonutChartPlugin'
import VueHighlightJS from './directives/vue-highlightjs'
import Fragment from 'vue-fragment'
import 'chartjs-plugin-datalabels'
import './chartjsJitter'
import store from './vuex/store'
import Risk3sixty from './components/Risk3sixty'
import router from './router'
import DecodeRecursive from './plugins/DecodeRecursive'

Vue.config.performance = !!window.webpackHotUpdate

Chart.register(ChartDataLabels)
Chart.register(InnerDonutChartPlugin)

Chart.defaults.set('plugins.datalabels', {
  ...Chart.defaults.plugins.datalabels,
  color: 'rgba(0, 0, 0, 0)',
})

// external libraries and components
import './components/reusable'

import R3sFetch from './factories/R3sFetch'
import { handleFetchResponse } from './factories/ApiHelpers'

// css
import 'timesheet.js/dist/timesheet-white.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './css/app.scss'
import './css/theme/extras.1.1.0.min.css'
// import 'highlight.js/styles/default.css'
import 'highlight.js/styles/agate.css'
import 'vue-select/dist/vue-select.css'

// NOTE: `vue-observe-visibility` uses Intersection Observer API
// which older browsers aren't compatible with. Might need to
// add the polyfill here: https://github.com/Akryum/vue-observe-visibility#installation
import VueObserveVisibility from 'vue-observe-visibility'
import vVisible from './directives/v-visible'
Vue.use(DecodeRecursive)
Vue.use(Fragment.Plugin)
Vue.use(VueObserveVisibility)
Vue.use(vVisible)
Vue.use(VueHighlightJS)

window.vueRouter = router
// hljs.registerLanguage('json', hljsJson)

// Initiate FastClick for mobile devices to remove the built-in 300ms
// delay. Read more in https://github.com/ftlabs/fastclick
if ('addEventListener' in document)
  document.addEventListener(
    'DOMContentLoaded',
    () => FastClick.attach(document.body),
    false
  )

window.toastr = toastr
window.toastr.options.positionClass = 'toast-bottom-right toastr-bottom-margin'
window.toastr.options.escapeHtml = true // avoid xss

// Needed to make component `TypeaheadInput` work, see src at
// src/components/reusable/typeahead/typeahead.js
Vue.prototype.$http = {
  async get(src, { params }) {
    const queryStringDelim = src.includes('?') ? '&' : '?'
    if (params && params.search)
      src = `${src}${queryStringDelim}search=${params.search}`

    const response = await R3sFetch(src)
    const data = await handleFetchResponse(response)
    return { data }
  },
}

// Moved to Risk3sixty.vue in `created` hook
// /**
//  * Sidebar toggles for responsive nav on small screens
//  */
// $(document).ready(function () {
//   // Toggle open when both the responsive icon(s) appear but also
//   // when a navigation link is clicked
//   $('.toggle-sidebar, #main-side-nav .nav-link:not(.dropdown-toggle), #main-side-nav a.dropdown-item').click(function (/*e*/) {
//     $('.main-sidebar').toggleClass('open')
//   })
// })

// window.stripe = Stripe('pk_test_6EzuJe5RCFuEH835XCVI6hwo')
// window.elements = window.stripe.elements()

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  store,
  el: '#risk3sixty',
  router,
  template: '<Risk3sixty/>',
  components: { Risk3sixty },
})
