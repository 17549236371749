<template lang="pug">
  datepicker-shards(
    placeholder="Select Date...",
    :input-id="'report-input-date-' + ind",
    :value="value",
    ref="input",
    @changedWithKey="updateValue")
</template>

<script>
  export default {
    props: {
      value: {
        type: [ Date, String ],
        required: true
      },
      ind: {
        type: [ Number, String ],
        default: 0
      }
    },

    methods: {
      updateValue(newVal) {
        this.$emit('input', newVal)
        this.$emit('change', newVal)
      }
    }
  }
</script>
