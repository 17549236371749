<template lang="pug">
  user-avatar-editable-group(
    :owners="reviewers",
    @add="addUser",
    @delete="deleteUser")
</template>

<script>
  import ApiVendorManagement from '../../factories/ApiVendorManagement'

  export default {
    props: {
      questionnaireId: { type: [ Number, String ] },
      reviewers: { type: Array }
    },

    computed: {
      reviewersUserIds() {
        return (this.reviewers || []).map(r => r.user_id || r.id)
      }
    },

    methods: {
      async addUser(newUserId) {
        try {
          const newUserIds = [ ...new Set([ ...this.reviewersUserIds, newUserId ]) ]
          await ApiVendorManagement.updateQuestionnaireReviewers(this.questionnaireId, newUserIds)
          this.$emit('updated')
        } catch(err) {
          window.toastr.error(err.message)
        }
      },

      async deleteUser(userId) {
        try {
          await ApiVendorManagement.removeQuestionnaireReviewer(this.questionnaireId, userId)
          this.$emit('updated')
        } catch(err) {
          window.toastr.error(err.message)
        }
      }
    }
  }
</script>