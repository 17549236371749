<template lang="pug">
  div
    template(v-if="$store.state.isModuleAuthorized('assessments')")
      div.mt-2.py-2.border-bottom
        div.d-flex
          h6.mb-1 Requests:
        div.p-2(v-if="!event.requests || event.requests.length === 0")
          i No requests linked to event yet...
        ol(v-else)
          li.p-2(v-for="(request, ind) in event.requests")
            div.d-flex
              div.d-flex
                router-link(:to="`/assessments/evidence/${request.project_id}/${request.id}?noscroll`")
                  strong(v-html="request.name")
                  small.ml-2(:class="`text-${(requestStatusInfo(request.project_id, request.status).variant || 'primary')}`") ({{ requestStatusInfo(request.project_id, request.status).text || 'N/A' }})
              div.ml-auto
                a.ml-2
                  i.fal.fa-times.text-danger(:id="`unmap-request-${ind}`")
                  confirmation-popover(
                    :target="`unmap-request-${ind}`",
                    header="Unlink Request?",
                    :text="`Are you sure you want to unlink request from event?`",
                    @confirm="mapEvent('assessmentRequest', request.id, true)")

    div.mt-2.py-2
      div.d-flex
        h6.mb-1 Risks:
      div.p-2(v-if="!event.risks || event.risks.length === 0")
        i No risks linked to event yet...
      ol(v-else)
        li.p-2(v-for="(risk, ind) in event.risks")
          div.d-flex
            router-link(:to="`/risk/register/${risk.risk_register_id}/item/${risk.id}`")
              strong(v-html="risk.description")
            risk-register-data-circle.ml-1(
              type="risk",
              :value="getResidualRisk(risk) || 'N/A'",
              size="25")
            div.ml-auto
              a.ml-2
              i.fal.fa-times.text-danger(:id="`unmap-risk-${ind}`")
              confirmation-popover(
                :target="`unmap-risk-${ind}`",
                header="Unlink Risk?",
                :text="`Are you sure you want to unlink risk from event?`",
                @confirm="mapEvent('riskRegisterItem', risk.id, true)")
          div.small.d-flex.justify-content-between
            div(:class="`text-${riskStatuses.find(s => s.value == risk.status).variant}`") {{ riskStatuses.find(s => s.value == risk.status).text }}
            div created {{ getFormattedDate(risk.created_at) }}
            
</template>

<script>
  import { mapState } from 'vuex'
  import TimeHelpers from '../../factories/TimeHelpers'

  export default {
    props: {
      event: { type: Object, required: true },
    },
    computed: {
      ...mapState({
        allProjectStatuses: (state) => state.assessments.projectStatuses,
        allControlStatuses: (state) => state.assessments.allControlStatuses,
        allRequestStatuses: (state) => state.assessments.allRequestStatuses,

        riskStatuses: (state) => state.riskregister.statuses || {},
      }),
    },

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,

      getResidualRisk(risk) {
        return risk.impact * risk.likelihood
      },

      controlStatusInfo(projectId, status) {
        const statuses = this.allProjectStatuses[projectId]
          ? this.allProjectStatuses[projectId].control
          : this.allControlStatuses
        return statuses.find((s) => s.value === status) || {}
      },

      requestStatusInfo(projectId, status) {
        const statuses = this.allProjectStatuses[projectId]
          ? this.allProjectStatuses[projectId].request
          : this.allRequestStatuses
        return statuses.find((s) => s.value === status) || {}
      },

      update() {
        this.$emit('update')
      },

      async mapEvent(type, typeId, remove = false) {
        try {
          await this.$store.dispatch('mapCalendarEvent', {
            eventId: this.event.id,
            type: type,
            id: typeId,
            remove,
          })

          window.toastr.success(`Successfully ${remove ? 'un' : ''}mapped!`)
          this.update()
        } catch (err) {
          window.toastr.error(err)
        }
      },
    },

    async created() {
      await Promise.all([
        this.$store.dispatch('getAssessmentStatuses'),
        this.$store.dispatch('getRiskRegisterStatuses'),
      ])
    },
  }
</script>
