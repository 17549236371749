<template lang="pug">
  - // TODO: When we want to turn on search, remove the `.d-none` classes from
  - // both the `form` element below and `div.input-group` right below it
  form.w-100.d-none(action='#',:class="getFormClasses")
    div.d-flex.align-items-center
      breadcrumbs.ml-4
    div.input-group.input-group-seamless.ml-3.d-none(v-if="$store.state.isLoggedIn && $store.state.session")
      div.input-group-prepend
        div.input-group-text
          i.fal.fa-search
      input.navbar-search.form-control(type='text',placeholder='Search for something...',aria-label='Search')
</template>

<script>
  export default {
    props: {
      location: { type: String, default: 'topbar' }
    },

    computed: {
      getFormClasses() {
        return (this.location === 'sidebar')
          ? 'main-sidebar__search border-right d-sm-flex d-md-none d-lg-none'
          : 'main-navbar__search d-none d-md-flex d-lg-flex'
      }
    }
  }
</script>
