<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-hidden="true")
    div.modal-dialog.modal-xl(role="document")
      div.modal-content
        div.modal-header
          h5.modal-title Extension Execution History
          button.close(type="button",data-dismiss="modal",aria-label="Close")
            span(aria-hidden="true") &times;
        div.modal-body
          div(v-if="isLoading")
            loader
          div(v-else)
            div.table-responsive.m-0
              table.table.table-striped.table-bordered.small-padding.small.mb-0
                //- thead.table-header
                //-   tr
                //-     th(colspan="100%")
                //-       h6.m-0 {{ control.control_number }}: {{ control.name }}
                thead
                  tr
                    th #
                    th Date
                    th Status
                    th Output
                    th Supplemental Files
                tbody
                  tr(v-if="history.length === 0")
                    td(colspan="100%")
                      i No execution history for this extension yet...
                  tr(v-else,v-for="(item, index) in history")
                    td {{ index + 1 }}.
                    td {{ getFormattedDate(item.created_at, 'MMM D, YYYY h:mm a') }}
                    td {{ item.status }}
                    td
                      textarea.form-control(
                        readonly
                        rows="6"
                        :value="item.output")
                    td
                      ul.list-unstyled(v-if="item.files && item.files.length > 0")
                        li.mb-2.bg-light(v-for="(att, ind) in item.files")
                          attachment-item(
                            :attachment="att"
                            :index="ind")
                      div(v-else)
                        i No supplemental files...
          div.modal-footer
            button.btn.btn-secondary(type="button",data-dismiss="modal") Cancel
            button.btn.btn-primary(type="button",@click="getHistory") Refresh History
</template>

<script>
  import $ from 'jquery'
  import ApiExtensions from '../../../factories/ApiExtensions'
  import StringHelpers from '../../../factories/StringHelpers'
  import TimeHelpers from '../../../factories/TimeHelpers'

  export default {
    props: {
      mappingId: { type: [Number, String], default: null },
    },

    data() {
      return {
        history: [],
        isLoading: false,
      }
    },

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,
      titleCase: StringHelpers.titleCase,

      async getHistory() {
        try {
          const info = await ApiExtensions.getExecutionsForMapping(
            this.mappingId,
            1,
            1e2
          )
          this.history = info.data
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    mounted() {
      $(`#${this.$el.id}`).on('show.bs.modal', async () => {
        if (this.mappingId) {
          this.isLoading = true
          await this.getHistory()
        }
        this.isLoading = false
      })
    },
  }
</script>
