import ApiReports from '@/factories/ApiReports'

export default {
  async getAllReportConfigurations(
    { commit, state },
    reset = state.globalReset
  ) {
    if (state.reporting.reports.data.length > 0 && !reset) return

    // pagination not yet split between custom and default reports
    const page = 1 //state.reporting.pagination.reports.page
    const perPage = 9999 //state.reporting.pagination.reports.perPage
    const sorting = state.reporting.sorting.reports
    const { reportConfigurations } =
      await ApiReports.getAllReportConfigurations(page, perPage, sorting)
    commit('SET_REPORTS', reportConfigurations)
  },

  async getReportTypes({ commit, state }, reset = state.globalReset) {
    if (Object.keys(state.reporting.types).length > 0 && !reset) return

    const { types } = await ApiReports.allTypes()
    commit('SET_REPORT_TYPES', types)
  },

  async setCurrentReportType({ commit, state }, typeId) {
    if (state.reporting?.currentType?.id === typeId) return

    const foundType =
      Object.values(state.reporting.types)
        .flat()
        .find((t) => t.id === typeId) || {}
    commit('SET_CURRENT_REPORT_TYPE', foundType)
  },

  async setReportsSorting({ commit, state, dispatch }, { key, direction }) {
    commit('SET_REPORT_SORTING', { key, direction })
    if (state.reporting.reports.data.length > 0)
      dispatch('getAllReportConfigurations', true)
  },

  async setReportsPage({ commit, state, dispatch }, page) {
    commit('SET_REPORTS_PAGE', page)
    if (state.reporting.reports.data.length > 0)
      dispatch('getAllReportConfigurations', true)
  },

  async setReportsPerPage({ commit, state, dispatch }, perPage) {
    commit('SET_REPORTS_PER_PAGE', perPage)
    if (state.reporting.reports.data.length > 0)
      dispatch('getAllReportConfigurations', true)
  },
}
