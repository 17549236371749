<template lang="pug">
  div.d-flex.align-items-center.flex-wrap
    label.mb-0.mr-2(v-if="label")
      strong {{ label }}
    button.btn.btn-vsm.mr-2(
      v-for="option in options"
      :class="activeButtonClass(option.value)"
      @click="mutableValue = option.value")
        | {{ option.text }}
</template>

<script>
  export default {
    props: {
      options: { type: Array, required: true },
      value: { type: [Number, Object, String], default: null },
      label: { type: String, default: null },
    },

    computed: {
      mutableValue: {
        get() {
          return this.value
        },

        set(newVal) {
          this.$emit('input', newVal)
        },
      },
    },

    methods: {
      activeButtonClass(btnVal) {
        return btnVal == this.value ? 'btn-primary' : 'btn-white'
      },
    },
  }
</script>
