<template lang="pug">
  data-circle(
    :value="text",
    :size="size",
    :card-class="isActive ? 'bg-primary text-white' : 'bg-light'")
</template>

<script>
  export default {
    props: {
      text: { type: String, required: true },
      isActive: { type: Boolean, default: false },
      size: { type: [ Number, String ], default: 30 }
    }
  }
</script>