<template lang="pug">
  tr
    td
      div.form-group.w-100.mb-0.ml-0.mr-3.d-flex.align-items-top
        i.clickable.fal.fa-times.text-danger.mr-2(@click="addRemoveChoice('delete')")
        //- label.m-0
        //-   | {{ ind + 1 }}.#[sup.text-danger *]
        //-   | #[i.clickable.fal.fa-times.text-danger.ml-1(@click="addRemoveChoice('delete')")]
        input.form-control(type="text" v-model="text")
    template(v-if="isSelfAssessment")
      td
        div.d-flex.justify-content-top.align-items-top
          div.custom-control.custom-toggle-sm.custom-toggle
            input.custom-control-input(
              type="checkbox"
              :id="`question-choice-correct-${_uid}`"
              :name="`question-choice-correct-${_uid}`"
              v-model="is_correct")
            label.custom-control-label(:for="`question-choice-correct-${_uid}`")
      td
        div.form-group.mr-2
          div.bg-white
            rich-text-editor.border.rounded(
              :options="{ theme: 'bubble' }"
              v-model="recommendation")
      //- td(v-if="isR3sOrg")
      //-   div.form-group.mr-2
      //-     div.d-flex.align-items-center(v-for="(finding) in formalFindings")
      //-       div
      //-         strong {{ truncateString(stripHtmlTags(finding.summary), 20) }}
      //-       div.ml-1
      //-         strong
      //-           a.text-danger(@click="removeFormalFinding(finding.id)")
      //-             i.fal.fa-times-circle
      //-     button.btn.btn-sm.btn-outline.pl-0(@click="showModal(`add-findings-modal-${ind}`)") + Add Formal Finding
      td
        div.form-group.mr-2
          div.bg-white
            v-select(
              style="width: max-content;"
              v-model="pointValue"
              placeholder="Select Criticality"
              :options="[{ val: null, text: 'Not Set' }].concat(pointValues.filter((v) => v.val > -1))"
              :reduce="v => v.val"
              :get-option-label="v => v.text"
              :clearable="false")
      td
        div.form-group.mb-2
          div(v-for="(q, ind) in triggerQuestions")
            typeahead-input-new(
              v-if="!(q && q.id)"
              placeholder="+ Add Branch"
              :src="`/api/1.0/vendormanagement/questionnaire/questions/search?excludeBranched=true&id=${questionnaire.id}&groupId=${question.group_id}&questionId=${question.id}`"
              keys-from-response="questions"
              :show-prop-function="qu => `${stripHtmlTags(qu.text).slice(0, 50)}...`"
              :optionFilterFunction="qu => !question.trigger_question_choices.flatMap((c) => parseInt(c.question_id)).includes(parseInt(qu.id))"
              @input="selectTriggerQuestion")
            div.d-flex.align-items-center(v-else)
              div
                strong {{ truncateString(stripHtmlTags(q.text), 50) }}
              div.ml-3
                strong
                  a.text-danger(@click="selectTriggerQuestion(null, ind)")
                    i.fal.fa-times-circle
    
      //- entity-finding-map-modal(
      //-   :id="`add-findings-modal-${ind}`"
      //-   entityName="Question Choice",
      //-   :linkControls="false"
      //-   @selected="addFormalFinding")
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import Utilities from '@risk3sixty/utilities'
  import StringHelpers from '../../../factories/StringHelpers'
  import EntityFindingMapModal from '../../reusable/EntityFindingMapModal'

  const stripHtmlTags = Utilities.Strings.stripHtmlTags

  export default {
    components: {
      EntityFindingMapModal,
    },

    props: {
      value: { type: Object, required: true },
      ind: { type: Number, required: true },
      question: { type: Object, required: true },
      questionnaire: { type: Object, required: true },
    },

    watch: {
      value: {
        handler(newVal) {
          this.triggerQuestions = [{ id: null, text: null }].concat(
            JSON.parse(JSON.stringify(newVal.trigger_questions || []))
          )

          this.isBranching =
            this.triggerQuestions && this.triggerQuestions.length > 1

          this.formalFindings = newVal.formal_findings
        },
        deep: true,
      },
    },

    data() {
      return {
        isBranching: false,
        triggerQuestions: [],
        formalFindings: [],
      }
    },

    computed: {
      ...mapState({
        types: (state) => state.vendormanagement.questionnaireQuestionTypes,
        pointValues: (state) => state.questionnaire.questionPointValues,
        isSelfAssessment: (_, getters) => getters.isSelfAssessment,
        // isR3sOrg: (state) => state.session.current_team.id.toString() === '1',
      }),

      is_correct: {
        get() {
          return this.value.is_correct
        },

        set(is_correct) {
          this.$emit('input', {
            ...this.value,
            is_correct,
          })
          this.$emit('correctChanged', this.ind, is_correct)
        },
      },

      text: {
        get() {
          return this.value.text
        },

        set(text) {
          this.$emit('input', {
            ...this.value,
            text,
          })
        },
      },

      recommendation: {
        get() {
          return this.value.recommendation
        },

        set(recommendation) {
          this.$emit('input', {
            ...this.value,
            recommendation,
          })
        },
      },

      pointValue: {
        get() {
          return this.value.point_value
        },

        set(point_value) {
          this.$emit('input', {
            ...this.value,
            point_value,
          })
        },
      },
    },

    methods: {
      stripHtmlTags,
      truncateString: StringHelpers.truncateString,

      showModal(id) {
        $(`#${id}`).modal('show')
      },

      addFormalFinding(finding) {
        const { entityId, summary } = finding
        this.formalFindings.push({ id: entityId, summary })
        this.emitUpdatedFormalFindings()
      },

      removeFormalFinding(findingId) {
        this.formalFindings = this.formalFindings.filter(
          (finding) => finding.id != findingId
        )
        this.emitUpdatedFormalFindings()
      },

      emitUpdatedFormalFindings() {
        this.$emit('input', {
          ...this.value,
          formal_findings: this.formalFindings,
        })
      },

      addRemoveChoice(which = 'add') {
        which == 'delete'
          ? this.$emit('removeChoice', this.ind)
          : this.$emit('addChoice')
      },

      removeBranching() {
        this.isBranching = false
        this.triggerQuestions = [{ id: null, text: null }]
      },

      selectTriggerQuestion(question, removeIndex) {
        if (removeIndex) {
          this.triggerQuestions.splice(removeIndex, 1)
        } else {
          this.triggerQuestions = this.triggerQuestions.concat([question])
        }
        let uniqueIds = {}
        this.$emit('input', {
          ...this.value,
          trigger_questions: this.triggerQuestions.filter((q) => {
            const id = q && q.id
            if (!id) return false
            if (uniqueIds[id]) return
            return (uniqueIds[id] = true)
          }),
        })
      },
    },

    created() {
      this.triggerQuestions = [{ id: null, text: null }]
      if (this.value.trigger_questions) {
        this.triggerQuestions = this.triggerQuestions.concat(
          JSON.parse(JSON.stringify(this.value.trigger_questions))
        )
      }

      this.isBranching =
        this.triggerQuestions && this.triggerQuestions.length > 1

      this.formalFindings = this.value.formal_findings ?? []
    },
  }
</script>
<style scoped>
  .table td {
    vertical-align: top;
  }
</style>
