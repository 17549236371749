export default {
  install(Vue) {
    Vue.prototype.$decodeURIComponentRecursive = function(str) {
      try {
        const newStr = decodeURIComponent(str)
        if (str === newStr) return newStr
        return this.$decodeURIComponentRecursive(newStr)
      } catch (err) {
        return str
      }
    }
  },
}
