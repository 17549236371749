<template lang="pug">
  div.d-flex.align-items-center
    //- This ref is referred to by Secret.vue to set the focus
    input.form-control(:type="secretControlType",v-model="secret", ref="input", :disabled="disabled")
    div.ml-2(v-if="secretHasValue() && !disableShow")
      a.small(href="javascript:void(0)",@click="toggleSecretControlType")
        i {{ (secretControlType === 'text') ? 'hide' : 'show' }}
</template>

<script>
  export default {
    props: {
      value: { type: String, default: '' },
      disableShow: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      defaultControlType: { type: String, default: 'password' },
    },

    data() {
      return {
        secretControlType: this.defaultControlType,
      }
    },

    computed: {
      secret: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', val)
        },
      },
    },

    methods: {
      toggleSecretControlType() {
        return (this.secretControlType =
          this.secretControlType === 'text' ? 'password' : 'text')
      },

      secretHasValue() {
        return this.secret && this.secret.length > 0
      },
    },
  }
</script>
