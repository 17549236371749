<template lang="pug">
table.table.small.table-striped.ccm-evidence-preview-table
  thead.sticky-header.ccm
    th(v-for="columnHeader in tableHeaders") {{prettyName(columnHeader)}}
  tbody
    tr(v-for="row in tabularData")
      td(v-for="columnHeader in tableHeaders")
        div.table-datum(v-html="row[columnHeader]")
  </template>

<script>
  import { prettyName } from '../../factories/ConnectedAppHelpers'
  export default {
    props: {
      tabularData: { type: Array, default: () => [] },
    },

    data() {
      return {}
    },

    computed: {
      tableHeaders() {
        if (this.tabularData.length === 0) {
          return ['No Data']
        }
        return Object.keys(this.tabularData[0])
      },
    },

    methods: {
      prettyName,
    },

    mounted() {},
  }
</script>
<style lang="scss">
  .table-datum {
    max-height: 50px;
    overflow-y: scroll;
  }
</style>
