<template lang="pug">
  div
    div.mb-2.d-flex.justify-content-center(v-if="isEditing")
      add-tag-to-entity(
        :allow-create-tag="true"
        :only-emit="true"
        @added="addTag"
        @created="addTag")
    div.small(@click="canEdit ? isEditing = !isEditing : null" :class="canEdit ? 'editable' : ''")
      div(v-if="!isEditing && selectedTags.length === 0")
        i {{canEdit ? 'No tags yet...' : ''}}
      pill-inline.wide.small.mr-1.mb-1(
        v-else
        v-for="tag in selectedTags"
        :background="tag.color"
        :is-hollow="true"
        :key="tag.id"
        :text="tag.name"
        @close="removeTag(tag.id)")
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    props: {
      value: { type: String, default: null },
      canEdit: { type: Boolean, default: true },
    },

    data() {
      return {
        isEditing: false,
      }
    },

    computed: {
      ...mapState({
        tags: (state) => state.tags,
      }),

      selectedTags() {
        return this.selectedTagIds
          .map((tagId) => this.tags.find((t) => t.id == tagId))
          .filter((t) => !!t)
          .sort((t1, t2) =>
            t1.name.toLowerCase() < t2.name.toLowerCase() ? -1 : 1
          )
      },

      selectedTagIds: {
        get() {
          return (this.value || '').split(',').filter((i) => !!i)
        },

        async set(newVal) {
          await this.updateVal(newVal.filter((i) => !!i).join(','))
        },
      },
    },

    methods: {
      async addTag({ id: tagId }) {
        const allIds = [...new Set(this.selectedTagIds.concat([tagId]))]
        await this.updateVal(allIds.join(','))
      },

      async removeTag(tagId) {
        await this.updateVal(
          this.selectedTagIds.filter((i) => i != tagId).join(','),
          false
        )
      },

      async updateVal(newVal) {
        if (newVal === this.value) return
        this.$emit('input', newVal)
        // this.isEditing = false
      },
    },

    async created() {
      await this.$store.dispatch('getTags')
    },
  }
</script>
