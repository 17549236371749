<template lang="pug">
  page-header.pb-2(:title="getInfo('title')",:sub-title="getInfo('subTitle')")
</template>

<script>
  import { getRoutes } from '@/router'

  export default {
    methods: {
      getAttr(item='title') {
        const [ matchedRoute ] = this.$route.matched
        if (!matchedRoute)
          return null

        const routeConfig = getRoutes().find(r => r.path === matchedRoute.path)
        if (routeConfig)
          return routeConfig[item]
        return null
      },

      getInfo(item='title') {
        const info = this.getAttr(item)
        if (typeof info === 'function')
          return info({ ...this.$route.params, pageTitle: this.$store.state.pageTitle })

        return info
      }
    }
  }
</script>
