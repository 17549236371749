<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-labelledby="style-guide-modal-label",aria-hidden="true")
    div.modal-dialog(role="document")
      div.modal-content
        div.modal-header
          h5.modal-title 
            div #[img(style="width:40px;margin-top:-1px;" src="../../../public/images/upgrade_icon.png", alt="content shield",:id="`upgrade-tag-${_uid}`")] Upgrade fullCircle Subscription
          button.close(type="button",data-dismiss="modal",aria-label="Close")
            span(aria-hidden="true") &times;
        div.modal-body(v-html="defaultText")
          //- Body programmatically replaced on load
          //- by ApiHelpers.handleFetchResponse
        div.modal-footer.d-flex.justify-content-space-evenly(v-html="defaultButtons")
</template>
<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import Utilities from '@risk3sixty/utilities'

  export default {
    data() {
      return {
        csBookingLink:
          'https://outlook.office365.com/owa/calendar/CustomerSuccess1@risk3sixty.com/bookings/',
      }
    },

    computed: {
      ...mapState({
        currentTeam: (state) => state.session.current_team,
      }),

      defaultText() {
        return `
        <p class="text-center">
          <b>Current Subscription: ${this.titleCase(
            this.currentTeam?.subscription_type.replace('_', ' ')
          )}</b>
        </p>
        </br>
        <p>
          From start-ups to enterprise operations, an upgraded fullCircle subscription offers all the tools you need to build,
          manage, and maintain your security and compliance program.
        </p>
        </br>
        <p>
          <b>Reach out to Sales: 
            <a href="mailto: sales@risk3sixty.com"> sales@risk3sixty.com </a>
          </b>
        </p>`
      },

      defaultButtons() {
        return `
        <a class="btn btn-primary text-white" href="mailto: sales@risk3sixty.com" target="_blank">Contact Sales</a>
        <a class="btn btn-primary text-white" href="${this.csBookingLink}" target="_blank">Book a Demo</a>
        `
      },
    },

    methods: {
      titleCase(s) {
        return Utilities.Strings.titleCase(s)
      },

      openStripePortal() {
        window.open('/api/1.0/stripe/portal')
      },

      resetDefaults() {
        // Reset the text and buttons since they could have been modified by jQuery
        $(`#upgrade-subscription-modal .modal-body`).html(this.defaultText)
        $(`#upgrade-subscription-modal .modal-footer`).html(this.defaultButtons)
      },
    },

    created() {
      window.openStripePortal = this.openStripePortal
    },

    mounted() {
      $(`#${this.$el.id}`).on('hidden.bs.modal', () => {
        this.resetDefaults()
      })

      // This is here due to this issue
      // https://stackoverflow.com/questions/10636667/bootstrap-modal-appearing-under-background
      $(`#${this.$el.id}`).appendTo('body')
    },

    beforeDestroy() {
      // See comments in mounted hook above as to why this needs to be here.
      $(`#${this.$el.id}`).remove()
    },
  }
</script>
