<template lang="pug">
  div
    div.mb-4.d-flex.justify-content-center(v-if="isEditing")
      a(:id="`assessment-control-cell-${_uid}`") Add control(s)
      assessment-control-finder-popover(
        :target="`assessment-control-cell-${_uid}`"
        @select="addControl")
    div(
      v-else
      @click="canEdit ? isEditing = !isEditing : null"
      :class="canEdit ? 'editable' : ''")
        div(v-if="selectedControls.length === 0")
          i {{canEdit ? 'No controls yet...' : ''}}
        ul.list-unstyled(v-else)
          li.mb-2.strong(v-for="control in selectedControls")
            div.d-flex.align-items-start
              div.mr-2
                div.nowrap(:id="`spreadsheet-assessment-control-${_uid}-${control.id}`")
                  | {{ control.control_number }} - {{ truncateString(control.name, 20) }}
                b-tooltip(:target="`spreadsheet-assessment-control-${_uid}-${control.id}`")
                  | #[strong {{ control.control_number }}] - {{ control.name }}
                div.small.d-flex.align-items-center(:class="statusTextClass(control.status)")
                  request-status-updater.mr-1(
                    record-type="control"
                    :passed-record="control")
                  small {{ titleCase(control.status, true) }}
              div
                a(@click.stop="removeControl(control.id)")
                  i.fal.fa-times.text-danger
</template>

<script>
  import { mapState } from 'vuex'
  import StringHelpers from '../../../../factories/StringHelpers'

  export default {
    props: {
      value: { type: String, default: null },
      canEdit: { type: Boolean, default: true },
    },

    watch: {
      async value() {
        await this.getControls()
      },
    },

    data() {
      return {
        isEditing: false,
      }
    },

    computed: {
      ...mapState({
        allControlStatuses: (state) => state.assessments.allControlStatuses,
        controlMap: (state) => state.inventories.assessmentControls,
      }),

      selectedControls() {
        return this.selectedControlIds
          .map((controlId) => this.controlMap[controlId])
          .filter((v) => !!v)
          .sort((v1, v2) =>
            v1.control_number.toLowerCase() < v2.control_number.toLowerCase()
              ? -1
              : 1
          )
      },

      selectedControlIds: {
        get() {
          return (this.value || '').split(',').filter((i) => !!i)
        },

        async set(newVal) {
          await this.updateVal(newVal.filter((i) => !!i).join(','))
        },
      },
    },

    methods: {
      titleCase: StringHelpers.titleCase,
      truncateString: StringHelpers.truncateString,

      statusTextClass(status) {
        if (this.allControlStatuses && this.allControlStatuses.length > 0) {
          const variant = (
            this.allControlStatuses.find((s) => s.value === status) || {}
          ).variant
          return `text-${variant || 'primary'}`
        }

        return 'text-primary'
      },

      async addControl(controlId) {
        const allIds = [...new Set(this.selectedControlIds.concat([controlId]))]
        await this.updateVal(allIds.join(','))
      },

      async removeControl(controlId) {
        await this.updateVal(
          this.selectedControlIds.filter((i) => i != controlId).join(','),
          false
        )
      },

      async updateVal(newVal) {
        if (newVal === this.value) return
        this.$emit('input', newVal)
        this.isEditing = false
      },

      async getControls() {
        try {
          if (this.selectedControlIds.length === 0) return
          await this.$store.dispatch(
            'getBiaAssessmentControls',
            this.selectedControlIds
          )
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    async created() {
      // get all controls that we've selected now
      await Promise.all([
        this.$store.dispatch('getAssessmentStatuses'),
        this.getControls(),
      ])
    },
  }
</script>
