<template lang="pug">
  div.card.card-small
    div.card-body.bg-light
      div.form-group
        label Extension Config Variables #[i#extension-config-info.fal.fa-info-circle]
          b-tooltip(target="extension-config-info")
            | These configurations will be available in the
            | extension code as environment variables.
        
        div.form-group(v-for="(conf, ind) in sortedConfigs")
          div.row.small-gutters
            div.col
              div.strong
                | {{ ind + 1 }}. {{ prettyName(conf.variable) }}#[sup.text-danger(v-if="conf.is_required") * (required)]
            div.col
              component(
                :is="typeCompMap[conf.value_type] || typeCompMap.string"
                size="sm"
                :value="value[conf.variable]"
                @input="updateVar(...arguments, conf.variable)")
</template>

<script>
  import Utilities from '@risk3sixty/utilities'
  import AwsKeysType from './controls/AwsKeys'
  import GcpFileType from './controls/GcpFile'
  import StringType from './controls/String'
  import SecretType from './controls/Secret'
  import { prettyName } from '../../../factories/ConnectedAppHelpers'

  export default {
    props: {
      // the mapping env_config object that should be key/value pairs of the
      // config that will be passed to the container at execution
      value: { type: Object, default: () => null },
      configs: { type: Array, default: () => null },
    },

    computed: {
      sortedConfigs() {
        return (this.configs || []).slice().sort((c1, c2) => {
          if (c1.is_required && c2.is_required)
            return c1.variable.toLowerCase() < c2.variable.toLowerCase()
              ? -1
              : 1
          if (c1.is_required) return -1
          if (c2.is_required) return 1
          return 0
        })
      },

      typeCompMap() {
        return {
          aws_api_keys: AwsKeysType,
          gcp_auth_file: GcpFileType,
          secret: SecretType,
          string: StringType,
        }
      },
    },

    methods: {
      capitalize: Utilities.Strings.capitalize,
      prettyName,

      updateVar(val, key) {
        this.$emit('input', {
          ...this.value,
          [key]: val,
        })
      },
    },

    components: {
      AwsKeysType,
      GcpFileType,
      SecretType,
      StringType,
    },
  }
</script>
