<template lang="pug">
div
  div.text-gray.mb-2
    | Filter your report from the following filters.
  div.alert.alert-success.rounded(v-if="!areThereFilters") No filters required to fill out.
  div.card.card-small.no-shadow.mt-2(v-for="(filterInfo, col) in filters")
    div.card-body.p-0
      div.form-group.m-0
        label
          div.strong {{ filterInfo.name }}#[span.text-danger(v-if="filterInfo.required") *]
          //- div.small.text-gray(v-if="filterInfo.description") {{ filterInfo.description }}
        component(
          :is="getComponent(filterInfo.type)",
          :options="filterInfo.typeVals ? [{key: 'Select Filter', value: null}, ...filterInfo.typeVals] : undefined",
          :ind="col.split('.').join('-')",
          :value="mutableFilters[col]?.value || null",
          :disabled="!userCanEdit",
          @change="updateFilter(col, {...filterInfo, value: arguments[0]})")
        div.mt-2(v-if="filterInfo.type === 'multiselect' && filterValues[col] && filterValues[col]?.value?.length > 0")
          pill-inline.mr-1.mb-1(
            v-for="(filterValue, ind) in mutableFilters[col]?.value || []",
            :key="filterValue",
            :text="filterInfo.typeVals.find(v => v.value.toString() == filterValue.toString())?.key",
            :showClose="userCanEdit",
            @close="updateFilter(col, filterInfo, ind)")
</template>

<script>
  import DateFilter from './filtertypes/Date'
  import NumberFilter from './filtertypes/Number'
  import SelectFilter from './filtertypes/Select'
  import TextFilter from './filtertypes/Text'
  import { mapState } from 'vuex'

  export default {
    props: {
      filterValues: { type: Object, default: () => ({}) },
      userCanEdit: { type: Boolean },
    },

    watch: {
      filterValues: {
        immediate: true,
        deep: true,
        handler(newValue) {
          this.mutableFilters = { ...newValue }
        },
      },
    },

    data() {
      return {
        mutableFilters: {},
      }
    },

    computed: {
      ...mapState({
        filters: (state) =>
          state.reporting.currentType?.configuration?.filters || {},
        areThereFilters: (state) =>
          Object.keys(state.reporting.currentType?.configuration?.filters || {})
            .length > 0,
      }),
    },

    methods: {
      getComponent(type) {
        switch (type) {
          case 'date':
            return 'DateFilter'
          case 'select':
            return 'SelectFilter'
          case 'multiselect':
            return 'SelectFilter'
          case 'number':
            return 'NumberFilter'
          default:
            return 'TextFilter'
        }
      },

      updateFilter(filterKey, filterObj, removingIndex = null) {
        const { value, type } = filterObj
        const currentValue = this.mutableFilters[filterKey]?.value || []
        let newValue
        if (!value && removingIndex === null) {
          newValue = null
        } else if (type === 'multiselect') {
          if (typeof removingIndex === 'number') {
            newValue = [...currentValue]
            newValue.splice(removingIndex, 1)
          } else {
            newValue = currentValue.concat(value)
          }
        } else {
          newValue = value
        }

        this.$emit('updateFilter', filterKey, newValue)
      },
    },

    components: {
      DateFilter,
      NumberFilter,
      SelectFilter,
      TextFilter,
    },
  }
</script>
