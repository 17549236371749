<template lang="pug">
  div
    div.d-flex
      strong {{text}} ({{selectedCount == 0 ? 'all' : selectedCount}})
      a.ml-2(v-if="selectedCount > 0"  @click="clearVals") clear
    div.ml-2.custom-control.custom-checkbox(v-for="(opt, ind) in options" :key="ind")
      input.custom-control-input(
        :id="`filter-checkbox-${ind}-${_uid}`",
        type="checkbox",
        :checked="(value || []).includes(opt.value)"
        @change="updateVal(opt.value)")
      label.custom-control-label(:for="`filter-checkbox-${ind}-${_uid}`")
        | {{opt.text}}
</template>

<script>
  export default {
    props: {
      value: {
        type: Array,
        default() {
          return []
        },
      },
      options: { type: Array },
      text: { type: String, default: null },
    },

    computed: {
      selectedCount() {
        return this.value.filter((val) =>
          this.options.map((opt) => opt.value).includes(val)
        ).length
      },
    },

    methods: {
      clearVals() {
        this.$emit('input', [])
        this.$emit('change', [])
      },

      updateVal(value) {
        const valueSet = new Set(this.value)
        if (valueSet.has(value)) {
          valueSet.delete(value)
        } else {
          valueSet.add(value)
        }

        this.$emit('input', Array.from(valueSet))
        this.$emit('change', Array.from(valueSet))
      },
    },
  }
</script>
