<template lang="pug">
  div
    div.r3s-entity-icon
      a.comment-button(data-toggle="modal",:data-target="`#${modalId}`")
        div.nav-link-icon__wrapper(:style="iconWrapperStyle")
          i.fal.fa-file-alt
            span.badge.badge-pill.badge-primary(v-show="files.length > 0") {{ files.length }}

    div.modal.fade(:id="modalId",tabindex="-1",role="dialog",aria-labelledby="files-modal",aria-hidden="true")
      div.modal-dialog.modal-lg(role="document")
        div.modal-content
          div.modal-header
            h5.modal-title {{ modalTitle || 'Files' }}
            button.close(type="button",data-dismiss="modal",aria-label="Close")
              span(aria-hidden="true") &times;
          div.modal-body
            div(v-if="files.length === 0")
              i No files linked yet...
            ol.px-3(v-else)
              li.mb-2.bg-light(v-for="(file, ind) in files")
                attachment-item(
                  :attachment="file",
                  :index="ind",
                  @delete="deleteFile")
            
            div.mt-2
              loader(v-show="isAttachmentUploading")
              file-uploader.mt-3(
                v-show="!isAttachmentUploading",
                size="sm",
                defaultMessage="Click or drop files here to upload.",
                @added="uploadedAttachment",
                @processing="attachmentUploading")
          div.modal-footer
            button.btn.btn-secondary(type="button",data-dismiss="modal") Close
            - //button.btn.btn-primary(@click="confirm") Confirm
</template>

<script>
  import $ from 'jquery'
  import ApiFiles from '../../factories/ApiFiles'

  export default {
    props: {
      table: { type: String, required: true },
      entityId: { type: [Number, String], required: true },
      modalTitle: { type: String, default: null },
      wrapperBorder: { type: Boolean, default: false },
    },

    data() {
      return {
        isAttachmentUploading: false,
        files: [],
      }
    },

    watch: {
      entityId: {
        handler() {
          this.getFiles()
        },
        deep: true,
        immediate: true,
      },
    },

    computed: {
      modalId() {
        return `files-modal-${this._uid}`
      },

      iconWrapperStyle() {
        return this.wrapperBorder
          ? {}
          : {
              borderWidth: '0px',
              borderRadius: 'inherit',
              width: 'auto',
              height: 'auto',
            }
      },
    },

    methods: {
      resetUpdateModal() {
        // This is here due to this issue
        // https://stackoverflow.com/questions/10636667/bootstrap-modal-appearing-under-background
        $(`#${this.modalId}`).appendTo('body')
      },

      attachmentUploading() {
        this.isAttachmentUploading = true
      },

      async uploadedAttachment([, file]) {
        try {
          this.isAttachmentUploading = false
          const { filename } = file

          // TODO: do files need to be private scoped here?
          const { files } = await ApiFiles.saveFile(
            { name: filename },
            'private'
          )
          await Promise.all(
            files.map(async (file) => {
              await this.addUpdateFile(file.id)
            })
          )

          this.$emit('uploaded', files)
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async addUpdateFile(id, del = false) {
        try {
          await ApiFiles.createOrUpdateAndLinkToEntity(
            {
              id,
              table: this.table,
              entityId: this.entityId,
            },
            del
          )
          await this.getFiles()
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async deleteFile(id) {
        await this.addUpdateFile(id, true)
      },

      async getFiles() {
        try {
          if (!(this.table && this.entityId)) return
          const { files } = await ApiFiles.getByEntity(
            this.table,
            this.entityId
          )
          this.files = files
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    mounted() {
      this.resetUpdateModal()
    },

    beforeDestroy() {
      $(`#${this.modalId}`).remove()
    },
  }
</script>
