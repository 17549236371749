<template lang="pug">
bar.w-100(v-if="!!chartData", :options="chartOptions" :data="chartData" :height="height" :width="width")
</template>

<script>
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
  } from 'chart.js'
  import { Bar } from 'vue-chartjs'
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  )
  export default {
    components: {
      Bar,
    },
    props: {
      options: { type: Object, default: null },
      chartData: { type: Object, default: () => ({}) },
      height: { type: Number, default: null },
      width: { type: Number, default: null },
    },
    computed: {
      chartOptions() {
        return {
          ...this.options,
          indexAxis: 'y',
        }
      },
    },
  }
</script>
