<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-labelledby="create-extension-label",aria-hidden="true")
    //- div.modal-dialog(role="document")
    div.modal-dialog.modal-lg(role="document")
      div.modal-content
        form(@submit="updateExtension")
          div.modal-header
            h5.modal-title Create/Update Extension
            button.close(
              type="button"
              data-dismiss="modal"
              aria-label="Close")
                span(aria-hidden="true") &times;
          div.modal-body
            loader(v-if="isLoadingLocal")
            template(v-else)
              div.card.no-shadow.border
                div.card-header.header-tabs
                  ul.nav.nav-tabs.card-header-tabs
                    li.nav-item
                      a.nav-link(
                        :class="tabNavClass('extension')"
                        @click="activeTab = 'extension'") Extension
                    li.nav-item
                      a.nav-link(
                        :class="tabNavClass('mappings')"
                        @click="activeTab = 'mappings'") Mappings
                div.card-body(v-if="activeTab === 'extension'")
                  template(v-if="updatingExistingExtension")
                    div.form-group
                      label Extension Name
                      div
                        strong {{ currentExtension.name }}
                    div.form-group
                      label Description
                      div
                        strong {{ currentExtension.description || '...' }}
                  template(v-else)
                    div.form-group
                      label Extension Name#[sup.text-danger *]
                      input.form-control(type="text",v-model="currentExtension.name")
                    div.form-group
                      label Description
                      textarea.form-control(v-model="currentExtension.description")

                    div.form-group
                      label Extension Vendor
                      v-select(
                        v-model="currentExtension.vendor_id"
                        :options="vendors"
                        :reduce="v => v.id"
                        label="name")
                    
                    div.card.card-small.border.no-shadow.my-2
                      div.card-body.bg-light
                        div.form-group.m-0
                          label Extension Tarball (.tar, .tar.gz, .tgz)
                          div.d-flex.align-items-center.justify-content-center(v-if="currentExtension.tarball_file")
                            a.strong.overflow-ellipses(@click="download")
                              | {{ currentExtension.tarball_file }}
                            a.ml-1(@click="currentExtension.tarball_file = null")
                              i.fal.fa-times.text-danger
                          file-uploader(
                            v-else
                            acceptedFileTypes="application/gzip, application/tar, application/tar+gzip, application/x-compressed"
                            :is-extension="true"
                            :allowMulti="false"
                            size="sm"
                            :maxFileSize="10"
                            @added="setTarballFile")

                    template(v-if="isR3s")
                      label.mt-3 Internal risk3sixty Options
                      div.form-group
                        div.custom-control.custom-toggle.custom-toggle-sm
                          input#is-template-cb.custom-control-input(
                            type="checkbox"
                            v-model="currentExtension.is_template")
                          label.custom-control-label(for="is-template-cb")
                            | Is this extension a template?
                      div.form-group
                        div.custom-control.custom-toggle.custom-toggle-sm(v-if="currentExtension.is_template")
                          input#is-published-cb.custom-control-input(
                            type="checkbox"
                            v-model="currentExtension.is_published")
                          label.custom-control-label(for="is-published-cb")
                            | Is it published?

                    div.card.card-small.border.no-shadow.mt-4
                      div.card-body.bg-light
                        div.form-group
                          label
                            | Extension Configurations #[i#extension-config-info.fal.fa-info-circle]
                          b-tooltip(target="#extension-config-info")
                            | These configurations will be available in the extension as
                            | environment variables. Users will configure values for these
                            | when they map the extension in various places in fullCircle.
                          div.d-flex.justify-content-center.mb-2
                            add-remove-btn-group(
                              @add="currentExtension.configs.push(newConfig())",
                              @remove="currentExtension.configs.splice(currentExtension.configs.length - 1, 1)")
                          
                          i(v-if="currentExtension.configs.length === 0")
                            | No configurations yet...
                          div.form-group(v-else v-for="(conf, ind) in currentExtension.configs")
                            div.row.small-gutters
                              div.col-4
                                label {{ ind + 1 }}. Variable
                                input.form-control.form-control-sm(v-model="conf.variable")
                              div.col-4
                                label Type
                                select.col.form-control.form-control-sm(v-model="conf.value_type")
                                  option(v-for="val in variableTypes" :value="val.value") {{ val.text }}
                              div.col-2
                                label required?
                                div.custom-control.custom-toggle.custom-toggle-sm
                                  input.custom-control-input(
                                    :id="`is-config-required-${ind}`"
                                    type="checkbox"
                                    v-model="conf.is_required")
                                  label.custom-control-label(:for="`is-config-required-${ind}`")
                              div.col-1
                                label.nowrap(
                                  :id="`runtime-info-${ind}`") runtime? #[i.fal.fa-info-circle]
                                b-tooltip(:target="`runtime-info-${ind}`")
                                  | Can a user who executes this extension override the default
                                  | value of this configuration at execution time?
                                div.custom-control.custom-toggle.custom-toggle-sm
                                  input.custom-control-input(
                                    :id="`is-config-runtime-${ind}`"
                                    type="checkbox"
                                    v-model="conf.is_runtime_enabled")
                                  label.custom-control-label(:for="`is-config-runtime-${ind}`")
                              div.col-1.d-flex.align-items-center.justify-content-end
                                a(@click="currentExtension.configs.splice(ind, 1)")
                                  i.fal.fa-times.text-danger
                div.card-body(v-if="activeTab === 'mappings'")
                  extension-list(
                    :passed-mappings="uniqueMappings"
                    :is-in-modal="true")
          div.modal-footer
            button.btn.btn-secondary(type="button",data-dismiss="modal") Cancel
            button.btn.btn-primary(type="submit") Save
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import ApiExtensions from '../../../factories/ApiExtensions'
  import DomHelpers from '../../../factories/DomHelpers'

  export default {
    props: {
      extensionId: { type: [Number, String], default: null },
    },

    data() {
      return {
        activeTab: 'extension',
        currentExtension: this.resetExtension(),
        isLoadingLocal: false,
        variableTypes: [
          { value: 'aws_role_arn', text: 'AWS Role ARN' },
          { value: 'aws_api_keys', text: 'AWS Credentials' },
          { value: 'gcp_auth_file', text: 'GCP Auth File' },
          { value: 'secret', text: 'Password/Secret' },
          { value: 'string', text: 'String (default)' },
        ],
      }
    },

    computed: {
      ...mapState({
        currentTeamId: (state) => state.session.current_team.id,
        hasVendorManagement: (state) =>
          state.isModuleAuthorized('vendor_management'),
        // isInternalUser: (_, getters) => getters.isInternalUser,
        isR3s: (_, getters) => getters.isR3s,
        vendors: (state) => state.vendormanagement.vendors,
      }),

      updatingExistingExtension() {
        return (
          this.currentExtension &&
          this.currentExtension.id &&
          this.currentExtension.team_id !== this.currentTeamId
        )
      },

      uniqueMappings() {
        let mapIds = {}
        return this.currentExtension.mappings.reduce((ary, mapping) => {
          if (mapIds[mapping.id]) return ary
          ary.push(mapping)
          mapIds[mapping.id] = true
          return ary
        }, [])
      },
    },

    methods: {
      resetExtension() {
        return {
          vendor_id: null,
          name: null,
          description: null,
          repo_url: null,
          tarball_file: null,
          is_template: false,
          is_published: false,

          configs: [],
        }
      },

      newConfig() {
        return {
          variable: null,
          value_type: 'string',
          is_required: false,
        }
      },

      tabNavClass(type = 'extension') {
        let isActive = type === this.activeTab
        return isActive ? 'active' : ''
      },

      setTarballFile([, { filename }]) {
        this.currentExtension.tarball_file = filename
      },

      download() {
        DomHelpers.downloadUri(
          `/file/extension/s3/${this.currentExtension.tarball_file}`,
          decodeURIComponent(this.currentExtension.tarball_file)
        )
      },

      async getExtension() {
        try {
          this.isLoadingLocal = true
          const { extension, configs } = await ApiExtensions.getExtension(
            this.extensionId
          )
          this.currentExtension = {
            ...extension,
            configs,
          }
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.isLoadingLocal = false
        }
      },

      async updateExtension(evt) {
        try {
          evt.preventDefault()
          if (!this.currentExtension.name) {
            return window.toastr.error(
              `Please at least add a name for the extension.`
            )
          }

          const { id } = await ApiExtensions.saveExtension(
            this.currentExtension
          )
          this.$emit('saved', { ...this.currentExtension, id })
          $(`#${this.$el.id}`).modal('toggle')
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    async mounted() {
      $(`#${this.$el.id}`).on('show.bs.modal', async () => {
        this.activeTab = 'extension'
        if (this.extensionId) await this.getExtension()
        else this.currentExtension = this.resetExtension()

        await Promise.all([
          this.$store.dispatch('getExtensionTypes'),
          (async () => {
            if (this.hasVendorManagement)
              await this.$store.dispatch('getVendors')
          })(),
        ])
      })
    },
  }
</script>
