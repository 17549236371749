<template lang="pug">
  div.row
    div.col-12
      div.card.card-small
        div.card-body
          router-link.d-block(to="settings/organization")
            h6.m-0.d-flex.align-items-center
              div Org Settings
              div.ml-auto
                i.fal.fa-cog
            div.py-3.d-flex.justify-content-center
              div.d-flex.align-items-center.justify-content-center.p-2(style="border-radius: 50%; border: 5px solid rgba(225, 229, 235, 0.51); width: 150px; height: 150px")
                img.img-fluid(
                  v-if="teamLogo",
                  style="max-width: 85%",
                  :src="`/file/s3/${teamLogo}`")
                div(v-else) {{ teamName }}
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    computed: mapState({
      teamName: state => state.session.current_team.name,
      teamLogo: (_, getters) => getters.currentTeamLogo
    })
  }
</script>