<template lang="pug">
  div.card.card-small.mb-4
    div.card-body(v-if="isLoadingLocal")
      loader
    div(v-else)
      div.card-header.border-bottom.d-flex.align-items-center
        h5.m-0 Credentials
        div.ml-auto
          button.btn.btn-primary.btn-vsm(
            @click="editingCredId = null",
            data-toggle="modal",
            data-target="#create-update-cred-modal") Create Credential
      ul.list-group.list-group-flush
        li.list-group-item(v-if="credentials.length === 0")
          i No credentials added yet...
        li.list-group-item(v-else,v-for="(cred, index) in credentials")
          div.d-flex.align-items-center
            div {{ index + 1 }}. {{ cred.username }}
            div.ml-auto.d-flex.align-items-center
              comments.mr-2(
                table="network_credentials",
                :entity-id="cred.id",
                :modal-title="`${cred.username} Credential Comments`")
              files.mr-4(
                table="network_credentials",
                :entity-id="cred.id",
                :modal-title="`${cred.username} Credential Linked Files`")
              a(@click="editingCredId = cred.id",
                data-toggle="modal",
                data-target="#create-update-cred-modal")
                i.fal.fa-edit.clickable
      branded-card-footer.border-top

    credentials-update-modal#create-update-cred-modal(:cred-id="editingCredId",@update="getCreds")
</template>

<script>
  import ApiPentesting from '../../factories/ApiPentesting'

  export default {
    data() {
      return {
        isLoadingLocal: true,
        credentials: [],

        editingCredId: null,
      }
    },

    methods: {
      async getCreds() {
        try {
          const { credentials } = await ApiPentesting.getCredentials()
          this.credentials = credentials
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.isLoadingLocal = false
        }
      },
    },

    async created() {
      await this.getCreds()
    },
  }
</script>
