<template lang="pug">
div.input-group.color-picker(ref="colorpicker")
  div.d-flex.fit-content-h
    div.input-group-prepend
      div.input-group-text.p-2(:style="{ borderColor: colorValue }")
        div(
          style="height: 15px; width: 15px; border-radius: 50%",
          :style="{ backgroundColor: colorValue }"
        )
    input.form-control(
      :style="{ borderColor: colorValue }",
      :id="`color-picker-input-${_uid}`",
      type="text",
      v-model="colorValue",
      @focus="showPicker()",
      @input="updateFromInput"
    )
  
  span.input-group-addon.color-picker-container
    span.current-color(
      :style="'background-color: ' + colorValue",
      @click="togglePicker()"
    )
    chrome-picker(
      v-if="displayPicker",
      style="position: fixed; z-index: 1;"
      :value="colors",
      @input="updateFromPicker"
    )
</template>

<script>
  import Utilities from '@risk3sixty/utilities'
  import { Chrome } from 'vue-color'

  const Colors = Utilities.Colors

  export default {
    props: {
      value: { type: String, default: '#1c567d' },
    },

    watch: {
      value(newColor) {
        this.setColor(newColor)
      },

      colorValue(val) {
        if (val) {
          this.updateColors(val)
          this.$emit('input', val)
        }
      },
    },

    data() {
      return {
        colors: {
          hex: '#1c567d',
        },

        colorValue: '',
        displayPicker: false,
      }
    },

    methods: {
      setColor(color) {
        this.updateColors(color)
        this.colorValue = color
      },

      updateColors(color) {
        if (color.slice(0, 1) == '#') {
          this.colors = { hex: color }
        } else if (color.slice(0, 4) == 'rgba') {
          const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',')
          const hex =
            '#' +
            (
              (1 << 24) +
              (parseInt(rgba[0]) << 16) +
              (parseInt(rgba[1]) << 8) +
              parseInt(rgba[2])
            )
              .toString(16)
              .slice(1)

          this.colors = {
            hex: hex,
            a: rgba[3],
          }
        }
      },

      showPicker() {
        document.addEventListener('click', this.documentClick)
        this.displayPicker = true
      },

      hidePicker() {
        document.removeEventListener('click', this.documentClick)
        this.displayPicker = false
      },

      togglePicker() {
        this.displayPicker ? this.hidePicker() : this.showPicker()
      },

      updateFromInput() {
        this.updateColors(this.colorValue)
      },

      updateFromPicker(color) {
        this.colors = color
        if (color.rgba.a == 1) {
          this.colorValue = color.hex
        } else {
          this.colorValue = `rgba(${color.rgba.r}, ${color.rgba.g}, ${color.rgba.b}, ${color.rgba.a})`
        }

        if (!this.colorValue) return

        let isDarkBg = true
        if (Colors.getLumaNormalized(this.colorValue) > 0.5) isDarkBg = false

        this.$emit('isDark', isDarkBg)
      },

      documentClick(e) {
        const el = this.$refs.colorpicker
        const target = e.target

        if (el !== target && !el.contains(target)) {
          this.hidePicker()
        }
      },
    },

    mounted() {
      this.setColor(this.value || '#1c567d')
    },

    components: {
      'chrome-picker': Chrome,
    },
  }
</script>
