<template lang="pug">
  div.form-group
    div.small(v-if="!event.comments || event.comments.length === 0")
      i No comments added to event yet...
    div.mt-1(v-else,style="max-height: 60vh; overflow-y: auto;")
      div.mb-4(v-if="thisInstanceComments.length > 0")
        h6.m-0 This instance's comments:
        div.card.card-small.mb-1.no-shadow(v-for="comment in thisInstanceComments")
          comment-card(
            :comment="comment",
            @update="updateComment",
            @delete="deleteComment")
      div.mb-4(v-if="otherInstanceComments.length > 0")
        div(v-for="(comments, instance) in groupedOtherInstanceComments")
          h6.m-0 {{ getFormattedDate(instance, 'MMMM Do, YYYY', true) }} instance's comments:
          div.card.card-small.my-1.no-shadow(v-for="comment in comments")
            comment-card(
              :comment="comment",
              @update="updateComment",
              @delete="deleteComment")
    //- div.card.card-small.mb-3.no-shadow.border
    //- h6.m-0 New Comment
    div.form-group.mb-2
      rich-text-editor.border(
        :options="{ theme: 'bubble' }",
        v-model="newComment",
        @mentionReplaced="addMention")
    div.form-group.m-0.text-center
      button.btn.btn-sm.btn-primary(type="button",@click="updateComment()") Add Comment
</template>

<script>
  import { mapState } from 'vuex'
  import Utilities from '@risk3sixty/utilities'
  import TimeHelpers from '../../factories/TimeHelpers'

  const moment = Utilities.Dates.moment
  const arrayGroupBy = Utilities.Arrays.arrayGroupBy

  export default {
    props: {
      event: { type: Object },
    },

    computed: {
      ...mapState({
        currentUser: (state) => state.auth.user,
      }),

      thisInstanceComments() {
        return this.event.comments.filter((c) => {
          const fileDate = moment(c.recurring_event_start_date).format(
            'YYYY-MM-DD'
          )
          const instDate = moment(this.event.start).format('YYYY-MM-DD')
          return fileDate === instDate
        })
      },

      otherInstanceComments() {
        return this.event.comments.filter((c) => {
          const fileDate = moment(c.recurring_event_start_date).format(
            'YYYY-MM-DD'
          )
          const instDate = moment(this.event.start).format('YYYY-MM-DD')
          return fileDate !== instDate
        })
      },

      groupedOtherInstanceComments() {
        return arrayGroupBy(
          this.otherInstanceComments,
          (f) => f.recurring_event_start_date
        )
      },
    },

    data() {
      return {
        isEditingComment: false,
        newComment: null,
        commentMentions: [],
      }
    },

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,

      addMention(item) {
        this.commentMentions.push({ key: item.value, email: item.id })
      },

      async updateComment(commentObj, mentionsAry = this.commentMentions) {
        try {
          if (!commentObj && this.newComment) {
            commentObj = {
              user_id: this.currentUser.id,
              comment: this.newComment,
            }
          }

          await this.$store.dispatch('createUpdateCalendarEventComment', {
            eventId: this.event.id,
            date: this.event.start,
            mentions: mentionsAry,
            comment: commentObj,
          })

          this.newComment = null
          this.isEditingComment = false
          this.commentMentions = []
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async deleteComment(comment) {
        try {
          await this.$store.dispatch('deleteCalendarEventComment', {
            eventId: this.event.id,
            commentId: comment.id,
          })
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },
  }
</script>
