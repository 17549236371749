<template lang="pug">
  div
    div.form-group
      label.d-flex.align-item-center
        div.d-flex.align-items-center.mr-2 Field Variables
        add-remove-btn-group(@add="addNewFieldVar" :show-remove="false")
      div.row.small-gutters.mb-1(v-for="(fv, ind) in fieldVariables")
        div.col-3
          input.form-control.form-control-sm(
            :disabled="true"
            :value="fv.var")
        div.col-8
          select.form-control.form-control-sm(v-model="fv.field",@change="setField(ind, fv.field)")
            option(v-for="field in fields",:value="field.id") {{ field.key }}
        div.col-1.d-flex.align-items-center.justify-content-end
          a(@click="removeFieldVar(ind)")
            i.fal.fa-times.text-danger
    div.form-group
      label Expression Builder
      expression-builder(v-model="expressionLatex")
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    props: {
      // fields: { type: Array, required: true },
      value: { type: String, default: null },
    },

    data() {
      return {
        vars: ['x', 'y', 'z', 'a', 'b', 'c', 'd', 'e', 'f', 'g'],
      }
    },

    computed: {
      ...mapState({
        fields: (state) =>
          state.inventories.currentInventory.fields.filter((f) =>
            ['enum', 'number'].includes(f.type)
          ),
      }),

      parsedValue() {
        return JSON.parse(this.value || 'null') || {}
      },

      expressionLatex: {
        get() {
          return this.parsedValue.expressionLatex
        },

        set(exp) {
          this.updateValue({ expressionLatex: exp })
        },
      },

      fieldVariables: {
        get() {
          return this.parsedValue.fieldVariables || []
        },

        set(newVars) {
          this.updateValue({
            fieldVariables: newVars,
            expressionLatex: this.expressionLatex || '2x+3',
          })
        },
      },
    },

    methods: {
      addNewFieldVar() {
        if (!this.fields || this.fields.length === 0)
          return window.toastr.error(
            `No fields to add as variables in the expression.`
          )

        this.fieldVariables = (this.fieldVariables || []).concat([
          {
            var: this.vars[this.fieldVariables.length],
            field: this.fields[0].id,
          },
        ])
      },

      removeFieldVar(ind) {
        this.fieldVariables.splice(ind, 1)
        this.fieldVariables = this.fieldVariables.slice(0)
      },

      setField(ind, id) {
        this.fieldVariables[ind].field = id.toString()
        this.fieldVariables = this.fieldVariables.slice(0)
      },

      updateValue(keyValPairs) {
        this.$emit(
          'input',
          JSON.stringify({
            ...this.parsedValue,
            ...keyValPairs,
          })
        )
      },
    },
  }
</script>
