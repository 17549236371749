<template lang="pug">
  b-popover(:target="target",:ref="target",boundary="viewport",@shown="init")
    template(slot="title")
      div.d-flex.align-items-center
        h6.m-0 {{ fieldId ? 'Edit' : 'Create New' }} Column
        div.ml-auto
          i.ml-2.fal.fa-times.clickable(@click="$refs[target].$emit('close')")
    div
      loader(v-if="isLoadingLocal")
      div(v-else)
        div.form-group
          label Column Name
          input.form-control(
            v-model="field.key"
            placeholder="Column Name")
        div.form-group
          label Column Description
          textarea.form-control(
            v-model="field.description"
            placeholder="Column Description")
        div.form-group
          label Column Type
          select.form-control(v-model="field.type" @change="getTeamEnums()")
            option(
              v-for="type in fieldTypes"
              v-if="typeof type.condition === 'undefined' || type.condition"
              :value="type.value") {{ type.text }}
        div.form-group(v-if="field.type === 'enum'")
          label
            | Select Dropdown List
            i.ml-1.clickable.text-success.fal.fa-plus-circle(
              data-toggle="modal"
              :data-target="`#create-enum-modal-${_uid}`")
          select.form-control(v-model="field.enum_options_id",placeholder="Select from possible values...")
            option(v-for="en in teamEnums",:value="en.id") {{ en.name }}
        extra-expression-config(
          v-else-if="field.type === 'expression'"
          v-model="field.mod1")
        div.form-group.text-center
          button.btn.btn-success(@click="saveField") Save Column

    create-edit-enum-modal(
      :id="`create-enum-modal-${_uid}`"
      :enum-id="field.enum_options_id",
      @saved="getTeamEnums(true)")
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import ExtraExpressionConfig from './ExtraExpressionConfig'
  import ApiSpreadsheet from '../../../factories/ApiSpreadsheet'

  export default {
    props: {
      target: { type: String, required: true },
      spreadsheetId: { type: [Number, String], required: true },
      fieldId: { type: [Number, String], default: null },
    },

    watch: {
      async fieldId() {
        await this.getField()
      },
    },

    data() {
      return {
        isLoadingLocal: true,
        field: {},
      }
    },

    computed: {
      ...mapState({
        teamEnums: (state) => state.team.enums,
      }),

      fieldTypes() {
        return [
          {
            value: 'assessment_control',
            text: 'Assessment Control',
            condition: this.$store.state.isModuleAuthorized('assessments'),
          },
          { value: 'date', text: 'Date' },
          { value: 'expression', text: 'Expression' },
          { value: 'number', text: 'Number' },
          { value: 'enum', text: 'List' },
          {
            value: 'risk',
            text: 'Risk',
            condition: this.$store.state.isModuleAuthorized('risk_register'),
          },
          { value: 'tag', text: 'Tag' },
          { value: 'text', text: 'Text' },
          { value: 'user', text: 'User' },
          {
            value: 'vendor',
            text: 'Vendor',
            condition: this.$store.state.isModuleAuthorized(
              'vendor_management'
            ),
          },
        ]
      },
    },

    methods: {
      resetModal() {
        // This is here due to this issue
        // https://stackoverflow.com/questions/10636667/bootstrap-modal-appearing-under-background
        $(`#create-enum-modal-${this._uid}`).appendTo('body')
      },

      async getTeamEnums(reset = false) {
        await this.$store.dispatch('getTeamEnums', reset)
        this.resetModal()
      },

      async getField() {
        try {
          if (!this.fieldId) return (this.field = this.defaultField())

          const { field } = await ApiSpreadsheet.getField(this.fieldId)
          this.field = field
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.isLoadingLocal = false
        }
      },

      async saveField() {
        try {
          if (!(this.field && this.field.key))
            return window.toastr.error(
              `Make sure you at least have a column name to save.`
            )

          if (this.field.type === 'enum' && !this.field.enum_options_id)
            return window.toastr.error(
              `You selected an options type without selecting from a list of options.`
            )

          await ApiSpreadsheet.saveField({
            ...this.field,
            spreadsheet_id: this.spreadsheetId,
          })
          window.toastr.success(`Successfully saved column!`)
          this.$emit('saved', this.field)
          this.$refs[this.target].$emit('close')
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      defaultField() {
        return {
          type: 'text',
          key: null,
          enum_options_id: null,
          ordering: null,
          is_deleted: false,
        }
      },

      async init() {
        this.resetModal()
        await Promise.all([this.getField(), this.getTeamEnums(true)])
      },
    },

    beforeDestroy() {
      // See comments in this.resetModal as to why this needs to be here.
      $(`#create-enum-modal-${this._uid}`).remove()
    },

    components: {
      ExtraExpressionConfig,
    },
  }
</script>
