<template lang="pug">
  b-popover(:target="target",boundary="viewport",:show.sync="isShowing")
    template(slot="title")
      div.d-flex.align-items-center
        h6.mb-0.mr-4 Find Assessment Controls
        div.ml-auto
          i.fal.fa-times.clickable(@click="isShowing = false")
    div
      div.mb-2
        div
          small PROJECT
        div.d-flex.align-items-center(v-if="projectRecord")
          a(:href="`/assessments/testing/${projectRecord.id}`",target="_blank",rel="noopener noreferrer")
            strong {{ projectRecord.name }}
            span.ml-1(v-if="projectRecord.audit_period_end")
              | ({{ getFormattedDate(projectRecord.audit_period_end, 'MMM YYYY', true) }})
          i.ml-1.clickable.fal.fa-times.text-danger(@click="resetRecords")
        div(v-else)
          typeahead-input(
            placeholder="Search for project (1 chars min)",
            src="/api/1.0/assessments/projects/search",
            :params="{ keysFromResponse: 'projects', showPropFunction: p => `${p.name} (${p.status})`, minChars: 1 }",
            @onHit="selectProject")
      div(v-if="projectRecord")
        div
          small CONTROL
        div(v-if="controlRecord")
          div.d-flex.align-items-center
            a(:href="`/assessments/testing/${projectRecord.id}/${controlRecord.id}`",target="_blank",rel="noopener noreferrer")
              span {{ controlRecord.control_number }} - #[strong {{ controlRecord.name }}]
            i.ml-1.clickable.fal.fa-times.text-danger(@click="controlRecord = null")
        div(v-else)
          typeahead-input(
            placeholder="Search for control",
            :src="`/api/1.0/assessments/controls/search?id=${projectRecord.id}`",
            :params="{ keysFromResponse: 'controls', showPropFunction: r => `${r.control_number} - ${r.name}`, minChars: 1 }",
            @onHit="selectControl")
</template>

<script>
  // import ApiAssessments from '../../factories/ApiAssessments'
  import TimeHelpers from '../../factories/TimeHelpers'

  export default {
    props: {
      target: { type: String, required: true },
    },

    data() {
      return {
        isShowing: false,

        projectRecord: null,
        controlRecord: null,
      }
    },

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,

      selectProject(project) {
        this.projectRecord = project
      },

      async selectControl(control) {
        this.controlRecord = control
        this.$emit('select', control.id)
      },

      async resetRecords() {
        this.projectRecord = null
        this.controlRecord = null
      },
    },
  }
</script>
