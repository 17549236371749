<template lang="pug">
div.input-group(style="width: unset;")
  div.search-input.d-flex.align-items-center(:class="additionalClasses")
    input.form-control(type="text"
      :id="`searchbox-${_uid}`"
      :placeholder="placeholder || 'Search...'"
      :disabled="disabled"
      v-model="searchTerm"
      @keyup.enter="$emit('search', localSearchTerm)"
      @keyup.escape="searchTerm = ''")
    template(v-if="!clickToSearch")
      i.input-icons.magnifying-glass.fa.fa-search(v-show="!searchTerm")
      i.input-icons.ml-1.fa.fa-times.clickable(v-show="searchTerm" @click="searchTerm = '', $emit('search', '')")
  div.input-group-append
    button.btn.btn-primary(v-if="clickToSearch" :disabled="disabled" @click="$emit('search', localSearchTerm)") #[i.fa.fa-search]
</template>

<script>
  export default {
    props: {
      value: { type: String },
      placeholder: { type: String, default: null },
      disabled: { type: Boolean, default: false },
      clickToSearch: { type: Boolean, default: false },
      additionalClasses: { type: String, default: '' },
    },

    data() {
      return {
        localSearchTerm: this.value,
      }
    },

    computed: {
      searchTerm: {
        get() {
          return this.localSearchTerm
        },
        set(val) {
          this.localSearchTerm = val
          this.$emit('input', val)
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  .search-input {
    position: relative;
    width: unset;
    .search-input__input {
      width: 100%;
      font-size: 14px;
      color: #2c3e50;
      line-height: 1.42857143;
      box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.4);
      -webkit-transition: border-color ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
      font-weight: 300;
      padding: 12px 26px;
      border: none;
      border-radius: 22px;
      letter-spacing: 1px;
      box-sizing: border-box;
    }

    .search-input__input:focus {
      border-color: #4fc08d;
      outline: 0;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #4fc08d;
    }

    .input-icons {
      float: right;
      position: relative;
      right: 25px;
      opacity: 0.4;
    }

    .active {
      background-color: #012160;
    }
  }
</style>
