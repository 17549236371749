<template lang="pug">
div
  loader(v-if="isLoadingLocal")

  //- No access to object
  div.alert.alert-danger(v-else-if="!hasAccessToObject && !isLoadingLocal")
    | You do not have access to preview this object.

  //- Image preview
  div.d-flex.justify-content-center(v-else-if="isImage(fileName)")
    img-viewer(:src="getDownloadLink")
  
  //- PDF doc type
  div(v-else-if="!pdfParseError && getObjectExtension === 'pdf'")
    div.card.no-shadow.border
      div.card-body.p-2
        pdf-viewer(
          :src="getDownloadLink",
          @error="setPdfParseError")
  
  //- Other doc types we attempt to convert to HTML
  div(v-else-if="objectConvertedToHtmlCache")
    div.card.no-shadow.border
      div.card-body.p-2.m-6
        div(v-html="objectConvertedToHtmlCache")
  
  //- Ascii doc type
  div(v-else-if="objectAsciiCache")
    div.d-flex.align-items-center
      div.m-0
        strong Object Preview
      div.ml-2
        button.btn.btn-vsm.btn-primary(
          @click="wrapPreview = !wrapPreview") {{ wrapPreview ? 'Unwrap' : 'Wrap' }} Text
    pre.mt-2.mb-0(v-highlightjs)
      code(
        :class="objectAsciiCache.length > 4e4 ? 'bash' : ''",
        :style="getPreviewStyle") {{ objectAsciiCache }}
  
  //- Unsupported doc type
  div(v-else)
    div.alert.alert-info.mb-0.text-center
      p.mb-2 This file cannot be displayed in the browser.
      p #[button.btn.btn-primary.btn-sm(@click="downloadObject()") Download Object] to open it and review its contents.
</template>

<script>
  import mammoth from 'mammoth'
  import ApiFiles from '../../factories/ApiFiles'
  import DomHelpers from '../../factories/DomHelpers'
  import { isImage, checkFileAscii } from '../../factories/Utilities'

  export default {
    props: {
      fileId: { type: [Number, String], required: true },
      fileName: { type: String, required: true },
    },

    watch: {
      fileId: {
        async handler() {
          this.hasAccessToObject = false
          await this.checkFilePermissions()
        },
        immediate: true,
      },
      fileName: {
        async handler() {
          this.objectAsciiCache = false
          this.objectConvertedToHtmlCache = false
          await this.getFileBlob()
        },
        immediate: true,
      },
    },

    data() {
      return {
        isLoadingLocal: true,
        hasAccessToObject: false,
        objectAsciiCache: false,
        objectConvertedToHtmlCache: false,
        wrapPreview: false,
        pdfParseError: null,
        fileBlob: null,
      }
    },

    computed: {
      getPreviewStyle() {
        return this.wrapPreview ? { whiteSpace: 'pre-wrap' } : ''
      },

      getObjectExtension() {
        const pathSplit = (this.fileName || '').split('.')
        return (pathSplit[pathSplit.length - 1] || '').toLowerCase()
      },

      getDownloadLink() {
        return `/file/s3/${this.fileName}`
      },
    },

    methods: {
      isImage,

      downloadObject() {
        const splitFile = this.fileName.split('/')
        DomHelpers.downloadUri(
          this.getDownloadLink,
          splitFile[splitFile.length - 1]
        )
      },

      setPdfParseError(err) {
        this.pdfParseError = err
      },

      async checkAndCacheIsFileAscii() {
        try {
          if (this.objectAsciiCache) return
          return (this.objectAsciiCache = await checkFileAscii(this.fileBlob))
        } catch (err) {
          this.objectAsciiCache = false
        }
      },

      async checkAndCacheIsFileDocx() {
        try {
          if (this.objectConvertedToHtmlCache) return
          const fileInfo = await ApiFiles.getFile(this.fileId)
          const file = await ApiFiles.downloadFileBlob(fileInfo.file.name)
          const convertedFile = await mammoth.convertToHtml({
            arrayBuffer: file,
          })
          return (this.objectConvertedToHtmlCache = convertedFile.value)
        } catch (err) {
          this.objectConvertedToHtmlCache = false
        }
      },

      async checkFilePermissions() {
        try {
          // will send a 404 if file doesn't exist, telling us no access
          await ApiFiles.getFile(this.fileId)
          this.hasAccessToObject = true
        } catch (err) {
          this.hasAccessToObject = false
        }
      },

      async getFileBlob() {
        try {
          this.fileBlob = await ApiFiles.downloadFileBlob(this.fileName)

          if (
            this.getObjectExtension == 'doc' ||
            this.getObjectExtension == 'docx'
            // this.getObjectExtension == 'xlsx'
          ) {
            await this.checkAndCacheIsFileDocx()
          } else {
            await this.checkAndCacheIsFileAscii()
          }
        } finally {
          this.isLoadingLocal = false
        }
      },
    },
  }
</script>
<style scoped>
  .m-6 {
    margin: 4rem !important;
  }
</style>
