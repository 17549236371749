<template lang="pug">
  div.unassume-modal.p-2.small.text-center(v-if="shouldShow")
    a(v-if="assumingUserOriginalId",href="javascript:void(0)",@click="unassumeUser()") Unassume User
    a(v-if="assumingUserTypeAndRole",href="javascript:void(0)",@click="unassumeRole()") Unassume Role
</template>

<script>
  import { mapState } from 'vuex'
  // import ApiAdmin from '../../factories/ApiAdmin'

  export default {
    computed: {
      ...mapState({
        assumingUserOriginalId: state => state.session && state.session.assumingUserOriginalId,
        assumingUserTypeAndRole: state => state.session && state.session.assumingUserTypeAndRole
      }),

      shouldShow() {
        return this.assumingUserOriginalId || this.assumingUserTypeAndRole
      }
    },

    methods: {
      async unassumeRole() {
        window.location.href = `/api/1.0/admin/assume/role`
      },

      async unassumeUser() {
        try {
          // await ApiAdmin.unassumeUser()
          // window.location.href = '/'
          window.location.href = `/api/1.0/admin/unassume/user`
        } catch(err) {
          window.toastr.error(err.message)
        }
      }
    }
  }
</script>
