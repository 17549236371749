<template lang="pug">
  tr(:id="`system-component-row-${_uid}`", @click="$emit('click')")
    td.item-name
      div.d-flex.align-items-center
        i.fa.fa-circle.mr-2(:class="(tool.tool_name || tool.description) ? 'text-success' : 'text-gray'")
        div {{ tool.item_name }}
    td.w-25
      a(v-if="!isEditing",v-html="tool.tool_name")
      input.form-control.form-control-sm(v-else,v-model="toolCopy.tool_name")
    td.w-25
        a(v-if="!isEditing",v-html="tool.description")
        textarea.form-control.form-control-sm(v-else,v-model="toolCopy.description")
    td.text-center(v-if="isAdmin")
      div.d-flex.align-items-start.justify-content-center(v-if="!isEditing")
        div.small-circle-icon.mr-1
          i.fal.fa-pen.clickable(title="Edit Component")
        div.small-circle-icon
          a(@click.stop, :id="`system-component-delete-${_uid}`")
            i.fal.fa-trash-alt.text-danger.clickable(title="Delete Component")
          confirmation-popover.h-25(
            :target="`system-component-delete-${_uid}`",
            header="Confirm Delete System Component?",
            text="Are you sure you want to delete this system component?",
            @confirm="updateTool(true)")
      div.d-flex.align-items-start.justify-content-center(v-else)
        div.small-circle-icon.mr-1(@click.stop="updateTool(false, false)")
          i.fal.fa-check.text-success.clickable(title="Update Component")
    b-tooltip(v-if="isAdmin && !isEditing",:target="`system-component-row-${_uid}`") Click to edit
</template>

<script>
  import { mapState } from 'vuex'
  import ApiAssessments from '../../factories/ApiAssessments'

  export default {
    props: {
      tool: { type: Object },
      index: { type: [Number, String] },
      isEditing: { type: Boolean, default: false },
    },

    watch: {
      tool(newTool) {
        this.toolCopy = newTool
      },

      async isEditing(isEditing) {
        if (!isEditing) await this.updateTool(false, true, false)
      },
    },

    data() {
      return {
        toolCopy: {},
      }
    },

    computed: {
      ...mapState({
        isAdmin: (_, getters) => getters.isAdmin,
      }),
    },

    methods: {
      async updateTool(
        isRemoved = false,
        showMessage = true,
        toggleEditing = true
      ) {
        try {
          let componentData = this.toolCopy
          if (isRemoved) componentData = { ...componentData, is_removed: true }

          await ApiAssessments.updateSystemComponent(componentData)
          if (showMessage) window.toastr.success('Updated system component.')
          this.$emit('updated', this.toolCopy)
          if (toggleEditing) this.$emit('editing', this.isEditing)
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    created() {
      this.toolCopy = this.tool
    },
  }
</script>

<style scoped lang="scss">
  table.table.small-padding tr td:first-child {
    padding-left: 0.2rem;

    &[rowspan] {
      padding-left: 0.75rem;
    }
  }

  tbody {
    td {
      &.w-25 {
        min-width: 30%;
      }

      &[rowspan] {
        background-color: white;
        vertical-align: middle;
        // -webkit-transform: rotate(-90deg);
        // transform: rotate(-90deg);
      }
    }
  }
</style>
