<template lang="pug">
  div
    div.mr-2.custom-control.custom-checkbox-sm.custom-checkbox(v-for="opt in choices" )
      input.custom-control-input(
        :id="`option-checkbox-${opt.id}`",
        v-model="selected[opt.id]"
        @change="update"
        type="checkbox"
        :class="hasOptionClass(opt.id)")
      label.custom-control-label(:for="`option-checkbox-${opt.id}`") {{opt.text}}
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    props: {
      value: { type: Array, default: null },
      choices: { type: Array, default: null },
    },

    data() {
      return {
        // maps array like: [{ choice_id: 1 }, { choice_id: 2 }, { choice_id: 3 }]
        // to { 1: true, 2: true, 3: true }
        selected: this.value.reduce(
          (a, v) => ({ ...a, [v.choice_id]: true }),
          {}
        ),
      }
    },

    computed: {
      ...mapState({
        canAnswerQuestionnaire: (_, getters) => getters.canAnswerQuestionnaire,
      }),
    },

    methods: {
      update() {
        if (!this.canAnswerQuestionnaire) return
        // return window.toastr.error(`
        //   This question is currently read only and cannot be updated. This could
        //   be due to the questionnaire being locked or this question has already
        //   been reviewed by the reviewer. Contact your administrator if you believe this
        //   is a mistake.
        // `)
        const newVal = Object.entries(this.selected).flatMap((s) =>
          s[1] ? { choice_id: s[0] } : []
        )

        this.$emit('input', newVal)
        this.$emit('change', newVal)
      },

      hasOptionClass(choiceId) {
        return (
          (this.value || []).map((o) => o.choice_id).includes(choiceId) &&
          'active'
        )
      },
    },
  }
</script>
