<template lang="pug">
  div.card.card-small
    div.card-header.d-flex.align-items-center.justify-content-center
      i.mr-2.fal.fa-circle(v-if="isRemoteHost",:class="nodeActiveIconClass(node.isAlive)")
      h5.m-0.overflow-ellipses
        router-link(:to="remoteNodeLink") {{ node.external_name || node.hostname || node.ip_address }}
    div.card-body.border-top
      div.row
        div.mb-4(:class="gridClass")
          div.text-gray.small IP Address
          update-remote-host-param(@update="updatedParam",:remote-id="getId('remoteId')",:host-id="getId('hostId')",col="ip_address",model="host_assets",:val="node.ip_address")
        div.mb-4(:class="gridClass")
          div.text-gray.small Hostname
          update-remote-host-param(@update="updatedParam",:remote-id="getId('remoteId')",:host-id="getId('hostId')",col="hostname",model="host_assets",:val="node.hostname")
        div.mb-4(:class="gridClass")
          div.text-gray.small External Name
          update-remote-host-param(@update="updatedParam",:remote-id="getId('remoteId')",:host-id="getId('hostId')",col="external_name",model="host_assets",:val="node.external_name")
        div.mb-4(:class="gridClass")
          div.text-gray.small DNS
          update-remote-host-param(@update="updatedParam",:remote-id="getId('remoteId')",:host-id="getId('hostId')",col="dns",model="host_assets",:val="node.dns")
        div.col-md-12(v-if="isRemoteHost")
          div.text-gray.small.d-flex.align-items-center
            div Node API Key
            a.ml-1.small(@click="showApiKey = !showApiKey") ({{ (showApiKey) ? 'hide' : 'show' }})
          div.small.mt-1
            div(v-if="!showApiKey") ...
            div(v-else) {{ node.api_key }}
    div.card-body.border-top(v-if="isRemoteHost")
      div.row
        div.col.d-flex.align-items-center.mb-3
          h6.m-0 9lyn Proxy Configuration
          div
            i#remote-proxy-info.fal.fa-info-circle.ml-1
            b-tooltip(target="#remote-proxy-info")
              | If you are setting up the 9lyn proxy service to remotely
              | access your internal vulnerability solution,
              | enter the following information corresponding to the
              | proxy configuration you've installed.
      div.row
        div(:class="gridClass")
          div.text-gray.small Proxy Protocol
          update-remote-host-param(@update="updatedParam",:remote-id="getId('remoteId')",:host-id="getId('hostId')",col="proxy_protocol",:val="node.proxy_protocol")
        div(:class="gridClass")
          div.text-gray.small Proxy Port
          update-remote-host-param(@update="updatedParam",:remote-id="getId('remoteId')",:host-id="getId('hostId')",col="proxy_port",:val="node.proxy_port")
    div.card-body.border-top.d-flex.align-items-center.justify-content-center(v-if="isRemoteHost")
      a.btn.btn-white.btn-sm.mr-2(:href="'/remote/proxy/' + node.api_key + '/'",target="_blank",rel="noopener noreferrer") Remote Nessus Proxy
</template>

<script>
  import UpdateRemoteHostParam from '../vulnerability/RemoteAccess/UpdateRemoteHostParam'

  export default {
    props: {
      node: { type: Object },
      isRemoteHost: { type: Boolean, default: true },
      gridClass: { type: String, default: 'col-md-6' }
    },

    data() {
      return {
        showApiKey: false
      }
    },

    computed: {
      remoteNodeLink() {
        return (this.node && this.node.host_assets_id)
          ? `/vulnerability/remote/${this.node.id}`
          : ''
      }
    },

    methods: {
      getId(column) {
        return (column === 'remoteId' && !this.isRemoteHost) ? null : this.node.id
      },

      nodeActiveIconClass(isAlive) {
        return (isAlive) ? 'text-success' : 'text-danger'
      },

      updatedParam(paramDetails) {
        this.$emit('updatedParam', paramDetails)
      }
    },

    components: {
      UpdateRemoteHostParam
    }
  }
</script>
