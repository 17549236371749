<template lang="pug">
  div
    div(v-if="isLoadingLocal")
    div(v-else)
      div(v-if="!hasApiKeysAvailable")
        div.alert.alert-info
          span
            | We see you don't have AWS access key information configured
            | yet. Please #[router-link(to="/settings/aws") click here]
            | to go enter AWS key information so we can fetch vulnerability data
            | from AWS Inspector.
      div(v-else)
        div(v-if="scan && scan.id")
          div
            | Great! We will kick off a new AWS Inspector run with the
            | target/template provided originally when creating the run. Please
            | note that the API keys found #[a(href="/settings/aws",target="_blank",rel="noopener noreferrer") in your settings]
            | need to have Inspector full access permissions to create a new run!
          div.text-center.mt-3
            img.img-fluid.img-thumbnail.rounded.max-400(src="/public/images/aws_inspector_fullaccess_policy.png")
        div(v-else)
          h5 AWS Inspector Assessment Info

          div.api-key-section
            div.form-group
              label(for="aws-key") Select an AWS API key:
              select#aws-key.form-control(v-model="selected.vendorDataId",@change="getRegions()")
                option(v-for="key in apiKeys",:value="key.id") {{ key.decrypted_data.name }}

          div.region-section(v-if="selected.vendorDataId")
            div(v-if="loading.regions")
              loader
            div(v-else)
              div(v-if="awsRegions.length === 0")
                div.alert.alert-info.mb-2(v-if="!selected.region")
                  | We couldn't pull down a list of regions (the IAM account likely doesn't have permissions).
                  | If you know the region you'd like to create/import a scan from, enter it below (ex. us-east-1).
                form(@submit="selectFreeTextRegion")
                  div.form-group
                    label(for="aws-regions-free") AWS region code (ex. us-east-1)
                    input.form-control(v-model="regionFreeTextEnter")
              div.form-group(v-else)
                label(for="aws-regions") Select an AWS region:
                select#aws-regions.form-control(v-model="selected.region",@change="getAssessmentTargets()")
                  option(v-for="region in awsRegions",:value="region.RegionName") {{ region.RegionName }} ({{ region.Endpoint }})

          div.target-section(v-if="selected.region")
            div(v-if="loading.targets")
              loader
            div(v-else)
              div(v-if="assessmentTargets.length === 0")
                div.alert.alert-info We didn't find any assessment targets in the {{ selected.region }} region.
              div.form-group(v-else)
                label(for="aws-targets") Select the assessment target you're interested in:
                select#aws-targets.form-control(v-model="selected.assessmentTarget",@change="getAssessmentTemplates()")
                  option(v-for="target in assessmentTargets",:value="target.arn") {{ target.name }}
              div(v-if="selected.assessmentTargets.length > 0")
                pill-inline.mr-1.mb-1.clickable(v-for="(target, ind) in selected.assessmentTargets",:key="target",:text="target",@close="removeTarget(ind)")
                hr


          div.template-section(v-if="selected.assessmentTargets.length > 0")
            div(v-if="loading.templates")
              loader
            div(v-else)
              div(v-if="assessmentTemplates.length === 0")
                div.alert.alert-info We didn't find any assessment templates for the selected target.
              div.form-group(v-else)
                label(for="aws-templates") Select the assessment template you're interested in:
                select#aws-templates.form-control(v-model="selected.assessmentTemplate",@change="getAssessmentRuns()")
                  option(v-for="template in assessmentTemplates",:value="template.arn") {{ template.name }} ({{ template.assessmentRunCount }} runs)
              div(v-if="selected.assessmentTemplates.length > 0")
                pill-inline.mr-1.mb-1.clickable(v-for="(template, ind) in selected.assessmentTemplates",:key="template",:text="template",@close="removeTemplate(ind)")
                hr

          div.template-section(v-if="selected.assessmentTemplates.length > 0")
            div(v-if="loading.runs")
              loader
            div(v-else)
              div.form-group
                div.custom-control.custom-radio
                  input#start-new-run.custom-control-input(type="radio",name="start-new-run",:value="true",v-model="selected.createNewRun",@change="allowScanToBeSubmitted()")
                  label.custom-control-label(for="start-new-run") Start a New Assessment Run With the Selected Template
                div.custom-control.custom-radio
                  input#use-existing-run.custom-control-input(type="radio",name="use-existing-run",:value="false",v-model="selected.createNewRun",@change="allowScanToBeSubmitted()")
                  label.custom-control-label(for="use-existing-run") Pull Findings from Existing Run
              div(v-if="!selected.createNewRun")
                hr
                div(v-if="assessmentRuns.length === 0")
                  div.alert.alert-info We didn't find any assessment runs for the selected template.
                div.form-group(v-else)
                  label(for="aws-runs") Select the assessment run(s) you're interested in:
                  select#aws-runs.form-control(v-model="selected.assessmentRun",@change="setAssessmentRun()")
                    option(v-for="run in assessmentRuns",:value="run.arn") {{ truncateString(run.name, 80) }} ({{ getFindingsFromRun(run) }} findings)
                div(v-if="selected.assessmentRuns.length > 0")
                  pill-inline.mr-1.mb-1.clickable(v-for="(run, ind) in selected.assessmentRuns",:key="run",:text="run",@close="removeRun(ind)")

          scheduler-form(
            v-if="selected.createNewRun"
            @sendItem="setScheduleItem")
</template>

<script>
  import ApiAws from '../../../../factories/ApiAws'
  import StringHelpers from '../../../../factories/StringHelpers'

  export default {
    props: {
      modalId: { type: String },
      scan: { type: Object, default: null },
    },

    data() {
      return {
        isLoadingLocal: true,
        hasApiKeysAvailable: false,

        apiKeys: [],

        assessmentTargets: [],
        assessmentTemplates: [],
        assessmentRuns: [],
        awsRegions: [],

        loading: {
          regions: true,
          targets: true,
          templates: true,
          runs: true,
        },

        regionFreeTextEnter: null,

        selected: {
          region: null,
          assessmentTarget: null,
          assessmentTemplate: null,
          assessmentRun: null,

          vendorDataId: null,
          assessmentTargets: [],
          assessmentTemplates: [],
          assessmentRuns: [],

          createNewRun: false,

          schedule: {
            frequencyType: 'one-time',
            frequency: null,
            dayOfWeek: null,
            startDate: null,
            endDate: null,
          },
        },
      }
    },

    methods: {
      titleCase: StringHelpers.titleCase,
      truncateString: StringHelpers.truncateString,

      allowScanToBeSubmitted(reset = false, extraInfoOverride = null) {
        this.$emit(
          'processExtraInfo',
          extraInfoOverride || {
            region: this.selected.region,
            vendorDataId: this.selected.vendorDataId,
            assessmentTarget: this.selected.assessmentTargets,
            assessmentTemplate: this.selected.assessmentTemplates,
            assessmentRun: this.selected.assessmentRuns,
            createNewRun: this.selected.createNewRun,
            schedule: this.selected.schedule,
          },
          !reset
        )
      },

      getFindingsFromRun(run) {
        return Object.values(run.findingCounts).reduce(
          (sum, num) => sum + num,
          0
        )
      },

      clearSelectedInfoCascade(whatIsBeingFetched) {
        this.allowScanToBeSubmitted(true)
        switch (whatIsBeingFetched) {
          case 'regions':
            this.selected.assessmentTargets =
              this.selected.assessmentTemplates =
              this.selected.assessmentRuns =
                []
            return (this.selected.region =
              this.selected.assessmentTarget =
              this.selected.assessmentTemplate =
              this.selected.assessmentRun =
                null)
          case 'targets':
            this.selected.assessmentTargets =
              this.selected.assessmentTemplates =
              this.selected.assessmentRuns =
                []
            return (this.selected.assessmentTarget =
              this.selected.assessmentTemplate =
              this.selected.assessmentRun =
                null)
          case 'templates':
            this.selected.assessmentTemplates = this.selected.assessmentRuns =
              []
            return (this.selected.assessmentTemplate =
              this.selected.assessmentRun =
                null)
          case 'runs':
            this.selected.assessmentRuns = []
            return (this.selected.assessmentRun = null)
        }
      },

      setScheduleItem(key, val) {
        this.selected.schedule[key] = val
      },

      setAssessmentRun() {
        this.selected.assessmentRuns.push(this.selected.assessmentRun)
        this.allowScanToBeSubmitted()
      },

      async selectFreeTextRegion(evt) {
        evt.preventDefault()
        this.selected.region = this.regionFreeTextEnter
        await this.getAssessmentTargets()
      },

      async getRegions() {
        try {
          this.clearSelectedInfoCascade('regions')
          this.loading.regions = true
          const { regions } = await ApiAws.getRegions(
            this.selected.vendorDataId
          )
          this.awsRegions = regions
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.loading.regions = false
        }
      },

      async getAssessmentTargets() {
        try {
          this.clearSelectedInfoCascade('targets')
          this.loading.targets = true
          const response = await ApiAws.getInspectorAssessmentTargets({
            region: this.selected.region,
            vendor_data_id: this.selected.vendorDataId,
          })
          this.assessmentTargets = response.results
          this.loading.targets = false

          if (this.assessmentTargets.length === 1) {
            this.selected.assessmentTarget = this.assessmentTargets[0].arn
            await this.getAssessmentTemplates()
          }
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.loading.targets = false
        }
      },

      async getAssessmentTemplates(refreshOnly = false) {
        try {
          this.clearSelectedInfoCascade('templates')

          if (
            !refreshOnly &&
            !this.selected.assessmentTargets.includes(
              this.selected.assessmentTarget
            )
          )
            this.selected.assessmentTargets.push(this.selected.assessmentTarget)

          this.loading.templates = true
          const response = await ApiAws.getInspectorAssessmentTemplates({
            region: this.selected.region,
            targets: this.selected.assessmentTargets,
            vendor_data_id: this.selected.vendorDataId,
          })
          this.assessmentTemplates = response.results
          this.loading.templates = false

          if (this.assessmentTemplates.length === 1) {
            this.selected.assessmentTemplate = this.assessmentTemplates[0].arn
            await this.getAssessmentRuns()
          }
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.loading.templates = false
        }
      },

      async getAssessmentRuns(refreshOnly = false) {
        try {
          this.clearSelectedInfoCascade('runs')

          if (
            !refreshOnly &&
            !this.selected.assessmentTemplates.includes(
              this.selected.assessmentTemplate
            )
          )
            this.selected.assessmentTemplates.push(
              this.selected.assessmentTemplate
            )

          this.loading.runs = true
          const response = await ApiAws.getInspectorAssessmentRuns({
            region: this.selected.region,
            templates: this.selected.assessmentTemplates,
            vendor_data_id: this.selected.vendorDataId,
          })
          this.assessmentRuns = response.results
          this.loading.runs = false

          if (this.assessmentRuns.length === 1) {
            this.selected.assessmentRun = this.assessmentRuns[0].arn

            if (
              !this.selected.assessmentRuns.includes(
                this.selected.assessmentRun
              )
            )
              this.selected.assessmentRuns.push(this.selected.assessmentRun)

            this.allowScanToBeSubmitted()
          }
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.loading.runs = false
        }
      },

      async removeTarget(index) {
        this.selected.assessmentTarget = null
        this.selected.assessmentTargets.splice(index, 1)
        await this.getAssessmentTemplates(true)
      },

      async removeTemplate(index) {
        this.selected.assessmentTemplate = null
        this.selected.assessmentTemplates.splice(index, 1)
        await this.getAssessmentRuns(true)
      },

      removeRun(index) {
        this.selected.assessmentRun = null
        this.selected.assessmentRuns.splice(index, 1)
      },
    },

    async created() {
      try {
        const { records } = await ApiAws.getApiKeys()
        this.apiKeys = records.map((r) => ({
          ...r,
          decrypted_data: JSON.parse(r.decrypted_data),
        }))
        this.hasApiKeysAvailable = this.apiKeys.length > 0

        if (this.apiKeys.length === 1)
          this.selected.vendorDataId = this.apiKeys[0].id

        if (this.scan && this.scan.id)
          return this.allowScanToBeSubmitted(false, {
            createNewRun: true,
            schedule: this.selected.schedule,
          })

        if (this.hasApiKeysAvailable && this.selected.vendorDataId)
          await this.getRegions()
      } finally {
        this.isLoadingLocal = false
      }
    },
  }
</script>
