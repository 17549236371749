<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-labelledby="mfa-modal-label",aria-hidden="true")
    div.modal-dialog(role="document")
      div.modal-content
        form(@submit="validateCreationMfaToken")
          div.modal-header
            h5.modal-title Two-factor Auth Validation
            button.close(type="button",data-dismiss="modal",aria-label="Close")
              span(aria-hidden="true") &times;
          div.modal-body
            div.alert.alert-danger.mb-4(v-if="require")
              strong
                | Your org administrator requires that you enable MFA for your account
                | to login to fullCircle. Please use an authenticator app such as Google Authenticator
                | or Duo to enable MFA. Contact your administrator if you have any
                | questions or concerns
            div
              | Scan the following QR code with your MFA app to generate
              | a token. Enter the token below and Submit to enable
              | two-factor authentication.
            hr
            div.d-flex.justify-content-center
              img.img-fluid(v-if="mfaQrUrl",:src="mfaQrUrl")
              loader(v-else)
            hr
            div.form-group.mb-0
              label(for="two-factor-auth-token") Two-factor authentication token
              input#two-factor-auth-token.form-control.form-control-lg(
                v-model="mfaToken"
                type="text"
                autocomplete="false")
            div.alert.alert-success.mt-2(v-if="mfaSuccess")
              | Successfully validated your token and two-factor authentication
              | is now enabled for your account.
            div.alert.alert-danger.mt-2(v-if="!!mfaError") {{ mfaError }}
          div.modal-footer
            button.btn.btn-secondary(type="button",data-dismiss="modal") Cancel
            button.btn.btn-primary(type="submit") Submit Token
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import QRCode from 'qrcode'
  import ApiAuth from '../../factories/ApiAuth'
  import { sleep } from '../../factories/Utilities'

  export default {
    props: {
      require: { type: Boolean, default: false },
    },

    data() {
      return {
        mfaQrUrl: null,
        mfaError: null,
        mfaSuccess: null,
        mfaToken: null,
      }
    },

    computed: {
      ...mapState({
        isTwoFactorEnabled: (state) => state.auth.user.two_factor_enabled,
      }),
    },

    methods: {
      async validateCreationMfaToken(evt) {
        try {
          if (evt) evt.preventDefault()

          await ApiAuth.validateCreationMfaToken(this.mfaToken)

          this.mfaSuccess = true
          this.$emit('enable')
          await sleep(1000)
          $(`#${this.$el.id}`).modal('hide')
        } catch (err) {
          this.mfaError = err.message
        }
      },
    },

    mounted() {
      $(`#${this.$el.id}`).on('shown.bs.modal', async () => {
        try {
          const res = await ApiAuth.generateMfaSecret()
          this.mfaQrUrl = await QRCode.toDataURL(res.url)
        } catch (err) {
          window.toastr.error(err.message)
        }
      })

      $(`#${this.$el.id}`).on('hidden.bs.modal', () => {
        this.$emit('close')
      })
    },
  }
</script>
