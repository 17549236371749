<template lang="pug">
  div.row.text-center.reporting-tabs
    a.col.border-left.border-top.border-right.border-bottom.p-2.d-flex.align-items-center.justify-content-center(:class="isActiveTabClass('main')",@click="updateActiveTab('main')")
      div Main
    a.col.border-top.border-right.border-bottom.p-2.d-flex.align-items-center.justify-content-center(:class="isActiveTabClass('filters')",@click="updateActiveTab('filters')")
      div Filters
    a.col.border-top.border-right.border-bottom.p-2.d-flex.align-items-center.justify-content-center(:class="isActiveTabClass('confirm')",@click="updateActiveTab('confirm')")
      div Confirm and Submit
</template>

<script>
  export default {
    props: [ 'value' ],

    methods: {
      isActiveTabClass(tab) {
        return (this.value === tab) ? 'active-tab' : ''
      },

      updateActiveTab(tab) {
        this.$emit('input', tab)
      }
    }
  }
</script>
