import { render, staticRenderFns } from "./CreateExtensionModal.vue?vue&type=template&id=1f57a6c7&lang=pug"
import script from "./CreateExtensionModal.vue?vue&type=script&lang=js"
export * from "./CreateExtensionModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports