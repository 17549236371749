<template lang="pug">
  div
    div.mb-4.d-flex.justify-content-center(v-if="isEditing")
      a(:id="`risk-cell-${_uid}`") Add risk(s)
      risk-finder-popover(
        :target="`risk-cell-${_uid}`"
        @select="addRisk")
    div(
      @click="canEdit ? isEditing = !isEditing : ''" :class="canEdit ? 'editable' : ''")
        div(v-if="selectedRisks.length === 0")
          i {{canEdit ? 'No risks yet...' : ''}}
        ul.list-unstyled(v-else)
          li.strong.mb-2(v-for="risk in selectedRisks")
            div.d-flex.align-items-start
              div.mr-2
                risk-register-data-circle(
                  :value="risk.risk_score || '-'"
                  size="25"
                  type="risk")
              div.mr-2
                div.nowrap(:id="`spreadsheet-risk-${_uid}-${risk.id}`")
                  | {{ truncateString(stripHtmlTags(risk.description), 40) }}
                b-tooltip(:target="`spreadsheet-risk-${_uid}-${risk.id}`")
                  div(v-html="risk.description")
                div.small {{ risk.register_name }}
              div
                a(@click.stop="removeRisk(risk.id)")
                  i.fal.fa-times.text-danger
</template>

<script>
  import { mapState } from 'vuex'
  import StringHelpers from '../../../../factories/StringHelpers'

  export default {
    props: {
      value: { type: String, default: null },
      canEdit: { type: Boolean, default: true },
    },

    watch: {
      async value() {
        await this.getRisks()
      },
    },

    data() {
      return {
        isEditing: false,
      }
    },

    computed: {
      ...mapState({
        riskMap: (state) => state.inventories.risks,
      }),

      selectedRisks() {
        return this.selectedRiskIds
          .map((riskId) => this.riskMap[riskId])
          .filter((v) => !!v)
          .sort((v1, v2) =>
            this.stripHtmlTags(v1.description).toLowerCase() <
            this.stripHtmlTags(v2.description).toLowerCase()
              ? -1
              : 1
          )
      },

      selectedRiskIds: {
        get() {
          return (this.value || '').split(',').filter((i) => !!i)
        },

        async set(newVal) {
          await this.updateVal(newVal.filter((i) => !!i).join(','))
        },
      },
    },

    methods: {
      stripHtmlTags: StringHelpers.stripHtmlTags,
      truncateString: StringHelpers.truncateString,

      async addRisk(riskId) {
        const allIds = [...new Set(this.selectedRiskIds.concat([riskId]))]
        await this.updateVal(allIds.join(','))
      },

      async removeRisk(controlId) {
        await this.updateVal(
          this.selectedRiskIds.filter((i) => i != controlId).join(','),
          false
        )
      },

      async updateVal(newVal) {
        if (newVal === this.value) return
        this.$emit('input', newVal)
        this.isEditing = false
      },

      async getRisks() {
        try {
          if (this.selectedRiskIds.length === 0) return
          await this.$store.dispatch('getBiaRisks', this.selectedRiskIds)
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    async created() {
      // get all risks that we've selected now
      await this.getRisks()
    },
  }
</script>
