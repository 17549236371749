<template lang="pug">
  div
    div
      strong File Uploaded
    attachment-item.bg-light(:attachment="data",@delete="deleteItem")
</template>

<script>
  export default {
    props: {
      // Same thing as 'attachment' prop in ./AttachmentItem component
      data: { type: Object, required: true },
    },

    methods: {
      deleteItem(id) {
        this.$emit('deleteFile', id)
      },
    },
  }
</script>
