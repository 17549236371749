<template lang="pug">
  div.d-flex.align-items-center.w-100
    template(v-for="(opt, ind) in options")
      div.d-flex.align-items-center.justify-content-center.clickable(
        :id="(tooltips && tooltips[ind]) ? `bubble-val-${tooltips[ind].type}-${tooltips[ind].value}` : null",
        style="border-radius: 50%;",
        :class="circleClasses(opt)",
        @click="selectVal(opt.value)"
      )
        div #[strong {{ opt.value }}]
        b-tooltip(v-if="tooltips && tooltips[ind]",:target="`bubble-val-${tooltips[ind].type}-${tooltips[ind].value}`")
          | {{ tooltips[ind].tooltip }}
      div.line-horizontal(
        v-if="ind !== options.length - 1",
        :class="opt.cssGradientClass")
</template>

<script>
  import Utilities from '@risk3sixty/utilities'

  export default {
    props: {
      value: { type: Number },
      size: { type: Number, default: 30 },
      tooltips: { type: Array, default: null },
    },

    data() {
      return {
        options: [
          {
            value: 1,
            variant: 'success',
            cssGradientClass: 'bg-grad-success-minimal',
          },
          {
            value: 2,
            variant: 'minimal',
            cssGradientClass: 'bg-grad-minimal-info',
          },
          {
            value: 3,
            variant: 'info',
            cssGradientClass: 'bg-grad-info-warning',
          },
          {
            value: 4,
            variant: 'warning',
            cssGradientClass: 'bg-grad-warning-danger',
          },
          { value: 5, variant: 'danger', cssGradientClass: '' },
        ],
      }
    },

    methods: {
      truncateString: Utilities.Strings.truncateString,

      circleClasses(opt) {
        let classes = [`size-${this.size}`, `border-${opt.variant}`]

        if (this.value === opt.value)
          classes.push(`bg-${opt.variant}`, 'text-white')
        else classes.push(`text-${opt.variant}`)

        return classes
      },

      selectVal(newValue) {
        if (this.value === newValue) return

        this.$emit('input', newValue)
        this.$emit('change', newValue)
      },
    },
  }
</script>
