<template lang="pug">
  div.page-header.row.no-gutters(style="overflow: hidden;")
    div.col-12.col-sm-8.text-center.text-sm-left.mb-0(style="max-width: 100%")
      span.text-uppercase.page-subtitle(v-if="subTitle", v-html="subTitle")
      div.d-flex.align-items-center
        div
          img.img-fluid.mr-2.pr-2.border-right(v-if="imageSrc",:src="imageSrc",:class="imageClass")
        h3.page-title.title-overflow(v-html="title")
</template>

<script>
  export default {
    props: {
      title: { type: String },
      subTitle: { type: String, default: 'Dashboard' },
      imageSrc: { type: String, default: null },
      imageClass: { type: String, default: 'max-50' },
    },

    watch: {
      title(newTitle) {
        this.$store.commit('SET_PAGE_TITLE', newTitle)
      },
    },

    created() {
      this.$store.commit('SET_PAGE_TITLE', this.title)
    },
  }
</script>

<style lang="scss" scoped>
  .title-overflow {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.wrap-always {
      white-space: normal;
      word-break: break-all;
    }
  }
</style>
