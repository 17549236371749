<template lang="pug">
  div#r3s.container-fluid.h-100(:class="(isLeftNavExpanded) ? '' : 'icon-sidebar-nav'")
    div.row.h-100
      risk3sixty-main-sidebar#r3s-sidebar.col-12.col-md-3.col-lg-2(v-if="isLoggedIn")
      main#r3s-main-body.main-content(:class="mainGridClasses",:style="(isLeftNavExpanded) ? { padding: '0 !important' } : {}")
        risk3sixty-main-topbar(v-if="isLoggedIn")
        risk3sixty-main-banner(v-if="isLoggedIn")
        div.main-content-container.container-fluid(:class="(currentModuleHasPadding && 'px-4') || 'p-0'")
          div(v-if="isLoading")
            loader
          div(v-else)
            router-view#main-content
        risk3sixty-footer(v-if="isLoggedIn && currentModuleHasPadding")
    a.text-white.toggle-left-nav.d-none.d-md-block(v-if="isLoggedIn",href="javascript:void(0)",@click="toggleLeftNav")
      i.fal(:class="openCloseToggleClass")
    //- div.toggle-sticky-notes.d-none.d-md-block.clickable(
    //-   v-if="!isLoading && isLoggedIn && isInternalUser",
    //-   @click="toggleStickyNotes")

    sticky-notes-board(
      v-if="isStickyNotesOpen",
      :board="stickyNotesBoard",
      @reset="updateGlobalStickyNotes")
    vulnerability-create-scan-modal#create-vulnerability-modal-global(
      :scan="vulnerabilityScanEdited",
      @hide="hideGlobalVulnerabilityScanCreationModal")
    mfa-modal#global-mfa-modal(:require="true",@close="checkAndPotentiallyLogout")
    reset-password-modal
    unassume-user-modal
    easter-eggs-modal
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import ApiGlobal from '../factories/ApiGlobal'
  import Utilities from '@risk3sixty/utilities'
  import EasterEggsModal from './eastereggs/Modal'
  import DataTrendCard from './reusable/DataTrendCard'
  import ResetPasswordModal from './auth/ResetPasswordModal'
  import UnassumeUserModal from './auth/UnassumeUserModal'
  import Risk3sixtyMainBanner from './Risk3sixtyMainBanner'
  import Risk3sixtyMainSidebar from './Risk3sixtyMainSidebar'
  import Risk3sixtyMainTopbar from './Risk3sixtyMainTopbar'
  import Risk3sixtyFooter from './Risk3sixtyFooter'
  import StickyNotesBoard from './StickyNotes/Board'
  import ApiAdmin from '../factories/ApiAdmin'
  import ApiUsers from '../factories/ApiUsers'
  import R3sSocket from '../factories/R3sSocket'
  import {
    checkAndDisplayIndependenceConfirmationToastr,
    checkUserNavAndPossiblyRedirect,
  } from '../factories/Utilities'

  const moment = Utilities.Dates.moment

  export default {
    name: 'risk3sixty',

    data() {
      return {
        stickyNotesBoard: { notes: [] },
      }
    },

    computed: {
      ...mapState({
        currentModuleHasPadding: (_, getters) =>
          getters.currentModuleHasPadding,
        currentTeam: (state) => state.session && state.session.current_team,
        isInternalUser: (_, getters) => getters.isInternalUser,
        isLeftNavExpanded: (state) => state.isLeftNavExpanded,
        isLoading: (state) => state.isLoading,
        isLoggedIn: (state) => state.isLoggedIn,
        isStickyNotesOpen: (state) => state.isStickyNotesOpen,
        stickyNotes: (state) => state.team.stickyNotes,
        stickyNotesIncludeDeleted: (state) => state.stickyNotesIncludeDeleted,
        user: (state) => state.auth.user,
        userNavItems: (state) => state.auth.userNavItems,
        vulnerabilityScanEdited: (state) =>
          state.vulnerability.globalScanBeingEdited,
      }),

      mainGridClasses() {
        let classes = this.isLoggedIn ? [] : [`fullcircle-bg`]

        if (!this.isLeftNavExpanded) {
          classes.push('col')
        } else {
          classes.push(
            `nav-expanded col-lg-10 col-md-9 col-sm-12 offset-lg-2 offset-md-3`
          )
        }

        return classes
      },

      openCloseToggleClass() {
        return this.isLeftNavExpanded ? 'fa-chevron-left' : 'fa-chevron-right'
      },
    },

    watch: {
      userNavItems: {
        handler(navInfo) {
          checkUserNavAndPossiblyRedirect.call(this, navInfo)
        },
        deep: true,
      },

      async $route(/*to, from*/) {
        checkUserNavAndPossiblyRedirect.call(this)
        await this.$store.dispatch('init')

        R3sSocket.emit(
          'globalUpdatePagePath',
          this.$router.currentRoute.fullPath
        )

        // Hide all modals and popovers on route change
        $('.modal').modal('hide')
        this.$root.$emit('bv::hide::popover') // https://bootstrap-vue.js.org/docs/components/popover/

        checkAndDisplayIndependenceConfirmationToastr(
          this.$route.query,
          `Successfully attested your independence for ${this.$route.query.independence}!`
        )

        this.triggerAppcues()
        this.triggerFullStory()
      },
    },

    methods: {
      hideGlobalVulnerabilityScanCreationModal(isOnlyHiding) {
        if (!isOnlyHiding)
          this.$store.commit('SET_VULNERABILITY_GLOBAL_SCAN_BEING_ASSIGNED')
      },

      subscribeR3sSocket() {
        R3sSocket.emit('globalSubscribe', this.$router.currentRoute.fullPath)
      },

      triggerAppcues() {
        if (!(this.user && this.user.id) || !window?.Appcues) return

        window.Appcues.page()

        window.Appcues.identify(
          this.user.id, // unique, required
          {
            createdAt: moment(this.user.created_at).unix(), // Unix timestamp of user signup date
            purchasedAt: null, // Unix timestamp of account purchase date (leave null if empty)
            planTier: null, // Current user’s plan tier
            role: this.currentTeam.role, // Current user’s role or permissions
            accountId: this.currentTeam.id, // Current user's account ID
            firstName: this.user.first_name, // current user's first name

            // additional suggestions

            companyName: this.currentTeam.name, // Current user’s company name
            email: this.user.username_email, // Current user's email
            location: this.currentTeam.zip_code, // a zipcode, state, or country enables location-based targeting
            version: null, // users on different versions may need to see different content
            language: null, // for multi-language applications
            renewalDate: null, // to remind users to renew
            planType: this.currentTeam.type, // Current user's organization type (global, organization, grc)
            subscriptionType: this.currentTeam.subscription_type, // User's organization subscription type (legacy, essentials, startup, compliance, scaling, enterprise )
          }
        )
      },

      triggerFullStory() {
        if (!(this.user && this.user.id) || !window?.FS) return

        window.FS.identify(
          this.user.id, // unique, required
          {
            displayName: this.user.name, // Current user’s name
            email: this.user.username_email, // Current user's email
            subscriptionType: this.currentTeam.subscription_type, // User's organization subscription type (legacy, essentials, startup, compliance, scaling, enterprise )
            isPrimaryUser:
              this.currentTeam.id == this.currentTeam.main_contact_user_id
                ? true
                : false, // Check to see if user's account ID is the same as the main contact ID, returns true if it is.
            organizationType: this.currentTeam.type, // Current user's organization type (global, organization, grc)
            role: this.currentTeam.role, // Current user’s role or permissions
            organizationId: this.currentTeam.id, // Current user's account ID
            organizationName: this.currentTeam.name, // Current user's account name
          }
        )
      },

      async toggleStickyNotes() {
        await this.$store.dispatch('getStickyNotes')
        this.stickyNotesBoard.notes = this.stickyNotes.slice()
        this.$store.commit('TOGGLE_STICKY_NOTES')
      },

      async updateGlobalStickyNotes() {
        const { notes } = await ApiAdmin.getOrgStickyNotes(
          this.stickyNotesIncludeDeleted
        )
        this.$store.commit('SET_STICKY_NOTES', notes)
        this.stickyNotesBoard.notes = this.stickyNotes.slice()
      },

      async toggleLeftNav() {
        this.$store.commit('TOGGLE_LEFT_NAV')
        await ApiUsers.updatePersonalization(`global_left_nav_expanded_state`, {
          isExpanded: this.isLeftNavExpanded,
        })
      },

      async checkAndPotentiallyLogout() {
        await this.$store.dispatch('getAndSetUserSession', true)

        // If MFA is required, and not setup for user, then logout
        if (!this.user.two_factor_enabled)
          return (window.location.href = '/logout')

        // If user still needs to reset password, then show reset password modal
        if (this.user && this.user.needs_password_reset)
          return $('#reset-password-modal').modal('show')
      },

      async getECSMetadata() {
        // Pulls data from ECS metadata service and populates meta tag defined in index.pug
        let metadata = 'ECS Metadata service not available'
        try {
          let res = await ApiGlobal.getECSMetadata()
          if (res) metadata = res
          $('#ecs-metadata-tag').attr('content', JSON.stringify(metadata))
        } catch (err) {
          return
        }
      },
    },

    created() {
      R3sSocket.on('connect', () => this.subscribeR3sSocket())
      // R3sSocket.on('connect_error', console.error)
      // R3sSocket.on('error', console.error)
      R3sSocket.on('disconnect', () => R3sSocket.open())
      R3sSocket.on('hardReload', () => location.reload())
      this.getECSMetadata()

      // Sidebar toggles for responsive nav on small screens
      $(
        '.toggle-sidebar, #main-side-nav .nav-link:not(.dropdown-toggle), #main-side-nav a.dropdown-item'
      ).click(function (/*e*/) {
        $('.main-sidebar').toggleClass('open')
      })
    },

    async mounted() {
      await this.$store.dispatch('init')

      // If MFA is required, and not setup for user, then show MFA modal
      if (
        this.currentTeam &&
        this.currentTeam.require_mfa &&
        !this.user.two_factor_enabled
      )
        return $('#global-mfa-modal').modal('show')

      // If user needs to reset password, then show reset password modal
      if (this.user && this.user.needs_password_reset)
        return $('#reset-password-modal').modal('show')
    },

    components: {
      EasterEggsModal,
      DataTrendCard,
      ResetPasswordModal,
      UnassumeUserModal,
      Risk3sixtyFooter,
      Risk3sixtyMainBanner,
      Risk3sixtyMainSidebar,
      Risk3sixtyMainTopbar,
      StickyNotesBoard,
    },
  }
</script>

<style scoped lang="scss">
  #r3s-main-body,
  #r3s-sidebar {
    -webkit-transition: width 0.25s; /* Safari */
    transition: width 0.25s;
  }

  .toggle-left-nav {
    position: fixed;
    left: 12px;
    bottom: 12px;
    z-index: 1100;
  }

  .toggle-sticky-notes {
    position: fixed;
    bottom: 5px;
    right: 5px;
    z-index: 1100;
    width: 40px;
    height: 40px;
    background-image: url(/public/images/stickynote.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    i {
      color: rgba(#fce203, 0.9);
    }
  }
</style>
