<template lang="pug">
  div.question-container.pt-4.px-4(v-if="question")
    div.d-flex
      div.mb-3.mr-4.text-center(v-if="!compressedVersion")
        question-circle(
          :text="`${sectionNumber}.${questionNumber}`",
          :is-active="isActiveQuestion"
          :class="getQuestionNumberSize(sectionId, questionId)")
        div.mt-3.mb-2
          div.mb-1(v-if="isInternalUser")
            comments.d-inline-block(
              table="questionnaire_questions"
              :entity-id="questionId"
              :only-internal="true"
              :modal-title="`${sectionNumber}.${questionNumber} Question Comments`")
          div.mb-1
            files.d-inline-block(
              table="questionnaire_questions"
              :entity-id="questionId"
              :modal-title="`${sectionNumber}.${questionNumber} Question Files`")
          div.mb-1
            question-flag.d-inline-block(:question-id="questionId", :questionnaire-id="questionnaireId", :useOutlinedFlag="true")
        //-   div
        //-     change-log.d-inline-block(
        //-         :modal-title="`Question History`"
        //-         :change-log="question.change_log"
        //-         :choices="question.choices")
      div.mb-3
        div.card.card-small.mb-4.border.border-info(v-if="question.needs_clarification")
          div.card-header.bg-info
            h6.m-0.text-white Question Needs Clarification #[i#clarification-info.fal.fa-info-circle]
          div.card-body
            div
              strong Notes from reviewer:
            div.no-pmargin(v-if="question.clarification_notes",v-html="question.clarification_notes")
            div(v-else)
              i No notes provided by reviewer...
            b-tooltip(target="#clarification-info")
              | Please use either the answer text box if the question is free form
              | or add a comment using the comment toggle modal in the top right
              | of this question to provide clarification asked for by the inquirer.
        div.mb-3(v-html="question.is_optional ? question.text : (question.text + '<span class=text-danger>*</span></p>')")
        question-options(
          :type="question.type"
          :choices="question.choices"
          v-model="question.responses"
          @change="updateValue")
        div.mt-2(v-if="!readOnly && expandableQuestionRecords && expandableQuestionRecords.length > 0")
          questionnaire-question(
            v-for="depQues in expandableQuestionRecords"
            :key="depQues.id"
            :section-id="depQues.group_id"
            :question-id="depQues.id"
            :compressed-version="true")
        //- div.mt-3(v-if="questionResponseNames(question.responses) && !compressedVersion")
        //-   | Answered by {{ questionResponseNames(question.responses) }}
</template>

<script>
  import { mapState } from 'vuex'
  import ChangeLog from './ChangeLog'
  import QuestionCircle from './QuestionCircle'
  import QuestionFlag from './QuestionFlag'
  import QuestionOptions from './QuestionOptions'
  import ApiVendorbase from '../../../factories/ApiVendorbase'

  export default {
    name: 'QuestionnaireQuestion',

    props: {
      sectionId: { type: [Number, String], default: null },
      questionId: { type: [Number, String], default: null },
      compressedVersion: { type: Boolean, default: false },
      readOnly: { type: Boolean, default: false },
    },

    watch: {
      async questionId() {
        this.questionReviewNotes = this.question.review_notes
        if (!this.activeQuestionId) return
        // await ApiVendorbase.addBreadcrumb({
        //   sectionNum: this.sectionNumber,
        //   questionNumber: this.questionNumber,
        //   sectionId: this.activeSectionId,
        //   questionId: this.activeQuestionId,
        //   questionName: this.question.text,
        // })
        // await this.$store.dispatch('getBreadcrumbs')
      },
    },

    computed: {
      ...mapState({
        activeSectionId: (state) => state.questionnaire.activeSectionId,
        activeQuestionId: (state) => state.questionnaire.activeQuestionId,
        questionnaireId: (state) =>
          state.questionnaire.record && state.questionnaire.record.id,

        question(state) {
          try {
            return state.questionnaire.record.sections
              .find((s) => s.id == this.sectionId)
              .questions.find((q) => q.id == this.questionId)
          } catch (err) {
            return null
          }
        },

        sectionNumber(state) {
          return state.questionnaire.getSectionNum(this.sectionId)
        },

        questionNumber(state) {
          return state.questionnaire.getQuestionNum(
            this.sectionId,
            this.questionId
          )
        },
        getQuestionNumberFromState: (state) =>
          state.questionnaire.getQuestionNum.bind(state.questionnaire),
        isInternalUser: (_, getters) => getters.isInternalUser,
      }),

      isActiveQuestion() {
        return false
        // (
        //   this.sectionId.toString() === this.activeSectionId.toString() &&
        //   this.questionId.toString() === this.activeQuestionId.toString()
        // )
      },

      expandableQuestionRecords() {
        const responseChoiceIds = this.question.responses.map(
          (res) => res.choice_id && res.choice_id.toString()
        )
        return (
          this.question.responses &&
          this.question.responses.length > 0 &&
          this.question.choices
            .reduce((questions, choice) => {
              if (
                !choice.trigger_questions ||
                choice.trigger_questions.length < 1 ||
                !responseChoiceIds.includes(choice.id.toString())
              ) {
                return questions
              }

              return [...questions, ...choice.trigger_questions]
            }, [])
            .sort((a, b) => a.ordering - b.ordering)
        )
      },
    },

    methods: {
      questionResponseNames(responses) {
        if (!responses || responses.length === 0) return null
        return responses.map((r) => r.user_name).join(', ')
      },

      getQuestionNumberSize(sectionId, questionId) {
        let arr = this.getQuestionNumberFromState(sectionId, questionId)
          .toString()
          .split('.')
        if (arr.length > 1) return 'text-small'
      },

      async updateValue(newValueAry) {
        try {
          if (this.readOnly) return
          await ApiVendorbase.answerQuestion(
            this.questionnaireId,
            this.questionId,
            newValueAry
          )
        } catch (err) {
          window.toastr.error(err.message)
          await this.$store.dispatch('getSelfAssessment', this.questionnaireId)
        }
      },
    },

    components: {
      ChangeLog,
      QuestionCircle,
      QuestionFlag,
      QuestionOptions,
    },
  }
</script>

<style lang="scss">
  .question-container {
    overflow-y: scroll;

    &:last-child {
      border-bottom: 0px !important;
    }
  }

  .no-pmargin {
    p {
      margin: 0px;
    }
  }
</style>
