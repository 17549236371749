<template lang="pug">
  div
    div(v-if="isLoadingLocal")
      loader
    div(v-else-if="!entityId")
      div.d-flex.flex-column.align-items-center
        img.mt-4.img-fluid(src="/public/images/clipboard.svg")
        h1.mt-4.mb-0 Questionnaire Editor
        h4.mt-3.mb-0 What you would like to do?
        div.mt-5.mb-4
          button.mr-2.btn.btn-lg.btn-white(
            data-toggle="modal",
            data-target="#select-questionnaire-builder-modal") Edit Existing Questionnaire
          button.btn.btn-lg.btn-primary(
            data-toggle="modal",
            data-target="#create-questionnaire-builder-modal") Create New Questionnaire
    div.row(v-else)
      div.d-none.d-md-block.max-container-height.mb-4.mr-3(style="max-width: 23%;")
        div.card.card-small.mb-4.sticky-card
          div.card-header.border-bottom.d-flex.justify-content-space-between.align-items-center
            h5.m-0.mr-1(v-if="currentQuestionnaire && currentQuestionnaire.name") {{truncateString(currentQuestionnaire.name, collapsed ? 5 : 100)}}
            a#collapse-icon(@click="collapsed=!collapsed")
              i.fal.fa-arrow-circle-left(v-if="!collapsed")
              i.fal.fa-arrow-circle-right(v-if="collapsed")
            b-tooltip(target="collapse-icon") {{ collapsed ? 'Expand' : 'Collapse' }} Section View
          div.card-body.p-0
            div.card.card-small.no-shadow.rounded-0
              div.card-header.border-bottom(v-if="!collapsed")
                div.d-flex.align-items-center.mt-2.flex-wrap.justify-content-space-evenly
                  a#bulk-upload-questions-button.bulk-questions-btn.btn.btn-sm.btn-white.m-1(data-toggle="modal",data-target="#bulk-upload-modal")
                    i.fal.fa-upload
                    span.ml-1 Upload Questions
                    b-tooltip(target="#bulk-upload-questions-button")
                      | Import questions from a populated CSV.
                  a#bulk-download-questions-button.bulk-questions-btn.btn.btn-sm.btn-white.m-1(@click="downloadQuestions")
                    i.fal.fa-download
                    span.ml-1 Export Questions
                    b-tooltip(target="#bulk-download-questions-button")
                      | Export questions to CSV.
              
              div.card-header.border-bottom
                div.d-flex.align-items-center
                  h5.m-0.strong Sections
                  div.ml-auto
                    div.btn-group.btn-group-sm
                      div(v-if="isAddingSection")
                        loader-inline
                      div(v-else)
                        i.clickable.ml-1.fal.fa-plus-circle.text-success(:id="`questionnaire-section-addq-${_uid}`"
                          @click="createSection")
              draggable.list-group.list-group-flush.scroll-after-fold(
                v-bind="{ disabled: !sections || sections.length === 0 }"
                v-model="currentQuestionnaire.sections"
                tag="ul"
                @change="updateSectionOrdering")
                  li.list-group-item(v-if="!sections || sections.length === 0")
                    i No sections added yet...
                  li.hoverable.clickable.list-group-item.px-3(
                    v-else v-for="(section, sInd) in sections"
                    :class="activeSection && activeSection.id == section.id ? 'selected' : ''")
                    div.d-flex.align-items-center.justify-content-space-between( @click="selectSection(section, sInd)")
                      h6.m-0 {{truncateString(section.text, collapsed ? 5 : 100)}}
                      i.fal.fa-chevron-right
          branded-card-footer.border-top

      div.fill-space.pr-1
        div.card(v-if="!activeSection")
          div.card-body.p-0
            h4.text-center.my-5
              | #[i.fa.fa-arrow-alt-left] Select a section to review and make changes

        div.card(v-else)
          div.card-header.border-bottom.col-12.d-flex.align-items-center.justify-content-space-between
            div.d-flex.align-items-start.w-100
              div.form-group.m-0.w-100
                div.bg-white.w-100
                  input.border.rounded.p-3.section-name-input.w-75(
                    type="text"
                    :options="{ theme: 'bubble' }"
                    v-model="activeSection.text"
                    @blur="saveSection(activeSection)")
                      
            div.ml-auto.nowrap
              button.btn.btn-danger.add-question-btn(
                :id="`questionnaire-section-delete-${activeSection.id}`") 
                | Delete Section
              confirmation-popover(
                :target="`questionnaire-section-delete-${activeSection.id}`",
                header="Confirm Delete Section?",
                text="Are you sure you want to delete this section?",
                @confirm="deleteSection(activeSection)")

          div.card-body.p-0 
          draggable(
            v-model="activeSectionQuestions"
            @change="updateQuestionOrdering(activeSectionQuestions, true)")
              div.border-left.bg-light( v-for="(question, index) in activeSectionQuestions")
                div.row.m-0.question-container.pt-4.pl-4(
                  v-if="activeQuestion && activeQuestion.id == question.id")

                  div.d-flex.question-editor-container
                    div.col-1.mb-3.pl-0.text-center.circle-container
                      data-circle(
                        :value="`${getSectionNumber(activeSection.id)}.${getQuestionNumber(activeSection.id, activeQuestion.id)}`",
                        :size="30",
                        card-class="bg-primary text-white")

                    div.card.card-small.mb-4.border.border-info.px-3.mr-3
                      question-editor(
                        v-if="question.id = activeQuestion.id"
                        :question="{ ...question, choices: null }"
                        :choices="question.choices"
                        @updateEditMode="getQuestionnaireInfo"
                        @deleted="deletedQuestion"
                        @saved="updateQuestionOrdering(activeSectionQuestions)"
                        @cancel="activeQuestion = null")
                div.hoverable.clickable(
                  v-else 
                  @click="activeQuestion = question, collapsed = true")
                    questionnaire-question(
                      :section-id="question.group_id"
                      :question-id="question.id"
                      :read-only="true")
          div.d-flex.justify-content-center.m-3
            button.btn.btn-primary(
              @click="createQuestion") + Add Question
          branded-card-footer.border-top
          
    create-questionnaire-modal#create-questionnaire-builder-modal(@created="gotoQuestionnaireBuilder")
    questionnaire-selection-modal#select-questionnaire-builder-modal(@select="gotoQuestionnaireBuilder")
</template>

<script>
  import { mapState } from 'vuex'
  import BulkUploadQuestionModal from '../vendormanagement/BulkUploadQuestionModal'
  import DomHelpers from '../../factories/DomHelpers'
  import QuestionnaireQuestion from '../selfassessments/copy/QuestionnaireQuestion'
  import QuestionnaireSideCard from '../vendormanagement/QuestionnaireSideCard'
  import SendQuestionnaireOptions from '../vendormanagement/SendQuestionnaireOptions'
  import ApiVendorManagement from '../../factories/ApiVendorManagement'
  import StringHelpers from '../../factories/StringHelpers'

  export default {
    props: {
      entityId: { type: [Number, String], default: null },
    },

    watch: {
      async entityId(newId) {
        if (newId) {
          await this.getQuestionnaireInfo()
        }
      },
    },

    data() {
      return {
        collapsed: false,

        isLoadingLocal: true,
        isAddingSection: false,
        isPreviewMode: true,

        activeSectionNum: null,
        activeQuestionNum: null,
        activeQuestion: null,

        activeSection: null,
        activeSectionQuestions: null,
      }
    },

    computed: {
      ...mapState({
        currentQuestionnaire: (state) =>
          state.vendormanagement.currentQuestionnaireAndSections,

        getSectionNumberFromState: (state) =>
          state.questionnaire.getSectionNum.bind(state.questionnaire),
        getQuestionNumberFromState: (state) =>
          state.questionnaire.getQuestionNum.bind(state.questionnaire),
        moveChildrenUnderParentsState: (state) =>
          state.questionnaire.moveChildrenUnderParents.bind(
            state.questionnaire
          ),
        sections: (state) =>
          state.vendormanagement.currentQuestionnaireAndSections.sections,
      }),
    },

    methods: {
      stripHtmlTags: StringHelpers.stripHtmlTags,
      titleCase: StringHelpers.titleCase,
      truncateString: StringHelpers.truncateString,

      getSectionNumber(sectionId) {
        return this.getSectionNumberFromState(sectionId)
      },
      getQuestionNumber(sectionId, questionId) {
        return this.getQuestionNumberFromState(sectionId, questionId)
      },

      moveChildrenUnderParents(questions) {
        return this.moveChildrenUnderParentsState(questions)
      },

      toggleAllExpandedStates(which) {
        let isExpanded = true
        if (which === 'collapse') isExpanded = false

        const sections = this.sections || []
        sections.forEach((s) => {
          this.$set(s, 'isExpanded', isExpanded)
        })
      },

      deselectQuestion() {
        this.activeQuestion = null
        this.activeQuestionNum = null
      },

      selectQuestion(question, sectionIndex, questionIndex) {
        this.activeQuestion = question
        this.activeSectionNum = sectionIndex + 1
        this.activeQuestionNum = questionIndex + 1
      },

      deselectSection() {
        this.activeSection = null
        this.activeSectionNum = null
      },

      selectSection(section, sectionIndex, scrollTo = true) {
        if (scrollTo) window.scrollTo(0, 0)
        this.activeSection = section
        this.activeSectionNum = sectionIndex + 1

        this.activeSectionQuestions = section.questions.sort(
          (a, b) => a.ordering - b.ordering
        )
        this.deselectQuestion()
      },

      getSectionIndex(sectionId) {
        return this.sections.findIndex(
          (s) => s.id.toString() === sectionId.toString()
        )
      },

      resetActiveInfo() {
        const currentSectionId = (this.activeSection || {}).id
        if (!currentSectionId) return

        const sectionIndex = this.getSectionIndex(currentSectionId)

        if (typeof sectionIndex === 'number') {
          this.selectSection(this.sections[sectionIndex], sectionIndex, false)
        }

        this.deselectQuestion()
      },

      gotoQuestionnaireBuilder(qId) {
        this.$router.push(`/vendor/management/qbuilder/${qId}`)
      },

      async downloadQuestions() {
        try {
          const { filename } = await ApiVendorManagement.bulkDownloadQuestions(
            this.currentQuestionnaire.id
          )

          DomHelpers.downloadUri(`/file/s3/${filename}`, filename)

          window.toastr.success(`Successfully downloaded questions.`)
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async deletedQuestion() {
        try {
          this.selectQuestion(null, null, null)
          await this.getQuestionnaireInfo()
          window.toastr.success('Successfully deleted question.')
        } catch (err) {
          window.toastr.error(`Failed to delete question. ${err.message}`)
        }
      },

      async createSection() {
        try {
          this.isAddingSection = true
          const { id } = await ApiVendorManagement.updateQuestionnaireSection({
            text: `New Section!`,
            questionnaire_id: this.entityId,
          })
          await this.getQuestionnaireInfo()

          const sectionIndex = this.getSectionIndex(id)
          this.activeSection = this.sections[sectionIndex]
          this.activeSectionNum = sectionIndex + 1
          this.createQuestion(sectionIndex)
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.isAddingSection = false
        }
      },

      async deleteSection(section) {
        try {
          await this.saveSection(section, { is_deleted: true })
          this.deselectSection()
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async saveSection(section, otherAttrs = null) {
        try {
          await ApiVendorManagement.updateQuestionnaireSection({
            ...section,
            ...otherAttrs,
          })
          this.getQuestionnaireInfo()
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          window.toastr.success('Changes Saved')
        }
      },

      async createQuestion(sectionIndex = null) {
        try {
          const sectionIdToCreateQuestionIn =
            typeof sectionIndex === 'number'
              ? this.sections[sectionIndex].id
              : this.activeSection.id
          await ApiVendorManagement.updateQuestionnaireQuestion({
            group_id: sectionIdToCreateQuestionIn,
            text: '<p>New question!</p>',
            type: 'free_form',
            ordering: this.activeSection.questions.length + 1,
          })
          await this.getQuestionnaireInfo()
          const numberOfQuestions = this.activeSection.questions.length
          this.activeQuestion =
            this.activeSection.questions[numberOfQuestions - 1]
          this.activeQuestionNum = numberOfQuestions
          window.scrollTo(0, document.body.scrollHeight)
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          window.toastr.success('Changes Saved')
        }
      },

      async updateSectionOrdering() {
        try {
          await Promise.all(
            this.currentQuestionnaire.sections.map(async (section, ind) => {
              await ApiVendorManagement.updateQuestionnaireSection({
                id: section.id,
                questionnaire_id: this.entityId,
                ordering: ind + 1,
              })
            })
          )
          await this.getQuestionnaireInfo()
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          window.toastr.success('Changes Saved')
        }
      },

      async refreshAndSelectQuestion(sectionInd, questionId) {
        await this.getQuestionnaireInfo()
        const questions = this.sections[sectionInd].questions
        const questionInd = questions.findIndex(
          (q) => q.id.toString() === questionId.toString()
        )
        const question = questions[questionInd]
        this.selectQuestion(question, sectionInd, questionInd)
      },

      async updateQuestionOrdering(questions, updateFromDrag = false) {
        try {
          this.moveChildrenUnderParents(questions)
          await Promise.all(
            questions.map(async (question, ind) => {
              await ApiVendorManagement.updateQuestionnaireQuestion({
                id: question.id,
                ordering: ind + 1,
              })
            })
          )
          await this.getQuestionnaireInfo()
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          if (updateFromDrag) window.toastr.success('Changes Saved')
        }
      },

      async getQuestionnaireInfo() {
        await Promise.all([
          this.$store.dispatch('getQuestionnaireAndSections', this.entityId),
          this.$store.dispatch('getVendorbaseQuestionnaire', this.entityId),
        ])
        this.resetActiveInfo()
      },
    },

    async created() {
      try {
        this.isLoadingLocal = true
        if (!this.entityId) return (this.isLoadingLocal = false)

        await this.getQuestionnaireInfo()

        // select question if one isn't selected when we first hit the page
        if (!(this.activeSectionNum && this.activeQuestionNum)) {
          const firstSection = this.sections && this.sections[0]
          if (firstSection) {
            const firstQuestion =
              firstSection &&
              this.sections[0].questions &&
              this.sections[0].questions[0]
            if (firstQuestion) {
              this.selectQuestion(firstQuestion, 0, 0)
            }
          }
        }
      } catch (err) {
        window.toastr.error(err.message)
      } finally {
        this.isLoadingLocal = false
      }
    },

    components: {
      BulkUploadQuestionModal,
      QuestionnaireQuestion,
      QuestionnaireSideCard,
      SendQuestionnaireOptions,
    },
  }
</script>

<style lang="scss">
  .question-editor-container {
    flex: 1;

    .circle-container {
      flex: 0;
    }
  }

  .question-container {
    overflow: hidden;
  }

  .add-question-btn {
    min-width: max-content;
  }

  .hoverable {
    &:hover {
      background-color: rgba(#000, 0.05);
    }
  }

  .selected {
    background-color: rgba(49, 142, 203, 0.1);
  }

  .section-name-input {
    font-family: 'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, sans-serif;
    font-weight: 400;
    color: rgb(0, 28, 99);
    font-size: 20px;
  }
</style>
