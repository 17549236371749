<template lang="pug">
  div
    div
      strong Comment Added
    comment-card(
      :comment="data",
      @update="update",
      @delete="deleteComment")
</template>

<script>
  export default {
    props: {
      // Same thing as 'comment' prop in ./CommentCard component
      data: { type: Object, required: true }
    },

    methods: {
      update(commentRecord, mentions) {
        this.$emit('updateComment', {
          id: commentRecord.id,
          comment: commentRecord.comment,
          mentions: mentions
        })
      },

      deleteComment(commentRecord) {
        this.$emit('deleteComment', commentRecord.id)
      }
    }
  }
</script>