<template lang="pug">
  div.progress-stepper.d-flex.align-items-center
    div.full-step.d-flex.align-items-center.clickable(v-for="(step, index) in steps",:class="setCompleteClass(step)",@click="clickStep(step, index)")
      div.triangle-left
      div.stepper-step.d-flex.align-items-center.justify-content-center.text-center(:class="setActiveClass(step)")
        h4.ml-0.mt-0.mb-0.mr-1 {{ index + 1 }}.
        div
          div {{ step.title || 'Step Title' }}
          div.small(v-if="step.subtitle") {{ step.subtitle }}
        div.ml-2
          i.fal(:class="getIconClass(step)")
      div.triangle-right.mr-1
</template>

<script>
  export default {
    props: {
      /**
       * steps:
       * Array of objects containing information about the step
       * Properties:
       *  - title:
       *  - subtitle:
       *  - active <Boolean>: whether this step is currently being worked on
       *  - complete <Boolean>: whether the step is complete or not
       *  - icon <String>: What font-awesome icon to represent for the step
       */
      steps: { type: Array }
    },

    data() {
      return {
        selectedTagId: null
      }
    },

    methods: {
      getIconClass(step) {
        if (step.icon)
          return step.icon

        if (step.complete)
          return 'fa-check-circle'

        return 'fa-minus-circle'
      },

      setActiveClass(step) {
        const classes = []

        if (step.active)
          classes.push('stepper-active')

        return classes
      },

      setCompleteClass(step) {
        const classes = []

        if (step.complete)
          classes.push('step-complete')

        return classes
      },

      clickStep(step, index) {
        this.$emit('clickStep', step, index)
      }
    }
  }
</script>
