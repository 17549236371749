<template lang="pug">
  div#file-drag-drop
    div.py-3.d-flex.align-items-center.justify-content-center.file-drop-form.hoverable(
      :class="clickable || !dragAndDropCapable ? 'clickable' : ''" 
      ref="fileform"
      :style="`height: ${dropBoxHeight}`"
    )
      span.drop-files {{defaultMessage}}
    div.file-list.m-auto.p-3.d-flex.align-items-center(v-for="(file, index) in files")
      img.preview(:ref="`preview-${index}`") 
      div.mx-2(style="width: fit-content") {{file.name}}
      progress.w-100.mr-2.ml-auto(v-if="uploading" max="100" :value.prop="uploadPercentage[index]")
      a.w-10.mr-0.ml-auto.remove(@click="removeFile(index)") #[i.fal.fa-times.text-danger.ml-1.clickable]
    input(type="file" ref="fileSelect" :multiple="allowMulti" :accept="acceptedFileTypes" style="position: fixed; top: -100%;" @change="handleSelectEvent")
    div.d-flex.justify-content-center
      button.mt-3.mr-auto.ml-0.btn.btn-small.btn-primary(v-if="!instantUpload && !keepLocal" @click="uploadFiles" :disabled="files.length == 0 || uploading") Upload

</template>

<script>
  import AwsUrlSigner from '../../factories/AwsUrlSigner'
  import $ from 'jquery'

  export default {
    props: {
      size: { type: String, default: 'sm' },
      instantUpload: { type: Boolean, default: true },
      removeAfterUpload: { type: Boolean, default: true },
      allowMulti: { type: Boolean, default: true },
      clickable: { type: [String, Boolean], default: true },
      defaultMessage: { type: String, default: 'Drop files here to upload' },
      maxFileSize: { type: Number, default: 20480 }, // Size in MegaBytes
      acceptedFileTypes: { type: String, default: null }, // ex. "image/png, .jpeg"
      isReport: { type: Boolean, default: false },
      isExtension: { type: Boolean, default: false },
      keepLocal: { type: Boolean, default: false }, // Used to prevent upload to S3. Component emits file for use in parent
    },

    data() {
      return {
        dragAndDropCapable: false,
        files: [],
        uploadPercentage: {},
        uploading: false,
      }
    },

    computed: {
      dropBoxHeight() {
        switch (this.size) {
          case 'xl':
            return '200px'
          case 'lg':
            return '150px'
          case 'md':
            return '100px'
          case 'sm':
          default:
            return '50px'
        }
      },
    },

    methods: {
      determineDragAndDropCapable() {
        // Create a test element to see if the `draggable` event is in the element
        // or the `ondragstart` and `ondrop` events are in the element. If
        // they are, then we have what we need for dragging and dropping files.
        // also check to see if the window has `FormData` and `FileReader` objects
        // present so we can do our AJAX uploading
        var div = document.createElement('div')
        return (
          ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
          'FormData' in window &&
          'FileReader' in window
        )
      },

      handleSelectEvent() {
        for (let i = 0; i < this.$refs.fileSelect.files.length; i++) {
          const file = this.$refs.fileSelect.files[i]
          this.addFile(file)
        }
        if (this.instantUpload && !this.keepLocal) this.uploadFiles()
      },

      handleDropEvent(e) {
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
          const file = e.dataTransfer.files[i]
          this.addFile(file)
        }
        if (this.instantUpload && !this.keepLocal) this.uploadFiles()
      },

      addFile(file) {
        if (!this.verifyFile(file)) return
        if (!this.allowMulti) {
          this.removeAllFiles()
        }
        const arrLength = this.files.push(file)
        this.$emit('fileAdded', file)
        this.$set(this.uploadPercentage, arrLength - 1, 0)
        this.getImagePreviews()
      },

      verifyFile(file) {
        if (this.maxFileSize && file.size > this.maxFileSize * 10e5) {
          window.toastr.error(
            `File exceeeds maximum upload size of ${this.maxFileSize} MB`
          )
          return false
        }

        if (this.acceptedFileTypes) {
          const acceptedFileTypesArray = Array.isArray(this.acceptedFileTypes)
            ? this.acceptedFileTypes
            : this.acceptedFileTypes.split(',').map(function (type) {
                return type.trim()
              })
          const fileExtension = '.' + file.name.split('.')[1]
          if (
            !(
              acceptedFileTypesArray.includes(file.type) ||
              acceptedFileTypesArray.includes(fileExtension)
            )
          ) {
            window.toastr.error(
              `File must be of type: ${this.acceptedFileTypes}`
            )
            return false
          }
        }

        return true
      },

      handleProgress(progressEvent, index) {
        this.uploadPercentage[index] = parseInt(
          Math.round((100 * progressEvent.loaded) / progressEvent.total)
        )
      },

      getImagePreviews() {
        this.files.forEach((file, index) => {
          if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
            let reader = new FileReader()

            //Once the file is loaded, update the ref source
            reader.addEventListener(
              'load',
              function () {
                this.$refs[`preview-${index}`][0].src = reader.result
              }.bind(this),
              false
            )

            /*
              Read the data for the file in through the reader. When it has
              been loaded, we listen to the event propagated and set the image
              src to what was loaded from the reader.
            */
            reader.readAsDataURL(file)
          } else if (/\.pdf$/i.test(file.name)) {
            this.$nextTick(function () {
              this.$refs[`preview-${index}`]
                ? (this.$refs[`preview-${index}`][0].src =
                    '../../../public/images/pdf.svg')
                : null
            })
          } else if (/\.(doc?x)?$/i.test(file.name)) {
            this.$nextTick(function () {
              this.$refs[`preview-${index}`]
                ? (this.$refs[`preview-${index}`][0].src =
                    '../../../public/images/doc.svg')
                : null
            })
          } else if (/\.(xls?x|csv)?$/i.test(file.name)) {
            this.$nextTick(function () {
              this.$refs[`preview-${index}`]
                ? (this.$refs[`preview-${index}`][0].src =
                    '../../../public/images/spreadsheet.svg')
                : null
            })
          } else if (/\.(ppt?x)?$/i.test(file.name)) {
            this.$nextTick(function () {
              this.$refs[`preview-${index}`]
                ? (this.$refs[`preview-${index}`][0].src =
                    '../../../public/images/ppt.svg')
                : null
            })
          } else if (/\.(zip|rar|7z)?$/i.test(file.name)) {
            this.$nextTick(function () {
              this.$refs[`preview-${index}`]
                ? (this.$refs[`preview-${index}`][0].src =
                    '../../../public/images/zip.svg')
                : null
            })
          } else if (/\.(txt|log)?$/i.test(file.name)) {
            this.$nextTick(function () {
              this.$refs[`preview-${index}`]
                ? (this.$refs[`preview-${index}`][0].src =
                    '../../../public/images/txt.svg')
                : null
            })
          } else {
            this.$nextTick(function () {
              this.$refs[`preview-${index}`]
                ? (this.$refs[`preview-${index}`][0].src =
                    '../../../public/images/file.svg')
                : null
            })
          }
        })
      },

      removeFile(fileIndex) {
        this.files.splice(fileIndex, 1)
        this.$emit('fileRemoved')
      },

      /** Used by parent component as ref  */
      removeAllFiles() {
        this.files = []
        this.$refs.fileSelect.value = null
      },

      async uploadFiles(e = null) {
        if (e) e.preventDefault()
        if (!this.files || this.files.length === 0) return
        this.uploading = true
        try {
          for (let i = 0; i < this.files.length; i++) {
            const file = this.files[i]
            this.fileProcessing()
            // upload file to AWS S3
            let signingUrl = '/api/1.0/aws/url/putObject/get'
            if (this.isReport) {
              signingUrl += '?isReport=true'
            } else if (this.isExtension) {
              signingUrl += '?isExtension=true'
            }
            const response = await AwsUrlSigner.sendFile(
              file,
              {
                signingURL: () => signingUrl,
                sendFileToServer: false,
                withCredentials: true,
                onUploadProgress: (event) => this.handleProgress(event, i),
              },
              false
            )
            if (!response.success) return this.s3UploadError(response.message)
            this.s3UploadSuccess(response.message)
          }
          if (this.removeAfterUpload) this.removeAllFiles()
        } catch (err) {
          this.s3UploadError('Error uploading files')
        } finally {
          this.uploading = false
        }
      },

      s3UploadSuccess(fullFileUrl) {
        const fileSplit = fullFileUrl.split('/')
        const filename = fileSplit[fileSplit.length - 1]
        this.$emit('added', [null, { filename }])
      },

      s3UploadError(...args) {
        this.$emit('error', args)
      },

      fileProcessing(...args) {
        this.$emit('processing', args)
      },

      triggerFileSelectBox(event) {
        event.preventDefault()
        this.$refs.fileSelect.click()
      },
    },

    mounted() {
      this.dragAndDropCapable = this.determineDragAndDropCapable()
      if (this.dragAndDropCapable) {
        // Listen to all of the drag events and bind an event listener to each
        // for the fileform.
        const dragEvents = [
          'drag',
          'dragstart',
          'dragend',
          'dragover',
          'dragenter',
          'dragleave',
          'drop',
        ]
        dragEvents.forEach(
          function (evt) {
            // prevent browser from opening file
            this.$refs.fileform.addEventListener(
              evt,
              function (e) {
                e.preventDefault()
                e.stopPropagation()
              }.bind(this),
              false
            )
          }.bind(this)
        )

        this.$refs.fileform.addEventListener('drop', this.handleDropEvent)
      }

      if (this.clickable || !this.dragAndDropCapable) {
        this.$refs.fileform.addEventListener('click', this.triggerFileSelectBox)
        if (typeof this.clickable === 'string') {
          $(this.clickable).on('click', this.triggerFileSelectBox)
        }
      }
    },
  }
</script>
<style scoped lang="scss">
  .file-drop-form {
    border-style: dashed !important;
    border-color: lightgray;
    border-width: 2px;
    border-radius: 4px;
    display: block;
  }

  .file-list {
    border-bottom: 1px solid #ddd;
  }

  .file-list img[src] {
    height: 50px;
  }
  .file-list img[src='/images/file.png'] {
    // If no image to display, keep preview height small
    height: 10px;
  }

  // progress {
  //   width: 70%;
  // }
</style>
