<template lang="pug">
  b-popover(ref="independence-popover",:target="`independence-icon-${parentUid}`",triggers="click")
    template(slot="title") 
      div.d-flex.align-items-center
          h6.mb-0.mr-4 Independence Update
          div.ml-auto
            i.fal.fa-times.clickable(@click="closePopover")
    div.mb-2
      | You {{ (isIndependent) ? "assert" : "haven't asserted" }} your independence
      | with {{ team.name }}.
    hr
    div.form-group.m-0.d-flex.justify-content-center
      div.custom-control.custom-toggle
        input.custom-control-input(
          @change="updateIndependence"
          type="checkbox"
          :id="`independence-toggle-${parentUid}`"
          :name="`independence-toggle-${parentUid}`"
          v-model="isIndependent")
        label.custom-control-label(:for="`independence-toggle-${parentUid}`") Update independence
</template>

<script>
  import ApiAssessments from '../../factories/ApiAssessments'
  import { sleep } from '../../factories/Utilities'

  export default {
    props: {
      independence: { type: Boolean },
      parentUid: { type: [Number, String] },
      team: { type: Object },
    },

    data() {
      return {
        isIndependent: this.independence,
      }
    },

    methods: {
      async updateIndependence() {
        try {
          await ApiAssessments.updateIndependence(
            this.isIndependent,
            this.team.id
          )
          window.toastr.success(`Successfully updated independence!`)
          this.$emit('update', this.isIndependent)

          await sleep(250)
          this.closePopover()
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      closePopover() {
        this.$refs['independence-popover'].$emit('close')
      },
    },
  }
</script>
