<template lang="pug">
  div.row
    div.col-lg-12.mb-4
      div.card.sticky-card
        div.card-header.border-bottom
          div.d-flex.align-items-center
            h6.m-0 {{ currentTeam.name }}'s Compliance Program Checklist
        
        div(v-if="isLoading")
          loader
        div.card-body.p-0.pt-3.mb-4(v-else)
          div.card.no-shadow.scope.border.mx-4.my-2.clickable(
            v-for="(item, index) in checklistItems",
            @click="goToItem(item.route)")
              div.card-body.py-3.pl-3
                div.d-flex.align-items-center
                  div.d-flex.align-items-center.justify-content-center.data-header-circle.mr-2(:id="`success-circle-${index}`")
                    div.card.card-small.no-shadow.justify-content-center.align-items-center.border.border-secondary.size-30.bg-light
                      div(:class="item.completed ? 'fal fa-check text-success font-weight-bold' : '' ")
                  b-tooltip(:target="`success-circle-${index}`") {{ item.tooltip }}
                  div
                    h6.m-0 {{ item.name }} 
                      | #[i.small.ml-1.text-warning(v-if="!item.completed" :id="`how-to-complete-${index}`") Incomplete]
                      | #[i.small.ml-1.text-success(v-else :id="`how-to-complete-${index}`") Complete]
                    div.font-italic(v-if="!item.completed") {{ item.description }}
                    b-tooltip(:target="`how-to-complete-${index}`") {{ item.tooltip }}
</template>

<script>
  import { mapState } from 'vuex'
  import ApiChecklist from '../../factories/ApiChecklist'

  export default {
    data() {
      return {
        isLoading: true,
        isChecklistComplete: false,
        checklistItems: [],
      }
    },

    computed: mapState({
      currentTeam: (state) => state.session.current_team,
    }),

    methods: {
      async goToItem(route) {
        this.$router.push(route)
      },

      async refreshData() {
        const {
          isChecklistComplete,
          checklistItems,
        } = await ApiChecklist.getChecklistItems()
        this.isChecklistComplete = isChecklistComplete
        this.checklistItems = checklistItems.sort((a, b) => a.order - b.order)
      },
    },

    async mounted() {
      await this.refreshData()
      this.isLoading = false
    },
  }
</script>
