<template lang="pug">
  tbody.border-0
    tr
      td
        label.m-0 Role
        input.form-control.form-control-vsm(v-model="contact.contact_name")
      td.border-left.pl-2(colspan="100%")
        div.d-flex.justify-content-between
          label.m-0 User
          a.pb-2(@click="$emit('closeForm')")
            i.fa.fa-times.text-danger
        div.d-flex.align-items-center
          typeahead-input-new.w-100(
            placeholder="Search for user...",
            :options="teamUsers",
            :showPropFunction="u => u.name || u.username_email",
            :optionSearchFilter="(s, u) => (u.name || u.username_email || '').toLowerCase().includes(s.toLowerCase())",
            @input="(user) => contact.user_id = user.id")
          button.ml-1.btn.btn-vsm.btn-primary.nowrap(@click="createContact()") Save Key Contact
</template>

<script>
  import { mapState } from 'vuex'
  import ApiAssessments from '../../factories/ApiAssessments'

  export default {
    props: {
      // projectId: { type: [Number, String] },
      category: { type: [String] },
    },

    data() {
      return {
        contact: {
          // group_name: null,
          contact_name: null,
          user_id: null,
        },
      }
    },

    computed: {
      ...mapState({
        teamUsers: (state) => state.team.users,
      }),
    },

    methods: {
      async createContact(evt) {
        try {
          if (evt) evt.preventDefault()

          if (!this.contact.contact_name)
            return window.toastr.error(
              `Please enter at least a role to proceed.`
            )

          const newContact = {
            ...this.contact,
            group_name: this.category,
          }
          await ApiAssessments.updateKeyContact(newContact)
          window.toastr.success(`Successfully created your key contact!`)

          this.$emit('created', newContact)
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },
  }
</script>
