<template lang="pug">
  component(
    :is="getComponent",
    :value="value",
    :choices="choices",
    :multiple="type === 'multiple_choice_multiple'",
    @input="updateValue",
    @change="changeEvent")
</template>

<script>
  import FreeForm from './FreeForm'
  import MultipleChoice from './MultipleChoice'
  import MultipleChoiceMultiple from './MultipleChoiceMultiple'
  import StringHelpers from '../../../../factories/StringHelpers'

  export default {
    props: {
      type: { type: String, required: true },
      choices: { type: Array, required: true },
      value: { type: Array, default: null },
    },

    computed: {
      getComponent() {
        return this.typeComponentMap[this.type] || 'FreeForm'
      },
    },

    data() {
      return {
        typeComponentMap: {
          free_form: FreeForm,
          multiple_choice_single: MultipleChoice,
          multiple_choice_multiple: MultipleChoiceMultiple,
        },
      }
    },

    methods: {
      titleCase: StringHelpers.titleCase,

      updateValue(newVal) {
        this.$emit('input', newVal)
      },

      changeEvent(newVal) {
        this.$emit('change', newVal)
      },
    },

    components: {
      FreeForm,
      MultipleChoice,
      MultipleChoiceMultiple
    },
  }
</script>
