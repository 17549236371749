<template lang="pug">
  div.alert.alert-accent.alert-dismissible.fade.show.mb-0(role='alert',v-if="!!$store.state.mainNotification && $store.state.isLoggedIn")
    button.close(type='button',data-dismiss='alert',aria-label='Close',@click="dismissMainNotification")
      span(aria-hidden='true') ×
    i.fal.fa-info.mx-2
    strong {{ $store.state.mainNotification }}
</template>

<script>
  import { mapState } from 'vuex'
  import ApiGlobal from '../factories/ApiGlobal'

  export default {
    computed: mapState({
      isLoading: state => state.isLoading,
      isLoggedIn: state => state.isLoggedIn
    }),

    methods: {
      async dismissMainNotification() {
        await ApiGlobal.dismissMainNotification()
      },

      async getNotificationAfterLoading() {
        if (this.$store.state.isLoggedIn) {
          const res = await ApiGlobal.getMainNotification()
          this.$store.commit('SET_MAIN_NOTIFICATION', res.notification)
        }
      }
    },

    async mounted() {
      await this.getNotificationAfterLoading()
    }
  }
</script>
