<template lang="pug">
  div
    div(:id="`rr-residual-risk-${_uid}`")
      risk-register-data-circle(
        type="risk",
        :value="residualRisk || '-'",
        :header="header",
        :size="size")

    b-tooltip(:target="`rr-residual-risk-${_uid}`")
      div(v-html="glossary.RESIDUAL_RISK")
</template>

<script>
  import { glossary } from '../../constants/glossary'

  export default {
    props: {
      residualRisk: { type: [Number, String], required: true },
      size: { type: [Number, String], default: 40 },
      header: { type: String, default: 'Residual Risk' },
    },

    data() {
      return {
        glossary: glossary.riskRegister.risks,
      }
    },
  }
</script>
