<template lang="pug">
div.w-100
  div.row.small-gutters
    div.col-md-4.mb-1
      label.mb-0 Frequency
      select.form-control(v-model="recurringOptions.freq",@change="updateByDay")
        option(
          v-for="frequency in frequencyOptions",
          :value="frequency.value") {{ frequency.text }}
    div.col-md-4.mb-1
      label.mb-0
        | Every {{ recurringOptions.interval }}
        | {{ (frequencyOptions.find(o => o.value === recurringOptions.freq) || {}).unit || 'year' }}(s)
      input.form-control(v-model.number="recurringOptions.interval",type="number",@change="updateOptions")
    div.col-md-4.mb-1(v-if="showUntil")
      datepicker-shards(
        :show-clear="true",
        label="Until",
        :input-id="'recurring-options-until'",
        v-model="recurringOptions.until",
        @input="updateOptions")
  div.row.m-1
    div.col-md-12(v-if="!this.isWeeklyOption")
      div.custom-control.custom-radio
        input.custom-control-input(
          :id="`by-day-default-${_uid}`",
          :name="`by-day-default-${_uid}`",
          type="radio",
          :value="true",
          v-model="byDayRadio",
          @change="updateByDay")
        label.custom-control-label(:for="`by-day-default-${_uid}`") {{ byDayDefaultText }}
      div.custom-control.custom-radio
        input.custom-control-input(
          :id="`by-day-option-${_uid}`",
          :name="`by-day-option-${_uid}`",
          type="radio",
          :value="false",
          v-model="byDayRadio",
          @change="updateByDay")
        label.custom-control-label(:for="`by-day-option-${_uid}`") {{ byDayOptionText}}
    div.col-md-12.my-3(v-else)
      div.row.no-gutters
        div.col(v-for="(day, i) in weekdays")
          div.custom-control.custom-checkbox(style="padding-left: 1.2rem;")
            input.custom-control-input(
              :id="`${_uid}_${i}`",
              type="checkbox",
              v-model="day.state",
              @change="updateByWeekday")
            label.custom-control-label(:for="`${_uid}_${i}`") {{ day.text }}
  div.row  
    div.col-12
      i.small {{  overviewIntroText }} {{ rruleText }} {{ timeToDisplay ? ` at ${timeToDisplay}` : ''}}
</template>

<script>
  import Utilities from '@risk3sixty/utilities'

  const RRule = Utilities.Dates.RRule
  const moment = Utilities.Dates.moment

  // RRule weekday integer equivalents
  const rruleWeekdays = Utilities.Dates.rruleWeekdays

  // Moment weekday to RRule weekday equivalents
  const rruleWeekdaysFromMomentWeekdays =
    Utilities.Dates.rruleWeekdaysFromMomentWeekdays

  export default {
    props: {
      value: {
        type: Object,
        required: true,
      },
      date: {
        type: [Date, String],
      },
      showUntil: {
        type: Boolean,
        default: true,
      },
      timeToDisplay: {
        type: String,
        default: null,
      },
      overviewIntroText: {
        type: String,
        default: 'This event will recur',
      },
    },

    watch: {
      date() {
        this.updateByDay()
      },

      value: {
        handler(newOptions) {
          this.recurringOptions = {
            freq: newOptions.freq || RRule.YEARLY,
            interval: newOptions.interval || 1,
            until: newOptions.until ? new Date(newOptions.until) : null,
            bymonth: newOptions.bymonth || null,
            bymonthday: newOptions.bymonthday || null,
            byweekday: newOptions.byweekday
              ? this.convertByWeekDays(newOptions.byweekday)
              : null,
          }
        },
        deep: true,
        immediate: true,
      },
    },

    data() {
      return {
        recurringOptions: {},
        frequencyOptions: Utilities.Dates.frequencyOptions.filter(
          (o) => o.unit !== 'day'
        ),

        byDayRadio: this.value.bymonthday ? true : false,
        weekdays: [],
      }
    },

    computed: {
      rruleText() {
        const rrule = new RRule({
          ...this.recurringOptions,
          dtstart: this.recurringOptions.dtstart
            ? new Date(this.recurringOptions.dtstart)
            : null,
          until: this.recurringOptions.until
            ? new Date(this.recurringOptions.until)
            : null,
        })
        const rruleText = rrule.toText()
        return this.capitalize(rruleText)
      },

      isYearlyOption() {
        return this.recurringOptions.freq === RRule.YEARLY
      },

      isWeeklyOption() {
        return this.recurringOptions.freq === RRule.WEEKLY
      },

      momentDtstart() {
        return this.date
          ? moment(this.date)
          : moment(this.recurringOptions.dtstart)
      },

      byDayDefaultText() {
        const rrule = new RRule({
          freq: this.recurringOptions.freq,
          interval: this.recurringOptions.interval,
          bymonth: this.isYearlyOption
            ? parseInt(this.momentDtstart.utc().format('M'))
            : null,
          bymonthday: parseInt(this.momentDtstart.utc().format('D')),
        })
        const rruleText = rrule.toText()
        return this.capitalize(rruleText)
      },

      byDayOptionText() {
        const rrule = new RRule({
          freq: this.recurringOptions.freq,
          interval: this.recurringOptions.interval,
          bymonth: this.isYearlyOption
            ? parseInt(this.momentDtstart.utc().format('M'))
            : null,
          byweekday: rruleWeekdaysFromMomentWeekdays[
            this.momentDtstart.utc().format('d')
          ].nth(this.getNthWeekdayOfMonth(this.momentDtstart)),
        })
        const rruleText = rrule.toText()
        return this.capitalize(rruleText)
      },
    },

    methods: {
      convertByWeekDays(byWeekDay) {
        return Utilities.Dates.rruleConvertByWeekDays(byWeekDay)
      },

      resetWeekdays() {
        // Default weekdays values all set to false
        let defaultWeekdays = [
          { text: 'Mon', state: false },
          { text: 'Tue', state: false },
          { text: 'Wed', state: false },
          { text: 'Thu', state: false },
          { text: 'Fri', state: false },
          { text: 'Sat', state: false },
          { text: 'Sun', state: false },
        ]

        // If multiple weekdays, find any that are already set in
        // reoccuring options, and set state to true for checkboxes
        if (Array.isArray(this.recurringOptions.byweekday)) {
          this.recurringOptions.byweekday.forEach((day) => {
            const ind = rruleWeekdays.findIndex(
              (val) => val.weekday === day.weekday
            )
            defaultWeekdays[ind].state = true
          })
        }

        return (this.weekdays = defaultWeekdays)
      },

      updateOptions() {
        if (this.isWeeklyOption) {
          this.recurringOptions.bymonthday = null
          this.recurringOptions.bymonth = null
        }

        this.$emit('input', this.recurringOptions)
      },

      updateByDay() {
        this.recurringOptions.bymonth = this.isYearlyOption
          ? parseInt(this.momentDtstart.utc().format('M'))
          : null

        if (this.byDayRadio) {
          this.recurringOptions.bymonthday = parseInt(
            this.momentDtstart.utc().format('D')
          )
          this.recurringOptions.byweekday = null
        } else {
          this.recurringOptions.bymonthday = null
          this.recurringOptions.byweekday = rruleWeekdaysFromMomentWeekdays[
            this.momentDtstart.utc().format('d')
          ].nth(this.getNthWeekdayOfMonth(this.momentDtstart))
        }

        this.updateOptions()
      },

      updateByWeekday() {
        // Find all the RRule weekday objects that are checked in checkboxes
        var rruleWeekdayArray = rruleWeekdays.filter((item, index) => {
          return this.weekdays[index].state === true
        })

        // If empty set to null (empty array in RRule defaults to every day)
        rruleWeekdayArray =
          rruleWeekdayArray.length === 0 ? null : rruleWeekdayArray

        // Set byweekday options to array of RRule weekdays
        this.recurringOptions = {
          ...this.recurringOptions,
          byweekday: rruleWeekdayArray,
        }

        this.updateOptions()
      },

      getNthWeekdayOfMonth(originalDate) {
        // Get count of this specific day of week, up until the given day (i)
        // (ex. 3rd Tuesday on a month with 4 Tuesdays, i = 3)
        // (ex. 4th Wednesday on a month with 4 Wednesday, i = 4)
        let weekAgo = originalDate.clone().subtract(1, 'week')
        let i = 1

        while (originalDate.month() === weekAgo.month()) {
          weekAgo = weekAgo.subtract(1, 'week')
          i++
        }

        // Get count of this specific day of week, after the given day, for the rest of the month (j)
        // (ex. 3rd Tuesday on a month with 4 Tuesdays, j = 1)
        // (ex. 4th Wednesday on a month with 4 Wednesday, j = 0)
        let nextWeek = originalDate.clone().add(1, 'week')
        let j = 0

        while (originalDate.month() === nextWeek.month()) {
          nextWeek = nextWeek.add(1, 'week')
          j++
        }

        // If we are over the 2nd occurance, calculate the count from end of month, instead of from beginning of month
        // (ex. 3rd Tuesday on a month with 4 Tuesdays becomes 2nd last Tuesday of the month)
        // (ex. 4th Wednesday on a month with 4 Wednesday becomes last Wednesday of the month)
        // * Edge case: Only calculate the count from end of month if there are less than 2 more occurances of that day in the month (j)
        // (ex. 3rd Thruday on a month with 5 Thursdays stays 3rd Thursday of the month instead of 3rd last Thursday of the month)
        if (i > 2 && j < 2) {
          i = i - (i + j) - 1
        }

        return i
      },

      capitalize(s) {
        return Utilities.Strings.capitalize(s)
      },
    },

    created() {
      const objIsEmpty =
        Object.keys(this.value).length === 0 &&
        this.value.constructor === Object
      if (!objIsEmpty)
        this.recurringOptions = Object.assign(this.recurringOptions, this.value)

      // Converting "byweekday" value into valid "Weekday" object in RRule
      if (this.recurringOptions.byweekday)
        this.recurringOptions.byweekday = this.convertByWeekDays(
          this.recurringOptions.byweekday
        )

      this.$emit('input', this.recurringOptions)
      this.resetWeekdays()
    },
  }
</script>
