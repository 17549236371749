<template lang="pug">
  div
    div(:id="`mapping-selector-${_uid}`") N/A
    b-tooltip(
      :target="`mapping-selector-${_uid}`") {{ value }}
</template>

<script>
  export default {
    props: {
      value: { type: [Number, String], default: null },
    },
  }
</script>
