<template lang="pug">
  b-popover(
    :target="target"
    boundary="viewport"
    :ref="target")
      template(slot="title")
        div.d-flex.align-items-center
          h6.m-0 {{ header }}
          div.ml-auto
            i.fal.fa-times.clickable(@click="confirm('cancel')")
      div(v-html="text")
      slot

      div.mt-2.d-flex.justify-content-center
        button.btn-vsm.btn-primary.mr-2(@click="confirm('cancel')") Cancel
        button.btn-vsm.btn-danger(@click="confirm('confirm')") Confirm
</template>

<script>
  export default {
    props: {
      target: { type: String, required: true },
      text: { type: String, required: true },
      header: { type: String, default: 'Confirm' },
    },

    methods: {
      confirm(action) {
        this.$emit(action)
        this.$root.$emit('bv::hide::popover', this.target)
      },
    },
  }
</script>
