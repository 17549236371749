<template lang="pug">
  div
    div.multiple-choice-option.clickable.pull-left.d-inline-block.border.rounded(
      v-for="opt in choices",
      :class="hasOptionClass(opt.id)",
      @click="selectValue(opt)")
        div.d-flex.align-items-center
          //- i.fa.mr-2(:class="hasOptionClass(opt.id) ? 'fa-check-circle' : 'fa-minus-circle'")
          | {{ opt.text }}
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    props: {
      value: { type: Array, default: null },
      choices: { type: Array, default: null },
    },

    computed: mapState({
      canAnswerQuestionnaire: (_, getters) => getters.canAnswerQuestionnaire,
    }),

    methods: {
      update(newVal) {
        this.$emit('input', newVal)
        this.$emit('change', newVal)
      },

      hasOptionClass(choiceId) {
        return (
          (this.value || []).map((o) => o.choice_id).includes(choiceId) &&
          'active'
        )
      },

      selectValue(choice) {
        if (!this.canAnswerQuestionnaire) return
        // return window.toastr.error(`
        //   This question is currently read only and cannot be updated. This could
        //   be due to the questionnaire being locked or this question has already
        //   been reviewed by the reviewer. Contact your administrator if you believe this
        //   is a mistake.
        // `)

        const curVal = this.value || []
        const shouldRemoveVal = curVal
          .map((v) => v.choice_id)
          .includes(choice.id)
        const newValueAry = [{ choice_id: choice.id }]
        let newVal = newValueAry

        // remove dups
        let valCache = {}
        newVal = newVal.filter((v) => {
          if (valCache[v.choice_id]) return false

          valCache[v.choice_id] = true
          return true
        })

        if (shouldRemoveVal)
          newVal = newVal.filter((v) => v.choice_id !== choice.id)

        this.update(newVal)
      },
    },
  }
</script>
