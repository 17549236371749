<template lang="pug">
  div.scroll-after-fold
    div(v-if="isLoadingLocal")
      loader
    div.card.card-small(v-else)
      div.card-header.border-bottom
        div.d-flex.align-items-start
          h5.overflow-ellipses(v-html="questionnaire.name")
          div.ml-auto.d-flex.align-items-center
            div.d-flex.align-items-center.justify-content-center.action-icon-circle-container.mr-1(
              v-if="showRemindButton")
                a#questionnaire-reminder.text-info
                  i.fal.fa-paper-plane
                  b-tooltip(target="questionnaire-reminder") Send Reminder
                confirmation-popover(
                  target="questionnaire-reminder",
                  header="Confirm Send Reminder",
                  text="Are you sure you want to remind all vendor contacts? This will resend them all an email with a link to the questionnaire.",
                  @confirm="remindVendorOfQuestionnaire")
            div.d-flex.align-items-center.justify-content-center.action-icon-circle-container
              a#delete-questionnaire.text-danger
                i.fal.fa-trash-alt
              confirmation-popover(
                target="delete-questionnaire",
                header="Confirm Delete Questionnaire",
                text="Are you sure you want to delete this questionnaire?",
                @confirm="deleteQuestionnaire")
            //- div.d-flex.align-items-center.justify-content-center.action-icon-circle-container.ml-1
            //-   router-link(:to="`/vendor/management/questionnaires`")
            //-     i.fal.fa-minus
        div.mt-2(v-html="questionnaire.description")
        div.mt-2(v-if="questionnaire.review")
          router-link(:to="`/vendor/management/reviews/${questionnaire.review.id}/questionnaires`")
            b Review: 
            | {{ questionnaire.review.title }}
        div.mt-2.small.d-flex.align-items-center
          //- router-link.mr-1.btn.btn-sm.btn-primary.btn-circle(:id="`goto-qbuilder-button-sidecard-${mutableQuestionnaire.id}`",v-if="showEditButton", :to="`/vendor/management/qbuilder/${mutableQuestionnaire.id}`")
          //-   i.fal.fa-comment-alt-edit
          //- b-tooltip(:target="`#goto-qbuilder-button-sidecard-${mutableQuestionnaire.id}`")
          //-   | Edit Questionnaire

          pill-inline.wide(
            v-if="questionnaire.is_template",
            background="grey",
            text="Template",
            :show-close="false",
            :is-hollow="true")
          div.d-flex.align-items-center(v-else)
            //- div.d-flex.align-items-center
            //-   a.mr-1.btn.btn-sm.btn-success.btn-circle(:id="`review-answers-button-sidecard-${mutableQuestionnaire.id}`",v-if="showReviewButton",:href="reviewQuestionnaireUrl")
            //-     i.fal.fa-comment-alt-lines
            //-   b-tooltip(:target="`#review-answers-button-sidecard-${mutableQuestionnaire.id}`")
            //-     | Review Questionnaire

            questionnaire-status-updater(
              :text="getStatus(mutableQuestionnaire.status).text",
              :passed-record="mutableQuestionnaire",
              @updated="updateAndRefresh")
            b-tooltip(
              v-if="mutableQuestionnaire.review_status",
              target="#status-sidecard-pill")
              div Reviewed by: #[strong {{ $store.state.getUserName(mutableQuestionnaire.reviewed_by) }}]
              div.mt-2(v-if="mutableQuestionnaire.review_notes") {{ mutableQuestionnaire.review_notes }}
          div.ml-auto.d-flex.align-items-center
            div.mr-2 Reviewers:
            questionnaire-reviewers-group(
              :questionnaire-id="entityId",
              :reviewers="mutableQuestionnaire.reviewers",
              @updated="updateAndRefresh")
      
      div.card-body.border-bottom
        div.row.d-flex.justify-content-space-evenly
          a.btn.btn-sm.btn-primary.m-1(
            v-if="mutableQuestionnaire.questions && mutableQuestionnaire.questions.length > 0"
            :href="previewQuestionnaireUrl") #[i.fal.fa-eye] Preview Questionnaire
          a.btn.btn-sm.btn-success.text-white.m-1(v-if="showDeployButton()",@click="showModal(`send-${_uid}`)") 
            i.fal.fa-paper-plane
            span.ml-1 Deploy Questionnaire
          a.btn.btn-sm.btn-success.text-white.m-1(v-else-if="showReviewButton()",:href="reviewQuestionnaireUrl") 
            i.fal.fa-comment-alt-lines
            span.ml-1 Review Questionnaire

      card-tabs(
        :sections="tabs",
        :active-section="activeTab",
        @changeActive="changeTab")
      
      div.card-body.border-bottom(v-if="activeTab === 'info'")
        questionnaire-edit-form(v-model="mutableQuestionnaire",:uid="`inline-${entityId}`")
        div.form-group.mb-0.mt-3
          div.d-flex.justify-content-center
            button.btn.btn-primary(@click="updateAllQuestionnaireFields") Save

      div.card-body.border-bottom(v-if="activeTab === 'users'")
        div
          div.d-flex.align-items-center.mb-2(v-if="questionnaire.vendor")
            router-link(:to="`/vendor/management/vendors/${questionnaire.vendor.team_vendor_id}/users`")
              div.text-large {{ questionnaire.vendor.name }}
            div.ml-2(v-if="questionnaire.status === 'draft'")
              i.clickable.fal.fa-times-circle.text-danger(:id="`vendor-remove-${_uid}-${questionnaire.id}`",@click="updateQuestionnaireTeamVendor(null)")
              b-tooltip(:target="`vendor-remove-${_uid}-${questionnaire.id}`")
                | Remove Vendor
          div(v-else)
            div.form-group
              label Select Vendor
              div(v-if="teamVendors.length === 0")
                i No vendors added yet...
              select.form-control.form-control-sm(v-else,v-model="teamVendorIdSelection",@change="updateQuestionnaireTeamVendor()")
                option(v-for="vendor in teamVendors",:value="vendor.id") {{ vendor.name }}
          div.form-group(v-if="questionnaire.vendor || teamVendorIdSelection")
            label Assign vendor contact(s) to questionnaire:
            select.form-control.form-control-sm(v-model="assigneeIdSelection",@change="addUserToQuestionnaire()")
              option(v-for="user in vendorUsers",:value="user.id") {{ user.username_email }}
        user-list(
          v-if="questionnaireVendorUsers && questionnaireVendorUsers.length > 0",
          hover="uuid",
          :users="questionnaireVendorUsers",
          :bordered="true",
          :can-create-user="false",
          @addedUser="updateAndRefresh",
          @removeUser="removeUserFromQuestionnaire")
      
      div.card-body.border-bottom(v-if="activeTab === 'tags'")
        entity-tags-list(:tags="tags",@addTag="updateTag",@removeTag="removeTag")

      //- div.card-body.border-bottom(v-if="activeTab === 'comments'")
      //-   entity-comments-list(:entityId="entityId",entityTable="questionnaires",@updated="updateAndRefresh")
      //- div.card-body.border-bottom(v-if="activeTab === 'files'")
      //-   questionnaire-files(:entityId="entityId",@updated="updateAndRefresh")

      div.card-body.border-bottom(v-if="activeTab === 'pm'")
        pm-timeline(
          entity-table="questionnaires"
          :entity-id="entityId"
          :items="pmTimeline"
          @uploadedFile="getQuestionnaire()"
          @deleteFile="getQuestionnaire()"
          @createComment="getQuestionnaire()"
          @updateComment="getQuestionnaire()"
          @deleteComment="getQuestionnaire()")

      branded-card-footer

    send-questionnaire-modal(
      :parent-uid="_uid",
      :vendor-id="mutableQuestionnaire ? mutableQuestionnaire.team_vendor_id : null",
      :review-id="mutableQuestionnaire ? mutableQuestionnaire.team_vendor_review_id : null",
      :questionnaire-id="mutableQuestionnaire ? mutableQuestionnaire.id : null"
      @send="getQuestionnaire")
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import QuestionnaireEditForm from './QuestionnaireEditForm'
  import QuestionnaireFiles from './QuestionnaireFiles'
  import QuestionnaireStatusUpdater from './QuestionnaireStatusUpdater'
  import SendQuestionnaireModal from '../vendormanagement/SendQuestionnaireModal'
  import ApiTags from '../../factories/ApiTags'
  import ApiVendorManagement from '../../factories/ApiVendorManagement'
  import StringHelpers from '../../factories/StringHelpers'
  import TimeHelpers from '../../factories/TimeHelpers'

  export default {
    props: {
      tab: { type: String },
      entityId: { type: [Number, String] },
    },

    data() {
      return {
        isLoadingLocal: true,
        activeTab: this.tab || 'info',
        teamVendorIdSelection: null,
        assigneeIdSelection: null,
        vendorUsers: [],

        mutableQuestionnaire: {},
      }
    },

    watch: {
      async entityId() {
        await this.getQuestionnaire()
      },

      questionnaire: {
        handler(questionnaireData) {
          this.mutableQuestionnaire = questionnaireData
        },
        deep: true,
      },

      tab(newTabKey) {
        this.activeTab = newTabKey || this.activeTab
      },
    },

    computed: {
      ...mapState({
        isInternalAdmin: (_, getters) => getters.isInternalAdmin,
        isInternalUser: (_, getters) => getters.isInternalUser,
        isManager: (_, getters) => getters.isManager,
        currentUserId: (state) => ((state.auth || {}).user || {}).id || '',

        questionnaire: (state) => state.vendormanagement.currentQuestionnaire,
        tags: (state) => state.vendormanagement.currentQuestionnaire.tags || [],
        comments: (state) =>
          state.vendormanagement.currentQuestionnaire.comments || [],
        files: (state) =>
          state.vendormanagement.currentQuestionnaire.files || [],
        questionnaireVendorUsers: (state) =>
          state.vendormanagement.currentQuestionnaire.vendor_users || [],
        teamVendors: (state) => state.vendormanagement.teamVendors.data || [],
        pmTimeline: (state) =>
          state.vendormanagement.currentQuestionnaire.pmTimeline || [],

        questionnaireStatuses: (state) =>
          state.vendormanagement.questionnaireStatuses,
        variantColors: (state) => state.dashboard.variantColors(),

        users: (state) => state.team.teamAndInternalUsers,
      }),

      previewQuestionnaireUrl() {
        return `/api/1.0/vendormanagement/questionnaire/preview?questionnaireId=${
          this.mutableQuestionnaire.id
        }&redirect=${encodeURIComponent(this.$route.path)}`
      },

      reviewQuestionnaireUrl() {
        return `/api/1.0/vendormanagement/questionnaire/review/answers?questionnaireId=${
          this.mutableQuestionnaire.id
        }&redirect=${encodeURIComponent(this.$route.path)}`
      },

      showRemindButton() {
        const validStatus = ['sent', 'in_progress'].includes(
          this.mutableQuestionnaire.status
        )
        return validStatus
      },

      showEditButton() {
        const validStatus = ['draft'].includes(this.mutableQuestionnaire.status)

        const isTemplate = this.mutableQuestionnaire.is_template
        const isPublished = this.mutableQuestionnaire.is_published

        const hasTemplateAccess =
          isTemplate &&
          (this.mutableQuestionnaire.team_id == this.currentTeamId ||
            (isPublished && this.isInternalAdmin))

        // Only show button if in specific status and is not template
        // or is template and user has acces
        return (!isTemplate && validStatus) || hasTemplateAccess
      },

      tabs() {
        const tabs = [
          { key: 'info', text: 'Info', number: 0, icon: 'fa-pen-square' },
          {
            key: 'tags',
            text: 'Tags',
            number: this.tags.length,
            icon: 'fa-tags',
          },
          // {
          //   key: 'comments',
          //   text: 'Comments',
          //   number: this.comments.length,
          //   icon: 'fa-comment',
          // },
          // {
          //   key: 'files',
          //   text: 'Files',
          //   number: this.files.length,
          //   icon: 'fa-file-alt',
          // },
          {
            key: 'pm',
            text: 'Project Management',
            number: this.pmTimeline.length,
            icon: 'fa-clock',
          },
        ]

        if (!this.mutableQuestionnaire.is_template)
          tabs.splice(1, 0, {
            key: 'users',
            text: 'Assignees',
            number: this.questionnaireVendorUsers.length,
            icon: 'fa-users',
          })

        return tabs
      },
    },

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,
      titleCase: StringHelpers.titleCase,

      showReviewButton(quest = this.mutableQuestionnaire) {
        const validStatus = [
          'sent',
          'in_progress',
          'submitted',
          'under_review',
          'complete',
        ].includes(quest.status)

        const reviewer =
          quest.reviewers &&
          quest.reviewers.find((r) => r.id == this.currentUserId)

        // Only show button if in specific status and the user
        // is assigned as a reviewer or is a manager or above
        return validStatus && (reviewer || this.isManager)
      },

      showDeployButton(quest = this.mutableQuestionnaire) {
        const validStatus = ['draft'].includes(quest.status)

        // Only show button if in specific status
        return validStatus
      },

      showModal(modalId) {
        $(`#${modalId}`).modal('show')
      },

      getStatus(value) {
        let status = JSON.parse(
          JSON.stringify(
            this.questionnaireStatuses.find((s) => s.value === value) || {}
          )
        )
        return status
      },

      async update() {
        await Promise.all([
          await this.getQuestionnaire(),
          await this.$store.dispatch(
            'getQuestionnaireAndSections',
            this.entityId
          ),
          this.$store.dispatch('getQuestionnaires', true),
          this.$store.dispatch('getQuestionnaireTemplates', true),
        ])

        this.$emit('updated')
      },

      changeTab(newTabKey) {
        const newPath = `/vendor/management/qbuilder/${this.entityId}/${newTabKey}`
        if (this.$route.path === newPath) return

        this.$router.push(newPath)
      },

      resetUpdateModal() {
        // This is here due to this issue
        // https://stackoverflow.com/questions/10636667/bootstrap-modal-appearing-under-background
        $(`#sidecard-edit-questionnaire-modal`).appendTo('body')
      },

      async updateAndRefresh() {
        await this.update()
      },

      async addUserToQuestionnaire(userId = this.assigneeIdSelection) {
        try {
          await ApiVendorManagement.addUserToQuestionnaire(
            this.entityId,
            userId
          )
          this.assigneeIdSelection = this.teamVendorIdSelection = null
          await this.updateAndRefresh()
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async removeUserFromQuestionnaire(userId) {
        try {
          await ApiVendorManagement.removeUserFromQuestionnaire(
            this.entityId,
            userId
          )
          await this.updateAndRefresh()
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async updateQuestionnaireTeamVendor(
        teamVendorId = this.teamVendorIdSelection
      ) {
        try {
          if (teamVendorId == null) {
            this.questionnaireVendorUsers.map(async (vu) => {
              await this.removeUserFromQuestionnaire(vu.id)
            })
          }

          await this.updateQuestionnaireKeyValue('team_vendor_id', teamVendorId)
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async getVendorUsersList(teamVendorId = this.teamVendorIdSelection) {
        try {
          const { vendor } = await ApiVendorManagement.getTeamVendor(
            teamVendorId
          )
          this.vendorUsers = vendor.vendor_users
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      // TAGS
      async updateTag(tag, operation = 'add') {
        try {
          await ApiTags.updateEntity({
            tagId: tag.id,
            table: 'questionnaires',
            entityId: this.entityId,
            operation: operation,
          })

          await this.updateAndRefresh()
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async removeTag(_, tag) {
        await this.updateTag(tag, 'remove')
      },

      // QUESTIONNAIRE
      async updateQuestionnaireKeyValue(key, value) {
        try {
          this.mutableQuestionnaire[key] = value
          await this.updateAllQuestionnaireFields(true, false)
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async updateAllQuestionnaireFields(refresh = true, showToast = true) {
        try {
          await Promise.all([
            ApiVendorManagement.createUpdateQuestionnaire({
              ...this.questionnaire,
              ...this.mutableQuestionnaire,
            }),
          ])

          if (refresh) await this.updateAndRefresh()

          if (showToast)
            window.toastr.success(`Successfully updated questionnaire!`)
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async remindVendorOfQuestionnaire() {
        try {
          await ApiVendorManagement.remindVendorOfQuestionnaire(
            this.questionnaire.id
          )
          window.toastr.success(`Successfully sent questionnaire reminder.`)
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async deleteQuestionnaire() {
        try {
          await ApiVendorManagement.deleteQuestionnaire(this.questionnaire.id)
          await this.update()
          this.$router.push(`/vendor/management/qbuilder`)
          window.toastr.success(`Successfully deleted questionnaire.`)
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async getQuestionnaire() {
        try {
          await this.$store.dispatch('getQuestionnaire', this.entityId)
          if (this.questionnaire.team_vendor_id) {
            await this.getVendorUsersList(this.questionnaire.team_vendor_id)
          } else {
            this.vendorUsers = []
          }
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.isLoadingLocal = false
        }
      },
    },

    async created() {
      await this.getQuestionnaire()
      this.mutableQuestionnaire = this.questionnaire
    },

    mounted() {
      this.resetUpdateModal()
    },

    beforeDestroy() {
      // See comments in this.resetUpdateModal as to why this needs
      // to be here.
      $(`#sidecard-edit-questionnaire-modal`).remove()
    },

    components: {
      QuestionnaireEditForm,
      QuestionnaireFiles,
      SendQuestionnaireModal,
      QuestionnaireStatusUpdater,
    },
  }
</script>
