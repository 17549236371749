<template lang="pug">
  b-popover(:target="target",:ref="target",boundary="viewport",@shown="getBlockchainInfo()")
    template(slot="title")
      div.d-flex.align-items-center
        h6.m-0 Stellar Blockchain Transaction Information
        div.ml-auto
          i.fal.fa-times.clickable(@click="$refs[target].$emit('close')")
    div(v-if="isLoadingLocal")
      loader
    div(v-else)
      div.text-right
        i(:id="`blockchain-txn-info-${txn.id}`") what is this info and what does this mean? #[i.fal.fa-info-circle]
        b-tooltip(:target="`blockchain-txn-info-${txn.id}`")
          | The information provided here is being validated in real-time, and means the
          | data provided by you and the data stored in the stellar blockchain are verified
          | as being the same at the time of submission. If the
          | #[strong Txn Data Hash] and #[strong Calculated Hash] match below,
          | we can confirm with certainty that the data that was provided
          | at the time of submission is the same as we
          | have stored today and has not been tampered with.
      div.mt-2.mb-3
        strong
          | In order to prove the validity of this data at a later
          | point in time for auditing purposes, we store the data as a
          | #[a(href="https://en.wikipedia.org/wiki/SHA-2",target="_blank",rel="noopener noreferrer") SHA256]
          | hash in the Stellar blockchain at the time of submission.
      div.table-responsive.small
        table.table.small-padding.m-0
          tbody
            tr
              th.nowrap Txn Hash
              td
                a.overflow-ellipses.no-hover(
                  :href="`https://stellar.expert/explorer/public/tx/${txn.txn_hash}`",
                  target="_blank",
                  rel="noopener noreferrer") {{ txn.txn_hash }}
            //- tr
            //-   th.nowrap Data SHA256 Hash:
            //-   td {{ info.sha256 }}
            tr
              th.nowrap Txn Data Hash
              td(:class="hashComparisonClass")
                strong {{ info.memoHash }}
            tr
              th.nowrap Calculated Hash
              td(:class="hashComparisonClass")
                strong {{ info.fileHash }}
</template>

<script>
  import ApiBlockchain from '../../factories/ApiBlockchain'

  export default {
    props: {
      target: { type: String, required: true },
      txn: { type: Object, required: true }
    },

    data() {
      return {
        isLoadingLocal: true,
        info: null
      }
    },

    computed: {
      hashComparisonClass() {
        return this.info && this.info.memoHash === this.info.fileHash ? 'text-success' : 'text-danger'
      }
    },

    methods: {
      async getBlockchainInfo() {
        try {
          const info = await ApiBlockchain.txnInfo(this.txn.id)
          this.info = info
        } catch(err) {
          window.toastr.error(err.message)
        } finally {
          this.isLoadingLocal = false
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  td, th {
    border: 0px !important;
  }
</style>