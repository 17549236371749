<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-labelledby="compare-diff-modal-label",aria-hidden="true")
    div.modal-dialog.modal-lg(role="document")
      div.modal-content
        div.modal-header
          h5.modal-title {{ title }}
          button.close(type="button",data-dismiss="modal",aria-label="Close")
            span(aria-hidden="true") &times;
        div.modal-body
          div(v-if="!diffHtml")
            loader
          div.content(v-else, v-html="diffHtml")
</template>

<script>
  import diff from 'v-node-htmldiff'

  export default {
    props: {
      title: { type: String, default: 'Compare' },
      oldString: { type: String, required: true },
      newString: { type: String, required: true },
      context: { type: Number, default: 10 },
      outputFormat: { type: String, default: 'side-by-side' },
    },

    computed: {
      diffHtml() {
        return diff(this.oldString, this.newString, 'diff') || this.newString
      },
    },
  }
</script>
<style lang="scss">
  ins.diff {
    background-color: rgb(186, 214, 186);
  }
  del.diff {
    background-color: rgb(224, 145, 145);
  }
  .content {
    a {
      color: #68cef8;
    }

    ul,
    ol {
      padding: 0 1rem;
      margin-left: 1rem;
    }

    p {
      margin-left: 4px;
      margin-top: 2px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.1;
      margin-left: 3px;
    }

    code {
      background-color: rgba(#616161, 0.1);
      color: #616161;
    }

    pre {
      background: #0d0d0d;
      color: #fff;
      font-family: 'JetBrainsMono', monospace;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;

      code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
      }
    }

    mark {
      background-color: #faf594;
    }

    hr {
      margin: 1rem 0;
    }

    blockquote {
      margin-left: 3px;
      padding-left: 1rem;
      border-left: 2px solid rgba(#0d0d0d, 0.1);
    }

    hr {
      border: none;
      border-top: 2px solid rgba(#0d0d0d, 0.1);
      margin: 2rem 0;
    }

    ul[data-type='taskList'] {
      list-style: none;
      padding: 0;

      li {
        display: flex;
        align-items: center;

        > label {
          flex: 0 0 auto;
          margin-right: 0.5rem;
        }
      }
    }

    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
      margin: 0;
      overflow: hidden;

      td,
      th {
        min-width: 1em;
        border: 2px solid #ced4da;
        padding: 3px 5px;
        vertical-align: top;
        box-sizing: border-box;
        position: relative;

        > * {
          margin-bottom: 0;
        }
      }

      th {
        font-weight: bold;
        text-align: left;
        background-color: #f1f3f5;
      }

      .selectedCell:after {
        z-index: 2;
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(200, 200, 255, 0.4);
        pointer-events: none;
      }

      .column-resize-handle {
        position: absolute;
        right: -2px;
        top: 0;
        bottom: -2px;
        width: 4px;
        background-color: #adf;
        pointer-events: none;
      }
    }

    .tableWrapper {
      overflow-x: auto;
    }

    .resize-cursor {
      cursor: ew-resize;
      cursor: col-resize;
    }
  }
</style>
