<template lang="pug">
  div
    template(v-if="event && event.id")
      card-tabs(
        :sections="tabs",
        :active-section="activeTab",
        @changeActive="changeTab")

    div.card-body(v-if="activeTab === 'main'")
      calendar-event-main(
        :event="event"
        :saveText="saveText" 
        @input="update" 
        @deleted="deleted")
    div.card-body(v-if="activeTab === 'dependencies'")
      calendar-event-dependencies(:event="event" @update="update")
    div.card-body(v-if="activeTab === 'pm'")
      calendar-event-files(:event="event")
      calendar-event-comments.mt-4(:event="event")
</template>

<script>
  import CalendarEventMain from './CalendarEventMain'
  import CalendarEventDependencies from './CalendarEventDependencies'
  import CalendarEventComments from './CalendarEventComments'
  import CalendarEventFiles from './CalendarEventFiles'

  export default {
    props: {
      event: { type: Object, default: () => ({}) },
      saveText: { type: String, default: 'Create' },
    },

    data() {
      return {
        activeTab: 'main',
      }
    },

    computed: {
      tabs() {
        return [
          { key: 'main', text: 'Event Info', icon: 'fa-pen-square' },
          {
            key: 'dependencies',
            text: 'Dependencies',
            number:
              ((this.event.controls || []).length || 0) +
              ((this.event.requests || []).length || 0) +
              ((this.event.risks || []).length || 0),
            icon: 'fa-link',
          },
          {
            key: 'pm',
            text: 'Project Management',
            icon: 'fa-history',
          },
        ]
      },
    },

    methods: {
      isActiveTabClass(tab) {
        return this.activeTab === tab ? 'active' : ''
      },

      changeTab(newTab) {
        this.activeTab = newTab
      },

      update(event) {
        this.$emit('update', event)
      },

      deleted() {
        this.$emit('deleted')
      },
    },

    components: {
      CalendarEventMain,
      CalendarEventDependencies,
      CalendarEventComments,
      CalendarEventFiles,
    },
  }
</script>
