<template lang="pug">
  div.main-navbar.sticky-top.bg-white.border-bottom
    - // Main Navbar
    nav.navbar.align-items-stretch.navbar-light.flex-md-nowrap.p-0
      div.px-4.border-right.d-none.d-md-flex.align-items-center(
        v-if="isLoggedIn && currentTeam && currentTeamLogo",
        style="overflow: hidden; max-width: 240px;")
          router-link(to="/",:title="currentTeam.id")
            img.img-fluid.team-logo(:src="`/file/s3/${currentTeamLogo}`")
      main-page-header.pl-4.d-none.d-md-flex.align-items-center
      - //main-search(location="topbar")
      ul.navbar-nav.flex-row.d-flex.align-items-center(v-if="!shouldSeeNavItem('topbar') && isLoggedIn")
        li.nav-item.dropdown.d-flex.align-items-center
          a#tc-logout.nav-link.nav-link-icon.text-center(href="/logout")
            i.fal.fa-sign-out-alt
          b-tooltip(target="tc-logout") Logout
      ul.navbar-nav.ml-auto.flex-row.d-flex.align-items-center(v-if="showTopNav && shouldSeeNavItem('topbar')")
        risk3sixty-main-page-users.px-1.mr-1
        li.nav-item.px-1(v-if="currentTeam.id != 1 && !isEnterpriseCustomer")
          div.nav-link.nav-link-icon.text-center
            a.clickable(data-toggle="modal",data-target="#upgrade-subscription-modal")
              div#upgrade.nav-link-icon__wrapper
                img(style="width:40px;margin-top:-1px;" src="../../public/images/upgrade_icon.png", alt="content shield",:id="`upgrade-tag-${_uid}`")
              b-tooltip(target="upgrade") Upgrade
        li.nav-item.px-1
          router-link#users.nav-link.nav-link-icon.text-center(to="/settings/organization/users")
            div.nav-link-icon__wrapper
              i.fal.fa-users-medical
          b-tooltip(target="users") Users

        //- li.nav-item.notifications.px-1
        //-   router-link#queues.nav-link.nav-link-icon.text-center(to="/queues")
        //-     div.nav-link-icon__wrapper
        //-       i.fal.fa-list-alt
        //-       span.badge.badge-pill.badge-danger(v-show="totalQueueCount > 0") {{ totalQueueCount }}
        //-   b-tooltip(target="queues") Queues
        //- li.nav-item.notifications.px-1
        //-   router-link#feedback.nav-link.nav-link-icon.text-center(to="/feedback")
        //-     div.nav-link-icon__wrapper
        //-       i.fal.fa-comment-dots
        //-   b-tooltip(target="feedback") Support
        //- li.nav-item.notifications.px-1
        //-   router-link#messages.nav-link.nav-link-icon.text-center(to="/messages")
        //-     div.nav-link-icon__wrapper
        //-       i.fal.fa-inbox
        //-       span.badge.badge-pill.badge-danger(v-show="unreadSecureMessages.length > 0") {{ unreadSecureMessages.length }}
        //-   b-tooltip(target="messages") Messages
        risk3sixty-main-alerts#alerts.px-1.mr-2
        b-tooltip(target="alerts") Alerts
        li#top-nav-user-dropdown.border-left.nav-item.dropdown.d-flex.align-items-center.justify-content-center.align-self-stretch
          a.nav-link.dropdown-toggle.text-nowrap.px-3.d-flex.align-items-center(
            data-toggle='dropdown',
            href='#',
            role='button',
            aria-haspopup='true',
            aria-expanded='false')
            img.user-avatar.rounded-circle.mr-2(
              v-if="!!userRecord.avatar_file",
              :src="'/file/s3/' + userRecord.avatar_file",
              alt='User Avatar')
            i.material-icons(v-else,style="font-size:2rem") account_circle
            span.d-none.d-md-inline-block(style="line-height: 1")
              div
                span.d-none.d-md-inline-block(v-if="userRecord",:title="userRecord.id",
                  style="max-width: 10rem; text-overflow: ellipsis; overflow: hidden !important")
                  | {{ userRecord.name || userRecord.username_email }}
              div(v-if="currentTeam")
                div.d-flex.align-items-center(:title="currentTeam.id")
                  img.img-fluid.mt-1(
                    v-if="currentTeamLogo",
                    :src="`/file/s3/${currentTeamLogo}`",
                    style="max-height: 10px")
                  small(v-else) {{ currentTeam.name }}
          div.main-navbar-dropdown.dropdown-menu.dropdown-menu-small.ml-auto(

            style="min-width: 135%;")
            template(v-if="userHasAccessToMultipleTeams")
              div.dropdown-item(@click.stop)
                div(v-if="currentTeam")
                  small {{ currentTeam.name }}
                label.mb-1(for="switch-teams") Switch Organizations
                typeahead-input-new#switch-teams(
                  :options="allUserTeamsOptions"
                  src="/api/1.0/teams/getall?db=true"
                  keys-from-response="teams"
                  show-prop="name"
                  @input="switchTeams")
              div.dropdown-divider
            div.dropdown-item.d-flex(@click.stop)
              div.custom-control.custom-toggle-sm.custom-toggle
                input.custom-control-input(
                  type="checkbox",
                  id="global-darkmode-toggle",
                  name="global-darkmode-toggle",
                  v-model="isDarkMode")
                label.custom-control-label(for="global-darkmode-toggle") Dark Mode
            div.dropdown-divider
            - //router-link.dropdown-item(to='/settings/client')
            - //  i.material-icons business
            - //  |  Client Settings
            router-link.dropdown-item(to='/settings/user')
              i.material-icons &#xE7FD;
              |  Profile
            router-link.dropdown-item(to='/settings')
              i.material-icons settings
              |  Settings

            div.dropdown-divider
            a.dropdown-item(href="javascript:void(0)", @click="openJiraWidget")
              i.fa.fa-comment-dots.mr-2
              | Support Request
            a.dropdown-item(
              href="https://risk3sixty.zendesk.com/hc/en-us/signin?return_to=https%3A%2F%2Frisk3sixty.zendesk.com%2Fhc%2Fen-us"
              target="_blank"
              rel="noopener noreferrer")
              i.material-icons.mr-2 psychology
              | Knowledge Base

            - // Any logged in users should see the logout button
            div.dropdown-divider
            a.dropdown-item.text-danger(href='/logout')
              i.material-icons.text-danger &#xE879;
              |  Logout
      nav.nav
        a.nav-link.nav-link-icon.toggle-sidebar.d-sm-inline.d-md-none.text-center.border-left.d-flex.align-items-center.justify-content-center(
          href='#',
          data-toggle='collapse',
          data-target='.header-navbar',
          aria-expanded='false',
          aria-controls='header-navbar'
        )
          i.material-icons &#xE5D2;
      
    upgrade-subscription-modal#upgrade-subscription-modal

</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import MainSearch from './reusable/MainSearch'
  import Risk3sixtyMainAlerts from './Risk3sixtyMainAlerts'
  import Risk3sixtyMainPageUsers from './Risk3sixtyMainPageUsers'
  import ApiTeams from '../factories/ApiTeams'
  import ApiUsers from '../factories/ApiUsers'
  import UpgradeSubscriptionModal from './reusable/UpgradeSubscriptionModal.vue'

  export default {
    data() {
      return {
        totalMessageCount: 0,
        teamsLengthCutoff: 5,
      }
    },

    watch: {
      isDarkMode(newVal) {
        this.changeDarkMode(newVal)
      },
    },

    computed: {
      ...mapState({
        currentTeam: (state) => state.session.current_team,
        currentTeamLogo: (_, getters) => getters.currentTeamLogo,
        isInternalUser: (_, getters) => getters.isInternalUser,
        isLoggedIn: (state) => state.isLoggedIn,
        showTopNav: (state) => state.isLoggedIn && state.session,
        unreadSecureMessages: (state) => state.unreadSecureMessages,
        userNavItems: (state) => state.auth.userNavItems,
        userRecord: (state) => state.auth.user,

        isEnterpriseCustomer: (_, getters) => getters.isEnterpriseCustomer,

        totalQueueCount(state) {
          const queueSummary = state.queueSummary
          return Object.values(queueSummary || []).reduce(
            (total, num) => total + parseInt(num),
            0
          )
        },

        allUserTeams(state) {
          if (!state.session) return false
          return state.session.all_user_teams
        },

        recentUserTeamsIds(state) {
          if (!state.session) return false
          return state.session.recent_user_teams
        },

        userHasAccessToMultipleTeams(state) {
          if (!state.session) return false
          return state.session.user_teams_count > 1
        },
      }),

      // if <= 5 teams, show all teams
      // if > 5 teams, grab the cached teams and show those
      allUserTeamsOptions() {
        const recentUserTeams = (this.recentUserTeamsIds || []).map(
          (teamId) => {
            return this.allUserTeams.find((team) => team.id === teamId) || {}
          }
        )

        const teams =
          this.allUserTeams.length <= this.teamsLengthCutoff
            ? this.allUserTeams
            : recentUserTeams

        return teams
          ? teams.map((t) => {
              return {
                name: t.name,
                id: t.id,
              }
            })
          : null
      },

      isDarkMode: {
        get() {
          return this.$store.state.isDarkMode
        },

        async set(newVal) {
          this.$store.commit('SET_DARK_MODE', newVal)
          this.$store.commit('SET_USER_PERSONALIZATIONS', {
            dark_mode: {
              parameters: { enabled: newVal },
            },
          })
          this.changeDarkMode(newVal)
          await ApiUsers.updatePersonalization(`dark_mode`, { enabled: newVal })
        },
      },
    },

    methods: {
      changeDarkMode(newVal = this.isDarkMode) {
        const newDarkModeVal = newVal

        let jqMethod = 'removeClass'
        if (newDarkModeVal) jqMethod = 'addClass'

        $('body')[jqMethod]('dark')
      },

      shouldSeeNavItem(item) {
        return this.userNavItems && this.userNavItems[item].canSee
      },

      openJiraWidget() {
        // gets the iframe on which the widget is embedded
        const iframe = document.querySelector('#jsd-widget')

        // gets the content of the iframe
        const iframeContent = iframe.contentDocument

        // gets button which opens the widget
        const button = iframeContent.querySelector('#help-button')

        // "click" button to open widget
        button.click()
      },

      async switchTeams(team) {
        const splitRoute = this.$router.currentRoute.path.split('/')
        // the first element of the array will always be ''. shift removes it
        splitRoute.shift()

        // remove all route parts after the first number
        const newRoute = splitRoute
          .slice(
            0,
            splitRoute.findIndex((routePart) => /^\d+$/.test(routePart))
          )
          .join('/')

        await ApiTeams.switch(this, team.id, `${encodeURIComponent(newRoute)}`)

        // Normally, redirecting to a new route would essentially refresh the page and clear state.
        // We want to do that even if not navigating to a new route
        if (`/${newRoute}` == this.$router.currentRoute.path) {
          this.$router.go(this.$router.currentRoute)
        }
      },
    },

    mounted() {
      this.changeDarkMode()
    },

    components: {
      MainSearch,
      Risk3sixtyMainAlerts,
      Risk3sixtyMainPageUsers,
      UpgradeSubscriptionModal,
    },
  }
</script>

<style lang="scss">
  #top-nav-user-dropdown {
    @media (min-width: 768px) {
      min-width: 180px;
    }
  }
</style>
