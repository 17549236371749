<template lang="pug">
div.modal.fade(tabindex="-1",role="dialog",aria-hidden="true")
  div.modal-dialog.modal-lg(role="document")
    div.modal-content
      form(@submit="createTask")
        div.modal-header
          h5.modal-title {{ (taskId) ? 'Update' : 'New' }} {{ modalTitle }}
          button.close(type="button",data-dismiss="modal",aria-label="Close")
            span(aria-hidden="true") &times;
        div.modal-body
          template(v-if="chooseProject")
            label.h6 Project:
            div(v-if="selectedProject")
              div
                strong
                  | {{ selectedProject.name }}
                  | #[i.clickable.fal.fa-times.text-danger(@click="resetProject")]
            loader(v-else-if="isloadingProjects")
            div(v-else)
              select.form-control(v-model="selectedProject" @change="getGroupsFromProject(selectedProject)")
                option(:value="null") Select project...
                option(v-for="project in projects" :key="project.id" :value="project") {{ project.name }}
          div(v-if="chooseProject && selectedProject")
            div.form-group.py-3.border-bottom
              label.h6 Section:
              div(v-if="selectedGroup")
                div
                  strong
                    | {{ selectedGroup.name }}
                    | #[i.clickable.fal.fa-times.text-danger(@click="resetGroup")]
              div(v-else)
                select.form-control(v-model="selectedGroup" @change="selectedGroupId = selectedGroup.id")
                  option(:value="null") Select project section...
                  option(v-for="group in groups" :key="group.id" :value="group") {{ group.name }}
          task-edit-form(
            v-if="selectedGroupId"
            v-model="mutableTaskRecord"
            :uid="selectedGroupId")
        div.modal-footer
          button.btn.btn-secondary(type="button",data-dismiss="modal") Cancel
          button.btn.btn-primary(type="submit",@click="createAnother = true" :disabled="submitting") Add and Create Another #[loader-inline(v-if="submitting")]
          button.btn.btn-primary(type="submit" :disabled="submitting") {{ (this.taskId) ? 'Update' : 'Create' }} #[loader-inline(v-if="submitting")]
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import TaskEditForm from '../projects/TaskEditForm'
  import ApiProjects from '../../factories/ApiProjects'
  import StringHelpers from '../../factories/StringHelpers'

  export default {
    props: {
      chooseProject: { type: Boolean, default: false },
      projectId: { type: [Number, String], default: null },
      taskId: { type: [Number, String], default: null },
      taskGroupId: { type: [Number, String] },
      redirectOnCreate: { type: Boolean, default: true },
      parentTaskId: { type: [Number, String], default: null },
      modalTitle: { type: String, default: 'Project Task' },
      defaultFields: { type: Object, default: () => {} },
    },

    data() {
      return {
        projects: [],
        isloadingProjects: false,
        selectedProject: null,
        groups: [],
        selectedGroup: null,
        selectedGroupId: this.taskGroupId,

        createAnother: false,
        mutableTaskRecord: {
          status: 'not_started',
          owners: [],
          collaborators: [],
          parent_task_id: this.parentTaskId,
        },

        submitting: false,
      }
    },

    computed: {
      ...mapState({
        currentUser: (state) => state.auth.user,
      }),
    },

    methods: {
      titleCase: StringHelpers.titleCase,

      resetMutableTaskRecord() {
        this.mutableTaskRecord = {
          ...this.defaultFields,
          status: 'not_started',
          owners: [this.currentUser.id],
          collaborators: [],
          parent_task_id: this.parentTaskId,
        }
      },

      async getTask(taskId) {
        const { task, owners, collaborators } = await ApiProjects.getTask(
          taskId
        )

        this.mutableTaskRecord = task
        this.mutableTaskRecord.owners = owners.map((owner) => owner.user_id)
        this.mutableTaskRecord.collaborators = collaborators.map(
          (c) => c.user_id
        )
      },

      async createTask(evt) {
        try {
          evt.preventDefault()
          this.submitting = true

          if (
            !(this.mutableTaskRecord.description && this.mutableTaskRecord.name)
          )
            return window.toastr.error(
              `Please enter at least a name and short description for the task.`
            )

          if (
            this.hasPeriodicity(this.mutableTaskRecord) &&
            !this.mutableTaskRecord.due_date
          )
            return window.toastr.error(
              `A due date is required to set a periodicity.`
            )

          if (!this.hasAllRequiredPeriodicityFields(this.mutableTaskRecord))
            return window.toastr.error(
              `Both frequency and frequency unit are required to set a periodicity.`
            )

          const { id } = await ApiProjects.createOrUpdateTask({
            ...this.mutableTaskRecord,
            task_group_id: this.selectedGroupId,
          })
          await Promise.all([
            ApiProjects.updateTaskOwners(id, this.mutableTaskRecord.owners),
            ApiProjects.updateTaskCollaborators(
              id,
              this.mutableTaskRecord.collaborators
            ),
          ])

          this.$emit('created', { id })

          this.resetMutableTaskRecord()
          let newTaskUrl = `/projects/${
            this.projectId || this.selectedProject.id
          }/task/${id}`
          if (!this.createAnother) {
            $(`#${this.$el.id}`).modal('toggle')

            if (!this.mutableTaskRecord.id && this.redirectOnCreate) {
              this.$router.push(newTaskUrl)
            }
          }
          window.toastr.success(
            `Successfully ${this.taskId ? 'updated' : 'created'} the task!`
          )
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.createAnother = false
          this.submitting = false
        }
      },

      async getProjects() {
        try {
          this.isloadingProjects = true
          const { projects } = await ApiProjects.getAllProjects()
          this.projects = projects
        } catch (err) {
          window.toastr.error('Error fetching projects. Please try again.')
        } finally {
          this.isloadingProjects = false
        }
      },

      getGroupsFromProject(project) {
        this.groups = project.task_groups
      },

      resetProject() {
        this.selectedProject = null
        this.resetGroup()
        this.groups = []
      },

      resetGroup() {
        this.selectedGroup = null
        this.selectedGroupId = null
      },

      hasPeriodicity(task) {
        const hasFrequency = !!task.frequency
        const hasFrequencyUnit = !!task.frequency_unit
        const hasFrequencyEndDate = !!task.frequency_end_date

        return hasFrequency || hasFrequencyUnit || hasFrequencyEndDate
      },

      hasAllRequiredPeriodicityFields(task) {
        const hasFrequency =
          typeof task.frequency !== 'undefined' && task.frequency !== null
        const hasFrequencyUnit =
          typeof task.frequency_unit !== 'undefined' &&
          task.frequency_unit !== null

        // if either frequency field is set, both must be before save
        return hasFrequency || hasFrequencyUnit
          ? hasFrequency && hasFrequencyUnit
          : true
      },
    },

    mounted() {
      $(`#${this.$el.id}`).on('shown.bs.modal', async () => {
        this.resetMutableTaskRecord()
        if (this.chooseProject) {
          this.getProjects()
        } else if (this.taskId) {
          this.getTask(this.taskId)
        }
      })
    },

    components: {
      TaskEditForm,
    },
  }
</script>
