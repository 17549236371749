import $ from 'jquery'
import store from '../vuex/store'

export default {
  showGatedModal,
  getBlockingMatchedRestriction,
  showUpgradeModal,
  isActionBlocked,
}

function showGatedModal(modalId, restrictionKey, count) {
  const blockingRestriction = getBlockingMatchedRestriction(
    restrictionKey,
    count
  )
  if (blockingRestriction && !store.getters.isInternalUser) {
    showUpgradeModal(
      blockingRestriction.modalText,
      blockingRestriction.jqueryButton
    )
  } else {
    $(`#${modalId}`).modal('show')
  }
}

// Uses the 'gates' passed from the backend in Subscription.ts (via the session)
//   if the gate type is 'time', the comparitor should be a Date object
//   if the gate type is 'limit', the comparitor should be the count of items
//   the restrictionKey should be matched to the 'key' key in the Restriction object in Subscription.ts
function getBlockingMatchedRestriction(restrictionKey, comparitor) {
  const matchedRestriction = getMatchedRestriction(restrictionKey)
  if (!matchedRestriction) return null

  const gate = matchedRestriction.gate
  let restrictionBlocksAction = false

  switch (gate.type) {
    case 'boolean':
      restrictionBlocksAction = !gate.value
      break
    case 'time': // Assumes that time is in days
      if (gate.value === null) {
        restrictionBlocksAction = false
      } else {
        // restrictionBlocksAction = daysRemaining <= 0
        const daysRemaining =
          gate.value -
          Math.ceil(
            (new Date().getTime() - new Date(comparitor).getTime()) /
              (1000 * 3600 * 24)
          )
        restrictionBlocksAction = daysRemaining > 0
      }
      break
    case 'limit':
      if (gate.value === null) {
        restrictionBlocksAction = false
      } else if (gate.value === 0) {
        // limit === 0 means not allowed to create
        restrictionBlocksAction = true
      } else {
        // In the case of a limit, the comparitor is the number of items
        restrictionBlocksAction = comparitor >= gate.value
      }
      break
    default:
      restrictionBlocksAction = false
  }
  return restrictionBlocksAction ? matchedRestriction : null
}

function getMatchedRestriction(restrictionKey) {
  const restrictions = store.getters.subscriptionRestrictions
  return restrictions.find((r) => r.key === restrictionKey)
}

// Updates the text and buttons then displays the modal found in UpgradeSubscriptionModal.vue
function showUpgradeModal(modalText, jqueryButton) {
  if (modalText) {
    // Update the modal text
    $(`#upgrade-subscription-modal .modal-body`).html(modalText)
  }
  if (jqueryButton) {
    // Update the modal buttons if applicable
    const { method, html } = jqueryButton
    $(`#upgrade-subscription-modal .modal-footer`)[method](html)
  }
  // Finally, show the modal
  $(`#upgrade-subscription-modal`).modal('show')
}

function isActionBlocked(restrictionKey, count) {
  const blockingRestriction = getBlockingMatchedRestriction(
    restrictionKey,
    count
  )
  return !!blockingRestriction
}
