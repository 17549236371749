<template lang="pug">
  div
    div.summary.font-weight-normal.clickable(@click="isHighlighted = !isHighlighted")
      div.d-flex.align-items-center.justify-content-space-between.p-3(:class="isHighlighted ? 'active-list-row' : ''")
        div.mr-4.d-flex
          i.px-1(:class="levelIcon")
          div(v-if="multiSelect")
            //checkbox for multi-select
            div.custom-control.custom-checkbox(@click.stop)
              input.custom-control-input(
                :id="`select-${id}`",
                type="checkbox",
                v-model="isSelected"
                @change="$emit('select', id)") 
              label.custom-control-label(:for="`select-${id}`")
          h6.mb-0 {{title}}
        slot(name="title")
        div.mr-2 
          slot(name="actions")
          i.fa(:class="isHighlighted ? 'fa-chevron-down' : 'fa-chevron-right'")
    slot(@click.stop v-if="isHighlighted" name="content")
</template>

<script>
  export default {
    props: {
      title: { type: String },
      levelIcon: { type: String, default: null },
      multiSelect: { type: Boolean, default: false },
      id: { type: String, default: null },
      selectAll: { type: Boolean, default: false },
      openByDefault: { type: Boolean, default: false },
    },

    watch: {
      selectAll: {
        immediate: true,
        handler(val) {
          this.isSelected = val
        },
      },

      openByDefault: {
        immediate: true,
        handler(val) {
          this.isHighlighted = val
        },
      },
    },

    data() {
      return {
        isHighlighted: false,
        isSelected: this.selectAll,
      }
    },

    methods: {
      /** Used by parent via $ref */
      collapse() {
        this.isHighlighted = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .summary {
    &:hover {
      background: #f0f0f0;
    }
  }
</style>
