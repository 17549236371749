<template lang="pug">
  div.form-group
    label Search for items where '{{ value }}' equals the following
    select.form-control.form-control-sm(ref="searchColumn",:value="value",@change="changeSearchColumn")
      option(v-for="field in $store.state.vulnerability.fields",:value="field.internal_name") {{ field.display_name }}
    - //input.form-control.form-control-sm(:placeholder="'Search term for ' + value",v-model="searchTextFilter")
    typeahead-input#item-search(
      extra-classes="form-control-sm",
      :src="'/api/1.0/vulnerability/items/search?distinct=true&field=' + value + '&id=' + scanId",
      :params="{ keysFromResponse: 'items', showPropFunction: itemSearchDisplay, minChars: 1 }",
      @onHit="sendItem")
    pill-inline.mt-1(
      v-if="searchTextFilter",
      :text="searchTextFilter",
      @close="sendItem({ [value]: '' })")
</template>

<script>
  export default {
    props: {
      scanId: { type: [ Number, String ] },
      searchTextFilter: { type: String },
      value: { type: String } // searchColumn field to bind to v-model
    },

    methods: {
      changeSearchColumn() {
        this.$emit('input', this.$refs.searchColumn.value)
      },
      
      itemSearchDisplay(item) {
        const values = Object.keys(item).filter(key => ![ 'item_id', 'status' ].includes(key)).map(key => item[key])
        return values.join(' - ')
      },

      sendItem(item) {
        this.$emit('select', item)
      }
    }
  }
</script>