<template lang="pug">
div
  div.form-group.ml-2
    div.custom-control.custom-radio.mb-1
      input.custom-control-input(
        id="anyone"
        name="visibility"
        type="radio",
        :value="0"
        v-model="mutableCode"
        :disabled="!userCanEdit"
        @change="clearUserListAndUpdate")
      label.custom-control-label(for="anyone") Anyone
    div.custom-control.custom-radio.mb-1
      input.custom-control-input(
        id="manager"
        name="visibility"
        type="radio",
        :value="managerPermissionCode"
        v-model="mutableCode"
        :disabled="!userCanEdit"
        @change="clearUserListAndUpdate")
      label.custom-control-label(for="manager") Managers & Admins
    div.custom-control.custom-radio.mb-1
      input.custom-control-input(
        id="admin"
        name="visibility"
        type="radio",
        :value="adminPermissionCode"
        v-model="mutableCode"
        :disabled="!userCanEdit"
        @change="clearUserListAndUpdate")
      label.custom-control-label(for="admin") Admins
    div.custom-control.custom-radio.mb-1
      input.custom-control-input(
        id="userList"
        name="visibility"
        type="radio",
        :value="tempUserListCode"
        v-model="mutableCode"
        :disabled="!userCanEdit"
        @change="clearUserListAndUpdate")
      label.custom-control-label(for="userList") Specific Users
    user-avatar-editable-group.ml-3(
      v-if="isCodeUserList",
      :owners="Array.from(mutableUserList)",
      max-height="25",
      height="30px",
      :should-show-close="false"
      :editable="userCanEdit"
      :disabled="!userCanEdit"
      @add="updateUserList($event, 'add')"
      @delete="updateUserList($event, 'delete')")
    div.custom-control.custom-radio.mb-1
      input.custom-control-input(
        id="user"
        name="visibility"
        type="radio",
        :value="null"
        v-model="mutableCode"
        :disabled="!userCanEdit"
        @change="clearUserListAndUpdate")
      label.custom-control-label(for="user") Only Me
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    props: {
      code: { type: Number, default: 0 },
      userList: { type: Array, default: () => [] },
      userCanEdit: { type: Boolean },
    },

    data() {
      return {
        //- Use a value of 9999 temporarily on the frontend to distinguish between "only me" and "specific users", but both will be null in DB
        tempUserListCode: 9999,
        mutableCode: 0,
        mutableUserList: new Set(),
      }
    },

    watch: {
      code: {
        immediate: true,
        handler(newCode) {
          // used to distinguish between "only me" and "specific users"
          this.mutableCode = newCode == null ? null : parseInt(newCode)
          this.mutableUserList = new Set(this.userList)

          if (this.userList?.length > 0 && newCode === null) {
            this.mutableCode = this.tempUserListCode
          } else if (newCode != null) {
            try {
              this.mutableCode = newCode
            } catch (e) {
              this.mutableCode = null
            }
          } else {
            this.mutableCode = null
          }
        },
      },
    },

    computed: {
      ...mapState({
        managerPermissionCode: (state) =>
          state.userRoles.find((r) => r.val === 'manager').permissionCode,
        adminPermissionCode: (state) =>
          state.userRoles.find((r) => r.val === 'admin').permissionCode,

        userId: (state) => state.auth.user.id,
      }),

      isCodeUserList() {
        return this.mutableCode === this.tempUserListCode
      },
    },

    methods: {
      updateUserList(userId, action) {
        if (action === 'add') this.mutableUserList.add(userId)
        else this.mutableUserList.delete(userId)
        this.mutableUserList = new Set(this.mutableUserList)

        this.$emit('updateAccess', {
          code: this.tempUserListCode,
          userList: Array.from(this.mutableUserList),
        })
      },

      clearUserListAndUpdate() {
        this.mutableUserList = new Set()
        this.updateCode()
      },

      updateCode() {
        this.$emit('updateAccess', {
          code: this.mutableCode,
          userList:
            this.mutableCode === this.tempUserListCode
              ? Array.from(this.mutableUserList)
              : null,
        })
      },
    },
  }
</script>
