<template lang="pug">
user-avatar-editable-group(
  :owners="owners",
  :groupMaxWidth="groupMaxWidth",
  :editable="editable",
  @add="addOwner",
  @delete="deleteOwner")
</template>

<script>
  import ApiProjects from '../../factories/ApiProjects'

  export default {
    props: {
      owners: { type: Array, default: () => [] },
      taskId: { required: true },
      groupMaxWidth: { type: Number, default: 100 },
      editable: { type: Boolean, default: true },
      type: {
        type: String,
        default: 'owner',
        validator: (v) => ['owner', 'collaborator'].includes(v),
      },
    },

    methods: {
      async addOwner(newUserId) {
        try {
          const newUserIds = [
            ...new Set([
              ...this.owners.map((o) => o.user_id || o.id),
              parseInt(newUserId),
            ]),
          ]
          if (this.type === 'owner') {
            await ApiProjects.updateTaskOwners(this.taskId, newUserIds)
          } else {
            await ApiProjects.updateTaskCollaborators(this.taskId, newUserIds)
          }
          this.$emit('updated')
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async deleteOwner(userId) {
        try {
          if (this.type === 'owner') {
            await ApiProjects.removeTaskOwner(this.taskId, userId)
          } else {
            await ApiProjects.removeTaskCollaborator(this.taskId, userId)
          }
          this.$emit('updated')
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },
  }
</script>
