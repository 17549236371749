<template lang="pug">
  div
    div(v-if="!isEditing" @click="canEdit ? isEditing = !isEditing : null" :class="canEdit ? 'editable' : ''")
      div(v-if="!value")
        i {{canEdit ? 'No date entered yet...' : ''}}
      div(v-else) {{ getFormattedDate(value, 'MMMM D, YYYY', true) }}
    datepicker-shards(v-else v-model="mutableVal")
</template>

<script>
  import Utilities from '@risk3sixty/utilities'
  import TimeHelpers from '../../../../factories/TimeHelpers'

  const moment = Utilities.Dates.moment

  export default {
    props: {
      value: { type: String, default: null },
      canEdit: { type: Boolean, default: true },
    },

    data() {
      return {
        isEditing: false,
      }
    },

    computed: {
      mutableVal: {
        get() {
          return this.value
        },

        set(d) {
          if (d === this.value) return
          this.$emit('input', moment(d).toISOString())
          this.isEditing = false
        },
      },
    },

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,
    },
  }
</script>
