<template lang="pug">
  div.dropdown.clickable
    div(:id="`rr-initial-risk-${_uid}`",
        role='button',
        data-toggle='dropdown',
        aria-haspopup='true',
        aria-expanded='false',
        @click="refreshItem(itemId)")
      risk-register-data-circle(
        type="risk",
        :value="initialRiskScore || '-'",
        header="Inherent Risk",
        :size="size")
    div.dropdown-menu.dropdown-menu-small
      a.dropdown-item.p-2.border-bottom(v-for="risk in riskHistory",@click="update(risk.risk)")
        div.text-gray.mb-2(style="font-size: 0.6rem;") {{ getFormattedDate(risk.created_at) }}
        div.d-flex.justify-content-center
          div(v-if="isLoadingCurrentItem")
            loader
          risk-register-data-circle(
            v-else,
            type="risk",
            :value="risk.risk || '-'",
            size="25")

    b-tooltip(:target="`rr-initial-risk-${_uid}`")
      div(v-html="glossary.INHERENT_RISK")
</template>

<script>
  import { mapState } from 'vuex'
  import TimeHelpers from '../../factories/TimeHelpers'
  import { glossary } from '../../constants/glossary'

  export default {
    props: {
      initialRiskScore: { type: [Number, String], required: true },
      size: { type: [Number, String], default: 40 },
      itemId: { type: [Number, String], default: null },
    },

    data() {
      return {
        glossary: glossary.riskRegister.risks,
        isLoadingCurrentItem: false,
      }
    },

    computed: mapState({
      riskHistory(state) {
        const history = state.riskregister.currentItem.history
        let impact = 1
        let likelihood = 1
        let returnedHistory = []
        for (let i = 0; i < history.length; i++) {
          const item = history[i]
          if (!['impact', 'likelihood'].includes(item.key)) continue

          if (item.key === 'impact') impact = item.value
          if (item.key === 'likelihood') likelihood = item.value

          const lastItem = returnedHistory[returnedHistory.length - 1]
          if (lastItem && lastItem.risk === impact * likelihood) continue

          returnedHistory.push({ ...item, risk: impact * likelihood })
        }

        return returnedHistory.concat([
          {
            risk: state.riskregister.currentItem.record.risk_score,
            created_at: new Date(),
          },
        ])
      },
    }),

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,
      update(newInitialRisk) {
        this.$emit('update', newInitialRisk)
      },

      async refreshItem(itemId) {
        if (itemId) {
          this.isLoadingCurrentItem = true
          this.$store.state.riskregister.currentItem = {
            history: [],
            record: {
              risk_score: null,
            },
          }
          await this.$store.dispatch('getRiskRegisterItem', itemId)
          this.isLoadingCurrentItem = false
        }
      },
    },
  }
</script>
