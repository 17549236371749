<template lang="pug">
  div.col-lg-6.col-sm-12
    div.card.card-small.card-post.card-post--aside.card-post--1
      div.card-post__image(:style="mainImageStyle")
      router-link.card-post__category.badge.badge-pill.badge-primary(v-if="link",:to="link") {{ justFirstWordInTitle }}
        //- div.card-post__author.d-flex
        //-   a.card-post__author-avatar.card-post__author-avatar--small(
        //-     href='#',
        //-     style="background-image: url('images/avatars/0.jpg');") Written by Lance
      div.card-body
        h5.card-title
          router-link.text-fiord-blue(v-if="link",:to="link") {{ title }}
        p.card-text.d-inline-block.mb-3(v-if="description") {{ description }}
        span.text-muted(v-if="footer") {{ footer }}
</template>

<script>
  export default {
    props: {
      src: { type: String },
      title: { type: String },
      link: { type: String, default: null },
      description: { type: String, default: null },
      footer: { type: String, default: null }
    },

    computed: {
      justFirstWordInTitle() {
        return this.title.split(' ')[0]
      },

      mainImageStyle() {
        return {
          'background-image': `url('${this.src}')`
        }
      }
    }
  }
</script>
