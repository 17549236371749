<template lang="pug">
  div
    div.row.m-1.border-bottom.d-flex.align-items-center.my-2
      div.col-8.mb-2
        div.form-group.m-0
          select-buttons(
            label="Question Type"
            v-model="mutableQuestion.type"
            :options="types") 
      div.col-2.d-flex.align-items-start.justify-content-end.mb-2
        div.custom-control.custom-checkbox
          input#optional-checkbox.custom-control-input(type="checkbox",v-model="mutableQuestion.is_optional")
          label#optional-info.custom-control-label(for="optional-checkbox")
            | Optional?
            | #[i.fal.fa-info-circle]
        b-tooltip(target="#optional-info")
          | Questions are required by default and need to be answered before
          | the questionnaire can be submitted. If a question is optional,
          | it no longer needs to be answered for the questionnaire to be submitted
          | by the vendor.
      div.col-2.d-flex.align-items-start.justify-content-end.mb-2
        div.d-flex.align-items-center.justify-content-center.action-icon-circle-container.mr-2
          a#move-question
            i.fal.fa-exchange
            b-tooltip(target="move-question") Move Question
          b-popover(target="#move-question",boundary="viewport",:show.sync="isMoveQuestionPopoverShowing")
            template(slot="title")
              div.d-flex.align-items-center
                h6.mb-0.mr-4 Move Question to Section
                div.ml-auto
                  i.fal.fa-times.clickable(@click="isMoveQuestionPopoverShowing = false")
            div
              div.form-group
                label New Section:
                select.form-control.form-control-sm(v-model="newSectionId")
                  option(v-for="section in sections",:value="section.id") {{ section.text }}
              b.pt-2.text-danger(v-if="mutableQuestion && mutableQuestion.trigger_question_choices.length > 0")
                | * You are moving a nested question. It will be removed from the parent question, and moved to the new section.
            div.mt-3.d-flex.justify-content-center
              button.btn.btn-vsm.btn-primary(@click="moveQuestion") Move Question     
        div.d-flex.align-items-center.justify-content-center.action-icon-circle-container
          i.clickable.fal.fa-trash-alt.text-danger(
            :id="`questionnaire-question-delete-${_uid}`")
          confirmation-popover(
            :target="`questionnaire-question-delete-${_uid}`",
            header="Confirm Delete Question?",
            text="Are you sure you want to delete this question?",
            @confirm="deleteQuestion()")
    div.row.mb-0.bt-1
      div.col-12.m-0.text-danger(v-if="mutableQuestion && mutableQuestion.trigger_question_choices.length > 0")
        | This is a branched question and is triggered by question(s):
        ul.mb-0
          li(v-for="q in triggerQuestionTextArray" v-html="q")
    div.row.m-0.mb-3.mt-3.d-flex
      div.pl-0.col-8
        div.form-group.m-0
          div.bg-white
            rich-text-editor.border.rounded(
              :options="{ theme: 'bubble' }"
              v-model="mutableQuestion.text")

      div.mb-3.mt-3.col-4.align-self-center

    div.row.m-0          
      div.form-group.mb-0.flex-1(v-if="mutableQuestion.type && mutableQuestion.type !== 'free_form'")
        table.table.choice-editor-table.mb-0
          thead
            tr
              th.small.col-2 Options
              template(v-if="isSelfAssessment")
                th.small.col-1 Correct
                th.small.col-4 Recommendation
                  i.fal.fa-info-circle.ml-1#recommendation-text-box
                  b-tooltip(target="#recommendation-text-box") Text entered here will be provided as a recommendation in the self assessment results if the choice is marked as correct and not selected, or not marked as correct and selected.
                //- th.small.col-2(v-if="isR3sOrg") Formal Findings
                th.small.col-1 Criticality
                th.small.col-2.branch-question-header
                  | Branch Question
                  | #[i.fal.fa-info-circle#branch-question-header]
                  b-tooltip(target="#branch-question-header")
                    | Branched Questions will become visible after a certain answer is provided.
                    | Here, you can choose questions to appear after selected responses to this question. 
          tbody(v-for="(choice, cInd) in mutableChoices")
            choice-editor(
              v-if="!choice.is_deleted"
              :value="choice"
              :ind="cInd"
              :questionnaire="questionnaireAndSectionInfo"
              :question="question"
              @input="updateChoice(...arguments, cInd)"
              @correctChanged="setCorrectChoice"
              @addChoice="mutableChoices.push(newChoice())"
              @removeChoice="i => $set(mutableChoices[i], 'is_deleted', true)")
    div.row.m-0
      div.form-group(v-if="mutableQuestion.type && mutableQuestion.type !== 'free_form'")
        i.fal.fa-times.text-danger.mr-2.hidden-spacer
        button.btn.btn-sm.btn-outline.pl-0(
          @click="mutableChoices.push(newChoice())") + Add Option
    //- //- linking controls is only available to the internal top-level organization
    //- div(v-if="isR3sOrg")
    //-   hr
    //-   div.row.m-0
    //-     div.pl-0.col-8
    //-       div.bg-white.form-group.mb-1
    //-         strong Linked Controls:
    //-       div.form-group.mb-1
    //-         v-select(
    //-           v-model="selectedAssessment"
    //-           placeholder="Select template assessment to search controls"
    //-           :options="assessmentOptions")
    //-       div.form-group.mb-1
    //-         typeahead-input-new(
    //-           :disabled="!(selectedAssessment)"
    //-           placeholder="Search for control"
    //-           :src="selectedAssessment ? `/api/1.0/assessments/controls/search?id=${selectedAssessment.raw}` : 'null'"
    //-           :option-filter-function="filterFunction"
    //-           :custom-class="`small`"
    //-           :keys-from-response="`controls`"
    //-           :show-prop-function="controlSearchDisplay"
    //-           @input="addControl"
    //-         )
    //-         div.mb-1(v-if="linkedControls.length > 0")
    //-           div.d-flex.align-items-center(v-for="control in linkedControls")
    //-             a.mr-2(
    //-               :id="`remove-control-${control.id}`"
    //-               @click="unlinkControl(control.id)"
    //-             ) #[i.text-danger.fal.fa-times]
    //-             b-tooltip(
    //-               :target="`remove-control-${control.id}`"
    //-             )
    //-               div.text-danger Remove control mapping
    //-             div.pt-1.pb-2 {{controlSearchDisplay(control)}}
    //- hr
    div.form-group.text-center.d-flex.justify-content-space-between
      button.btn.btn-danger(@click="cancelChanges") Cancel Changes
      button.btn.btn-primary.save-button(@click="saveQuestion") Save
    
    confirmation-modal#confirm-delete-questions(
      @confirm="deleteQuestionAndAllChildren()",
      header="Confirm Delete",
      :text="confirmDeleteText"
    )
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import ChoiceEditor from './ChoiceEditor'
  import QuestionAdditionalOptionsPopover from './QuestionAdditionalOptionsPopover'
  import QuestionChoiceTemplatesPopover from '../../vendormanagement/QuestionChoiceTemplatesPopover'
  import ApiVendorManagement from '../../../factories/ApiVendorManagement'
  import StringHelpers from '../../../factories/StringHelpers'
  import TimeHelpers from '../../../factories/TimeHelpers'

  export default {
    props: {
      question: { type: Object, required: true },
      choices: { type: Array, default: null },
    },

    watch: {
      question: {
        handler(newQuestionInfo) {
          this.mutableQuestion = JSON.parse(JSON.stringify(newQuestionInfo))
          this.checkAndPopulateDefaultChoices()
        },
        deep: true,
      },

      choices: {
        handler(choices) {
          this.mutableChoices = JSON.parse(JSON.stringify(choices))
          this.checkAndPopulateDefaultChoices()
        },
        deep: true,
      },
    },

    data() {
      return {
        mutableQuestion: JSON.parse(JSON.stringify(this.question || {})),
        mutableChoices: JSON.parse(JSON.stringify(this.choices || [])),
        isMoveQuestionPopoverShowing: false,
        newSectionId: null,
        linkedControls: [],
        control: '',
        selectedAssessment: null,

        confirmDeleteText: '',
      }
    },

    computed: {
      ...mapState({
        types: (state) => state.vendormanagement.questionnaireQuestionTypes,
        pointValues: (state) => state.questionnaire.questionPointValues,
        assessmentOptions: (state) =>
          state.assessments.projects.map((assessment) => {
            return {
              label: assessment.name,
              raw: assessment.id,
            }
          }),
        // need to check if the team is r3s, since global templates and global controls
        // should only be used in global assessments
        isR3sOrg: (state) => state.session.current_team.id.toString() === '1',
        questionnaireAndSectionInfo: (state) =>
          state.vendormanagement.currentQuestionnaireAndSections,
        isSelfAssessment: (_, getters) => getters.isSelfAssessment,
      }),

      sections() {
        return this.questionnaireAndSectionInfo.sections
      },

      triggerQuestionTextArray() {
        const triggerQuestionIds =
          this.mutableQuestion.trigger_question_choices.map(
            (tqc) => tqc.question_id
          )

        return this.questionnaireAndSectionInfo.sections
          .flatMap((s) => s.questions)
          .flatMap((q) => {
            return triggerQuestionIds.indexOf(q.id) >= 0 ? [q.text] : []
          })
      },

      defaultChoices() {
        return [
          {
            id: null,
            value: 'Yes',
            text: 'Yes',
            is_correct: true,
            is_deleted: false,
          },
          {
            id: null,
            value: 'No',
            text: 'No',
            is_correct: false,
            is_deleted: false,
          },
          {
            id: null,
            value: 'Partial',
            text: 'Partial',
            is_correct: false,
            is_deleted: false,
          },
        ]
      },

      childQuestions() {
        // Returns an object of children question Ids as the keys and their texts as the value
        /// ex.
        // {
        //   id1: question1Text,
        //   id2: question2Text
        // }
        return this.mutableChoices.reduce((agg, curr) => {
          const triggerQuestions = curr.trigger_questions || []
          triggerQuestions.forEach((tq) => {
            agg[tq.id] = tq.text
          })
          return agg
        }, {})
      },
    },

    mounted() {
      this.checkAndPopulateDefaultChoices()
      this.getLinkedControls()
    },

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,
      stripHtmlTags: StringHelpers.stripHtmlTags,
      titleCase: StringHelpers.titleCase,

      checkAndPopulateDefaultChoices() {
        if (
          this.mutableChoices.length === 1 &&
          this.mutableChoices[0].value == null
        ) {
          // delete the placeholder null choice and replace with default choices.
          // see the ['questionnaires/question/update'] endpoint in src/libs/api/1.0/vendormanagement/index.ts for clarification
          this.mutableChoices[0].is_deleted = true
          this.mutableChoices = this.mutableChoices.concat(this.defaultChoices)
        }
      },

      async getLinkedControls() {
        this.linkedControls = await ApiVendorManagement.getControlsFromQuestion(
          this.question.id
        )
      },

      newChoice() {
        return {
          value: null,
          text: null,
          ordering: null,
          trigger_questions: [],
          is_correct: false,
          is_deleted: false,
        }
      },

      addTemplateChoices(groupInfo) {
        const { choices } = groupInfo
        this.mutableChoices = this.mutableChoices.map((c) => ({
          ...c,
          is_deleted: !c.text,
        }))
        this.mutableChoices = [
          ...this.mutableChoices,
          ...choices.map((c) => ({ ...this.newChoice(), ...c, id: null })),
        ]
      },

      setCorrectChoice(index, isCorrect) {
        this.mutableChoices = this.mutableChoices.map((c, i) => ({
          ...c,
          is_correct: i == index ? isCorrect : c.is_correct,
        }))
      },

      updateChoice(choice, index) {
        this.mutableChoices = Object.values({
          ...this.mutableChoices,
          [index]: choice,
        })
      },

      async moveQuestion() {
        try {
          if (!this.newSectionId)
            return window.toastr.error(
              `Make sure you've selected a section to move this question to.`
            )

          await ApiVendorManagement.moveQuestionnaireQuestion({
            id: this.mutableQuestion.id,
            groupId: this.newSectionId,
          })

          this.$emit('saved')
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async deleteQuestion() {
        if (Object.keys(this.childQuestions).length > 0) {
          // If the question to delete has branched questions, display the modal to confirm deleting branched questions
          const uniqueIds = new Set(Object.keys(this.childQuestions))
          this.confirmDeleteText =
            '<p>This will also delete the following Branched Questions:</p>'
          this.confirmDeleteText += '<ul>'
          uniqueIds.forEach((id) => {
            this.confirmDeleteText += `<li>${this.childQuestions[id]}</li>`
          })
          this.confirmDeleteText += '</ul>'
          this.confirmDeleteText +=
            '<p>To retain these questions, you must first un-branch them from the question you want to delete.'
          $(`#confirm-delete-questions`).modal('show')
        } else {
          await ApiVendorManagement.updateQuestionnaireQuestion({
            id: this.mutableQuestion.id,
            is_deleted: true,
          })
          this.$emit('deleted')
        }
      },

      async deleteQuestionAndAllChildren() {
        try {
          const uniqueIds = new Set(Object.keys(this.childQuestions))
          uniqueIds.add(this.mutableQuestion.id)

          await Promise.all(
            Array.from(uniqueIds).map((id) => {
              return ApiVendorManagement.updateQuestionnaireQuestion({
                id: id,
                is_deleted: true,
              })
            })
          )
        } catch (err) {
          window.toastr.error('There was a problem deleting the questions.')
        } finally {
          window.toastr.success('Successfully deleted question and children.')
          this.$emit('deleted')
        }
      },

      async cancelChanges() {
        this.$emit('cancel')
      },

      async saveQuestion() {
        try {
          const q = this.mutableQuestion
          const choices = this.mutableChoices
          if (!q.text)
            return window.toastr.error(
              `Please make sure you have question text entered.`
            )

          if (!q.type)
            return window.toastr.error(
              `Please make sure you have question type selected.`
            )

          if (q.type !== 'free_form') {
            if (!choices || choices.length === 0)
              return window.toastr.error(
                `Please make sure you have at least one choice added.`
              )

            const choicesAreValid = (choices || [])
              .filter((c) => !c.is_deleted)
              .reduce((isValid, choice) => {
                if (!choice.text) return false
                return isValid
              }, true)

            if (!choicesAreValid)
              return window.toastr.error(
                `Please make sure the choices you've added at least have text added.`
              )
          }

          const { id } = await ApiVendorManagement.updateQuestionnaireQuestion(
            this.mutableQuestion
          )

          await Promise.all([
            ApiVendorManagement.postControlQuestionMap(
              this.question.id,
              this.linkedControls.map((c) => c.id)
            ),
            choices.map(async (choice, choiceInd) => {
              await ApiVendorManagement.updateQuestionnaireChoice({
                ...choice,
                ordering: choiceInd + 1,
                question_id: id,
              })
            }),
          ])

          window.toastr.success(`Successfully saved question!`)
          this.$emit('saved')
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      controlSearchDisplay(control) {
        return `${control.control_number}: ${control.name}`
      },

      addControl(control) {
        this.linkedControls.push(control)
      },

      unlinkControl(controlId) {
        this.linkedControls = this.linkedControls.filter(
          (lc) => lc.id !== controlId
        )
      },

      filterFunction(control) {
        return !this.linkedControls.map((lc) => lc.id).includes(control.id)
      },
    },

    components: {
      ChoiceEditor,
      QuestionAdditionalOptionsPopover,
      QuestionChoiceTemplatesPopover,
    },
  }
</script>

<style lang="scss">
  .table.choice-editor-table {
    border: none;
    border-collapse: inherit;
    th,
    td {
      border: none !important;
      padding: 0.25rem;
    }
  }

  .card {
    flex: 1;
  }

  .hidden-spacer {
    opacity: 0;
  }

  .save-button {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .flex-1 {
    flex: 1;
  }

  .question-container {
    overflow: visible !important;
  }
</style>
