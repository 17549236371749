var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"main-footer d-flex p-2 px-3 border-top"},[_c('ul',{staticClass:"nav"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/"}},[_vm._v("Home")])],1),_vm._m(0),_vm._m(1),(_vm.isLoggedIn)?[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"javascript:void(0)"},on:{"click":_vm.openJiraWidget}},[_vm._v("Support Request")])]),_vm._m(2)]:_vm._e(),_c('li',{staticClass:"nav-item border-left"}),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)],2),_c('span',{staticClass:"copyright ml-auto my-auto mr-2"},[_vm._v("Copyright © "+_vm._s(_vm.getFormattedDate(new Date(), 'YYYY'))),_c('a',{staticClass:"ml-2",attrs:{"href":"https://risk3sixty.com","target":"_blank","rel":"noopener noreferrer"}},[_c('img',{staticClass:"d-inline-block align-middle",staticStyle:{"max-width":"50px"},attrs:{"src":_vm.getR3sLogo}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"https://risk3sixty.com/about","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("About")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"https://risk3sixty.com/privacy-policy","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Privacy Policy")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"https://risk3sixty.zendesk.com/hc/en-us/signin?return_to=https%3A%2F%2Frisk3sixty.zendesk.com%2Fhc%2Fen-us","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Knowledge Base")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-item",attrs:{"href":"https://www.facebook.com/risk3sixty","target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"fab fa-facebook"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-item",attrs:{"href":"https://twitter.com/risk3sixty","target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"fab fa-twitter"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-item",attrs:{"href":"https://www.instagram.com/risk3sixty","target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"fab fa-instagram"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-item",attrs:{"href":"https://www.linkedin.com/company/risk3sixty","target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"fab fa-linkedin"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-item",attrs:{"href":"https://github.com/risk3sixty","target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"fab fa-github"})])])
}]

export { render, staticRenderFns }