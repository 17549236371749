<template lang="pug">
  user-avatar-editable-group(
    :owners="owners",
    :groupMaxWidth="groupMaxWidth",
    @add="addUser",
    @delete="deleteOwner")
</template>

<script>
  import ApiRiskRegister from '../../factories/ApiRiskRegister'

  export default {
    props: {
      itemId: { type: [Number, String] },
      owners: { type: Array },
      groupMaxWidth: { type: Number, default: 100 },
    },

    computed: {
      ownerUserIds() {
        return (this.owners || []).map((o) => o.user_id || o.id)
      },
    },

    methods: {
      async addUser(newUserId) {
        try {
          const newUserIds = [...new Set([...this.ownerUserIds, newUserId])]
          await ApiRiskRegister.updateItemOwners(this.itemId, newUserIds)
          this.$emit('updated')
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async deleteOwner(userId) {
        try {
          await ApiRiskRegister.removeItemOwner(this.itemId, userId)
          this.$emit('updated')
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },
  }
</script>
