import { render, staticRenderFns } from "./ChoiceEditor.vue?vue&type=template&id=634a035c&scoped=true&lang=pug"
import script from "./ChoiceEditor.vue?vue&type=script&lang=js"
export * from "./ChoiceEditor.vue?vue&type=script&lang=js"
import style0 from "./ChoiceEditor.vue?vue&type=style&index=0&id=634a035c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "634a035c",
  null
  
)

export default component.exports