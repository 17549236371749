<template lang="pug">
  div
    comment-list(
      :comments="comments"
      :mention-users="mentionUsers"
      v-model="newComment"
      @createComment="addUpdateComment"
      @updateComment="addUpdateComment"
      @deleteComment="deleteComment")
</template>

<script>
  import ApiComments from '../../factories/ApiComments'

  export default {
    props: {
      entityId: { type: [Number, String], required: true },
      entityTable: { type: [String], required: true },
      mentionUsers: { type: Array, default: null },
    },

    data() {
      return {
        newComment: null,
        comments: [],
      }
    },

    watch: {
      async entityId() {
        await this.getComments()
      },
    },

    methods: {
      async addUpdateComment(
        { id, comment, mentions, internalOnly },
        deleteComment = false
      ) {
        try {
          const finalComm = comment || this.newComment
          await ApiComments.createOrUpdateAndLink(
            {
              id,
              comment: finalComm,
              mentions,
              internalOnly,
              table: this.entityTable,
              entityId: this.entityId,
            },
            deleteComment
          )
          await this.getComments()

          this.$emit('updated', {
            id,
            comment: finalComm,
            mentions,
            internalOnly,
          })
          this.newComment = null
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async deleteComment(id) {
        await this.addUpdateComment({ id }, true)
      },

      async getComments() {
        try {
          const { comments } = await ApiComments.getByEntity(
            this.entityTable,
            this.entityId
          )
          this.comments = comments
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    async created() {
      await this.getComments()
    },
  }
</script>
