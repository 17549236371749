<template lang="pug">
  div.card
    div.card-header.d-flex.align-items-center
      div.btn-group.btn-group-sm
        button.small.btn.btn-toggle.btn-white(type="button",@click="removeRow((value || []).length - 1)")
          div.d-flex.align-items-center
            i.fal.fa-minus
        button.small.btn.btn-toggle.btn-white(type="button",@click="addNewRow")
          div.d-flex.align-items-center
            i.fal.fa-plus
      div.ml-auto
        a(@click="resetData",:id="`reset-${_uid}`")
          i Reset #[i.fal.fa-info-circle]
          b-tooltip(:target="`reset-${_uid}`")
            | This will reset your table to the data that was
            | last saved. If you made changes here and want to clear those changes,
            | click here to reset them back to the last saved state.
    div.card-body.p-0.table-responsive
      table.table.table-bordered.small-padding
        thead.table-header
          tr
            th #
            th(v-for="col in columns") {{ col.text || col.value }}
            th
        tbody
          tr(v-if="!value || value.length === 0")
            td(colspan="100%")
              i None specified yet...
          tr(v-else,v-for="(row, ind) in value")
            td {{ ind + 1 }}
            td(v-for="col in columns")
              input.form-control(v-model="row[col.value]")
            td
              a(@click="removeRow(ind)")
                i.fal.fa-times.text-danger
    branded-card-footer
</template>

<script>
  export default {
    props: {
      columns: { type: Array, required: true }, // [{value: 'internal_col_val', text: 'Optional Text Value'}, {...}]
      value: { type: Array, default: null },
    },

    data() {
      return {
        initialValueCopyJson: null,
      }
    },

    methods: {
      resetData() {
        this.$emit('input', JSON.parse(this.initialValueCopyJson))
      },

      addNewRow() {
        this.$emit(
          'input',
          (this.value || []).concat(
            Object.keys(this.columns).reduce(
              (o, key) => ({ ...o, [key]: null }),
              {}
            )
          )
        )
      },

      removeRow(index) {
        let valueCopy = this.value.slice(0)
        valueCopy.splice(index, 1)
        this.$emit('input', valueCopy)
      },
    },

    created() {
      this.initialValueCopyJson = JSON.stringify(this.value)
    },
  }
</script>
