<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-hidden="true")
    div.modal-dialog(role="document")
      div.modal-content
        form(@submit="createRegister")
          div.modal-header
            h5.modal-title {{ (registerId) ? 'Update' : 'New' }} Risk Register
            button.close(type="button",data-dismiss="modal",aria-label="Close")
              span(aria-hidden="true") &times;
          div.modal-body
            div.form-group.mb-2
              label Name
              input.form-control(v-model="record.name")
            div.form-group.mb-2
              label Description
              textarea.form-control(v-model="record.description")
          div.modal-footer
            button.btn.btn-secondary(type="button",data-dismiss="modal") Cancel
            button.btn.btn-primary(type="submit") {{ (this.registerId) ? 'Update' : 'Create' }}
</template>

<script>
  import $ from 'jquery'
  import ApiRiskRegister from '../../factories/ApiRiskRegister'

  export default {
    props: {
      registerId: { type: [Number, String], default: null },
    },

    data() {
      return {
        record: {
          name: null,
          description: null,
        },
      }
    },

    methods: {
      resetRegisterRecord() {
        return (this.record = {
          name: null,
          description: null,
        })
      },

      async getRegister(id = this.registerId) {
        try {
          const { register } = await ApiRiskRegister.getRegister(id)
          this.record = register
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async createRegister(evt) {
        try {
          evt.preventDefault()

          if (!this.record.name)
            return window.toastr.error(
              `Please add a name of the register before saving.`
            )

          const { id } = await ApiRiskRegister.createRiskRegister(this.record)
          window.toastr.success(`Successfully updated risk register!`)
          this.$emit('created', id)
          $(`#${this.$el.id}`).modal('hide')
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    mounted() {
      $(`#${this.$el.id}`).on('shown.bs.modal', async () => {
        if (!this.registerId) return this.resetRegisterRecord()
        await this.getRegister()
      })
    },
  }
</script>
