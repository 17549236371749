<template lang="pug">
bubble(:options="options" :data="chartData")
</template>

<script>
  import {
    Chart as ChartJS,
    Tooltip,
    Legend,
    PointElement,
    LinearScale,
  } from 'chart.js'
  import { Bubble } from 'vue-chartjs'
  ChartJS.register(LinearScale, PointElement, Tooltip, Legend)
  export default {
    components: {
      Bubble,
    },
    props: {
      options: { type: Object, default: null },
      chartData: { type: Object, default: null },
    },
  }
</script>
