<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-labelledby="risk-register-formal-findings-label",aria-hidden="true")
    div.modal-dialog.modal-lg(role="document")
      div.modal-content
        form(@submit="mapFormalFindings")
          div.modal-header
            h5#risk-register-formal-findings-label.modal-title
              | Map Formal Findings to Risk Register
            button.close(type="button",data-dismiss="modal",aria-label="Close")
              span(aria-hidden="true") &times;
          div.modal-body
            div.form-group
              div(v-if="selectedAssessment")
                label.mb-0 Assessment:
                div
                  strong
                    | {{ selectedAssessment.name }}
                    | #[i.clickable.fal.fa-times.text-danger(@click="resetAssessment")]
              div(v-else)
                typeahead-input(
                  placeholder="Search for assessment (1 chars min)",
                  src="/api/1.0/assessments/projects/search",
                  :params="{ keysFromResponse: 'projects', showPropFunction: p => p.name, minChars: 1 }",
                  @onHit="selectAssessment")

            div.form-group
              div(v-if="selectedRiskRegister")
                label.mb-0 Risk Register:
                div
                  strong {{ selectedRiskRegister.name }} #[i.clickable.fal.fa-times.text-danger(@click="selectedRiskRegister = null")]
              div(v-else)
                select.form-control(v-model="selectedRiskRegister")
                  option(:value="null") Select risk register...
                  option(v-for="register in riskRegisters",:value="register") {{ register.name }}

            div(v-if="selectedAssessment")
              hr
              div(v-if="formalFindings.length === 0")
                i No formal findings found in assessment...
              div(v-else)
                label Select formal findings to map:
                div.small.text-center
                  i
                    | #[a(@click="updateSelectedFindings(false)") unselect all]
                    | /
                    | #[a(@click="updateSelectedFindings()") select all]
                ul.list-unstyled.pl-0.my-0.border.rounded
                  li.hover-fade.py-3.px-2.border-bottom(v-for="(finding, ind) in formalFindings")
                    div.custom-control.custom-checkbox.mr-2
                      input.custom-control-input(
                        :id="`finding-map-select-${finding.id}`",
                        type="checkbox",
                        v-model="finding.isSelected")
                      label.custom-control-label.w-100(:for="`finding-map-select-${finding.id}`")
                        div.d-flex.align-items-center
                          div.mr-1
                            pill-inline.wide(
                              :background="getFindingStatusColor(finding.level)"
                              :show-close="false")
                                div
                                  | {{ finding.level }}
                                  | #[i.small(v-if="!finding.is_published") (unpublished)]
                          div.overflow-ellipses.no-hover(
                            :id="`finding-summary-${_uid}-${finding.id}`",
                            v-html="stripHtmlTags(finding.summary || '')")
                          b-tooltip(:target="`finding-summary-${_uid}-${finding.id}`")
                            div(v-html="finding.summary")
                    div.form-group.small.mb-0.ml-4.mt-3(v-if="finding.isSelected")
                      label.m-0 Risk Register Category#[span.text-danger *]
                      select.form-control.form-control-sm(v-model="finding.risk_register_category_id")
                        option(:value="null") Select category...
                        option(v-for="cat in riskRegisterCategories",:value="cat.id") {{ cat.name }}
          div.modal-footer
            button.btn.btn-secondary(type="button",data-dismiss="modal") Cancel
            button.btn.btn-primary(type="submit") Map Findings
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import Utilities from '@risk3sixty/utilities'
  import ApiAssessments from '../../factories/ApiAssessments'
  import ApiRiskRegister from '../../factories/ApiRiskRegister'

  export default {
    props: {
      assessmentId: { type: [Number, String] },
      registerId: { type: [Number, String] },
    },

    data() {
      return {
        selectedAssessment: null,
        selectedRiskRegister: null,
        formalFindings: [],
        formalFindingsStatuses: [],
      }
    },

    computed: {
      ...mapState({
        riskRegisters: (state) => state.riskregister.riskRegisters,

        riskRegisterCategories(state) {
          return (
            state.riskregister.categories[this.selectedRiskRegisterId] || []
          )
        },
      }),

      selectedRiskRegisterId() {
        return (
          (this.selectedRiskRegister || {}).id ||
          this.registerId ||
          0
        ).toString()
      },
    },

    methods: {
      stripHtmlTags: Utilities.Strings.stripHtmlTags,

      getFindingStatusColor(findingLevel) {
        try {
          const status = this.formalFindingsStatuses.find(
            (s) => s.value === findingLevel
          )
          return this.$store.state.dashboard.colors[status.color]
        } catch (err) {
          return ''
        }
      },

      updateSelectedFindings(newVal = true) {
        this.formalFindings.forEach((f) => (f.isSelected = newVal))
      },

      resetAssessment() {
        this.selectedAssessment = null
        this.formalFindings = []
      },

      async selectAssessment(ass) {
        this.selectedAssessment = ass
        await this.getFormalFindings()
      },

      async mapFormalFindings(evt) {
        try {
          evt.preventDefault()
          // TODO: make sure there is an assessment, register, and
          // selected formal findings to add to the register
          const regId = (this.selectedRiskRegister || {}).id
          const findings = this.formalFindings.filter((f) => f.isSelected)

          if (!(regId && findings.length > 0))
            return window.toastr.error(
              `Be sure to select a risk register and at least one formal finding to map to risk register items.`
            )

          if (findings.find((f) => !f.risk_register_category_id))
            return window.toastr.error(
              `Make sure all selected findings have a risk register category selected to add them to the register.`
            )

          await Promise.all(
            findings.map(async (finding) => {
              const isMapped = await ApiRiskRegister.checkIfMappingExists(
                this.selectedRiskRegister.id,
                'assessment_formal_findings',
                finding.id
              )

              // if an item already exists mapped to this
              // formal finding ignore mapping again
              if (isMapped) return

              const { id } = await ApiRiskRegister.createOrUpdateItem({
                risk_register_id: this.selectedRiskRegister.id,
                risk_register_category_id: finding.risk_register_category_id,
                description: finding.summary,
                risk_description: finding.associated_risk,
                recommendation: finding.remediation_guidance,
                root_cause: finding.root_cause,
                mgmt_action_plan: finding.action_plan,
                end_date: finding.due_date,
              })

              await Promise.all([
                ApiRiskRegister.updateItemOwners(id, [finding.owner]),
                ApiRiskRegister.mapItemToEntity({
                  itemId: id,
                  table: 'assessment_formal_findings',
                  entityId: finding.id,
                }),
              ])
            })
          )

          this.$emit('mapped')
          $(`#${this.$el.id}`).modal('hide')
          window.toastr.success(
            `Successfully mapped findings to your risk register: ${this.selectedRiskRegister.name}.`
          )
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async getFormalFindings() {
        try {
          if (!this.selectedAssessment)
            return window.toastr.error(
              `Select a valid assessment before fetching formal findings to map.`
            )

          const { findings, statuses } = await ApiAssessments.getFormalFindings(
            this.selectedAssessment.id
          )
          this.formalFindings = findings.map((f) => ({
            ...f,
            isSelected: f.is_published ? true : false,
            risk_register_category_id: null,
          }))
          this.formalFindingsStatuses = statuses
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    async mounted() {
      await this.$store.dispatch('getRegisters', { reset: true })

      $(`#${this.$el.id}`).on('show.bs.modal', async () => {
        await Promise.all([
          this.$store.dispatch('initRiskRegister'),

          (async () => {
            if (this.assessmentId) {
              const { project } = await ApiAssessments.getProject(
                this.assessmentId
              )
              this.selectedAssessment = project
              await this.getFormalFindings()
            }
          })(),

          (async () => {
            if (this.registerId) {
              this.selectedRiskRegister = this.riskRegisters.find(
                (r) => r.id === this.registerId
              )
            } else if (this.riskRegisters.length === 1) {
              this.selectedRiskRegister = this.riskRegisters[0]
            }
          })(),
        ])
      })
    },
  }
</script>
