<template lang="pug">
  tbody.border-0
    tr
      td
        label.m-0 Purpose
        input.form-control.form-control-vsm(v-model="component.item_name")
      td
        label.m-0 Tool Name
        input.form-control.form-control-vsm(v-model="component.tool_name")
      td(colspan="100%")
        label.m-0 Description
        div.d-flex.align-items-center
          textarea.form-control.form-control-vsm(v-model="component.description")
          button.ml-1.btn.btn-vsm.btn-primary.nowrap(@click="createComponent()") Save Component
</template>

<script>
  import ApiAssessments from '../../factories/ApiAssessments'

  export default {
    props: {
      projectId: { type: [Number, String] },
      category: { type: String },
    },

    data() {
      return {
        component: {
          item_type: 'custom',
          item_name: null,
          // category: null,
          tool_name: null,
          description: null,
        },
      }
    },

    methods: {
      async createComponent(evt) {
        try {
          if (evt) evt.preventDefault()

          if (!(this.component.item_name && this.component.tool_name))
            return window.toastr.error(
              `Please enter at least a component purpose and tool name to proceed.`
            )

          const newComponent = {
            ...this.component,
            category: this.category,
            assessment_project_id: this.projectId,
          }
          await ApiAssessments.updateSystemComponent(newComponent)
          window.toastr.success(`Successfully created your component!`)

          this.$emit('created', newComponent)
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },
  }
</script>
