<template lang="pug">
  div
    div.mb-2
      div
        small PROJECT
      div.d-flex.align-items-center(v-if="projectRecord")
        a(
          :href="`/assessments/evidence/${projectRecord.id}`"
          target="_blank"
          rel="noopener noreferrer")
            strong {{ projectRecord.name }}
            span.ml-1(v-if="projectRecord.audit_period_end")
              | ({{ getFormattedDate(projectRecord.audit_period_end, 'MMM YYYY', true) }})
        i.ml-1.clickable.fal.fa-times.text-danger(@click="reset")
      div(v-else)
        typeahead-input-new(
          placeholder="Search for project (1 chars min)"
          src="/api/1.0/assessments/projects/search"
          keys-from-response="projects"
          :show-prop-function="p => `${p.name} (${p.status})`"
          @input="selectProject")
    div.mb-2(v-if="projectRecord")
      div
        small REQUEST
      div(v-if="requestRecord")
        div.d-flex.align-items-center
          a(
            :href="`/assessments/evidence/${projectRecord.id}/${requestRecord.id}`"
            target="_blank"
            rel="noopener noreferrer")
              span {{ requestRecord.project_ref_num }} - #[strong {{ requestRecord.name }}]
          i.ml-1.clickable.fal.fa-times.text-danger(@click="$emit('input', {...value, request: null})")
      div(v-else)
        typeahead-input-new(
          placeholder="Search for an evidence request",
          :src="`/api/1.0/assessments/project/request/search?id=${projectRecord.id}`",
          keys-from-response="requests"
          :show-prop-function="r => `${r.project_ref_num} - ${r.name}`"
          @input="selectRequest")
</template>

<script>
  import TimeHelpers from '../../factories/TimeHelpers'

  export default {
    props: {
      value: { type: Object, default: null },
    },

    computed: {
      projectRecord() {
        return (this.value || {}).project
      },

      requestRecord() {
        return (this.value || {}).request
      },
    },

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,

      selectProject(project) {
        this.$emit('input', {
          ...this.value,
          project,
        })
      },

      selectRequest(request) {
        this.$emit('input', {
          ...this.value,
          request,
        })
      },

      reset() {
        this.$emit('input', {
          project: null,
          request: null,
        })
        this.$emit('reset')
      },
    },
  }
</script>
