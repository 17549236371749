<template lang="pug">
  div.pdf-viewer-wrapper(v-dragscroll='true')
    div(v-if="loading") Loading page count...
    div.d-flex.align-items-center(v-else)
      div.mr-2(v-if="showAllPages") {{ pageCount }} page(s)
      template(v-else)
        button.btn.btn-white.p-1.m-1(:disabled="page <= 1", @click="page--") <
        div.mx-1 {{page}} / {{pageCount}}
        button.btn.btn-white.p-1.m-1(:disabled="page >= pageCount", @click="page++") >
      div.custom-control.custom-checkbox
        input#show-all-pages.custom-control-input(
          type="checkbox",
          v-model="showAllPages")
        label.custom-control-label(for="show-all-pages") Show All Pages
    vue-pdf-embed.pdf-page.grabbable(
      ref="pdfRef",
      :source="src",
      :page="page",
      @rendered="handleDocumentRender"
      @rendering-failed="onError"
      @loading-failed="onError")
</template>

<script>
  import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
  import { dragscroll } from 'vue-dragscroll'

  export default {
    props: {
      src: { type: [String, Uint8Array] },
    },

    watch: {
      showAllPages() {
        this.page = this.showAllPages ? null : 1
      },
    },

    data() {
      return {
        page: null,
        pageCount: 1,
        loading: true,
        showAllPages: true,
      }
    },

    methods: {
      handleDocumentRender() {
        this.loading = false
        this.pageCount = this.$refs.pdfRef.pageCount
      },

      onError() {
        this.$emit('error', arguments)
      },
    },

    components: {
      VuePdfEmbed,
    },

    directives: {
      dragscroll,
    },
  }
</script>

<style lang="scss">
  .pdf-viewer-wrapper {
    overflow: auto;
    max-height: 60vh;
    &.zoom-active {
      cursor: grab;
    }
  }
</style>
