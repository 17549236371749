<template lang="pug">
  div
    pill-status-updater(
      :id="itemDecisionUpdaterId"
      v-model="mutableDecision",
      :is-hollow="true",
      :options="decisionPillUpdaterOptions",
      @input="updateItemDecision")

    b-tooltip(
      v-if="mutableDecision && !(['not_applicable', 'not_set'].includes(mutableDecision))", 
      :target="itemDecisionUpdaterId" 
      placement="right")
      div(v-html="glossary[mutableDecision.toUpperCase()]")

    update-item-field-reason-modal(
      field="treatment decision",
      :unique-id="uniqueId",
      :new-value="mutableDecision",
      @submitReason="updateReason"
      @cancel="mutableDecision = decision")
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import ApiRiskRegister from '../../factories/ApiRiskRegister'
  import StringHelpers from '../../factories/StringHelpers'
  import { glossary } from '../../constants/glossary'

  export default {
    props: {
      itemId: { type: [Number, String], required: true },
      decision: { type: String, default: null },
      reason: { type: String, default: null },
    },

    watch: {
      decision(newDecision) {
        this.mutableDecision = newDecision
      },
    },

    data() {
      return {
        mutableDecision: this.decision,
        mutableReason: this.reason,
        uniqueId: this._uid,
        glossary: glossary.riskRegister.treatmentDecisions,
      }
    },

    computed: {
      ...mapState({
        decisions: (state) => state.riskregister.decisions,

        decisionPillUpdaterOptions(state) {
          return state.riskregister.decisions.map((decision) => {
            return {
              value: decision.value,
              text: decision.text,
              color: state.dashboard.variantColors()[decision.variant],
            }
          })
        },
      }),

      itemParamModalId() {
        return `#update-item-param-modal-${this.uniqueId}`
      },

      itemDecisionUpdaterId() {
        return `update-item-decision-${this.uniqueId}`
      },
    },

    methods: {
      titleCase: StringHelpers.titleCase,

      updateReason(statusReason) {
        $(this.itemParamModalId).modal('hide')
        this.mutableReason = statusReason
        this.saveItem()
      },

      async updateItemDecision(newDecision = this.mutableDecision) {
        try {
          if (
            this.decisions.find((d) => d.value === newDecision).mod1 === 'true'
          ) {
            return $(this.itemParamModalId).modal('show')
          } else {
            this.saveItem(newDecision)
          }
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async saveItem(newDecision = this.mutableDecision) {
        await ApiRiskRegister.createOrUpdateItem({
          id: this.itemId,
          treatment_decision: newDecision,
          status_reason: this.mutableReason,
        })
        this.$emit('updated', newDecision)
      },
    },

    mounted() {
      // This is here due to this issue
      // https://stackoverflow.com/questions/10636667/bootstrap-modal-appearing-under-background
      $(this.itemParamModalId).appendTo('body')
    },

    beforeDestroy() {
      // See comments in `mounted` as to why this needs to be here.
      $(this.itemParamModalId).remove()
    },
  }
</script>
