<template lang="pug">
  div.card.card-small
    div.card-header.border-bottom
      div.small.d-flex.align-items-center
        router-link(to="/risk/register")
          strong Active Risk Registers
        div.ml-auto
          select.form-control.form-control-sm(v-model="registerInfo.perPage",@change="changePerPage()")
            option 5
            option 10
            option 15
            option 20
            option 25
            option 50
    div.card-body.p-0
      div(v-if="isLoadingLocal")
        loader
      div.table-responsive.mb-0(v-else)
        table.table.small-padding.small.mb-0
          thead.bg-light
            tr
              th #
              th(v-if="isInternalUser") Org
              th Risk Register
              - //th Description
              th.text-center Created
          tbody.hover-fade
            tr.no-hover(v-if="registerInfo.data.length === 0")
              td(colspan="100%")
                i No active risk registers currently...
            tr.clickable(v-else,v-for="(register, index) in registerInfo.data",@click="goToRegister(register)")
              td {{ getRowNumber(index) }}.
              td.nowrap(v-if="isInternalUser") {{ register.team_name }}
              td.nowrap
                div {{ register.name }}
              - //td.nowrap 
              - //  div {{ register.description }}
              td.text-center.nowrap {{ getFormattedDate(register.created_at, 'MMM D, YYYY') }}
    div.card-footer.border-top
      div.d-flex.justify-content-end
        pagination(
          :info="registerInfo",
          size="sm"
          @changePage="changePage")
</template>

<script>
  import { mapState } from 'vuex'
  import ApiRiskRegister from '../../../../factories/ApiRiskRegister'
  import ApiTeams from '../../../../factories/ApiTeams'
  import ApiUsers from '../../../../factories/ApiUsers'
  import TimeHelpers from '../../../../factories/TimeHelpers'

  export default {
    data() {
      return {
        isLoadingLocal: true,
        registerInfo: {
          currentPage: 1,
          data: [],
          numberPages: 1,
          perPage: 10,
          totalCount: 0,
        },
      }
    },

    computed: mapState({
      isInternalUser: (_, getters) => getters.isInternalUser,
    }),

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,

      async goToRegister(register) {
        await ApiTeams.switch(
          this,
          register.team_id,
          encodeURIComponent(`/risk/register/${register.id}`)
        )
      },

      getRowNumber(index) {
        return (
          this.registerInfo.perPage * (this.registerInfo.currentPage - 1) +
          (index + 1)
        )
      },

      async changePerPage() {
        const newOption = { value: this.registerInfo.perPage }
        await Promise.all([
          this.changePage(1),
          ApiUsers.updatePersonalization(
            `dashboard_risk_registers_list_per_page`,
            newOption
          ),
        ])

        this.$store.commit('SET_USER_PERSONALIZATIONS', {
          dashboard_risk_registers_list_per_page: {
            parameters: newOption,
          },
        })
      },

      async changePage(newPage) {
        this.registerInfo.currentPage = newPage
        await this.getRiskRegisters()
      },

      async getRiskRegisters() {
        try {
          const { info } = await ApiRiskRegister.getDashboardRegisters(
            this.registerInfo.currentPage,
            this.registerInfo.perPage
          )

          this.registerInfo = info
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.isLoadingLocal = false
        }
      },
    },

    async created() {
      const perPageParams = this.$store.state.getPersonalizationParams(
        'dashboard_risk_registers_list_per_page'
      )
      this.registerInfo.perPage =
        (perPageParams && perPageParams.value) || this.registerInfo.perPage

      await this.getRiskRegisters()
    },
  }
</script>
