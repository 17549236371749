<template lang="pug">
form(@submit="sendQuestionnaire")
  div
    div
      h6 Vendor
      div.form-group
        label Choose vendor: {{ createNewVendorOption ? '(or' : '' }} 
          a(v-if="createNewVendorOption",@click="showCreateVendor") Add New)
        v-select(
          v-model="selectedTeamVendorId"
          :options="teamVendors"
          :reduce="v => v.id"
          label="name"
          @input="teamVendorUpdated")

    div(v-if="selectedTeamVendorId")
      h6 Review
      div.form-group
        label Choose review: {{ createNewReviewOption ? '(or' : '' }} 
          a(v-if="createNewReviewOption",@click="showCreateReview") Create New)
        select.form-control.form-control-sm(v-model="selectedReviewId")
          option(:value="null") Select a review..
          option(v-for="review in reviews",:value="review.id") {{ review.title }}

    div(v-if="selectedReviewId || selectedQuestionnaireId")
      h6 Questionnaire
      div.form-group
        label Choose questionnaire: {{ createNewQuestionnaireOption && $route.path !== '/vendor/management/qbuilder' ? '(or' : '' }} 
          router-link(
            v-if="createNewQuestionnaireOption && $route.path !== '/vendor/management/qbuilder'",
            to="/vendor/management/qbuilder") Create New)
        select.form-control.form-control-sm(v-model="selectedQuestionnaireId")
          option(:value="null") Select a questionnaire..
          option(v-for="questionnaire in questionnaires",:value="questionnaire.id") {{ questionnaire.name }}
      div.form-group(v-if="questionnaire.is_template")
        input.form-control.form-control-sm(:placeholder="'Enter new questionnaire name...'", v-model="newQuestionnaireName")
        small.text-info *Chosen questionnaire is a template. Enter a name for the new questionnaire that will be sent, otherwise it will default to '{Template Name} ({Vendor Name})'    
    div(v-if="selectedReviewId && selectedQuestionnaireId")
      h6 Vendor Contacts
      div.form-group
        label Choose vendor contact: {{ createNewContactOption ? '(or' : '' }} 
          a(v-if="createNewContactOption",@click="showCreateContact") Create New)
        select.form-control.form-control-sm(v-model="selectedAssignee",@change="addUserToQuestionnaire()")
          option(:value="null") Select a contact..
          option(v-for="user in vendor.vendor_users",:value="user") {{ user.username_email }}
      
      div(v-if="!questionnaire.vendor_users || questionnaire.vendor_users.length === 0")
        small No vendor contacts selected yet...
      div(v-else)
        label Assigned vendor contacts:
        user-list(
          hover="uuid",
          :users="questionnaire.vendor_users",
          :bordered="true",
          :can-edit-user="false",
          :can-create-user="false",
          @removeUser="removeUserFromQuestionnaire")
      hr
    div(v-if="selectedReviewId && selectedQuestionnaireId")
      h6 Questionnaire Reviewers
      div.form-group
        label Assign questionnaire reviewers:
        select.form-control.form-control-sm(v-model="reviewUser",@change="addReviewer(reviewUser)")
          option(:value="null") Select a reveiwer..
          option(v-for="user in usersWithPermission",:value="user") {{ user.name || user.username_email }}
      
      div(v-if="!questionnaire.reviewers || questionnaire.reviewers.length === 0")
        small No reviewers selected yet...
      div.d-flex.align-items-center(v-else)
        user-avatar.mr-1(
          v-for="(user, ind) in questionnaire.reviewers",
          :key="user.id",
          :id="`questionnaire-reviewer-user-avatar-${user.id}-${_uid}`",
          :user-id="user.id",
          @close="removeReviewer(ind)")
      hr
    div(v-if="questionnaire.name && selectedReviewId && vendor.name")
      label Sending <b> {{ questionnaire.name }} </b> questionnaire to <b> {{ vendor.name }} </b>        
      br
      h6 Message to Contact(s) #[i#message-tooltip.fal.fa-info-circle.small]
      b-tooltip(target="#message-tooltip") Optional message to be displayed in notification email and on questionnaire landing page.
      rich-text-editor(v-model="questionnaireMessage")
      hr

  div.d-flex.justify-content-end
    button.btn.btn-secondary.mr-2(v-if="showCancel",type="button",data-dismiss="modal") Cancel
    button.btn.btn-primary(type="submit" :disabled="isSending") Send #[loader-inline(v-if="isSending")]
</template>

<script>
  import { mapState } from 'vuex'
  import ApiVendorManagement from '../../factories/ApiVendorManagement'

  export default {
    props: {
      vendorId: { type: [Number, String], default: null },
      reviewId: { type: [Number, String], default: null },
      questionnaireId: { type: [Number, String], default: null },
      createNewVendorOption: { type: Boolean, default: true },
      createNewReviewOption: { type: Boolean, default: true },
      createNewQuestionnaireOption: { type: Boolean, default: true },
      createNewContactOption: { type: Boolean, default: true },
      showCancel: { type: Boolean, default: true },
    },

    data() {
      return {
        selectedTeamVendorId: this.vendorId,
        selectedReviewId: this.reviewId,
        selectedQuestionnaireId: this.questionnaireId,
        selectedAssignee: null,
        reviewUser: null,
        questionnaireMessage: null,
        newQuestionnaireName: null,
        isSending: false,
      }
    },

    watch: {
      vendorId: {
        handler(newVal) {
          this.selectedTeamVendorId = newVal
        },
      },

      reviewId: {
        handler(newVal) {
          this.selectedReviewId = newVal
        },
      },

      questionnaireId: {
        handler(newVal) {
          this.selectedQuestionnaireId = newVal

          if (this.selectedQuestionnaireId) {
            const questIsGood = this.questionnaires.find(
              (q) => q.id == this.selectedQuestionnaireId
            )
            if (!questIsGood) this.selectedQuestionnaireId = null
          }
        },
      },
    },

    computed: {
      ...mapState({
        usersWithPermission: (state) =>
          state.getUsersWithPerrmissionToModule(
            'is_vendor_management_customer'
          ),

        vendors: (state) => state.vendormanagement.vendors,
        categories: (state) => state.vendormanagement.categories,

        teamVendors: (state) => state.vendormanagement.teamVendors.data,
        allQuestionnaires: (state) =>
          state.vendormanagement.questionnaires.data,
        questionnaireTemplates: (state) =>
          state.vendormanagement.questionnaireTemplates.data,
      }),

      vendor() {
        return (
          this.teamVendors.find((tv) => tv.id == this.selectedTeamVendorId) ||
          {}
        )
      },

      reviews() {
        return this.vendor.reviews
      },

      review() {
        return this.reviews.find((r) => r.id === this.selectedReviewId)
      },

      questionnaires() {
        const allDraftQs = this.allQuestionnaires
          .filter((q) => q.status === 'draft')
          .concat(
            this.questionnaireTemplates.map((t) => {
              const templateType =
                t.team_id == 1 ? 'Global Template' : 'Template'
              return { ...t, name: `${t.name} (${templateType})` }
            })
          )
        const allVendorQs = this.selectedTeamVendorId
          ? allDraftQs.filter(
              (q) =>
                q.team_vendor_id == this.selectedTeamVendorId ||
                q.team_vendor_id == null
            )
          : allDraftQs
        const allReviewQs = this.selectedReviewId
          ? allVendorQs.filter(
              (q) =>
                q.team_vendor_review_id == this.selectedReviewId ||
                q.team_vendor_review_id == null
            )
          : allVendorQs
        return allReviewQs
      },

      questionnaire() {
        this.selectedAssignee
        this.reviewUsers
        return (
          this.questionnaires.find(
            (q) => q.id == this.selectedQuestionnaireId
          ) || {}
        )
      },
    },

    methods: {
      closeModal() {
        this.$emit('close')
      },

      showCreateVendor() {
        this.$emit('showCreateVendor')
      },

      showCreateReview() {
        this.$emit('showCreateReview', this.questionnaire)
      },

      showCreateContact() {
        this.$emit('showCreateContact', this.selectedQuestionnaireId)
      },

      teamVendorUpdated(id) {
        this.checkAndResetQuestionnaire()
        this.$emit('teamVendorUpdated', id)
      },

      checkAndResetQuestionnaire() {
        if (!this.questionnaireId) this.resetQuestionnaire()
      },

      resetQuestionnaire() {
        this.selectedQuestionnaireId = null
      },

      async update() {
        await Promise.all([
          this.$store.dispatch('getTeamVendors', true),
          this.$store.dispatch('getReviews', true),
          this.$store.dispatch('getQuestionnaires', true),
        ])
      },

      async addUserToQuestionnaire(user = this.selectedAssignee) {
        this.questionnaire.vendor_users = this.questionnaire.vendor_users || []

        if (this.questionnaire.vendor_users.find((vu) => vu.id == user.id))
          return (this.selectedAssignee = null)

        if (this.questionnaire.is_template) {
          this.questionnaire.vendor_users.push(user)
          return (this.selectedAssignee = user)
        }

        try {
          await Promise.all([
            ApiVendorManagement.createUpdateQuestionnaire({
              ...this.questionnaire,
              team_vendor_id: this.selectedTeamVendorId,
            }),
            ApiVendorManagement.addUserToQuestionnaire(
              this.selectedQuestionnaireId,
              user.id
            ),
          ])
          this.selectedAssignee = null
          await this.update()
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async removeUserFromQuestionnaire(userId) {
        this.questionnaire.vendor_users = this.questionnaire.vendor_users || []

        if (this.questionnaire.is_template) {
          this.questionnaire.vendor_users =
            this.questionnaire.vendor_users.filter((u) => u.id != userId)
          return (this.selectedAssignee = null)
        }

        try {
          await ApiVendorManagement.removeUserFromQuestionnaire(
            this.selectedQuestionnaireId,
            userId
          )
          await this.update()

          if (
            !this.questionnaire.vendor_users ||
            this.questionnaire.vendor_users.length === 0
          )
            await ApiVendorManagement.createUpdateQuestionnaire({
              ...this.questionnaire,
              team_vendor_id: null,
            })
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async addReviewer(newUser) {
        this.questionnaire.reviewers = this.questionnaire.reviewers || []

        if (this.questionnaire.reviewers.find((r) => r.id == newUser.id))
          return (this.reviewUser = null)

        this.questionnaire.reviewers.push(newUser)
        this.reviewUser = newUser

        if (!this.questionnaire.is_template) await this.updateQuestionnaire()
      },

      async removeReviewer(ind) {
        this.questionnaire.reviewers.splice(ind, 1)
        this.reviewUser = null

        if (!this.questionnaire.is_template) await this.updateQuestionnaire()
      },

      async updateQuestionnaire(refresh = true) {
        try {
          await ApiVendorManagement.createUpdateQuestionnaire({
            ...this.questionnaire,
          })

          if (refresh) await this.update()
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async createQuestionnaireFromTemplate() {
        try {
          const templateQuestionnaire = this.questionnaireTemplates.find(
            (q) => q.id == this.questionnaire.id
          )

          const newQuestionnaire = {
            id: null,
            name:
              this.newQuestionnaireName ||
              `${templateQuestionnaire.name} (${this.vendor.name})`,
            description: this.questionnaire.description,
            template_id: this.questionnaire.id,
            due_date: this.questionnaire.due_date,
            reviewers: this.questionnaire.reviewers,
            team_vendor_id: this.selectedTeamVendorId,
            team_vendor_review_id: this.selectedReviewId,
          }

          const { id } = await ApiVendorManagement.createUpdateQuestionnaire(
            newQuestionnaire
          )

          await Promise.all(
            this.questionnaire.vendor_users.map(async (user) => {
              await ApiVendorManagement.addUserToQuestionnaire(id, user.id)
            })
          )

          return id
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async sendQuestionnaire(evt) {
        this.isSending = true
        try {
          evt.preventDefault()

          this.questionnaire.team_vendor_id =
            this.questionnaire.team_vendor_id || this.selectedTeamVendorId

          this.questionnaire.team_vendor_review_id =
            this.questionnaire.team_vendor_review_id || this.selectedReviewId

          if (!this.questionnaire.team_vendor_review_id)
            return window.toastr.error(
              'Please assign the questionnaire to a review before sending.'
            )

          if (
            !this.questionnaire.vendor_users ||
            this.questionnaire.vendor_users.length == 0
          )
            return window.toastr.error(
              'Please assign at least one contact to the questionnaire before sending.'
            )

          if (
            !this.questionnaire.reviewers ||
            this.questionnaire.reviewers.length == 0
          )
            return window.toastr.error(
              'Please assign at least one reviewer to the questionnaire before sending.'
            )

          // If template, create questionnaire from template,
          // and add all users and reviewers
          if (this.questionnaire.is_template) {
            const newQId = await this.createQuestionnaireFromTemplate()
            this.selectedQuestionnaireId = newQId
          } else {
            await this.updateQuestionnaire(false)
          }

          await ApiVendorManagement.deployQuestionnaire(
            this.selectedQuestionnaireId,
            this.questionnaireMessage
          )
          await this.update()
          this.$emit('send')
          this.closeModal()
          window.toastr.success(`Successfully deployed questionnaire!`)
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.isSending = false
        }
      },

      async updateAndSetQuestionnaire(id) {
        await this.update()
        this.selectedQuestionnaireId = id
      },
    },

    created() {
      if (this.selectedQuestionnaireId) {
        const questIsGood = this.questionnaires.find(
          (q) => q.id == this.selectedQuestionnaireId
        )
        if (!questIsGood) this.selectedQuestionnaireId = null
      }
    },
  }
</script>
