<template lang="pug">
  footer.main-footer.d-flex.p-2.px-3.border-top
    ul.nav
      li.nav-item
        router-link.nav-link(to="/") Home
      li.nav-item
        a.nav-link(href="https://risk3sixty.com/about",target="_blank",rel="noopener noreferrer") About
      li.nav-item
        a.nav-link(href="https://risk3sixty.com/privacy-policy",target="_blank",rel="noopener noreferrer") Privacy Policy

      template(v-if="isLoggedIn")
        li.nav-item
          a.nav-link(href="javascript:void(0)", @click="openJiraWidget") Support Request
        li.nav-item
          a.nav-link(
            href="https://risk3sixty.zendesk.com/hc/en-us/signin?return_to=https%3A%2F%2Frisk3sixty.zendesk.com%2Fhc%2Fen-us"
            target="_blank"
            rel="noopener noreferrer") Knowledge Base

      - // Separater between main nav and social media icons
      li.nav-item.border-left

      li.nav-item
        a.nav-item(href="https://www.facebook.com/risk3sixty",target="_blank",rel="noopener noreferrer")
          i.fab.fa-facebook
      li.nav-item
        a.nav-item(href="https://twitter.com/risk3sixty",target="_blank",rel="noopener noreferrer")
          i.fab.fa-twitter
      li.nav-item
        a.nav-item(href="https://www.instagram.com/risk3sixty",target="_blank",rel="noopener noreferrer")
          i.fab.fa-instagram
      li.nav-item
        a.nav-item(href="https://www.linkedin.com/company/risk3sixty",target="_blank",rel="noopener noreferrer")
          i.fab.fa-linkedin
      li.nav-item
        a.nav-item(href="https://github.com/risk3sixty",target="_blank",rel="noopener noreferrer")
          i.fab.fa-github
    span.copyright.ml-auto.my-auto.mr-2 Copyright &copy; {{ getFormattedDate(new Date(), 'YYYY') }}
      a.ml-2(href="https://risk3sixty.com",target="_blank",rel="noopener noreferrer")
        img.d-inline-block.align-middle(style="max-width: 50px;",:src="getR3sLogo")

</template>

<script>
  import { mapState } from 'vuex'
  import TimeHelpers from '../factories/TimeHelpers'

  export default {
    computed: mapState({
      getR3sLogo(state) {
        return state.isDarkMode
          ? '/public/images/r3s_logo_white.png'
          : '/public/images/r3s_logo.png'
      },
      isLoggedIn: (state) => state.isLoggedIn,
    }),

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,

      openJiraWidget() {
        // gets the iframe on which the widget is embedded
        const iframe = document.querySelector('#jsd-widget')

        // gets the content of the iframe
        const iframeContent = iframe.contentDocument

        // gets button which opens the widget
        const button = iframeContent.querySelector('#help-button')

        // "click" button to open widget
        button.click()
      },
    },
  }
</script>

<style lang="scss" scoped>
  li.nav-item {
    min-width: 25px;

    // .d-flex
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex !important;

    // .justify-content-center
    -ms-flex-pack: center !important;
    -webkit-box-pack: center !important;
    justify-content: center !important;

    // .align-items-center
    -ms-flex-align: center !important;
    -webkit-box-align: center !important;
    align-items: center !important;

    &.border-left {
      margin: 0px 10px;
      min-width: 0px;
      width: 0px;
    }
  }
</style>
