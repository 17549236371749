<template lang="pug">
bar(:options="options" :data="chartData")
</template>
<script>
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
  } from 'chart.js'
  import { Bar } from 'vue-chartjs'
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  )
  export default {
    components: {
      Bar,
    },
    props: {
      options: { type: Object, default: null },
      chartData: { type: Object, default: null },
    },
  }
</script>
