<template lang="pug">
  div.img-thumbnail.border-only.dark-border.force-circle(:class="widthClass",style="border: 3px solid")
    img(:class="getOrientationClass(img)",:src="img")
</template>

<script>
  export default {
    props: {
      img: { type: String },
      orientation: { type: String, default: 'portrait' },
      size: { type: [ Number, String ], default: 80 }
    },

    data() {
      const widthClass = (this.size.toString() == '100') ? `width-${this.size}` : (`w-${this.size}` || 'w-80')
      return {
        widthClass: { [widthClass]: true }
      }
    },

    methods: {
      getOrientationClass(img) {
        if (this.orientation)
          return { [this.orientation]: true }
        if (img.orientation)
          return { [img.orientation]: true }

        return { portrait: true }
      }
    }
  }
</script>
