<template lang="pug">
  div.modal-select-list.modal.fade(tabindex="-1",role="dialog",aria-labelledby="select-questionnaire-label",aria-hidden="true")
    div.modal-dialog(role="document")
      div.modal-content
        div.modal-header
          div.d-flex.align-items-center
            h5#select-questionnaire-label.modal-title Select Questionnaire
            questionnaire-template-toggle.ml-2
          button.close(type="button",data-dismiss="modal",aria-label="Close")
            span(aria-hidden="true") &times;
        div.modal-body.p-0
          ul.list-unstyled.m-0
            li.border-bottom
              div.d-flex.align-items-center
                div #[small Questionnaire]
            li.border-bottom(v-if="displayQuestionnaires.length === 0")
              i No questionnaires to select...
            li.border-bottom.user-row.py-3.clickable(
              v-for="(questionnaire, index) in displayQuestionnaires"
              @click="selectQuestionnaire(questionnaire.id)")
                div
                  strong {{ index + 1 }}. {{ questionnaire.name }}
                  div.small
                    small {{ titleCase(questionnaire.type, true) }}
        div.modal-footer.border-0
          button.btn.btn-secondary(type="button",data-dismiss="modal") Close
          //- button.btn.btn-primary(type="button") Save
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import Utilities from '@risk3sixty/utilities'

  export default {
    computed: {
      ...mapState({
        currentTeam: (state) => state.session && state.session.current_team,
        questionnaires: (state) => state.vendormanagement.questionnaires.data,
        questionnaireTemplates: (state) =>
          state.vendormanagement.questionnaireTemplates.data,

        showQuestionnaireTemplates: (state) =>
          state.vendormanagement.showQuestionnaireTemplates,
      }),

      filteredQuestionnaireTemplates() {
        return (this.questionnaireTemplates || []).filter((t) => {
          return t.team_id == this.currentTeam.id
        })
      },

      displayQuestionnaires() {
        return this.showQuestionnaireTemplates
          ? this.filteredQuestionnaireTemplates
          : this.questionnaires
      },
    },

    methods: {
      titleCase: Utilities.Strings.titleCase,

      selectQuestionnaire(id) {
        this.$emit('select', id)
        $(`#${this.$el.id}`).modal('hide')
      },

      async getQuestionnaires() {
        await this.$store.dispatch('getQuestionnaires')
      },
    },

    mounted() {
      $(`#${this.$el.id}`).on(
        'show.bs.modal',
        async () => await this.getQuestionnaires()
      )
    },
  }
</script>
