<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-labelledby="attestation-modal",aria-hidden="true")
    div.modal-dialog(role="document")
      div.modal-content
        form(@submit="attest")
          div.modal-header
            h5.modal-title {{ title || 'Attestation' }}
            button.close(type="button",data-dismiss="modal",aria-label="Close")
              span(aria-hidden="true") &times;
          div.modal-body
            div.mb-4(v-html="text")
            div.form-group
              label Enter your full name - #[strong(:class="nameCorrect ? 'text-success' : 'text-danger'") {{ userName }}]
              input.form-control.form-control-lg(
                :class="nameCorrect ? 'is-valid' : 'is-invalid'"
                type="text"
                v-model="typedName")
            div.form-group
              label Enter today's date - #[strong(:class="dateCorrect ? 'text-success' : 'text-danger'") {{ date }}]
              input.form-control.form-control-lg(
                :class="dateCorrect ? 'is-valid' : 'is-invalid'"
                type="text"
                v-model="typedDate")
          div.modal-footer
            button.btn.btn-secondary(type="button",data-dismiss="modal") Cancel
            button.btn.btn-primary(type="submit") Confirm Attestation
</template>

<script>
  import $ from 'jquery'
  import Utilities from '@risk3sixty/utilities'
  import { mapState } from 'vuex'

  const moment = Utilities.Dates.moment

  export default {
    props: {
      title: { type: String, default: 'Attestation' },
      text: {
        type: String,
        default: `By entering the info and confirming below, you attest to the information before you.`,
      },
    },

    data() {
      return {
        typedName: null,
        typedDate: null,
      }
    },

    computed: {
      ...mapState({
        user: (state) => state.auth.user,
      }),

      date() {
        return moment().format('YYYY-MM-DD')
      },

      userName() {
        return this.user.name
      },

      nameCorrect() {
        return this.userName === this.typedName
      },

      dateCorrect() {
        return this.date === this.typedDate
      },
    },

    methods: {
      attest(evt) {
        if (evt) evt.preventDefault()

        if (this.userName !== this.typedName)
          return window.toastr.error(
            `Please enter your full first and last name exactly as it is above.`
          )

        if (this.date !== this.typedDate)
          return window.toastr.error(
            `Please enter today's date exactly as it is above.`
          )

        this.$emit('attest', { user: this.user, date: this.date })
        $(`#${this.$el.id}`).modal('hide')
      },
    },
  }
</script>
