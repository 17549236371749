<template lang="pug">
  div
    div.text-center
      div.d-flex.justify-content-center
        file-uploader.d-none(
          clickable="#update-client-logo"
          :maxFileSize="1"
          @added="successfullyUploadedLogo"
          @error="errorUploadingLogo")
        a#update-client-logo
          img.img-thumbnail.max-200(
            v-if="!!currentTeamLogo"
            :src="`/file/s3/${currentTeamLogo}`")
          div(v-else)
            i.material-icons(style="font-size:72px") account_circle
          button.btn.btn-sm.btn-white.d-table.mx-auto.mt-2(type="button")
            i.fal.fa-cloud-upload-alt.mr-1
            span Upload Logo
      - //span.text-muted.d-block.mb-2 {{ $store.state.session.current_team.external_id }}
      - //button.mb-2.btn.btn-sm.btn-pill.btn-outline-primary.mr-2(type='button')
      - //  i.material-icons.mr-1 person_add
      - //  | Follow
    ul.list-group.list-group-flush
      li.list-group-item.p-4
        div.row
          div.col-12
            div.form-row.mb-3
              div.form-group.col-md-6.mb-2
                label(for='client-name') Organization Name
                input#client-name.form-control(
                  v-model="currentTeamName"
                  type='text'
                  placeholder='Organization Name')
              div.form-group.col-md-6.mb-2
                label(for='legal-client-name') Legal Organization Full Name
                input#legal-client-name.form-control(
                  v-model="currentTeamLegalFullName"
                  type='text'
                  :placeholder="currentTeamName")
              div.form-group.col-12.mb-2
                div.d-flex
                  label(for='client-url') Organization Website
                  a.ml-auto.mb-2(
                    v-if="currentTeamUrl"
                    :href="currentTeamUrl"
                    target="_blank"
                    rel="noopener")
                      small #[i (go to website)]
                input#client-url.form-control(
                  v-model="currentTeamUrl"
                  type='text'
                  placeholder='Website URL')
            div.form-row
              h5 Address
            div.form-row.mb-3
              div.form-group.col-md-6.mb-2
                label(for='client-address') Address
                input#client-address.form-control(
                  v-model="currentTeamAddress"
                  type='text'
                  placeholder='Address')
              div.form-group.col-md-6.mb-2
                label(for='client-address2') Address2
                input#client-address2.form-control(
                  v-model="currentTeamAddress2"
                  type='text'
                  placeholder='Address 2')
              div.form-group.col-md-3.mb-2
                label(for='client-city') City
                input#client-city.form-control(
                  v-model="currentTeamCity"
                  type='text'
                  placeholder='City')
              div.form-group.col-md-3.mb-0
                label(for='client-state') State
                select#client-state.form-control(v-model="currentTeamState")
                  option(
                    v-for="state in states"
                    :value="state.abbreviation") {{ state.name }} ({{ state.abbreviation }})
              div.form-group.col-md-3.mb-0
                label(for='client-zip') Zip Code
                input#client-zip.form-control(
                  v-model="currentTeamZipCode"
                  type='number'
                  placeholder='Zip Code')
              div.form-group.col-md-3.mb-0
                label(for='client-country') Country
                select#client-country.form-control(v-model="currentTeamCountry")
                  option(
                    v-for="country in countries"
                    :value="country.code") {{ country.name }} ({{ country.code }})
            div.form-row
              h5 Additional Options
            div.form-row
              div.form-group.col-md-6(v-if="isAdmin")
                label#domain-registration-restrictions-label(for='domain-registration-restrictions')
                  | Restrict User Registration by Email Domain #[i.fal.fa-info-circle]
                input#domain-registration-restrictions.form-control(v-model="currentTeamDomainRestrictions")
                b-tooltip(target="domain-registration-restrictions-label")
                  | If configured, only users who have an email with these domains will be able
                  | to be registered. Separate multiple domains by commas.
              div.form-group.col-md-6
                label(for='client-main-contact') Main Contact
                select#client-main-contact.form-control(v-model="currentTeamMainContact")
                  option(v-for="user in teamUsers",:value="user.id") {{ user.name || user.username_email }}
              //- div.form-group.col-md-6(v-if="isInternalUser && $store.state.session.current_team.type === 'organization'")
              //-   label Internal Users in Org
              //-   select.form-control(v-model="selectedInternalUser",@change="addInternalUser()")
              //-     option(:value="null") Select user...
              //-     option(v-for="user in $store.state.internalUsers",:value="user.id") {{ user.name || user.username_email }}
              //-   div.mt-1(v-if="internalUserIds.length > 0")
              //-     user-avatar.mr-1.mb-1(
              //-       v-for="userId in internalUserIds",
              //-       :id="'org-internal-user-' + userId",
              //-       :key="userId",
              //-       :user-id="userId",
              //-       @close="removeInternalUser")

</template>

<script>
  import { mapState } from 'vuex'
  import ApiTeams from '../../factories/ApiTeams'

  export default {
    data() {
      return {
        // selectedInternalUser: null,
      }
    },

    computed: {
      currentTeamName: {
        get() {
          return this.$store.state.session.current_team.name
        },

        set(newVal) {
          this.$store.commit('SET_CURRENT_TEAM_FIELD', {
            key: 'name',
            value: newVal,
          })
        },
      },

      currentTeamLegalFullName: {
        get() {
          return this.$store.state.session.current_team.legal_full_name
        },

        set(newVal) {
          this.$store.commit('SET_CURRENT_TEAM_FIELD', {
            key: 'legal_full_name',
            value: newVal,
          })
        },
      },

      currentTeamUrl: {
        get() {
          return this.$store.state.session.current_team.url
        },

        set(newVal) {
          this.$store.commit('SET_CURRENT_TEAM_FIELD', {
            key: 'url',
            value: newVal,
          })
        },
      },

      currentTeamAddress: {
        get() {
          return this.$store.state.session.current_team.address
        },

        set(newVal) {
          this.$store.commit('SET_CURRENT_TEAM_FIELD', {
            key: 'address',
            value: newVal,
          })
        },
      },

      currentTeamAddress2: {
        get() {
          return this.$store.state.session.current_team.address2
        },

        set(newVal) {
          this.$store.commit('SET_CURRENT_TEAM_FIELD', {
            key: 'address2',
            value: newVal,
          })
        },
      },

      currentTeamCity: {
        get() {
          return this.$store.state.session.current_team.city
        },

        set(newVal) {
          this.$store.commit('SET_CURRENT_TEAM_FIELD', {
            key: 'city',
            value: newVal,
          })
        },
      },

      currentTeamState: {
        get() {
          return this.$store.state.session.current_team.state
        },

        set(newVal) {
          this.$store.commit('SET_CURRENT_TEAM_FIELD', {
            key: 'state',
            value: newVal,
          })
        },
      },

      currentTeamZipCode: {
        get() {
          return this.$store.state.session.current_team.zip_code
        },

        set(newVal) {
          this.$store.commit('SET_CURRENT_TEAM_FIELD', {
            key: 'zip_code',
            value: newVal,
          })
        },
      },

      currentTeamCountry: {
        get() {
          return this.$store.state.session.current_team.country
        },

        set(newVal) {
          this.$store.commit('SET_CURRENT_TEAM_FIELD', {
            key: 'country',
            value: newVal,
          })
        },
      },

      currentTeamDomainRestrictions: {
        get() {
          return this.$store.state.session.current_team
            .domain_registration_restrictions
        },

        set(newVal) {
          this.$store.commit('SET_CURRENT_TEAM_FIELD', {
            key: 'domain_registration_restrictions',
            value: newVal,
          })
        },
      },

      currentTeamMainContact: {
        get() {
          return this.$store.state.session.current_team.main_contact_user_id
        },

        set(newVal) {
          this.$store.commit('SET_CURRENT_TEAM_FIELD', {
            key: 'main_contact_user_id',
            value: newVal,
          })
        },
      },

      ...mapState({
        isAdmin: (_, getters) => getters.isAdmin,
        currentTeamLogo: (_, getters) => getters.currentTeamLogo,
        internalUserIds: (state, getters) =>
          getters.teamInternalUsers.map((u) => u.id),

        // isInternalUser: (_, getters) => getters.isInternalUser,

        currentTeam: (state) => state.session.current_team,
        countries: (state) => state.countries,
        states: (state) => state.states,
        teamUsers: (state) => state.team.users,
      }),
    },

    methods: {
      async successfullyUploadedLogo([, response]) {
        try {
          const logoName = response.filename
          await ApiTeams.update({
            id: this.currentTeam.id,
            logo_file: decodeURIComponent(logoName),
          })

          window.toastr.success(`Successfully added your team logo!`)
          await this.$store.dispatch('getAndSetUserSession', true)
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      errorUploadingLogo([, err]) {
        window.toastr.error(err)
      },

      // async removeInternalUser(userId) {
      //   await this.addInternalUser(
      //     this.internalUserIds.filter((id) => id !== userId)
      //   )
      // },

      // async addInternalUser(idAryOverride = null) {
      //   try {
      //     const userIds = idAryOverride || [
      //       ...new Set(
      //         this.internalUserIds
      //           .concat([this.selectedInternalUser])
      //           .filter((u) => !!u)
      //       ),
      //     ]
      //     await ApiTeams.updateAllInternalUsers(userIds)
      //     await this.$store.dispatch('getTeamAndInternalUsers', true)
      //     window.toastr.success(`Successfully updated internal users.`)
      //     this.selectedInternalUser = null
      //   } catch (err) {
      //     window.toastr.error(err.message)
      //   }
      // },
    },
  }
</script>
