<template lang="pug">
  div.upgrade-container
    slot.btn-upgrade
    div.upgrade-overlay(
      v-if="forceDisplayBadge || isBlocked",
      :style="`top: ${top}px; right: ${right}px; left: ${left}px; bottom: ${bottom}px;`")
      img.w-75(src="../../../public/images/upgrade_icon.png", alt="content shield",:id="`upgrade-tag-${_uid}`")
      b-tooltip(:target="`upgrade-tag-${_uid}`") {{tooltip}}
</template>
<script>
  import SubscriptionHelpers from '../../factories/SubscriptionHelpers'
  export default {
    props: {
      top: { type: [Number, String], default: null },
      right: { type: [Number, String], default: null },
      left: { type: [Number, String], default: null },
      bottom: { type: [Number, String], default: null },
      restrictionKey: { type: String, default: '' },
      count: { type: [Number, String], default: 0 },
      forceDisplayBadge: { type: Boolean, default: false },
      tooltip: {
        type: String,
        default: 'This content is reserved for a higher subscription tier',
      },
    },

    computed: {
      isBlocked() {
        return this.isActionBlocked(this.restrictionKey, this.count)
      },
    },

    methods: {
      isActionBlocked: SubscriptionHelpers.isActionBlocked,
    },
  }
</script>
<style lang="scss" scoped>
  .upgrade-container {
    position: relative;
  }

  .btn-upgrade {
    position: absolute;
    top: 0;
    left: 0;
  }

  .upgrade-overlay {
    z-index: 999;
    position: absolute;
    cursor: default;
    font-size: 1rem;
  }
</style>
