<template lang="pug">
  div
    div(:id="`mapping-selector-${_uid}`")
      a(v-if="projectAndRequest && projectAndRequest.request")
        div.d-flex.align-items-center
          div.p-1.mr-2.rounded-circle.nowrap.d-flex.align-items-center.justify-content-center(
            style="border: 2px solid; height: 28px; width: 28px")
              | {{ projectAndRequest.request.project_ref_num }}
          div {{ projectAndRequest.request.name }}
      div.d-flex.align-items-center(v-else)
        a
          i None selected (click here to add)
    assessment-request-finder-popover(
      :target="`mapping-selector-${_uid}`"
      placement="top"
      :value="projectAndRequest"
      @input="update")
</template>

<script>
  import { mapState } from 'vuex'
  import ApiAssessments from '../../../../factories/ApiAssessments'

  export default {
    props: {
      value: { type: [Number, String], default: null },
    },

    watch: {
      async value() {
        await this.getRequest()
      },
    },

    data() {
      return {
        projectAndRequest: null,
      }
    },

    computed: {
      ...mapState({
        // extensionTypes: (state) => state.extensions.types,
      }),
    },

    methods: {
      update(newVal) {
        this.projectAndRequest = newVal
        this.$emit(
          'input',
          (newVal && newVal.request && newVal.request.id) || this.value
        )
      },

      async getRequest(id = this.value) {
        const { request, project } = await ApiAssessments.getRequest(id)
        this.projectAndRequest = {
          request,
          project,
        }
      },
    },

    async created() {
      if (this.value) {
        await this.getRequest()
      }
    },
  }
</script>
