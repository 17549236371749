<template lang="pug">
div
  div.mt-2
    div.mb-2(v-if="groupByOptions.length > 0")
      div.strong.mb-1 Group By
      dropdown-select(
        :options="groupByOptions.map(option => ({ key: titleCase(option, true), value: option }))"
        :value="mutableGroupBy"
        :disabled="!userCanEdit"
        @change="updateInfo('groupBy', ...arguments)")

    div.mb-2(v-if="sortByOptions.length > 0")
      div.strong.mb-1 Sort By
      dropdown-select(
        :options="sortByOptions.map(option => ({ key: titleCase(option, true), value: option }))"
        :value="mutableSortBy"
        :disabled="!userCanEdit"
        @change="updateInfo('sortBy', ...arguments)")
    
    div.mb-2(v-if="outputTypeOptions.length > 0")
      div.strong.mb-1 Output Type
      template(v-for="option in outputTypeOptions")
        div.custom-control.custom-radio
          input.custom-control-input(
            :id="`output-type-${option}-${_uid}`"
            type="radio"
            :name="`output-type-${option}-${_uid}`"
            :value="option"
            v-model="mutableOutputType"
            :disabled="!userCanEdit"
            @click="updateInfo('outputType', option)")
          label.custom-control-label(:for="`output-type-${option}-${_uid}`") {{option}}

    div.mb-2(v-if="orientationOptions.length > 0 && mutableOutputType != 'csv'")
      div.strong.mb-1 Orientation
      template(v-for="option in orientationOptions")
        div.custom-control.custom-radio
          input.custom-control-input(
            :id="`orientation-${option}-${_uid}`"
            type="radio"
            :name="`orientation-${option}-${_uid}`"
            :value="option"
            v-model="mutableOrientation"
            :disabled="!userCanEdit"
            @click="updateInfo('orientation', option)")
          label.custom-control-label(:for="`orientation-${option}-${_uid}`") {{titleCase(option)}}
</template>

<script>
  import { mapState } from 'vuex'
  import DropdownSelect from './filtertypes/Select'
  import Utilities from '@risk3sixty/utilities'

  export default {
    props: {
      outputType: { type: String },
      groupBy: { type: String },
      sortBy: { type: String },
      orientation: { type: String },
      userCanEdit: { type: Boolean },
    },

    watch: {
      outputType: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.mutableOutputType = newValue
          } else {
            this.updateInfo('outputType', this.outputTypeOptions[0])
          }
        },
      },
      groupBy: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.mutableGroupBy = newValue
          } else {
            this.updateInfo('groupBy', this.groupByOptions[0])
          }
        },
      },
      sortBy: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.mutableSortBy = newValue
          } else {
            this.updateInfo('sortBy', this.sortByOptions[0])
          }
        },
      },

      orientation: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.mutableOrientation = newValue
          } else {
            this.updateInfo('orientation', this.orientationOptions[0])
          }
        },
      },
    },

    data() {
      return {
        mutableOutputType: 'csv',
        mutableGroupBy: null,
        mutableSortBy: null,
        mutableOrientation: 'portrait',
      }
    },

    computed: {
      ...mapState({
        groupByOptions: (state) =>
          state.reporting.currentType?.configuration?.groupByOptions || [],
        sortByOptions: (state) =>
          state.reporting.currentType?.configuration?.sortByOptions || [],
        outputTypeOptions: (state) =>
          state.reporting.currentType?.configuration?.outputTypeOptions || [
            'csv',
          ],
        orientationOptions: (state) =>
          state.reporting.currentType?.configuration?.orientationOptions || [
            'portrait',
            'landscape',
          ],
      }),
    },

    methods: {
      titleCase: Utilities.Strings.titleCase,

      updateInfo(key, val) {
        this.$emit('update', key, val)
      },
    },

    components: {
      DropdownSelect,
    },
  }
</script>
