<template lang="pug">
  confirmation-popover(
    :target="target",
    header="Confirm Execute?",
    :text="`Are you sure you want to execute?`",
    @confirm="trigger()")
      template(v-if="runtimeEnabledConfigs.length > 0")
        hr
        h6.m-0.nowrap Configuration Override #[i.fal.fa-info-circle(:id="`override-info-${_uid}`")]
        b-tooltip(:target="`override-info-${_uid}`")
          | If a configuration is selected as an option you can override
          | at execution time, you can override the default values
          | provided by updating the values below.

        extension-mapping-configuration.border.no-shadow.mt-4(
          :configs="runtimeEnabledConfigs"
          v-model="overrideConfigs")
</template>

<script>
  import ExtensionMappingConfiguration from './ExtensionMappingConfiguration'

  export default {
    props: {
      target: { type: String, required: true },
      extensionId: { type: [Number, String], required: true },
      extensionName: { type: String, required: true },
      mappingId: { type: [Number, String], required: true },
      configs: { type: Array, default: () => null },
    },

    data() {
      return {
        overrideConfigs: {},
      }
    },

    computed: {
      runtimeEnabledConfigs() {
        return (this.configs || []).filter((c) => c.is_runtime_enabled)
      },
    },

    methods: {
      trigger() {
        this.$emit('trigger', this.mappingId, this.overrideConfigs)
      },
    },

    created() {
      this.overrideConfigs = (this.configs || []).reduce(
        (o, c) => ({
          ...o,
          [c.variable]: null,
        }),
        {}
      )
    },

    components: {
      ExtensionMappingConfiguration,
    },
  }
</script>
