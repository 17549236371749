<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-labelledby="achievement-modal",aria-hidden="true")
    div.modal-dialog.modal-dialog-centered.modal-xl(role="document")
      div.modal-content(style="border-radius: 15px; background-color: #f7f7f7")
        img(src="../../../public/images/achievement-modal-background.png")
        div.modal-header.justify-content-center.position-absolute(style="width: 100%; border-bottom: 0")
          div.d-flex.flex-column.align-items-center.position-relative.mt-2(style="max-width: 100%")
            h3.mt-2(style="color: #ffffff") fullCircle Achievements
            img.mt-2(src="../../../public/images/accolades.png",style="max-width: 6.5rem")
        div(v-if="isLoading")
          loader
        div.modal-body(v-else)
          div.d-flex.flex-column.align-items-center
            h4.number-color {{ currentProject.name }}
          div.d-flex.justify-content-center.my-4
            h1.card.scorecard.align-items-center.w-25.mx-4.my-0
              div.oval.mt-2.mb-2.d-flex.justify-content-center.align-items-center
                h4.pt-2.number-color {{ milestones.files && milestones.files.count ? milestones.files.count : 'N/A' }}
              h6 Pieces of Evidence Uploaded
            h1.card.scorecard.align-items-center.w-25.mx-4.my-0
              div.oval.mt-2.mb-2.d-flex.justify-content-center.align-items-center
                h4.pt-2.number-color {{ milestones.comments && milestones.comments.count ? milestones.comments.count : 'N/A' }}
              h6 Comment Responses
            h1.card.scorecard.align-items-center.w-25.mx-4.my-0
              div.oval.mt-2.mb-2.d-flex.justify-content-center.align-items-center
                h4.pt-2.number-color {{ daysWorked }}
              h6 Days Worked on Project
            //- h1.card.scorecard.align-items-center.w-25.mx-4.my-0
            //-   div.oval.mt-2.mb-2.d-flex.justify-content-center.align-items-center
            //-     h4.pt-2.number-color {{ hoursWorked }}
            //-   h6 Hours Worked on Project
          div(style="border-top: 3px solid #e5edf4")
          div.d-flex.justify-content-around.my-4.flex-wrap-reverse.mw-100
            div.card.flex-row.align-items-center.justify-content-between.m-2(
              v-if="milestones.perPersonEffort && milestones.perPersonEffort.length > 0"
              v-for="user in milestones.perPersonEffort"
              style="width: 45%")
                div.d-flex.flex-row.align-items-center
                  div.m-3.mb-2.d-flex.justify-content-center.align-items-center
                    user-avatar(:user-id="user.id",:should-show-close="false",height="65px" )
                  h6.mt-2.mr-2 {{ user.name }}
                div.d-flex.flex-row.align-items-center
                  div.d-flex.flex-column.user-info-border.align-items-center
                    div.oval-score.d-flex.justify-content-center.align-items-center
                      h6.pt-2.number-color {{ user.file_count }}
                    div.user-info-title Evidence
                  div.d-flex.flex-column.user-info-border.align-items-center
                    div.oval-score.d-flex.justify-content-center.align-items-center
                      h6.pt-2.number-color {{ user.comment_count }}
                    div.user-info-title Comments
                  //- div.d-flex.flex-column.user-info-border.align-items-center
                  //-   div.oval-score.d-flex.justify-content-center.align-items-center
                  //-     h6.pt-2.number-color 215
                  //-   div.user-info-title Days Worked
                  //- div.d-flex.flex-column.user-info-border.align-items-center
                  //-   div.oval-score.d-flex.justify-content-center.align-items-center
                  //-     h6.pt-2.number-color 215
                  //-   div.user-info-title Hours Worked
        div.modal-footer
          button.btn.btn-secondary(
            type="button"
            data-dismiss="modal") Close
</template>

<script>
  import $ from 'jquery'
  import moment from 'moment'
  import { mapState } from 'vuex'
  import ApiAssessments from '../../factories/ApiAssessments'

  export default {
    props: {
      header: { type: String, default: 'Confirm' },
      text: { type: String },
      projectId: { type: [String, Number], required: true },
    },

    data() {
      return { milestones: {}, isLoading: true }
    },

    computed: {
      ...mapState({
        currentProject: (state) => state.assessments.currentProject || {},
      }),

      daysWorked() {
        return this.milestones.firstAndLastEvidence.last_created_at
          ? moment(this.milestones.firstAndLastEvidence.last_created_at).diff(
              moment(this.milestones.firstAndLastEvidence.first_created_at),
              'days'
            )
          : 'N/A'
      },

      hoursWorked() {
        return this.milestones.firstAndLastEvidence.last_created_at
          ? moment(this.milestones.firstAndLastEvidence.last_created_at).diff(
              moment(this.milestones.firstAndLastEvidence.first_created_at),
              'hours'
            )
          : 'N/A'
      },
    },

    methods: {
      async getAssessmentAchievements() {
        try {
          this.isLoading = true
          const milestones = await ApiAssessments.getAssessmentAchievements(
            this.projectId
          )
          this.milestones = milestones || {}
          this.isLoading = false
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    mounted() {
      $(`#${this.$el.id}`).on('show.bs.modal', async () => {
        await this.getAssessmentAchievements()
      })
    },
  }
</script>
<style lang="scss" scoped>
  .card {
    border: solid 2px #e1e5eb;
    box-shadow: none;
  }
  .scorecard {
    width: 14rem;
    height: 8rem;
  }
  .number-color {
    color: #11354d;
  }
  .oval {
    width: 75px;
    height: 75px;
    border: solid 2px #1c567d;
    background-color: #eceef3;
    border-radius: 50%;
    display: inline-block;
  }
  .oval-score {
    width: 40px;
    height: 40px;
    margin: 10px 0 0 0;
    border: solid 1.4px #1c567d;
    background-color: #eceef3;
    border-radius: 50%;
    display: inline-block;
  }
  .user-info-border {
    border-left: 2px solid #eceef3;
    width: 6rem;
  }
  .user-info-title {
    font-size: 12px;
    font-weight: 500;
    color: #1c567d;
  }
  h6 {
    font-size: 16px;
    color: #1c567d;
  }
</style>
