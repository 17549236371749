<template lang="pug">
div.card.card-small
  div.card-header.border-bottom
    div.small.d-flex.align-items-center
      router-link(to='/vulnerability')
        strong Scan Open Vulnerabilities Over Time
      div.ml-auto.d-flex.align-items-center
        select-dropdown-filter.mr-2(
            :value="scansFilter",
            :multi="true",
            icon="fa fa-filter",
            text="Selected Projects",
            :options="getOptions()",
            @change="(filter) => setFilter(filter)"
          )
  div.card-body
    div(v-if="isLoadingLocal")
      loader
    div(v-else)
      div.text-center.p-2(v-if="!summaryData")
        i No data yet to report on.
      div(v-else)
        component.ml-auto.mr-auto(:is="getChartComponent()",:width="100",:height="25",:chart-data="chartData",:options="chartOptions",:chart-id="'vulnerability-open-items-over-time'")
</template>

<script>
  import moment from 'moment'
  import Utilities from '@risk3sixty/utilities'
  import BarChart from '../../../reusable/chartjs/Bar'
  import LineChart from '../../../reusable/chartjs/Line'
  import ApiVulnerability from '../../../../factories/ApiVulnerability'
  import ChartHelpers from '../../../../factories/ChartHelpers'
  import StringHelpers from '../../../../factories/StringHelpers'

  export default {
    data() {
      const monthsSpan = this.getChartComponent() === 'BarChart' ? 3 : 6

      return {
        isLoadingLocal: true,
        colors: [],
        labels: [],
        scansFilter: [],
        chartData: null,
        chartOptions: {
          plugins: {
            legend: {
              display: false,
              position: 'top',
            },

            tooltip: {
              mode: 'index',

              // https://www.chartjs.org/docs/latest/configuration/tooltip.html#tooltip-callbacks
              callbacks: {
                title(info) {
                  const utcDate = moment(info[0].label).utc()
                  return `Q${utcDate.format('Q')} - ${utcDate.format('YYYY')}` // .format('MMMM D, YYYY')
                },
              },
            },
          },
          spanGaps: 1000 * 60 * 60 * 24 * 30 * monthsSpan, // monthsSpan months
          scales: {
            x: {
              type: 'time',
              title: {
                display: true,
                text: 'Scan Dates',
              },
            },
            y: {
              title: {
                display: true,
                text: 'Number of Open Vulnerabilities',
              },
              min: 0,
            },
          },
        },
        summaryData: null,
      }
    },

    methods: {
      titleCase: StringHelpers.titleCase,

      getChartComponent() {
        if (this.summaryData && Object.keys(this.summaryData).length > 0) {
          const allQuarters = Utilities.Arrays.flatten(
            Object.keys(this.summaryData).map((scanName) => {
              return this.summaryData[scanName].map((i) =>
                moment(i.quarter_of_scan).utc().format('YYYY-MM-DD')
              )
            })
          )
          const numQuarters = [...new Set(allQuarters)].length
          return numQuarters > 1 ? 'LineChart' : 'BarChart'
        }

        return 'BarChart'
      },

      getChartLabels() {
        const utcDateFormatted = Object.keys(this.summaryData).map(
          (scanName) => {
            return this.summaryData[scanName].map((i) =>
              moment(i.quarter_of_scan).utc().format('YYYY-MM-DD')
            )
          }
        )
        return [...new Set(Utilities.Arrays.flatten(utcDateFormatted))]
          .map((i) => moment.utc(i))
          .sort((d1, d2) => {
            if (d1.isBefore(d2)) return -1
            return 1
          })
      },

      getOptions() {
        if (!this.summaryData) return []
        return Object.keys(this.summaryData).map((scanName) => {
          return {
            text: scanName,
            value: scanName,
          }
        })
      },

      setFilter(filter) {
        this.scansFilter = filter
        this.setChartData(this.scansFilter)
        this.getChartComponent()
      },

      setChartData(filter = []) {
        this.labels = this.getChartLabels()
        const unflattenedDatasets = this.getUnflattenedDatasets(filter)

        this.chartData = {
          labels: this.labels,
          datasets: Utilities.Arrays.flatten(unflattenedDatasets),
        }
      },

      getUnflattenedDatasets(filter = []) {
        return Object.keys(this.summaryData)
          .filter((scanName) => {
            return filter.length === 0 || filter.includes(scanName)
          })
          .map((scanName) => {
            return ['critical', 'high', 'medium', 'low'].map((sev, ind) => {
              return Object.assign(ChartHelpers.lineDatasetDefaults(), {
                // fill: (ind === 0) ? 'origin' : `-${ind}`,
                label: `${scanName} - ${this.titleCase(sev)} severity`,
                backgroundColor: this.colors[ind].bg_light,
                borderColor: this.colors[ind].color,
                data: this.labels.map((momentDate) => {
                  const data = this.summaryData[scanName].find(
                    (i) =>
                      moment(i.quarter_of_scan)
                        .utc()
                        .startOf('quarter')
                        .format('YYYY-MM-DD') ===
                      momentDate.format('YYYY-MM-DD')
                  )
                  return data
                    ? parseInt(data[`total_open_${sev}_after`] || 0)
                    : 0
                }),
              })
            })
          })
      },
    },

    async mounted() {
      try {
        const response =
          await ApiVulnerability.getDashboardOpenItemsOverTimeSummary()
        this.summaryData = response.summary

        const severityColors = this.$store.state.dashboard.severityColors()
        const allColors = Object.keys(severityColors).map((sev) =>
          Utilities.Colors.hexToRgb(severityColors[sev])
        )
        this.colors = allColors.map((c) => {
          return {
            bg: `rgba(${c.r}, ${c.g}, ${c.b}, 0.8)`,
            bg_light: `rgba(${c.r}, ${c.g}, ${c.b}, 0.1)`,
            color: `rgba(${c.r}, ${c.g}, ${c.b}, 1)`,
          }
        })

        this.setChartData()
      } catch (err) {
        window.toastr.error(err.message)
      } finally {
        this.isLoadingLocal = false
      }
    },

    components: {
      BarChart,
      LineChart,
    },
  }
</script>
