import ApiRiskRegister from '../../factories/ApiRiskRegister'

export default {
  async initRiskRegister({ dispatch, state }, reset = state.globalReset) {
    await Promise.all([
      dispatch('getRiskRegisterPolicies', reset),
      dispatch('getRiskRegisterCategories', reset),
      dispatch('getRiskRegisterStatuses'),
    ])
  },

  async getRiskRegisterPolicies({ commit, state } /* , reset = false */) {
    // if (state.riskregister.impactScores.length > 0 && !reset) return
    if (!state.riskregister.currentRegister.id) return

    const policies = await ApiRiskRegister.getPolicies(
      state.riskregister.currentRegister.id
    )
    commit('SET_RISK_REGISTER_POLICIES', policies)
  },

  async getRiskRegisterCategories(
    { commit, state },
    reset = state.globalReset
  ) {
    const registerId = state.riskregister.currentRegister.id
    if (
      registerId &&
      (state.riskregister.categories[registerId] || []).length > 0 &&
      !reset
    ) {
      return
    }

    const { categories } = await ApiRiskRegister.getCategories(registerId)
    commit('SET_RISK_REGISTER_CATEGORIES', categories)
  },

  async getRegisters(
    { commit, state },
    { status = 'active', reset = state.globalReset }
  ) {
    if (state.riskregister.riskRegisters.length > 0 && !reset) return

    const { registers } = await ApiRiskRegister.getAllRegisters(status)
    commit('SET_RISK_REGISTERS', registers)
  },

  async getRiskRegisterStatuses({ commit, state }) {
    if (state.riskregister.statuses.length > 0) return //Statuses and treatment_descisions are not currently updated in the DB, so 'RESET' is never valid.

    const { statuses, decisions } =
      await ApiRiskRegister.getStatusesAndDecisions()
    commit('SET_RISK_REGISTER_STATUSES', statuses)
    commit('SET_RISK_REGISTER_TREATMENT_DECISIONS', decisions)
  },

  async riskRegisterItemChangePage({ commit, dispatch }, newPage) {
    commit('SET_RISK_REGISTER_ITEMS_PAGE', newPage)
    await dispatch('getRiskRegisterItems', { reset: true })
  },

  async riskRegisterItemChangePerPage({ commit, dispatch }, newPerPage) {
    commit('SET_RISK_REGISTER_ITEMS_PAGE', 1)
    commit('SET_RISK_REGISTER_ITEMS_PER_PAGE', newPerPage)
    await dispatch('getRiskRegisterItems', { reset: true })
  },

  async riskRegisterItemFrontendChangePage(
    { commit, dispatch, state },
    { newPage, isGlobalTemplate }
  ) {
    const globalReset = state.globalReset
    const riskRegisterId = isGlobalTemplate ? 'global' : null
    await dispatch('getRiskRegisterItems', { globalReset, riskRegisterId })
    commit('SET_RISK_REGISTER_FRONTEND_PAGINATION_OBJ', {
      currentPage: newPage,
    })
  },

  async riskRegisterItemFrontendChangePerPage(
    { commit, dispatch, state },
    { newPerPage, isGlobalTemplate }
  ) {
    const globalReset = state.globalReset
    const riskRegisterId = isGlobalTemplate ? 'global' : null
    newPerPage = newPerPage ?? state.riskregister.frontendPaginatedData.perPage
    await dispatch('getRiskRegisterItems', { globalReset, riskRegisterId })
    commit('SET_RISK_REGISTER_FRONTEND_PAGINATION_OBJ', {
      currentPage: 1,
      perPage: newPerPage,
    })
  },

  async getRiskRegisterItems(
    { commit, state },
    { reset = state.globalReset, riskRegisterId = null }
  ) {
    try {
      if (state.riskregister.items.length > 0 && !reset) return

      const { items, tags } = await ApiRiskRegister.getAllItems(
        riskRegisterId ? riskRegisterId : state.riskregister.currentRegister.id,
        state.riskregister.itemFilters,
        state.riskregister.items.currentPage,
        state.riskregister.items.perPage,
        state.riskregister.sorting
      )
      commit('SET_RISK_REGISTER_ITEMS', items)
      commit('SET_RISK_REGISTER_TAGS', tags)
      commit('SET_RISK_REGISTER_ERROR', null)
    } catch (err) {
      commit('SET_RISK_REGISTER_ERROR', err.message)
      throw err
    }
  },

  async getRiskRegisterItem(
    { commit, state },
    itemId = state.riskregister.currentItem.record.id
  ) {
    try {
      const {
        item,
        metricHistory,
        comments,
        files,
        owners,
        tags,
        changeLogs,
        pmTimeline,
        jiraMappings,
        calendarEvents,
        teamVendors,
        inventoryItems,
        controls,
        formalFindings,
        projectTasks,
        myControls,
      } = await ApiRiskRegister.getItem(itemId)

      commit('SET_RISK_REGISTER_ITEM', item)
      commit('SET_RISK_REGISTER_ITEM_METRIC_HISTORY', metricHistory)
      commit('SET_RISK_REGISTER_ITEM_COMMENTS', comments)
      commit('SET_RISK_REGISTER_ITEM_FILES', files)
      commit('SET_RISK_REGISTER_ITEM_OWNERS', owners)
      commit('SET_RISK_REGISTER_ITEM_TAGS', tags)
      commit('SET_RISK_REGISTER_ITEM_CHANGE_LOGS', changeLogs)
      commit('SET_RISK_REGISTER_ITEM_PMTIMELINE', pmTimeline)
      commit('SET_RISK_REGISTER_ITEM_JIRA_MAPPINGS', jiraMappings)
      commit('SET_RISK_REGISTER_ITEM_EVENTS', calendarEvents)
      commit('SET_RISK_REGISTER_ITEM_VENDORS', teamVendors)
      commit('SET_RISK_REGISTER_ITEM_INVENTORY_ITEMS', inventoryItems)
      commit('SET_RISK_REGISTER_ITEM_CONTROLS', controls)
      commit('SET_RISK_REGISTER_ITEM_FORMAL_FINDINGS', formalFindings)
      commit('SET_RISK_REGISTER_ITEM_PROJECT_TASKS', projectTasks)
      commit('SET_RISK_REGISTER_ITEM_MY_CONTROLS', myControls)
      commit('SET_RISK_REGISTER_ERROR', null)
    } catch (err) {
      commit('SET_RISK_REGISTER_ERROR', err.message)
      throw err
    }
  },

  clearRiskRegisterItem({ commit }) {
    commit('SET_RISK_REGISTER_ITEM', {})
    commit('SET_RISK_REGISTER_ITEM_METRIC_HISTORY', [])
    commit('SET_RISK_REGISTER_ITEM_COMMENTS', [])
    commit('SET_RISK_REGISTER_ITEM_FILES', [])
    commit('SET_RISK_REGISTER_ITEM_OWNERS', [])
    commit('SET_RISK_REGISTER_ITEM_TAGS', [])
    commit('SET_RISK_REGISTER_ITEM_CHANGE_LOGS', [])
    commit('SET_RISK_REGISTER_ITEM_PMTIMELINE', [])
    commit('SET_RISK_REGISTER_ITEM_JIRA_MAPPINGS', [])
    commit('SET_RISK_REGISTER_ITEM_EVENTS', [])
    commit('SET_RISK_REGISTER_ITEM_VENDORS', [])
    commit('SET_RISK_REGISTER_ITEM_INVENTORY_ITEMS', [])
    commit('SET_RISK_REGISTER_ITEM_CONTROLS', [])
    commit('SET_RISK_REGISTER_ITEM_FORMAL_FINDINGS', [])
    commit('SET_RISK_REGISTER_ITEM_PROJECT_TASKS', [])
    commit('SET_RISK_REGISTER_ERROR', null)
  },

  async getRiskRegisterRecord({ commit }, id) {
    try {
      const { assessmentMappings, register } =
        await ApiRiskRegister.getRegister(id)
      commit('SET_RISK_REGISTER', register)
      commit('SET_RISK_REGISTER_ASSESSMENT_MAPPINGS', assessmentMappings)
      commit('SET_RISK_REGISTER_ERROR', null)
    } catch (err) {
      commit('SET_RISK_REGISTER_ERROR', err.message)
      throw err
    }
  },

  async setRiskRegisterFilterAndGetItems({ commit, dispatch }, { key, val }) {
    commit('SET_RISK_REGISTER_ITEM_FILTER', { key, val })
    await dispatch('getRiskRegisterItems', { reset: true })
  },

  async riskRegisterItemOrderingUpdate(
    { dispatch },
    { registerId, itemIds, startIndex }
  ) {
    await ApiRiskRegister.updateItemOrdering(registerId, itemIds, startIndex)
    await dispatch('getRiskRegisterItems', { reset: true })
  },

  async setRiskRegisterSorting({ commit, dispatch }, { key, val }) {
    commit('SET_RISK_REGISTER_SORTING', { key, val })
    await dispatch('getRiskRegisterItems', { reset: true })
  },
}
