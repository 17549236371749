<template lang="pug">
  input.form-control(type="number",:value="value",ref="input",@input="updateValue")
</template>

<script>
  export default {
    props: ['value'],

    methods: {
      updateValue() {
        this.$emit('input', this.$refs.input.value)
        this.$emit('change', this.$refs.input.value)
      }
    }
  }
</script>
