<template lang="pug">
  div.card.card-small
    div.card-header.border-bottom
      h5.m-0.d-flex.align-items-center
        div {{ title }} {{canEdit ? '' : '(Read Only)'}}
        div.ml-auto(v-if="canEdit && columns && columns.length > 0")
          add-remove-btn-group(
            :show-remove="false"
            @add="newRow()",
            @remove="mutableRows.splice(mutableRows.length - 1, 1)")
    div.card-body.p-0
      div.table-responsive.m-0.table-body-scroll.scrollable(style="height: 65.5vh;")
        div.d-flex.justify-content-center.border-bottom(v-if="!columns || columns.length === 0")
          div.my-3.text-center(v-if="canEdit")
            h6 No columns configured yet!
            div
              | #[button#new-field-btn.btn.btn-vsm.btn-primary Click Here]
              | to create your first column.
            field-edit-popover(
              target="#new-field-btn"
              :spreadsheet-id="spreadsheetId"
              @saved="refresh")
        table.r3s-spreadsheet.table.table-bordered.table-striped.small-padding(v-else)
          thead.bg-light
            draggable(
              tag="tr"
              v-model="mutableColumns"
              @change="changeColumnOrder")
                spreadsheet-th(
                  v-for="(field, cInd) in mutableColumns"
                  :key="field.id"
                  :spreadsheet-id="spreadsheetId"
                  :field="field"
                  :is-sorting="sortCol == field.id"
                  :sort-direction="sortDir"
                  :canEdit="canEdit"
                  @updated="refresh"
                  @sort="sortByCol")
                th(v-if="canEdit" style="vertical-align: top")
                  div.d-flex.justify-content-end
                    new-field-button(
                      :spreadsheet-id="spreadsheetId",
                      @saved="refresh")
          tbody.hover-fade
            tr(v-if="!filteredRows || filteredRows.length === 0")
              td(colspan="100%")
                i No rows added yet...
            tr(
              v-else,
              v-for="row,index in filteredRows"
              :class="{ 'sticky-row bg-light': isFrozen == row.row_id }")
              spreadsheet-td(
                v-for="field in mutableColumns"
                :spreadsheet-id="spreadsheetId"
                :key="field.id"
                :field="field"
                :row="row"
                :columns="columns"
                :canEdit="canEdit"
                @updated="refresh")
              td.p-0
                div(v-if="row.row_id && canEdit")
                  comments.border-bottom.my-1.py-1.px-2(
                    table="spreadsheet_rows",
                    :entity-id="row.row_id",
                    :modal-title="`Row Comments`")
                  files.border-bottom.my-1.py-1.px-2(
                    table="spreadsheet_rows",
                    :entity-id="row.row_id",
                    :modal-title="`Row Attachments`")
                  div.border-bottom.my-1.py-1.px-2.r3s-entity-icon.d-flex.align-items-center.justify-content-center
                    a.mr-2.comment-button(
                      :id="`dup-row-${row.row_id}`"
                      @click="duplicateRow(row.row_id)")
                        i.fal.fa-copy
                    b-tooltip(:target="`dup-row-${row.row_id}`") Copy row
                    a.comment-button(:id="`delete-row-${row.row_id}`")
                      i.fal.fa-times-circle.text-danger
                    confirmation-popover(
                      :target="`delete-row-${row.row_id}`",
                      header="Confirm Delete Row?",
                      text="Are you sure you want to delete this inventory row?",
                      @confirm="deleteRow(row.row_id)")
                  div.my-1.py-1.px-2.d-flex.align-items-center.justify-content-center
                    a(@click="freezeRow(row.row_id)",:id="`freeze-row-${row.row_id}`")
                      i.fal.fa-snowflake
                    b-tooltip(:target="`freeze-row-${row.row_id}`") Freeze row
            tr(v-if="filteredRows.length < mutableRows.length")
              td.text-center(colspan="100%" v-observe-visibility="{once: false, callback: isVisible => loadMoreRows(5, isVisible)}") Loading more rows... #[loader-inline]
    branded-card-footer

    //- confirmation-popover(
    //-   :target="`delete-row-${_uid}`",
    //-   header="Confirm Delete Row?",
    //-   :text="`Are you sure you want to delete this row?`",
    //-   @confirm="deleteField")
</template>

<script>
  import { mapState } from 'vuex'
  import FieldEditPopover from './FieldEditPopover'
  import NewFieldButton from './NewFieldButton'
  import SpreadsheetTd from './SpreadsheetTd'
  import SpreadsheetTh from './SpreadsheetTh'
  import InventoryHelpers from '../../../factories/InventoryHelpers'
  import ApiSpreadsheet from '../../../factories/ApiSpreadsheet'

  export default {
    props: {
      spreadsheetId: { type: [Number, String], required: true },
      title: { type: String, default: 'Spreadsheet' },
      columns: { type: Array, default: () => null },
      rows: { type: Array, default: () => null },
      canEdit: { type: Boolean, default: true },
    },

    watch: {
      columns: {
        handler(newCols) {
          this.mutableColumns = (newCols || []).slice(0)
        },
        deep: true,
      },

      rows: {
        handler(newRows) {
          this.mutableRows = (newRows || []).slice(0)
        },
        deep: true,
      },
    },

    data() {
      return {
        mutableColumns: (this.columns || []).slice(0),
        mutableRows: (this.rows || []).slice(0),
        sortCol: null,
        sortDir: null,
        isFrozen: null,
        visibleRowsCount: 5,
      }
    },

    computed: mapState({
      filteredRows(state) {
        const allFilters = state.inventories.filters
        const helpers = InventoryHelpers(this)
        const sorted = helpers.sortedRows(
          this.mutableRows,
          this.mutableColumns,
          this.sortCol,
          this.sortDir
        )
        const filtered = helpers.filteredRows(
          sorted,
          this.mutableColumns,
          allFilters[this.spreadsheetId]
        )
        return filtered.slice(0, this.visibleRowsCount)
      },
    }),

    methods: {
      refresh() {
        this.$emit('refresh', ...arguments)
      },

      sortByCol(fieldId, direction) {
        this.sortCol = fieldId
        this.sortDir = direction
      },

      freezeRow(rowId) {
        this.isFrozen = this.isFrozen == rowId ? null : rowId
      },

      loadMoreRows(count = 5, isVisible) {
        if (isVisible) this.visibleRowsCount += count
      },

      async duplicateRow(rowId) {
        if (!this.canEdit) return
        try {
          await ApiSpreadsheet.copyRow(rowId)
          window.toastr.success(
            `Successfully duplicated row at the end of your inventory.`
          )
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.refresh()
        }
      },

      async newRow() {
        if (!this.canEdit) return
        try {
          await ApiSpreadsheet.addRow(this.spreadsheetId)
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.refresh()
        }
      },

      async deleteRow(id) {
        if (!this.canEdit) return
        try {
          await ApiSpreadsheet.deleteRow(id)
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.refresh()
        }
      },

      async changeColumnOrder() {
        if (!this.canEdit) return
        try {
          await Promise.all(
            this.mutableColumns.map(async (col, ind) => {
              await ApiSpreadsheet.saveField({
                id: col.id,
                spreadsheet_id: this.spreadsheetId,
                ordering: ind + 1,
              })
            })
          )
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.refresh()
        }
      },
    },

    created() {
      this.mutableColumns = (this.columns || []).slice(0)
      this.mutableRows = (this.rows || []).slice(0)
    },

    components: {
      FieldEditPopover,
      NewFieldButton,
      SpreadsheetTd,
      SpreadsheetTh,
    },
  }
</script>

<style lang="scss" scoped>
  .scrollable {
    max-height: 85vh;
  }
  .sticky-row {
    position: sticky !important;
    top: 3.5rem !important;
    z-index: 10 !important;
  }
  .r3s-spreadsheet {
    border-collapse: separate;
    border-spacing: 0;
  }
  table.r3s-spreadsheet {
    th,
    td {
      vertical-align: top;
    }
  }
</style>
