<template lang="pug">
  file-uploader(
    defaultMessage="Click here to upload your respective tool's exported files for us to process.",
    :remove-after-upload="false",
    @added="addedFile",
    @processing="processingFile")
</template>

<script>
  export default {
    methods: {
      addedFile([, response]) {
        this.$emit('fileAdded', response)
      },

      processingFile() {
        this.$emit('fileProcessing')
      },
    },
  }
</script>
