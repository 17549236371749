import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  async allTypes() {
    const response = await R3sFetch(`/api/1.0/reports/types/all`)
    return await handleFetchResponse(response)
  },

  async getAllReportConfigurations(page = 1, perPage = 30, sorting = {}) {
    const response = await R3sFetch(
      `/api/1.0/reports/all?page=${page || ''}&pageSize=${
        perPage || ''
      }&sorting=${encodeURIComponent(JSON.stringify(sorting))}`
    )
    return await handleFetchResponse(response)
  },

  async getTypeConfiguration(typeId) {
    const response = await R3sFetch(
      `/api/1.0/reports/configuration/get?typeId=${typeId}`
    )
    return await handleFetchResponse(response)
  },

  async generatedReports(page = 1, perPage = 30) {
    const response = await R3sFetch(
      `/api/1.0/reports/generated/get?page=${page || ''}&pageSize=${
        perPage || ''
      }`
    )
    return await handleFetchResponse(response)
  },

  async submitReport(typeId, config) {
    const response = await R3sFetch(`/api/1.0/reports/generate`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ typeId, config }),
    })
    return await handleFetchResponse(response)
  },

  async getTeamConfigurations(reportTypeId = null) {
    let queryParams = ``
    if (reportTypeId) queryParams += `?reportTypeId=${reportTypeId}`
    const response = await R3sFetch(
      `/api/1.0/reports/team/configurations/get?${queryParams}`
    )
    return await handleFetchResponse(response)
  },

  async saveTeamConfigurations(
    reportConfiguration,
    scheduleConfiguration,
    accessConfiguration
  ) {
    const response = await R3sFetch(
      `/api/1.0/reports/team/configurations/save`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          configuration_id: reportConfiguration.configuration_id,
          report_type_id: reportConfiguration.report_type_id,
          report_name: reportConfiguration.report_name,
          report_description: reportConfiguration.report_description,
          filters: reportConfiguration.filters,
          groupBy: reportConfiguration.groupBy,
          sortBy: reportConfiguration.sortBy,
          outputType: reportConfiguration.outputType,
          orientation: reportConfiguration.orientation,

          is_recurring: scheduleConfiguration.isRecurring,
          start_date: scheduleConfiguration.startDate,
          recurring_options: scheduleConfiguration.recurringOptions,
          email_data: scheduleConfiguration.emailData,

          permissionsCode: accessConfiguration.permissionsCode,
          permissionsUserList: accessConfiguration.permissionsUserList,
        }),
      }
    )
    return await handleFetchResponse(response)
  },

  async getReport(
    { internalNamespace, internalKey, filters, configuration, isPreview },
    signal = null
  ) {
    const response = await R3sFetch(`/api/1.0/reports/get`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        internalNamespace,
        internalKey,
        filters,
        configuration,
        isPreview,
      }),
      signal,
    })
    return await handleFetchResponse(response)
  },

  async emailReport(
    { emailConfig, emailUserAddresses, emailSubject, emailMessage },
    signal = null
  ) {
    const response = await R3sFetch(`/api/1.0/reports/email`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        emailConfig,
        emailUserAddresses,
        emailSubject,
        emailMessage,
      }),
      signal,
    })
    return await handleFetchResponse(response)
  },
}
