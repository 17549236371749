<template lang="pug">
div.modal.fade(tabindex="-1",role="dialog",aria-labelledby="generate-report-label",aria-hidden="true")
  div.modal-dialog.modal-max(role="document")
    div.modal-content(style="min-height: 80vh;")
      div(v-if="isLoadingLocal")
          loader
      template(v-else)
        div.modal-header.d-flex.align-items-center
          h5.modal-title Generate Report

          div.ml-auto.d-flex-align-itmes-center
            a.mr-3#download-report-icon(@click="downloadReport")
                loader-inline(v-if="downloadingReport")
                i.fas.fa-download(v-else)
                b-tooltip(:target="'#download-report-icon'") Download Report
            
            a.mr-4#email-report-icon(v-if="userCanEditReports" data-toggle="modal", :data-target="`#email-report-modal-${_uid}`")
                loader-inline(v-if="emailingReport")
                i.fas.fa-envelope(v-else)
                b-tooltip(:target="'#email-report-icon'") Email Report

          i.fa.fa-times.clickable(data-dismiss="modal")
        div.modal-body.p-2
          div.d-flex
            div.col-3.sticky-card(style="top: 0; height: 80vh; overflow-y: auto;")
              div
                custom-details.border-bottom(title="Details", :openByDefault="true")
                  template(v-slot:content)
                    div.p-2
                      div.mb-2
                        div(v-if="!userCanEditReports")
                          i.small #[span.text-danger *] As an analyst, you can view and generate reports. Ability to edit reports is restricted.
                        div.strong.mb-1 Report Type
                        b-dropdown.type-dropdown(
                          :id="`report-type-dropdown-${_uid}`"
                          :text="currentReportType?.report_name || 'Select Report Type'", 
                          variant="outline-primary", 
                          boundary="scrollParent",
                          size="sm",
                          :disabled="!userCanEditReports")
                          b-dropdown-item(
                            v-for="reportType in availableReports", 
                            :key="reportType.id", 
                            :active="currentReportType.id === reportType.id",
                            @click="setCurrentReportType(reportType.id)"
                          )
                            | {{ reportType?.report_name }}
                          hr(v-if="availableExports.length > 0")
                          b-dropdown-item(
                            v-for="reportType in availableExports", 
                            :key="reportType.id", 
                            :active="currentReportType.id === reportType.id",
                            @click="setCurrentReportType(reportType.id)"
                          )
                            | {{ reportType?.report_name }} #[small.text-gray (Export)]
                      div.mb-2
                        div.strong.mb-1 Name #[span.text-danger *]
                        input.form-control.mb-2(
                          placeholder="Enter report name..."
                          v-model="mutableReportName"
                          :disabled="!userCanEditReports")
                      div.mb-2
                            div.strong.mb-1 Description
                            input.form-control.mb-2(
                              placeholder="Enter report description..."
                              v-model="mutableReportDescription"
                              :disabled="!userCanEditReports")
                            
              div(v-for="dropdown in configDropdowns")
                custom-details.border-bottom(:title="dropdown", :openByDefault="openByDefault(dropdown)")
                  template(v-slot:content)
                    div.p-2(v-show="dropdown === 'Layout'")
                      div.mb-2
                        section-layout(
                          :outputType="mutableOutputType",
                          :groupBy="mutableGroupBy",
                          :sortBy="mutableSortBy",
                          :orientation="mutableOrientation",
                          :userCanEdit="userCanEditReports",
                          @update="updateReportConfig")
                    div.p-2(v-show="dropdown === 'Filters'")
                      div.mb-2
                        section-filters(
                          :filterValues="mutableFilters",
                          :userCanEdit="userCanEditReports",
                          @updateFilter="updateFilterValue")
                    //- div.p-2(v-show="dropdown === 'Columns'")
                    //-   div.d-flex.justify-content-between.align-items-center.mb-2
                    //-     div.mr-2 {{ dropdown }}
                    //-     select.form-control
                    //-       option(value="1") Default
                    //-       option(value="2") Custom
                    div.p-2(v-show="dropdown === 'Schedule'")
                      div.mb-2
                        section-schedule(
                          :startDate="mutableStartDate", 
                          :recurringOptions="mutableRecurringOptions", 
                          :isRecurring="mutableIsRecurring", 
                          :emailData="mutableEmailData",
                          :userCanEdit="userCanEditReports",
                          @update="updateReportConfig"
                        )
                    //-   div.d-flex.justify-content-between.align-items-center.mb-2
                    //-     div.mr-2 {{ dropdown }}
                    //-     select.form-control
                    //-       option(value="1") Default
                    //-       option(value="2") Custom
                    div.p-2(v-show="dropdown === 'Access'")
                      div.mb-2
                        section-access(
                          :code="mutablePermissionsCode",
                          :userList="mutablePermissionsUserList",
                          :userCanEdit="userCanEditReports",
                          @updateAccess="updateAccess")
                    //- div.p-2(v-show="dropdown === 'Tags'")
                    //-   div.d-flex.justify-content-between.align-items-center.mb-2
                    //-     div.mr-2 {{ dropdown }}
                    //-     select.form-control
                    //-       option(value="1") Default
                    //-       option(value="2") Custom
            div.col-9.bg-light(style="max-height: 80vh; overflow-y: auto;")
              loader(v-if="loadingPreview")

              template(v-else-if="!currentReportType.id")
                div.d-flex.justify-content-center.align-items-center.h-100
                  h5 Please select a report type to see a preview
                
              template(v-else-if="!canBeSubmitted")
                div.d-flex.justify-content-center.align-items-center.h-100
                  h5 Please select all required filters to see a preview

              loader(v-else-if="reportPreview == null")
              template(v-else)
                div.card.no-shadow.my-4(v-if="reportIsHtml" :style="`float:none; margin:auto; ${isPreviewLandscape ? 'width: 11in; ' : 'width: 8.5in; '}`")
                  div.card-body.p-0
                    iframe.w-100(ref="reportPreview" seamless="seamless" style="flex-grow: 1; border: none; margin: 0; padding: 0;" :srcdoc="reportPreview" @load="resizeIFrame")
                prettify-array-of-objects.my-4(v-else :tabular-data="reportPreview")

        div.modal-footer 
          div.d-flex.justify-content-between.align-items-center
            button.btn.btn-sm.btn-secondary.ml-2(
              type="button" 
              data-dismiss="modal") Cancel
            button.btn.btn-sm.btn-primary.ml-2(
              v-if="!!configurationId"
              :disabled="saving || !userCanEditReports || !hasRequiredFieldsForSave" 
              type="button" 
              @click="saveReportConfiguration(true)") Update Existing #[loader-inline(v-if="saving")]
            button.btn.btn-sm.btn-primary.ml-2(
              type="button" 
              :disabled="saving || !userCanEditReports || !hasRequiredFieldsForSave" 
              @click="saveReportConfiguration(false)") Save As New #[loader-inline(v-if="saving")]
  
  email-report-modal(
    :id="`email-report-modal-${_uid}`",
    :emailSubject="defaultEmailSubject", 
    :emailMessage="defaultEmailMessage",
    :userCanEditReports="userCanEditReports",
    @emailReport="emailReport")
</template>

<script>
  import $ from 'jquery'

  import EmailReportModal from './EmailReportModal.vue'
  import ReportHeaderTabs from '../../../components/reporting/generator/ReportHeaderTabs'
  import SectionAccess from '../../../components/reporting/generator/SectionAccess'
  import SectionConfirm from '../../../components/reporting/generator/SectionConfirm'
  import SectionFilters from '../../../components/reporting/generator/SectionFilters'
  import SectionLayout from '../../../components/reporting/generator/SectionLayout'
  import SectionSchedule from '../../../components/reporting/generator/SectionSchedule'
  import ApiReports from '../../../factories/ApiReports'
  import Utilities from '@risk3sixty/utilities'
  import DomHelpers from '@/factories/DomHelpers'
  import { mapState, mapGetters } from 'vuex'

  const moment = Utilities.Dates.moment

  export default {
    props: {
      // Either get report type by ID
      reportTypeId: { type: [Number, String] },
      // Or get report type by namespace and key
      reportNamespace: { type: String },
      reportInternalKey: { type: String },

      reportConfiguration: { type: Object, default: () => ({}) },
    },

    data() {
      return {
        currentType: null,
        configurationId: null,

        mutableReportName: null,
        mutableReportDescription: null,
        mutableOutputType: null,
        mutableGroupBy: null,
        mutableSortBy: null,
        mutableOrientation: null,
        mutableFilters: {},
        //Scheduling
        mutableIsRecurring: false,
        mutableStartDate: new Date(),
        mutableRecurringOptions: {},
        mutableEmailData: {
          emailSubject: null,
          emailMessage: null,
          emailUserAddresses: [],
        },

        mutablePermissionsUserList: null,
        mutablePermissionsCode: 0,

        abortController: new AbortController(),
        isLoadingLocal: false,
        loadingPreview: false,
        // reportPreview is either a string of HTML or an array of objects
        // null means no preview has been loaded
        reportPreview: null,
        reportIsHtml: null,

        downloadingReport: false,
        emailingReport: false,
        saving: false,

        configDropdowns: [
          'Layout',
          'Filters',
          // 'Columns',
          'Schedule',
          'Access',
          // 'Tags',
        ],
      }
    },

    computed: {
      ...mapState({
        currentTeamName: (state) => state.session.current_team.name,
        reportTypes: (state) => Object.values(state.reporting.types).flat(),
        currentReportType: (state) => state.reporting.currentType || {},

        defaultEmailMessage: (_, getters) => getters.defaultEmailMessage,
      }),

      ...mapGetters(['userCanModify', 'isManager']),

      userCanEditReports() {
        return this.userCanModify(this.isManager)
      },

      namespaceReportTypes() {
        return this.reportNamespace != 'reporting'
          ? this.reportTypes.filter(
              (rt) => rt.internal_namespace == this.reportNamespace
            )
          : this.reportTypes
      },

      availableReports() {
        return this.namespaceReportTypes
          .filter((rt) => rt.internal_namespace == 'my_controls')
          .sort((a, b) => a.report_name.localeCompare(b.report_name))
      },

      availableExports() {
        return this.namespaceReportTypes
          .filter((rt) => rt.internal_namespace != 'my_controls')
          .sort((a, b) => a.report_name.localeCompare(b.report_name))
      },

      canBeSubmitted() {
        return (
          (
            Object.entries(
              this.currentReportType?.configuration?.filters || {}
            ) || []
          ).filter(([key, { required }]) => {
            if (required) {
              try {
                const currentVal = this.mutableFilters[key]?.value || null
                return !currentVal || currentVal.length === 0
              } catch (e) {
                return true
              }
            } else {
              return false
            }
          }).length === 0
        )
      },

      isPreviewLandscape() {
        return this.mutableOrientation === 'landscape'
      },

      defaultEmailSubject() {
        return (
          this.mutableReportName ||
          this.currentReportType?.report_name ||
          'fullCircle'
        )
      },

      isExportType() {
        return this.currentReportType.internal_namespace != 'my_controls'
      },

      hasRequiredFieldsForPreview() {
        // if not an export, ensure all fields are filled out
        if (!this.isExportType) {
          return (
            this.mutableGroupBy &&
            this.mutableSortBy &&
            this.mutableOutputType &&
            this.mutableOrientation &&
            this.mutableFilters
          )
        }
        // if an export, ensure filters are not null
        return !!this.mutableFilters
      },

      hasRequiredFieldsForSave() {
        return this.mutableReportName && this.hasRequiredFieldsForPreview
      },
    },

    methods: {
      openByDefault(dropdown) {
        return dropdown === 'Layout' && this.currentReportType.id ? true : false
      },

      async setCurrentReportType(
        reportTypeId = null,
        reportNamespace = null,
        reportInternalKey = null
      ) {
        if (!reportTypeId) {
          const matchedReport = this.reportTypes.find(
            (rt) =>
              rt.internal_namespace == reportNamespace &&
              rt.internal_key == reportInternalKey
          )
          if (!matchedReport) return
          reportTypeId = matchedReport.id
        }
        await this.$store.dispatch('setCurrentReportType', reportTypeId)
        this.resetMutableConfigs()
        this.getReportPreview()
      },

      resetMutableConfigs() {
        this.configurationId = null
        this.mutableReportName = null
        this.mutableReportDescription = null
        this.mutableOutputType = null
        this.mutableGroupBy = null
        this.mutableSortBy = null
        this.mutableOrientation = null
        this.mutableFilters = {}
        this.mutableIsRecurring = false
        this.mutableStartDate = new Date()
        this.mutableRecurringOptions = {}
        this.mutableEmailData = {
          emailSubject: this.defaultEmailSubject,
          emailMessage: this.defaultEmailMessage,
          emailUserAddresses: [],
        }
        this.mutablePermissionsUserList = null
        this.mutablePermissionsCode = 0

        this.reportPreview = null
        this.reportIsHtml = null
      },

      resizeIFrame() {
        const reportPreview = this.$refs.reportPreview
        // for some reason this is being called multiple times, sometimes before the ref exists
        // regardless we want to clear the loading state
        if (reportPreview) {
          reportPreview.style.height =
            reportPreview.contentWindow.document.documentElement.scrollHeight +
            'px'
        }

        this.loadingPreview = false
      },

      getLastRunDate(date) {
        return date ? moment(date).format('MM/DD/YY') : 'Never'
      },

      updateReportConfig(key, val, getPreview = true) {
        switch (key) {
          case 'groupBy':
            this.mutableGroupBy = val
            if (getPreview) {
              this.getReportPreview()
            }
            break
          case 'sortBy':
            this.mutableSortBy = val
            if (getPreview) {
              this.getReportPreview()
            }
            break
          case 'outputType':
            this.mutableOutputType = val
            if (getPreview) {
              this.getReportPreview()
            }
            break
          case 'orientation':
            this.mutableOrientation = val
            if (getPreview) {
              this.getReportPreview()
            }
            break
          case 'startDate':
            this.mutableStartDate = val
            break
          case 'recurringOptions':
            this.mutableRecurringOptions = val
            break
          case 'isRecurring':
            this.mutableIsRecurring = val
            break
          case 'emailData':
            this.mutableEmailData = val
            break
        }
      },

      updateFilterValue(key, val) {
        this.mutableFilters = {
          ...this.mutableFilters,
          [key]: { value: val },
        }

        this.getReportPreview()

        // reload iframe to track when it is done loading the new display
        this.$refs.reportPreview.contentWindow.location.reload()
      },

      updateAccess(newAccessValue) {
        const { userList, code } = newAccessValue
        this.mutablePermissionsUserList = userList
        this.mutablePermissionsCode = code
      },

      async saveReportConfiguration(updateExisting = false) {
        this.saving = true
        try {
          await ApiReports.saveTeamConfigurations(
            {
              configuration_id: updateExisting ? this.configurationId : null,
              report_type_id: this.currentReportType.id,
              report_name: this.mutableReportName,
              report_description: this.mutableReportDescription,
              groupBy: this.mutableGroupBy,
              sortBy: this.mutableSortBy,
              outputType: this.mutableOutputType,
              orientation: this.mutableOrientation,
              filters: this.mutableFilters,
            },
            {
              isRecurring: this.mutableIsRecurring,
              recurringOptions: this.mutableRecurringOptions,
              startDate: this.mutableStartDate,
              emailData: this.mutableEmailData,
            },
            {
              permissionsCode:
                this.mutablePermissionsCode == 9999
                  ? null
                  : this.mutablePermissionsCode,
              permissionsUserList: this.mutablePermissionsUserList,
            }
          )
          window.toastr.success('Report configuration saved!')
          $(`#${this.$el.id}`).modal('hide')
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.$emit('updated')
          this.saving = false
        }
      },

      async downloadReport() {
        if (this.downloadingReport) return

        this.downloadingReport = true
        const report = {
          ...this.currentReportType,
          groupBy: this.mutableGroupBy,
          sortBy: this.mutableSortBy,
          outputType: this.mutableOutputType,
          filters: this.mutableFilters,
          orientation: this.mutableOrientation,
          reportName: this.mutableReportName,
        }
        try {
          const { filename } = await ApiReports.getReport({
            internalNamespace: report.internal_namespace,
            internalKey: report.internal_key,
            configuration: {
              ...report,
            },
            isPreview: false,
          })

          if (!filename)
            throw Error(
              'There was an error downloading the report. Please try again later.'
            )

          DomHelpers.downloadUri(
            `/file/s3/${encodeURIComponent(filename)}`,
            filename
          )
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.downloadingReport = false
        }
      },

      async emailReport({ emailUserAddresses, emailSubject, emailMessage }) {
        this.emailingReport = true
        try {
          const report = {
            ...this.currentReportType,
            groupBy: this.mutableGroupBy,
            sortBy: this.mutableSortBy,
            outputType: this.mutableOutputType,
            filters: this.mutableFilters,
            orientation: this.mutableOrientation,
            reportName: this.mutableReportName,
          }

          await ApiReports.emailReport({
            emailConfig: {
              internal_namespace: report.internal_namespace,
              internal_key: report.internal_key,
              configuration: {
                ...report,
              },
            },
            emailUserAddresses,
            emailSubject,
            emailMessage,
          })

          window.toastr.success('Report has been emailed!')
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.emailingReport = false
        }
      },

      async getReportTypes(reset = false) {
        try {
          await this.$store.dispatch('getReportTypes', reset)
        } catch (err) {
          window.toastr.error(`Error getting report types: ${err.message}`)
        } finally {
          this.isLoadingLocal = false
        }
      },

      async getReportPreview() {
        this.loadingPreview = true
        this.reportIsHtml = null

        // check if outputtype, groupby, sortby, & orientation are filled out
        // for non-export reports
        if (!this.hasRequiredFieldsForPreview) {
          this.loadingPreview = false
          this.reportPreview = null
          return
        }

        if (
          !this.canBeSubmitted ||
          Object.keys(this.currentReportType).length === 0
        ) {
          this.reportPreview = null
          this.loadingPreview = false
          return
        }
        if (this.abortController && this.abortController.abort()) {
          this.abortController.abort()
        }
        try {
          this.abortController = new AbortController()
          const { reportOutput, html } = await ApiReports.getReport(
            {
              internalNamespace: this.currentReportType.internal_namespace,
              internalKey: this.currentReportType.internal_key,
              configuration: {
                groupBy: this.mutableGroupBy,
                sortBy: this.mutableSortBy,
                outputType: this.mutableOutputType,
                filters: this.mutableFilters,
                reportName: this.mutableReportName || 'Report Preview',
                orientation: this.mutableOrientation,
              },
              isPreview: true,
            },
            this.abortController.signal
          )
          this.reportPreview = reportOutput
          this.reportIsHtml = !!html
        } catch (err) {
          if (err.name === 'AbortError') return
          window.toastr.error(err.message)
        } finally {
          if (!this.reportIsHtml) {
            this.loadingPreview = false
          } else {
            this.resizeIFrame()
          }
        }
      },
    },

    mounted() {
      this.getReportTypes()
      $(`#${this.$el.id}`).on('shown.bs.modal', async () => {
        await this.setCurrentReportType(
          this.reportTypeId,
          this.reportNamespace,
          this.reportInternalKey
        )
        this.configurationId = this.reportConfiguration.configuration_id
        this.mutableReportName = this.reportConfiguration.report_name
        this.mutableReportDescription =
          this.reportConfiguration.report_description
        this.mutableGroupBy = this.reportConfiguration.groupBy
        this.mutableSortBy = this.reportConfiguration.sortBy
        this.mutableOutputType = this.reportConfiguration.outputType
        this.mutableFilters = this.reportConfiguration.filters || {}
        this.mutableOrientation = this.reportConfiguration.orientation
        this.mutablePermissionsUserList = this.reportConfiguration.user_list
        this.mutablePermissionsCode =
          this.reportConfiguration.permission_code == null
            ? null
            : parseInt(this.reportConfiguration.permission_code)
        this.mutableStartDate = this.reportConfiguration.start_date
        this.mutableRecurringOptions =
          this.reportConfiguration.recurring_options
        this.mutableIsRecurring = this.reportConfiguration.is_recurring
        this.mutableEmailData = this.reportConfiguration.email_data
          ? JSON.parse(this.reportConfiguration.email_data)
          : {
              emailSubject: this.defaultEmailSubject,
              emailMessage: this.defaultEmailMessage,
              emailUserAddresses: [],
            }
        this.getReportPreview()
      })
      $(`#${this.$el.id}`).on('hidden.bs.modal', async () => {
        this.resetMutableConfigs()
        this.abortController.abort()
      })
    },

    components: {
      EmailReportModal,
      ReportHeaderTabs,
      SectionAccess,
      SectionConfirm,
      SectionFilters,
      SectionLayout,
      SectionSchedule,
    },
  }
</script>
<style lang="scss" scoped>
  .modal-configuration {
    border-right: 1px solid #e9ecef;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }

  .card {
    border: 1px solid #e1e5eb;
    border-radius: 0%;
  }
  .type-dropdown /deep/ .dropdown-menu {
    max-height: 80vh;
    overflow-y: auto;
  }
</style>
