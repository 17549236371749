<template lang="pug">
div
  h6 Email Info
  div.pl-2
    div.form-group.mt-2
      label(for="send-email-recipients") Recipients:
      typeahead-input-new#send-email-recipients(
        :options="teamAndInternalUsers"
        :showPropFunction="u => u.name"
        placeholder="Add recipient..."
        :includeSearchInOptions="true"
        :optionSearchFilter="(searchText, option) => option.name.toLowerCase().includes(searchText.toLowerCase()) || option.username_email.toLowerCase().includes(searchText.toLowerCase())"
        :disabled="!userCanEdit"
        @input="(user) => updateEmailAddresses('add', user.username_email || user)")

      div.mt-2
        div(v-if="emailUserAddresses.length === 0")
          small No recipients added...
        div.d-flex(v-else, v-for="email in emailUserAddresses")
          strong
            | {{ email }}
            | #[i.clickable.fal.fa-times.text-danger(@click="updateEmailAddresses('delete', email)")]

    div.form-group.mt
      label(for="send-email-subject") Subject:
      input#send-email-subject.form-control(
        v-model="mutableEmailSubject" 
        :disabled="!userCanEdit")

    div.form-group.mt
      label(for="send-email-message") Message:
      rich-text-editor#send-email-message(
        v-model="mutableEmailMessage" 
        :disabled="!userCanEdit")
</template>

<script>
  import { mapState } from 'vuex'
  import ApiAuth from '../../../factories/ApiAuth'

  export default {
    props: {
      emailSubject: { type: String, required: true },
      emailMessage: { type: String, required: true },
      emailUserAddresses: { type: Array, default: () => [] },
      userCanEdit: { type: Boolean, default: true },
    },

    data() {
      return {
        selectedUserId: null,
        newEmailAddress: null,
      }
    },

    computed: {
      ...mapState({
        teamAndInternalUsers: (state) => state.team.teamAndInternalUsers,
      }),

      mutableEmailSubject: {
        get() {
          return this.emailSubject
        },
        set(value) {
          this.$emit('update', 'emailSubject', value)
        },
      },

      mutableEmailMessage: {
        get() {
          return this.emailMessage
        },
        set(value) {
          this.$emit('update', 'emailMessage', value)
        },
      },
    },

    methods: {
      isValidEmail: ApiAuth.isValidEmail,

      updateEmailAddresses(operation, email) {
        const emailUserAdressesSet = new Set(this.emailUserAddresses)
        if (operation == 'add') {
          if (!this.isValidEmail(email)) {
            return window.toastr.error('Please enter valid email.')
          }
          emailUserAdressesSet.add(email)
        } else {
          emailUserAdressesSet.delete(email)
        }

        this.$emit(
          'update',
          'emailUserAddresses',
          Array.from(emailUserAdressesSet)
        )
        this.newEmailAddress = null
      },
    },
  }
</script>
