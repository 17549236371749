<template lang="pug">
  div.modal-select-list.modal.fade(tabindex="-1",role="dialog",aria-labelledby="add-user-to-module-label",aria-hidden="true")
    div.modal-dialog(role="document")
      div.modal-content
        div.modal-header
          h5#add-user-to-module-label.modal-title Permissions for {{ moduleText }}
          button.close(type="button",data-dismiss="modal",aria-label="Close")
            span(aria-hidden="true") &times;
        div.modal-body.p-0
          form.border-bottom.py-3.px-4(@submit="inviteUser")
            div.form-group.m-0
              //- label(for="invite-new-user-to-module") Add New User
              input#invite-new-user-to-module.form-control(placeholder="Add new user by e-mail address...",v-model="invitedUserEmail")
          ul.list-unstyled.m-0
            li.border-bottom
              div.d-flex.align-items-center
                div #[small User]
                div.ml-auto #[small Access to {{ moduleText }}]
            li.border-bottom(v-if="numberUsers === 0")
              i No analyst users in your org yet. Invite new users above...
            template(v-else,v-for="(userList, key) in users")
              li.border-bottom.user-row(v-for="(user, index) in userList")
                div.d-flex.align-items-center
                  div.mr-2(v-if="user.avatar_file")
                    img.user-avatar.rounded-circle(style="max-height: 40px;",:src="'/file/s3/' + user.avatar_file")
                  div
                    strong {{ user.name || user.username_email }}
                    div.small.text-light
                      div.small {{ user.username_email }}
                      - // div.small(v-if="users.internal.length > 0") {{ titleCase(key) }} User
                  div.ml-auto
                    div.form-group.m-0.d-flex.justify-content-center
                      div.custom-control.custom-toggle-sm.custom-toggle(:id="module + '-container-' + index")
                        input.custom-control-input(
                          type="checkbox",
                          :id="module + '-' + index",
                          :name="module + '-' + index",
                          v-model="user.app_permissions[permission]",
                          @change="updateUserPermissions(user)"
                        )
                        label.custom-control-label(:for="module + '-' + index")
                      b-tooltip(:target="module + '-container-' + index")
                        | {{ titleCase(permission.replace('is_', '').replace('_customer', ''), true) }}
                        | ({{ user.name || user.username_email }})
                    div.small {{ titleCase(user.role) }}
        div.modal-footer.border-0
          button.btn.btn-secondary(type="button",data-dismiss="modal") Close
          //- button.btn.btn-primary(type="button") Save
</template>

<script>
  import $ from 'jquery'
  import ApiAdmin from '../../factories/ApiAdmin'
  import ApiAuth from '../../factories/ApiAuth'
  import ApiTeams from '../../factories/ApiTeams'
  import ApiUsers from '../../factories/ApiUsers'
  import StringHelpers from '../../factories/StringHelpers'

  export default {
    props: {
      module: { type: String },
    },

    data() {
      return {
        invitedUserEmail: '',
        users: {
          external: [],
          internal: [],
        },
      }
    },

    computed: {
      moduleText() {
        return StringHelpers.titleCase(this.module, true)
      },

      permission() {
        return `is_${this.module}_customer`
      },

      numberUsers() {
        return Object.keys(this.users).reduce((tot, key) => {
          return tot + (this.users[key] || []).length
        }, 0)
      },
    },

    methods: {
      titleCase: StringHelpers.titleCase,

      async updateUserPermissions(user, showSuccess = true) {
        try {
          await ApiUsers.updateTeamModulePermissions(
            user.app_permissions,
            user.id
          )

          if (showSuccess)
            window.toastr.success(
              `Successfully updated permissions for ${user.name ||
                user.username_email}!`
            )
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async inviteUser(evt) {
        try {
          evt.preventDefault()

          if (
            this.invitedUserEmail &&
            ApiAuth.isValidEmail(this.invitedUserEmail)
          ) {
            const { id } = await ApiTeams.inviteTeamMember({
              email: this.invitedUserEmail,
              send_email: true,
            })
            await this.updateUserPermissions(
              { id, app_permissions: { [this.permission]: true } },
              false
            )
            return await this.getUsers()
          }

          window.toastr.error(`Enter a valid e-mail address to add the user.`)
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async getUsers() {
        try {
          const { users } = await ApiAdmin.getUserTeamsPermissions(
            this.$store.state.session.current_team.id
          )
          this.users = users
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    mounted() {
      $(`#${this.$el.id}`).on(
        'show.bs.modal',
        async () => await this.getUsers()
      )
    },
  }
</script>
