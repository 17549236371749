<template lang="pug">
  div
    add-remove-btn-group(
      :show-remove="false"
      @add="addNewMapping")
    ul.list-unstyled.mt-2.mb-0
      li(v-if="!value || value.length === 0")
        i No mappings available...
      template(v-else)
        li.mb-2
          div.row
            div.col-md-6
              strong Mapping Type
            div.col.md-6
              strong Record
        li.mb-2(v-for="(map, index) in value")
          div.row
            div.col-md-6
              v-select(
                v-model="map.mapping_type"
                :options="extensionTypes"
                :reduce="v => v.value"
                :get-option-label="t => t.text")
            div.col-md-5
              component(
                :is="typeToComponent[map.mapping_type] || 'Default'"
                v-model="map.mapping_record_id")
            div.col-md-1.text-right
              a.text-danger(:id="`delete-record-mapping-${map.mapping_record_id}`")
                i.fal.fa-times-circle
              confirmation-popover(
                header="Delete Record Map"
                text="Are you sure you want to delete this mapping to the automation?"
                :target="`delete-record-mapping-${map.mapping_record_id}`"
                @confirm="removeVal(index)")
</template>

<script>
  import { mapState } from 'vuex'
  import AssessmentRequest from './mappingSelectors/AssessmentRequest'
  import Default from './mappingSelectors/Default'

  export default {
    props: {
      // mappings (i.e. recordMaps)
      value: { type: Array, default: null },
    },

    data() {
      return {
        typeToComponent: {
          evidence: AssessmentRequest,
          generic: Default,
        },
      }
    },

    computed: {
      ...mapState({
        extensionTypes: (state) => state.extensions.types,
      }),
    },

    methods: {
      addNewMapping() {
        this.$emit('input', [
          ...this.value,
          { mapping_type: 'generic', mapping_record_id: '0' },
        ])
      },

      removeVal(ind) {
        this.$emit('input', [
          ...this.value.slice(0, ind),
          ...this.value.slice(ind + 1),
        ])
      },
    },

    async mounted() {
      await this.$store.dispatch('getExtensionTypes')
    },

    components: {
      AssessmentRequest,
      Default,
    },
  }
</script>
