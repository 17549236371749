<template lang="pug">
  div
    div(v-if="isLoadingLocal")
      loader
    div(v-else)
      div.d-flex.align-items-center.justify-content-end.mb-2(
        data-toggle="modal"
        data-target="#create-system-comp-modal")
          button.btn.btn-primary Create New Category/Component
      div.card.card-small.no-shadow.border.mb-4(v-for="(tools, category) in components")
        div.card-header.border-bottom
          div.d-flex.align-items-center
            h6.m-0
              span {{ category }}
              i.add-item-to-group.text-gray.ml-1.fal.fa-plus.clickable(
                @click="$set(newComponentInCategory, category, !newComponentInCategory[category])")
        div.card-body.p-0.table-responsive
          system-components-table(
            :project-id="projectId"
            :tools="tools"
            :category="category"
            :is-creating-new="newComponentInCategory[category]"
            @toggleRow="toggleNewComponentInCategory"
            @update="refreshComponents")

    create-system-component-modal#create-system-comp-modal(
      :project-id="projectId"
      :categories="compCategories"
      @created="refreshComponents")
</template>

<script>
  import SystemComponentsRow from './SystemComponentsRow'
  import ApiAssessments from '../../factories/ApiAssessments'
  import ApiProgram from '../../factories/ApiProgram'

  export default {
    props: {
      projectId: { type: [Number, String], default: null },
    },

    data() {
      return {
        isLoadingLocal: true,
        components: null,
        newComponentInCategory: {},
      }
    },

    computed: {
      compCategories() {
        return Object.keys(this.components || {})
      },
    },

    methods: {
      async toggleNewComponentInCategory(comp) {
        this.newComponentInCategory[comp.category] = false
        await this.refreshComponents()
      },

      async refreshComponents() {
        await this.getSystemComponents()
      },

      async getSystemComponents() {
        try {
          let components = []
          if (this.projectId) {
            const res = await ApiAssessments.getSystemComponents(this.projectId)
            components = res.components
          } else {
            const res = await ApiProgram.getAllSystemComponents()
            components = res.components
          }

          this.components = components
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.isLoadingLocal = false
        }
      },
    },

    async created() {
      await this.getSystemComponents()
    },

    components: {
      SystemComponentsRow,
    },
  }
</script>

<style scoped lang="scss">
  .table tbody + tbody {
    border-top: 1px solid #dee2e6;
  }
</style>
