<template lang="pug">
  div#credential-update-modal.modal.fade(tabindex="-1",role="dialog",aria-labelledby="credential-update-modal",aria-hidden="true")
    div.modal-dialog(role="document")
      div.modal-content
        div.modal-header
          h5#credential-update-modal.modal-title Update Credentials
          button.close(type="button",data-dismiss="modal",aria-label="Close")
            span(aria-hidden="true") &times;
        div.modal-body
          div.form-group
            label(for="username") Username
            input.form-control(type="text",name="username",v-model="credRecord.username")
          div.form-group
            label(for="username") Password (#[a.small(@click="pwTypeToggle") {{ pwType === 'password' ? 'show' : 'hide' }}])
            input.form-control(:type="pwType",name="password",v-model="credRecord.password")
          div.form-group
            label(for="domain") Domain
            input.form-control(type="text",name="domain",v-model="credRecord.domain")
          div.form-group
            label(for="format") Format
            input.form-control(type="text",name="format",v-model="credRecord.format")
          div.form-group
            label(for="hash") Hash
            input.form-control(type="text",name="hash",v-model="credRecord.hash")
        div.modal-footer
          button.btn.btn-secondary(type="button",data-dismiss="modal") Cancel
          button.btn.btn-primary(@click="saveCredentials") Save
</template>

<script>
  import $ from 'jquery'
  import ApiPentesting from '../../factories/ApiPentesting'

  export default {
    props: {
      credId: { type: [ Number, String ]}
    },

    data() {
      return {
        credRecord: defaultCredentials(),
        pwType: 'password'
      }
    },

    methods: {
      pwTypeToggle() {
        return this.pwType = this.pwType === 'password' ? 'text' : 'password'
      },

      async getCredentialRecord() {
        try {
          const { credential } = await ApiPentesting.getCred(this.credId)
          this.credRecord = credential

        } catch(err) {
          window.toastr.error(err.message)
        }
      },

      async saveCredentials() {
        try {
          const { id } = await ApiPentesting.createUpdateCredentials({
            id: this.credId,
            domain: this.credRecord.domain,
            username: this.credRecord.username,
            password: this.credRecord.password,
            format: this.credRecord.format,
            hash: this.credRecord.hash,
          })
          $( `#${this.$el.id}` ).modal('hide')
          this.$emit('update', id)

        } catch(err) {
          window.toastr.error(err.message)
        }
      }
    },

    mounted() {
      $( `#${this.$el.id}` ).on('shown.bs.modal', async () => {
        this.credRecord = defaultCredentials()
        if (this.credId)
          await this.getCredentialRecord()
      })
    }
  }

  function defaultCredentials() {
    return {
      domain: null,
      username: null,
      password: null,
      format: null,
      hash: null
    }
  }
</script>
