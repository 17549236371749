<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-hidden="true")
    div.modal-dialog(role="document")
      div.modal-content
        form(@submit="createQuestionnaire")
          div.modal-header
            h5.modal-title New Questionnaire
            button.close(type="button",data-dismiss="modal",aria-label="Close")
              span(aria-hidden="true") &times;

          div.modal-body
            div
              h6 Create from Template
            div.form-group.mb-2
              label Template #[span.text-danger *]
              select.form-control(v-model="templateRecord", @change="updateForm")
                option(:value="null") Select template...
                option(
                  v-for="questionnaire in questionnaireTemplates.data",
                  :value="questionnaire") 
                  div(v-if="questionnaire.team_id == 1") {{ questionnaire.name + ' (Global Template)' }}
                  div(v-else) {{ questionnaire.name }}
            div.text-center
              h6 OR
            hr
            div.mt-2
              h6 Create New
            div.form-group.mb-2
              label Name
              input.form-control(v-model="record.name")
            div.form-group.mb-2
              label Description
              textarea.form-control(v-model="record.description")
            div.form-group.mb-2
              label Due Date
              datepicker-shards(
                :show-clear="true",
                :input-id="`questionnaire-due-${_uid}`",
                v-model="record.due_date")

            hr
            div.form-group
              label.m-0 Reviewers
              select.form-control(v-model="reviewUser",@change="addReviewer(reviewUser)")
                option(v-for="user in usersWithPermission",:value="user") {{ user.name || user.username_email }}
              div.my-2
                div.small(v-if="!reviewers || reviewers.length === 0")
                  i No reviewers selected yet...
                div.d-flex.align-items-center(v-else)
                  user-avatar.mr-1(
                    v-for="(user, ind) in reviewers",
                    :key="user.id",
                    :id="`questionnaire-reviewer-user-avatar-${user.id}-${_uid}`",
                    :user-id="user.id",
                    @close="reviewers.splice(ind, 1)")
            hr
            div.form-group.mb-2(v-if="isManager")
              div.custom-control.custom-toggle.custom-toggle-sm
                input.custom-control-input(
                  :id="`is-template-${_uid}`"
                  type="checkbox"
                  v-model="record.is_template")
                label.custom-control-label(:for="`is-template-${_uid}`")
                  | Is this a template questionnaire?

          div.modal-footer
            button.btn.btn-secondary(type="button",data-dismiss="modal") Cancel
            button.btn.btn-primary(type="submit" @click="editNow = true") Create & Edit Now
            button.btn.btn-primary(type="submit") Create & Edit Later

</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  // import ApiVendorManagement from '../../factories/ApiVendorManagement'
  import { questionnaireTypes } from '../../constants/questionnaireTypes'

  export default {
    props: {
      teamVendorId: { type: [Number, String] },
      teamVendorReviewId: { type: [Number, String] },
    },

    data() {
      return {
        createdQuestionnaireId: null,
        templateRecord: null,
        record: {
          name: null,
          type: questionnaireTypes.VENDOR_QUESTIONNAIRE,
          description: null,
          due_date: null,
          is_template: false,
        },
        reviewers: [],
        reviewUser: null,
        editNow: false,
      }
    },

    computed: {
      ...mapState({
        isManager: (_, getters) => getters.isManager,

        usersWithPermission: (state) =>
          state.getUsersWithPerrmissionToModule(
            'is_vendor_management_customer'
          ),

        questionnaireTemplates: (state) =>
          state.vendormanagement.questionnaireTemplates || [],
      }),
    },

    methods: {
      updateForm() {
        if (this.templateRecord === null) {
          this.resetQuestionnaireRecord()
        } else {
          // If templateRecord exists, set record off of template
          this.record = {
            ...this.templateRecord,
            id: null,
            type: questionnaireTypes.VENDOR_QUESTIONNAIRE,
            template_id: this.templateRecord.id,
            is_template: false,
            is_published: false,
          }
        }

        this.reviewers = this.record.reviewers
      },

      resetQuestionnaireRecord() {
        return (this.record = {
          name: null,
          type: questionnaireTypes.VENDOR_QUESTIONNAIRE,
          description: null,
          due_date: null,
          is_template: false,
        })
      },

      addReviewer(newUser) {
        this.reviewers = this.reviewers || []
        this.reviewers.push(newUser)
        this.reviewers = [...new Set(this.reviewers)]
        this.reviewUser = null
      },

      async createQuestionnaire(evt) {
        try {
          evt.preventDefault()

          if (!this.record.name)
            return window.toastr.error(
              `Please add a name of the questionnaire before saving.`
            )

          if (this.reviewers && this.reviewers.length > 0)
            this.record.reviewers = this.reviewers

          if (this.teamVendorId) this.record.team_vendor_id = this.teamVendorId

          if (this.teamVendorReviewId)
            this.record.team_vendor_review_id = this.teamVendorReviewId

          const questionnaireId = await this.$store.dispatch(
            'createUpdateQuestionnaire',
            this.record
          )

          window.toastr.success(`Successfully updated questionnaire!`)

          this.$emit('created', questionnaireId)
          this.createdQuestionnaireId = questionnaireId
          this.templateRecord = null
          this.reviewers = []
          $(`#${this.$el.id}`).modal('hide')
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    mounted() {
      $(`#${this.$el.id}`).on('shown.bs.modal', async () => {
        return this.resetQuestionnaireRecord()
      })

      $(`#${this.$el.id}`).on('hidden.bs.modal', () => {
        try {
          if (!this.createdQuestionnaireId) return
          const newPath = `/vendor/management/qbuilder/${this.createdQuestionnaireId}`
          if (!this.editNow || this.$route.path === newPath) return

          this.$router.push(newPath)
        } finally {
          this.createdQuestionnaireId = null
        }
      })
    },
  }
</script>
