<template lang="pug">
  form(@submit="updateEvent")
    div.form-group.d-flex.justify-content-end.side-card-save-btn
      button.btn.btn-primary(type="submit" :disabled="isSaving") {{ (mutableEvent.id) ? 'Save' : 'Create' }}
    div.form-row.d-flex.align-items-center
      div.form-group.mb-3.col-md-7
        label.mb-0(:id="`event-id-info-${_uid}`") Title#[span.text-danger *]
        b-tooltip(:target="`event-id-info-${_uid}`") id: {{ mutableEvent.id }}
        input.form-control(type="text",v-model="mutableEvent.title")
      div.form-group.mb-3.col-md-3
        label Status
        div
          select-dropdown-filter(
            v-model="mutableEvent.status",
            icon="fa fa-shield",
            text="Status",
            :options="calendarStatusOptions")
      div.form-group.mb-3.col-md-2
        label.nowrap Locked? #[i.fal.fa-info-circle(:id="`event-locked-info-${_uid}`")]
        div
          i.fal.fa-2x.clickable(
            :class="(mutableEvent.is_locked) ? 'text-danger fa-lock' : 'text-success fa-lock-open'"
            @click="$set(event, 'is_locked', !mutableEvent.is_locked)")
          b-tooltip(:target="`event-locked-info-${_uid}`")
            | If locked, this event can only be updated by the event creator or other
            | organization administrators.

    div.form-row
      div.form-group.mb-3.col-7
        label.mb-0 Description
        rich-text-editor.border.rounded(:options="{ theme: 'bubble', placeholder: '' }",v-model="mutableEvent.description")

      div.form-group.mb-3.col-5
          label.mb-0 Event Color
          select-dropdown-filter.ml-1.my-1(
            v-if="recentColors.length > 0",
            icon="fa fa-eye-dropper",
            text="Recent Color",
            :dropdown-right="true",
            :options="recentColors.map((c, i) => ({ text: `${i}. ${c}`, value: c, color: c }))",
            @input="setRecentColor")
          color-picker(
            v-model="mutableEvent.background_color",
            @isDark="setTextColor")
          div.mt-2(v-if="mutableEvent.background_color")
            pill-inline(
              :background="mutableEvent.background_color",
              :text="truncateString(mutableEvent.title || 'Your event will look like this...', 25)",
              :show-close="false")

    hr

    div.row.small-gutters
      div.form-group.mb-3(:class="showReminderOptions ? 'col-md-4' : 'col-md-6'")
        label.mb-0 Owner
        v-select(
          v-model="mutableEvent.owner"
          :options="teamAndInternalUsers"
          :reduce="u => u.id"
          :get-option-label="u => u.name || u.username_email")
        div.mt-3.small(v-if="!mutableEvent.owner")
          i No owner selected yet...
        div.mt-1(v-else)
          user-avatar.mr-1.mb-1(
            :key="mutableEvent.owner",
            :id="`event-create-owner-user-avatar-${mutableEvent.owner}-${_uid}`",
            :user-id="mutableEvent.owner",
            @close="mutableEvent.owner = null")
      div.form-group.mb-3(:class="showReminderOptions ? 'col-md-4' : 'col-md-6'")
        label.mb-0 Collaborators
        v-select(
          v-model="assignmentUser"
          :options="teamAndInternalUsers"
          :get-option-label="u => u.name || u.username_email"
          @input="addAssignee")
        div.mt-3.small(v-if="!mutableEvent.assignments || mutableEvent.assignments.length === 0")
          i No collaborators selected yet...
        div.mt-1(v-else)
          user-avatar.mr-1.mb-1(
            v-for="(user, ind) in mutableEvent.assignments",
            :key="user.id",
            :id="`event-create-assignee-user-avatar-${user.id}-${_uid}`",
            :user-id="user.id",
            @close="mutableEvent.assignments.splice(ind, 1)")
      div.form-group.mb-3.col-4(v-if="typeof mutableEvent.is_reminder_active === 'boolean' && showReminderOptions")
        label.mb-0 Event Reminders
        div.card.card-small.no-shadow.border
          div.card-body.p-2
            div.custom-control.custom-checkbox-sm.custom-checkbox
              input.custom-control-input(
                type="checkbox"
                :id="`event-create-reminder-${_uid}`"
                :name="`event-create-reminder-${_uid}`"
                @change="setIsReminderActive"
                v-model="mutableEvent.is_reminder_active")
              label.custom-control-label(:for="`event-create-reminder-${_uid}`")
                | Get reminders?
            div.mt-1(v-if="mutableEvent.is_reminder_active")
              select.form-control(:value="getReminderMinutes()" @change="setReminderMinutes")
                option(v-for="opt in reminderDayOptions",:value="opt.value") {{ opt.text }}
      
    hr.mt-0

    div.row.small-gutters
      div.col-md-12
        div.form-row 
          div.form-group.mb-3.col-4
            label.m-0 Start Date#[span.text-danger *]
            datepicker-shards(
              :show-clear="true",
              :input-id="`event-start-${_uid}`",
              v-model="mutableEvent.start_date")
          div.form-group.mb-3.col-4(v-if="!mutableEvent.is_recurring && mutableEvent.all_day")
            label.m-0 End Date
            datepicker-shards(
              :show-clear="true",
              :input-id="`event-end-${_uid}`",
              v-model="mutableEvent.end_date")
          div.form-group.mb-3.col-2
            label.ml-1 Add Time
            div.custom-control.custom-toggle-sm.custom-toggle.ml-1(:id="`all-day-container-${_uid}`")
              input.custom-control-input(
                type="checkbox",
                :id="`all-day-toggle-${_uid}`",
                :name="`all-day-toggle-${_uid}`",
                :true-value="false",
                :false-value="true",
                v-model="mutableEvent.all_day")
              label.custom-control-label(:for="`all-day-toggle-${_uid}`")
          div.form-group.mb-3.col-3(v-if="!mutableEvent.all_day")
            label.m-0 Start Time
            b-form-timepicker(
              :id="`event-start-time-${_uid}`",
              v-model="startTime")
          div.form-group.mb-3.col-3(v-if="!mutableEvent.all_day")
            label.m-0 End Time
            b-form-timepicker(
              :id="`event-end-time-${_uid}`",
              v-model="endTime")
      div.col-md-12
        div.form-row
          div.form-group.ml-1.mb-3.col-12
            div.custom-control.custom-toggle-sm.custom-toggle(:id="`recurring-container-${_uid}`")
              input.custom-control-input(
                type="checkbox",
                :id="`recurring-toggle-${_uid}`",
                :name="`recurring-toggle-${_uid}`",
                v-model="mutableEvent.is_recurring")
              label.custom-control-label(:for="`recurring-toggle-${_uid}`") Recurring Event

      div.col-md-12(v-if="mutableEvent.is_recurring")
        div.form-row
          div.form-group.mb-3.col-12
            calendar-recurring-picker(v-model="mutableEvent.recurring_options",:date="mutableEvent.start_date")

    hr.mt-0

    entity-tags-list(:tags="mutableEvent.tags",@addTag="addTag",@removeTag="removeTag")
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import Utilities from '@risk3sixty/utilities'
  import StringHelpers from '../../factories/StringHelpers'

  const moment = Utilities.Dates.moment

  export default {
    props: {
      event: { type: Object, default: () => ({}) },
    },

    watch: {
      event: {
        handler(newEvent) {
          this.setEvent(newEvent)
        },
        immediate: true,
        deep: true,
      },
    },

    data() {
      return {
        assignmentUser: {},
        mutableEvent: {},
        isSaving: false,

        reminderDayOptions: [
          { value: 1440, text: '1 day before' },
          { value: 4320, text: '3 days before' },
          { value: 10080, text: '1 week before' },
          { value: 43200, text: '1 month before' },
        ],
      }
    },

    computed: {
      ...mapState({
        calendarStatusOptions(state) {
          return state.compliance.calendar.statuses.map((s) => ({
            text: s.text,
            color: this.variantColors[s.variant],
            value: s.value,
          }))
        },

        primaryColor: (state) => state.dashboard.colors.primary,
        recentColors: (state) => state.compliance.calendar.recentColors,
        teamAndInternalUsers: (state) => state.team.teamAndInternalUsers,
        teamUsers: (state) => state.team.users,
        user: (state) => state.auth.user,

        variantColors: (state) => state.dashboard.variantColors(),
      }),

      startTime: {
        get() {
          const date = new Date(this.mutableEvent.start_date)
          return `${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()}`
        },

        set(val) {
          const [hours, mins, secs] = val.split(':')
          const date = new Date(this.mutableEvent.start_date)
          date.setUTCHours(hours)
          date.setUTCMinutes(mins)
          date.setUTCSeconds(secs)
          this.mutableEvent.start_date = date.toISOString()
        },
      },

      endTime: {
        get() {
          const date = new Date(
            this.mutableEvent.end_date || this.mutableEvent.start_date
          )
          return `${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()}`
        },

        set(val) {
          const [hours, mins, secs] = val.split(':')
          const date = new Date(
            this.mutableEvent.end_date || this.mutableEvent.start_date
          )
          date.setUTCHours(hours)
          date.setUTCMinutes(mins)
          date.setUTCSeconds(secs)
          this.mutableEvent.end_date = date.toISOString()
        },
      },

      showReminderOptions() {
        const currentUserAssigned =
          (this.mutableEvent.assignments || []).find(
            (u) => u.id.toString() === this.user.id
          ) || {}
        return Object.keys(currentUserAssigned).length > 0
      },
    },

    methods: {
      truncateString: StringHelpers.truncateString,

      setRecentColor(color) {
        this.mutableEvent.background_color = color
      },

      setIsReminderActive(clickEvent) {
        const currentUserAssigned =
          (this.mutableEvent.assignments || []).find(
            (u) => u.id.toString() === this.user.id
          ) || {}

        currentUserAssigned.is_reminder_active = clickEvent.target.checked
      },

      getReminderMinutes() {
        const currentUserAssigned =
          (this.mutableEvent.assignments || []).find(
            (u) => u.id.toString() === this.user.id
          ) || {}
        return currentUserAssigned.reminder_minutes_before_event
      },

      setReminderMinutes(clickEvent) {
        const currentUserAssigned =
          (this.mutableEvent.assignments || []).find(
            (u) => u.id.toString() === this.user.id
          ) || {}
        currentUserAssigned.reminder_minutes_before_event =
          clickEvent.target.value
      },

      resetUpdateModal() {
        // This is here due to this issue
        // https://stackoverflow.com/questions/10636667/bootstrap-modal-appearing-under-background
        $(`#confirm-delete-calendar-event`).appendTo('body')
      },

      setTextColor(isDarkBg) {
        this.$set(
          this.mutableEvent,
          'text_color',
          isDarkBg ? '#ffffff' : '#000000'
        )
      },

      setEvent(newEvent) {
        const def = defaultEvent.call(this)
        const evCopy = Object.assign(this.mutableEvent)
        this.mutableEvent = Object.assign(newEvent)

        Object.keys({ ...this.mutableEvent, ...evCopy, ...def }).forEach(
          (key) => {
            let val = this.mutableEvent[key]
            if (
              evCopy.id &&
              newEvent.id &&
              evCopy.id === newEvent.id &&
              ['title', 'description', 'is_locked'].includes(key)
            )
              val = evCopy[key] || val || def[key]
            this.$set(this.mutableEvent, key, val)
          }
        )

        // Redundacy here, need to find more simple solution? Move to state?
        this.$set(
          this.mutableEvent,
          'text_color',
          this.mutableEvent.text_color || '#ffffff'
        )
        this.$set(
          this.mutableEvent,
          'background_color',
          this.mutableEvent.background_color || this.primaryColor
        )

        this.$set(
          this.mutableEvent,
          'start_date',
          this.mutableEvent.start_date ??
            (this.event.start && !!this.event.start
              ? moment(this.event.start).toISOString()
              : null)
        )
        this.$set(
          this.mutableEvent,
          'recurring_options',
          this.mutableEvent.recurring_options || {}
        )
        this.$set(
          this.mutableEvent,
          'all_day',
          this.mutableEvent.all_day || false
        )

        const currentUserAssigned =
          (this.mutableEvent.assignments || []).find(
            (u) => u.id.toString() === this.user.id
          ) || {}
        this.$set(
          this.mutableEvent,
          'is_reminder_active',
          currentUserAssigned.is_reminder_active || false
        )
        this.$set(
          this.mutableEvent,
          'reminder_minutes_before_event',
          currentUserAssigned.reminder_minutes_before_event
        )
      },

      addAssignee() {
        const user = this.assignmentUser
        this.assignmentUser = {}

        // TODO: move the default state of assignments into vuex
        this.$set(
          this.mutableEvent,
          'assignments',
          this.mutableEvent.assignments || []
        )

        if (this.mutableEvent.assignments.find((a) => a.id == user.id)) return

        this.mutableEvent.assignments.push(user)
      },

      addTag(tag) {
        // TODO: move the default state of tags into vuex
        this.$set(this.mutableEvent, 'tags', this.mutableEvent.tags || [])
        if (this.mutableEvent.tags.map((t) => t.id).includes(tag.id)) return

        this.mutableEvent.tags.push(tag)
      },

      removeTag(ind) {
        this.mutableEvent.tags.splice(ind, 1)
      },

      async updateEvent(evt) {
        try {
          evt.preventDefault()

          if (!this.mutableEvent.title)
            return window.toastr.error(`Please enter a title for the event.`)

          if (!this.mutableEvent.start_date)
            return window.toastr.error(
              `Please enter a start date for the event.`
            )

          if (this.mutableEvent.all_day) {
            this.mutableEvent.start_date = moment
              .utc(this.mutableEvent.start_date)
              .startOf('day')
              .toDate()

            this.mutableEvent.end_date = this.mutableEvent.end_date
              ? moment.utc(this.mutableEvent.end_date).startOf('day').toDate()
              : moment
                  .utc(this.mutableEvent.start_date)
                  .add(1, 'day')
                  .startOf('day')
                  .toDate()

            const sameDateTime =
              new Date(this.mutableEvent.start_date).getTime() ===
              new Date(this.mutableEvent.end_date).getTime()
            const endBeforeStart =
              new Date(this.mutableEvent.start_date).getTime() >
              new Date(this.mutableEvent.end_date).getTime()

            if (
              this.mutableEvent.is_recurring ||
              sameDateTime ||
              endBeforeStart
            )
              this.mutableEvent.end_date = moment
                .utc(this.mutableEvent.start_date)
                .add(1, 'day')
                .startOf('day')
                .toDate()
          } else {
            if (!this.mutableEvent.end_date)
              this.mutableEvent.end_date = moment
                .utc(this.mutableEvent.start_date)
                .add(1, 'hour')
                .toDate()

            const sameDate =
              new Date(this.mutableEvent.start_date).toDateString() ===
              new Date(this.mutableEvent.end_date).toDateString()

            if (!sameDate) {
              const startMonth = new Date(
                this.mutableEvent.start_date
              ).getUTCMonth()
              const startDate = new Date(
                this.mutableEvent.start_date
              ).getUTCDate()
              const endDate = new Date(this.mutableEvent.end_date)
              endDate.setUTCMonth(startMonth)
              endDate.setUTCDate(startDate)
              this.mutableEvent.end_date = endDate.toISOString()
            }
          }

          this.isSaving = true
          const newId = await this.$store.dispatch(
            'createUpdateCalendarEvent',
            this.mutableEvent
          )
          if (this.mutableEvent.linkType) {
            await this.$store.dispatch('mapCalendarEvent', {
              eventId: newId,
              type: this.mutableEvent.linkType,
              id: this.mutableEvent.linkRecId,
            })
          }

          window.toastr.success(`Successfully updated your event!`)

          this.$emit('input', { ...this.mutableEvent, id: newId })
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.isSaving = false
        }
      },
    },

    mounted() {
      this.resetUpdateModal()
    },

    created() {
      $(`#confirm-delete-calendar-event`).remove()
      this.resetUpdateModal()
    },

    beforeDestroy() {
      // See comments in this.resetUpdateModal as to why this needs
      // to be here.
      $(`#confirm-delete-calendar-event`).remove()
    },
  }

  function defaultEvent() {
    return {
      id: null,
      title: null,
      description: null,
      start: this.event.start,
      end: null,
      due_date: null,
      status: null,
      background_color: this.primaryColor,
      text_color: '#ffffff',
      is_recurring: null,
      is_reminder_active: false,
      recurring_options: {},
      owner: this.user.id,
      all_day: true,
    }
  }
</script>

<style lang="scss" scoped>
  .fixed-calendar-buttons {
    position: -webkit-sticky;
    position: sticky;
    bottom: -8px;
    z-index: 998;
  }

  .border-top-strong {
    border-top: 2px solid rgba(212, 214, 217, 0.71) !important;
  }
</style>
