<template lang="pug">
  div.card.card-small
    div.card-header.border-bottom
      div.small.d-flex.justify-content-between
        strong(style="color: #1c567d") {{ (contentTypes.find(type => {return type.id == r3sContent.type}) || {}).text  || 'Fun Facts'}} 
        i.clickable.fal.fa-plus-circle(v-if="userCanAddContent", data-toggle="modal", data-target="#add-r3s-content-modal")
    loader(v-if="isLoadingLocal")
    div.card-body.d-flex.flex-row.align-items-center.justify-content-center(v-else)
      div.col-4.d-flex.justify-content-center
        img.w-100.rounded(v-if="r3sContent.img", :src="`/file/s3/${r3sContent.img}`")
        i.fal.fa-lightbulb-on.fa-lg.fa-6x(v-else, style="color: #f39c12")
      div.col-8
        h6 {{ r3sContent.title || 'No fun fact for today..' }}
        div {{ r3sContent.description || 'Check back tomorrow for more fun facts!'}}
    div.card-footer.border-top.rounded(v-if="!isLoadingLocal")
      div.d-flex.align-items-center.justify-content-center(v-if="r3sContent.link")
        a.link(:href="r3sContent.link",target="_blank",rel="noopener noreferrer") 
          pill-inline.w-100(
            style="border-radius: 20px"
            :is-hollow="true",
            :show-close="false",
            text="Read More")

    add-r3s-content-modal#add-r3s-content-modal
</template>

<script>
  import { mapState } from 'vuex'
  import ApiGlobal from '../../../factories/ApiGlobal'
  import StringHelpers from '../../../factories/StringHelpers'
  import TimeHelpers from '../../../factories/TimeHelpers'
  import AddR3sContentModal from '../../reusable/AddR3sContentModal'

  export default {
    data() {
      return {
        isLoadingLocal: true,
        r3sContent: {},
        contentTypes: [],
        r3sInternalUsers: [],
      }
    },

    computed: {
      ...mapState({
        currentUser: (state) => state.auth.user,
      }),

      userCanAddContent() {
        return (this.r3sInternalUsers || []).find(
          (u) => u.id == this.currentUser.id
        )
      },
    },

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,
      titleCase: StringHelpers.titleCase,

      async getR3sContent() {
        try {
          const { types } = await ApiGlobal.getR3sContentTypes()
          this.contentTypes = types || []
          const { content, internalUsers } = await ApiGlobal.getR3sContent()
          this.r3sContent = content || {}
          this.r3sInternalUsers = internalUsers || []
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.isLoadingLocal = false
        }
      },
    },

    async created() {
      await this.getR3sContent()
    },

    components: {
      AddR3sContentModal,
    },
  }
</script>
