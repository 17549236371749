<template lang="pug">
  div
    div.modal.fade(
      id="bulk-upload-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="add-question-modal-label"
      aria-hidden="true")
        div.modal-dialog.modal-lg(role="document")
          div.modal-content
            div.modal-header
              h5#add-question-modal-label.modal-title Bulk Upload Questions
              button.close(type="button",data-dismiss="modal",aria-label="Close")
                span(aria-hidden="true") &times;
            div.modal-body
              div.form-group
                label Select Assessment or Questionnaire to Import Questions (or 
                  a(
                    data-toggle="modal"
                    data-target="#create-questionnaire-bulk-upload-modal") Create New)
                v-select(
                  v-model="selectedQuestionnaireId"
                  :options="allQuestionnaires"
                  :reduce="q => q.id"
                  :get-option-label="q => q.name")
              div.text-center
                file-uploader.d-none(
                  acceptedFileTypes="text/csv, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xlsx",
                  :clickable="`#upload-bulk-question-csv-${_uid}`"
                  @added="uploadedCsv"
                  @processing="uploadingCsv")
                div
                  | #[a(:href="templateDownloadUrl",target="_blank",rel="noopener noreferrer") Click here]
                  | to download a template file for bulk uploading questions.
                div.mt-4(v-show="!bulkUploadCsvFilename")
                  button.btn.btn-primary(:id="`upload-bulk-question-csv-${_uid}`")
                    | Upload file #[loader-inline.ml-2(v-if="bulkUploadProcessing")]
                div.alert.alert-success.mt-4.mb-0(v-if="!!bulkUploadCsvFilename")
                  div Successfully uploaded file to add questions from
                  div.mt-2 {{ bulkUploadCsvFilename }}
            div.modal-footer
              button.btn.btn-secondary(type="button",data-dismiss="modal") Cancel
              button.btn.btn-primary(
                v-if="!bulkUploadProcessing && bulkUploadCsvFilename && selectedQuestionnaireId"
                type="button"
                @click="saveItems()") Import Questions

    create-questionnaire-modal#create-questionnaire-bulk-upload-modal(
      @created="questionnaireCreated")
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import ApiFiles from '../../factories/ApiFiles'
  import ApiVendorManagement from '../../factories/ApiVendorManagement'

  export default {
    props: {
      questionnaireId: { type: [Number, String], default: null },
      isSelfAssessments: { type: Boolean, default: false },
    },

    data() {
      return defaultData()
    },

    computed: {
      ...mapState({
        isInternalAdmin: (_, getters) => getters.isInternalAdmin,
        isManager: (_, getters) => getters.isManager,

        currentTeamId: (state) => state.session.current_team.id,

        allSelfAssessments: (state) =>
          state.selfassessments.allSelfAssessments.data,
        allSelfAssessmentTemplates: (state) =>
          state.selfassessments.allSelfAssessmentTemplates.data,
        questionnaires: (state) => state.vendormanagement.questionnaires.data,
        questionnaireTemplates: (state) =>
          state.vendormanagement.questionnaireTemplates.data,
      }),

      allQuestionnaires() {
        let listKeys = ['questionnaires', 'questionnaireTemplates']
        if (this.isSelfAssessments) {
          listKeys = ['allSelfAssessments', 'allSelfAssessmentTemplates']
        }

        const allQs = this[listKeys[0]].concat(
          this[listKeys[1]].map((t) => {
            return {
              ...t,
              name: `${t.name} (Template)`,
            }
          })
        )

        return allQs.filter((q) => {
          return q.team_id === this.currentTeamId
        })
      },

      templateDownloadUrl() {
        return 'https://s3.amazonaws.com/public.risk3sixty.com/assets/bulk_questionnaire_question_upload_template.csv'
      },
    },

    methods: {
      uploadingCsv() {
        this.bulkUploadProcessing = true
      },

      uploadedCsv([, response]) {
        this.bulkUploadCsvFilename = response.filename
        this.bulkUploadProcessing = false
      },

      questionnaireCreated(id) {
        this.selectedQuestionnaireId = id
      },

      async saveItems() {
        try {
          const { files } = await ApiFiles.saveFile(
            { name: this.bulkUploadCsvFilename },
            'private'
          )
          const [file] = files
          await ApiVendorManagement.bulkUploadQuestions(
            this.selectedQuestionnaireId,
            file.id
          )
          window.toastr.success(
            `Successfully began adding your questions. Please be patient as this could take a little time to complete.`
          )

          $(`#bulk-upload-modal`).modal('hide')
          Object.assign(this.$data, defaultData())
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    created() {
      this.selectedQuestionnaireId = this.questionnaireId
    },

    mounted() {
      // This is here due to this issue
      // https://stackoverflow.com/questions/10636667/bootstrap-modal-appearing-under-background
      $(`#bulk-upload-modal`).appendTo('body')
      $(`#create-questionnaire-bulk-upload-modal`).appendTo('body')

      $(`#bulk-upload-modal`).on('shown.bs.modal', async () => {
        // https://stackoverflow.com/questions/28077066/bootstrap-modal-issue-scrolling-gets-disabled
        $('body').addClass('modal-open')
        await this.$store.dispatch('getAllQuestionnaireInfo', true)
        this.selectedQuestionnaireId = this.questionnaireId
      })

      $(`#bulk-upload-modal`).on('hidden.bs.modal', () => {
        this.bulkUploadCsvFilename = null
      })
    },

    beforeDestroy() {
      // See comments in mounted as to why this needs
      // to be here.
      $(`#bulk-upload-modal`).remove()
      $(`#create-questionnaire-bulk-upload-modal`).remove()
    },
  }

  function defaultData() {
    return {
      bulkUploadCsvFilename: null,
      bulkUploadProcessing: false,
      selectedQuestionnaireId: null,
    }
  }
</script>
