<template lang="pug">
  div
    div(
      v-if="!isEditing"
      @click="canEdit ? isEditing = !isEditing : ''" :class="canEdit ? 'editable' : ''")
        div(v-if="!mutableVal")
          i {{canEdit ? 'Click to add value...' : ''}}
        div(v-else v-html="value")
    div(v-else,style="min-width: 150px")
      rich-text-editor.border.rounded.bg-white(
        v-model="mutableVal"
        :options="{ theme: 'bubble' }")
      div.mt-1.d-flex.justify-content-center
        button.btn.btn-secondary.btn-vsm.mr-1(@click="update(value)") Cancel
        button.btn.btn-success.btn-vsm(@click="update(tmpVal)") Save
</template>

<script>
  export default {
    props: {
      value: { type: String, default: null },
      canEdit: { type: Boolean, default: true },
    },

    data() {
      return {
        isEditing: false,
        isBlurred: false,
        tmpVal: this.value,
      }
    },

    computed: {
      mutableVal: {
        get() {
          return this.value
        },

        set(t) {
          if (t === this.value) return
          if (!this.isBlurred) return (this.tmpVal = t)

          this.$emit('input', this.tmpVal)
          this.isBlurred = false
        },
      },
    },

    methods: {
      update(v) {
        this.isBlurred = true
        this.mutableVal = v || this.tmpVal
        this.isEditing = false
      },
    },
  }
</script>
