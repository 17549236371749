<template lang="pug">
div.form-group.mb-0
  label.mb-0(v-if="label",:for="inputId") {{ label }}
  input.form-control(
    ref="Input"
    :disabled="!updatable"
    type="date",
    :id="inputId",
    :placeholder="placeholder",
    :class="getSizeClass + ' ' + additionalClasses"
    v-model="inputDate",
    @input="dateChanged(inputDate)")

</template>

<script>
  import $ from 'jquery'
  import moment from 'moment'

  export default {
    props: {
      valueKey: { type: String, default: null },
      label: { type: String, default: null },
      placeholder: { type: String, default: 'Click here to select date...' },
      value: { type: [Date, String] },
      minDate: { type: Date, default: null },
      maxDate: { type: Date, default: null },
      size: { type: String, default: null },
      showClear: { type: Boolean, default: false },
      additionalClasses: { type: String, default: null },
      updatable: { type: Boolean, default: true },
    },

    watch: {
      value: {
        immediate: true,
        handler(newVal) {
          if (!newVal) return (this.date = this.inputDate = null)

          this.date = this.inputDate = moment.utc(newVal).format('YYYY-MM-DD')
        },
      },
    },

    data() {
      return {
        date: null,
        inputDate: null, // used for directly inputting a date into the text box
      }
    },

    computed: {
      inputId() {
        return `datepicker-shards-${this._uid}`
      },

      getSizeClass() {
        return this.size ? `form-control-${this.size}` : ''
      },
    },

    methods: {
      /** Referenced by parent */
      focus() {
        this.$refs.Input.focus()
      },

      dateChanged(date) {
        if (date == 'Invalid date') date = null
        if (!date) {
          this.$emit('input', null)
          return
        }

        // Check if date has changed to prevent recursive loop
        if (this.date === moment.utc(date).format('YYYY-MM-DD')) return

        // If for some reason (i.e. through direct input) the date
        // isn't a valid date, clear it here
        if (date && !moment(date).utc(true).isValid()) {
          window.toastr.error(
            `Please only enter a date in the format of YYYY-MM-DD.`
          )
          this.$emit('input', this.value)
          this.$emit('changedWithKey', this.value, this.valueKey)
          return
        }

        this.date = date
        this.inputDate = moment(date).utc(true)
        const sanitizedDate = this.date
          ? this.inputDate.format('YYYY-MM-DD')
          : null
        this.$emit('input', sanitizedDate)
        this.$emit('changedWithKey', sanitizedDate, this.valueKey)
      },
    },

    mounted() {
      const pickerElement = $(`#${$.escapeSelector(this.inputId)}`)
      if (pickerElement.length === 0) return
      pickerElement
        .datepicker({
          autoclose: true,
          startDate: this.minDate,
          endDate: this.maxDate,
          clearBtn: this.showClear,
          format: 'yyyy-mm-dd',
          todayHighlight: true,
          zIndexOffset: 1020,
        })
        .on('changeDate', (evt) => this.dateChanged(evt.date))
    },
  }
</script>
<style lang="scss" scoped>
  input::-webkit-calendar-picker-indicator {
    display: none;
  }

  input[type='date']::-webkit-input-placeholder {
    visibility: hidden !important;
  }
</style>
