<template lang="pug">
  div.attachment-item.px-3(:class="attachment.internal_only ? 'internal' : ''")
    div.d-flex.align-items-center(v-if="size === 'sm'")
      user-avatar.mr-1(
        :user-id="attachment.user_id"
        :should-show-close="false"
        max-height="25px")
      div
        div.d-flex.align-items-center
          a.mr-1(@click="previewAttachment()" data-toggle="modal" :data-target="`#preview-attachment-modal-${_uid}`")
            i.fal.fa-eye.clickable
          a.mr-1(@click="downloadAttachment()")
            i.fal.fa-download
          div(:id="`request-file-${_uid}-${attachment.id}`")
            a(@click="$emit('select', attachment.id)")
              | {{ truncateString(decodeURIComponent(attachment.external_name || attachment.name), 40) }}
        div.small.text-gray
          span #[b.mr-1 Uploaded:] {{ getFormattedDate(attachment.created_at, 'MMM D, YYYY h:mm a') }}
          //- span.ml-2(v-if="attachment.mapped_at") #[b.mr-1 Mapped:] {{ getFormattedDate(attachment.mapped_at, 'MMM D, YYYY h:mm a') }}
      b-tooltip(:target="`request-file-${_uid}-${attachment.id}`")
        | {{ decodeURIComponent(attachment.external_name || attachment.name) }}
    div.row.no-gutters(v-else class="py-3")
      div.col-8
        div.d-flex.align-items-start
          user-avatar.mr-1(
            :id="`attachment-${index || 0}-${attachment.user_id}`",
            height="25px",
            max-height="25",
            :should-show-close="false",
            :user-id="attachment.user_id")
          h6.m-0 {{ $store.state.getUserName(attachment.user_id) }}
          //- 8/30/22: Removed blockchain transaction (txn) functionality (causing performance issues)
          //- div.clickable.ml-1(
          //-   v-if="attachment.blockchain_txns && attachment.blockchain_txns.length > 0"
          //-   :id="`attachment-blockchain-${_uid}`")
          //-     img.max-15.img-fluid(:src="stellarLogoSrc")
          //-     blockchain-txn-popover(
          //-       v-for="txn in attachment.blockchain_txns",
          //-       :key="txn.id",
          //-       :target="`attachment-blockchain-${_uid}`",
          //-       :txn="txn")
        div.overflow-ellipses.no-hover.d-flex.align-items-center.w-100.mt-2
          a(:id="`att-item-name-${_uid}`",@click="downloadAttachment()")
            | #[i.fal(:class="getFileIconClass(attachment)")]
            | {{ decodeURIComponent(attachment.external_name || attachment.name) }}
          b-tooltip(:target="`att-item-name-${_uid}`")
            | {{ decodeURIComponent(attachment.description || attachment.external_name || attachment.name) }}
      div.col-4.d-flex.align-items-center
        div.ml-auto
          div.small.d-flex.justify-content-end #[b.mr-1 Uploaded:] {{ getFormattedDate(attachment.created_at, 'MMM D, YYYY h:mm a') }}
          //- div.small.d-flex.justify-content-end(v-if="attachment.mapped_at") #[b.mr-1 Mapped:] {{ getFormattedDate(attachment.mapped_at, 'MMM D, YYYY h:mm a') }}
          div.d-flex.align-items-center.justify-content-end
            a(v-if="allowEdit", data-toggle="modal", :data-target="`#attachment-item-file-update-modal-${_uid}`")
              i.fal.fa-edit.clickable(:id="`edit-att-${_uid}`")
               b-tooltip(:target="`edit-att-${_uid}`")
                | Edit
            a.ml-2(v-if="allowInternal && isInternalUser", @click="toggleInternalOnly()")
              i.fal.fa-user-lock.clickable(:id="`internal-att-${_uid}`", :class="attachment.internal_only ? 'text-danger' : ''")
              b-tooltip(:target="`internal-att-${_uid}`")
                | Internal Only {{ attachment.internal_only ? `(On)` : `(Off)`}}
            a.ml-2(@click="previewAttachment()", data-toggle="modal", :data-target="`#preview-attachment-modal-${_uid}`")
              i.fal.fa-eye.clickable
            a.ml-2(@click="downloadAttachment()")
              i.fal.fa-download.text-primary
            a.ml-2(v-if="allowDelete")
              i.fal.fa-times.text-danger(:id="`delete-att-${_uid}`")
              confirmation-popover(
                :target="`delete-att-${_uid}`",
                header="Delete Attachment?",
                :text="`Are you sure you want to delete ${decodeURIComponent(attachment.external_name || attachment.name)}`",
                @confirm="deleteAttachmentAction()")

    preview-attachment-modal(
      :id="`preview-attachment-modal-${_uid}`",
      :file-id="attachment.file_upload_id || attachment.id",
      :file-name="attachment.name",
      :title="attachment.external_name || attachment.name")
    
    file-update-modal(:id="`attachment-item-file-update-modal-${_uid}`", :file="attachment", @updated="update", @newVersion="linkNewFileVersion")
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import DomHelpers from '../../factories/DomHelpers'
  import StringHelpers from '../../factories/StringHelpers'
  import TimeHelpers from '../../factories/TimeHelpers'
  import ApiFiles from '../../factories/ApiFiles'
  import { fileExtensionIconClasses } from '../../factories/Utilities'

  export default {
    props: {
      // basically a `file_uploads` record
      attachment: { type: Object },
      index: { type: Number, default: null },

      allowDelete: { type: Boolean, default: true },
      allowInternal: { type: Boolean, default: false },
      allowEdit: { type: Boolean, default: false },

      // if provided, don't actually download, just emit an event to parent
      emitOnly: { type: Boolean, default: false },

      size: { type: String, default: 'normal' },
    },

    watch: {
      'attachment.name'() {
        this.previewingObj = false
      },
    },

    computed: {
      ...mapState({
        isInternalUser: (_, getters) => getters.isInternalUser,
        stellarLogoSrc: (_, getters) => getters.stellarLogoSrc,
      }),

      uid() {
        return typeof this.index === 'number' ? this.index : this.attachment.id
      },
    },

    data() {
      return {
        previewingObj: false,
      }
    },

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,
      truncateString: StringHelpers.truncateString,

      resetUpdateModal() {
        // This is here due to this issue
        // https://stackoverflow.com/questions/10636667/bootstrap-modal-appearing-under-background
        $(`#preview-attachment-modal-${this._uid}`).appendTo('body')
        $(`#attachment-item-file-update-modal-${this._uid}`).appendTo('body')
      },

      update() {
        this.$emit('update', this.attachment.id)
      },

      async linkNewFileVersion(newFile) {
        try {
          await this.deleteAttachmentAction()
          this.$emit('newVersion', newFile)
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          $(`#attachment-item-file-update-modal-${this._uid}`).modal('hide')
        }
      },

      async toggleInternalOnly() {
        try {
          await ApiFiles.update({
            ...this.attachment,
            id: this.attachment.file_upload_id || this.attachment.id,
            internal_only: !this.attachment.internal_only,
          })
          this.update()
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      previewAttachment() {
        this.previewingObj = true
        this.$emit('preview', this.attachment.id)
      },

      downloadAttachment() {
        this.$emit('download', this.attachment.id)
        if (this.emitOnly) return

        DomHelpers.downloadUri(
          `/file/s3/${this.attachment.name}`,
          decodeURIComponent(
            this.attachment.external_name || this.attachment.name
          )
        )
      },

      deleteAttachmentAction() {
        this.$emit('delete', this.attachment.id)
      },

      getFileIconClass(file) {
        const fileSplit = (file.external_name || file.name)
          .toLowerCase()
          .split('.')
        const extension = fileSplit[fileSplit.length - 1]
        return (
          fileExtensionIconClasses[extension || 'default'] ||
          fileExtensionIconClasses['default']
        )
      },
    },

    mounted() {
      this.resetUpdateModal()
    },

    beforeDestroy() {
      // See comments in this.resetUpdateModal as to why this needs
      // to be here.
      $(`#preview-attachment-modal-${this._uid}`).remove()
      $(`#attachment-item-file-update-modal-${this._uid}`).remove()
    },
  }
</script>

<style scoped>
  .modal {
    z-index: 1073;
  }
</style>
