<template lang="pug">
  form.overflow-ellipses(@submit="updateValue")
    a(@click="isEditing = !isEditing",v-if="!isEditing") {{ valCopy || defaultText }}
    input.form-control.form-control-sm(v-else,type="text",v-model="valCopy")
</template>

<script>
  import ApiRemote from '../../../factories/ApiRemote'

  export default {
    props: {
      remoteId: { type: [ Number, String ] },
      hostId: { type: [ Number, String ], default: null },
      val: { type: [ Number, String ] },
      col: { type: String },
      model: { type: String, default: 'remote_node_config' },
      defaultText: { type: String, default: 'N/A' }
    },

    watch: {
      val(newVal) {
        this.valCopy = newVal
      }
    },

    data() {
      return {
        isEditing: false,
        valCopy: this.val
      }
    },

    methods: {
      async updateValue(evt) {
        try {
          evt.preventDefault()

          const updateData = {
            id: this.remoteId,
            host_id: this.hostId,
            model: this.model,
            col: this.col,
            val: this.valCopy
          }
          await ApiRemote.updateParam(updateData)
          this.$emit('update', updateData)

          this.isEditing = !this.isEditing

        } catch(err) {
          window.toastr.error(err.message)
        }
      }
    }
  }
</script>
