<template lang="pug">
  div(:class="isSelfAssessment ? 'd-flex flex-column align-items-center' : ''")
    div.data-header-circle.d-flex.align-items-center.justify-content-center(:class="isVertical && 'flex-column'")
      div.card.card-small.no-shadow.justify-content-center.align-items-center.border.border-secondary(:class="['size-' + size].concat(extraCardClasses).concat(isSelected ? 'selected' : '')")
        div.d-flex.justify-content-center.align-items-center
          div(:class="textClass") {{ value }}
      div.nowrap.header(v-if="header && !isSelfAssessment",:class="(isVertical && 'mt-1') || 'ml-1'")
        strong {{ titleCase(header, true) }}
        div.small(v-if="subValue") {{ subValue }}
    div.d-flex.flex-column.justify-content-center(v-if="isSelfAssessment")
      div.mt-2
        strong {{ titleCase(header, true) }}
        div.small(v-if="subValue") {{ subValue }}

</template>

<script>
  import StringHelpers from '../../factories/StringHelpers'

  export default {
    props: {
      header: { type: String },
      value: { type: [Number, String] },
      subValue: { type: String, default: null },
      size: { type: [Number, String], default: 35 },
      cardClass: { type: [Array, String], default: null },
      textClass: { type: [Array, String], default: null },
      isVertical: { type: Boolean, default: false },
      isSelfAssessment: { type: Boolean, default: false },
      isSelected: { type: Boolean, default: false },
    },

    computed: {
      extraCardClasses() {
        if (!this.cardClass) return []

        return this.cardClass && this.cardClass instanceof Array
          ? this.cardClass
          : [this.cardClass]
      },
    },

    methods: {
      titleCase: StringHelpers.titleCase,

      abs(num) {
        return Math.abs(num)
      },

      valueWithThousandsSeparator(num) {
        return num
          .toString()
          .replace(/,/g, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
    },
  }
</script>
<style scoped>
  .selected {
    border: 2px solid #1c567d !important;
  }
</style>
