<template lang="pug">
div.modal.fade(tabindex="-1",role="dialog",aria-hidden="true")
  div.modal-dialog(role="document")
    div.modal-content
      div.modal-header
        h5.modal-title {{ title }} 
        button.close(ref="Close" type="button",data-dismiss="modal",aria-label="Close")
          span(aria-hidden="true") &times;
      div.modal-body.text-center
        div.d-flex.align-items-center.flex-column
          template(v-if="!isJiraConfigured")
            p Jira is not configured for this team. Please see the #[a(href="/settings/jira") Jira Integration Page] for more details on setting up the integration.
          template(v-else)
            slot
            template(v-if="allowSyncExisting")
              div.my-2
                div.custom-control.custom-toggle-sm.custom-toggle
                  input.custom-control-input(
                    type="checkbox",
                    :id="`create-new-issue-toggle-${_uid}`",
                    name="create-new-issue-toggle",
                    v-model="createNewIssue")
                  label.custom-control-label(:for="`create-new-issue-toggle-${_uid}`") Create New Jira Issue

            div.my-2
              label Jira Project
              loader(v-if="loading")
              select.form-control(v-else v-model="targetProjectId")
                option(v-for="project in availableProjects",:value="project.id") {{ project.name }}

            template(v-if="targetProjectId && !createNewIssue")
              div.my-2.w-100
                label Existing Issue
                v-select.typeahead-input-new(
                  :disabled="!targetProjectId"
                  placeholder="Search for a Jira issue in selected project"
                  :options="(jiraIssuesInProject || []).map(issue => ({label: `${issue.key}: ${issue.summary}`, code: issue.id}))"
                  v-model="selectedIssue"
                  @search="getJiraIssuesInProject")
                  template(v-slot:option="{label}")
                    div(v-html="label")
                  span(slot="no-options" @click="$refs.select.open = false") Type to search for Jira Issues
              
            button.btn.btn-primary.mt-2(:id="`sync-jira-${_uid}`" type="button" @click="sync" :disabled="isCreateDisabled") Sync
</template>

<script>
  import Utilities from '@risk3sixty/utilities'
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import ApiIntegrations from '../../../factories/ApiIntegrations'

  export default {
    props: {
      title: { type: String, default: 'Sync with Jira' },
      allowSyncExisting: { type: Boolean, default: true },
    },

    data() {
      return {
        createNewIssue: true,
        targetProjectId: null,
        loading: false,
        jiraIssuesInProject: [],
        selectedIssue: null,
      }
    },

    computed: {
      ...mapState({
        availableProjects: (state) => state.jira.availableProjects,
        isJiraConfigured: (state) =>
          !!state.session.current_team.jira_webtrigger_url,
      }),

      isCreateDisabled() {
        if (this.loading) return true
        if (!this.targetProjectId) return true
        if (!this.createNewIssue && !this.selectedIssue) return true
        return false
      },
    },

    methods: {
      getJiraIssuesInProject: Utilities.debounce(async function (searchText) {
        try {
          if (!searchText) {
            this.jiraIssuesInProject = []
          }
          this.jiraIssuesInProject = await ApiIntegrations.getTasksInProject(
            this.targetProjectId,
            searchText
          )
        } catch (err) {
          window.toastr.error(`Error getting Jira issues in project. ${err}`)
        }
      }, 400),

      async sync() {
        if (!this.isJiraConfigured)
          return window.toastr.error(
            'Jira is not configured for this team. Please see the Jira Integration Page at Settings > Jira Integration for more details on setting up the integration.'
          )

        this.$emit('submit', {
          jiraProjectId: this.targetProjectId,
          jiraIssueId: this.createNewIssue ? null : this.selectedIssue.code,
        })
        this.$refs.Close.click()
      },
    },

    mounted() {
      $(`#${this.$el.id}`).on('shown.bs.modal', async () => {
        this.loading = true
        try {
          await this.$store.dispatch('getJiraProjects')
        } catch (err) {
          window.toastr.error(`Error getting Jira projects. ${err}`)
        } finally {
          this.loading = false
        }
      })
      $(`#${this.$el.id}`).on('hidden.bs.modal', async () => {
        this.createNewIssue = true
        this.targetProjectId = null
        this.loading = false
        this.jiraIssuesInProject = []
        this.selectedIssue = null
      })
    },
  }
</script>
