<template lang="pug">
  div
    pill-status-updater(
      :value="status",
      :is-hollow="true",
      :options="statusPillUpdaterOptions",
      :updatable="updatable"
      @input="updateItemStatus")
</template>

<script>
  import { mapState } from 'vuex'
  import ApiRiskRegister from '../../factories/ApiRiskRegister'
  import StringHelpers from '../../factories/StringHelpers'

  export default {
    props: {
      itemId: { type: [Number, String], required: true },
      status: { type: String, default: null },
      updatable: { type: Boolean, default: true },
    },

    computed: {
      ...mapState({
        statusPillUpdaterOptions(state) {
          return state.riskregister.statuses.map((st) => {
            return {
              value: st.value,
              text: StringHelpers.titleCase(st.value, true),
              color: state.dashboard.variantColors()[st.variant],
            }
          })
        },
      }),
    },

    methods: {
      titleCase: StringHelpers.titleCase,
      async updateItemStatus(newStatus) {
        try {
          await ApiRiskRegister.createOrUpdateItem({
            id: this.itemId,
            status: newStatus,
          })
          this.$emit('updated', newStatus)
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    mounted() {
      this.$store.dispatch('getRiskRegisterStatuses')
    },
  }
</script>
