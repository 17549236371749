<template lang="pug">
  div
    div.mb-1(v-if="isEditMode")
      select.form-control.form-control-vsm(v-model="assignmentUserId",@change="updateAssignment(assignmentUserId)")
        option(v-for="user in $store.state.team.teamAndInternalUsers",:value="user.id") {{ user.name || user.username_email }}
    div
      div.d-flex(v-if="users && users.length > 0")
        user-avatar.mr-1(
          v-for="user in users",
          :key="user.id",
          :id="'update-assignments-' + uid + '-' + user.id",
          :user-id="user.id",
          @close="updateAssignment(user.id, 'delete')")
      div(v-else)
        i No assignees
</template>

<script>
  export default {
    props: {
      users: { type: Array },
      uid: { type: [ Number, String ] },
      isEditMode: { type: Boolean, default: false }
    },

    data() {
      return {
        assignmentUserId: null
      }
    },

    methods: {
      updateAssignment(userId, action) {
        this.$emit('update', userId, action)
        // this.assignmentUserId = null
      }
    }
  }
</script>
