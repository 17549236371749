<template lang="pug">
  th(
    style="vertical-align: top"
    @mouseover="isHovered = true",
    @mouseout="isHovered = false")
      div.mx-1.d-flex.justify-content-center(v-if="isMinimized")
        a(:id="`toggle-minimize-expand-${_uid}`",@click="toggleMinimize")
          i.fal.fa-plus-circle
        b-tooltip(:target="`toggle-minimize-expand-${_uid}`")
          | {{ fieldKey }}
      div.d-flex.flex-column(v-else :id="`spreadsheet-col-main-${_uid}`")
        //- i.mr-1.mt-1.fal.fa-grip-lines(
        //-   :id="`spread-th-drag-${_uid}`"
        //-   style="cursor: grab")
        div.ml-auto.nowrap
          a.ml-1.text-gray(v-visible="isHovered" @click="toggleMinimize")
            i.fal.fa-minus-circle

          a.ml-1(
            v-visible="isHovered || fieldHasFilters"
            :id="`filter-field-${_uid}`"
            :class="fieldHasFilters ? 'text-info' : ''")
              i.fal.fa-filter
          field-filter-popover(
            :target="`#filter-field-${_uid}`"
            :field="field")
          
          a.ml-1(v-visible="isHovered && canEdit" :id="`edit-field-${_uid}`")
            i.fal.fa-pen-square
          field-edit-popover(
            v-if="!isMinimized"
            :target="`#edit-field-${_uid}`"
            :spreadsheet-id="spreadsheetId"
            :field-id="fieldId"
            @saved="updateField")

          a.ml-1.text-danger(v-visible="isHovered && canEdit" :id="`delete-col-${_uid}`")
            i.fal.fa-times-circle
          confirmation-popover(
            :target="`delete-col-${_uid}`",
            header="Confirm Delete Column?",
            :text="`Are you sure you want to delete <strong>${fieldKey}</strong>?`",
            @confirm="deleteField")
        div.clickable.d-flex.align-items-start.mb-1(@click.stop="sortBy")
          i.mr-1.clickable.fa(
            v-if="isSortable"
            v-visible="isHovered || isSorting"
            :class="sortIcon")
          div(:class="fieldHasFilters ? 'text-info' : ''") {{ fieldKey }}
        b-tooltip(
          v-if="description"
          boundary="viewport"
          placement="top"
          :target="`spreadsheet-col-main-${_uid}`")
            | {{ description }}

      //- b-tooltip(:target="`spread-th-drag-${_uid}`")
        | Drag to change the order of the column.
</template>

<script>
  import { mapState } from 'vuex'
  import FieldEditPopover from './FieldEditPopover'
  import FieldFilterPopover from './FieldFilterPopover'
  import InventoryHelpers from '../../../factories/InventoryHelpers'
  import ApiSpreadsheet from '../../../factories/ApiSpreadsheet'
  import ApiUsers from '../../../factories/ApiUsers'

  export default {
    props: {
      spreadsheetId: { type: [Number, String], required: true },
      field: { type: Object, required: true },
      isSorting: { type: Boolean, default: false },
      sortDirection: { type: String, default: 'asc' },
      canEdit: { type: Boolean, default: true },
    },

    data() {
      return {
        isHovered: false,
      }
    },

    computed: {
      ...mapState({
        personalizations: (state) => state.auth.personalizations,

        isMinimized(state) {
          return (
            state.auth.personalizations[this.minimizeKey] &&
            state.auth.personalizations[this.minimizeKey].parameters[
              this.fieldId
            ]
          )
        },

        fieldHasFilters(state) {
          return (
            (
              (state.inventories.filters[
                state.inventories.currentInventory.id
              ] || {})[this.fieldId] || []
            ).length > 0
          )
        },
      }),

      description() {
        return this.field.description
      },

      fieldId() {
        return this.field.id
      },

      fieldKey() {
        return this.field.key
      },

      fieldType() {
        return this.field.type
      },

      isSortable() {
        return InventoryHelpers(this)
          .sortableFieldTypes()
          .find((t) => t.type == this.fieldType)
      },

      sortIcon() {
        if (!this.isSorting) return 'fa-sort-up'
        return this.sortDirection && this.sortDirection.toLowerCase() === 'desc'
          ? 'fa-sort-down'
          : 'fa-sort-up'
      },

      minimizeKey() {
        return `spreadsheet_fields_minimized_${this.spreadsheetId}`
      },
    },

    methods: {
      updateField() {
        this.$emit('updated', ...arguments)
      },

      sortBy() {
        this.$emit(
          'sort',
          this.fieldId,
          !this.isSorting
            ? 'asc'
            : this.sortDirection && this.sortDirection.toLowerCase() !== 'asc'
            ? 'asc'
            : 'desc'
        )
      },

      async deleteField() {
        if (!this.canEdit) return
        try {
          await ApiSpreadsheet.saveField({
            id: this.fieldId,
            spreadsheet_id: this.spreadsheetId,
            is_deleted: true,
          })
          this.updateField()
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async toggleMinimize() {
        try {
          const newVal = {
            ...(this.personalizations[this.minimizeKey] || {}).parameters,
            [this.fieldId]: !this.isMinimized,
          }
          this.$store.commit('SET_USER_PERSONALIZATIONS', {
            [this.minimizeKey]: { parameters: newVal },
          })
          await ApiUsers.updatePersonalization(this.minimizeKey, newVal)
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    components: {
      FieldEditPopover,
      FieldFilterPopover,
    },
  }
</script>
