<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-hidden="true")
    div.modal-dialog(role="document")
      div.modal-content
        form(@submit="createVendor")
          div.modal-header
            h5.modal-title Add Vendor
            button.close(type="button",data-dismiss="modal",aria-label="Close")
              span(aria-hidden="true") &times;
          div.modal-body
            template(v-if="vendors.length > 0")
              div
                h6 Use Existing Vendor
              div.form-group.mb-2
                label Vendor
                v-select(
                  v-model="existingVendorId"
                  :options="vendors"
                  label="name"
                  @input="updateForm")
              div.text-center
                h6 OR
              hr
            div.mt-2
              h6 Create New Vendor
            div.form-group.mb-2
              label Category #[span.text-danger *]
              select.form-control(v-model="record.category_id")
                option(:value="null") Select category for vendor...
                option(
                  v-for="cat in categories",
                  :value="cat.id"
                  v-html="cat.name")
            div.form-group.mb-2
              label Name #[span.text-danger *]
              input.form-control(v-model="record.name",:disabled="record.is_published")
            div.form-group.mb-2
              label Description
              textarea.form-control(v-model="record.description",:disabled="record.is_published")
            div.form-group.mb-2
              label Contact(s)
              user-list.card-body.p-0.border-bottom(
                :users="vendorUsers",
                :can-edit-user="false",
                modalTitle="Add Contact",
                @addedUser="addUserToVendorUsersArray",
                @removeUser="removeUserFromVendorUsersArray")

          div.modal-footer
            button.btn.btn-secondary(type="button",data-dismiss="modal") Cancel
            button.btn.btn-primary(type="submit") Add
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import ApiVendorManagement from '../../factories/ApiVendorManagement'

  export default {
    data() {
      return {
        existingVendorId: null,
        record: {
          name: null,
          description: null,
          category_id: null,
        },

        vendorUsers: [],
      }
    },

    computed: {
      ...mapState({
        vendors: (state) => state.vendormanagement.vendors,
        categories: (state) => state.vendormanagement.categories,
        users: (state) => state.team.teamAndInternalUsers,
      }),
    },

    methods: {
      updateForm(vendorRecord) {
        if (!vendorRecord) return this.resetVendorRecord()
        // Set vendor id as 'vendor_id' param for create/update purposes
        this.record = {
          ...vendorRecord,
          id: null,
          vendor_id: vendorRecord.id,
        }

        if (this.record === null) {
          this.resetVendorRecord()
          this.vendorUsers = []
        } else {
          this.vendorUsers = this.record.vendor_users || this.vendorUsers
        }
      },

      resetVendorRecord() {
        return (this.record = {
          name: null,
          description: null,
          category_id: null,
        })
      },

      addUserToVendorUsersArray(user) {
        this.vendorUsers.push(user)
      },

      removeUserFromVendorUsersArray(userId) {
        const userRecord = this.vendorUsers.find((vu) => vu.id == userId)
        if (userRecord) {
          const ind = this.vendorUsers.indexOf(userRecord)
          this.vendorUsers.splice(ind, 1)
        }
      },

      async addUserToVendor(teamVendorId, userId) {
        try {
          await ApiVendorManagement.addUserToVendor(teamVendorId, userId)
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async createVendor(evt) {
        try {
          evt.preventDefault()

          if (!this.record.name)
            return window.toastr.error(
              `Please add a name of the vendor before saving.`
            )

          if (!this.record.category_id)
            return window.toastr.error(
              `Please select a category of the vendor before saving.`
            )

          const teamVendorId = await this.$store.dispatch(
            'createUpdateTeamVendor',
            this.record
          )

          // Map added contacts to vendor
          if (this.vendorUsers) {
            this.vendorUsers.map(async (vu) => {
              await this.addUserToVendor(teamVendorId, vu.id)
            })
          }

          window.toastr.success(`Successfully added vendor!`)
          this.$emit('created', teamVendorId)
          $(`#${this.$el.id}`).modal('hide')
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    mounted() {
      $(`#${this.$el.id}`).on('shown.bs.modal', () => {
        this.resetVendorRecord()

        // https://stackoverflow.com/questions/28077066/bootstrap-modal-issue-scrolling-gets-disabled
        $('body').addClass('modal-open')
      })
      $(`#${this.$el.id}`).on('hide.bs.modal', () => this.$emit('cancel'))
    },
  }
</script>
