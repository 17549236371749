<template lang="pug">
  div.card.card-small
    div.card-header.border-bottom
      div.small.d-flex.align-items-center
        router-link(to="/risk/register")
          strong Risk Register Open Items
        div.ml-auto
          select.form-control.form-control-sm(v-model="itemInfo.perPage",@change="changePerPage()")
            option 5
            option 10
            option 15
            option 20
            option 25
            option 50
    ul.list-group.list-group-flush
      li.list-group-item(v-if="isLoadingLocal")
        loader
      template(v-else-if="itemInfo.totalCount > 0")
        li.clickable.list-group-item.hover-fade.py-1.px-3(v-for="(item, ind) in itemInfo.data",@click="goToItem(item)")
          div.row.small-gutters
            div.d-flex.align-items-center.col-1 {{ getRowNumber(ind) }}.
            //- div.col-1.d-flex.align-items-center.overflow-ellipses.no-hover {{ item.register_name }}
            div.col-5.d-flex.align-items-center(:id="`rr-dashboard-items-${item.id}`")
              div.overflow-ellipses.no-hover(v-html="item.description")
              b-tooltip(:target="`rr-dashboard-items-${item.id}`")
                div(v-html="item.description")
            div.col.d-flex.align-items-center.justify-content-end.small
              small
                risk-register-item-status-updater(
                  :unique-string="`rr-dashboard-${item.risk_register_id}`"
                  :item-id="item.id",
                  :status="item.status")
            div.col
              div.ml-1.d-flex.align-items-center.justify-content-end
                risk-register-data-circle(
                  type="risk",
                  :value="item.risk_score || 'N/A'",
                  header="Residual Risk",
                  size="25")
                //- risk-register-data-circle.mx-1(
                //-   type="cost",
                //-   :value="item.cost_estimation || 'N/A'",
                //-   header="Cost",
                //-   size="25")
                //- risk-register-data-circle.mx-1(
                //-   type="effort",
                //-   :value="item.effort_estimation || 'N/A'",
                //-   header="Effort",
                //-   size="25")
        li.list-group-item(v-if="itemInfo.numberPages > 1")
          div.d-flex.align-items-center.justify-content-end
            pagination(
              :info="itemInfo",
              size="sm"
              @changePage="changePage")
      li.list-group-item(v-else)
        small
          i No active risk register items...
</template>

<script>
  import ApiRiskRegister from '../../../../factories/ApiRiskRegister'
  import StringHelpers from '../../../../factories/StringHelpers'
  import TimeHelpers from '../../../../factories/TimeHelpers'

  export default {
    data() {
      return {
        isLoadingLocal: true,
        itemInfo: {
          currentPage: 1,
          data: [],
          numberPages: 1,
          perPage: 5,
          totalCount: 0,
        },
      }
    },

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,
      truncateString: StringHelpers.truncateString,

      getRowNumber(index) {
        return (
          this.itemInfo.perPage * (this.itemInfo.currentPage - 1) + (index + 1)
        )
      },

      goToItem(item) {
        this.$router.push(
          `/risk/register/${item.risk_register_id}/item/${item.id}`
        )
      },

      async changePerPage(newPerPage = this.itemInfo.perPage) {
        this.itemInfo.perPage = newPerPage
        this.changePage(1)
      },

      async changePage(newPage = this.itemInfo.currentPage) {
        this.itemInfo.currentPage = newPage
        await this.getItems()
      },

      async getItems() {
        try {
          this.isLoadingLocal = true
          const { info } = await ApiRiskRegister.getDashboardItems(
            this.itemInfo.currentPage,
            this.itemInfo.perPage
          )
          this.itemInfo = info
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.isLoadingLocal = false
        }
      },
    },

    async created() {
      await Promise.all([
        this.$store.dispatch('initRiskRegister'),
        this.getItems(),
      ])
    },
  }
</script>
