<template lang="pug">
  div.m-1
    div.mb-3 {{ value }}
    //- div.text-light.text-right(style="font-size: 0.6rem;")
    //-   span(:id="`exp-latex-human-${_uid}`")
</template>

<script>
  import InventoryHelpers from '../../../../factories/InventoryHelpers'

  const MQ = window.MathQuill.getInterface(2)

  export default {
    props: {
      field: { type: Object, required: true },
      row: { type: Object, required: true },
      columns: { type: Array, required: true },
    },

    watch: {
      field: {
        handler() {
          this.setExprField()
          this.value = this.computeValue()
        },
        deep: true,
      },

      row: {
        handler() {
          this.value = this.computeValue()
        },
        deep: true,
      },
    },

    data() {
      return {
        value: null,
        mathField: null,
      }
    },

    computed: {
      expressionLatex() {
        return JSON.parse(this.field.mod1).expressionLatex
      },

      fieldVariables() {
        return JSON.parse(this.field.mod1).fieldVariables
      },

      computedVarObj() {
        return InventoryHelpers(this).createExpressionEvalObj(
          this.fieldVariables,
          this.columns,
          this.row
        )
      },
    },

    methods: {
      computeValue() {
        try {
          const fn = window.evaluatex(this.expressionLatex)
          return fn(this.computedVarObj)
        } catch (err) {
          // console.error('Error computing value', err)
          return 'N/A'
        }
      },

      setExprField() {
        const el = document.getElementById(`exp-latex-human-${this._uid}`)
        if (!el) return

        if (!this.mathField) {
          this.mathField = MQ.StaticMath(el)
        }
        return this.mathField.latex(this.expressionLatex)
      },
    },

    mounted() {
      this.setExprField()
      this.value = this.computeValue()
    },
  }
</script>
