<template lang="pug">
  table.table
    thead.bg-light
      tr
        th.border-0.nowrap(scope='col',style="width: 30%") Role
        th.border-0.nowrap.pl-2(scope='col') Name
        //- th.border-0.nowrap(scope='col') Email
        //- th.border-0.nowrap(scope='col') Phone
        //- th.border-0.nowrap(scope='col') Job Title
        //- th.border-0.nowrap(scope='col') Location
    create-key-contact-tbody(
      v-if="isCreatingNew",
      :project-id="projectId",
      :category="groupName",
      @created="toggleNewRow"
      @closeForm="$emit('close')")
    tbody
      template(v-for="(usersInRole, roleName) in groupedRoles")
        tr(v-if="usersInRole.length === 0 || !usersInRole[0].id")
          td(colspan="100%")
            i No roles added yet...
        tr(
          v-else
          v-for="(role, index) in usersInRole")
            td(:rowspan="usersInRole.length",v-show="index === 0")
              div.d-flex.align-items-center
                i.fa.fa-circle.mr-2(:class="usersInRole[0].username_email ? 'text-success' : 'text-gray'")
                div {{ roleName }}
                div.ml-auto(v-if="isAdmin")
                  div.d-flex.align-items-center.text-center
                    a(@click="updateRoleUser(role, true)")
                      div.small-circle-icon
                        i.fal.fa-trash-alt.clickable(:title="`Delete ${role.contact_name} from this scope`")
                    a.ml-1(v-if="isAdmin",@click="$set(role, 'is_editing', !role.is_editing)")
                      div.small-circle-icon
                        i.fal.fa-plus.text-success.clickable(:title="`Add users to ${role.contact_name}`")
            td.border-left.pl-2
              div.d-flex.align-items-center.mb-3(v-if="role.is_editing")
                div.w-25
                  v-select(
                    v-model="selectedUserId"
                    :options="teamUsers"
                    :get-option-label="u => u.name || u.username_email"
                    @input="user => addUserToRole(user, role)")
                i.ml-1.fal.fa-plus-circle.text-success.clickable(
                  :id="`new-user-${_uid}`"
                  data-toggle="modal"
                  data-target="#invite-team-member-modal-key-contacts"
                  @click="addingNewUser(role)")
                i.ml-1.fal.fa-times-circle.clickable(@click="$set(role, 'is_editing', !role.is_editing)")
              div.d-flex.align-items-center
                img.user-avatar.rounded-circle.mr-1(
                  v-if="!!role.avatar_file"
                  style="max-height:20px;"
                  :src="`/file/s3/${role.avatar_file}`"
                  alt='User Avatar')
                div.d-flex.align-items-center
                  div
                    div {{ role.name || role.username_email || 'N/A' }}
                    div.small
                      small {{ role.username_email }}
                  i.ml-1.fal.fa-times.text-danger.clickable(
                    v-if="role.username_email"
                    @click="removeUserFromRole(role)")
            //- td {{ role.username_email }}
            //- td {{ displayPhoneNumber(role.phone_number) }}
            //- td {{ role.job_title }}
            //- td {{ role.location }}

    add-team-member-modal#invite-team-member-modal-key-contacts(@added="addedNewUser")
</template>

<script>
  import { mapState } from 'vuex'
  import ApiAssessments from '../../factories/ApiAssessments'
  import StringHelpers from '../../factories/StringHelpers'
  import Utilities from '@risk3sixty/utilities'

  export default {
    props: {
      projectId: { type: [Number, String], default: null },
      roles: { type: Array, default: () => [] },
      isCreatingNew: { type: Boolean, default: false },
      groupName: { type: String, default: null },
    },

    data() {
      return {
        selectedUserId: null,
        newUserRole: null,
      }
    },

    computed: {
      ...mapState({
        isAdmin: (_, getters) => getters.isAdmin,
        teamUsers: (state) => state.team.users,
      }),

      groupedRoles() {
        return Utilities.Arrays.arrayGroupBy(
          this.roles,
          (r) => r.contact_name || ''
        )
      },
    },

    methods: {
      displayPhoneNumber: StringHelpers.displayPhoneNumber,

      async toggleNewRow(newContact) {
        this.$emit('toggleRow', newContact)
      },

      addingNewUser(role) {
        this.newUserRole = role
      },

      async addedNewUser(user) {
        await this.addUserToRole(user, this.newUserRole)
        this.newUserRole = null
      },

      async addUserToRole(user, role) {
        this.selectedUserId = user.id
        await this.updateRoleUser(role)
      },

      async updateRoleUser(role, isRemoved = null) {
        try {
          await ApiAssessments.updateKeyContact({
            ...role,
            user_id: this.selectedUserId,
            is_removed: isRemoved,
          })
          this.$emit('update')

          this.selectedUserId = null
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async removeUserFromRole(role) {
        try {
          await ApiAssessments.deleteKeyContactAssignment(role.assignment_id)
          this.$emit('update')
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  tbody {
    td {
      white-space: nowrap;
    }
  }
</style>
