<template lang="pug">
  div
    div.r3s-entity-icon
      a.comment-button(data-toggle="modal",:data-target="`#${modalId}`")
        div.nav-link-icon__wrapper(:style="iconWrapperStyle")
          i.fal.fa-comments
            span.badge.badge-pill.badge-primary(v-show="comments.length > 0") {{ comments.length }}

    div.modal.fade(:id="modalId",tabindex="-1",role="dialog",aria-labelledby="comments-modal",aria-hidden="true")
      div.modal-dialog.modal-lg(role="document")
        div.modal-content
          div.modal-header
            h5.modal-title {{ modalTitle || 'Comments' }}
            button.close(type="button",data-dismiss="modal",aria-label="Close")
              span(aria-hidden="true") &times;
          div.modal-body
            comment-list(
              :comments="comments"
              v-model="newComment"
              :onlyInternal="onlyInternal"
              @createComment="addUpdateComment"
              @updateComment="addUpdateComment"
              @deleteComment="deleteComment")
          div.modal-footer
            button.btn.btn-secondary(type="button",data-dismiss="modal") Close
            - //button.btn.btn-primary(@click="confirm") Confirm
</template>

<script>
  import $ from 'jquery'
  import ApiComments from '../../factories/ApiComments'

  export default {
    props: {
      table: { type: String, required: true },
      entityId: { type: [Number, String], required: true },
      modalTitle: { type: String, default: null },
      wrapperBorder: { type: Boolean, default: false },
      onlyInternal: { type: Boolean, default: false },
    },

    data() {
      return {
        newComment: null,
        comments: [],
      }
    },

    watch: {
      entityId: {
        handler() {
          this.getComments()
        },
        deep: true,
        immediate: true,
      },
    },

    computed: {
      modalId() {
        return `comments-modal-${this._uid}`
      },

      iconWrapperStyle() {
        return this.wrapperBorder
          ? {}
          : {
              borderWidth: '0px',
              borderRadius: 'inherit',
              width: 'auto',
              height: 'auto',
            }
      },
    },

    methods: {
      resetUpdateModal() {
        // This is here due to this issue
        // https://stackoverflow.com/questions/10636667/bootstrap-modal-appearing-under-background
        $(`#${this.modalId}`).appendTo('body')
      },

      async addUpdateComment(
        { id, comment, mentions, internalOnly },
        deleteComment = false
      ) {
        try {
          const finalComm = comment || this.newComment
          await ApiComments.createOrUpdateAndLink(
            {
              id,
              comment: finalComm,
              mentions,
              internalOnly,
              table: this.table,
              entityId: this.entityId,
            },
            deleteComment
          )
          this.newComment = null
          await this.getComments()
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async deleteComment(id) {
        await this.addUpdateComment({ id }, true)
      },

      async getComments() {
        try {
          if (!(this.table && this.entityId)) return
          const { comments } = await ApiComments.getByEntity(
            this.table,
            this.entityId
          )
          this.comments = comments
        } catch (err) {
          window.toastr.error(err.message)
        }
      },
    },

    async mounted() {
      this.resetUpdateModal()
    },

    beforeDestroy() {
      $(`#${this.modalId}`).remove()
    },
  }
</script>
