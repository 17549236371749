<template lang="pug">
doughnut(:options="options" :data="chartData")
</template>

<script>
  import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
  import { Doughnut } from 'vue-chartjs'
  ChartJS.register(ArcElement, Tooltip, Legend)

  export default {
    components: { Doughnut },
    props: {
      options: { type: Object, default: null },
      chartData: { type: Object, default: null },
    },
  }
</script>
