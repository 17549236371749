<template lang="pug">
  div.card
    div.card-body
      div.text-header
        div.d-flex.align-items-start
          div.mr-4.edit-user-details__avatar
            img(v-if="user.avatar_file",alt="User Avatar",:src="'/file/s3/' + user.avatar_file")
            div(v-else)
              i.material-icons account_circle
          div
            h2.mt-0 {{ user.name || user.username_email }}
            h5(v-if="user.job_title") {{ user.job_title }}
            div.d-flex.align-items-center
              div.small(v-if="user.phone_number")
                | m. {{ displayPhoneNumber(user.phone_number) }}
                | #[span(v-if="user.phone_number && user.username_email") |]
                | e. {{ user.username_email }}
      hr
      div.mb-4(v-if="user.summary")
        h4 Summary
        div(v-html="user.summary")
      div.mb-4(v-if="hasJsonbItems('experience')")
        h4 Experience
        div(v-if="user.experience.isHtml",v-html="user.experience.html")
        ul(v-else)
          li(v-for="exp in user.experience.items") {{ exp.info }}
      div.mb-4(v-if="hasJsonbItems('education')")
        h4 Education
        div(v-if="user.education.isHtml",v-html="user.education.html")
        ul(v-else)
          li(v-for="edu in user.education.items") {{ edu.info }}
      div.mb-4(v-if="$store.getters.isR3s")
        h4 Publications
        div.text-gray
          | Available by request or on the web:
          | #[a(href="https://www.risk3sixty.com/blog",target="_blank",rel="noopener noreferrer") Thought Leadership]
</template>

<script>
  import StringHelpers from '../../factories/StringHelpers'

  export default {
    props: {
      user: { type: Object }
    },

    methods: {
      displayPhoneNumber: StringHelpers.displayPhoneNumber,

      hasJsonbItems(prop) {
        return this.user[prop] && (this.user[prop].isHtml || (this.user[prop].items && this.user[prop].items.length > 0))
      }
    }
  }
</script>
