<template lang="pug">
  div.modal.fade(
    tabindex="-1"
    role="dialog"
    aria-labelledby="file-manager-modal-label"
    aria-hidden="true")
      div.modal-dialog.modal-xl(role="document")
        div.modal-content.pb-2
          div.modal-header.px-4
            h5#edit-template-center-config-modal-label.modal-title
              | {{ modalTitle }}
            button.close(type="button",data-dismiss="modal",aria-label="Close")
              span(aria-hidden="true") &times;
          div.modal-body.m-0.p-0.overflow-scroll(:style="`max-height: calc(100vh - 180px)`")
            org-file-manager(@addFile="addFile")
</template>

<script>
  export default {
    props: {
      modalTitle: { type: String, default: `Add File` },
      filters: { type: Object, default: () => null },
    },

    methods: {
      async addFile(file) {
        this.$emit('addFile', file)
      },
    },
  }
</script>
