<template lang="pug">
  div.d-flex(v-if="alwaysShowPerPage || info.totalCount > Math.min($store.state.pageSizes) || info.numberPages > 1",:class="isRight && 'justify-content-end'")
    div.mr-2.d-flex.align-items-center
      div.d-flex.align-items-center(v-if="size !== 'sm'")
        label.mb-0.mr-2.text-right.nowrap(v-if="perPageLabel") {{ perPageLabel }}
        v-select(
          v-model="perPageChangeable"
          :options="$store.state.pageSizes"
          @input="changePerPage()",
          append-to-body
          :calculate-position="withPopper"
        )

    b-pagination.mb-0(:size="size",:limit="limit",v-model="info.currentPage",:total-rows="info.totalCount",:per-page="info.perPage",@change="changePage")
</template>
<script>
  import { createPopper } from '@popperjs/core'
  export default {
    props: {
      perPageLabel: { type: String, default: 'Per page:' },
      info: { type: Object },
      size: { type: String, default: null },
      isRight: { type: Boolean, default: true },
      alwaysShowPerPage: { type: Boolean, default: false },
    },

    data() {
      return {
        perPageChangeable: this.info.perPage || 30,
        placement: 'top',
      }
    },

    computed: {
      limit() {
        return this.size === 'sm' ? 3 : 5
      },
    },

    methods: {
      // https://vue-select.org/guide/positioning.html
      // This guide tells you how to use Popper with Vue Select to make a dropup.
      // We use the append-to-body and calculate-position props above so Popper
      // can appropriately position the dropup.
      // Popper docs on modifiers API: https://popper.js.org/docs/v2/modifiers/

      withPopper(dropdownList, component, { width }) {
        dropdownList.style.width = width

        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: this.placement,
          modifiers: [
            {
              name: 'toggleClass',
              enabled: true,
              phase: 'write',
              fn({ state }) {
                component.$el.classList.toggle(
                  'drop-up',
                  state.placement === 'top'
                )
              },
            },
          ],
        })
        return () => popper.destroy()
      },

      changePage(...args) {
        this.$emit('changePage', ...args)
      },

      changePerPage() {
        this.$emit('changePerPage', this.perPageChangeable)
      },
    },

    created() {
      this.perPageChangeable = this.info.perPage || this.perPageChangeable
    },
  }
</script>
