<template lang="pug">
  div(v-if="mappingsCount > 0")
    a(@click.stop="openModal")
      icon-with-badge.small(
        icon-class="fa-map"
        :uid="`controls-count-${requestId}`"
        :count="mappingsCount"
        :tooltip-text="`${mappingsCount} possible artifacts that can be linked to this request.`"
        position="bottom-right")

    div.modal.fade(
      :id="`request-mapper-modal-${_uid}`"
      tabindex="-1"
      role="dialog"
      aria-hidden="true")
        div.modal-dialog.modal-xl(role="document")
          div.modal-content
            div.modal-header
              h5.modal-title Possible Artifacts for request: #[em {{ requestName || 'Request' }}]
              button.close(type="button",data-dismiss="modal",aria-label="Close")
                span(aria-hidden="true") &times;
            div(v-if="loading")
              loader
            div.modal-body.p-0.table-responsive.mb-0(v-else)
              table.table.table-bordered
                thead
                  tr
                    th(v-for="req in possibleRequestMappings",:style="cellWidthStyle")
                      div
                        div.small #[strong {{ getEngagementTypeHuman(req.engagement_type) }}]
                        div.small(v-for="con in req.controls") {{ con.control_number }} - {{ con.name }}
                        div {{ req.project_ref_num }} - {{ req.name }}
                tbody
                  tr
                    td(v-for="req in possibleRequestMappings")
                      ul.list-unstyled
                        li.mb-3.text-gray.small
                          i
                            | Click on an artifact below if you want to map
                            | it to #[strong {{ requestName || 'this request' }}]:
                        li.mb-2(v-for="(att, ind) in req.attachments")
                          attachment-item(
                            :class="selectedFileIds.includes(att.id) ? 'bg-primary' : 'bg-light'"
                            :attachment="att"
                            :index="ind"
                            size="sm"
                            :allow-delete="false"
                            :emit-only="true"
                            @click.native="selectAttachment(att.id)")
            div.modal-footer
              button.btn.btn-secondary(type="button",data-dismiss="modal") Cancel
              button.btn.btn-primary(
                v-if="selectedFileIds.length > 0"
                @click="mapArtifacts"
                :disabled="inlineLoader") Map Artifacts to Request #[loader-inline.ml-2(v-if="inlineLoader")]
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'
  import ApiAssessments from '../../factories/ApiAssessments'
  import TimeHelpers from '../../factories/TimeHelpers'

  export default {
    props: {
      projectId: { type: [Number, String], required: true },
      requestId: { type: [Number, String], required: true },
      requestName: { type: String, default: null },
    },

    data() {
      return {
        loading: false,
        inlineLoader: false,
        selectedFileIds: [],
        possibleRequestMappings: [],
      }
    },

    computed: {
      ...mapState({
        engTypes: (state) => state.assessments.engagementTypes,

        mappingsCount(state) {
          return (
            parseInt(
              state.assessments.currentProjectRequestMappingsCount[
                this.requestId
              ]
            ) || 0
          )
        },
      }),

      cellWidthStyle() {
        return {
          width: `${Math.floor(
            100 / (this.possibleRequestMappings.length || 1)
          )}%`,
        }
      },
    },

    methods: {
      getFormattedDate: TimeHelpers.getFormattedDate,

      getEngagementTypeHuman(type) {
        try {
          return this.engTypes.find((t) => t.key === type).text
        } catch (err) {
          return ''
        }
      },

      async openModal() {
        this.initializeModal()
        this.loading = true
        $(`#request-mapper-modal-${this._uid}`).modal('show')
        const { mappings } = await ApiAssessments.getRequestMappings(
          this.requestId,
          this.projectId
        )
        this.possibleRequestMappings = mappings[0].requests ?? []
        this.loading = false
      },

      selectAttachment(fileId) {
        if (this.selectedFileIds.includes(fileId)) {
          this.selectedFileIds.splice(
            this.selectedFileIds.findIndex((f) => fileId == f.id),
            1
          )
        } else {
          this.selectedFileIds.push(fileId)
        }
        this.selectedFileIds = [...new Set(this.selectedFileIds)]
      },

      async mapArtifacts() {
        this.inlineLoader = true
        try {
          await Promise.all(
            this.selectedFileIds.map(async (fileId) => {
              await ApiAssessments.addRequestAttachment(this.projectId, {
                requestId: this.requestId,
                fileId,
              })
            })
          )
          this.$emit('mapped')
          $(`#request-mapper-modal-${this._uid}`).modal('hide')
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.inlineLoader = false
        }
      },

      initializeModal() {
        $(`#request-mapper-modal-${this._uid}`).appendTo('body')
        $(`#request-mapper-modal-${this._uid}`).on('shown.bs.modal', () => {
          this.selectedFileIds = []
        })
        $(`#request-mapper-modal-${this._uid}`).on('hide.bs.modal', () => {
          this.possibleRequestMappings = []
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  th {
    vertical-align: middle;
  }

  td {
    vertical-align: top !important;
  }
</style>
