<template lang="pug">
  user-avatar-editable-group(
    :owners="owners",
    @add="addUser",
    @delete="deleteOwner")
</template>

<script>
  import ApiVendorManagement from '../../factories/ApiVendorManagement'

  export default {
    props: {
      vendorId: { type: [ Number, String ] },
      owners: { type: Array }
    },

    computed: {
      ownerUserIds() {
        return (this.owners || []).map(o => o.user_id || o.id)
      }
    },

    methods: {
      async addUser(newUserId) {
        try {
          const newUserIds = [ ...new Set([ ...this.ownerUserIds, newUserId ]) ]
          await ApiVendorManagement.updateVendorOwners(this.vendorId, newUserIds)
          this.$emit('updated')
        } catch(err) {
          window.toastr.error(err.message)
        }
      },

      async deleteOwner(userId) {
        try {
          await ApiVendorManagement.removeVendorOwner(this.vendorId, userId)
          this.$emit('updated')
        } catch(err) {
          window.toastr.error(err.message)
        }
      }
    }
  }
</script>