<template lang="pug">
  div.table-responsive
    table.table(:class="bordered && 'table-bordered'")
      thead.bg-light
        tr
          th Name
          th
            div.d-flex.align-items-center
              div Email
          th.nowrap Job Title
          th.text-right
            i.ml-2.clickable.fal.fa-plus-circle.text-success(
                  v-if="canCreateUser"
                  @click="resetAndShowModal")
      tbody
        tr(v-if="!users || users.length === 0")
          td(colspan="100%")
            i No users yet...
        tr(v-else,v-for="(u, ind) in users")
          td {{ u.name }}
          td
            div(:id="`user-list-${_uid}-${u.id}`") {{ u.username_email }}
            b-tooltip(v-if="hover",:target="`user-list-${_uid}-${u.id}`")
              | {{ u[hover] }}
          td {{ u.job_title }}
          td.text-right.d-flex.border-0
            i.clickable.mr-2(v-if="customButton",:class="customButtonIcon",@click="$emit('customButton', u)")
            i.clickable.fal.fa-pencil.text-success.mr-2(
              v-if="canEditUser"
              :id="`user-list-edit-${_uid}-${u.id}`"
              @click="editUser(u)")
            b-tooltip(:target="`user-list-edit-${_uid}-${u.id}`")
              | Edit User
            i.clickable.fal.fa-times-circle.text-danger(
              :id="`user-list-remove-${_uid}-${u.id}`")
            b-tooltip(:target="`user-list-remove-${_uid}-${u.id}`")
              | Remove User
            confirmation-popover(
              :target="`user-list-remove-${_uid}-${u.id}`",
              header="Confirm Remove Contact?",
              text="Are you sure you want to remove this contact from the vendor?",
              @confirm="removeUser(u.id)")

    div.modal.fade(:id="`create-user-modal-${_uid}`",tabindex="-1",role="dialog",aria-hidden="true")
      div.modal-dialog(role="document")
        div.modal-content
          div.modal-header
            h5#risk-register-formal-findings-label.modal-title {{ modalTitle }}
            button.close(type="button",data-dismiss="modal",aria-label="Close")
              span(aria-hidden="true") &times;
          div.modal-body
            div.form-group
              label Email Address #[span.text-danger *]
              input.form-control(type="text",v-model="mutableUser.username_email",:disabled="mutableUser.id")
            div.form-group
              label First Name
              input.form-control(type="text",v-model="mutableUser.first_name")
            div.form-group
              label Last Name
              input.form-control(type="text",v-model="mutableUser.last_name")
            div.form-group
              label Job Title
              input.form-control(type="text",v-model="mutableUser.job_title")
            div.form-group.mt-2.mb-0(v-if="createError")
              div.alert.alert-danger.rounded {{ createError }}
          div.modal-footer
            button.btn.btn-secondary(type="button",data-dismiss="modal") Cancel
            button.btn.btn-primary(type="button",@click="createUpdateUser") {{ mutableUser.id ? 'Save' : 'Add' }} 
</template>

<script>
  import $ from 'jquery'
  import ApiAuth from '../../factories/ApiAuth'
  import ApiUsers from '../../factories/ApiUsers'

  export default {
    props: {
      users: { type: Array, required: true },
      hover: { type: String, default: null },
      modalTitle: { type: String, default: 'Add User' },
      bordered: { type: Boolean, default: false },
      canCreateUser: { type: Boolean, default: true },
      canEditUser: { type: Boolean, default: true },
      customButton: { type: Boolean, default: false },
      customButtonIcon: { type: String, default: null },
      userType: { type: String, default: null },
    },

    data() {
      return {
        createError: null,
        mutableUser: {
          username_email: null,
          first_name: null,
          last_name: null,
          job_title: null,
        },
      }
    },

    methods: {
      resetModal() {
        // This is here due to this issue
        // https://stackoverflow.com/questions/10636667/bootstrap-modal-appearing-under-background
        $(`#create-user-modal-${this._uid}`).appendTo('body')
      },

      resetAndShowModal() {
        this.resetUser()
        this.resetModal()
        $(`#create-user-modal-${this._uid}`).modal('show')
      },

      resetUser() {
        this.mutableUser = {
          username_email: null,
          first_name: null,
          last_name: null,
          job_title: null,
        }
      },

      editUser(user) {
        user.first_name = user.first_name || user.name.split(' ')[0]
        user.last_name = user.last_name || user.name.split(' ')[1]

        this.mutableUser = {
          ...user,
        }
        $(`#create-user-modal-${this._uid}`).modal('show')
      },

      async createUpdateUser() {
        try {
          if (!ApiAuth.isValidEmail(this.mutableUser.username_email))
            return (this.createError = `Please enter a valid e-mail address to add the user.`)

          let id
          if (this.mutableUser.id) {
            await ApiUsers.update({
              id: this.mutableUser.id,
              username_email: this.mutableUser.username_email,
              first_name: this.mutableUser.first_name,
              last_name: this.mutableUser.last_name,
              job_title: this.mutableUser.job_title,
            })
            id = this.mutableUser.id
          } else {
            const res = await ApiUsers.create(
              this.mutableUser.username_email,
              this.mutableUser.first_name,
              this.mutableUser.last_name,
              this.mutableUser.job_title,
              this.userType
            )
            id = res.id
          }
          this.$emit('addedUser', {
            id,
            username_email: this.mutableUser.username_email,
            name: `${this.mutableUser.first_name} ${this.mutableUser.last_name}`,
            job_title: this.mutableUser.job_title,
          })
          this.createError = null
          this.resetUser()
          $(`#create-user-modal-${this._uid}`).modal('hide')
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      removeUser(userId) {
        this.$emit('removeUser', userId)
      },
    },

    mounted() {
      this.resetModal()
    },

    beforeDestroy() {
      // See comments in this.resetModal as to why this needs to be here.
      $(`#create-user-modal-${this._uid}`).remove()
    },
  }
</script>
