<template lang="pug">
  div
    div
      ul.row.no-gutters.nav.nav-tabs.border-bottom-0.flush
        li.nav-item.text-center(v-for="section in sections",:class="section.span ? `col-${section.span}` : 'col'")
          a.nav-link(
            :id="'card-tabs-' + section.key",
            :class="isActive(section) && 'active'",
            @click="changeSectionOpenState(section.key)"
          )
            div.d-flex.align-items-center.justify-content-center
              i.fal.fa-2x(:class="section.icon")
              span.badge.badge-pill.p-1(
                v-if="section.number",
                :class="(isActive(section) && 'badge-white') || 'badge-light'") {{ section.number }}
            b-tooltip(:target="'card-tabs-' + section.key") {{ section.text }}

            div.nowrap.small.text-center
              small {{ truncateString(section.text, 14) }}

    div.card-header.border-bottom
      h6.m-0 {{ (sections.find(s => s.key === activeSection) || {}).text }}
</template>

<script>
  import StringHelpers from '../../factories/StringHelpers'

  export default {
    props: {
      // Array of objects of information to display in tabs
      // Object keys:
      //    1. key: key to map to 'activeSection' for active tab state
      //    2. text: Text for title of section
      //    3. number: Number of items to display in badge for tab
      //    4. icon: font awesome icon class
      sections: { type: Array },
      activeSection: { type: String }
    },

    methods: {
      truncateString: StringHelpers.truncateString,

      isActive(section) {
        return this.activeSection === section.key
      },

      changeSectionOpenState(section) {
        this.$emit('changeActive', section)
      }
    }
  }
</script>

<style scoped lang="scss">
  .nav-item {
    overflow: hidden;
  }
</style>