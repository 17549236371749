<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-labelledby="jira-integration-label",aria-hidden="true")
    div.modal-dialog.modal-xl(role="document")
      div.modal-content
        form(@submit="createJiraTickets")
          div.modal-header
            h5#jira-integration-label.modal-title Create JIRA Issues
            button.close(type="button",data-dismiss="modal",aria-label="Close")
              span(aria-hidden="true") &times;
          div.modal-body
            div(v-if="isLoadingLocal")
              loader
            div(v-else)
              div.form-group
                label
                  div.d-flex.align-items-center
                    div Select Jira Project
                    a.ml-1(
                      v-if="false && projectId",
                      :href="selectedProject.self",
                      target="_blank",
                      rel="noopener noreferrer") ({{ selectedProject.name }})
                select.form-control(v-model="projectId",@change="getIssueMetadata")
                  option(:value="null") Select project...
                  option(v-for="proj in jiraProjects",:value="proj.id") {{ proj.name }}
              div.table-responsive.mb-0(style="overflow: visible;" v-if="projectId")
                label Issues to link
                table.table.table-bordered.small-padding
                  thead.bg-light
                    tr
                      th
                        div.custom-control.custom-checkbox
                          input#select-all-jira-data.custom-control-input(
                            type="checkbox",
                            v-model="allIssuesSelected",
                            @change="updateAllIssuesSelected")
                          label.custom-control-label(for="select-all-jira-data")
                      th Category
                      th Description
                      th JIRA Issue Type
                      th.nowrap
                        | Existing Task #[i#existing-task-info.fal.fa-info-circle]
                        b-tooltip(:target="`#existing-task-info`")
                          | If you'd like to link this record to an already existing
                          | JIRA issue/task, add it here.
                  tbody
                    tr(v-if="data.length === 0")
                      td(colspan="100%")
                        i No items...
                    tr(v-for="issue in newJiraIssues")
                      td
                        div.custom-control.custom-checkbox
                          input.custom-control-input(
                            :id="`select-jira-issue-${issue.id}`",
                            type="checkbox",
                            v-model="issue.isSelected")
                          label.custom-control-label(:for="`select-jira-issue-${issue.id}`")
                      td {{ issue.category }}
                      td.nowrap
                        span {{ truncateString(stripHtmlTags(issue.description), 40) }}
                        i.ml-1.fal.fa-info-circle(:id="`jira-new-issue-${issue.id}`")
                        b-tooltip(
                          :target="`jira-new-issue-${issue.id}`"
                          v-html="issue.description")
                      td
                        loader-inline(v-if="loadingMetadata")
                        select.form-control.form-control-sm(v-else,v-model="issue.issueTypeId")
                          option(:value="null") Select issue type...
                          option(v-for="type in projectIssueTypes",:value="type.id") {{ type.name }}
                      td
                        typeahead-input-new(
                          v-if="!issue.existingJiraTask"
                          :src="`/api/1.0/integrations/jira/issues/search?projectId=${projectId}`"
                          keys-from-response="issues"
                          show-prop="fields.summary"
                          v-model="issue.existingJiraTask")
                        div(v-else)
                          | #[strong {{ issue.existingJiraTask.key }}]:
                          | {{ issue.existingJiraTask.fields.summary }}
                          | #[i.fal.fa-times.text-danger.clickable(@click="issue.existingJiraTask = null")]
          div.modal-footer
            button.btn.btn-secondary(type="button",data-dismiss="modal") Cancel
            button.btn.btn-primary(type="submit") Create Tasks
</template>

<script>
  import $ from 'jquery'
  import utilities from '@risk3sixty/utilities'
  import { mapState } from 'vuex'
  import ApiIntegrations from '../../factories/ApiIntegrations'
  import StringHelpers from '../../factories/StringHelpers'

  const stripHtmlTags = utilities.Strings.stripHtmlTags

  export default {
    props: {
      // fields in data array
      // id, category, description
      data: { type: Array },
    },

    watch: {
      data: {
        handler(newItems) {
          const issueTypes = this.projectIssueTypes && this.projectIssueTypes[0]
          this.newJiraIssues = newItems.map((i) => ({
            ...i,
            issueTypeId: (issueTypes || {}).id,
            isSelected: !!i.isSelected,
          }))
        },
        deep: true,
      },
    },

    data() {
      return {
        isLoadingLocal: true,
        loadingMetadata: false,
        projectId: null,
        jiraProjects: [],
        projectIssueTypes: [],
        allIssuesSelected: null,

        newJiraIssues: this.data.map((i) => ({
          ...i,
          issueTypeId: null,
          isSelected: !!i.isSelected,
        })),
      }
    },

    computed: {
      ...mapState({}),

      selectedProject() {
        return this.jiraProjects.find((p) => p.id === this.projectId)
      },
    },

    methods: {
      stripHtmlTags,
      truncateString: StringHelpers.truncateString,

      updateAllIssuesSelected() {
        let newVal = false
        if (this.allIssuesSelected) newVal = true

        this.newJiraIssues = this.newJiraIssues.map((i) => ({
          ...i,
          isSelected: newVal,
        }))
      },

      async createJiraTickets(evt) {
        try {
          evt.preventDefault()

          this.$emit(
            'integrate',
            this.projectId,
            this.newJiraIssues.filter((t) => t.isSelected)
          )
          $(`#${this.$el.id}`).modal('hide')
        } catch (err) {
          window.toastr.error(err.message)
        }
      },

      async getIssueMetadata() {
        try {
          this.loadingMetadata = true

          if (!this.projectId)
            return window.toastr.error(
              `Please select a project to find all issue types we can create.`
            )

          const { issueTypes } = await ApiIntegrations.getJiraIssuetypes(
            this.projectId
          )
          this.projectIssueTypes = issueTypes || []

          this.newJiraIssues = this.newJiraIssues.map((i) => ({
            ...i,
            issueTypeId: (this.projectIssueTypes[0] || {}).id,
            isSelected: !!i.isSelected,
          }))
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.loadingMetadata = false
        }
      },

      async getJiraProjects() {
        try {
          this.isLoadingLocal = true
          const { projects } = await ApiIntegrations.getJiraProjects()
          this.jiraProjects = projects
        } catch (err) {
          window.toastr.error(err.message)
        } finally {
          this.isLoadingLocal = false
        }
      },
    },

    async mounted() {
      $(`#${this.$el.id}`).on('show.bs.modal', async () => {
        await this.getJiraProjects()
      })
    },
  }
</script>
