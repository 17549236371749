<template lang="pug">
  div.modal.fade(tabindex="-1",role="dialog",aria-hidden="true")
    div.modal-dialog(role="document")
      div.modal-content
        div.modal-header
          h5.modal-title Jira Sync
          button.close(ref="Close" type="button",data-dismiss="modal",aria-label="Close")
            span(aria-hidden="true") &times;
        div.modal-body
          div.d-flex.align-items-center.flex-column
            template(v-if="syncingTask && task.linked_jira_issue_url")
              div This task is synced with Jira issue #[a.strong(v-if="task.linked_jira_issue_url" target="_blank" :href="task.linked_jira_issue_url") {{ jiraIssueKey}}]  
              br
              div Would you like to unsync this task from Jira?
              br
              div This will delete the sync between this task and its corresponding issue in Jira. Updates to the Jira task will no longer be reflected in fullCircle, but the Jira task will remain unchanged by this action.
            template(v-else-if="syncingProject && project.linked_jira_project_id && syncedProject.name")
              div This project is synced to Jira project #[b {{ syncedProject.name}}]  
              br
              div Would you like to unsync this project from Jira? Going forward, new tasks will not be synced with Jira.
              br
              div.custom-control.custom-checkbox
                input.custom-control-input(
                  :id="`unsync-current-tasks-${_uid}`"
                  type="checkbox",
                  v-model="unsyncCurrentTasks",)
                label.custom-control-label(:for="`unsync-current-tasks-${_uid}`") Also remove sync for currently synced tasks in project
              p.text-danger.small(v-if="unsyncCurrentTasks") This will delete the sync between this project's tasks and their corresponding issues in Jira. Updates to the Jira tasks will no longer be reflected in fullCircle, but the Jira task will remain unchanged by this action.
            template(v-else)
              div There seems to be an issue with the Jira Sync. Would you like to delete the sync?
            br
            button.btn.btn-danger(type="button" @click="unSyncEntity" :disabled="loading") Unsync from Jira #[loader-inline(v-if="loading")]
</template>

<script>
  import { mapState } from 'vuex'
  import JiraHelpers from '../../../factories/JiraHelpers'

  export default {
    props: {
      task: { type: Object, default: () => ({}) },
      project: { type: Object, default: () => ({}) },
    },

    data() {
      return {
        unsyncCurrentTasks: false,
        loading: false,
      }
    },

    computed: {
      ...mapState({
        availableProjects: (state) => state.jira.availableProjects,
        isJiraConfigured: (state) =>
          !!state.session.current_team.jira_webtrigger_url,
      }),

      syncedProject() {
        return (
          this.availableProjects.find(
            (project) => project.id === this.project.linked_jira_project_id
          ) || {}
        )
      },
      jiraIssueKey() {
        return this.task?.linked_jira_issue_url?.split('/').pop() || null
      },

      syncingTask() {
        return !this.project?.id
      },

      syncingProject() {
        return !!this.project.id
      },
    },

    methods: {
      async unSyncEntity() {
        if (!this.isJiraConfigured) {
          window.toastr.error(
            'Jira is not configured for this team. Please see the Jira Integration Page at Settings > Jira Integration for more details on setting up the integration.'
          )
          return
        } else {
          this.loading = true
          if (this.syncingTask) {
            await JiraHelpers(this).handleJiraTaskSync({
              createSync: false,
              fullCircleTaskId: this.task.id,
            })
          } else {
            await JiraHelpers(this).handleJiraProjectSync({
              createSync: false,
              fullCircleProjectId: this.project.id,
              applyToProjectTasks: this.unsyncCurrentTasks,
            })
          }
          this.$refs.Close.click()
          this.loading = false
        }
      },
    },
  }
</script>
